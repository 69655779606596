import shortid from 'shortid';

export default function createSocketIoClient(isMock = false) {
  if (isMock) {
    return {
      isMock: true,
      connect(sioServerOrigin, options) {
        return new SocketMock(sioServerOrigin, options);
      }
    };
  } else {
    return require('socket.io-client');
  }
};

class SocketMock {
  static CONNECTION_TIME = 800;   // ms

  constructor(serverOrigin, options) {
    this.id = shortid.generate();
    this._serverOrigin = serverOrigin;
    this._options = options;
    this._handlers = {};

    this._connect();
  }

  on(eventName, handler) {
    this._subscribeHandler(eventName, handler);
  }

  once(eventName, handler) {
    this._subscribeHandler(eventName, handler, true);
  }

  off(eventName, handler) {
    const idx = (this._handlers[eventName] || []).indexOf(item => item.handler === handler);
    if (idx >= 0) {
      this._handlers.splice(idx, 1);
    }
  }

  _subscribeHandler(eventName, handler, once=false) {
    if (!this._handlers[eventName]) {
      this._handlers[eventName] = [];
    }

    const idx = this._handlers[eventName].indexOf(item => item.handler === handler);
    if (idx < 0) {
      this._handlers[eventName].push({
        handler,
        once
      });
    }
  }

  _connect() {
    setTimeout(() => {
      this._dispatchEvent('connect');
    }, SocketMock.CONNECTION_TIME);

    this._options.mockEvents.forEach(event => {
      if (event.useMock) {
        setTimeout(() => {
          this._dispatchEvent(event.serverSideName);
        }, event.mockTimeOut * 1000);
      }
    });
  }

  _dispatchEvent(eventName) {
    if (!this._handlers[eventName]) {
      return;
    }

    this._handlers[eventName] = this._handlers[eventName].filter(item => {
      item.handler();
      return item.once;
    });
  }
}
