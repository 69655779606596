import React, {useState, useEffect, useRef} from 'react';
import {Link, Redirect} from 'react-router-dom';
import queryString from 'query-string';
import {CSSTransition} from 'react-transition-group';

import SubmitButton from 'components/widgets/SubmitButton';
import AuthPageContainer from 'components/widgets/AuthPageContainer';
import {useAuthRequests} from 'hooks';
import { t, redirectTo, getAccessToken, setAccessToken, withCurrentQueryArgs,
  getErrorText, isEmpty, isEmailValid, addRefFocus} from 'utils';
import settings from 'settings';


export default function PasswordRecovery() {
  const {pa, uid} = queryString.parse(window.location.search);
  const {error, isFetching, isSuccess, setError, onSubmit} = useAuthRequests();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const passwordRef = useRef();
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(true);
  }, []);


  useEffect(() => {
    if (passwordRef) {
      addRefFocus(passwordRef.current);
    }
  }, [passwordRef]);

  useEffect(() => {
    if (getAccessToken()) {
      redirectTo('/');
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError(t('error.password.notMatch'));
      return;
    }
    if (isEmpty(password)) {
      setError(t('error.field.empty'));
      return;
    }
    const data = { token: pa, password };
    const headers = { headers: {'Authorization': undefined} };
    onSubmit('/auth/reset', data, headers, () => {
      setTimeout(() => {
        redirectTo('/signin');
      }, 5000);
    });
  };

  if (!pa) {
    return (<Redirect to="/signin" />);
  }

  return (
    <AuthPageContainer>
      <CSSTransition
        in={pageLoading}
        timeout={300}
        classNames="register-nav-w-animation"
        unmountOnExit
      >
        <div className="register-nav-w">
          <div className="register-nav-container">
            <a href={`${settings.LANDING_URL}/`}  className="nav-logo w-nav-brand">
              <img src={require('styles/images/logo-heycom.svg')} className="sign-logo-img"/></a>
            <div className="register-nav-block">
              <div className="register-nav-text">{t('reset.note.alreadyUser')}</div>
              <Link to={withCurrentQueryArgs('/signin', 'next', 'lang')} className="sign-nav-link">
                {t('reset.login.link')}
              </Link>
            </div>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={pageLoading}
        timeout={300}
        classNames="register-wrapper-animation"
        unmountOnExit
      >
        <div className="register-wrapper">
          <div className="register-col-2 no-min-h">
            <div className="sign-heading-div min-margin">
              <div className="sign-heading">{t('recovery.header')}</div>
            </div>
            <div className="form-block-4 w-form">
              <form className="register-form" onSubmit={handleSubmit}>
                {t('password.placeholder', {},
                  msg => <input ref={passwordRef} type="password" className="text-field add-indent w-input"
                                maxLength="256" placeholder={msg} required=""
                                value={password} onChange={e => setPassword(e.target.value)}/>)}
                {t('password.confirm.placeholder', {},
                  msg => <input type="password" className="text-field add-indent w-input"
                                maxLength="256" placeholder={msg} required=""
                                value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>)}
                <SubmitButton simpleButton={true} additionalClass={'full-w'} isSending={isFetching} text={t('recovery.button')}/>
              </form>
              {isSuccess && (
                <div className="w-form-done success-message">
                  <div>{t('recovery.success')}</div>
                </div>
              )}
              {error && (
                <div className="w-form-fail error-message-2">
                  <div>{error}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </AuthPageContainer>
  );
}
