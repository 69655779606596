import React, {Fragment} from 'react';


export default function ActionButtons(props) {
  const {onSave, onCancel} = props;
  return (
    <>
      <div className="divider"></div>
      <div className="settings-btn-w">
        <div className="blue-button w-button" onClick={onCancel}>Cancel</div>
        <div className="button left-indent w-button" onClick={onSave}>Save</div>
      </div>
    </>
  );
};