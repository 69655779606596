import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash';
import moment from "moment";

import {useTablePagination, useMeetingReports, useWidgets, useUpdateData, useOperators} from 'hooks';
import {getOperatorUserName, downloadFile, t} from 'utils';
import Loader from "components/widgets/Loader";
import TablePagination from 'components/widgets/TablePagination';


export default function BookedMeetingTables(props) {
  const {dateRange, selectedOperator, selectedMeetingLink} = props;

  const [sortManagerBookedMeeting, setSortManagerBookedMeeting] = useState('-count');

  const {bookedMeetings, managersByBookedMeeting, bookedMeetingsCount, managersByBookedMeetingCount,
    isUpdateBookedMeetings, isBookedMeetingsFetching, isManagersFetching,
    getBookedMeetings, getManagerBookedMeetings, exportBookedMeetings} = useMeetingReports();
  const {currentWidgetId} = useWidgets();

  const bookingMeetingsTableHeaders = [
    'name', 'bookedWith', 'bookingDate', 'meetingDate',
    //'status'
  ];
  const topMeetingsTableHeaders = ['managerName', 'meetingsBooked'];

  const handleBookedMeetingsGet = (offsetParams) => {
    getBookedMeetings({
      ...offsetParams,
      operator_id: _.get(selectedOperator, 'id'),
      meeting_link_id: _.get(selectedMeetingLink, 'id'),
      start_datetime: dateRange.created_at__gte,
      end_datetime: dateRange.created_at__lte,
    });
  };

  const handleBookedMeetingsExport = () => {
    exportBookedMeetings({
      operator_id: _.get(selectedOperator, 'id'),
      meeting_link_id: _.get(selectedMeetingLink, 'id'),
      start_datetime: dateRange.created_at__gte,
      end_datetime: dateRange.created_at__lte,
    }).then(data => {
      downloadFile(data.data, data.fileName, data.mimeType);
    });
  };

  const handleManagerBookedMeetingsGet = (offsetParams = {}) => {
    getManagerBookedMeetings({
      ...offsetParams,
      sort: sortManagerBookedMeeting,
      operator_id: _.get(selectedOperator, 'id'),
      meeting_link_id: _.get(selectedMeetingLink, 'id'),
      start_datetime: dateRange.created_at__gte,
      end_datetime: dateRange.created_at__lte,
    });
  };

  const {itemsOnPage: bookingMeetingsOnPage, pageCount: bookingMeetingsPageCount,
    pageNumber: bookingMeetingsPageNumber, tableRowCount: bookingMeetingsTableRowCount,
    rowCounts: bookingMeetingsRowCounts, setPageNumber: setBookingMeetingsPageNumber,
    setTableRowCount: setBookingMeetingsTableRowCount} = useTablePagination(bookedMeetings,
    bookedMeetingsCount, handleBookedMeetingsGet);
  // const {itemsOnPage: topManagersOnPage, pageCount: topManagersPageCount,
  //   pageNumber: topManagersPageNumber, tableRowCount: topManagersTableRowCount,
  //   rowCounts: topManagersRowCounts, setPageNumber: setTopManagersPageNumber,
  //   setTableRowCount: setTopManagersTableRowCount} = useTablePagination(managersByBookedMeeting,
  //   managersByBookedMeetingCount, handleManagerBookedMeetingsGet);

  const {operatorsById} = useOperators();

  const isFirstRun = useRef([true, true]);

  useEffect(() => {
    if (isFirstRun.current[0]) {
      isFirstRun.current[0] = false;
      //return;
    } else {
      if (!_.isEmpty(dateRange)) {
        handleBookedMeetingsGet({
          offset: bookingMeetingsTableRowCount * bookingMeetingsPageNumber,
          limit: bookingMeetingsTableRowCount,
        });
        handleManagerBookedMeetingsGet();
      }
    }
  }, [dateRange, selectedOperator, selectedMeetingLink, currentWidgetId]);

  useUpdateData(() => {
    handleBookedMeetingsGet({
      offset: bookingMeetingsTableRowCount * bookingMeetingsPageNumber,
      limit: bookingMeetingsTableRowCount,
    });
    handleManagerBookedMeetingsGet();
  });

  useEffect(() => {
    if (!_.isEmpty(dateRange)) {
      getManagerBookedMeetings({
        sort: sortManagerBookedMeeting,
        operator_id: _.get(selectedOperator, 'id'),
        meeting_link_id: _.get(selectedMeetingLink, 'id'),
        start_datetime: dateRange.created_at__gte,
        end_datetime: dateRange.created_at__lte,
      });
    }
    //getMeetingsChartData();

  }, [sortManagerBookedMeeting]);


  const handleSort = (selectedOrdering) => {
    let newOrdering = '';
    if (sortManagerBookedMeeting.includes(selectedOrdering)) {
      if (sortManagerBookedMeeting.includes('-')) {
        newOrdering = selectedOrdering;
      } else {
        newOrdering = `-${selectedOrdering}`;
      }
    } else {
      newOrdering = selectedOrdering;
    }
    setSortManagerBookedMeeting(newOrdering);
  };

  const _t = (value, values = {}, children = null) => {
    return t(`meetings.reports.table.${value}`, values, children);
  };

  let dataRangeStr = '';
  if (dateRange.year__gte === dateRange.year__lte && dateRange.month__gte === dateRange.month__lte) {
    dataRangeStr = `${moment(dateRange.created_at__gte, moment.ISO_8601).format('MMMM D')} - ${moment(dateRange.created_at__lte, moment.ISO_8601).format('D, YYYY')}`;
  } else {
    if (dateRange.year__gte === dateRange.year__lte && dateRange.month__gte !== dateRange.month__lte) {
      dataRangeStr = `${moment(dateRange.created_at__gte, moment.ISO_8601).format('MMMM D')} - ${moment(dateRange.created_at__lte, moment.ISO_8601).format('MMMM D, YYYY')}`;
    } else {
      dataRangeStr = `${moment(dateRange.created_at__gte, moment.ISO_8601).format('MMMM D, YYYY')} - ${moment(dateRange.created_at__lte, moment.ISO_8601).format('MMMM D, YYYY')}`;
    }
  }

  return (
    <>
      <div className='content-white-block'>
        <div className='page-content-header-div hor-flex space-between'>
          <div className='group-title'>
            {_t('bookedMeetings.title')} <span className='regular'>({dataRangeStr})</span>
          </div>
          <a className='action-btn no-indent w-inline-block' onClick={handleBookedMeetingsExport}>
            <div>
              <span className='awesome-icon'></span> {_t('bookedMeetings.export')}
            </div>
          </a>
        </div>
        <div className='content-white-block table-container-2'>
          <div className="table-w">
            <div className="table-row table-header">
              {bookingMeetingsTableHeaders.map((header,index) =>
                <div key={index} className='table-col1'>
                  <div className="details-header">{_t(`bookedMeetings.header.${header}`)}</div>
                </div>
              )}
            </div>
            <div className="table-content">
              {bookedMeetings.length > 0 && bookingMeetingsOnPage.map(meeting =>
                <div key={meeting.id} className='table-row'>
                  <div className='table-col1'>
                    <div className='cp-info'><strong>{meeting.name}</strong> /<br/> {meeting.email}</div>
                  </div>
                  <div className='table-col1'>
                    <div className='chat-avatar-2'>
                      {meeting.operatorId && operatorsById[meeting.operatorId] && operatorsById[meeting.operatorId].user && (
                        <>
                          {operatorsById[meeting.operatorId].user.avatar.url ? (
                            <img className="operator-avatar-meeting" src={operatorsById[meeting.operatorId].user.avatar.url}/>
                          ) : (
                            <div>{getOperatorUserName(operatorsById[meeting.operatorId]) ?
                              getOperatorUserName(operatorsById[meeting.operatorId])[0].toUpperCase() : 'O'}</div>
                          )}
                        </>
                      )}
                    </div>
                    <div className='cp-info'>
                      <strong>{getOperatorUserName(operatorsById[meeting.operatorId])}</strong>
                    </div>
                  </div>
                  <div className='table-col1'>
                    <div className='cp-info'>{meeting.bookingDate}</div>
                  </div>
                  <div className='table-col1'>
                    <div className='cp-info'>{meeting.meetingDate}</div>
                  </div>
                  {/*<div className='table-col1'>*/}
                    {/*<div className='badge badge-success'>{meeting.status}</div>*/}
                  {/*</div>*/}
                </div>
              )}
            </div>
          </div>
          <div className="table-settings right-float">
            <TablePagination itemsLength={bookingMeetingsOnPage.length} rowCounts={bookingMeetingsRowCounts}
                             pageCount={bookingMeetingsPageCount} tableRowCount={bookingMeetingsTableRowCount}
                             onTogglePage={page => setBookingMeetingsPageNumber(page.selected)}
                             setTableRowCount={setBookingMeetingsTableRowCount}
                             pageNumber={bookingMeetingsPageNumber}/>
          </div>
        </div>
      </div>

      <div className='content-white-block'>
        <div className='page-content-header-div hor-flex space-between'>
          <div className='group-title'>
            {_t('topMeetings.title')}
            <span className='regular'>
              ({dataRangeStr})
            </span>
          </div>
        </div>
        <div className='content-white-block table-container-2'>
          <div className="table-w">
            <div className="table-row table-header">
              {topMeetingsTableHeaders.map((header,index) =>
                <div key={header} className='table-col1'>
                  <div className="details-header">{_t(`topMeetings.header.${header}`)}</div>
                  {header === 'meetingsBooked' && (
                    <div className="sorting arrows-block" onClick={() => handleSort('count')}>
                      <div className={`arrow-up ${(sortManagerBookedMeeting.includes(header.value) && (!(sortManagerBookedMeeting.includes('-')))) ? 'active' : ''}`}/>
                      <div className={`arrow-down ${(sortManagerBookedMeeting.includes(header.value) && sortManagerBookedMeeting.includes('-')) ? 'active' : ''}`}/>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="table-content">
              {managersByBookedMeeting.length > 0 && managersByBookedMeeting.map((operator, i) =>
                <div key={i} className='table-row'>
                  <div className='table-col1'>
                    <div className='chat-avatar-2'>
                      {operator.id && operatorsById[operator.id] && operatorsById[operator.id].user && (
                        <>
                          {operatorsById[operator.id].user.avatar.url ? (
                            <img className="operator-avatar-meeting" src={operatorsById[operator.id].user.avatar.url}/>
                          ) : (
                            <div>{getOperatorUserName(operatorsById[operator.id]) ?
                              getOperatorUserName(operatorsById[operator.id])[0].toUpperCase() : 'O'}</div>
                          )}
                        </>
                      )}
                    </div>
                    <div className='cp-info'>
                      <strong>{getOperatorUserName(operatorsById[operator.id])}</strong>
                    </div>
                  </div>
                  <div className='table-col1'>
                    <div className='cp-info'>{operator.count}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/*<div className="table-settings right-float">*/}
            {/*<TablePagination itemsLength={topManagersOnPage.length} rowCounts={topManagersRowCounts}*/}
                             {/*pageCount={topManagersPageCount} tableRowCount={topManagersTableRowCount}*/}
                             {/*onTogglePage={page => setTopManagersPageNumber(page.selected)}*/}
                             {/*setTableRowCount={setTopManagersTableRowCount}*/}
                             {/*pageNumber={topManagersPageNumber}/>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
}