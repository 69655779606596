import React, { Fragment } from 'react';
import {randString} from 'utils';

export default function Checkbox(props){
  const inputId = 'chb-' + randString(10);
  const { label, additionalClass, checked, onChange} = props;

  return (
    <label className="w-checkbox" key={inputId}>
      <input type="checkbox" id={inputId} className="w-checkbox-input checkbox" checked={checked} onChange={onChange}/>
      <span className="w-form-label">{label}</span>
    </label>
  );
}
