import update from 'immutability-helper';
import l_cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import * as actions from 'actions';
import {ANALYTICS_TYPES, WIDGET_CHART_LINES, CHANNELS_OPTIONS,
  FACEBOOK_CHART_LINES, CAMPAIGN_TYPES} from 'constants.js'


const initialState = {
  operatorData: {},
  chatsChartData: null,
  campaignsChartData: null,
  analyticsError: null,
  _isAnalyticsFetching: false,
  selectedAnalyticsType: Object.values(ANALYTICS_TYPES)[0]
};

export default function analytics(state=initialState, action) {
  switch(action.type) {
    case actions.FETCH_ANALYTICS__R: {
      return update(state, {
        _isAnalyticsFetching: {$set: true}
      });
    }
    case actions.FETCH_ANALYTICS__S: {
      let updateData = {
        _isAnalyticsFetching: {$set: false}
      };
      if (action.payload.type === Object.values(ANALYTICS_TYPES)[0].value) {
        const data = getFormattedChartData(action.payload.data, action.payload.type);
        updateData['chatsChartData'] =  {$set: {
          chartData: data.chartData,
          totalCounts: data.totalCounts,
          tickFormat: action.payload.tickFormat
        }}
      }
      if (action.payload.type === Object.values(ANALYTICS_TYPES)[1].value) {
        updateData['operatorData'] = {$set: action.payload.data};
      }
      if (action.payload.type === Object.values(ANALYTICS_TYPES)[2].value) {
        const data = getFormattedChartData(action.payload.data, action.payload.type);
        updateData['campaignsChartData'] =  {$set: {
          chartData: data.chartData,
          totalCounts: data.totalCounts,
          tickFormat: action.payload.tickFormat
        }}
      }
      if (CHANNELS_OPTIONS.facebookMessenger.includedTypes.includes(action.payload.type)) {
        const data = getFormattedChartData(action.payload.data, action.payload.type);
        updateData['campaignsChartData'] =  {$set: {
            chartData: data.chartData,
            totalCounts: data.totalCounts,
            tickFormat: action.payload.tickFormat
          }}
      }
      return update(state, updateData);
    }
    case actions.FETCH_ANALYTICS__F: {
      return update(state, {
        analyticsError: {$set: action.payload},
        _isAnalyticsFetching: {$set: false}
      });
    }

    case actions.SET_ANALYTICS_TYPE_FILTER: {
      return update(state, {
        selectedAnalyticsType: {$set: action.payload},
      });
    }
    default:
      return state;
  }
}

const getFormattedChartData = (analyticsData, type) => {
  const chartData = [];
  if (type === CAMPAIGN_TYPES.facebookMessenger.value) {
    const totalCounts = {
      [FACEBOOK_CHART_LINES.chats]: 0,
      [FACEBOOK_CHART_LINES.leads]: 0,
      [FACEBOOK_CHART_LINES.finished]: 0,
      [FACEBOOK_CHART_LINES.subscribes]: 0,
      [FACEBOOK_CHART_LINES.unsubscribes]: 0,
      [FACEBOOK_CHART_LINES.activeSubscribes]: 0,
    };
    const chats = analyticsData[FACEBOOK_CHART_LINES.chats];
    const leads = analyticsData[FACEBOOK_CHART_LINES.leads];
    const finished = analyticsData[FACEBOOK_CHART_LINES.finished];
    const subscribes = analyticsData[FACEBOOK_CHART_LINES.subscribes];
    const unsubscribes = analyticsData[FACEBOOK_CHART_LINES.unsubscribes];
    const activeSubscribes = analyticsData[FACEBOOK_CHART_LINES.activeSubscribes];

    chats.forEach((point, i) => {
      const p = {
        datetime: point.datetime,
        date: moment(point.datetime).format('YYYY-MM-DD'),
        time: moment(point.datetime).format('HH:mm'),
        [FACEBOOK_CHART_LINES.chats]: point.value,
        [FACEBOOK_CHART_LINES.leads]: leads[i].value,
        [FACEBOOK_CHART_LINES.finished]: finished[i].value,
        [FACEBOOK_CHART_LINES.subscribes]: subscribes[i].value,
        [FACEBOOK_CHART_LINES.unsubscribes]: unsubscribes[i].value,
        [FACEBOOK_CHART_LINES.activeSubscribes]: activeSubscribes[i].value,
      };
      totalCounts[FACEBOOK_CHART_LINES.chats] += point.value;
      totalCounts[FACEBOOK_CHART_LINES.leads] += leads[i].value;
      totalCounts[FACEBOOK_CHART_LINES.finished] += finished[i].value;
      totalCounts[FACEBOOK_CHART_LINES.subscribes] += subscribes[i].value;
      totalCounts[FACEBOOK_CHART_LINES.unsubscribes] += unsubscribes[i].value;
      totalCounts[FACEBOOK_CHART_LINES.activeSubscribes] += activeSubscribes[i].value;
      chartData.push(p)
    });

    return {chartData, totalCounts};
  }
  if (type === Object.values(ANALYTICS_TYPES)[0].value) {
    const totalCounts = {
      [WIDGET_CHART_LINES.totalChats]: 0,
      [WIDGET_CHART_LINES.totalMessages]: 0,
      [WIDGET_CHART_LINES.visitorMessages]: 0,
      [WIDGET_CHART_LINES.operatorMessages]: 0,
    };
    const totalChats = analyticsData[WIDGET_CHART_LINES.totalChats];
    const totalMessages = analyticsData[WIDGET_CHART_LINES.totalMessages];
    const visitorMessages = analyticsData[WIDGET_CHART_LINES.visitorMessages];
    const operatorMessages = analyticsData[WIDGET_CHART_LINES.operatorMessages];

    totalChats.forEach((point, i) => {
      const p = {
        datetime: point.datetime,
        date: moment(point.datetime).format('YYYY-MM-DD'),
        time: moment(point.datetime).format('HH:mm'),
        [WIDGET_CHART_LINES.totalChats]: point.value,
        [WIDGET_CHART_LINES.totalMessages]: totalMessages[i].value,
        [WIDGET_CHART_LINES.visitorMessages]: visitorMessages[i].value,
        [WIDGET_CHART_LINES.operatorMessages]: operatorMessages[i].value,
      };
      totalCounts[WIDGET_CHART_LINES.totalChats] += point.value;
      totalCounts[WIDGET_CHART_LINES.totalMessages] += totalMessages[i].value;
      totalCounts[WIDGET_CHART_LINES.visitorMessages] += visitorMessages[i].value;
      totalCounts[WIDGET_CHART_LINES.operatorMessages] += operatorMessages[i].value;
      chartData.push(p)
    });

    //console.log(chartData)

    return {chartData, totalCounts};
  }
  if (type === Object.values(ANALYTICS_TYPES)[2].value) {
    const totalCounts = {
      [WIDGET_CHART_LINES.campaignMessages]: 0
    };
    const campaignMessages = analyticsData[WIDGET_CHART_LINES.campaignMessages];

    campaignMessages.forEach((point, i) => {
      chartData.push({
        datetime: point.datetime,
        date: moment(point.datetime).format('YYYY-MM-DD'),
        time: moment(point.datetime).format('HH:mm'),
        [WIDGET_CHART_LINES.campaignMessages]: point.value,
      });
      totalCounts[WIDGET_CHART_LINES.campaignMessages] += point.value;
    });
    return {chartData, totalCounts};
  }
};
