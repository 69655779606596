import React, {useState, useCallback, useEffect} from 'react';
import {useSelector, useDispatch, shallowEqual } from 'react-redux';
import * as actions from 'actions';

export const usePlans = () => {
  const planFeatures = useSelector(state => state.billing.planFeatures, shallowEqual);
  const userCurrentPlan = useSelector(state => state.billing.userCurrentPlan, shallowEqual);
  const selectedPlan = useSelector(state => state.billing.selectedPlan, shallowEqual);
  const _isUserCurrentPlanFetching = useSelector(state => state.billing._isUserCurrentPlanFetching, shallowEqual);
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);

  const dispatch = useDispatch();
  const getPlans = useCallback((filter = {widgetId: currentWidgetId}) => {dispatch(actions.fetchPlans(filter))},
    [dispatch, currentWidgetId]);
  const setPlan = useCallback((plan, period) => {dispatch(actions.setSelectedPlan(plan, period))}, [dispatch]);

  return {planFeatures, getPlans, userCurrentPlan, _isUserCurrentPlanFetching, setPlan};
};

export const usePaymentHistory = () => {
  const currentUserId = useSelector(state => state.users.currentUser.id, shallowEqual);
  const paymentHistoryById = useSelector(state => state.billing.paymentHistoryById, shallowEqual);
  const paymentHistory = useSelector(state => state.billing.paymentHistory, shallowEqual);
  const paymentHistoryCount = useSelector(state => state.billing.paymentHistoryCount, shallowEqual);
  const isFetching = useSelector(state => state.billing._isBillingsFetching, shallowEqual);
  const isPaymentCheckingout = useSelector(state => state.billing._isPaymentCheckingout, shallowEqual);
  const isPaymentDeleting = useSelector(state => state.billing._isPaymentDeleting, shallowEqual);

  const dispatch = useDispatch();
  const getPaymentHistory = useCallback(params => {
    dispatch(actions.fetchUserPaymentHistory({
      //userId: currentUserId,
      ...params
    }))
  }, [dispatch, currentUserId]);

  return {paymentHistoryById, paymentHistory, paymentHistoryCount, getPaymentHistory,
    isFetching, isPaymentCheckingout, isPaymentDeleting};
};

export const useUserCard = () => {
  const _isUserCardSetting = useSelector(state => state.billing._isUserCardSetting, shallowEqual);
  const userCardError = useSelector(state => state.billing.userCardError, shallowEqual);

  const dispatch = useDispatch();

  const setUserCard = useCallback(stripeObject => dispatch(actions.setUserCard(stripeObject)), [dispatch]);
  const clearUserCardError = useCallback(() => dispatch(actions.clearUserCardError()), [dispatch]);

  return {setUserCard, userCardError, clearUserCardError, _isUserCardSetting};
};

export const usePayments = () => {
  const dispatch = useDispatch();

  const deletePayment = useCallback(paymentId => dispatch(actions.deletePayment(paymentId)), [dispatch]);

  return {deletePayment};
};

export const useCurrentUserPlan = () => {
  const userCurrentPlan = useSelector(state => state.billing.userCurrentPlan, shallowEqual);
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const currentUser = useSelector(state => state.users.currentUser, shallowEqual);
  const dispatch = useDispatch();

  const getUserCurrentPlan = useCallback((filter) => {
    console.log('User current plan: ', filter)
    if (filter.widget_id) {
      dispatch(actions.fetchUserCurrentPlan(filter));
    }},
  [dispatch]);

  return {userCurrentPlan, getUserCurrentPlan};
};