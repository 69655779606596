import _ from 'lodash';

export default class StripeFactory {
  static _instance = null;
  static _stripeApiKey = null;
  static _options = null;

  static getInstance(stripeApiKey = null, options = null) {
    // debugger;

    if (stripeApiKey != null) {
      if (!_.isEqual(stripeApiKey, StripeFactory._stripeApiKey) && !_.isEqual(options, StripeFactory._options)) {
        if (window.Stripe) {
          StripeFactory._instance = window.Stripe(stripeApiKey, options);
          StripeFactory._stripeApiKey = stripeApiKey;
          StripeFactory._options = options;
        }
      }
    }

    return StripeFactory._instance;
  }
}
