import React, { Fragment, useState } from 'react';
import ReactModal from 'react-modal';
import SubmitButton from 'components/widgets/SubmitButton';

export default function ModalWarning(props) {
  const {text, label, buttonConfirmText = 'Confirm', isOpen, onConfirm, onClose} = props;

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 outline-none"
      overlayClassName="popup-w-2 w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={onClose}
    >
      <p className="popup-subheader">{text}</p>
      <div className="w-form">
        <div>
          <div className="settings-btn-w add-space-2 align-center">
            <div className="link-2" onClick={onClose}>Cancel</div>
            <SubmitButton simpleButton={true} onClick={onConfirm} additionalClass="button-3 left-indent w-button"
                          isSending={false} text={buttonConfirmText}/>
          </div>
        </div>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
      <div className="abs-position" onClick={onClose}>
        <div className="delete-btn">
          <div className="close-line-1 rotate"></div>
          <div className="close-line-1"></div>
        </div>
      </div>
    </ReactModal>
  );
};