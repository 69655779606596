import update from 'immutability-helper';
import * as actions from 'actions';

const initialState = {
  widgetsById: null,
  widgetsError: null,
  _isWidgetsFetching: false,

  currentWidgetId: localStorage.getItem('hc_widget_id') || null,
  currentWidget: null,
  isInstallCurrentWidget: false,
  widgetError: null,

  selectedSettingsTab: 'widgetSettings',

  _isWidgetFetching: false,
  _isCreating: false,
  _isUpdating: false,
  _isRemoving: false,
  _isCheckingInstall: false,
  _isCreatingProperty: false,

  facebookPages: [],
  selectedFacebookPage: null,
  facebookConnectingError: null,
  _isFacebookConnecting: false,
  _isFacebookPagesFetching: false,
  _isFacebookPageConnecting: false,
  _isFacebookLogout: false
};

export default function widgets(state=initialState, action) {
  switch(action.type) {
    case actions.FETCH_WIDGETS__R: {
      return update(state, {
        _isWidgetsFetching: {$set: true}
      });
    }
    case actions.FETCH_WIDGETS__S: {
      const widgetsById = action.payload.reduce((obj, cur) => {
        obj[cur.id] = cur;
        return obj;
      }, {})
      return update(state, {
        widgetsById: {$set: widgetsById},
        currentWidgetId: {$set: state.currentWidgetId || (action.payload[0] ? action.payload[0].id : null)},
        currentWidget: {$set: state.currentWidgetId ? widgetsById[state.currentWidgetId] : action.payload[0]},
        widgetsError: {$set: null},
        _isWidgetsFetching: {$set: false}
      });
    }
    case actions.FETCH_WIDGETS__F: {
      return update(state, {
        widgetsError: {$set: action.payload},
        _isWidgetsFetching: {$set: false}
      });
    }

    case actions.FETCH_WIDGET__R: {
      return update(state, {
        _isWidgetFetching: {$set: true}
      });
    }
    case actions.FETCH_WIDGET__S: {
      return update(state, {
        currentWidget: {$set: action.payload},
        currentWidgetId: {$set: action.payload.id},
        widgetError: {$set: null},
        _isWidgetFetching: {$set: false}
      });
    }
    case actions.FETCH_WIDGET__F: {
      return update(state, {
        widgetError: {$set: action.payload},
        _isWidgetFetching: {$set: false}
      });
    }

    case actions.CREATE_WIDGET__R: {
      return update(state, {
        _isCreating: {$set: true}
      });
    }
    case actions.CREATE_WIDGET__S: {
      let widget = action.payload;
      return update(state, {
        widgetsById: {[action.payload.id]: {$set: widget}},
        _isCreating: {$set: false}
      });
    }
    case actions.CREATE_WIDGET__F: {
      return update(state, {
        widgetError: {$set: action.payload},
        _isCreating: {$set: false}
      });
    }

    case actions.UPDATE_WIDGET__R: {
      return update(state, {
        _isUpdating: {$set: true}
      });
    }
    case actions.UPDATE_WIDGET__S: {
      let widget = action.payload;
      return update(state, {
        widgetsById: {[action.payload.id]: {$set: widget}},
        selectedFacebookPage: {$set: getSelectedFacebookPage(state.facebookPages, action.payload)},
        currentWidget: {$set: widget},
        _isUpdating: {$set: false}
      });
    }
    case actions.UPDATE_WIDGET__F: {
      return update(state, {
        widgetError: {$set: action.payload},
        _isUpdating: {$set: false}
      });
    }

    case actions.REMOVE_WIDGET__R: {
      return update(state, {
        _isRemoving: {$set: true}
      });
    }
    case actions.REMOVE_WIDGET__S: {
      let widgetId = action.payload;
      if (localStorage.getItem('hc_widget_id') === widgetId) {
        localStorage.removeItem('hc_widget_id')
      }
      let firstWidgetId = Object.values(state.widgetsById)[0].id;
      return update(state, {
        widgetsById: {$unset: [widgetId]},
        currentWidgetId: {$set: firstWidgetId},
        _isRemoving: {$set: false}
      });
    }
    case actions.REMOVE_WIDGET__F: {
      return update(state, {
        widgetError: {$set: action.payload},
        _isRemoving: {$set: false}
      });
    }

    case actions.CHECK_INSTALL_WIDGET__R: {
      return update(state, {
        _isCheckingInstall: {$set: true}
      });
    }
    case actions.CHECK_INSTALL_WIDGET__S: {
      return update(state, {
        isInstallCurrentWidget: {$set: action.payload['is_installed']},
        widgetError: {$set: null},
        _isCheckingInstall: {$set: false}
      });
    }
    case actions.CHECK_INSTALL_WIDGET__F: {
      return update(state, {
        widgetError: {$set: action.payload},
        _isCheckingInstall: {$set: false}
      });
    }

    case actions.SET_CURRENT_WIDGET: {
      localStorage.setItem('hc_widget_id', action.payload)
      return update(state, {
        currentWidgetId: {$set: action.payload},
        currentWidget: {$set: state?.widgetsById[action.payload] ? state?.widgetsById[action.payload] : state.currentWidget},
      });
    }

    case actions.SELECT_WIDGET_SETTING_TAB: {
      return update(state, {
        selectedSettingsTab: {$set: action.payload}
      });
    }

    case actions.CREATE_CONTACT_PROPERTY__R: {
      return update(state, {
        _isCreatingProperty: {$set: true}
      });
    }
    case actions.CREATE_CONTACT_PROPERTY__S: {
      if (action.payload.id === state.currentWidget.id) {
        return update(state, {
          currentWidget: {$set: action.payload},
          widgetsById: {[action.payload.id]: {$set: action.payload}},
          _isCreatingProperty: {$set: false}
        });
      } else {
        return update(state, {
          widgetsById: {[action.payload.id]: {$set: action.payload}},
          _isCreatingProperty: {$set: false}
        });
      }

    }
    case actions.CREATE_CONTACT_PROPERTY__F: {
      return update(state, {
        widgetError: {$set: action.payload},
        _isCreatingProperty: {$set: false}
      });
    }


    case actions.FACEBOOK_LOGIN__R: {
      return update(state, {
        _isFacebookConnecting: {$set: true}
      });
    }
    case actions.FACEBOOK_LOGIN__S: {
      return update(state, {
        _isFacebookConnecting: {$set: false}
      });
    }
    case actions.FACEBOOK_LOGIN__F: {
      return update(state, {
        facebookConnectingError: {$set: action.payload},
        _isFacebookConnecting: {$set: false}
      });
    }

    case actions.FACEBOOK_LOGOUT__R: {
      return update(state, {
        _isFacebookLogout: {$set: true}
      });
    }
    case actions.FACEBOOK_LOGOUT__S: {
      return update(state, {
        _isFacebookLogout: {$set: false}
      });
    }
    case actions.FACEBOOK_LOGOUT__F: {
      return update(state, {
        facebookConnectingError: {$set: action.payload},
        _isFacebookLogout: {$set: false}
      });
    }

    case actions.FETCH_FACEBOOK_PAGES__R: {
      return update(state, {
        _isFacebookPagesFetching: {$set: true}
      });
    }
    case actions.FETCH_FACEBOOK_PAGES__S: {
      return update(state, {
        facebookPages: {$set: action.payload},
        selectedFacebookPage: {$set: getSelectedFacebookPage(action.payload, state.currentWidget)},
        _isFacebookPagesFetching: {$set: false}
      });
    }
    case actions.FETCH_FACEBOOK_PAGES__F: {
      return update(state, {
        facebookConnectingError: {$set: action.payload},
        _isFacebookPagesFetching: {$set: false}
      });
    }

    case actions.FACEBOOK_PAGE_CHOOSE__R: {
      return update(state, {
        _isFacebookPageConnecting: {$set: true}
      });
    }
    case actions.FACEBOOK_PAGE_CHOOSE__S: {
      return update(state, {
        _isFacebookPageConnecting: {$set: false}
      });
    }
    case actions.FACEBOOK_PAGE_CHOOSE__F: {
      return update(state, {
        facebookConnectingError: {$set: action.payload},
        _isFacebookPageConnecting: {$set: false}
      });
    }

    default:
      return state;
  }
}

const getSelectedFacebookPage = (facebookPages, currentWidget) => {
  const pageById = facebookPages.reduce((obj, cur) => {
    obj[cur.id] = cur;
    return obj;
  }, {});

  const selectedPageId = currentWidget.settings.channels.facebook.fb_selected_page_id;

  return pageById[selectedPageId] || null
}