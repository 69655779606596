import settings from 'settings';
import {ConnectionArrowSize} from 'constants/botEditor';

export const debugLog = (...args) => {
  if (settings.WIDGET_DEBUG_ENABLED) {
    console.log(`[[ ${settings.SERVICE_BRAND_NAME.toUpperCase()} ]]`, ...args);
  }
};

// // export const getTransitionCoordinates = (component, nextComponent, outElement, entryPointElement) => {
// export const getTransitionCoordinates = (outData, inData) => {
//   //console.log(component, nextComponent)
//   let curX = (outData.component.x) + 125;
//
//   // console.log('entryPointElement')
//   // console.log(entryPointElement)
//   //
//   // console.log('entryPointElement.offsetTop')
//   // console.log(entryPointElement.offsetTop)
//
//   let curY = (outData.component.y) + entryPointElement.offsetTop + 36 + 20; //add header + add bottom
//
//   let nextX = nextComponent.x;
//   let nextY = nextComponent.y;
//   //console.log(curX, curY, nextX, nextY);
//   let arrowPath = "M 0 0 L 6 6 L 0 12 L 0.75 6 L 0 0";
//   let refPoints = [{x: curX * 1.5, y: curY}, {x: curX * 1.5, y: nextY}];
//
//   if ((curX < nextComponent.x) && (curY < nextComponent.y)) {
//     //console.log(1)
//     //вниз-вправо
//     nextY += Math.round(outElement.offsetHeight / 2);
//     return {curX, curY, nextX, nextY, arrowPath, refPoints};
//   }
//
//   if ((curX >= nextComponent.x) && (curY < nextComponent.y)) {
//     //вниз-влево
//     //console.log(2)
//     nextY = nextComponent.y + outElement.offsetHeight / 2;
//     nextX = nextComponent.x + 250;
//     arrowPath = "M 0 6 L 12 0 L 11.75 6 L 12 12 L 0 6";
//     refPoints = [{x: curX, y: curY * 1.2}, {x: curX, y: nextY  * 1.8}];
//     return {curX, curY, nextX, nextY, arrowPath, refPoints};
//   }
//
//   if ((curX < nextComponent.x) && (curY >= nextComponent.y)) {
//     //вправо-вверх
//     //console.log(3)
//     nextY = nextComponent.y + outElement.offsetHeight / 2 + 36 + 20;
//     nextX = nextComponent.x + 125;
//     refPoints = [{x: curX, y: curY * 1.2}, {x: curX, y: nextY  * 1.8}];
//     return {curX, curY, nextX, nextY, arrowPath, refPoints};
//   }
//
//   if ((curX > nextComponent.x) && (curY >= nextComponent.y)) {
//     //влево-вверх
//     //console.log(4)
//     nextY = nextComponent.y + outElement.offsetHeight / 2 + 36 + 20;
//     nextX = nextComponent.x + 125;
//     refPoints = [{x: curX, y: curY * 1.2}, {x: curX, y: nextY  * 1.8}];
//     return {curX, curY, nextX, nextY, arrowPath, refPoints};
//   }
//
//   if ((Math.abs(curX - nextComponent.x) <= 140)) {
//     //console.log(6)
//     nextY = curY >= nextComponent.y ? nextComponent.y + outElement.offsetHeight / 2 : nextComponent.y;
//     nextX = curY >= nextComponent.y ? nextComponent.x : nextComponent.x + 125;
//     arrowPath = curY >= nextComponent.y ? "M 0 6 L 12 0 L 11.75 6 L 12 12 L 0 6" : "M 0 6 L 12 0 L 11.75 6 L 12 12 L 0 6";
//     if (curX - nextComponent.x > 0) {
//       refPoints = curY >= nextComponent.y ? [{x: curX - 200, y: curY}, {x: nextX - 200, y: nextY}] : refPoints;
//     } else {
//       refPoints = curY >= nextComponent.y ? [{x: curX + 200, y: curY}, {x: nextX + 200, y: nextY}] : refPoints;
//     }
//
//   }
//   //console.log(curX, curY, nextX, nextY);
//   return {curX, curY, nextX, nextY, arrowPath, refPoints};
// };

export const getConnectionCoordinates = (outData, inData) => {
  const [outAttachPointX, outAttachPointY] = calcAttachPointXy(outData);
  const [inAttachPointX, inAttachPointY] = calcAttachPointXy(inData);
  const quadrantIndex = calcQuadrantIndex(outAttachPointX, outAttachPointY, inAttachPointX, inAttachPointY);
  const {in: inConnectionTipDirection, out: outConnectionTipDirection}
    = struct[outData.attachPointType][inData.attachPointType][quadrantIndex];
  const conPoints = calcConnectionPoints(
    outAttachPointX, outAttachPointY,
    inAttachPointX, inAttachPointY,
    outConnectionTipDirection, inConnectionTipDirection,
  );

  return {
    curX: conPoints[0].point.x,
    curY: conPoints[0].point.y,
    nextX: conPoints[1].point.x,
    nextY: conPoints[1].point.y,
    refPoints: [{
      x: conPoints[0].ref.x,
      y: conPoints[0].ref.y,
    }, {
      x: conPoints[1].ref.x,
      y: conPoints[1].ref.y,
    }],
    arrowDirection: inConnectionTipDirection,
  };
};

export const calcAttachPointXy = data => {
  const attachPointWidth = AttachPoint.Width;    //px
  const attachPointHeight = AttachPoint.Height;    //px
  const attachPointMargin = AttachPoint.Margin;    //px

  let attachPointX = null;
  let attachPointY = null;

  const {mainElement, mainElementPosition} = data;
  const additionalElement = data.innerElement || mainElement;
  const additionalElementPosition = data.innerElementPosition || mainElementPosition;

  switch (data.attachPointType) {
    case AttachPointType.Left: {
      attachPointX =
        // data.component.x - attachPointWidth / 2 - attachPointMargin;
        mainElementPosition.x - attachPointWidth / 2 - attachPointMargin;
      attachPointY =
        additionalElementPosition.y + additionalElement.offsetHeight / 2;
      break;
    }
    case AttachPointType.Right: {
      attachPointX =
        // data.component.x + data.componentMainElement.offsetWidth + attachPointMargin + attachPointWidth / 2;
        mainElementPosition.x + mainElement.offsetWidth + attachPointMargin + attachPointWidth / 2;


      // console.log('###############################################################');
      // console.log(additionalElementPosition.y);
      // console.log(additionalElement.offsetHeight);


      attachPointY =
        // data.component.y + data.componentMainElement.offsetHeight / 2;
        additionalElementPosition.y + additionalElement.offsetHeight / 2;
      break;
    }
    case AttachPointType.Top: {
      attachPointX =
        // data.component.x + data.componentMainElement.offsetWidth / 2;
        additionalElementPosition.x + additionalElementPosition.offsetWidth / 2;
      attachPointY =
        // data.component.y - attachPointHeight / 2 - attachPointMargin;
        mainElementPosition.y - attachPointHeight / 2 - attachPointMargin;
      break;
    }
    case AttachPointType.Bottom: {
      attachPointX =
        // data.component.x + data.componentMainElement.offsetWidth / 2;
        additionalElementPosition.x + additionalElementPosition.offsetWidth / 2;
      attachPointY =
        // data.component.y + data.componentMainElement.offsetHeight + attachPointMargin + attachPointHeight / 2;
        mainElementPosition.y + mainElementPosition.offsetHeight + attachPointMargin + attachPointHeight / 2;
      break;
    }
    default: {
    }
  }

  return [attachPointX, attachPointY];
};

export const calcQuadrantIndex = (outAttachPointX, outAttachPointY, inAttachPointX, inAttachPointY) => {

  if (inAttachPointX >= outAttachPointX) {
    if (inAttachPointY >= outAttachPointY) {
      return QuadrantIndex.One;
    } else {
      return QuadrantIndex.Zero;
    }
  } else {
    if (inAttachPointY >= outAttachPointY) {
      return QuadrantIndex.Two;
    } else {
      return QuadrantIndex.Three;
    }
  }
};

export const calcConnectionPoints = (
    outAttachPointX, outAttachPointY,
    inAttachPointX, inAttachPointY,
    outConnectionTipType, inConnectionTipType,
) => {
  const attachPointWidth = AttachPoint.Width;           // px
  const attachPointHeight = AttachPoint.Height;         // px
  const refPointOffset = AttachPoint.RefPointOffset;    // px
  const connArrowLength = ConnectionArrowSize.Length;   // px

  const res = [{
    connInOutType: 'out',
    connType: outConnectionTipType,
    point: {
      x: outAttachPointX,
      y: outAttachPointY,
    },
    ref: {
      x: outAttachPointX,
      y: outAttachPointY,
    }
  }, {
    connInOutType: 'in',
    connType: inConnectionTipType,
    point: {
      x: inAttachPointX,
      y: inAttachPointY,
    },
    ref: {
      x: inAttachPointX,
      y: inAttachPointY,
    }
  }];

  res.forEach(item => {
    let deltaX = attachPointWidth / 2;
    let deltaY = attachPointHeight / 2;

    if (item.connInOutType === 'in') {
      deltaX = -(deltaX - connArrowLength);
      deltaY = -(deltaY - connArrowLength);
    }

    // ConnectionTipDirection считается относительно блока:
    // справа от блока - ConnectionTipDirection.Right
    // слева от блока - ConnectionTipDirection.Left
    // сверху от блока - ConnectionTipDirection.Top
    // снизу от блока - ConnectionTipDirection.Bottom

    if (item.connType === ConnectionTipDirection.Left) {
      item.point.x = item.point.x - deltaX;
      item.ref.x = item.point.x - refPointOffset;
    } else if (item.connType === ConnectionTipDirection.Right) {
      item.point.x = item.point.x + deltaX;
      item.ref.x = item.point.x + refPointOffset;
    } else if (item.connType === ConnectionTipDirection.Up) {
      item.point.y = item.point.y - deltaY;
      item.ref.y = item.point.y - refPointOffset;
    } else if (item.connType === ConnectionTipDirection.Down) {
      item.point.y = item.point.y + deltaY;
      item.ref.y = item.point.y + refPointOffset;
    }
  });

  return res;
};

const AttachPointType = {
  Top: 'top',
  Bottom: 'bottom',
  Left: 'left',
  Right: 'right',
};

const AttachPoint = {
  Width: 16,
  Height: 16,
  Margin: 4,
  RefPointOffset: 40,
};

const QuadrantIndex = {
  Zero: '0',
  One: '1',
  Two: '2',
  Three: '3',
};

export const ConnectionTipDirection = {
  Up: 'up',
  Right: 'right',
  Down: 'down',
  Left: 'left',

  reverse(direction) {
    switch (direction) {
      case ConnectionTipDirection.Up:
        return ConnectionTipDirection.Down;
      case ConnectionTipDirection.Down:
        return ConnectionTipDirection.Up;
      case ConnectionTipDirection.Left:
        return ConnectionTipDirection.Right;
      case ConnectionTipDirection.Right:
        return ConnectionTipDirection.Left;
      default:
        return null;
    }
  }
};

/**
 * [outAttachPoint][inAttachPoint][quadrantIndex]
 */
const struct = {
  [AttachPointType.Top]: {
    [AttachPointType.Top]: {
      [QuadrantIndex.Zero]: {
        out: ConnectionTipDirection.Right,
        in: ConnectionTipDirection.Left,
      }
    },

    [AttachPointType.Right]: {

    },

    [AttachPointType.Bottom]: {

    },

    [AttachPointType.Left]: {

    },

  },

  [AttachPointType.Right]: {
    [AttachPointType.Left]: {
      [QuadrantIndex.Zero]: {
        out: ConnectionTipDirection.Right,
        in: ConnectionTipDirection.Left,
      },
      [QuadrantIndex.One]: {
        out: ConnectionTipDirection.Right,
        in: ConnectionTipDirection.Left,
      },
      [QuadrantIndex.Two]: {
        out: ConnectionTipDirection.Right,
        in: ConnectionTipDirection.Left,
      },
      [QuadrantIndex.Three]: {
        out: ConnectionTipDirection.Right,
        in: ConnectionTipDirection.Left,
      },
    },
  },

  // Out - Bottom

  [AttachPointType.Bottom]: {
    [AttachPointType.Left]: {
      [QuadrantIndex.Zero]: {
        out: ConnectionTipDirection.Right,
        in: ConnectionTipDirection.Left,
      },
      [QuadrantIndex.One]: {
        out: ConnectionTipDirection.Down,
        in: ConnectionTipDirection.Left,
      },
      [QuadrantIndex.Two]: {
        out: ConnectionTipDirection.Down,
        in: ConnectionTipDirection.Up,
      },
      [QuadrantIndex.Three]: {
        out: ConnectionTipDirection.Left,
        in: ConnectionTipDirection.Down,
      },
    },
  },

  [AttachPointType.Left]: {

  },

};