import React, {Fragment, useState} from 'react';

import Switch from 'components/widgets/Switch';
import Checkbox from 'components/widgets/Checkbox';
import CustomSelect from 'components/widgets/CustomSelect';
import ActionButtons from './ActionButtons';
import {useOperators} from 'hooks';
import {h, getOperatorName} from 'utils';


export default function GoToLiveChatSettings(props) {
  const {currentNode, onChangeNode, t, onSave, onCancel} = props;
  const {operatorsById} = useOperators();
  const [isEmailReceiving, toggleEmailReceiving] = useState(false);
  const assignationTypeOptions = {
    'simple': {value: 'simple', label: 'chooseType.simple'},
    'default': {value: 'default', label: 'chooseType.default'}
  };

  const handleNodeChange = (data, field) => {
    onChangeNode(currentNode.content[field] ?
      {content: {[field]: {$set: data}}} : {content: {$merge: {[field]: data}}})
  };

  const _addOperatorMail = (str) => {
    handleNodeChange(str.split(', '), 'operator_mails');
  };

  const _addOperator = (id) => {
    onChangeNode(!currentNode.content.selected_operators ?
      {content: {selected_operators: {$set: [id]}}} : {content: {'selected_operators': {$push: [id]}}})
  };
  const save = () => {
    onSave();
  };

  return (
    <Fragment>
      {/*<div className="node-settings">*/}
        {/*<div className="settings-item">*/}
          {/*<div className="settings-item-label add-margin">{t('chooseType.title')}</div>*/}
          {/*<CustomSelect options={Object.values(assignationTypeOptions)}*/}
                        {/*defaultValue={assignationTypeOptions[currentNode.content.assignation_type || 'simple']}*/}
                        {/*selectClassName={`settings-select w-select`}*/}
                        {/*getOptionLabel={option => t(option.label)}*/}
                        {/*onChange={type => handleNodeChange(type.value, 'assignation_type')}/>*/}
          {/*<div className="info-text min-indent">*/}
            {/*{h('web.create.botBuilder.go_to_livechat.chooseType.simple.notice')}*/}
          {/*</div>*/}
          {/*<div className="info-text min-indent">*/}
            {/*{h('web.create.botBuilder.go_to_livechat.chooseType.default.notice')}*/}
          {/*</div>*/}
        {/*</div>*/}
      {/*</div>*/}
      {/*<div className="divider"></div>*/}
      <div className="transfer-header">
        <div className="settings-item-label">{t('selectAgents.title')}</div>
      </div>

      {Object.values(operatorsById).map((operator, i) => {
        return (
          <div>
            <Checkbox label={getOperatorName(operator, i)}
                      onChange={() => _addOperator(operator.id)}
                      checked={currentNode.content.selected_operators
                      && currentNode.content.selected_operators.includes(operator.id)}/>
          </div>
        );
      })}

      <div className="divider"></div>
      <div className="transfer-header">
        <div className="settings-item-label">{t('receiveEmail.title')}</div>
        <Switch isOn={currentNode.content.email_notification}
                onClick={() => handleNodeChange(!currentNode.content.email_notification, 'email_notification')}/>
      </div>
      {/*{currentNode.content.email_notification && (*/}
        {/*<div className="transfer-settings-w">*/}
          {/*<div className="settings-item no-margin">*/}
            {/*<div className="settings-item-label add-margin">{t('receiveEmail.label')}</div>*/}
            {/*{t('receiveEmail.placeholder', {},*/}
              {/*msg => <input type="email" value={currentNode.content.operator_mails ?*/}
                {/*currentNode.content.operator_mails.join(', ') : ''}*/}
                            {/*onChange={e => _addOperatorMail(e.target.value)}*/}
                            {/*className="node-settings-input w-input" maxLength="256" placeholder={msg}*/}
                            {/*required=""/>)}*/}
          {/*</div>*/}
        {/*</div>*/}
      {/*)}*/}
      <ActionButtons onSave={save} onCancel={onCancel}/>
    </Fragment>
  );
}
//GoToLiveChatSettings