import React, { Fragment, useEffect, useState } from 'react';
import SearchLine from 'components/widgets/SearchLine';
import {useChatFilter, useCurrentUserState, useGetCurrentOperator} from 'hooks';
import {t, getOperatorName} from 'utils';
import {CHANNELS_OPTIONS} from 'constants.js';


const chatStatusOptions = [
  { label: 'header.chatStatus.all', colorClass: null, value: null},
  { label: 'header.chatStatus.pending', colorClass: 'pending-status', value: 'pending'},
  { label: 'header.chatStatus.opened', colorClass: 'opened-status', value: 'opened'},
  { label: 'header.chatStatus.closed', colorClass: 'closed-status', value: 'closed'},
  { label: 'header.chatStatus.blocked', colorClass: 'blocked-status', value: 'blocked'}
];


export default function ChatHeader(props) {
  const {operatorsById, selectedChatId, onChangeFilters, onSearch} = props;

  const {currentUser} = useCurrentUserState();
  const [currentOperator] = useGetCurrentOperator();

  const [isChannelTypesOpen, setChannelTypesOpen] = useState(false);
  const [isOperatorsOpen, setOperatorsOpen] = useState(false);
  const [isChatStatusesOpen, setChatStatusOpen] = useState(false);

  const {channelType, selectedOperator, chatStatus, setChannelType, setSelectedOperator, setChatStatus} = useChatFilter();

  useEffect(() => {
    onChangeFilters({
      'chat.campaign_type': channelType && channelType.includedTypes.length ? channelType.includedTypes.join(',') : null,
      'chat.active_operator_ids': selectedOperator ? selectedOperator.id : selectedOperator,
      'chat.status': chatStatus ? chatStatus.value : chatStatus,
      'offset': 0
    })
  }, [channelType, selectedOperator, chatStatus]);

  const handleSearch = (value) => {
    onSearch(value);
  };

  return (
    <Fragment>
      <SearchLine onSearch={handleSearch} />
      <div className="filers-w">
        <div className="filter-drop-w">
          <div className="filter-drop w-dropdown" onMouseOver={() => setChannelTypesOpen(true)} onMouseOut={() => setChannelTypesOpen(false)}>
            <div className="filter-drop-tog w-dropdown-toggle">
              <div>{channelType && channelType.includedTypes.length ? t(channelType.label) : t('header.channel.title')}</div>
              <div className="drop-icon w-icon-dropdown-toggle"></div>
            </div>
            <nav className={`dropdown-list right-float-list w-dropdown-list ${isChannelTypesOpen ? 'w--open' : ''}`}>
              {Object.values(CHANNELS_OPTIONS).map((option, i) =>
                <div key={i} onClick={() => {
                  setChannelType(option);
                  setChannelTypesOpen(false);
                }} className="dropdown-link w-dropdown-link">
                  {t(option.label)}</div>
              )}
            </nav>
          </div>
          <div className="filter-drop w-dropdown" onMouseOver={() => setOperatorsOpen(true)} onMouseOut={() => setOperatorsOpen(false)}>
            <div className="filter-drop-tog w-dropdown-toggle">
              <div>{selectedOperator ?
                currentOperator.id === selectedOperator.id ? 'My chats' : getOperatorName(selectedOperator)
                : t('header.inbox.title')}</div>
              <div className="drop-icon w-icon-dropdown-toggle"></div>
            </div>
            <nav className={`height-250-px dropdown-list right-float-list w-dropdown-list ${isOperatorsOpen ? 'w--open' : ''}`}>
              <div onClick={() => setSelectedOperator(null)} className="dropdown-link w-dropdown-link operator-name">All</div>
              <div onClick={() => setSelectedOperator(currentOperator)} className="dropdown-link w-dropdown-link operator-name">My chats</div>
              {Object.values(operatorsById).map((operator, i) => {
                const name = getOperatorName(operator, i);
                return ( operator.user_id !== currentUser.id &&
                  <div key={i} onClick={() => {
                    setSelectedOperator(operator);
                    setOperatorsOpen(false);
                  }} className="dropdown-link w-dropdown-link operator-name">{name}</div>
                )}
             )}
            </nav>
          </div>
          <div className="filter-drop w-dropdown" onMouseOver={() => setChatStatusOpen(true)} onMouseOut={() => setChatStatusOpen(false)}>
            <div className="filter-drop-tog w-dropdown-toggle">
              <div>{chatStatus && chatStatus.value ? t(chatStatus.label): t('header.chatStatus.title')}</div>
              <div className="drop-icon w-icon-dropdown-toggle"></div>
            </div>
            <nav className={`dropdown-list right-float-list w-dropdown-list ${isChatStatusesOpen ? 'w--open' : ''}`}>
              {chatStatusOptions.map((option, i) =>
                <div key={i} className="status-link-w" onClick={() => {
                  setChatStatus(option);
                  setChatStatusOpen(false);
                }}>
                  <div className="dropdown-link w-dropdown-link">{t(option.label)}</div>
                  {(option.colorClass !== null) && (<div className={`chat-status-icon ${option.colorClass}`}></div>)}
                </div>
              )}
            </nav>
          </div>
        </div>
      </div>
    </Fragment>
  )
}