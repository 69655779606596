import React, {Fragment, useState, useEffect, useRef} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {isInt} from 'validator';

import Header from 'components/Header';
import StepChain from '../StepChain';
import ButtonDisplaySettings from './ButtonDisplaySettings';
import TriggerSettings from './TriggerSettings';
import ConditionSettings from './ConditionSettings';
import {t} from 'utils';
import {CAMPAIGN_REQUEST_TYPES} from 'constants.js';
import SubmitButton from "components/widgets/SubmitButton";
import {useCampaigns, useCurrentUserPlan} from "hooks";


export default function DisplaySettings(props) {
  const {isCreate, isCampaignSaving, type, currentStep, setCurrentStep, campaign, setCampaign, onSave} = props;
  const {isCreating, isUpdating} = useCampaigns();
  const {userCurrentPlan} = useCurrentUserPlan();
  const [errors, setErrors] = useState({scrollTriggerValue: null, periodicity: null});

  const messageConstant = 'web.create.displaySettings';
  const tabs = [
    {value: 'button', label: t(`${messageConstant}.button.${type}.tab`)},
    {value: 'trigger', label: t(`${messageConstant}.triggers.tab`)},
    {value: 'conditions', label: t(`${messageConstant}.conditions.tab`)},
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0].value); // button | trigger | conditions

  const isClickSubmitButton = useRef([false, false]);

  useEffect(() => {
    if (currentTab === 'trigger' && Object.keys(errors).some(key => key.includes('Trigger') && errors[key])) {
      const elementId = Object.keys(errors).find(key => key.includes('Trigger') && errors[key]);
      if (elementId) {
        document.getElementById(elementId).scrollIntoView();
      }
    }
  }, [currentTab]);

  const handleSettingsSave = (buttonNumber, activated) => {
    let newTriggerErrors = {periodicity: null};
    if (campaign.display_settings.triggers.is_after_scrolling_active) {
      if (!isInt('' + campaign.display_settings.triggers.after_scrolling_percents_value, {min: 1, max: 100})) {
        newTriggerErrors = {...newTriggerErrors, scrollTriggerValue: t('error.number.intFromRange')}
      }
    }
    if (campaign.display_settings.triggers.timed_trigger_seconds_value) {
      if (!isInt('' + campaign.display_settings.triggers.timed_trigger_seconds_value, {min: 0})) {
        newTriggerErrors = {...newTriggerErrors, timedTriggerValue: t('error.number.intLessZero')}
      }
    }
    if (campaign.display_settings.triggers.is_time_of_inactivity_active) {
      if (!isInt('' + campaign.display_settings.triggers.time_of_inactivity_seconds_value, {min: 0})) {
        newTriggerErrors = {...newTriggerErrors, inactivityTimedTriggerValue: t('error.number.intLessZero')}
      }
    }
    if (campaign.display_settings.triggers.is_periodicity_active) {
      if ((!isInt('' + campaign.display_settings.triggers.popup_will_appear_maximum_number_value, {min: 0})) ||
        (!isInt('' + campaign.display_settings.triggers.popup_time_between_appearances_value, {min: 0}))) {
        newTriggerErrors = {...newTriggerErrors, periodicity: t('error.number.intLessZero')}
      }
    }

    if (campaign.display_settings.triggers.is_not_respond_conversation_active) {
      if (!isInt('' + campaign.display_settings.triggers.without_responding_time_value, {min: 0})) {
        newTriggerErrors = {...newTriggerErrors, withoutRespondTriggerValue: t('error.number.intLessZero')}
      }
    }
    if (campaign.display_settings.triggers.is_not_take_conversation_active) {
      if (!isInt('' + campaign.display_settings.triggers.without_taking_time_value, {min: 0})) {
        newTriggerErrors = {...newTriggerErrors, notTakeTriggerValue: t('error.number.intLessZero')}
      }
    }

    if (Object.values(newTriggerErrors).every(error => !error)) {
      isClickSubmitButton.current[buttonNumber] = true;
      onSave(activated, isCreate);
    } else {
      setErrors({...errors, ...newTriggerErrors});
      if (Object.values(newTriggerErrors).some(error => error)) {
        if (currentTab === 'trigger') {
          const elementId = Object.keys(newTriggerErrors).find(key => key.includes('Trigger') && newTriggerErrors[key]);
          if (elementId) {
            document.getElementById(elementId).scrollIntoView();
          }
        } else {
          setCurrentTab(tabs[1].value);
        }
      }
    }
  };

  const handleCurrentStepToggle = (step) => {
    if (Object.values(errors).some(error => error)) {
      if (currentTab === 'trigger') {
        const elementId = Object.keys(errors).find(key => key.includes('Trigger') && errors[key]);
        if (elementId) {
          document.getElementById(elementId).scrollIntoView();
        }
      } else {
        setCurrentTab(tabs[1].value);
      }
    } else {
      setCurrentStep(step);
    }
  };

  return (
    <Fragment>
      <Header title={t(`${messageConstant}.${type}.header${campaign && campaign.id ? '.edit' : ''}`)}>
        {type ===  CAMPAIGN_REQUEST_TYPES.bot_web_chat && (
          <StepChain type={type} currentStep={currentStep} campaign={campaign}
                     setCurrentStep={handleCurrentStepToggle} additionalClass="no-bottom-indent"/>
        )}
      </Header>
      <div className="page-content normal-content">
        <div className="w-form">
          <div id="email-form-2" name="email-form-2" data-name="Email Form 2">
            {type ===  CAMPAIGN_REQUEST_TYPES.live_web_chat && (
              <StepChain type={type} currentStep={currentStep} campaign={campaign} setCurrentStep={setCurrentStep}/>
            )}
            <div className="page-content-header-div">
              <div className="page-header">{t(`${messageConstant}.${type}.title`)}</div>
              <div className="page-subheader">{t(`${messageConstant}.${type}.subtitle`)}</div>
            </div>
            <div className="tabs w-tabs">
              <div className="left-float-menu w-tab-menu top-0">
                {tabs.map((tab, i) =>
                  <div key={i} onClick={() => setCurrentTab(tab.value)}
                       className={`left-tab-link w-inline-block w-tab-link ${tab.value === currentTab ? 'w--current' : ''}`}>
                    <div>{tab.label}</div>
                  </div>
                )}
              </div>

                <div className="tabs-content w-tab-content">
                  {currentTab === 'button' && (
                    <ReactCSSTransitionGroup
                      transitionName="tabs-on"
                      transitionAppear={true}
                      transitionAppearTimeout={300}
                    >
                      <ButtonDisplaySettings webChatType={type} campaign={campaign} setCampaign={setCampaign}/>
                    </ReactCSSTransitionGroup>
                  )}
                  {currentTab === 'trigger' && (
                    <ReactCSSTransitionGroup
                      transitionName="tabs-on"
                      transitionAppear={true}
                      transitionAppearTimeout={300}
                    >
                      <TriggerSettings
                        webChatType={type}
                        campaign={campaign}
                        setCampaign={setCampaign}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    </ReactCSSTransitionGroup>
                  )}
                  {currentTab === 'conditions' && (
                    <ReactCSSTransitionGroup
                      transitionName="tabs-on"
                      transitionAppear={true}
                      transitionAppearTimeout={300}
                    >
                      <ConditionSettings webChatType={type} campaign={campaign} setCampaign={setCampaign}/>
                    </ReactCSSTransitionGroup>
                  )}
                </div>
            </div>
            <div className="settings-btn-w">
              {isCreate ? (
                <>
                  <SubmitButton simpleButton={true}
                                additionalClass={'blue-button w-button'}
                                disabled={type === CAMPAIGN_REQUEST_TYPES.bot_web_chat && userCurrentPlan.widget_bot_chats_limit &&
                                (userCurrentPlan.widget_bot_chats_count >= userCurrentPlan.widget_bot_chats_limit)}
                                spinnerClass={'blue-button'}
                                onClick={() => handleSettingsSave(0, true)}
                                isSending={(isCreating || isUpdating || isCampaignSaving) && isClickSubmitButton.current[0]}
                                onLoadEnd={() => {window.location = '/campaigns';}}
                                text={t(`${messageConstant}.activateNow`)}/>
                  <SubmitButton simpleButton={true} additionalClass={'left-indent w-button'}
                                onClick={() => handleSettingsSave(1, false)}
                                isSending={(isCreating || isUpdating || isCampaignSaving) && isClickSubmitButton.current[1]}
                                onLoadEnd={() => {window.location = '/campaigns';}}
                                text={t(`${messageConstant}.activateLater`)}/>
                </>
              ) : (
                <SubmitButton simpleButton={true} additionalClass={'left-indent w-button'}
                  onClick={() => handleSettingsSave(1, campaign.enabled)}
                  isSending={(isCreating || isUpdating || isCampaignSaving) && isClickSubmitButton.current[1]}
                  onLoadEnd={() => {window.location = '/campaigns';}}
                  text={t(`button.save`)}/>
              )}

            </div>
          </div>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}