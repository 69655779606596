import React from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { animateScroll as scroll } from 'react-scroll';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import setting from 'settings';


export const redirectTo = to => {
  window.location = to;
};

export const t = (id, values = {}, children = null) => {
  return (
    <FormattedMessage id={id} values={values}>
      {children ? children : msg => msg}
    </FormattedMessage>
  );
};

export const h = (id, values = {}, children = null) => {
  return (
    <FormattedHTMLMessage id={id} values={values}/>
  );
};

export const getOperatorName = (operator, additional = '') => {
  return operator?.user?.name || operator?.name || `Operator ${additional}`
};

export const getOperatorUserName = (operator, additional = '') => {
  return operator && operator.user && operator.user.name ? operator.user.name : additional;
};

export const randString = length => {
  let text = '';
  const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

export const scrollToBottom = (id = 'chat-window') => {
  scroll.scrollToBottom({
    containerId: id,
    isDynamic: true,
    smooth: false,
    duration: 0
  });
};

export const scrollToElement = (messageId, containerId = 'chat-window') => {
  if (document.getElementById(`scroll-to-${messageId}`)) {
    const headerHeight = document.getElementById('top-navbar') ? document.getElementById('top-navbar').clientHeight : 0;
    const chatHeaderHeight = document.getElementById('chat-header') ? document.getElementById('chat-header').clientHeight : 0;
    const scrollTop = document.getElementById(`scroll-to-${messageId}`).offsetTop -
      headerHeight - chatHeaderHeight - 40;
    document.getElementById(containerId).scrollTo({top: scrollTop});
  }
};

export const closeNodeByOnBlur = ({relatedTarget, currentTarget}) => {
  if (relatedTarget && relatedTarget.isEqualNode(currentTarget)) {
    return false;
  }
  let node = relatedTarget ? relatedTarget.parentNode : null;
  while (node !== null) {
    if (node.isEqualNode(currentTarget)) {
      return false;
    }
    node = node.parentNode;
  }
  return true;
};

export const addRefFocus = (ref) => {
  if (ref) {
    ref.focus();
  }
};

export const downloadFile = (data, fileName, mimeType) => {
  let blob = new Blob([data], {type: mimeType});
  let url = window.URL.createObjectURL(blob);
  let tempLink = document.createElement('a');
  tempLink.setAttribute('href', url);
  tempLink.setAttribute('download', fileName);
  tempLink.click();
};

export const addStringSplice = () => {
  if (String.prototype.splice === undefined) {
    /**
     * Splices text within a string.
     * @param {int} offset The position to insert the text at (before)
     * @param {string} text The text to insert
     * @param {int} [removeCount=0] An optional number of characters to overwrite
     * @returns {string} A modified string containing the spliced text.
     */
    String.prototype.splice = function(offset, text, removeCount=0) {
      let calculatedOffset = offset < 0 ? this.length + offset : offset;
      return this.substring(0, calculatedOffset) +
        text + this.substring(calculatedOffset + removeCount);
    };
  }
};


export const withCurrentQueryArgs = (location, ...paramFields) => {
  const queryArgs = queryString.parse(window.location.search || '');
  const queryParams = Object.keys(queryArgs)
    .filter(arg => paramFields.indexOf(arg) !== -1)
    .reduce((cur, arg) => {
      cur[arg] = queryArgs[arg];
      return cur;
    }, {});
  return `${location}?${queryString.stringify(queryParams)}`;
};

export const translateAssign = (first, second) => {
  const translate = {};
  Object.keys(first).forEach((language) => {
    translate[language] = Object.assign({}, first[language], second[language]);
  });
  return translate;
};

export const getGranularity = (filters) => {
  if (filters && filters.start_datetime && filters.end_datetime) {

    const gte = moment(filters.start_datetime);
    const lte = moment(filters.end_datetime);
    let gran = lte.diff(gte, 'days');

    if (gran <= 1) {
      return {granularity: 24, tickFormat: 'hours'};
    }
    if (1 < gran <= 6 || gran > 120) {
      return {granularity: 12, tickFormat: 'days'};
    }
    if (20 < gran <= 60) {
      return  {granularity: 18, tickFormat: 'days'};
    }
    if (60 < gran <= 120) {
      return {granularity: 24, tickFormat: 'days'};
    }
    return {granularity: gran, tickFormat: 'days'};
  } else {
    return {granularity: 24, tickFormat: 'days'};
  }
};