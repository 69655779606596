import React, { Fragment, useEffect, useState } from 'react';
import {t} from 'utils';
import {useDebounce} from "hooks";


export default function SearchLine(props) {
  const {onSearch} = props;
  const [value, setValue] = useState('');
  // State for search status (whether there is a pending API request)
  const debouncedSearchTerm = useDebounce(value, 1000);

  useEffect(
    () => {
      handleSearch(value);
    }, [debouncedSearchTerm]
  );
  const handleSearch = (value) => {
    onSearch({
      search: value
    });
  };

  const handleReset = () => {
    onSearch(null);
    setValue('')
  };
  return (
    <div className="search-form-w">
      <div className="search-form-block w-form closed-search-form">
        {t('search.line.placeholder', {},
          msg =>
            <input type="text" value={value} onChange={e => setValue(e.target.value)}
                   className="node-settings-input search-field w-input"
                   maxLength="256" placeholder={msg} id="Search"/>
          )}
        {!!value && (
          <div className="close-btn close-btn-search" onClick={handleReset}>
            <div className="close-line-1 rotate"></div>
            <div className="close-line-1"></div>
          </div>
        )}
      </div>
    </div>
  )
}

