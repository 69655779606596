import React, {useState} from 'react';


export const ChoiceCardMessage = (props) => {
  const {message, onSendMessage} = props;
  const cards = message.content.cards || [];
  const [selectedCardNumber, selectCard] = useState(0);

  const handleSlideNumberInc = () => {
    if (selectedCardNumber < cards.length - 1) {
      selectCard(selectedCardNumber + 1)
    }
  };
  const handleSlideNumberDec = () => {
    if (selectedCardNumber > 0) {
      selectCard(selectedCardNumber - 1)
    }
  };
  return (
    <div className="width-250">
      <div data-animation="slide" className="node-settinsg-slider-w no-padding2 w-slider in-chat">
        <div className="cards-node-masc w-slider-mask">
          {cards.map((card, i) => {
            return (selectedCardNumber === i ? (
            onSendMessage ? (
              <Card key={i} card={card} i={i} onSendMessage={onSendMessage}/>
            ) : (
              <Card key={i} card={card} i={i}/>
            )) : null
            );
          })}
        </div>
        {selectedCardNumber > 0 && (
          <div className="slider-arrow in-chat w-slider-arrow-left" onClick={handleSlideNumberDec}>
            <div className="w-icon-slider-left"></div>
          </div>
        )}
        {selectedCardNumber < cards.length - 1 && (
          <div className="slider-arrow right-arrow in-chat w-slider-arrow-right" onClick={handleSlideNumberInc}>
            <div className="w-icon-slider-right"></div>
          </div>
        )}
      </div>
    </div>
  );
};


const Card = (props) => {
  const {card, i, onSendMessage} = props;
  const mime = require('mime-types');

  let linkHtml = '';

  if (card.attachment && card.attachment.type && card.attachment.type !== 'file') {
    linkHtml = <a href={card.attachment.link} className="dn-message-text link">{card.attachment.link}</a>;

    if (card.attachment.link && (card.attachment.link.includes('youtube') || card.attachment.link.includes('youtu.be'))) {
      let link = card.attachment.link;

      if (card.attachment.link.includes('https://youtu.be/')) {
        link = `https://www.youtube.com/embed/${card.attachment.link.slice('https://youtu.be/'.length)}?controls=0`;
      }
      if (card.attachment.link.includes('https://www.youtube.com/watch?v=')) {
        link = `https://www.youtube.com/embed/${card.attachment.link.slice('https://www.youtube.com/watch?v='.length)}?controls=0`;
      }
      linkHtml = <iframe class="iframe-in-message margin-attachment-bottom" src={link} width="100%"
                frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen={false}></iframe>
    }

    if (card.attachment.link && mime.lookup(card.attachment.link) && mime.lookup(card.attachment.link).includes('image')) {
      linkHtml = <img className="iframe-in-message margin-attachment-bottom" src={card.attachment.link}/>
    }

    if (card.attachment.link && mime.lookup(card.attachment.link) && mime.lookup(card.attachment.link).includes('video') &&
      (mime.lookup(card.attachment.link).includes('mp4') || mime.lookup(card.attachment.link).includes('ogg') || mime.lookup(card.attachment.link).includes('webm'))) {
      linkHtml = <video className="iframe-in-message margin-attachment-bottom video-in-node" src={card.attachment.link}/>
    } 
  }

  return (
    <div className="card-slide w-slide">
      <div className="node-settings">
        {card.attachment && card.attachment.type && (
          <>
          {card.attachment.type === 'file' ? (
            <div className="settings-item add-margin">
              <div style={{backgroundImage: `url('${(card.attachment.file.url || card.attachment.file.preview)}')`}}
                   className="dn-media-w img-on-bg"></div>
            </div>
          ) : (
            <div className="dn-content-item word-break-all">
              <div className="dn-message-w">
                {linkHtml}
              </div>
            </div>
          )}
          </>
        )}
        {card.title && (
          <div className="dn-content-item">
            <div className="dn-message-w">
              <div className="dn-title">{card.title}</div>
            </div>
          </div>
        )}
        {card.description && (
          <div className="dn-content-item">
            <div className="dn-message-w">
              <div className="dn-message-text">{card.description}</div>
            </div>
          </div>
        )}
        {card.url && (
          <div className="dn-content-item">
            <div className="dn-message-w">
              <a href={card.url} className="dn-message-text link">{card.url}</a></div>
          </div>
        )}
        <div className="settings-item">
          {card.buttons && card.buttons.map((button, buttonInd) => {
            return (onSendMessage ? (
                <div onClick={() => onSendMessage({text: button.text})} className="dn-button" key={buttonInd}>
                  <div className="dn-button-text">{button.text}</div>
                </div>
              ) : (
                <div className="dn-button" key={buttonInd}>
                  <div className="dn-button-text">{button.text}</div>
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};