import React, {useState, useCallback, useEffect} from 'react';
import {useSelector, useDispatch, shallowEqual } from 'react-redux';
import * as actions from 'actions';


export const useCurrentUser = () => {
  const {currentUser} = useSelector(state => state.users, shallowEqual);
  const dispatch = useDispatch();
  const getCurrentUser = useCallback(() => dispatch(actions.fetchCurrentUser()), [dispatch]);

  return {currentUser, getCurrentUser};
};

export const useCurrentUserState = () => {
  const dispatch = useDispatch();
  const {currentUser} = useSelector(state => state.users, shallowEqual);
  const isCurrentUserFetching = useSelector(state => state.users._isCurrentUserFetching, shallowEqual);
  const updateCurrentUser = useCallback(data => dispatch(actions.updateCurrentUser(data)), [dispatch]);
  return {currentUser, updateCurrentUser, isCurrentUserFetching};
};

export const useGoogleAuth = () => {
  const dispatch = useDispatch();

  const {currentUser} = useSelector(state => state.users, shallowEqual);
  const isGoogleAuthUrlFetching = useSelector(state => state.users._isGoogleAuthUrlFetching, shallowEqual);
  const isGoogleSignOutProcessing = useSelector(state => state.users._isGoogleSignOutProcessing, shallowEqual);

  const getGoogleAuthUrl = useCallback(redirectUrl => dispatch(actions.fetchGoogleAuthUrl({
    user_id: currentUser.id,
    redirect_url: redirectUrl
  })), [dispatch]);
  const signOut = useCallback(() => dispatch(actions.googleAuthSignOut()), [dispatch]);

  return {getGoogleAuthUrl, isGoogleAuthUrlFetching, signOut, isGoogleSignOutProcessing};
};