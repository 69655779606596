import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import {CSSTransition} from 'react-transition-group';

import SubmitButton from 'components/widgets/SubmitButton';
import AuthPageContainer from 'components/widgets/AuthPageContainer';
import {useAuthRequests} from 'hooks';
import { t, redirectTo, getAccessToken, setAccessToken, setAccessSessionToken, withCurrentQueryArgs,
  getErrorText, isEmpty, isEmailValid} from 'utils';
import settings from 'settings';


export default function SignIn() {
  const {error, isFetching, isSuccess, setError, onSubmit} = useAuthRequests();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    if (getAccessToken()) {
      redirectTo('/');
    }
    setPageLoading(true);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEmpty(email) || isEmpty(password)) {
      setError(t('error.field.empty'));
      return;
    }
    if (!isEmailValid(email)) {
      setError(t('error.field.empty'));
      return;
    }
    const data = { email, password };
    const headers = { headers: {} };
    onSubmit('/auth/signin', data, headers, (data) => {
      setTimeout(() => {
        if (rememberMe) {
          setAccessToken(data.access_token);
        } else {
          setAccessSessionToken(data.access_token);
        }
        redirectTo(queryString.parse(window.location.search).next || '/');
      }, 3000);
    });
  };

  return (
    <AuthPageContainer>
      <CSSTransition
        in={pageLoading}
        timeout={300}
        classNames="register-nav-w-animation"
        unmountOnExit
      >
        <div className="register-nav-w">
          <div className="register-nav-container">
            <a href={`${settings.LANDING_URL}/`} className="nav-logo w-nav-brand">
              <img src={require('styles/images/logo-heycom.svg')} alt="" className="sign-logo-img"/></a>
            <div className="register-nav-block">
              <div className="register-nav-text">{t('signIn.register.label')}</div>
              <Link to={withCurrentQueryArgs('/signup', 'next', 'lang')} className="sign-nav-link">
                {t('signIn.register.link')}
              </Link>
            </div>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={pageLoading}
        timeout={300}
        classNames="register-wrapper-animation"
        unmountOnExit
      >
        <div className="register-wrapper">
          <div className="register-col-1 center-align">
            <div className="sign-info-header">Welcome back!</div>
            <div className="welcome-text-div">
              <div className="sign-info-subheader">Wish you happy chatting</div>
              <div className="emoji-icon"></div>
            </div>
          </div>
          <div className="register-col-2">
            <div className="sign-heading-div">
              <div className="sign-heading">Sign in to use your Heycom account</div>
            </div>
            <div className="form-block-4 w-form">
              <form className="register-form" onSubmit={handleSubmit}>
                <div className="google-signup-w">
                  <a href="#" className="google-btn w-inline-block">
                    <img src={require('styles/images/google.svg')} alt="" className="google-icon"/>
                    <div>Sign in with Google</div>
                  </a>
                  <div className="or-wrap">
                    <div className="grey-line"></div>
                    <div>or</div>
                    <div className="grey-line"></div>
                  </div>
                </div>
                {t('email.placeholder', {},
                  msg => <input type="email" className="text-field add-indent w-input" maxLength="256" placeholder={msg}
                                required="" value={email} onChange={e => setEmail(e.target.value)}/>)}
                {t('password.placeholder', {},
                  msg => <input type="password" className="text-field add-indent w-input" maxLength="256" placeholder={msg} required=""
                                value={password} onChange={e => setPassword(e.target.value)}/>)}
                <div className="form-div-2">
                  <label className="w-checkbox checkbox-field-3">
                    <div className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox-4 ${rememberMe ? 'w--redirected-checked' : ''}`}></div>
                    <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox"
                           className="remember-checkbox-input" onChange={e => setRememberMe(!rememberMe)}/>
                    <span className="checkbox-label w-form-label">Remember me</span>
                  </label>
                  <Link to={withCurrentQueryArgs('/password-recovery/reset', 'next', 'lang')} className="sign-link-3">
                    {t('signIn.forgotPassword.link')}</Link>
                </div>
                <SubmitButton simpleButton={true} additionalClass={'full-w'} isSending={isFetching} text={t('signIn.button')}/>
              </form>
              {isSuccess && (
                <div className="w-form-done display-none">
                  <div>{t('message.success')}</div>
                </div>
              )}
              {error && (
                <div className="w-form-fail error-message-2 display-block">
                  <div>{error}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </AuthPageContainer>
  );
}