import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import PasswordRecovery from './PasswordRecovery';

export default compose(
  connect((state, ownProps) => {
      return {
        messages: []
      };
    }
  ),

  lifecycle({
    componentDidMount() {
      document.title = 'Password Recovery | HeyCom'

      console.log('PasswordRecovery componentDidMount')
    },
  }),

  withHandlers()
) (
  PasswordRecovery
);