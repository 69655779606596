import React from 'react';


export const ChoiceButtonMessage = (props) => {
  const {message, onSendMessage} = props;
  if (!message.content.buttons) {
    return null;
  }
  return (
  message.content.buttons.map((button, i) => {
    return (onSendMessage ? (
        <div key={i} onClick={() => onSendMessage({text: button.text})} className="dn-button in-chat w-inline-block">
          <div className="dn-button-text blue-color">{button.text}</div></div>
      ) : (
        <div key={i} className="dn-button in-chat w-inline-block">
          <div className="dn-button-text blue-color">{button.text}</div></div>
      )
    );
  })
  );
};
