import React, { useState } from 'react';

export default function GradientPicker(props)  {
  const {colors, onChangeSettings} = props;
  const [isColorThemesOpen, openColorThemes] = useState(false);

  const gradients = [
    ['#5bdfdf', '#45afca'],
    ['#e9ae4e', '#a56d13'],
    ['#45c767', '#008823'],
    ['#8664e9', '#4517ca'],
    ['#d76dec', '#ab1ac2']
  ];

  const dropdownGradiends = [
    ['#D0021B', '#F81B36'],
    ['#FF9F00', '#FFBD50'],
    ['#B8E986', '#A4FF48'],
    ['#4A4A4A', '#303030'],
    ['#9013FE', '#9153C7']
  ];

  return (
    <div className="color-themes-w">
      {gradients.map((grad, i) => {
          return (
            <div key={i} className="color-item" style={{background: `linear-gradient(115deg, ${grad[0]}, ${grad[1]})`}}
                 onClick={() => onChangeSettings(grad)}>
              {colors && colors.length > 1
              && colors[0] === grad[0] && (
                <img src={require('styles/images/tick-sign.svg')} alt=""/>
              )}
            </div>
          )
      })}

      <div className="filter-drop more-color w-dropdown"
           onMouseOut={e => openColorThemes(false)}
           onMouseOver={e => openColorThemes(true)}>
        <div className="filter-drop-tog color-toggle w-dropdown-toggle">
          <div></div>
        </div>
        <nav className={`dropdown-list right-float-list w-dropdown-list ${isColorThemesOpen ? 'w--open' : ''}`}>
          <div className="color-theme-div">
            {dropdownGradiends.map((grad, i) => {
                return (
                  <div key={i} className="color-item" style={{background: `linear-gradient(115deg, ${grad[0]}, ${grad[1]})`}}
                       onClick={() => onChangeSettings(grad)}>
                    {colors && colors.length > 1
                    && colors[0] === grad[0] && (
                      <img src={require('styles/images/tick-sign.svg')} alt=""/>
                    )}
                  </div>
                )
            })}
          </div>
        </nav>
      </div>
    </div>
  );
}