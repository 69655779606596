import client from 'api/client';
import {ANALYTICS_TYPES, CHANNELS_OPTIONS} from 'constants.js'


export const FETCH_ANALYTICS__R = 'FETCH_ANALYTICS__R';
export const fetchAnalyticsRq = () => {
  return {
    type: FETCH_ANALYTICS__R
  };
};
export const FETCH_ANALYTICS__S = 'FETCH_ANALYTICS__S';
export function fetchAnalyticsSc(analytics) {
  return {
    payload: analytics,
    type: FETCH_ANALYTICS__S
  };
}
export const FETCH_ANALYTICS__F = 'FETCH_ANALYTICS__F';
export function fetchAnalyticsFl(error) {
  return {
    payload: error,
    type: FETCH_ANALYTICS__F
  };
}
export const FETCH_ANALYTICS = 'FETCH_ANALYTICS';
export const fetchAnalytics = (params, analyticsType, tickFormat) => (dispatch, getState) => {
  dispatch(fetchAnalyticsRq());

  let url = '';
  if (analyticsType === Object.values(ANALYTICS_TYPES)[0].value) {
    url = '/analytics/chats_messages';
  }
  if (analyticsType === Object.values(ANALYTICS_TYPES)[1].value) {
    url = '/analytics/managers';
  }
  if (analyticsType === Object.values(ANALYTICS_TYPES)[2].value) {
    url = '/analytics/campaigns';
  }
  if (analyticsType === Object.values(CHANNELS_OPTIONS)[3].value) {
    url = '/analytics/facebook_messenger';
  }
  return client.get(url, {
    params: {
      ...params
    }
  })
    .then(data => {
      if (analyticsType === Object.values(ANALYTICS_TYPES)[2].value) {
        data = {'campaign_messages': data};
      }
      dispatch(fetchAnalyticsSc({data: data, type: analyticsType, tickFormat}));
    })
    .catch(error => {
      dispatch(fetchAnalyticsFl(error))
    });
};

export const SET_ANALYTICS_TYPE_FILTER = 'SET_ANALYTICS_TYPE_FILTER';
export function setAnalyticsTypeFilter(value) {
  return {
    payload: value,
    type: SET_ANALYTICS_TYPE_FILTER,
  }
}