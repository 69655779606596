import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import update from 'immutability-helper';
import queryString from 'query-string';

import {useChats, useOperators, useChatState, useStateWidgets} from 'hooks';
import Chat from './Chat';
import ChatHeader from './ChatHeader';
import ChatList from './ChatList';
import Header from 'components/Header';
import IntlWrapper from 'components/widgets/IntlWrapper';
import {t} from 'utils';
import {main, chats} from 'translate';
import Loader from 'components/widgets/Loader';


export default function Chats(props) {
  const {operatorStartTyping, operatorEndTyping} = props;
  const {currentWidgetId, isWidgetFetching} = useStateWidgets();
  const {chatsById, chat, searchResults, clientId, chatFilters,
    selectedSearchResult, setSelectedSearchResult, clearChat,
    setChatFilters, clearSearch, getChatMessages, setChatId} = useChats(props);
  const {operatorsById} = useOperators();
  //const [chatFilters, setChatFilters] = useState({sort: '-created_at', limit: 30, offset: 0});
  const [searchMode, setSearchMode] = useState(false);

  const [localWidgetId, setWidgetId] = useState(null);

  useEffect(() => {
    return () => {
      clearChat();
    }
  }, []);


  useEffect(() => {
    if (currentWidgetId && currentWidgetId !== localWidgetId) {
      if (chat?.widget_id !== localWidgetId) {
        setWidgetId(currentWidgetId)
      }

      if (chatsById && Object.values(chatsById).length === 0) {
        setWidgetId(currentWidgetId)
      }
    }
  }, [currentWidgetId, localWidgetId, chat, chatsById]);


  if ((chatsById && Object.values(chatsById)[0] && !props.match.params.id && !clientId)){
    return (
      <Redirect to={`/chats/${Object.values(chatsById)[0].id}`}/>
    )
  }

  const handleSelectSearch = (value) => {
    setSelectedSearchResult(value)
  };

  const handleFiltersChange = (filters) => {
    setChatFilters(update(chatFilters, {
      $merge: filters
    }));
  };

  const handleSearch = (value) => {
    if (value && value.search) {
      setSearchMode(true);
      setChatFilters(update(chatFilters, {
        $merge: {...value, offset: 0}
      }));
      // searchChatMessages({
      //   ...chatFilters,
      //   ...value
      // });
    } else {
      if (searchMode) {
        setSearchMode(false);
        clearSearch();
        setChatFilters(update(chatFilters, {
          //offset: {$set: 0}, //chat ? chat.id : 0},
          $unset: ['search']
        }));
      }
    }
  };

  //console.log(chatsById, isWidgetFetching, currentWidgetId, chat)
  //console.log(localWidgetId, currentWidgetId, props.match.params.id, chat?.id)
  if ((props.match.params.id !== chat?.id && (localWidgetId && currentWidgetId && localWidgetId !== currentWidgetId))){
    return (
      <Redirect to={`/chats/`}/>
    )
  }

  if (!chatsById || (isWidgetFetching || (chat && chat.widget_id !== currentWidgetId))) {
    return (<Loader isSmall={true}/>);
  }

  if ((Object.values(chatsById)?.length === 0) && props.match.params.id && (localWidgetId === currentWidgetId)){
    return (
      <Redirect to={`/chats`}/>
    )
  }

  return (
    <IntlWrapper messages={[main, chats]}>
      <Header title={t('title')}>
        <ChatHeader onChangeFilters={handleFiltersChange} operatorsById={operatorsById}
                    selectedChatId={chat ? chat.id : null}
                    onSearch={handleSearch}/>
      </Header>
      <div className="page-content chats">
        <ChatList onChangeFilters={handleFiltersChange}
                  selectedChat={chat}
                  clientId={clientId}
                  onSelectChat={setChatId}
                  chatsById={chatsById}
                  searchMode={searchMode}
                  selectedSearchResult={selectedSearchResult}
                  searchResults={searchResults}
                  onSelectSearchResult={handleSelectSearch} />
        {chatsById && operatorsById && Object.keys(chatsById).length ? (
          <Chat chat={chat || {}} chatId={chat ? chat.id : null} operatorsById={operatorsById}
                searchMode={searchMode} selectedSearchResult={selectedSearchResult} getChatMessages={getChatMessages}
                onResetSelectSearchResult={() => setSelectedSearchResult(null)}
                operatorStartTyping={operatorStartTyping} operatorEndTyping={operatorEndTyping}
                typing={chat && chatsById[chat.id] ? chatsById[chat.id].typing || {} : {}}/>
        ) : (
          <div className="create-campaign-container">
            <div className="create-campaign-header">Create your first conversational campaign</div>
            <div className="create-campaign-element-wrapper">
              <div className="create-campaign-element">
                <img src={require(`styles/images/comment.svg`)} alt=""/>
                <Link to="/campaigns/create/web-chat" className="filter-drop-tog w-dropdown-toggle"><span className="awesome-icon">+</span> 
                    Web chat</Link>
              </div>
              <div className="create-campaign-element">
                <img src={require(`styles/images/target.svg`)} alt=""/>
                <Link to="/campaigns/create/landbot" className="filter-drop-tog w-dropdown-toggle"><span className="awesome-icon">+</span> 
                    Landing Bot</Link>
              </div>
              <div className="create-campaign-element">
                <img src={require(`styles/images/facebook-messenger-02.svg`)} alt=""/>
                <Link to="/campaigns/create/facebook-messenger" className="filter-drop-tog w-dropdown-toggle"><span className="awesome-icon">+</span> 
                    Messenger chat</Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </IntlWrapper>
  );
}