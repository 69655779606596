import React, {useEffect} from 'react';
import IntlWrapper from 'components/widgets/IntlWrapper';

import {assistants} from 'translate';
import Header from "../../Header";
import {t} from "../../../utils";
import client from 'api/client'
import {useStateWidgets} from "../../../hooks";
import {useState} from "react";
import {Link, useParams} from "react-router-dom";
import Switch from "../../widgets/Switch";
import moment from "moment";
import {CAMPAIGN_TYPES} from "../../../constants";
import CampaignsList from "../Campaigns/CampaignsList";
import ErrorBlock from "../../widgets/ErrorBlock";
import update from "immutability-helper";
import FileLoader from "../../widgets/FileLoader";


export default function Assistant(props) {
  const messages = [assistants];
  const {currentWidgetId} = useStateWidgets();

  const {assistantId} = useParams();

  const [assistant, setAssistant] = useState(null);

  const loadAssistant = () => {
    client.get(`/widgets/${currentWidgetId}/assistants/${assistantId}`, {}).then(data => {
      setAssistant(data)
    })
  }

  useEffect(() => {
    // console.log('Use effect')
    loadAssistant()
    loadCampaigns()
  }, [])


  const updateAssistantName = () => {
    client.patch(`/widgets/${currentWidgetId}/assistants/${assistantId}`, {
      name: assistant.name,
      campaign_ids: assistant.campaign_ids,
    }).then(data => {
      //
    })
    setAssistant({...assistant})
  }


  const [text, setText] = useState('');

  const handleInputChange = (event) => {
    setText(event.target.value)
  };

  const addTextItem = () => {
    client.post(`/widgets/${currentWidgetId}/assistants/${assistantId}/contexts/text`, {
      text_context: text
    }).then(data => {
      setAssistant({...assistant})
    })
  }

  const [selectedTab, setSelectedTab] = useState('table')
  const [tabs, setTabs] = useState([
    {
      code: 'table',
      label: 'Question - Answer',
    },
    {
      code: 'files',
      label: 'Uploaded files',
    },
    {
      code: 'sites',
      label: 'Parsed sites',
    },
    {
      code: 'templates',
      label: 'Answer templates',
    },
    {
      code: 'campaigns',
      label: 'Campaigns',
    },
  ]);

  const [qa, setQa] = useState([])

  useEffect(() => {
    if (assistant) {
      setQa(assistant.contexts.qa)
    }

  }, [assistant])


  const tableHeaders = ['question', 'answer', 'actions'];

  const addEmptyRow = () => {
    assistant.contexts.qa.push({
      question: '',
      answer: '',
    })
    setAssistant({...assistant})
  }

  const removeRow = (row) => {
    const index = assistant.contexts.qa.indexOf(row)
    assistant.contexts.qa.splice(index, 1)
    setAssistant({...assistant})
  }

  const saveQATable = () => {
    client.post(`/widgets/${currentWidgetId}/assistants/${assistantId}/contexts/qa`, {
      qa_list: assistant.contexts.qa
    }).then(data => {
      setAssistant({...assistant})
    })
  }

  const QATableContainer = () => {
    return (
      <div>
        <div className=" table-container content-white-block">
          <div className="table-w ">
            <div className="table-row table-header">
              {tableHeaders.map(header =>
                <div key={header} className="table-col1">
                  <div className="details-header">{`${header}`}</div>
                </div>
              )}
            </div>
            <div className="table-content">
              {qa.map((qaItem, index) =>
                <div key={index} className="table-row">


                  <div className="table-col1">
                  <textarea className="textarea w-input" value={qaItem.question} onChange={(e) => {
                    qaItem.question = e.target.value;
                    setQa([...qa])
                  }}></textarea>
                  </div>
                  <div className="table-col1 ">
                  <textarea className="textarea w-input" value={qaItem.answer} onChange={(e) => {
                    qaItem.answer = e.target.value;
                    setQa([...qa])
                  }}></textarea>
                  </div>

                  {/*<div className="action-btn delete-btn w-inline-block" onClick={() => handleDeleteCampaignClick(assistant.id)}>*/}
                  {/*  <div><span className="awesome-icon">&nbsp;</span>*/}
                  {/*    {t('assistants.action.delete')}*/}
                  {/*  </div>*/}
                  {/*</div>*/}


                  <div className="table-col1 " style={{display: 'flex', alignItems: 'flex-start'}}>
                    <button className="action-btn delete-btn w-inline-block" onClick={() => {
                      removeRow(qaItem)
                    }}><div><span className="awesome-icon">&nbsp;</span>Remove </div>
                    </button>
                  </div>


                </div>
              )}
            </div>

          </div>

        </div>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <button className="button blue-button" onClick={addEmptyRow}>Add new QA</button>
          <button className="button " onClick={saveQATable}>Save</button>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>


    )
  }

  // FILES UPLOADING

  const deleteContext = (contextId, context_type = 'txt_context') => {
    client.delete(`/widgets/${currentWidgetId}/assistants/${assistantId}/contexts/${contextId}`, {
      params: {
        context_type: context_type
      }
    }).finally(data => {
      loadAssistant()
    })
  }

  const filesTableHeaders = ['filename', 'content', 'actions'];
  const [file, setFile] = useState(null)
  const [fileUploadingError, setFileUploadingError] = useState(null)

  useEffect(() => {
    if (!file) {
      return
    }
    console.log('FILE: ', file, file.name)
    if (file.name.endsWith('.txt') || file.name.endsWith('.docx')) {
      setFileUploadingError('')
      // if (file.name.endsWith('.txt')) {
      const formData = new FormData()
      formData.append("file", file)
      client.post(`/widgets/${currentWidgetId}/assistants/${assistantId}/contexts/text_file`, formData).then(data => {
      })
      // }

    } else {
      setFile(null)
      setFileUploadingError("Only .txt and .docx files can be downloaded")
    }
  }, [file])

  const FilesContainer = () => (
    <div>
      <div className=" table-container content-white-block">
        <div className="table-w ">
          <div className="table-row table-header">
            {filesTableHeaders.map(header =>
              <div key={header} className="table-col1">
                <div className="details-header">{`${header}`}</div>
              </div>
            )}
          </div>
          <div className="table-content">
            {assistant.contexts.texts.map((file, index) =>
              <div key={index} className="table-row">


                <div className="table-col1">
                  {file.filename ? file.filename : '<without name>'}
                </div>
                <div className="table-col1 ">
                  <textarea className="textarea w-input" value={file.content} onChange={(e) => {
                    file.content = e.target.value;
                    // setQa([...qa])
                  }} readOnly={true}></textarea>
                </div>
                <div className="table-col1 " style={{display: 'flex', alignItems: 'flex-start'}}>
                  <button className="action-btn delete-btn w-inline-block" onClick={() => {
                    deleteContext(file.id)
                  }}><div><span className="awesome-icon">&nbsp;</span>Remove </div>
                  </button>
                </div>


              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <FileLoader
          loadFile={(file) => {
            setFile(file)
          }}
          setErrors={() => {
          }}
          children={({getRootProps, getInputProps}) => {
            return (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <button className="button blue-button">Upload file (txt, docx)</button>
              </div>
            );
          }}/>
        <ErrorBlock error={fileUploadingError}></ErrorBlock>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  )

  const sitesTableHeaders = ['title', 'url', 'content', 'actions'];
  const [url, setUrl] = useState(null)
  const [urlError, setUrlError] = useState(null)


  const addSite = () => {
    if (!url) {
      setUrlError('URL cannot be empty')
    } else {
      setUrlError(null)
      setUrl('')
      client.post(`/widgets/${currentWidgetId}/assistants/${assistantId}/contexts/url`, {
        url,
      }).then(data => {
        loadAssistant()
      })
    }
  }

  const SitesContainer = () => (
    <div>
      <div className=" table-container content-white-block">
        <div className="table-w ">
          <div className="table-row table-header">
            {sitesTableHeaders.map(header =>
              <div key={header} className="table-col1">
                <div className="details-header">{`${header}`}</div>
              </div>
            )}
          </div>
          <div className="table-content">
            {assistant.contexts.sites.map((site, index) =>
              <div key={index} className="table-row">
                <div className="table-col1">
                  {site.title}
                </div>
                <div className="table-col1">
                  {site.url}
                </div>
                <div className="table-col1 ">
                  <textarea className="textarea w-input" value={site.content} readOnly={true}></textarea>
                </div>
                <div className="table-col1 " style={{display: 'flex', alignItems: 'flex-start'}}>
                  <button className="action-btn delete-btn w-inline-block" onClick={() => {
                    deleteContext(site.id, 'url_context')
                  }}><div><span className="awesome-icon">&nbsp;</span>Remove </div>
                  </button>
                </div>


              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{display: "flex", justifyContent: "flex-start"}}>
        <input className="node-settings-input input w-input " style={{maxWidth: '300px'}} placeholder="Enter site url" value={url}
               onInput={(e) => {
                 setUrl(e.target.value)
               }}/>
        <div style={{marginLeft: "20px"}}>
          <button className="button blue-button" onClick={addSite}>Add site page</button>
        </div>
      </div>
      <ErrorBlock error={urlError}></ErrorBlock>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  )

  const templatesTableHeaders = ['content', 'parameters', 'actions'];
  const parameters = [
    {
      group: "Emotions",
      param: "emotions",
      variants: [
        {
          label: "Do not use",
          value: null,
        },
        {
          label: "Sadness",
          value: "sadness",
        },
        {
          label: "Joy",
          value: "joy",
        },
        {
          label: "Love",
          value: "love",
        },
        {
          label: "Anger",
          value: "anger",
        },
        {
          label: "Fear",
          value: "fear",
        },
        {
          label: "Surprise",
          value: "surprise",
        },
      ]
    },
    {
      group: "Intent",
      param: "intent",
      variants: [
        {
          label: "Do not use",
          value: null,
        },
        {
          label: "Cancellation",
          value: "cancellation",
        },
        {
          label: "Ordering",
          value: "ordering",
        },
        {
          label: "Shipping",
          value: "shipping",
        },
        {
          label: "Invoicing",
          value: "invoicing",
        },
        {
          label: "Billing and payment",
          value: "billing and payment",
        },
        {
          label: "Returns and refunds",
          value: "returns and refunds",
        },
        {
          label: "Complaints and feedback",
          value: "complaints and feedback",
        },
        {
          label: "Speak to person",
          value: "speak to person",
        },
        {
          label: "Edit account",
          value: "edit account",
        },
        {
          label: "Delete account",
          value: "delete account",
        },
        {
          label: "Delivery information",
          value: "delivery information",
        },
        {
          label: "Subscription",
          value: "subscription",
        },
        {
          label: "Recover password",
          value: "recover password",
        },
        {
          label: "Registration problems",
          value: "registration problems",
        },
        {
          label: "Appointment",
          value: "appointment",
        },
      ]
    },
    {
      group: "Sentiments",
      param: "sentiments",
      variants: [
        {
          label: "Do not use",
          value: null,
        },
        {
          label: "Positive",
          value: "positive",
        },
        {
          label: "Neutral",
          value: "neutral",
        },
        {
          label: "Negative",
          value: "negative",
        },

      ]
    },
    {
      group: "Topic",
      param: "topic",
      variants: [
        {
          label: "Do not use",
          value: null,
        },
        {
          label: "Arts & culture",
          value: "arts_&_culture",
        },
        {
          label: "Business & entrepreneurs",
          value: "business_&_entrepreneurs",
        },
        {
          label: "Celebrity & pop culture",
          value: "celebrity_&_pop_culture",
        },
        {
          label: "Diaries & daily life",
          value: "diaries_&_daily_life",
        },
        {
          label: "Family",
          value: "family",
        },
        {
          label: "Fashion & style",
          value: "fashion_&_style",
        },
        {
          label: "Film tv & video",
          value: "film_tv_&_video",
        },
        {
          label: "Fitness & health",
          value: "fitness_&_health",
        },
        {
          label: "Food & dining",
          value: "food_&_dining",
        },
        {
          label: "Gaming",
          value: "gaming",
        },
        {
          label: "Learning & educational",
          value: "learning_&_educational",
        },
        {
          label: "Music",
          value: "music",
        },
        {
          label: "News & social concern",
          value: "news_&_social_concern",
        },
        {
          label: "Other hobbies",
          value: "other_hobbies",
        },
        {
          label: "Relationships",
          value: "relationships",
        },
        {
          label: "Science & technology",
          value: "science_&_technology",
        },
        {
          label: "Sports",
          value: "sports",
        },
        {
          label: "Travel & adventure",
          value: "travel_&_adventure",
        },
        {
          label: "Youth & student life",
          value: "youth_&_student_life",
        },

      ]
    },
    {
      group: "Toxic",
      param: "toxic",
      variants: [
        {
          label: "Do not use",
          value: null,
        },
        {
          label: "Neutral",
          value: "neutral",
        },
        {
          label: "Toxic",
          value: "toxic",
        },


      ]
    },
  ]


  const addNewTemplate = () => {
    client.post(`/widgets/${currentWidgetId}/assistants/${assistantId}/templates`, {
      emotions: null,
      intent: null,
      sentiments: null,
      topic: null,
      toxic: null,
      result: 'Your answer: {answer}',
    }).then(data => {
      loadAssistant()
    })
  }

  const updateTemplate = (templateId, template, silence = false) => {
    client.patch(`/widgets/${currentWidgetId}/assistants/${assistantId}/templates/${templateId}`, template).then(data => {
      if (!silence) {
        loadAssistant()
      }

    })
  }

  const removeTemplate = (templateId) => {
    client.delete(`/widgets/${currentWidgetId}/assistants/${assistantId}/templates/${templateId}`).then(data => {
      loadAssistant()
    })
  }

  const TemplatesContainer = () => {
    return (
      <div>
        <div className=" table-container content-white-block">
          <div className="table-w ">
            <div className="table-row table-header">
              {templatesTableHeaders.map(header =>
                <div key={header} className="table-col1">
                  <div className="details-header">{`${header}`}</div>
                </div>
              )}
            </div>
            <div className="table-content">
              {assistant.templates.map((template, index) => {
                return (
                  <div key={index} className="table-row">
                    <div className="table-col1">
                      <textarea className="textarea w-input" value={template.template.result} onChange={(e) => {
                        template.template.result = e.target.value;
                        setAssistant({...assistant})
                        updateTemplate(template.id, template.template, true)
                      }} style={{height: '100%'}}></textarea>
                    </div>
                    <div className="table-col1" >
                      <div>
                        {parameters.map((paramGroup) => {
                          return (
                            <div>
                              <div><b>{paramGroup.group}</b></div>
                              <div style={{display: "flex", whiteSpace: "nowrap", flexWrap: "wrap"}}>
                                {paramGroup.variants.map((variant, i) =>
                                  <label key={i} className="radio-button-field w-radio">
                                    <input type="radio"
                                           className="w-form-formradioinput w-radio-input"
                                           checked={template.template[paramGroup.param] === variant.value}
                                           value={variant.value}
                                           onChange={(e) => {
                                             template.template[paramGroup.param] = variant.value
                                             updateTemplate(template.id, template.template)
                                             // setAssistant({...assistant})
                                           }}


                                    />
                                    <span className="radio-button-label w-form-label">{variant.label}</span>
                                  </label>
                                )}
                              </div>
                            </div>
                          )
                        })}


                      </div>
                    </div>
                    <div className="table-col1" style={{alignItems: "flex-start"}}>
                      <button className="action-btn delete-btn w-inline-block" onClick={() => {
                        removeTemplate(template.id)
                      }}><div><span className="awesome-icon">&nbsp;</span>Remove </div>
                      </button>
                    </div>
                  </div>
                )
              })}

            </div>

            {/*<div className="table-content">*/}
            {/*  {assistant.contexts.sites.map((site, index) =>*/}
            {/*    <div key={index} className="table-row">*/}
            {/*      <div className="table-col1">*/}
            {/*        {site.title}*/}
            {/*      </div>*/}
            {/*      <div className="table-col1">*/}
            {/*        {site.url}*/}
            {/*      </div>*/}
            {/*      <div className="table-col1 ">*/}
            {/*        <textarea className="textarea w-input" value={site.content} ></textarea>*/}
            {/*      </div>*/}
            {/*      <div className="table-col1 " style={{display: 'flex', alignItems: 'flex-start'}}>*/}
            {/*        <button className="button blue-button" onClick={() => {*/}
            {/*          deleteContext(site.id, 'url_context')*/}
            {/*        }}>Remove*/}
            {/*        </button>*/}
            {/*      </div>*/}


            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
        </div>
        <div style={{display: "flex", justifyContent: "flex-start"}}>
          {/*<input className="w-input " style={{maxWidth: '300px'}} placeholder="Enter site url" value={url} onInput={(e) => {setUrl(e.target.value)}}/>*/}
          <div style={{marginLeft: "20px"}}>
            <button className="button blue-button" onClick={addNewTemplate}>Add new template</button>
          </div>
        </div>
        {/*<ErrorBlock error={urlError}></ErrorBlock>*/}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }

  const campaignsTableHeaders = ['name', 'type', 'Analytics', 'actions'];

  const [campaigns, setCampaigns] = useState([])

  const loadCampaigns = () => {

    client.get(`/campaigns?send_statistics=true&widget_id=${currentWidgetId}`).then(data => {
      setCampaigns(data)
    })
  }

  const connectCampaign = (id) => {
    const ids = assistant.campaign_ids
    if (!ids.includes(id)) {
      ids.push(id)
    }
    client.patch(`/widgets/${currentWidgetId}/assistants/${assistantId}`, {
      name: assistant.name,
      campaign_ids: ids,
    }).then(data => {
      setAssistant({...assistant})
    })
  }

  const disconnectCampaign = (id) => {
    const ids = assistant.campaign_ids
    if (ids.includes(id)) {
      ids.splice(ids.indexOf(id), 1)
    }
    client.patch(`/widgets/${currentWidgetId}/assistants/${assistantId}`, {
      name: assistant.name,
      campaign_ids: ids,
    }).then(data => {
      setAssistant({...assistant})
    })
  }


  const CampaignsContainer = () => {
    return (
      <div>
        <div className=" table-container content-white-block">
          <div className="table-w ">
            <div className="table-row table-header">
              {campaignsTableHeaders.map(header =>
                <div key={header} className="table-col1">
                  <div className="details-header">{`${header}`}</div>
                </div>
              )}
            </div>
            <div className="table-content">
              {campaigns.map((campaign, index) => {
                return (
                  <div key={index} className="table-row">
                    <div className="table-col1">
                      <b>{campaign.name}</b>

                    </div>
                    <div className="table-col1">
                      {campaign.type}
                    </div>
                    <div className="table-col1">
                      {assistant.campaign_ids.includes(campaign.id) ? 'Responses: 76' : 'Disconnected'}
                    </div>
                    {/*<div className="table-col1">*/}
                    {/*  {assistant.campaign_ids.includes(campaign.id) ? 'Connected' : 'Disconnected'}*/}
                    {/*</div>*/}

                    <div className="table-col1" style={{alignItems: "flex-start"}}>
                      {assistant.campaign_ids.includes(campaign.id) ? (
                        <button className="button blue-button" onClick={() => {
                          disconnectCampaign(campaign.id)
                        }}>Disconnect
                        </button>
                      ) : (
                        <button className="button " onClick={() => {
                          connectCampaign(campaign.id)
                        }}>Connect
                        </button>
                      )}


                    </div>
                  </div>
                )
              })}

            </div>

          </div>
        </div>
        {/*<div style={{display: "flex", justifyContent: "flex-start"}}>*/}
        {/*  /!*<input className="w-input " style={{maxWidth: '300px'}} placeholder="Enter site url" value={url} onInput={(e) => {setUrl(e.target.value)}}/>*!/*/}
        {/*  <div style={{marginLeft: "20px"}}>*/}
        {/*    <button className="button blue-button" onClick={addNewTemplate}>Add new template</button>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<ErrorBlock error={urlError}></ErrorBlock>*/}
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    )
  }




  return (
    <IntlWrapper messages={messages}>
      <Header title={t('title')}/>
      {assistant ? (
        <div className="page-content normal-content">
          <form id="email-form" name="email-form" data-name="Email Form">
            <div className="error-info-client">
              <label htmlFor="name">Name</label>
            </div>
            <input type="text" value={assistant.name}
                   onInput={e => {
                     assistant.name = e.target.value;
                     updateAssistantName()
                   }}
                   className="node-settings-input input w-input" maxLength="256" name="name"
                   placeholder="Enter name of assistant" id="name"
            />

          </form>


          <div data-duration-in="300" data-duration-out="100" className="a-tabs w-tabs height-100">
            <div className="a-tabs-menu w-tab-menu">
              {tabs.map(tab => (
                <a key={tab.code} onClick={() => {
                  if (selectedTab !== tab.code) {
                    setSelectedTab(tab.code);
                  }
                }}
                   className={`a-tab-link w-inline-block w-tab-link tab-margin ${selectedTab === tab.code ? 'w--current' : ''}`}
                >
                  <div>{tab.label}</div>
                </a>
              ))}
            </div>
            <div className="a-tabs-content w-tab-content flex-1 overflow-unset">
              <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                {selectedTab === 'table' ? QATableContainer() : ''}
                {selectedTab === 'files' ? FilesContainer() : ''}
                {selectedTab === 'sites' ? SitesContainer() : ''}
                {selectedTab === 'templates' ? TemplatesContainer() : ''}
                {selectedTab === 'campaigns' ? CampaignsContainer() : ''}
                {/*<CampaignsList/>*/}
              </div>
            </div>
          </div>




        </div>
      ) : ''}
    </IntlWrapper>
  );
}
