import client from 'api/client';
import api from "../api/common";
import {withDelay} from 'utils/react';
import StripeFactory from "../api/StripeFactory";
import {StripeError} from "../utils";
import {PayablePaymentStatuses} from "../constants";


// plans

export const FETCH_PLANS__R = 'FETCH_PLANS__R';
export const fetchPlansRq = () => {
  return {
    type: FETCH_PLANS__R
  };
};
export const FETCH_PLANS__S = 'FETCH_PLANS__S';
export function fetchPlansSc(plans) {
  return {
    payload: plans,
    type: FETCH_PLANS__S
  };
}
export const FETCH_PLANS__F = 'FETCH_PLANS__F';
export function fetchPlansFl(error) {
  return {
    payload: error,
    type: FETCH_PLANS__F
  };
}
export const FETCH_PLANS = 'FETCH_PLANS';
export const fetchPlans = (params) => (dispatch, getState) => {
  dispatch(fetchPlansRq());

  return client.get('/billing/plans/available')
    .then(data => {
      dispatch(fetchPlansSc(data));
    })
    .catch(error => {
      dispatch(fetchPlansFl(error))
    });
};

//----------------------------------------------------------------------------------------------
// Fetch User Payment History

export const FETCH_USER_PAYMENT_HISTORY__R = 'FETCH_USER_PAYMENT_HISTORY__R';
export const fetchUserPaymentHistoryRq = () => {
  return {
    type: FETCH_USER_PAYMENT_HISTORY__R
  };
};

export const FETCH_USER_PAYMENT_HISTORY__S = 'FETCH_USER_PAYMENT_HISTORY__S';
export const fetchUserPaymentHistorySc = userPaymentHistory => {
  return {
    type: FETCH_USER_PAYMENT_HISTORY__S,
    payload: userPaymentHistory
  };
};

export const FETCH_USER_PAYMENT_HISTORY__F = 'FETCH_USER_PAYMENT_HISTORY__F';
export const fetchUserPaymentHistoryFl = error => {
  return {
    type: FETCH_USER_PAYMENT_HISTORY__F,
    errors: true,
    payload: error
  };
};

export const FETCH_USER_PAYMENT_HISTORY = 'FETCH_USER_PAYMENT_HISTORY';
export const fetchUserPaymentHistory = params => (dispatch, getState) => {
  dispatch(fetchUserPaymentHistoryRq());

  return api.getPaymentHistory(params)
    .then(data => {
      return dispatch(fetchUserPaymentHistorySc(data));
    })
    .catch(error => {
      return dispatch(fetchUserPaymentHistoryFl(error));
    });
};

//----------------------------------------------------------------------------------------------
// Fetch User Current History
export const FETCH_USER_CURRENT_PLAN__R = 'FETCH_USER_CURRENT_PLAN__R';
export const fetchUserCurrentPlanRq = () => {
  return {
    type: FETCH_USER_CURRENT_PLAN__R
  };
};

export const FETCH_USER_CURRENT_PLAN__S = 'FETCH_USER_CURRENT_PLAN__S';
export const fetchUserCurrentPlanSc = currentPlan => {
  return {
    type: FETCH_USER_CURRENT_PLAN__S,
    payload: currentPlan
  };
};

export const FETCH_USER_CURRENT_PLAN__F = 'FETCH_USER_CURRENT_PLAN__F';
export const fetchUserCurrentPlanFl = error => {
  return {
    type: FETCH_USER_CURRENT_PLAN__F,
    errors: true,
    payload: error
  };
};

export const FETCH_USER_CURRENT_PLAN = 'FETCH_USER_CURRENT_PLAN';
export const fetchUserCurrentPlan = params => (dispatch, getState) => {
  dispatch(fetchUserCurrentPlanRq());
  // dispatch(fetchUserCurrentPlanSc({
  //   "id": "88406e62-02c8-44f1-9159-f8c048b5f1a7",
  //   "stripe_plan_id": "plan_HEVP3EN9X1n36C",
  //   "managers_count": 1,
  //   "managers_limit": 1,
  //   "widget_bot_chats_limit": 250,
  //   "widget_bot_chats_count": 250,
  //   "landing_bot_chats_limit": 250,
  //   "landing_bot_chats_count": 250,
  //   "facebook_messenger_bot_chats_limit": 250,
  //   "facebook_messenger_bot_chats_count": 0,
  //   "live_visitors": false,
  //   "live_typing": true,
  //   "meetings": true,
  //   "callback_software": '5',
  //   "removable_branding": false,
  //   "subscription_id": "923395bb-f139-4595-b6a0-47a0a44a0753",
  //   "subscription": {
  //     "id": "923395bb-f139-4595-b6a0-47a0a44a0753",
  //     "stripe_subscription_id": "sub_HGpQt41ZwSfKGj",
  //     "months_count": 1,
  //     "plan_name": "Basic_monthly",
  //     "amount": 99,
  //     "created_at": "2020-05-13T10:24:47.243Z",
  //     "updated_at": "2020-05-13T10:24:47.243Z",
  //     "next_payment_at": "2020-05-13T10:24:47.243Z",
  //     "customer_id": "e634acf8-7175-4294-8806-43bb4bd0396d"
  //   }
  // }));
  // return 0;
  return client.get('/billing/plans/current', {
    params
  })
    .then(data => {
      dispatch(fetchUserCurrentPlanSc(data));
    })
    .catch(error => {
      dispatch(fetchUserCurrentPlanFl(error));
    });
};

//----------------------------------------------------------------------------------------------
// Set plan to pay

export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';
export const setSelectedPlan = (plan) => {
  return {
    type: SET_SELECTED_PLAN,
    payload: plan
  }
};


//----------------------------------------------------------------------------------------------
// Fetch card

export const FETCH_USER_CARD__R = 'FETCH_USER_CARD__R';   // Request
export const fetchUserCardRq = () => {
  return {
    type: FETCH_USER_CARD__R
  };
};

export const FETCH_USER_CARD__S = 'FETCH_USER_CARD__S';   // Success
export function fetchUserCardSc(card) {
  return {
    type: FETCH_USER_CARD__S,
    payload: {
      card
    }
  };
}

export const FETCH_USER_CARD__F = 'FETCH_USER_CARD__F';   // Failure
export function fetchUserCardFl(error) {
  return {
    type: FETCH_USER_CARD__F,
    error: true,
    payload: error
  };
}

export const FETCH_USER_CARD = 'FETCH_USER_CARD';
export const fetchUserCard = () => (dispatch, getState) => {
  dispatch(fetchUserCardRq());

  return client.get('/billing/card/', {
  })
    .then(data => {
      return dispatch(fetchUserCardSc(data));
    })
    .catch(error => {
      return dispatch(fetchUserCardFl(error));
    });
};

//----------------------------------------------------------------------------------------------
// Set user card

export const SET_USER_CARD__R = 'SET_USER_CARD__R';   // Request
export const setUserCardRq = () => {
  return {
    type: SET_USER_CARD__R
  };
};

export const SET_USER_CARD__S = 'SET_USER_CARD__S';   // Success
export const setUserCardSc = card => {
  return {
    type: SET_USER_CARD__S,
    payload: {
      card
    }
  };
};

export const SET_USER_CARD__F = 'SET_USER_CARD__F';   // Failure
export const setUserCardFl = error => {
  return {
    type: SET_USER_CARD__F,
    error: true,
    payload: error
  };
};

export const SET_USER_CARD = 'SET_USER_CARD';
export const setUserCard = withDelay([SET_USER_CARD__S, SET_USER_CARD__F])(
  stripeObject => (dispatch, getState) => {
    dispatch(setUserCardRq());

    const {name: userName = '', email: userEmail} = getState().users.currentUser;

    // TODO: To ensure that your integration is SCA-ready, be sure to always provide
    //  the customer’s name, email, billing address, and shipping address (if available)
    //  to the stripe.handleCardPayment call.
    //  https://stripe.com/docs/payments/payment-intents/quickstart
    const billingDetails = {};

    if (userName) {
      billingDetails.name = userName;
    }
    if (userEmail) {
      billingDetails.email = userEmail;
    }

    return api.stripe.createToken(stripeObject)
      .then(token => {
        // debugger;
        const card = {
          number: token.card.last4,
          expirationMonth: token.card.exp_month,
          expirationYear: token.card.exp_year,
        };
        return api.setPaymentCard(card, token.id)
          .then(() => card);
      })
      .then(card => {
        return dispatch(setUserCardSc({
          number: card.number,
          expiration_month: card.expirationMonth,
          expiration_year: card.expirationYear,
        }));
      })
      .catch(error => {
        // debugger;
        dispatch(setUserCardFl(error));
        return Promise.reject(error);
      });
  });


//----------------------------------------------------------------------------------------------
// Clear user card redux state

export const CLEAR_USER_CARD_ERROR = 'CLEAR_USER_CARD_ERROR';
export const clearUserCardError = () => {
  return {
    type: CLEAR_USER_CARD_ERROR
  };
};

export const CLEAR_BILLING_ERRORS = 'CLEAR_BILLING_ERRORS';
export const clearBillingErrors = () => {
  return {
    type: CLEAR_BILLING_ERRORS
  };
};



//----------------------------------------------------------------------------------------------
// Delete payment

export const DELETE_PAYMENT__R = 'DELETE_PAYMENT__R';
export const deletePaymentRq = () => {
  return {
    type: DELETE_PAYMENT__R
  };
};

export const DELETE_PAYMENT__S = 'DELETE_PAYMENT__S';
export const deletePaymentSc = (paymentId) => {
  return {
    type: DELETE_PAYMENT__S,
    payload: {paymentId}
  };
};

export const DELETE_PAYMENT__F = 'DELETE_PAYMENT__F';
export const deletePaymentFl = error => {
  return {
    type: DELETE_PAYMENT__F,
    error: true,
    payload: error
  };
};

export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const deletePayment = (paymentId) => (dispatch, getState) => {
  dispatch(deletePaymentRq());

  return api.deletePayment(paymentId)
    .then(data => {
      dispatch(deletePaymentSc(paymentId));
      return {data};
    })
    .catch(error => {
      dispatch(deletePaymentFl(error));
      return {error};
    });
};


//----------------------------------------------------------------------------------------------
// Buy plan

export const BUY_PLAN__R = 'BUY_PLAN__R';   // Request
export const buyPlanRq = () => {
  return {
    type: BUY_PLAN__R
  };
};

export const CHECKOUT_PLAN__S = 'CHECKOUT_PLAN__S';   // Success
export const checkoutPlanSc = plan => {
  return {
    type: CHECKOUT_PLAN__S,
    payload: {
      plan
    }
  };
};

export const BUY_PLAN__S = 'BUY_PLAN__S';   // Success
export const buyPlanSc = plan => {
  return {
    type: BUY_PLAN__S,
    payload: {
      plan
    }
  };
};

export const BUY_PLAN__F = 'BUY_PLAN__F';   // Failure
export const buyPlanFl = error => {
  return {
    type: BUY_PLAN__F,
    error: true,
    payload: error
  };
};

export const BUY_PLAN = 'BUY_PLAN';
export const buyPlan = withDelay([BUY_PLAN__S, BUY_PLAN__F])(
  (plan, stripeObject) => (dispatch, getState) => {
    dispatch(buyPlanRq());

    //debugger;

    const {name: userName = '', email: userEmail} = getState().users.currentUser;

    // TODO: To ensure that your integration is SCA-ready, be sure to always provide
    //  the customer’s name, email, billing address, and shipping address (if available)
    //  to the stripe.handleCardPayment call.
    //  https://stripe.com/docs/payments/payment-intents/quickstart
    const billingDetails = {};

    if (userName) {
      billingDetails.name = userName;
    }
    if (userEmail) {
      billingDetails.email = userEmail;
    }

    let lastPayment = null;

    return dispatch(setUserCard(stripeObject))
      .then(() => {
        return api.checkoutPlan(plan.id, plan.period)
      })

      // .then(() => {
      //   return api.getLastPayment()
      //     .then(payment => {
      //       lastPayment = payment;
      //     });
      // })

      .then(data => {
        return {
          requiresStripeAction: data.data.stripe_status === 'requires_action',
          stripeClientSecret: data.data.stripe_client_secret,
        };
      })

      .then(data => {
        dispatch(checkoutPlanSc());

        if (data && data.requiresStripeAction) {
          stripeObject = stripeObject || StripeFactory.getInstance();
          return api.stripe.handleCardPayment(stripeObject, data.stripeClientSecret);
        }
      })

      .then(() => {
        // Since Stripe triggers a webhook of our backend to signal about payment completion,
        // payment status updates asynchronously. Because of that we recurrently send a request
        // for last payment status to know for sure when payment is complete and we can continue.
        return new Promise(resolve => {
          const check = () => {
            return api.getLastPayment()
              .then(payment => {
                // debugger;
                if (payment && (!lastPayment || payment.id !== lastPayment.id)) {
                  resolve();
                } else {
                  setTimeout(check, 3000);
                }
              })
              .catch(() => {
                resolve();
              });
          };

          setTimeout(check, 3000);
        });
      })

      .then(() => {
        return dispatch(buyPlanSc());
      })

      .catch(error => {
        // debugger;
        dispatch(buyPlanFl(error));
        return Promise.reject(error);
      });
  });

//----------------------------------------------------------------------------------------------
// Pay payment

export const PAY_PAYMENT__R = 'PAY_PAYMENT__R';   // Request
export const payPaymentRq = () => {
  return {
    type: PAY_PAYMENT__R,
  };
};

export const CHECKOUT_PAYMENT__S = 'CHECKOUT_PAYMENT__S';   // Success
export const checkoutPaymentSc = () => {
  return {
    type: CHECKOUT_PAYMENT__S,
  };
};

export const PAY_PAYMENT__S = 'PAY_PAYMENT__S';   // Success
export const payPaymentSc = (payment = null) => {
  return {
    type: PAY_PAYMENT__S,
    payload: {
      payment,
    }
  };
};

export const PAY_PAYMENT__F = 'PAY_PAYMENT__F';   // Failure
export const payPaymentFl = error => {
  return {
    type: PAY_PAYMENT__F,
    error: true,
    payload: error
  };
};

export const PAY_PAYMENT = 'PAY_PAYMENT';
export const payPayment = (paymentId, params, stripeObject) => (dispatch, getState) => {
  stripeObject = stripeObject || StripeFactory.getInstance();

  dispatch(payPaymentRq());

  //debugger;

  return api.checkoutPayment(paymentId)
    .then(data => {
      dispatch(checkoutPaymentSc());

      // debugger;

      // throw new Error('Test error');
      if (data.data) {
        if (data.data.stripe_status === 'requires_action') {
          return api.stripe.handleCardPayment(stripeObject, data.data.stripe_client_secret);
        }
      } else {
        if (data.stripe_status === 'requires_action') {
          return api.stripe.handleCardPayment(stripeObject, data.stripe_client_secret);
        }
      }

    })

    // .then(() => {
    //   // Since Stripe triggers a webhook of our backend to signal about payment completion,
    //   // payment status updates asynchronously. Because of that we recurrently send a request
    //   // for the payment status to know for sure when the payment is complete and we can continue.
    //   return new Promise(resolve => {
    //     const check = () => {
    //       return api.getPayment(paymentId)
    //         .then(payment => {
    //           if (PayablePaymentStatuses.indexOf(payment.status) < 0) {
    //             resolve(payment);
    //           } else {
    //             setTimeout(check, 3000);
    //           }
    //         })
    //         .catch(() => {
    //           resolve();
    //         });
    //     };
    //
    //     setTimeout(check, 3000);
    //   });
    // })

    .then((payment = null) => {
      return dispatch(payPaymentSc(payment));
    })

    .catch(error => {
      // debugger;

      const finish = error => {
        dispatch(payPaymentFl(error));
        return Promise.reject(error);
      };

      // Check if user tries to pay the payment that can't be paid
      if (!(error instanceof StripeError) && error.message && error.message.indexOf('paid') > 0) {
        // Fetch the payment to get its actual status
        return api.getPayment(paymentId)
          .then(payment => {
            // debugger;
            // FIXME: Better create custom error for this case
            error.paymentStatus = payment.status;
            return finish(error);
          });
      } else {
        return finish(error);
      }
    });
};

//----------------------------------------------------------------------------------------------
// Fetch payment

export const FETCH_PAYMENT__R = 'FETCH_PAYMENT__R';   // Request
export const fetchPaymentRq = () => {
  return {
    type: FETCH_PAYMENT__R
  };
};

export const FETCH_PAYMENT__S = 'FETCH_PAYMENT__S';   // Success
export const fetchPaymentSc = payment => {
  return {
    type: FETCH_PAYMENT__S,
    payload: {
      payment,
    }
  };
};

export const FETCH_PAYMENT__F = 'FETCH_PAYMENT__F';   // Failure
export const fetchPaymentFl = error => {
  return {
    type: FETCH_PAYMENT__F,
    error: true,
    payload: error
  };
};

export const FETCH_PAYMENT = 'FETCH_PAYMENT';
export const fetchPayment = paymentId => (dispatch, getState) => {
  dispatch(fetchPaymentRq());

  return api.getPayment(paymentId)
    .then(data => {
      return dispatch(fetchPaymentSc(data));
    })
    .catch(error => {
      dispatch(fetchPaymentFl(error));
      throw error;
    });
};


//----------------------------------------------------------------------------------------------
// Fetch specific payments

export const FETCH_SPECIFIC_PAYMENTS__R = 'FETCH_SPECIFIC_PAYMENTS__R';   // Request
export const fetchSpecificPaymentsRq = () => {
  return {
    type: FETCH_SPECIFIC_PAYMENTS__R
  };
};

export const FETCH_SPECIFIC_PAYMENTS__S = 'FETCH_SPECIFIC_PAYMENTS__S';   // Success
export const fetchSpecificPaymentsSq = payments => {
  return {
    type: FETCH_SPECIFIC_PAYMENTS__S,
    payload: {
      payments,
    }
  };
};

export const FETCH_SPECIFIC_PAYMENTS__F = 'FETCH_SPECIFIC_PAYMENTS__F';   // Failure
export const fetchSpecificPaymentsFl = error => {
  return {
    type: FETCH_SPECIFIC_PAYMENTS__F,
    error: true,
    payload: error
  };
};

export const FETCH_SPECIFIC_PAYMENTS = 'FETCH_SPECIFIC_PAYMENTS';
export const fetchSpecificPayments = paymentIds => (dispatch, getState) => {
  dispatch(fetchSpecificPaymentsRq());

  return Promise.all(paymentIds.map(paymentId => client.get(`/user/payment_history/${paymentId}/`)))
    .then(payments => {
      return dispatch(fetchSpecificPaymentsSq(payments));
    })
    .catch(error => {
      dispatch(fetchSpecificPaymentsFl(error));
      throw error;
    });
};


//----------------------------------------------------------------------------------------------
// Confirm last payment transaction

export const CONFIRM_LAST_PAYMENT__R = 'CONFIRM_LAST_PAYMENT__R';   // Request
export const confirmLastPaymentRq = () => {
  return {
    type: CONFIRM_LAST_PAYMENT__R
  };
};

export const CONFIRM_LAST_PAYMENT__S = 'CONFIRM_LAST_PAYMENT__S';   // Success
export const confirmLastPaymentSq = payment => {
  return {
    type: CONFIRM_LAST_PAYMENT__S,
    payload: {
      payment,
    }
  };
};

export const CONFIRM_LAST_PAYMENT__F = 'CONFIRM_LAST_PAYMENT__F';   // Failure
export const confirmLastPaymentFl = error => {
  return {
    type: CONFIRM_LAST_PAYMENT__F,
    error: true,
    payload: error
  };
};

export const CONFIRM_LAST_PAYMENT = 'CONFIRM_LAST_PAYMENT';
export const confirmLastPayment = paymentTransactionId => (dispatch, getState) => {
  dispatch(confirmLastPaymentRq());

  // debugger;

  return api.confirmPayment(paymentTransactionId, true)
    .then(() => {
      return api.getLastPayment()
        .then(payment => {
          // debugger;
          return payment;
        })
        .catch(() => {
          return null
        });
    })
    .then((payment = null) => {
      // debugger;
      return dispatch(confirmLastPaymentSq(payment));
      // dispatch(fetchUserPaymentHistory());
    })
    .catch(error => {
      // debugger;
      return dispatch(confirmLastPaymentFl(error));
    });
};

export const ADD_PAYMENT = 'ADD_PAYMENT';
export function addPayment(data) {
  return {
    payload: data,
    type: ADD_PAYMENT,
  }
}

export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export function updatePayment(data) {
  return {
    payload: data,
    type: UPDATE_PAYMENT
  }
}

export const SUBSCRIBE_TO_PLAN = 'SUBSCRIBE_TO_PLAN';
export function subscribeToPlan(data) {
  return {
    payload: data,
    type: SUBSCRIBE_TO_PLAN
  }
}