import React, {useState, useCallback, useEffect} from 'react';
import {useSelector, useDispatch, shallowEqual } from 'react-redux';
import queryString from 'query-string';
import UIfx from 'uifx';

import {
  fetchChats, fetchChat, fetchChatMessages, updateVisitorInfo, sendMessage, createChat, sendEmail,
  gettingNewChat, startTyping, endTyping, updatingChat, deletingChat, gettingNewMessage, updatingMessage, deletingMessage,
  setChannelTypeFilter, setOperatorFilter, setStatusFilter, setChatSort, searchMessages, resetSearch, readMessage,
  updatingClient, updatingOperator, sendFiles, updateChat, getVisitorInfo, selectChat, askChatRating,
  addPayment, updatePayment, subscribeToPlan,
  toggleNotification as actionToggleNotification,
  offNewMessageAudioPlay as actionOffNewMessageAudioPlay,
  offNewChatAudioPlay as actionOffNewChatAudioPlay,
} from 'actions';

import {ServerEventsChannel} from 'serverEvents';
import {ServerEvents} from 'serverEvents';
import {getAccessToken} from 'utils';
import {PAGINATION_OFFSET_TYPE} from 'constants.js';
import bellAudio from 'widget/style/sounds/sound_1.mp3'
import * as actions from "../actions";

const bell = new UIfx(
  bellAudio,
  {
    volume: 0.8, // number between 0.0 ~ 1.0
  }
);

export const useChatSockets = () => {
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const [channel, setChannel] = useState(null);
  const dispatch = useDispatch();

  const operatorStartTyping = data => {
    channel.emit(ServerEvents.START_TYPING, data);
  };

  const operatorEndTyping = data => {
    channel.emit(ServerEvents.END_TYPING, data);
  };

  useEffect(() => {
    if (currentWidgetId) {

      if (channel) {
        try {
          channel.destroy();
        } catch (exc) {
          console.error(exc);
        }
        setChannel(null);
      }

      const token = getAccessToken();
      ServerEventsChannel.get(token, null, null, currentWidgetId)
        .then((channel) => {
          setChannel(channel);

          channel.on(ServerEvents.ADD_CHAT, data => {
            dispatch(gettingNewChat(data));
          });

          channel.on(ServerEvents.START_TYPING, data => {
            dispatch(startTyping(data));
          });

          channel.on(ServerEvents.END_TYPING, data => {
            dispatch(endTyping(data));
          });

          channel.on(ServerEvents.UPDATE_CHAT, data => {
            dispatch(updatingChat(data));
          });

          channel.on(ServerEvents.DELETE_CHAT, data => {
            dispatch(deletingChat(data));
          });

          channel.on(ServerEvents.ADD_MESSAGE, data => {
            dispatch(gettingNewMessage(data));
          });

          channel.on(ServerEvents.UPDATE_MESSAGE, data => {
            dispatch(updatingMessage(data));
          });

          channel.on(ServerEvents.DELETE_MESSAGE, data => {
            dispatch(deletingMessage(data));
          });

          channel.on(ServerEvents.CLIENT_CONNECTED, data => {
            dispatch(updatingClient(data));
          });

          channel.on(ServerEvents.CLIENT_DISCONNECTED, data => {
            //console.log('CLIENT_DISCONNECTED');
            dispatch(updatingClient(data));
          });

          channel.on(ServerEvents.UPDATE_CLIENT, data => {
            dispatch(updatingClient(data));
          });

          channel.on(ServerEvents.UPDATE_OPERATOR, data => {
            dispatch(updatingOperator(data));
          });

          channel.on(ServerEvents.ADD_PAYMENT, data => {
            dispatch(addPayment(data));
          });

          channel.on(ServerEvents.UPDATE_PAYMENT, data => {
            dispatch(updatePayment(data));
          });

          channel.on(ServerEvents.SUBSCRIBE_TO_PLAN, data => {
            dispatch(subscribeToPlan(data));
          });
        });
    }
  }, [currentWidgetId]);

  return {operatorStartTyping, operatorEndTyping, channel};
};

export const useChatState = () => {
  const {chatsById, orderedChatIds} = useSelector(state => state.chats, shallowEqual);
  const {searchResults, orderedSearchResults} = useSelector(state => state.chats, shallowEqual);
  return {chatsById, orderedChatIds, searchResults, orderedSearchResults};
};

export const useChats = (props) => {
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const {chatsById, orderedChatIds, searchResults, selectedChat, selectedChatId} = useSelector(state => state.chats, shallowEqual);
  const [clientId, setClientId] = useState(queryString.parse(window.location.search).client_id || null);
  //const [chat, setChat] = useState((selectedChatId && chatsById) ? chatsById[selectedChatId] : null);
  const [chatFilters, setChatFilters] = useState({sort: '-created_at', limit: `15,30`, offset: 0});
  const [searchMode, setSearchMode] = useState(false);
  const [selectedSearchResult, setSelectedSearchResult] = useState(null);
  const stateClientId = useSelector(state => state.chats.selectedClientId, shallowEqual);
  const dispatch = useDispatch();


  const setChatId = (chatId) => {
    if (chatId && chatId === selectedChatId) {
      if (!chatFilters.search && searchMode) {
        setSearchMode(false);
        getChatMessages({chat_id: chatId}, PAGINATION_OFFSET_TYPE.reset);
      }
    } else {
      dispatch(selectChat(chatId));
    }
  };

  const clearChat = () => {
    dispatch(selectChat(null));
  };

  const getClient = (clientId) => dispatch(getVisitorInfo(null, clientId));

  const setChatsById = (params = {}) => {
    if (Object.keys(chatFilters).length) {
      if (chatFilters.search) {
        searchChatMessages(chatFilters);
        setSearchMode(true);
      } else {
        getChats({...chatFilters, ...params})
          .then(data => {
            if (data.length && !clientId) {
              const chats = data.map(m => m.chat_id);
              //console.log(selectedChatId);
              if (selectedChat && selectedChat.id && selectedChatId === selectedChat.id) {
                if (selectedChat.widget_id !== currentWidgetId) {
                  setChatId(chats[0]);
                }
              } else {
                if (chatFilters.offset === 0 && !selectedChatId) {
                  if (props.match.params.id) {
                    setChatId(props.match.params.id);
                  } else {
                    setChatId(chats[0]);
                  }
                }
              }
            } else {
              if (clientId) {
                getClient(clientId);
              }
              if (!data.length) {
                setChatId(null);
              }
            }
          });
      }
    }
  }

  useEffect(() => {
    //console.log(chatFilters)
    setChatsById({widget_id: currentWidgetId});
    // if (selectedChat && selectedChat.id && selectedChatId === selectedChat.id) {
    //   if (selectedChat.widget_id !== currentWidgetId) {
    //     dispatch(selectChat(null));
    //   }
    // }
  }, [currentWidgetId]);


  useEffect(() => {
    //console.log(chatFilters)
    setChatsById();
  }, [chatFilters]);

  useEffect(() => {

    if (!selectedChat || selectedChat.id !== selectedChatId) {
      if (selectedChatId) {
        getChat({chat_id: selectedChatId});
      }
    }
  }, [selectedChatId]);

  useEffect(() => {

    if (selectedChat && selectedChat.id && selectedChatId === selectedChat.id) {
      if (selectedChat.widget_id !== currentWidgetId) {
        dispatch(actions.setCurrentWidget(selectedChat.widget_id));
        getChat({chat_id: selectedChatId, widget_id: selectedChat.widget_id});
      }
    }
  }, [selectedChat]);

  useEffect(() => {
    if (selectedChatId && chatsById[selectedChatId]) {
      //setChat(chatsById[selectedChatId]);
      if (selectedChatId !== 'default') {
        setClientId(null);
      }
    }
  }, [chatsById]);



  const getChats = useCallback((params = {}, paginationDirection = PAGINATION_OFFSET_TYPE.next) => {
    return dispatch(fetchChats({
      ...params,
      widget_id: currentWidgetId
    }, paginationDirection));
  }, [dispatch, currentWidgetId]);

  const getChat = useCallback((params = {}) => {
    //console.log('getChat', params);
    return dispatch(fetchChat({
      ...params,
      widget_id: params.widget_id || currentWidgetId
    }, searchMode));
  }, [dispatch, currentWidgetId]);

  const getChatMessages = useCallback((params = {}, paginationDirection = PAGINATION_OFFSET_TYPE.next) => {
    //console.log('getChatMessages', params);
    const offset = params.offset ? params.offset : (selectedSearchResult ? selectedSearchResult : -40);
    return dispatch(fetchChatMessages({
      ...params,
      offset,
      widget_id: currentWidgetId,
    }, paginationDirection));
  }, [dispatch, currentWidgetId]);

  const searchChatMessages = useCallback((params = {}) => {
    dispatch(searchMessages({
      ...params,
      widget_id: currentWidgetId,
    }));
  }, [dispatch, currentWidgetId]);

  const clearSearch = useCallback(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  return {chatsById, chat: selectedChat, selectedChatId, searchResults, clientId, chatFilters,
    selectedSearchResult,
    setChatFilters,
    setSelectedSearchResult,
    clearChat,
    clearSearch, getChatMessages, getChats, getChat, setChatId, searchChatMessages};
};

export const useChatFilter = () => {
  const {channelType, selectedOperator, chatStatus} = useSelector(state => state.chats, shallowEqual);
  const dispatch = useDispatch();

  const setChannelType = useCallback(value => {dispatch(setChannelTypeFilter(value))}, [dispatch]);
  const setSelectedOperator = useCallback(value => {dispatch(setOperatorFilter(value))}, [dispatch]);
  const setChatStatus = useCallback(value => {dispatch(setStatusFilter(value))}, [dispatch]);

  return {channelType, selectedOperator, chatStatus, setChannelType, setSelectedOperator, setChatStatus}
};

export const useChatSort = () => {
  const {chatOrder} = useSelector(state => state.chats, shallowEqual);
  const dispatch = useDispatch();
  const setChatOrder = useCallback(value => {dispatch(setChatSort(value))}, [dispatch]);

  return {chatOrder, setChatOrder}
};

export const useGetChats = (currentWidgetId) => {
  const dispatch = useDispatch();
  return useCallback((filter = {}) => {
    return dispatch(fetchChats({
      ...filter,
      widget_id: currentWidgetId
    }));
  }, [dispatch, currentWidgetId]);
};

export const useUpdateChat = () => {
  const dispatch = useDispatch();
  return useCallback((chatId, data) => {dispatch(updateChat(chatId, data))}, [dispatch]);
};

export const useCreateChat = () => {
  const dispatch = useDispatch();
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  return useCallback((clientId) => {return dispatch(createChat({widget_id: currentWidgetId, client_id: clientId}))}, [dispatch]);
};

export const useSetVisitorInfo = () => {
  const dispatch = useDispatch();
  return useCallback((chatId, clientId, data) => {dispatch(updateVisitorInfo(chatId, clientId, data))}, [dispatch]);
};

export const useSendMessage = () => {
  const dispatch = useDispatch();
  return useCallback((data, visitorId) => {return dispatch(sendMessage(data, visitorId))}, [dispatch]);
};

export const useReadMessage = () => {
  const dispatch = useDispatch();
  return useCallback((last_read_message, chatId) => {dispatch(readMessage(last_read_message, chatId))}, [dispatch]);
};

export const useSendFiles = () => {
  const dispatch = useDispatch();
  return useCallback((files, widgetKey) => dispatch(sendFiles(files)), [dispatch]);
};

export const useAskChatRating = () => {
  const dispatch = useDispatch();
  return useCallback((chatId) => dispatch(askChatRating(chatId)), [dispatch]);
};


let timeoutMessageID = null;
let timeoutChatID = null;
export const useChatNotification = () => {
  const dispatch = useDispatch();

  const currentOperator = useSelector(state => state.operators.currentOperator, shallowEqual);
  const {turnOffNotification, isNewMessageAudioPlay, isNewChatAudioPlay} = useSelector(state => state.chats, shallowEqual);
  const toggleNotification = () => dispatch(actionToggleNotification());
  const offNewMessageAudioPlay = () => dispatch(actionOffNewMessageAudioPlay());
  const offNewChatAudioPlay = () => dispatch(actionOffNewChatAudioPlay());

  useEffect(() => {
    if (currentOperator && currentOperator.notifications) {
      if (currentOperator.notifications.chat.new_chat_message_sound.enabled
      && isNewMessageAudioPlay && !turnOffNotification) {
      bell.play();
      timeoutMessageID = setTimeout(() => {
        offNewMessageAudioPlay();
      }, 1000);
    } else {
      if (timeoutMessageID) {
        clearTimeout(timeoutMessageID );
        timeoutMessageID = null;
      }
    }}
  }, [isNewMessageAudioPlay]);

  useEffect(() => {
    if (currentOperator && currentOperator.notifications.chat.new_chat_request_sound.enabled
      && isNewChatAudioPlay && !turnOffNotification) {
      bell.play();
      timeoutChatID = setTimeout(() => {
        offNewChatAudioPlay();
      }, 1000);
    } else {
      if (timeoutChatID) {
        clearTimeout(timeoutChatID );
        timeoutChatID = null;
      }
    }
  }, [isNewChatAudioPlay]);

  return {turnOffNotification, isNewMessageAudioPlay, toggleNotification, offNewMessageAudioPlay};
};

export const useSendTranscript = () => {
  const dispatch = useDispatch();
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  return useCallback((email, chatId) => dispatch(
    sendEmail({
      widget_id: currentWidgetId,
      mail_type: "chat_transcript",
      chat_id: chatId,
      email: email
    })
  ), [dispatch]);
};