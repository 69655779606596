import React, { useState } from 'react';

export default function DropdownBlock(props) {
  const {title, content, additionalClass} = props;
  const [isDropdownOpen, openDropdown] = useState(false);

  return (
    <div className={`custom-dropdown-w-2 ${additionalClass}`}>
      <div className="custom-dropdown-toggle" onClick={() => openDropdown(!isDropdownOpen)}>
        <div className="group-w-header">
          <div className="group-title">{title}</div>
          <img src={require('styles/images/down-button.svg')} alt=""
               className={`down-arrow display-none ${isDropdownOpen ? 'dropdown-arrow-up' : 'dropdown-arrow-down'}`}/>
        </div>
      </div>
      <div className={`custom-dropdown-list ${isDropdownOpen ? 'display-settings' : 'hide-settings'}`}>
        {content}
      </div>
    </div>
  );
}