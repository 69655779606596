import client from 'api/client';


/* ---- MEETINGS TODAY---- */
export const FETCH_MEETINGS_TODAY__R = 'FETCH_MEETINGS_TODAY__R';
export const fetchMeetingsTodayRq = () => {
  return {
    type: FETCH_MEETINGS_TODAY__R
  };
};

export const FETCH_MEETINGS_TODAY__S = 'FETCH_MEETINGS_TODAY__S';
export function fetchMeetingsTodaySc(meetings) {
  return {
    payload: meetings,
    type: FETCH_MEETINGS_TODAY__S
  };
}

export const FETCH_MEETINGS_TODAY__F = 'FETCH_MEETINGS_TODAY__F';
export function fetchMeetingsTodayFl(error) {
  return {
    payload: error,
    type: FETCH_MEETINGS_TODAY__F
  };
}

export const FETCH_MEETINGS_TODAY = 'FETCH_MEETINGS_TODAY';
export const fetchMeetingsToday = (params) => (dispatch, getState) => {
  dispatch(fetchMeetingsTodayRq());

  return client.get('/meetings', {params})
    .then(data => {
      dispatch(fetchMeetingsTodaySc(data));
    })
    .catch(error => {
      dispatch(fetchMeetingsTodayFl(error));
    });
};

export const COPY_MEETING_TODAY__R = 'COPY_MEETING_TODAY__R';
export const copyMeetingTodayRq = () => {
  return {
    type: COPY_MEETING_TODAY__R
  };
};

export const COPY_MEETING_TODAY__S = 'COPY_MEETING_TODAY__S';
export function copyMeetingTodaySc(meeting) {
  return {
    payload: meeting,
    type: COPY_MEETING_TODAY__S
  };
}

export const COPY_MEETING_TODAY__F = 'COPY_MEETING_TODAY__F';
export function copyMeetingTodayFl(error) {
  return {
    payload: error,
    type: COPY_MEETING_TODAY__F
  };
}

export const COPY_MEETING_TODAY = 'COPY_MEETING_TODAY';
export const copyMeetingToday = () => (dispatch, getState) => {
  dispatch(copyMeetingTodayRq());
  dispatch(copyMeetingTodaySc({

  }));

  // return client.get(`/meetings/settings`)
  //   .then(data => {
  //     dispatch(fetchMeetingLinksRq(data));
  //   })
  //   .catch(error => {
  //     dispatch(fetchMeetingLinksSc(error));
  //   });
};


/* ---- MEETING SETTINGS ---- */
export const FETCH_MEETING_SETTINGS__R = 'FETCH_MEETING_SETTINGS__R';
export const fetchMeetingSettingsRq = () => {
  return {
    type: FETCH_MEETING_SETTINGS__R
  };
};

export const FETCH_MEETING_SETTINGS__S = 'FETCH_MEETING_SETTINGS__S';
export function fetchMeetingSettingsSc(settings) {
  return {
    payload: settings,
    type: FETCH_MEETING_SETTINGS__S
  };
}

export const FETCH_MEETING_SETTINGS__F = 'FETCH_MEETING_SETTINGS__F';
export function fetchMeetingsSettingsFl(error) {
  return {
    payload: error,
    type: FETCH_MEETING_SETTINGS__F
  };
}

export const FETCH_MEETING_SETTINGS = 'FETCH_MEETING_SETTINGS';
export const fetchMeetingSettings = (widgetId) => (dispatch, getState) => {
  dispatch(fetchMeetingSettingsRq());

  return client.get(`/meetings/settings?widget_id=${widgetId}`)
    .then(data => {
      dispatch(fetchMeetingSettingsSc(data));
    })
    .catch(error => {
      dispatch(fetchMeetingsSettingsFl(error));
    });
};


export const UPDATE_MEETING_SETTINGS__R = 'UPDATE_MEETING_SETTINGS__R';
export const updateMeetingSettingsRq = () => {
  return {
    type: UPDATE_MEETING_SETTINGS__R
  };
};
export const UPDATE_MEETING_SETTINGS__S = 'UPDATE_MEETING_SETTINGS__S';
export function updateMeetingSettingsSc(data) {
  return {
    payload: data,
    type: UPDATE_MEETING_SETTINGS__S
  };
}
export const UPDATE_MEETING_SETTINGS__F = 'UPDATE_MEETING_SETTINGS__F';
export function updateMeetingSettingsFl(error) {
  return {
    payload: error,
    type: UPDATE_MEETING_SETTINGS__F
  };
}
export const UPDATE_MEETING_SETTINGS = 'UPDATE_MEETING_SETTINGS';
export const updateMeetingSettings = (updatedSettings, widgetId) => (dispatch, getState) => {
  dispatch(updateMeetingSettingsRq());

  return client.patch(`/meetings/settings?widget_id=${widgetId}`, updatedSettings)
    .then(data => {
      dispatch(updateMeetingSettingsSc(data));
      return {data};
    })
    .catch(error => {
      dispatch(updateMeetingSettingsFl(error));
      return {error};
    });
};

export const SEND_TEST_EMAIL__R = 'SEND_TEST_EMAIL__R';
export const sendTestEmailRq = (type) => {
  return {
    payload: {type},
    type: SEND_TEST_EMAIL__R
  };
};

export const SEND_TEST_EMAIL__S = 'SEND_TEST_EMAIL__S';
export function sendTestEmailSc(type) {
  return {
    payload: {type},
    type: SEND_TEST_EMAIL__S
  };
}

export const SEND_TEST_EMAIL__F = 'SEND_TEST_EMAIL__F';
export function sendTestEmailFl(error, type) {
  return {
    payload: error,
    meta: {type},
    type: SEND_TEST_EMAIL__F
  };
}

export const SEND_TEST_EMAIL = 'SEND_TEST_EMAIL';
export const sendTestEmail = (type, template, widgetId) => (dispatch, getState) => {
  dispatch(sendTestEmailRq(type));
  return client.post(`/meetings/settings/send_test_mail?widget_id=${widgetId}`, template).then(data => {
    dispatch(sendTestEmailSc(type));
    return data;
  }).catch(error => {
    dispatch(sendTestEmailFl(error));
  });
};



/* ---- MEETING CHART DATA ---- */
export const FETCH_MEETING_CHART_DATA__R = 'FETCH_MEETING_CHART_DATA__R';
export const fetchMeetingsChartDataRq = () => {
  return {
    type: FETCH_MEETING_CHART_DATA__R
  };
};

export const FETCH_MEETING_CHART_DATA__S = 'FETCH_MEETING_CHART_DATA__S';
export function fetchMeetingsChartDataSc(data, tickFormat) {
  return {
    payload: {data, tickFormat},
    type: FETCH_MEETING_CHART_DATA__S
  };
}

export const FETCH_MEETING_CHART_DATA__F = 'FETCH_MEETING_CHART_DATA__F';
export function fetchMeetingsChartDataFl(error) {
  return {
    payload: error,
    type: FETCH_MEETING_CHART_DATA__F
  };
}

export const FETCH_MEETING_CHART_DATA = 'FETCH_MEETING_CHART_DATA';
export const fetchMeetingsChartData = (filter, tickFormat) => (dispatch, getState) => {
  dispatch(fetchMeetingsChartDataRq());

  return client.get(`/meetings/statistics`, {
    params: {...filter}
  })
    .then(data => {
      dispatch(fetchMeetingsChartDataSc(data, tickFormat));
    })
    .catch(error => {
      dispatch(fetchMeetingsChartDataFl(error));
    });
};


export const EXPORT_BOOKED_MEETINGS__R = 'EXPORT_BOOKED_MEETINGS__R';
export const exportBookedMeetingsRq = () => {
  return {
    type: EXPORT_BOOKED_MEETINGS__R
  };
};

export const EXPORT_BOOKED_MEETINGS__S = 'EXPORT_BOOKED_MEETINGS__S';
export function exportBookedMeetingsSc() {
  return {
    type: EXPORT_BOOKED_MEETINGS__S
  };
}

export const EXPORT_BOOKED_MEETINGS__F = 'EXPORT_BOOKED_MEETINGS__F';
export function exportBookedMeetingsFl(error) {
  return {
    payload: error,
    type: EXPORT_BOOKED_MEETINGS__F
  };
}

export const EXPORT_BOOKED_MEETINGS = 'EXPORT_BOOKED_MEETINGS';
export const exportBookedMeetings = (params) => (dispatch, getState) => {
  dispatch(exportBookedMeetingsRq());
  return client.get(`/meetings`, {
    params: {
      ...params,
      export: 'csv'
    }
  })
    .then(data => {
      dispatch(exportBookedMeetingsSc(data));
      return data;
    })
    .catch(error => {
      dispatch(exportBookedMeetingsFl(error));
    });
};

/* ---- BOOKED MEETINGS ---- */
export const FETCH_BOOKED_MEETINGS__R = 'FETCH_BOOKED_MEETINGS__R';
export const fetchBookedMeetingsRq = () => {
  return {
    type: FETCH_BOOKED_MEETINGS__R
  };
};

export const FETCH_BOOKED_MEETINGS__S = 'FETCH_BOOKED_MEETINGS__S';
export function fetchBookedMeetingsSc(settings) {
  return {
    payload: settings,
    type: FETCH_BOOKED_MEETINGS__S
  };
}

export const FETCH_BOOKED_MEETINGS__F = 'FETCH_BOOKED_MEETINGS__F';
export function fetchBookedMeetingsFl(error) {
  return {
    payload: error,
    type: FETCH_BOOKED_MEETINGS__F
  };
}

export const FETCH_BOOKED_MEETINGS = 'FETCH_BOOKED_MEETINGS';
export const fetchBookedMeetings = (params) => (dispatch, getState) => {
  dispatch(fetchBookedMeetingsRq());

  return client.get(`/meetings`, {params})
    .then(data => {
      dispatch(fetchBookedMeetingsSc(data));
    })
    .catch(error => {
      dispatch(fetchBookedMeetingsFl(error));
    });
};

export const FETCH_TOP_MANAGER_BOOKED_MEETINGS__R = 'FETCH_TOP_MANAGER_BOOKED_MEETINGS__R';
export const fetchTopManagerBookedMeetingsRq = () => {
  return {
    type: FETCH_TOP_MANAGER_BOOKED_MEETINGS__R
  };
};

export const FETCH_TOP_MANAGER_BOOKED_MEETINGS__S = 'FETCH_TOP_MANAGER_BOOKED_MEETINGS__S';
export function fetchTopManagerBookedMeetingsSc(settings) {
  return {
    payload: settings,
    type: FETCH_TOP_MANAGER_BOOKED_MEETINGS__S
  };
}

export const FETCH_TOP_MANAGER_BOOKED_MEETINGS__F = 'FETCH_TOP_MANAGER_BOOKED_MEETINGS__F';
export function fetchTopManagerBookedMeetingsFl(error) {
  return {
    payload: error,
    type: FETCH_TOP_MANAGER_BOOKED_MEETINGS__F
  };
}

export const FETCH_TOP_MANAGER_BOOKED_MEETINGS = 'FETCH_TOP_MANAGER_BOOKED_MEETINGS';
export const fetchTopManagerBookedMeetings = (params) => (dispatch, getState) => {
  dispatch(fetchTopManagerBookedMeetingsRq());

  return client.get(`/meetings/rating`, {params})
    .then(data => {
      dispatch(fetchTopManagerBookedMeetingsSc(data));
    })
    .catch(error => {
      dispatch(fetchTopManagerBookedMeetingsFl(error));
    });
};


/* ---- MEETING LINKS ---- */
export const FETCH_MEETING_LINKS__R = 'FETCH_MEETING_LINKS__R';
export const fetchMeetingLinksRq = () => {
  return {
    type: FETCH_MEETING_LINKS__R
  };
};

export const FETCH_MEETING_LINKS__S = 'FETCH_MEETING_LINKS__S';
export function fetchMeetingLinksSc(links) {
  return {
    payload: links,
    type: FETCH_MEETING_LINKS__S
  };
}

export const FETCH_MEETING_LINKS__F = 'FETCH_MEETING_LINKS__F';
export function fetchMeetingLinksFl(error) {
  return {
    payload: error,
    type: FETCH_MEETING_LINKS__F
  };
}

export const FETCH_MEETING_LINKS = 'FETCH_MEETING_LINKS';
export const fetchMeetingLinks = (params) => (dispatch, getState) => {
  dispatch(fetchMeetingLinksRq());

  return client.get(`/meetings/links`, {params})
    .then(data => {
      dispatch(fetchMeetingLinksSc(data));
    })
    .catch(error => {
      dispatch(fetchMeetingLinksFl(error));
    });
};

export const FETCH_MEETING_LINK__R = 'FETCH_MEETING_LINK__R';
export const fetchMeetingLinkRq = () => {
  return {
    type: FETCH_MEETING_LINK__R
  };
};

export const FETCH_MEETING_LINK__S = 'FETCH_MEETING_LINK__S';
export function fetchMeetingLinkSc(link) {
  return {
    payload: link,
    type: FETCH_MEETING_LINK__S
  };
}

export const FETCH_MEETING_LINK__F = 'FETCH_MEETING_LINK__F';
export function fetchMeetingLinkFl(error) {
  return {
    payload: error,
    type: FETCH_MEETING_LINK__F
  };
}

export const FETCH_MEETING_LINK = 'FETCH_MEETING_LINK';
export const fetchMeetingLink = (linkId) => (dispatch, getState) => {
  dispatch(fetchMeetingLinkRq());

  return client.get(`/meetings/links/${linkId}`)
    .then(data => {
      dispatch(fetchMeetingLinkSc(data));
    })
    .catch(error => {
      dispatch(fetchMeetingLinkFl(error));
    });
};

export const ADD_MEETING_LINK__R = 'ADD_MEETING_LINK__R';
export const addMeetingLinkRq = () => {
  return {
    type: ADD_MEETING_LINK__R
  };
};

export const ADD_MEETING_LINK__S = 'ADD_MEETING_LINK__S';
export function addMeetingLinkSc(link) {
  return {
    payload: link,
    type: ADD_MEETING_LINK__S
  };
}

export const ADD_MEETING_LINK__F = 'ADD_MEETING_LINK__F';
export function addMeetingLinkFl(error) {
  return {
    payload: error,
    type: ADD_MEETING_LINK__F
  };
}

export const ADD_MEETING_LINK = 'ADD_MEETING_LINK';
export const addMeetingLink = (link) => (dispatch, getState) => {
  dispatch(addMeetingLinkRq());
  //dispatch(addMeetingLinkSc(link));

  return client.post(`/meetings/links`, link)
    .then(data => {
      dispatch(addMeetingLinkSc(data));
      return data;
    })
    .catch(error => {
      dispatch(addMeetingLinkFl(error));
      return error;
    });
};

export const DELETE_MEETING_LINK__R = 'DELETE_MEETING_LINK__R';
export const deleteMeetingLinkRq = () => {
  return {
    type: DELETE_MEETING_LINK__R
  };
};

export const DELETE_MEETING_LINK__S = 'DELETE_MEETING_LINK__S';
export function deleteMeetingLinkSc(linkId) {
  return {
    payload: linkId,
    type: DELETE_MEETING_LINK__S
  };
}

export const DELETE_MEETING_LINK__F = 'DELETE_MEETING_LINK__F';
export function deleteMeetingLinkFl(error) {
  return {
    payload: error,
    type: DELETE_MEETING_LINK__F
  };
}

export const DELETE_MEETING_LINK = 'DELETE_MEETING_LINK';
export const deleteMeetingLink = (linkId) => (dispatch, getState) => {
  dispatch(deleteMeetingLinkRq());
  dispatch(deleteMeetingLinkSc(linkId));

  return client.delete(`/meetings/links/${linkId}`)
    .then(data => {
      dispatch(fetchMeetingLinksSc(data));
      return data;
    })
    .catch(error => {
      dispatch(fetchMeetingLinksFl(error));
      return error;
    });
};

export const EDIT_MEETING_LINK__R = 'EDIT_MEETING_LINK__R';
export const editMeetingLinkRq = () => {
  return {
    type: EDIT_MEETING_LINK__R
  };
};

export const EDIT_MEETING_LINK__S = 'EDIT_MEETING_LINK__S';
export function editMeetingLinkSc(idLink) {
  return {
    payload: idLink,
    type: EDIT_MEETING_LINK__S
  };
}

export const EDIT_MEETING_LINK__F = 'EDIT_MEETING_LINK__F';
export function editMeetingLinkFl(error) {
  return {
    payload: error,
    type: EDIT_MEETING_LINK__F
  };
}

export const EDIT_MEETING_LINK = 'EDIT_MEETING_LINK';
export const editMeetingLink = (data, linkId) => (dispatch, getState) => {
  dispatch(editMeetingLinkRq());

  return client.patch(`/meetings/links/${linkId}`, data)
    .then(data => {
      dispatch(editMeetingLinkSc(data));
      return data;
    })
    .catch(error => {
      dispatch(editMeetingLinkFl(error));
      return error;
    });
};

export const COPY_MEETING_LINK__R = 'COPY_MEETING_LINK__R';
export const copyMeetingLinkRq = () => {
  return {
    type: COPY_MEETING_LINK__R
  };
};

export const COPY_MEETING_LINK__S = 'COPY_MEETING_LINK__S';
export function copyMeetingLinkSc(idLink) {
  return {
    payload: idLink,
    type: COPY_MEETING_LINK__S
  };
}

export const COPY_MEETING_LINK__F = 'COPY_MEETING_LINK__F';
export function copyMeetingLinkFl(error) {
  return {
    payload: error,
    type: COPY_MEETING_LINK__F
  };
}

export const COPY_MEETING_LINK = 'COPY_MEETING_LINK';
export const copyMeetingLink = (data) => (dispatch, getState) => {
  dispatch(editMeetingLinkRq());
  dispatch(editMeetingLinkSc({

  }));

  // return client.post(`/meetings/settings`)
  //   .then(data => {
  //     dispatch(fetchMeetingLinksSc(data));
  //   })
  //   .catch(error => {
  //     dispatch(fetchMeetingLinksFl(error));
  //   });
};


export const UPDATE_BOOKED_MEETING_LIST = 'UPDATE_BOOKED_MEETING_LIST';
export function updateBookedMeetingList(flag) {
  return {
    payload: flag,
    type: UPDATE_BOOKED_MEETING_LIST
  };
}