import React, {Fragment, useState} from 'react';

import BlockBuilderSections from './BlockBuilderSections';
import Switch from 'components/widgets/Switch';
import TextareaWithAddons from 'components/widgets/TextareaWithAddons';
import ActionButtons from './ActionButtons';
import {t as translateCommon} from 'utils';


export default function CloseChatSettings(props) {
  const {currentNode, onChangeNode, t, onErrorFactSet, onSave, onCancel} = props;
  const [errors, setErrors] = useState({message: null, transferToOperator: null});

  const handleNodeChange = (data, field) => {
    onChangeNode(currentNode.content[field] ?
      {content: {[field]: {$set: data}}} : {content: {$merge: {[field]: data}}}, !data)
  };
  const save = () => {
    let gettingErrors = {};
    if (!currentNode.content.message) {
      gettingErrors = {...gettingErrors, message: translateCommon('error.field.empty')};
    }
    if (currentNode.content.transfer_to_operator && (!currentNode.content.transfer_sections ||
      (currentNode.content.transfer_sections &&currentNode.content.transfer_sections.length === 0))) {
      gettingErrors = {...gettingErrors, transferToOperator: 'You must add the field'};
    }
    if (Object.values(gettingErrors).length === 0) {
      onErrorFactSet(false);
      onSave();
    } else {
      onErrorFactSet(true);
      setErrors(gettingErrors);
    }
  };

  return (
    <Fragment>
      <div className="node-settings">
        <div className="settings-item">
          <div className="info-text min-indent">{t('notice')}</div>
        </div>
        <div className="error-info-client">
          {errors && errors.message && (
            <div className="error-message">{errors.message}</div>
          )}
        </div>
        <div className="settings-item">
          <TextareaWithAddons
            isNotDeleting={true}
            value={currentNode.content.message ||  ''}
            onChange={value => {
              if (errors && errors.message) {
                setErrors({...errors, message: null});
              }
              handleNodeChange(value, 'message')
            }}
            placeholderField="web.create.botBuilder.close_chat.message.placeholder"/>
        </div>
      </div>
      <div className="divider"></div>
      <div className="transfer-header">
        <div className="settings-item-label">{translateCommon('web.create.botBuilder.transferMessage.title')}</div>
        <Switch isOn={currentNode.content.transfer_to_operator}
                onClick={() => {
                  if (currentNode.content.transfer_to_operator && errors && errors.transferToOperator) {
                    setErrors({...errors, transferToOperator: null});
                  }
                  handleNodeChange(!currentNode.content.transfer_to_operator, 'transfer_to_operator')
                }}/>
      </div>
      <div className="error-info-client">
        {errors && errors.transferToOperator && (
          <div className="error-message">{errors.transferToOperator}</div>
        )}
      </div>
      {currentNode.content.transfer_to_operator && (
        <div className="transfer-settings-w">
          <BlockBuilderSections sections={currentNode.content.transfer_sections || []}
                                textareaPlaceholderField="web.create.botBuilder.transferMessage.placeholder"
                                onAdd={section => {
                                  if (errors && errors.transferToOperator) {
                                    setErrors({...errors, transferToOperator: null});
                                  }
                                  onChangeNode(currentNode.content.transfer_sections ?
                                    {content: {transfer_sections: {$push: [section]}}} : {content: {$merge: {transfer_sections: [section]}}})
                                }}
                                onDelete={(i) => onChangeNode({content: {transfer_sections: {$splice: [[i, 1]]}}},
                                  currentNode.content.transfer_sections.length === 1)}
                                onChange={(data, i) => onChangeNode(
                                  {content: {transfer_sections: {[i]: {$merge: data}}}})}
          />
        </div>
      )}
      <ActionButtons onSave={save} onCancel={onCancel}/>
    </Fragment>
  );
}
//CloseChatSettings