import React, {useState, useEffect, useRef} from 'react';
import { NavLink, Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import Alert from 'react-s-alert';

import client from 'api/client';
import {useWidgets} from 'hooks';
import SubmitButton from 'components/widgets/SubmitButton';
import {t, removeAccessToken, isUrlValid, redirectTo} from 'utils';


export default function Header(props) {
  const {children, title} = props;
  const {widgetsById, currentWidgetId, setCurrentWidgetId, isCreating, addWidget, getWidget} = useWidgets();
  const [widgetModalVisibility, setWidgetModalVisibility] = useState(false);
  const [isWidgetDropdownOpen, toggleWidgetDropdown] = useState(false);
  const [isAccountDropdownOpen, toggleAccountDropdown] = useState(false);

  const isFirstRun = useRef(true);

  useEffect(() => {
    // skip a first call
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (currentWidgetId) {
      getWidget(currentWidgetId);
    }
  }, [currentWidgetId]);

  const handleLogout = () => {
    client.post('/auth/signout')
      .then(data => {
        removeAccessToken();
        window.redirectToSignin(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div id="top-navbar" className="top-navbar" style={{top: 0}}>
      <div className="top-nav-left-container">
        <div className="page-title">{title}</div>
        <div className="top-control-w">{children}</div>
      </div>
      <div className="top-nav-right-container">
        <div className="top-right-menu">
          <Link to="/upgrade" className="button left-indent w-button">{t('header.upgrade.button')}</Link>
          <div className="top-menu-w">
            <div className="account-dropdown w-dropdown"
                 onMouseOver={() => toggleWidgetDropdown(true)} onMouseOut={() => toggleWidgetDropdown(false)}>
              <div className="account-tog with-icon w-dropdown-toggle">
                <img src={require('styles/images/startup.svg')} alt="" className="top-nav-link-icon"/>
              </div>
              <nav className={`dropdown-list right-float-list w-dropdown-list ${isWidgetDropdownOpen ? 'w--open' : ''}`}>
                {Object.values(widgetsById).map(widget => (
                  <div key={widget.id}
                       className="dropdown-link w-dropdown-link display-flex"
                       onClick={() => {
                         setCurrentWidgetId(widget.id);
                         toggleWidgetDropdown(false);
                       }}>
                    <div className="nav-item-ellipsis">{widget.site_url}</div>
                    <span className={`awesome-icon check-icon ${currentWidgetId !== widget.id ? 'hide' : ''}`}></span>
                  </div>
                ))}
                <div className="step-btn with-border w-inline-block" onClick={() => setWidgetModalVisibility(true)}>
                  <div className="step-btn-text"> {t('header.addWidget.button')}</div>
                </div>
              </nav>
            </div>
            {/*<div className="top-nav-link w-inline-block">*/}
            {/*<img src={require('styles/images/lifesaver.svg')} alt="" className="top-nav-link-icon"/></div>*/}
            <div className="account-dropdown w-dropdown"
                 onMouseOver={() => toggleAccountDropdown(true)} onMouseOut={() => toggleAccountDropdown(false)}>
              <div className="account-tog w-dropdown-toggle">
                <div>S</div>
              </div>
              <nav className={`dropdown-list right-float-list w-dropdown-list ${isAccountDropdownOpen ? 'w--open' : ''}`}>
                <NavLink to="/account" className="dropdown-link w-dropdown-link">{t('header.account')}</NavLink>
                <NavLink to="/billing" className="dropdown-link w-dropdown-link">{t('header.billing')}</NavLink>
                <div className="dropdown-link w-dropdown-link" onClick={handleLogout}>{t('header.logout')}</div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <AddWidgetModal isOpen={widgetModalVisibility} isCreating={isCreating} onAdd={addWidget}
                      setCurrentWidgetId={setCurrentWidgetId}
                      onClose={() => setWidgetModalVisibility(false)}/>
    </div>
  );
}

const AddWidgetModal = (props) => {
  const {isOpen, isCreating, onClose, onAdd} = props;
  const [error, setError] = useState(null);
  const [siteUrl, setSiteUrl] = useState('');

  const handleWidgetAdd = () => {
    if (!isUrlValid(`http://${siteUrl}`)) {
      setError(t('error.url'));
      return;
    }
    onAdd({
      site_url: siteUrl
    })
      .then(data => {
        if (data.error) {
          setError(data.error.message || 'Error');
        } else {
          setError(null);
          setTimeout(() => {
            handleModalClose(data.data);
            Alert.success('Successful!');
          }, 1000);
        }
      });
  };

  const handleModalClose = (newWidget) => {
    onClose();
    setSiteUrl('');
    setError(null);
    props.setCurrentWidgetId(newWidget.id)
    redirectTo('/chats/');
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 outline-none"
      overlayClassName="popup-w w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleModalClose}
    >
      <div className="sign-header">{t('header.widget.modal.title')}</div>
      <div className="w-form">
        <label className="settings-item-label">{t('header.widget.modal.label')}</label>
        <input value={siteUrl} onChange={e => setSiteUrl(e.target.value)} type="text" className="node-settings-input w-input"
               maxLength="256" placeholder="eg.mywebsite.com" required=""/>
        <SubmitButton simpleButton={true} additionalClass={'right-float'} onClick={() => handleWidgetAdd()}
                      isSending={isCreating} text={t('header.widget.modal.button')}/>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        {error && (
          <div className="w-form-fail display-block">
            <div>{error}</div>
          </div>
        )}
      </div>
      <div className="abs-position">
        <div className="delete-btn" onClick={handleModalClose}>
          <div className="close-line-1 rotate back-color"></div>
          <div className="close-line-1 back-color"></div>
        </div>
      </div>
    </ReactModal>
  );
};