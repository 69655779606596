import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import Meetings from './Meetings';
import {meetings} from 'translate';

export default compose(
  connect((state, ownProps) => {
      return {
        messages: [meetings]
      };
    }
  ),

  lifecycle({
    componentDidMount() {
      document.title = 'Meetings | HeyCom'

      console.log('Meetings componentDidMount')
    },
  }),

  withHandlers()
) (
  Meetings
);