import React, { PureComponent } from 'react';


export default class Switch extends PureComponent {
  render() {
    const { isOn, onClick } = this.props;

    return (
      <div className={`switch-wrap w-clearfix ${isOn ? 'active-custom-switch' : ''}`} onClick={onClick}>
        <div className={`switch-handle ${isOn ? 'switch-handle-on' : 'switch-handle-off'}`}></div>
      </div>
    );
  }
}
