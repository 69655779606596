 import React, {useState} from 'react';

import BlockBuilderSections from './BlockBuilderSections';
import ActionButtons from './ActionButtons';
import {t} from 'utils';


export default function SendMessageSettings(props) {
  const {currentNode, onChangeNode, onSave, onErrorFactSet, onCancel} = props;
  const [errors, setErrors] = useState({sections: null});
  const handleNodeSectionsChange = (data, i) => {
    onChangeNode({content: {sections: {[i]: {$merge: data}}}});
  };
  const handleNodeSectionAdd = (section) => {
    onChangeNode(currentNode.content.sections ?
      {content: {sections: {$push: [section]}}} : {content: {$set: {sections: [section]}}});
  };
  const handleNodeSectionDelete = (i) => {
    onChangeNode({content: {sections: {$splice: [[i, 1]]}}}, currentNode.content.sections.length === 1);
  };
  const save = () => {
    let gettingErrors = {};
    if (!currentNode.content.sections || (currentNode.content.sections && currentNode.content.sections.length === 0)) {
      gettingErrors = {...gettingErrors, sections: 'You must add the field'};
    }
    if (Object.values(gettingErrors).length === 0) {
      onErrorFactSet(false);
      onSave();
    } else {
      onErrorFactSet(true);
      setErrors(gettingErrors);
    }
  };

  return (
    <>
      <div className="node-settings">
        <div className="error-info-client">
          {errors && errors.sections && (
            <div className="error-message">{errors.sections}</div>
          )}
        </div>
        <BlockBuilderSections sections={currentNode.content.sections || []}
                              textareaPlaceholderField="web.create.botBuilder.first.message.placeholder"
                              onAdd={(section) => {
                                if (errors && errors.sections) {
                                  setErrors({...errors, sections: null});
                                }
                                handleNodeSectionAdd(section);
                              }}
                              onChange={handleNodeSectionsChange}
                              onDelete={handleNodeSectionDelete}
        />
      </div>
      <ActionButtons onSave={save} onCancel={onCancel}/>
    </>
  );
}