// flow weak

import {
  createStore,
  applyMiddleware,
  compose
}                               from 'redux';
import thunkMiddleware          from 'redux-thunk';
//import { routerMiddleware }     from 'react-router-redux';
// #region import createHistory from hashHistory or BrowserHistory:
//import createHistory            from 'history/createHashHistory';
// import createHistory            from 'history/createBrowserHistory';
import reducer                  from './reducers';
//import { localStorageManager }  from '../middleware';
//import { persistStore, persistReducer } from 'redux-persist';
//import storage from "redux-persist/es/storage";

//export const history = createHistory();

/*
const persistedReducer = persistReducer({
  key: 'root',
  storage,
  whitelist: ['user']
}, reducer);
*/

// createStore : enhancer
const enhancer = compose(applyMiddleware(
    //localStorageManager,
    thunkMiddleware,
    //routerMiddleware(history),

));

export default function configureStore(initialState) {
  const store = createStore(reducer, initialState, enhancer);
  return store;
}
