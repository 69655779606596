const ServerEvents = {
  ADD_MESSAGE: 'add_message',
  UPDATE_MESSAGE: 'update_message',
  DELETE_MESSAGE: 'delete_message',

  START_TYPING: 'start_typing',
  END_TYPING: 'end_typing',

  ADD_CHAT: 'add_chat',
  UPDATE_CHAT: 'update_chat',
  DELETE_CHAT: 'delete_chat',

  CLIENT_CONNECTED: 'client_connected',
  CLIENT_DISCONNECTED: 'client_disconnected',
  UPDATE_CLIENT: 'update_client',

  UPDATE_OPERATOR: 'update_operator',
  UPDATE_ACTIVE_CAMPAIGN: 'update_active_campaign',

  ASK_CHAT_RATING: 'ASK_CHAT_RATING',

  ADD_PAYMENT: 'add_payment',
  UPDATE_PAYMENT: 'update_payment',

  SUBSCRIBE_TO_PLAN: 'subscribe_to_plan'
};

export default ServerEvents;

// const socket = io( "http://127.0.0.1:3003/", {
//   "transports": ["polling","websocket"],
//   "query": {
//     "Authorization": "865561dc-d302-43e8-9386-5ba75c75d559"
//   }
// });
//
// // Listeners
//
// socket.on("connect", (data) => {
//   console.log("socket connected");
// });
//
// socket.on("disconnect", () => {
//   console.log("socket disconnected");
// });

