import React, { Component } from 'react';
import {CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe} from 'react-stripe-elements';

class PaymentCardData extends Component {

  render() {
    const {_t} = this.props;
    this.props.stripeObjectContainer.stripe = this.props.stripe;

    const inputStyle = {
      lineHeight: 1.428571429,
      color: '#000',
      fontSize: '15px',
      fontWeight: 400,
    };

    // https://stripe.com/docs/stripe-js/reference#the-element-container
    // https://stripe.com/docs/stripe-js/reference#element-options

    return (
      <div className='group-content-w add-padding'>
        <div className='group-row horizontal-row'>
          <div className='col-6 full-w'>
            <label className="settings-item-label add-margin">{_t('modal.card.information.label')}
              {_t('modal.card.number.placeholder', {},
                msg => <CardNumberElement
                  id='number'
                  classes={{
                    base: 'node-settings-input w-input',
                  }}
                  placeholder={msg}
                  style={{
                    base: inputStyle,
                  }}
                />)}
            </label>
          </div>
          <div className='col-6 half-w display-flex'>
            <div className='col-4'>
              <label className="settings-item-label add-margin">{_t('modal.expirationDate.label')}
                <CardExpiryElement
                  classes={{
                    base: 'settings-select w-select',
                  }}
                  style={{
                    base: inputStyle,
                  }}
                />
              </label>
            </div>
            <div className='col-4 add-indent'>
              <label className="settings-item-label add-margin">{_t('modal.cvc.placeholder')}
                <CardCVCElement
                  id='cvc'
                  classes={{
                    base: 'node-settings-input w-input',
                  }}
                  style={{
                    base: inputStyle,
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectStripe(PaymentCardData);