import update from 'immutability-helper';
import * as actions from 'actions';

const initialState = {
  currentUser: null,
  currentUserError: null,

  googleAuthError: null,

  _isCurrentUserFetching: false,
  _isUpdating: false,
  _isGoogleAuthUrlFetching: false,
  _isGoogleSignOutProcessing: false
  //currentCurrentUserId: null
};

export default function users(state=initialState, action) {
  switch(action.type) {
    case actions.FETCH_CURRENT_USER__R: {
      return update(state, {
        _isCurrentUserFetching: {$set: true}
      });
    }
    case actions.FETCH_CURRENT_USER__S: {
      return update(state, {
        currentUser: {$set: action.payload},
        currentUserError: {$set: null},
        _isCurrentUserFetching: {$set: false}
      });
    }
    case actions.FETCH_CURRENT_USER__F: {
      return update(state, {
        currentUserError: {$set: action.payload},
        _isCurrentUserFetching: {$set: false}
      });
    }

    case actions.UPDATE_CURRENT_USER__R: {
      return update(state, {
        _isUpdating: {$set: true}
      });
    }

    case actions.UPDATE_CURRENT_USER__S: {
      return update(state, {
        currentUser: {$merge: action.payload},
        currentUserError: {$set: null},
        _isUpdating: {$set: false}
      });
    }

    case actions.UPDATE_CURRENT_USER__F: {
      return update(state, {
        currentUserError: {$set: action.payload},
        _isUpdating: {$set: false}
      });
    }

    case actions.FETCH_GOOGLE_AUTH_URL__R: {
      return update(state, {
        _isGoogleAuthUrlFetching: {$set: true}
      });
    }
    case actions.FETCH_GOOGLE_AUTH_URL__S: {
      return update(state, {
        googleAuthError: {$set: null},
        _isGoogleAuthUrlFetching: {$set: false}
      });
    }
    case actions.FETCH_GOOGLE_AUTH_URL__F: {
      return update(state, {
        googleAuthError: {$set: action.payload},
        _isGoogleAuthUrlFetching: {$set: false}
      });
    }

    case actions.GOOGLE_AUTH_SIGN_OUT__R: {
      return update(state, {
        _isGoogleSignOutProcessing: {$set: true}
      });
    }
    case actions.GOOGLE_AUTH_SIGN_OUT__S: {
      return update(state, {
        googleAuthError: {$set: null},
        _isGoogleSignOutProcessing: {$set: false}
      });
    }
    case actions.GOOGLE_AUTH_SIGN_OUT__F: {
      return update(state, {
        googleAuthError: {$set: action.payload},
        _isGoogleSignOutProcessing: {$set: false}
      });
    }

    default:
      return state;
  }
}