import React, { useState, useEffect } from 'react';
import Rating from 'react-rating';
import { WithContext as ReactTags } from 'react-tag-input';
import update from "immutability-helper";
import _ from 'lodash';
import {useSetVisitorInfo, useChatState} from 'hooks';
import { t, isEmpty, isEmailValid, isPhoneNumberValid} from 'utils';


const KeyCodes = {
  //comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.enter];


export default function VisitorInfo(props) {
  const {chat} = props;
  const updateVisitorInfo = useSetVisitorInfo();
  const {chatsById} = useChatState();

  const [visitor, setVisitor] = useState({});
  const [errors, setErrors] = useState({email: null, phone: null});

  useEffect(() => {
    if (chat.client && chat.client.id) {
      setVisitor({
        name: chat.client.name,
        email: chat.client.email,
        phone: chat.client.phone,
        notes: chat.client.notes,
        tags: chat.client.tags.map(tag => {return {id: tag, text: tag}}),
        chatStatus: chat.status,
        chatStartedFrom: chat.client.chat_started_from,
        browserName: chat.client.browser_name,
        device: chat.client.device,
        ipAddress: chat.client.ip_address
      });
    }
  }, [chat]);

  const handleVisitorInfoUpdate = () => {
    const data = getVisitorRequestData();
    let isError = false;
    if (data.email && !isEmailValid(data.email)) {
      setErrors(update(errors, {email: {$set: t('error.email.invalid')}}));
      isError = true;
    }
    if (data.phone && !isPhoneNumberValid(data.phone)) {
      setErrors(update(errors, {phone: {$set: t('error.phone.invalid')}}));
      isError = true;
    }
    if (isError) {
      return;
    }
    updateVisitorInfo(chat.id, chat.client_id, data);
  };

  const getVisitorRequestData = () => {
    const data = {
      name: visitor.name || null,
      email: visitor.email || null,
      phone: visitor.phone || null,
      notes: visitor.notes || null,
      tags: visitor.tags && visitor.tags.map(tag => tag.text)
    };
    return data;
  };

  const isSaveAvailable = () => {
    const data = getVisitorRequestData();
    const client = chatsById[chat.id] ? chatsById[chat.id].client : chat.client;
    if (client) {
      return !(_.isEqual(data, _.pick(client, _.keys(data))));
    }
  };

  if (!chat) {
    return null;
  }

  return (
    <div className="right-sidebar-w hidden-scrollbar">
      <div className="w-form">
        <form id="email-form-3" name="email-form-3" data-name="Email Form 3">
          <div className="visitor-info-w">
            <div className={`chat-avatar-2 ${visitor.chatStatus && visitor.chatStatus + '-chat'}`}>
              <div className="chat-avatar-icon"></div>
            </div>
            <input value={visitor.name || ''} onChange={e => setVisitor({...visitor, name: e.target.value})}
                   placeholder="Site Visitor" type="text"
                   className="visitor-info-field visitor-name w-input" maxLength="256" />
          </div>
          <div className="visitor-details-w">
            <div className="visitor-details-header-w">
              <div className="details-header">{t('visitor.detail.title')}</div>
            </div>
            <div className="visitor-details-info w-clearfix">
              <div className="visitor-details-item">
                <div className="error-info-client">
                  <label htmlFor="visitor-email" className="visitor-details-label">{t('visitor.detail.email.label')}</label>
                  {errors && errors.email && (
                    <div className="error-message">{errors.email}</div>
                  )}
                </div>
                {t('visitor.detail.email.placeholder', {},
                  msg => <input value={visitor?.email || ''} onChange={e => {
                                  if (errors && errors?.email) {
                                    setErrors(update(errors, {email: {$set: null}}));
                                  }
                                  setVisitor({...visitor, email: e.target.value})
                                }}
                                type="text" className="visitor-info-field w-input"
                                maxLength="256" placeholder={msg} id="visitor-email"/>)}

              </div>
              <div className="visitor-details-item">
                <div className="error-info-client">
                  <label htmlFor="visitor-phone" className="visitor-details-label">{t('visitor.detail.phone.label')}</label>
                  {errors && errors.phone && (
                    <div className="error-message">{errors.phone}</div>
                  )}
                </div>
                {t('visitor.detail.phone.placeholder', {},
                  msg => <input value={visitor.phone || ''}
                                onChange={e => {
                                  if (errors && errors.phone) {
                                    setErrors(update(errors, {phone: {$set: null}}));
                                  }
                                  setVisitor({...visitor, phone: e.target.value})
                                }}
                                type="text" className="visitor-info-field w-input"
                                id="visitor-phone" maxLength="256" placeholder={msg}/>)}
              </div>
              <div className="visitor-details-item">
                <label htmlFor="visitor-notes" className="visitor-details-label">{t('visitor.detail.notes.label')}</label>
                {t('visitor.detail.notes.placeholder', {},
                  msg => <textarea value={visitor.notes || ''} onChange={e => setVisitor({...visitor, notes: e.target.value})}
                                   id="visitor-notes" placeholder={msg} maxLength="5000"
                                   className="visitor-info-field textarea-info w-input custom-textarea"></textarea>)}
              </div>
              <div className="visitor-details-item">
                <label htmlFor="Visitor-tags" className="visitor-details-label">{t('visitor.detail.tags.label')}</label>
                {t('visitor.detail.tags.placeholder', {}, msg => msg)}

                {/*<ReactTags id="visitor-tags"*/}
                {/*           autofocus={false}*/}
                {/*           tags={''}*/}
                {/*           handleAddition={(tag) => setVisitor(update(visitor, {tags: {$push: [tag]}}))}*/}
                {/*           handleDelete={(tag) => setVisitor(update(visitor, {tags: {$splice: [[tag, 1]]}}))}  //messages: {$splice: [[deletingInd, 1]]}*/}
                {/*           allowDragDrop={false}*/}
                {/*           inputFieldPosition="bottom"*/}
                {/*           placeholder={'Enter tags'}*/}
                {/*           classNames={{*/}
                {/*             tagInputField: 'visitor-info-field w-input custom-textarea'*/}
                {/*           }}*/}
                {/*           delimiters={delimiters} />*/}
              </div>
              <div className="button right-float2 w-button"
                   style={isSaveAvailable() ? {} : {pointerEvents: 'none', opacity: 0.4}}
                   onClick={handleVisitorInfoUpdate}>{t('visitor.detail.save')}</div>
            </div>
          </div>
          <div className="visitor-details-w">
            <div className="visitor-details-header-w">
              <div className="details-header">{t('visitor.conversation.title')}</div>
            </div>
            <div className="visitor-details-info">
              <div className="visitor-details-item">
                <label className="visitor-details-label">Conversation started from</label>
                <div className="visitor-details-text wrap">{visitor.chatStartedFrom || '-'}</div>
              </div>
              <div className="visitor-details-item">
                <label className="visitor-details-label">Web browser and device</label>
                <div className="visitor-details-text wrap">{
                  visitor.browserName && visitor.device ?
                    visitor.browserName + ' / ' + visitor.device :
                    visitor.browserName || visitor.device || '-'
                }</div>
              </div>
              <div className="visitor-details-item">
                <label className="visitor-details-label">IP Address</label>
                <div className="visitor-details-text wrap">{visitor.ipAddress || '-'}</div>
              </div>
            </div>
          </div>
          {chat.client_rating && (
            <div className="visitor-details-w">
              <div className="visitor-details-header-w">
                <div className="details-header"><strong>Visitor rated this chat:</strong></div>
              </div>
              <div className="visitor-details-info">
                <div className="visitor-details-item">
                  <Rating placeholderRating={chat.client_rating}
                          initialRating={chat.client_rating}
                          quiet={true}
                          readonly={true}
                          emptySymbol={<img src={require('styles/images/star20g.svg')} alt="" className="image-7"/>}
                          placeholderSymbol={<img src={require('styles/images/star20g.svg')} alt="" className="image-7"/>}
                          fullSymbol={<img src={require('styles/images/star20y.svg')} alt="" className="image-7"/>}/>
                </div>
                {chat.client_comment && (
                  <div className="visitor-details-item">
                    <label for="visitor-phone-2" className="visitor-details-label">Comment:</label>
                    <div className="visitor-details-text">{chat.client_comment}</div>
                  </div>
                )}
              </div>
            </div>
          )}

        </form>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
  )
}