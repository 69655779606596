import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import {Link} from 'react-router-dom';
import ReactModal from 'react-modal';
import moment from 'moment';
import Alert from 'react-s-alert';

import {useCampaigns, useCampaign, useCampaignsCampaignTypeFilter, useCurrentUserPlan,
  useStateWidgets, useDebounce} from 'hooks';
import Loader from 'components/widgets/Loader';
import Switch from 'components/widgets/Switch/Switch';
import SubmitButton from "components/widgets/SubmitButton";
import {CAMPAIGN_REQUEST_TYPES, CAMPAIGN_TYPES} from 'constants.js';
import {t} from 'utils';



export default function CampaignsList(props) {
  const {campaigns, getCampaigns, isFetching, isCreating, deleteCampaign, enableCampaign, disableCampaign, archiveCampaign,
    unarchiveCampaign, cloneCampaign} = useCampaigns();
  const {currentWidgetId} = useStateWidgets();
  const {userCurrentPlan} = useCurrentUserPlan();
  const {selectedCampaignType} = useCampaignsCampaignTypeFilter();

  const [localCampaigns, setCampaign] = useState(campaigns);
  const debouncedLocalCampaigns = useDebounce(localCampaigns, 500);
  const [activeCampaign, setActiveCampaign] = useState(null); // campaign id | null
  const [cloneCampaignModalVisibility, setCloneCampaignModalVisibility] = useState(false);
  const [clonedCampaign, setClonedCampaign] = useState({});
  const [showArchived, toggleShowArchived] = useState(false);


  useEffect(() => {
    setCampaign(campaigns);
  }, [campaigns]);

  useEffect(() => {
    getCampaigns({
      send_statistics: true
    });
  }, [selectedCampaignType, currentWidgetId]);

  useEffect(() => {
    if (Object.values(debouncedLocalCampaigns).length > 0 && Object.values(campaigns).length > 0) {
      Object.values(debouncedLocalCampaigns).forEach((campaign) => {
        if (campaigns[campaign.id]) {
          if (campaign.archived !== campaigns[campaign.id].archived) {
            if (campaign.archived) {
              archiveCampaign(campaign.id);
            } else {
              unarchiveCampaign(campaign.id);
            }
          }
          if (campaign.enabled !== campaigns[campaign.id].enabled) {
            if (campaign.enabled) {
              enableCampaign(campaign.id);
            } else {
              disableCampaign(campaign.id);
            }
          }
        }
      });
    }
  }, [debouncedLocalCampaigns, campaigns]);

  const handleCloneCampaignClick = (campaign) => {
    setClonedCampaign(campaign);
    setCloneCampaignModalVisibility(true);
  };

  const handleArchiveCampaignClick = (id) => {
    setCampaign(update(localCampaigns, {
      [id]: {$toggle: ['archived']}
    }))
  };

  const handleDeleteCampaignClick = (id) => {
    if (window.confirm('Are you sure you want to delete this campaign?')) {
      deleteCampaign(id)
        .then(() => {
          Alert.success('Successful!');
        });
    }
  };

  const handleCampaignActiveToggle = (id) => {
    setCampaign(update(localCampaigns, {
      [id]: {$toggle: ['enabled']}
    }));
  }

  const handleCampaignRemoveActive = (id) => {
    setCampaign(update(localCampaigns, {
      [id]: {'enabled': {$set: false}}
    }));
  }

  const _activateCampaign = (id, campaignEnabled) => {
    if (localCampaigns[id].type === CAMPAIGN_REQUEST_TYPES.bot_web_chat ||
      localCampaigns[id].type === CAMPAIGN_REQUEST_TYPES.landbot ||
      localCampaigns[id].type === CAMPAIGN_REQUEST_TYPES.facebook_messenger) {
      if (localCampaigns[id].type === CAMPAIGN_REQUEST_TYPES.landbot) {
        if (userCurrentPlan.landing_bot_chats_limit && !campaignEnabled) {
          if (userCurrentPlan.landing_bot_chats_count < userCurrentPlan.landing_bot_chats_limit) {
            handleCampaignActiveToggle(id);
          } else {
            Alert.error('To much active campaigns!');
          }
        } else {
          handleCampaignActiveToggle(id);
        }
      }

      if (localCampaigns[id].type === CAMPAIGN_REQUEST_TYPES.bot_web_chat) {
        if (userCurrentPlan.widget_bot_chats_limit && !campaignEnabled) {
          if (userCurrentPlan.widget_bot_chats_count < userCurrentPlan.widget_bot_chats_limit) {
            handleCampaignActiveToggle(id);
          } else {
            Alert.error('To much active campaigns!');
          }
        } else {
          handleCampaignActiveToggle(id);
        }
      }

      if (localCampaigns[id].type === CAMPAIGN_REQUEST_TYPES.facebook_messenger) {
        if (userCurrentPlan.facebook_messenger_bot_chats_limit && !campaignEnabled) {
          if (userCurrentPlan.facebook_messenger_bot_chats_count < userCurrentPlan.facebook_messenger_bot_chats_limit) {
            // error if other active facebook campaigns exists
            let check = false;
            if (!localCampaigns[id].enabled) {
              Object.values(localCampaigns).forEach(campaign => {
                if ((campaign.id !== id) && (campaign.enabled)) {
                  Alert.error('Too much active campaigns!');
                  check = true;
                }
              })
            }
            if (!check) {
              handleCampaignActiveToggle(id);
            }
          } else {
            Alert.error('Too much active campaigns!');
          }
        } else {
          // error if other active facebook campaigns exists
          let check = false;
          if (!localCampaigns[id].enabled) {
            Object.values(localCampaigns).forEach(campaign => {
              if ((campaign.id !== id) && (campaign.enabled)) {
                Alert.error('Too much active campaigns!');
                check = true;
              }
            })
          }
          if (!check) {
            handleCampaignActiveToggle(id);
          }
        }
      }
    } else {
      handleCampaignActiveToggle(id);
    }
  };

  const onCloneCampaign = (campaign, campaignName) => {
    return cloneCampaign(campaign.id, campaignName);
  };

  if (isFetching) {
    return <Loader isSmall={true} additionalOverlayClass="list-loader"/>
  }

  return (
    <div className="campaigns-preview-w">
      {Object.values(campaigns).length > 0 && Object.values(localCampaigns).map((campaign) => {
        if (!campaign.archived && showArchived) {
          return null;
        }
        if (campaign.archived && !showArchived) {
          return null;
        }
        if (campaign.is_draft) {
          return null;
        }

        let campaignImage = 'bot-img.png';

        if (localCampaigns[campaign.id].type == CAMPAIGN_REQUEST_TYPES.bot_web_chat) {
           campaignImage = 'comment-filled.svg';
        }

        if (localCampaigns[campaign.id].type == CAMPAIGN_REQUEST_TYPES.landbot) {
            campaignImage = 'target-filled.svg';
        }

        if (localCampaigns[campaign.id].type == CAMPAIGN_REQUEST_TYPES.facebook_messenger) {
            campaignImage = 'messenger-filled.svg';
        }

        return (
          <div key={campaign.id} className="campaign-preview-item">
            <div className="cp-info-w">
              <div className="cp-avatar-div" style={campaign.archived ? {opacity: 0.5} : {}}>
                <img src={require(`styles/images/${campaignImage}`)} alt="" className="cp-avatar"/></div>
              <div className="cp-name-div" title={campaign.name}>
                <Link to={`campaigns/${campaign.id}`} className="cp-name-link" style={campaign.archived ? {opacity: 0.5} : {}}>{campaign.name}</Link>
              </div>
              <div className="cp-control-div">
                <Switch isOn={campaign.enabled} onClick={(e) => _activateCampaign(campaign.id, campaign.enabled)}/>
                <div className="filter-drop left-indent w-dropdown z-index-auto"
                     onMouseOver={() => setActiveCampaign(campaign.id)} onMouseOut={() => setActiveCampaign(null)}>
                  <div className="filter-drop-tog w-dropdown-toggle">
                    <img src={require('styles/images/settings-blue.svg')} alt="" className="dt-icon"/>
                    <div className="drop-icon w-icon-dropdown-toggle"></div>
                  </div>
                  <nav className={`dropdown-list w-dropdown-list ${
                    activeCampaign === campaign.id ? 'w--open z-index-10' : ''}`
                  }>
                    <Link to={`campaigns/${campaign.id}`} className="dropdown-link w-dropdown-link">{t('campaigns.action.edit')}</Link>
                    <a href="#" onClick={() => handleCloneCampaignClick(campaign)}
                       className="dropdown-link w-dropdown-link">{t('campaigns.action.clone')}<strong></strong></a>
                    <a href="#" onClick={() => handleArchiveCampaignClick(campaign.id)}
                       className="dropdown-link w-dropdown-link">{
                      campaign.archived ? t('campaigns.action.unarchive') : t('campaigns.action.archive')
                    }</a>
                    <a href="#" onClick={() => handleDeleteCampaignClick(campaign.id)}
                       className="dropdown-link red-link w-dropdown-link">{t('campaigns.action.delete')}</a>
                  </nav>
                </div>
              </div>
            </div>
            <div className="cp-info-block сp-info-block-campaigns">
              <div className="cp-info-item">
                <div className="cp-info-header"><span className="cp-info-header-icon"></span> {t('campaigns.statistic.chats')}</div>
                <div className="cp-info-count">{campaign.statistics ? campaign.statistics.all_chats_count : 0}</div>
              </div>
              <div className="cp-info-item">
                <div className="cp-info-header"><span className="cp-info-header-icon"></span> {t('campaigns.statistic.leads')}</div>
                <div className="cp-info-count">{
                  campaign.statistics && campaign.statistics.all_chats_count ? Math.round(100 * campaign.statistics.leads_count / campaign.statistics.all_chats_count) : 0
                }%</div>
              </div>
              <div className="cp-info-item">
                <div className="cp-info-header"><span className="cp-info-header-icon"></span> {t('campaigns.statistic.finished')}</div>
                <div className="cp-info-count">{
                  campaign.statistics && campaign.statistics.all_chats_count ? Math.round(100 * campaign.statistics.finished_chats_count / campaign.statistics.all_chats_count) : 0
                }%</div>
              </div>
              <div className="cp-info-item">
                <div className="cp-info-header"><span className="cp-info-header-icon"></span> {t('campaigns.statistic.lastUpdate')}</div>
                <div className="cp-info-date">{campaign.updated_by_operator_at ?
                  moment(campaign.updated_by_operator_at).format('MMM D, YYYY') :
                  moment(campaign.created_at).format('MMM D, YYYY')}{
                  (campaign.updated_by_operator) ? (' by ' + campaign.updated_by_operator.name) : ''
                }</div>
              </div>
            </div>
          </div>
        )
      })}
      <div className="links-campagins">
        {Object.values(campaigns).length > 0 && (
          <>
            {showArchived ? (
              <div className="link-2-campagins" onClick={() => toggleShowArchived(false)}>Back to all campaigns</div>
            ) : (
              <div className="link-2-campagins" onClick={() => toggleShowArchived(true)}>Show archived campaigns</div>
            )}
          </>
        )}
      </div>
      <CloneCampaignModal isOpen={cloneCampaignModalVisibility} value={clonedCampaign}
                        isLoading={isCreating} onSave={onCloneCampaign}
                        onClose={() => setCloneCampaignModalVisibility(false)}/>
    </div>
  )
}



const CloneCampaignModal = (props) => {
  const {isOpen, isLoading, onClose, onSave, value} = props;
  const [error, setError] = useState(null);
  const [campaignName, setCampaignName] = useState('');

  useEffect(() => {
    setCampaignName(value.name + ' copy' || '');
  }, [value]);

  const handleCloneCampaign = () => {
    if (!campaignName) {
      setError(t('error.field.empty'));
      return;
    }
    onSave(value, campaignName)
      .then(data => {
        if (data.error) {
          setError(data.error.message || 'Error');
        } else {
          setError(null);
          setTimeout(() => {
            handleModalClose();
            Alert.success('Successful!');
          }, 1000);
        }
      });
  };

  const handleModalClose = () => {
    onClose();
    setError(null);
  };
  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 outline-none"
      overlayClassName="popup-w w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleModalClose}
    >
      <div className="sign-header">{t('campaigns.action.cloneCampaign')}</div>
      <div className="w-form">
        <label className="settings-item-label">{t('campaigns.action.campaignName')}</label>
        <input value={campaignName} onChange={e => setCampaignName(e.target.value)} type="text" className="node-settings-input w-input"
               maxLength="256" required=""/>
        <SubmitButton simpleButton={true} additionalClass={'right-float'} onClick={() => handleCloneCampaign()}
                      isSending={isLoading} text={t('campaigns.action.clone')}/>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        {error && (
          <div className="w-form-fail display-block">
            <div>{error}</div>
          </div>
        )}
      </div>
      <div className="abs-position">
        <div className="delete-btn" onClick={handleModalClose}>
          <div className="close-line-1 rotate back-color"></div>
          <div className="close-line-1 back-color"></div>
        </div>
      </div>
    </ReactModal>
  );
};