import client from 'api/client';


export const FETCH_CURRENT_USER__R = 'FETCH_CURRENT_USER__R';
export const fetchCurrentUserRq = () => {
  return {
    type: FETCH_CURRENT_USER__R
  };
};
export const FETCH_CURRENT_USER__S = 'FETCH_CURRENT_USER__S';
export function fetchCurrentUserSc(currentUser) {
  return {
    payload: currentUser,
    type: FETCH_CURRENT_USER__S
  };
}
export const FETCH_CURRENT_USER__F = 'FETCH_CURRENT_USER__F';
export function fetchCurrentUserFl(error) {
  return {
    payload: error,
    type: FETCH_CURRENT_USER__F
  };
}
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const fetchCurrentUser = () => (dispatch, getState) => {
  dispatch(fetchCurrentUserRq());
  return client.get(`/auth/user`)
    .then(data => {
      dispatch(fetchCurrentUserSc(data));
      return data.id;
    })
    .catch(error => {
      dispatch(fetchCurrentUserFl(error))
    });
};

export const UPDATE_CURRENT_USER__R = 'UPDATE_CURRENT_USER__R';
export const updateCurrentUserRq = () => {
  return {
    type: UPDATE_CURRENT_USER__R
  };
};

export const UPDATE_CURRENT_USER__S = 'UPDATE_CURRENT_USER__S';
export function updateCurrentUserSc(user) {
  return {
    payload: user,
    type: UPDATE_CURRENT_USER__S
  };
}

export const UPDATE_CURRENT_USER__F = 'UPDATE_CURRENT_USER__F';
export function updateCurrentUserFl(error) {
  return {
    payload: error,
    type: UPDATE_CURRENT_USER__F
  };
}

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const updateCurrentUser = data => (dispatch, getState) => {
  dispatch(updateCurrentUserRq());

  if ('avatar' in data && (!data.avatar || !data.avatar.url)) {
    //delete data['avatar'];
  }

  return client.patch('/auth/user', data)
      .then(data => {
        dispatch(updateCurrentUserSc(data));
        return data;
      })
      .catch(error => {
        dispatch(updateCurrentUserFl(error));
        return error;
      });
};


export const FETCH_GOOGLE_AUTH_URL__R = 'FETCH_GOOGLE_AUTH_URL__R';
export const fetchGoogleAuthUrlRq = () => {
  return {
    type: FETCH_GOOGLE_AUTH_URL__R
  };
};

export const FETCH_GOOGLE_AUTH_URL__S = 'FETCH_GOOGLE_AUTH_URL__S';
export function fetchGoogleAuthUrlSc() {
  return {
    type: FETCH_GOOGLE_AUTH_URL__S
  };
}

export const FETCH_GOOGLE_AUTH_URL__F = 'FETCH_GOOGLE_AUTH_URL__F';
export function fetchGoogleAuthUrlFl(error) {
  return {
    payload: error,
    type: FETCH_GOOGLE_AUTH_URL__F
  };
}

export const FETCH_GOOGLE_AUTH_URL = 'FETCH_GOOGLE_AUTH_URL';
export const fetchGoogleAuthUrl = data => (dispatch, getState) => {
  dispatch(fetchGoogleAuthUrlRq());

  return client.post('/auth/google/auth_url', data)
    .then(data => {
      dispatch(fetchGoogleAuthUrlSc());
      return data;
    }).catch(error => {
      dispatch(fetchGoogleAuthUrlFl());
      return error;
    })
};

export const GOOGLE_AUTH_SIGN_OUT__R = 'GOOGLE_AUTH_SIGN_OUT__R';
export const googleAuthSignOutRq = () => {
  return {
    type: GOOGLE_AUTH_SIGN_OUT__R
  };
};

export const GOOGLE_AUTH_SIGN_OUT__S = 'GOOGLE_AUTH_SIGN_OUT__S';
export function googleAuthSignOutSc() {
  return {
    type: GOOGLE_AUTH_SIGN_OUT__S
  };
}

export const GOOGLE_AUTH_SIGN_OUT__F = 'GOOGLE_AUTH_SIGN_OUT__F';
export function googleAuthSignOutFl(error) {
  return {
    payload: error,
    type: GOOGLE_AUTH_SIGN_OUT__F
  };
}

export const GOOGLE_AUTH_SIGN_OUT = 'GOOGLE_AUTH_SIGN_OUT';
export const googleAuthSignOut = () => (dispatch, getState) => {
  dispatch(googleAuthSignOutRq());

  return client.post('/auth/google/signout')
    .then(data => {
      dispatch(googleAuthSignOutSc());
      return data;
    }).catch(error => {
      dispatch(googleAuthSignOutFl(error));
      return error;
    });
};
