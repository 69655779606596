import client from 'api/client';


export const FETCH_WIDGETS__R = 'FETCH_WIDGETS__R';
export const fetchWidgetsRq = () => {
  return {
    type: FETCH_WIDGETS__R
  };
};
export const FETCH_WIDGETS__S = 'FETCH_WIDGETS__S';
export function fetchWidgetsSc(widgets) {
  return {
    payload: widgets,
    type: FETCH_WIDGETS__S
  };
}
export const FETCH_WIDGETS__F = 'FETCH_WIDGETS__F';
export function fetchWidgetsFl(error) {
  return {
    payload: error,
    type: FETCH_WIDGETS__F
  };
}
export const FETCH_WIDGETS = 'FETCH_WIDGETS';
export const fetchWidgets = () => (dispatch, getState) => {
  dispatch(fetchWidgetsRq());
  return client.get('/widgets')
    .then(data => {
      dispatch(fetchWidgetsSc(data));
      if (data[0]) {
        dispatch(fetchFacebookPages(data[0].id));
      }
    })
    .catch(error => {
      dispatch(fetchWidgetsFl(error))
    });
};

export const FETCH_WIDGET__R = 'FETCH_WIDGET__R';
export const fetchWidgetRq = () => {
  return {
    type: FETCH_WIDGET__R
  };
};
export const FETCH_WIDGET__S = 'FETCH_WIDGET__S';
export function fetchWidgetSc(widget) {
  return {
    payload: widget,
    type: FETCH_WIDGET__S
  };
}
export const FETCH_WIDGET__F = 'FETCH_WIDGET__F';
export function fetchWidgetFl(error) {
  return {
    payload: error,
    type: FETCH_WIDGET__F
  };
}
export const FETCH_WIDGET = 'FETCH_WIDGET';
export const fetchWidget = (widgetId) => (dispatch, getState) => {
  dispatch(fetchWidgetRq());
  return client.get(`/widgets/${widgetId}`)
    .then(data => {
      dispatch(fetchWidgetSc(data));
      dispatch(fetchFacebookPages(data.id))
    })
    .catch(error => {
      dispatch(fetchWidgetFl(error))
    });
};


export const GET_VIDEO_FILES = 'GET_VIDEO_FILES';
export const getVideoFiles = () => (dispatch, getState) => {
  return client.get(`/filemanager/background_videos`)
    .then(data => {
      return data;
    })
    .catch(error => {
      return error;
    });
};

export const CREATE_WIDGET__R = 'CREATE_WIDGET__R';
export const createWidgetRq = () => {
  return {
    type: CREATE_WIDGET__R
  };
};
export const CREATE_WIDGET__S = 'CREATE_WIDGET__S';
export function createWidgetSc(widget) {
  return {
    payload: widget,
    type: CREATE_WIDGET__S
  };
}
export const CREATE_WIDGET__F = 'CREATE_WIDGET__F';
export function createWidgetFl(error) {
  return {
    payload: error,
    type: CREATE_WIDGET__F
  };
}
export const CREATE_WIDGET = 'CREATE_WIDGET';
export const createWidget = newWidgetData => (dispatch, getState) => {
  dispatch(createWidgetRq());

  return client.post('/widgets', newWidgetData)
    .then(data => {
      dispatch(createWidgetSc(data));
      return {data};
    })
    .catch(error => {
      dispatch(createWidgetFl(error));
      return {error};
    });
};


export const UPDATE_WIDGET__R = 'UPDATE_WIDGET__R';
export const updateWidgetRq = () => {
  return {
    type: UPDATE_WIDGET__R
  };
};
export const UPDATE_WIDGET__S = 'UPDATE_WIDGET__S';
export function updateWidgetSc(data) {
  return {
    payload: data,
    type: UPDATE_WIDGET__S
  };
}
export const UPDATE_WIDGET__F = 'UPDATE_WIDGET__F';
export function updateWidgetFl(error) {
  return {
    payload: error,
    type: UPDATE_WIDGET__F
  };
}
export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const updateWidget = (widgetId, updatedWidgetData) => (dispatch, getState) => {
  dispatch(updateWidgetRq());
  return client.patch(`/widgets/${widgetId}`, updatedWidgetData)
    .then(data => {
      dispatch(updateWidgetSc(data));
      return {data};
    })
    .catch(error => {
      dispatch(updateWidgetFl(error));
      return {error};
    });
};



export const REMOVE_WIDGET__R = 'REMOVE_WIDGET__R';
export const removeWidgetRq = () => {
  return {
    type: REMOVE_WIDGET__R
  };
};
export const REMOVE_WIDGET__S = 'REMOVE_WIDGET__S';
export function removeWidgetSc(id) {
  return {
    payload: id,
    type: REMOVE_WIDGET__S
  };
}
export const REMOVE_WIDGET__F = 'REMOVE_WIDGET__F';
export function removeWidgetFl(error) {
  return {
    payload: error,
    type: REMOVE_WIDGET__F
  };
}
export const REMOVE_WIDGET = 'REMOVE_WIDGET';
export const removeWidget = widgetId => (dispatch, getState) => {
  dispatch(removeWidgetRq());
  return client.delete(`/widgets/${widgetId}`)
    .then(data => {
      dispatch(removeWidgetSc(widgetId));
      return {data};
    })
    .catch(error => {
      dispatch(removeWidgetFl(error));
      return {error};
    });
};

export const CHECK_INSTALL_WIDGET__R = 'CHECK_INSTALL_WIDGET__R';
export const checkInstallWidgetRq = () => {
  return {
    type: CHECK_INSTALL_WIDGET__R
  };
};
export const CHECK_INSTALL_WIDGET__S = 'CHECK_INSTALL_WIDGET__S';
export function checkInstallWidgetSc(widget) {
  return {
    payload: widget,
    type: CHECK_INSTALL_WIDGET__S
  };
}
export const CHECK_INSTALL_WIDGET__F = 'CHECK_INSTALL_WIDGET__F';
export function checkInstallWidgetFl(error) {
  return {
    payload: error,
    type: CHECK_INSTALL_WIDGET__F
  };
}
export const CHECK_INSTALL_WIDGET = 'CHECK_INSTALL_WIDGET';
export const checkInstallWidget = (widgetId) => (dispatch, getState) => {
  dispatch(checkInstallWidgetRq());
  return client.post(`/widgets/${widgetId}/check_installed`)
    .then(data => {
      dispatch(checkInstallWidgetSc(data));
    })
    .catch(error => {
      console.log(error);
      dispatch(checkInstallWidgetFl(error))
    });
};


export const SET_CURRENT_WIDGET = 'SET_CURRENT_WIDGET';
export function setCurrentWidget(widgetId) {
  return {
    payload: widgetId,
    type: SET_CURRENT_WIDGET
  };
}

export const SELECT_WIDGET_SETTING_TAB = 'SELECT_WIDGET_SETTING_TAB';
export function selectSettingTab(tab) {
  return {
    payload: tab,
    type: SELECT_WIDGET_SETTING_TAB,
  }
}


export const CREATE_CONTACT_PROPERTY__R = 'CREATE_CONTACT_PROPERTY__R';
export const createContactPropertyRq = () => {
  return {
    type: CREATE_CONTACT_PROPERTY__R
  };
};
export const CREATE_CONTACT_PROPERTY__S = 'CREATE_CONTACT_PROPERTY__S';
export function createContactPropertySc(data) {
  return {
    payload: data,
    type: CREATE_CONTACT_PROPERTY__S
  };
}
export const CREATE_CONTACT_PROPERTY__F = 'CREATE_CONTACT_PROPERTY__F';
export function createContactPropertyFl(error) {
  return {
    payload: error,
    type: CREATE_CONTACT_PROPERTY__F
  };
}
export const CREATE_CONTACT_PROPERTY = 'CREATE_CONTACT_PROPERTY';
export const createContactProperty = (property, widgetId) => (dispatch, getState) => {
  dispatch(createContactPropertyRq());

  return client.post(`/widgets/${widgetId}/contact_properties`, property)
    .then(data => {
      dispatch(createContactPropertySc(data));
      return {data};
    })
    .catch(error => {
      dispatch(createContactPropertyFl(error));
      return {error};
    });
};


export const FACEBOOK_LOGIN__R = 'FACEBOOK_LOGIN__R';
export const facebookLoginRq = () => {
  return {
    type: FACEBOOK_LOGIN__R
  };
};
export const FACEBOOK_LOGIN__S = 'FACEBOOK_LOGIN__S';
export function facebookLoginSc(data) {
  return {
    payload: data,
    type: FACEBOOK_LOGIN__S
  };
}
export const FACEBOOK_LOGIN__F = 'FACEBOOK_LOGIN__F';
export function facebookLoginFl(error) {
  return {
    payload: error,
    type: FACEBOOK_LOGIN__F
  };
}
export const FACEBOOK_LOGIN = 'FACEBOOK_LOGIN';
export const facebookLogin = (data, widgetId) => (dispatch, getState) => {
  dispatch(facebookLoginRq());

  return client.post(`/widgets/${widgetId}/facebook/login`, data)
    .then(userData => {
      dispatch(fetchFacebookPages(widgetId))
        .then(() => {
          dispatch(facebookLoginSc(userData));
        })
      return {data};
    })
    .catch(error => {
      dispatch(facebookLoginFl(error));
      return {error};
    });
};

export const FACEBOOK_LOGOUT__R = 'FACEBOOK_LOGOUT__R';
export const facebookLogoutRq = () => {
  return {
    type: FACEBOOK_LOGOUT__R
  };
};
export const FACEBOOK_LOGOUT__S = 'FACEBOOK_LOGOUT__S';
export function facebookLogoutSc(data) {
  return {
    payload: data,
    type: FACEBOOK_LOGOUT__S
  };
}
export const FACEBOOK_LOGOUT__F = 'FACEBOOK_LOGOUT__F';
export function facebookLogoutFl(error) {
  return {
    payload: error,
    type: FACEBOOK_LOGOUT__F
  };
}
export const FACEBOOK_LOGOUT = 'FACEBOOK_LOGOUT';
export const facebookLogout = (widgetId) => (dispatch, getState) => {
  dispatch(facebookLogoutRq());

  return client.post(`/widgets/${widgetId}/facebook/logout`)
    .then(data => {
      dispatch(facebookLogoutSc(data));
      return {data};
    })
    .catch(error => {
      dispatch(facebookLogoutFl(error));
      return {error};
    });
};

export const FETCH_FACEBOOK_PAGES__R = 'FETCH_FACEBOOK_PAGES__R';
export const fetchFacebookPagesRq = () => {
  return {
    type: FETCH_FACEBOOK_PAGES__R
  };
};
export const FETCH_FACEBOOK_PAGES__S = 'FETCH_FACEBOOK_PAGES__S';
export function fetchFacebookPagesSc(data) {
  return {
    payload: data,
    type: FETCH_FACEBOOK_PAGES__S
  };
}
export const FETCH_FACEBOOK_PAGES__F = 'FETCH_FACEBOOK_PAGES__F';
export function fetchFacebookPagesFl(error) {
  return {
    payload: error,
    type: FETCH_FACEBOOK_PAGES__F
  };
}
export const FETCH_FACEBOOK_PAGES = 'FETCH_FACEBOOK_PAGES';
export const fetchFacebookPages = (widgetId) => (dispatch, getState) => {
  dispatch(fetchFacebookPagesRq());

  return client.get(`/widgets/${widgetId}/facebook/pages`)
    .then(data => {
      dispatch(fetchFacebookPagesSc(data));
      return {data};
    })
    .catch(error => {
      dispatch(fetchFacebookPagesFl(error));
      return {error};
    });
};

export const FACEBOOK_PAGE_CHOOSE__R = 'FACEBOOK_PAGE_CHOOSE__R';
export const facebookPageChooseRq = () => {
  return {
    type: FACEBOOK_PAGE_CHOOSE__R
  };
};
export const FACEBOOK_PAGE_CHOOSE__S = 'FACEBOOK_PAGE_CHOOSE__S';
export function facebookPageChooseSc(data) {
  return {
    payload: data,
    type: FACEBOOK_PAGE_CHOOSE__S
  };
}
export const FACEBOOK_PAGE_CHOOSE__F = 'FACEBOOK_PAGE_CHOOSE__F';
export function facebookPageChooseFl(error) {
  return {
    payload: error,
    type: FACEBOOK_PAGE_CHOOSE__F
  };
}
export const FACEBOOK_PAGE_CHOOSE = 'FACEBOOK_PAGE_CHOOSE';
export const facebookPageChoose = (pageId, widgetId) => (dispatch, getState) => {
  dispatch(facebookPageChooseRq());

  return client.post(`/widgets/${widgetId}/facebook/pages/connect`, {
    page_id: pageId
  })
    .then(data => {
      dispatch(facebookPageChooseSc(data));
      return {data};
    })
    .catch(error => {
      dispatch(facebookPageChooseFl(error));
      return {error};
    });
};

export const FACEBOOK_PAGE_DISCONNECT__R = 'FACEBOOK_PAGE_DISCONNECT__R';
export const facebookPageDisconnectRq = () => {
  return {
    type: FACEBOOK_PAGE_DISCONNECT__R
  };
};
export const FACEBOOK_PAGE_DISCONNECT__S = 'FACEBOOK_PAGE_DISCONNECT__S';
export function facebookPageDisconnectSc(data) {
  return {
    payload: data,
    type: FACEBOOK_PAGE_DISCONNECT__S
  };
}
export const FACEBOOK_PAGE_DISCONNECT__F = 'FACEBOOK_PAGE_DISCONNECT__F';
export function facebookPageDisconnectFl(error) {
  return {
    payload: error,
    type: FACEBOOK_PAGE_DISCONNECT__F
  };
}
export const FACEBOOK_PAGE_DISCONNECT = 'FACEBOOK_PAGE_DISCONNECT';
export const facebookPageDisconnect = (pageId, widgetId) => (dispatch, getState) => {
  dispatch(facebookPageChooseRq());

  return client.post(`/widgets/${widgetId}/facebook/pages/disconnect`, {
    page_id: pageId
  })
    .then(data => {
      dispatch(facebookPageChooseSc(data));
      return {data};
    })
    .catch(error => {
      dispatch(facebookPageChooseFl(error));
      return {error};
    });
};