import React, {useState, useRef} from 'react';
import { init, exec } from 'pell';
import {EditorState, RichUtils, AtomicBlockUtils} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import Editor from 'draft-js-plugins-editor';
import 'draft-js/dist/Draft.css';

import ImageLoaderModal from 'components/widgets/ImageLoaderModal';
import {t, mediaBlockRenderer, linkStrategy, addLinkPlugin} from 'utils';


export default function WYSIWYGEditor(props) {
  const {placeholderField, value, onChangeValue} = props;
  const [isShowImageLoader, toggleShowImageLoader] = useState(false);
  let options = {
    //defaultBlockTag: null,
  };
  const [editorState, setEditorState] = useState(value ?
    EditorState.createWithContent(stateFromHTML(value)) : EditorState.createEmpty());

  const editor = useRef(null);

  const focusEditor = () => {
    editor.current.focus();
  };

  const onChange = state => {
    onChangeValue(stateToHTML(editorState.getCurrentContent(), options));
    setEditorState(state);
  };

  const createNewEditor = (type, data) => {
    const state = editorState;
    const contentState = state.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      type,
      "IMMUTABLE",
      data
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      state,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    const valueText = data.text || " ";
    setEditorState(
      AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        valueText
      ));
  };

  const onAddImage = e => {
    e.preventDefault();
    toggleShowImageLoader(true);
    //const urlValue = window.prompt("Paste Image Link");
    //createNewEditor("image", { src: urlValue })
  };

  const onSaveImage = url => {
    createNewEditor("image", { src: url });
  };

  const onAddLink = () => {
    //const editorState = editorState;
    const selection = editorState.getSelection();
    const link = window.prompt("Paste the link -");
    if (!link) {
      onChange(RichUtils.toggleLink(editorState, selection, null));
      return "handled";
    }
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
    return "handled";
  };

  const onUnderlineClick = () => {
    onChange(
      RichUtils.toggleInlineStyle(editorState, "UNDERLINE")
    );
  };

  const onListClick = () => {
    onChange(
      RichUtils.toggleBlockType(editorState, "unordered-list-item")
    );
  };

  const onBoldClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const onItalicClick = (e) => {
    onChange(
      RichUtils.toggleInlineStyle(editorState, "ITALIC")
    );
  };
  return (
    <div className="input-div like-textarea">
      <div className="big-textarea-div">
        <div className="input-control top-left">
          <div onClick={onBoldClick} className="control-btn w-inline-block">
            <div></div>
          </div>
          <div onClick={onItalicClick} className="control-btn w-inline-block">
            <div></div>
          </div>
          <div onClick={onUnderlineClick} className="control-btn w-inline-block">
            <div></div>
          </div>
          <div onClick={onListClick} className="control-btn w-inline-block">
            <div></div>
          </div>
          <div onClick={onAddImage} className="control-btn w-inline-block">
            <div></div>
          </div>
          <div onClick={onAddLink} className="control-btn w-inline-block">
            <div></div>
          </div>
          <div className="control-btn w-inline-block">
            <div></div>
          </div>
        </div>
        {t(placeholderField, {},
          msg => <Editor
            blockRendererFn={mediaBlockRenderer}
            ref={editor}
            placeholder={msg}
            editorState={editorState}
            onChange={state => onChange(state)}
            plugins={[addLinkPlugin]}
          />)}
      </div>
      <ImageLoaderModal isOpen={isShowImageLoader} onClose={() => toggleShowImageLoader(false)}
                   onSaveFile={url => onSaveImage(url)}/>
    </div>
  );
}


