import React from 'react';
import Header from 'components/Header';
import {useCampaigns, useCampaignsCampaignTypeFilter} from 'hooks';
import CampaignsHeader from './CampaignsHeader';
import CampaignsList from './CampaignsList'
import IntlWrapper from 'components/widgets/IntlWrapper';
import {t} from 'utils';
import {main, campaigns} from 'translate';
import {CAMPAIGN_TYPES} from 'constants.js';


export default function Campaigns(props) {
  const messages = [main, campaigns];
  const {selectedCampaignType, setSelectedCampaignType} = useCampaignsCampaignTypeFilter();

  return (
    <IntlWrapper messages={messages}>
      <Header title={t('title')}><CampaignsHeader/></Header>
      <div className="page-content normal-content">
        <div data-duration-in="300" data-duration-out="100" className="a-tabs w-tabs height-100">
          <div className="a-tabs-menu w-tab-menu">
            {Object.values(CAMPAIGN_TYPES).map(tab =>
              <a key={tab.value} onClick={() => {
                if (selectedCampaignType.value !== tab.value) {
                  setSelectedCampaignType(tab);
                }
              }}
                 className={`a-tab-link w-inline-block w-tab-link tab-margin ${selectedCampaignType.value === tab.value ? 'w--current' : ''}`}>
                <div>{t(tab.label)}</div>
              </a>
            )}
          </div>
          <div className="a-tabs-content w-tab-content flex-1 overflow-unset">
            <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
              <CampaignsList/>
            </div>
          </div>
        </div>
      </div>
    </IntlWrapper>
  );
}
