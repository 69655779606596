import React, {Fragment, useState, useEffect, useRef, useCallback} from 'react';
import {CSSTransition} from 'react-transition-group';
import update from 'immutability-helper';

import {useTablePagination} from 'hooks';
import Workspace from 'components/widgets/Workspace';
import ColorPicker from 'components/widgets/ColorPicker';
import Switch from 'components/widgets/Switch';
import GradientPicker from 'components/widgets/GradientPicker';
import SubmitButton from 'components/widgets/SubmitButton';
import {t} from 'utils';
import settings from 'settings';


export default function WidgetSettings(props) {
  const queryArgPrefix = settings.SERVICE_BRAND_NAME.toLowerCase();
  const settingBlocks = ['widgetView', 'windowTexts', 'buttonView', 'tooltip', 'rating'];
  const {widget, setWidget, onSave} = props;
  const [widgetSettings, setWidgetSettings] = useState(widget.settings);

  useEffect(() => setWidget(update(widget, {settings: {$set: widgetSettings}})), [widgetSettings]);

  const _t = (value, values = {}, children = null) => {
    return t(`widget.${value}`, values, children);
  };

  const handleSave = useCallback(() => {
    onSave({...widget, settings: widgetSettings})
  }, [widget, widgetSettings])

  return (
    <div className="pane-content-w horizontal-flex">
      <div className="div-block-5">
        <div className="content-white-block grow-w">
          <div className="div-block-3">
            <div className="group-title">{_t('header')}</div>
          </div>
          <div className="group-content-w">
            {settingBlocks.map(block => (
              <DropdownBlock widgetSettings={widgetSettings.widget}
                             channels={widgetSettings.channels}
                             type={block} key={block}
                             onChangeSettings={settings => setWidgetSettings(
                               update(widgetSettings, {
                                 widget: settings
                               })
                             )}/>
            ))}
          </div>
        </div>
        <div className="settings-btn-w">
          <div className="button left-indent w-button"
               onClick={() => {
                 //setWidget(update(widget, {settings: {$set: widgetSettings}}));
                 handleSave()}
               }>{_t(`save.button`)}</div>
        </div>
      </div>
      <Workspace
        widgetSettings={widgetSettings}
        url={`/widget.html?${queryArgPrefix}_mode=`}
        widgetType='widget'
        modes={[
          {value: 'RMFqc1Hqq7', label: 'Online'},
          {value: 'RMFqc1Hqq8', label: 'Offline'},
          {value: 'RMFqc1Hqq9', label: 'Button'}
        ]}
      />
    </div>
  );
}

const DropdownBlock = (props) => {
  const {title, type, widgetSettings, channels, onChangeSettings} = props;
  const [isDropdownOpen, openDropdown] = useState(false);

  const _t = (value, values = {}, children = null) => {
    return t(`widget.${type}.${value}`, values, children);
  };

  return (
    <div className="custom-dropdown-w">
      <div className="custom-dropdown-toggle" onClick={() => openDropdown(!isDropdownOpen)}>
        <div className="group-w-header">
          <div className="group-title">{_t('title')}</div>
        </div>
      </div>
      <div className={`custom-dropdown-list ${isDropdownOpen ? 'display-settings' : 'hide-settings'}`}>
        {type === 'widgetView' && <WidgetView viewSettings={widgetSettings.view}
                                              onChangeSettings={settings => onChangeSettings({view: settings})}
                                              getText={_t}/>}
        {type === 'windowTexts' && <ChatWindowTexts textsSettings={widgetSettings.texts}
                                                    onChangeSettings={settings => onChangeSettings({texts: settings})}
                                                    getText={_t}/>}
        {type === 'buttonView' && <ChatButtonView buttonSettings={widgetSettings.button}
                                                  onChangeSettings={settings => onChangeSettings({button: settings})}
                                                  getText={_t}/>}
        {type === 'tooltip' && <TooltipSettings tooltipSettings={widgetSettings.tooltip}
                                                onChangeSettings={settings => onChangeSettings({tooltip: settings})}
                                                getText={_t}/>}
        {type === 'rating' && <ChatRating ratingSettings={widgetSettings.rate}
                                                onChangeSettings={settings => onChangeSettings({rate: settings})}
                                                getText={_t}/>}
      </div>
    </div>
  );
};

const WidgetView = (props) => {
  const {viewSettings, getText, onChangeSettings} = props;
  const gradients = [
    ['#5bdfdf', '#45afca'],
    ['#e9ae4e', '#a56d13'],
    ['#45c767', '#008823'],
    ['#8664e9', '#4517ca'],
    ['#d76dec', '#ab1ac2']
  ];
  const positions = ['left', 'right'];
  const deviceTypes = ['all', 'desktop', 'mobile', 'hide'];

  const pickerRef = useRef();

  const [isColorThemesOpen, openColorThemes] = useState(false);
  const [isColorPickerOpen, openColorPicker] = useState(false);
  return (
    <>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6">
              <div className="settings-item-label add-margin">{getText('color.themes')}</div>
              <GradientPicker colors={viewSettings.color}
                              onChangeSettings={value => onChangeSettings({color: {$set: value}})}/>
            </div>
            <div className="col-6">
              <div className="settings-item-label add-margin">{getText('color.custom')}</div>
              <ColorPicker color={viewSettings.color[0]} onChangeSettings={value => onChangeSettings({color: {$set: [value]}})}/>
            </div>
          </div>
        </div>
      </div>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-8">
              <div className="settings-item-label add-margin">{getText('position')}</div>
              <div className="choose-type-w">
                {positions.map((pos, i) => (
                  <>
                    <div className={`choose-item ${pos === viewSettings.position ? 'checked' : ''}`}
                      onClick={() => onChangeSettings({position: {$set: pos}})}>
                      <div>{getText(`position.${pos}`)}</div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-12">
              <div className="settings-item-label add-margin">{getText('deviceType')}</div>
              <div className="choose-type-w">
                {deviceTypes.map((type, i) => (
                  <>
                  <div className={`choose-item ${type === viewSettings.visible_devices ? 'checked' : ''}`}
                       onClick={() => onChangeSettings({visible_devices: {$set: type}})}>
                    <div>{getText(`deviceType.${type}`)}</div>
                  </div>
                  </>
                ))}
              </div>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6 hor-flex-2">
              <div className="settings-item-label">{getText('enableChat.label')}</div>
              <Switch isOn={viewSettings.enable_if_you_offline}
                      onClick={() => onChangeSettings({$toggle: ['enable_if_you_offline']})}/>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const ChatWindowTexts = (props) => {
  const {textsSettings, getText, onChangeSettings} = props;

  return (
    <>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6">
              <div className="settings-item-label add-margin">{getText('online.label')}</div>
              <input value={textsSettings.online_mode} onChange={e => onChangeSettings({online_mode: {$set: e.target.value}})}
                type="text" className="node-settings-input w-input" maxLength="256"
                placeholder="We reply immediately." id="name-5"/>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6">
              <div className="settings-item-label add-margin">{getText('offline.label')}</div>
              <input value={textsSettings.offline_mode} onChange={e => onChangeSettings({offline_mode: {$set: e.target.value}})}
                     type="text" className="node-settings-input w-input" maxLength="256"
                     placeholder="We typically reply within a few minutes."/>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6">
              <div className="settings-item-label add-margin">{getText('message.label')}</div>
              <textarea value={textsSettings.offline_message} onChange={e => onChangeSettings({offline_message: {$set: e.target.value}})}
                        placeholder="We’re currently unavailable. We’ll get back to you when one of our operators is able to respond. Please provide your email address so we can get in touch with you."
                        maxLength="5000" className="node-settings-input textarea w-input"></textarea>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6">
              <div className="settings-item-label add-margin">{getText('inputText.label')}</div>
              <input value={textsSettings.chat_input_text} onChange={e => onChangeSettings({chat_input_text: {$set: e.target.value}})}
                     type="text" className="node-settings-input w-input" maxLength="256"
                     placeholder="Enter your message."/>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const ChatButtonView = (props) => {
  const {buttonSettings, getText, onChangeSettings} = props;
  const buttonSizes = ['big', 'medium', 'small'];
  const buttonTypes = ['rounded', 'not_rounded'];
  return (
    <>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-8">
              <div className="settings-item-label add-margin">{getText('size')}</div>
              <div className="choose-type-w">
                {buttonSizes.map((size, i) => (
                  <>
                    <div className={`choose-item ${size === buttonSettings.size ? 'checked' : ''}`}
                         onClick={() => onChangeSettings({size: {$set: size}})}>
                      <div>{getText(`size.${size}`)}</div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-8">
              <div className="settings-item-label add-margin">{getText('type')}</div>
              <div className="choose-type-w">
                {buttonTypes.map((type, i) => (
                  <>
                    <div className={`choose-item ${type === buttonSettings.btn_type ? 'checked' : ''}`}
                         onClick={() => onChangeSettings({btn_type: {$set: type}})}>
                      <div>{getText(`type.${type}`)}</div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const TooltipSettings = (props) => {
  const {tooltipSettings, getText, onChangeSettings} = props;

  const [isShowByScroll, toggleShowByScroll] = useState(tooltipSettings.show_when_scrolling.enabled);
  const [isShowByTimeout, toggleShowByTimeout] = useState(tooltipSettings.show_after_time.enabled);
  const [scrollValue, setScrollValue] = useState(tooltipSettings.show_when_scrolling.percent);
  const [timeoutValue, setTimeoutValue] = useState(tooltipSettings.show_after_time.seconds);

  useEffect(() => onChangeSettings({show_when_scrolling: {$toggle: ['enabled']}}), [isShowByScroll]);
  useEffect(() => onChangeSettings({show_after_time: {$toggle: ['enabled']}}), [isShowByTimeout]);
  useEffect(() => onChangeSettings({show_when_scrolling: {percent: {$set: scrollValue}}}), [scrollValue]);
  useEffect(() => onChangeSettings({show_after_time: {seconds: {$set: timeoutValue}}}), [timeoutValue]);
  return (
    <>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6 hor-flex-2">
              <div className="settings-item-label">{getText('hover')}</div>
              <Switch isOn={tooltipSettings.show_if_hover}
                      onClick={() => onChangeSettings({$toggle: ['show_if_hover']})}/>
            </div>
            <div className="col-6 hor-flex-2"></div>
          </div>
        </div>
      </div>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6">
              <div className="div-block-4">
                <div className="settings-item-label">{getText('scroll')}</div>
                <Switch isOn={isShowByScroll}
                        onClick={() => toggleShowByScroll(!isShowByScroll)}/>
              </div>
              <div className={`more-settings-div ${isShowByScroll ? 'display-settings' : 'hide-settings'}`}>
                <div className="settings-item-label add-margin">{getText('scroll.label')}</div>
                <input value={scrollValue} onChange={e => setScrollValue(e.target.value)}
                       type="number" className="node-settings-input w-input" maxLength="256" placeholder="1"/>
              </div>
            </div>
            <div className="col-6 hor-flex-2"></div>
          </div>
        </div>
      </div>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6">
              <div className="div-block-4">
                <div className="settings-item-label">{getText('timeout')}</div>
                <Switch isOn={isShowByTimeout}
                        onClick={() => toggleShowByTimeout(!isShowByTimeout)}/>
              </div>
              <div className={`more-settings-div ${isShowByTimeout ? 'display-settings' : 'hide-settings'}`}>
                <div className="settings-item-label add-margin">{getText('timeout.label')}</div>
                <input value={timeoutValue} onChange={e => setTimeoutValue(e.target.value)}
                       type="number" className="node-settings-input w-input" maxLength="256" placeholder="1"/>
              </div>
            </div>
            <div className="col-6 hor-flex-2"></div>
          </div>
        </div>
      </div>
      <div className="show-group-content-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6">
              <div className="settings-item-label add-margin">{getText('text.label')}</div>
              <input value={tooltipSettings.text} onChange={e => onChangeSettings({text: {$set: e.target.value}})}
                     type="text" className="node-settings-input w-input" maxLength="256" placeholder="Chat with us"/>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const AdditionalChannels = (props) => {
  const {channelSettings, channels, getText, onChangeSettings} = props;

  return (
    <>
      {Object.keys(channels).map(channel => {
        //console.log(channel)
        if (!channels[channel] || channel === 'facebook') {
          return null;
        }
        return (
          <div className="show-group-content-2">
            <div className="show-group-div">
              <div className="group-row">
                <div className="col-6 hor-flex-2">
                  <div className="settings-item-label">{getText(channel)}</div>
                  <Switch isOn={channelSettings[channel]}
                          onClick={() => onChangeSettings({$toggle: [channel]})}/>
                </div>
                <div className="col-6 hor-flex-2"></div>
              </div>
            </div>
          </div>
        )
      })}

    </>
  );
};

const ChatRating = (props) => {
  const {ratingSettings, getText, onChangeSettings} = props;

  return (
    <>
    <div className="show-group-content-2">
      <div className="show-group-div">
        <div className="group-row">
          <div className="col-6 hor-flex-2">
            <div className="settings-item-label">{getText('enable.rate')}</div>
            <Switch isOn={ratingSettings.enable}
                    onClick={() => onChangeSettings({$toggle: ['enable']})}/>
          </div>
          <div className="col-6 hor-flex-2"></div>
        </div>
      </div>
    </div>
    <div className="show-group-content-2">
      <div className="show-group-div">
        <div className="group-row">
          <div className="col-6">
            <div className="settings-item-label add-margin">{getText('rate.label')}</div>
            <input type="text" className="node-settings-input w-input" maxLength="256"
                   value={ratingSettings.text} onChange={e => onChangeSettings({text: {$set: e.target.value}})}
                   placeholder="How did you like this conversation? Rate please! " id="name-5"/>
          </div>
          <div className="col-6"></div>
        </div>
      </div>
    </div>
    <div className="show-group-content-2">
      <div className="show-group-div">
        <div className="group-row">
          <div className="col-6">
            <div className="settings-item-label add-margin">{getText('comment.label')}</div>
            <input type="text" className="node-settings-input w-input" maxLength="256"
                   value={ratingSettings.comment_input_text} onChange={e => onChangeSettings({comment_input_text: {$set: e.target.value}})}
                   placeholder="Write your comment or leave empty…"/>
          </div>
          <div className="col-6"></div>
        </div>
      </div>
    </div>
    <div className="show-group-content-2">
      <div className="show-group-div">
        <div className="group-row">
          <div className="col-6">
            <div className="settings-item-label add-margin">{getText('button.label')}</div>
            <input type="text" className="node-settings-input w-input" maxLength="256"
                   value={ratingSettings.button_text} onChange={e => onChangeSettings({button_text: {$set: e.target.value}})}
                   placeholder="Send"/>
          </div>
          <div className="col-6"></div>
        </div>
      </div>
    </div>
    </>
  );
};