import React, { Component, useState, useEffect } from 'react';
import update from 'immutability-helper';
import moment from 'moment-timezone';
import Alert from 'react-s-alert';

import Header from 'components/Header';
import IntlWrapper from 'components/widgets/IntlWrapper';
import Loader from 'components/widgets/Loader';
import ImageLoader from 'components/widgets/ImageLoader';
import FileLoader from 'components/widgets/FileLoader';
import CustomSelect from 'components/widgets/CustomSelect';
import {useCurrentUserState, useSendFiles} from 'hooks';
import {t, getPasswordError, isEmailValid, isEmpty} from 'utils';


export default function UserAccount(props) {
  const {messages} = props;
  const {currentUser, updateCurrentUser, isCurrentUserFetching} = useCurrentUserState();
  const [user, setUser] = useState(currentUser);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [error, setError] = useState(null);
  const [avatar, setAvatar] = useState(user.avatar || null);
  const sendFiles = useSendFiles();

  useEffect(() => {
    if (!avatar || (avatar && !avatar.preview && user.avatar && avatar.url !== user.avatar.url)) {
      setAvatar(user.avatar);
    }
  }, [user]);

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
    }
  }, [currentUser]);

  const onSave = () => {
    let updateUser = user;
    if (!updateUser.name || !updateUser.email) {
      return;
    }

    if (!isEmailValid(updateUser.email)) {
      setEmailError(t('error.email.invalid'));
      return;
    }

    if (password) {
      const error = getPasswordError(password);
      if (error || password !== repeatPassword) {
        setError(error);
        return;
      }
      updateUser = {...updateUser,  password: password}
    }

    if (avatar && avatar.preview) {
      _getAvatarUrl(avatar)
        .then(fileData => {
          updateCurrentUser({...updateUser, avatar: fileData}).then(data => {
            success(data);
          })
        })
    } else {
      updateCurrentUser(updateUser).then(data => {
        success(data);
      })
    }
  };

  async function _getAvatarUrl(file) {
    let obj = {};
    const response = handleSendFiles(file);
    await response
      .then(data => {
        obj = data.files[0];
      });
    return obj;
  }
  async function handleSendFiles(file) {
    const sendingPromise = sendFiles([file]);
    return await sendingPromise;
  }


  const success = (data) => {
    if (data.code) {
      if (data.code === 1015) {
        setEmailError(data.message)
      } else {
        setError(data.message || 'Error');
      }
    } else {
      setError(null);
      setEmailError(null);
      setPassword('');
      setRepeatPassword('');
      Alert.success('Successful!');
    }
  };

  const handleSetErrors = (field, error) => {
    setError(error);
  };

  const timeZones = moment.tz.names();
  const timeZonesWithOffset = timeZones.reduce((obj, cur) => {
    obj[cur] = {
      value: cur,
      offset: moment.tz(cur)._offset,
      label: `${cur} (GMT${moment.tz(cur).format('Z')})`
    };
    return obj;
  }, {});

  if (isCurrentUserFetching) {
    return <Loader/>;
  }

  return (
    <IntlWrapper messages={messages}>
      <Header title={t('title')}/>
      <div className="page-content normal-content">
        <div className="w-form">
          <form id="email-form-2" name="email-form-2">
            <div className="group-row vertical-row">
              <div className="content-white-block grow-w">
                <div className="show-group-content no-indent">
                  <div className="show-group-col">
                    <div className="col-6 full-w">
                      <div className="group-title">{t('mainSettings.header')}</div>
                      <div className="show-group-content">
                        <div className="row">
                          <div className="label-w">
                            <div className="label-div">
                              <div className="settings-item-label add-margin">{t('mainSettings.name.label')}</div>
                            </div>
                            {!user.name && <div className="error-message">{t('empty')}</div>}
                          </div>
                          <input type="text" className="node-settings-input w-input" maxLength="256" value={user.name}
                                 onChange={(e) =>
                                   setUser(update(user, {name: {$set: e.target.value}}))}/>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="label-w">
                              <div className="label-div">
                                <div className="settings-item-label add-margin">{t('mainSettings.picture.label')}</div>
                              </div>
                            </div>
                            <div className="avatar-add-wrap square">
                              <ImageLoader value={avatar} defaultImgUrl={require('styles/images/upload-cloud.svg')}
                                           imgClasses={'new-mess-action w-inline-block'}
                                           onChange={(file) => setAvatar(file.file)}/>
                              {/*<FileLoader*/}
                                {/*loadFile={handleSendFiles}*/}
                                {/*setErrors={handleSetErrors}*/}
                                {/*children={({getRootProps, getInputProps}) => {*/}
                                  {/*return (*/}
                                    {/*<div {...getRootProps()} className="new-mess-action w-inline-block">*/}
                                      {/*<input {...getInputProps()} />*/}
                                      {/*<div><img src={require('styles/images/upload-cloud.svg')} alt=""/></div>*/}
                                    {/*</div>*/}
                                  {/*);*/}
                                {/*}}/>*/}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="label-w">
                            <div className="label-div">
                              <div className="settings-item-label add-margin">{t('mainSettings.email.label')}</div>
                            </div>
                            {!user.email && <div className="error-message">{t('empty')}</div>}
                          </div>
                          <input type="text" className="node-settings-input w-input" maxLength="256" value={user.email}
                                 onChange={(e) =>
                                   setUser(update(user, {email: {$set: e.target.value}}))}/>
                          {emailError && <div className="error-message">{emailError}</div>}
                        </div>
                        <div className="row">
                          <div className="label-w">
                            <div className="label-div">
                              <div className="settings-item-label add-margin">{t('mainSettings.timezone.label')}</div>
                            </div>
                          </div>
                          <CustomSelect options={Object.values(timeZonesWithOffset)}
                                        defaultValue={timeZonesWithOffset[user.timezone.name]}
                                        selectClassName={`settings-select w-select`}
                                        getOptionLabel={option => option.label}
                                        onChange={tz => setUser(update(user, {timezone: {$set: {name: tz.value, offset: tz.offset}}}))}
                                        value={timeZonesWithOffset[user.timezone.name]}/>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 full-w add-line">
                      <div className="group-title">{t('password.header')}</div>
                      <div className="show-group-content">
                        <div className="row">
                          <div className="label-w">
                            <div className="label-div">
                              <div className="settings-item-label add-margin">{t('password.new.label')}</div>
                            </div>
                          </div>
                          <input type="password" className="node-settings-input w-input" maxLength="256"
                                 value={password} onChange={e => setPassword(e.target.value)}/>
                        </div>
                        <div className="row">
                          <div className="label-w">
                            <div className="label-div">
                              <div className="settings-item-label add-margin">{t('password.new.repeat.label')}</div>
                            </div>
                            {password !== repeatPassword && <div className="error-message">{t('password.mismatch.label')}</div>}

                          </div>
                          <input type="password" className="node-settings-input w-input" maxLength="256"
                                 value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)}/>
                          <div className="error-message">{error}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="settings-btn-w">
                <a href="#" className="button w-button" onClick={() => onSave()}>{t('button.save')}</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </IntlWrapper>
  );
};