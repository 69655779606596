import {StripeError} from "../utils";


export default {

  createPaymentMethod(stripeObject, billingDetails) {
    if (!stripeObject) {
      return new Promise((resolve, reject) => resolve(null));
    }
    return stripeObject.createPaymentMethod('card', {
      billing_details: billingDetails
    })
      .then(data => {
        if (data.error) {
          throw new StripeError(data.error);
        } else {
          // debugger;
          return data.paymentMethod
        }
      });
  },

  createToken(stripeObject) {
    if (!stripeObject) {
      return new Promise((resolve, reject) => resolve(null));
    }

    return stripeObject.createToken({type: 'card'}).then(data => {
      if (data.error) {
        throw new StripeError(data.error);
      } else {
        return data.token;
      }
    })
  },

  handleCardPayment(stripeObject, stripeClientSecret) {
    return stripeObject.handleCardPayment(stripeClientSecret, {
      // return stripe.handleCardAction(data.stripeClientSecret, {
      // payment_method_data: {
      //   card: {
      //     token: 'pm_1EWnAxKFGouUhKnJj7Cr1hnK'
      //   },
      //   // TODO: To ensure that your integration is SCA-ready, be sure to always provide
      //   //  the customer’s name, email, billing address, and shipping address (if available)
      //   //  to the stripe.handleCardPayment call.
      //   //  https://stripe.com/docs/payments/payment-intents/quickstart
      //   billing_details: {
      //     name: 'johnny',
      //     email: 'johnny@www.com',
      //   },
      // }
    })
      .then(data => {
        // debugger;
        if (data.error) {
          // console.error(data.error);
          throw new StripeError(data.error);
        }
      });
  },
};
