export const CHAT_SOCKETIO_ORIGIN = process.env.REACT_APP_CHAT_SOCKETIO_ORIGIN;
export const CHAT_SOCKETIO_PATH = process.env.REACT_APP_CHAT_SOCKETIO_PATH;
export const CHAT_SOCKETIO_EVENTS_MOCK = JSON.parse(process.env.REACT_APP_CHAT_SOCKETIO_EVENTS_MOCK || false);
export const WIDGET_DEBUG_ENABLED = !!process.env.REACT_APP_WIDGET_DEBUG_ENABLED;

export const SERVICE_BRAND_NAME = process.env.REACT_APP_SERVICE_BRAND_NAME;

export const WIDGET_SETTINGS = JSON.parse(process.env.REACT_APP_WIDGET_SETTINGS);
export const LANDBOT_WIDGET_SETTINGS = JSON.parse(process.env.REACT_APP_LANDBOT_WIDGET_SETTINGS);

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

export const LANDING_URL = process.env.REACT_APP_LANDING_URL;

export default {
  CHAT_SOCKETIO_ORIGIN,
  CHAT_SOCKETIO_PATH,
  CHAT_SOCKETIO_EVENTS_MOCK,
  WIDGET_DEBUG_ENABLED,
  SERVICE_BRAND_NAME,
  WIDGET_SETTINGS,
  LANDBOT_WIDGET_SETTINGS,
  STRIPE_API_KEY,
  LANDING_URL
}