import stripe from './stripe';
import client from './client';
import StripeFactory from './StripeFactory';

const checkSiteUrlNotExist = siteUrl => {
  return client.post('/widgets/check_site_url/', {
    site_url: siteUrl,
  })
    //TODO: Normal api error processing level
    .then(data => {
      if (data.status === 'error' && data.message.indexOf('exist') >= 0) {
        throw new Error('Site url already registered');
      }
    });
};

const getPaymentClientToken = () => {
  return client.get('/billing/client_token/')
    //TODO: Normal api error processing level
    .then(data => {
      return data.client_token;
    });
};

const getPaymentHistory = params => {
  return client.get('/billing/payments/', {params: {...params}});
};

const getPayment = paymentId => {
  return client.get(`/user/payment_history/${paymentId}/`);
};

const deletePayment = paymentId => {
  return client.post(`/billing/payments/${paymentId}/delete`);
};

const getLastPayment = () => {
  // NB: Not very performant
  return getPaymentHistory({
    limit: 1,
  })
    .then(data => {
      return data.payload[0] || null;
      // return null;
    });
};

const checkoutPlan = (planId, planPeriod, stripePaymentMethod) => {
  return client.post('/billing/plans/subscribe/', {
    plan_id: planId,
    // payment_period: planPeriod,   // in months
    // number: stripePaymentMethod ? stripePaymentMethod.card.last4 : null,
    // expiration_month: stripePaymentMethod ? stripePaymentMethod.card.exp_month : null,
    // expiration_year: stripePaymentMethod ? stripePaymentMethod.card.exp_year : null,
    // //payment_method_token: "fake-three-d-secure-visa-unavailable-nonce",
    // payment_method_token: stripePaymentMethod ? stripePaymentMethod.id : null,
  });
};

const checkoutPayment = paymentId => {
  return client.post(`/billing/payments/${paymentId}/pay`, {
    //payment_id: paymentId,
  });
};

const confirmPayment = (paymentTransactionId, extended) => {
  const stripeObject = StripeFactory.getInstance();

  return client.post(`/billing/transaction_info/?extended=${extended}`, {
    transaction_id: paymentTransactionId,
  })
    .then(data => {
      // debugger;
      if (data.status === 'requires_action') {
        return stripe.handleCardPayment(stripeObject, data.client_secret)
          .then(() => {
            return data;
          });
      } else {
        return new Promise((resolve, reject) =>
          reject({message: 'The transaction is canceled or already paid', code: 116}));
      }
    })
    .then((data) => {
      // Since Stripe triggers a webhook of our backend to signal about payment completion,
      // payment status updates asynchronously. Because of that we recurrently send a request
      // for the last payment status to know for sure when the payment is complete and we can continue.
      return new Promise((resolve, reject) => {
        const check = () => {
          return confirmPayment(paymentTransactionId, false)
            .then(data => {
              // debugger;
              if (data.status === 'succeeded') {
                resolve(data);
              } else {
                if (data.status !== 'cancelled') {
                  setTimeout(check, 3000);
                } else {
                  reject({message: 'Error while paying for transaction', code: 116});
                }
              }
            })
            .catch(() => {
              resolve();
            });
        };

        setTimeout(check, 3000);
      });
    });
};

const setPaymentCard = (card, stripePaymentMethodId) => {
  return client.post('/billing/customers/card/', {
    number: card.number,
    expiration_month: card.expirationMonth,
    expiration_year: card.expirationYear,
    token: stripePaymentMethodId,
  });
};

export default {
  stripe,
  checkSiteUrlNotExist,
  getPaymentClientToken,
  getPaymentHistory,
  getPayment,
  deletePayment,
  getLastPayment,
  checkoutPlan,
  checkoutPayment,
  confirmPayment,
  setPaymentCard,
};
