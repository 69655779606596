import React, {Fragment} from 'react';

export default function CustomizableButton(props) {
  const {name, onChangeName, onDelete} = props;
  return (
    <Fragment>
      <div className="dn-button">
        <div className="dn-button-text">
          <input value={name} className="button-bot-builder-text-input" onChange={e => onChangeName(e.target.value)}/>
        </div>
      </div>
      <div className="diagram-node-control settings-control node-control-animation">
        <div className="control-btn more-size w-inline-block" onClick={onDelete}>
          <div></div>
        </div>
      </div>
    </Fragment>
  );
}