import React, {Fragment, useState, useEffect} from 'react';

import IntlWrapper from 'components/widgets/IntlWrapper';
import Loader from 'components/widgets/Loader';
import BotType from './BotType';
import BotBuilder from './BotBuilder';
import WelcomeMessage from './WelcomeMessage';
import DisplaySettings from './DisplaySettings';
import LandbotDisplaySettings from './Landbot/DisplaySettings';
import {useCampaign, useHistoryEvents} from 'hooks';
import * as translate from 'translate';
import {CAMPAIGN_REQUEST_TYPES, CAMPAIGN_TYPES} from 'constants.js';
import DesignSettings from "./DesignSettings";


export default function CampaignSettings(props) {
  const messages = [translate.main, translate.campaigns];

  const {isCreate, campaignType} = props;
  //const campaignIdParam = props.match ? props.match.params.campaignId : null;

  const {type, currentCampaign, handleSetCampaign, saveCampaign, clearCurrentCampaign,
    updateBotSchema, setCurrentCampaign, isFetching, isCampaignSaving} = useCampaign(props);
  const {onClearHistory} = useHistoryEvents();

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    handleSetCampaign();
    return () => {
      onClearHistory();
      clearCurrentCampaign();
    }
  }, []);

  if (isFetching) {
    return <Loader/>;
  }


  return (
    type && currentCampaign &&
    (
      <IntlWrapper messages={messages}>
        {type === CAMPAIGN_REQUEST_TYPES.live_web_chat &&
          <Fragment>
            {currentStep === 0 && <WelcomeMessage campaign={currentCampaign}
                                                  setCampaign={setCurrentCampaign}
                                                  isCreate={isCreate}
                                                  isCampaignSaving={isCampaignSaving}
                                                  type={type}
                                                  currentStep={currentStep}
                                                  onSave={saveCampaign}
                                                  setCurrentStep={setCurrentStep} />}

            {currentStep === 1 && <DisplaySettings campaign={currentCampaign}
                                                   setCampaign={setCurrentCampaign}
                                                   isCreate={isCreate}
                                                   isCampaignSaving={isCampaignSaving}
                                                   type={type}
                                                   currentStep={currentStep}
                                                   setCurrentStep={setCurrentStep}
                                                   onSave={saveCampaign} />}
          </Fragment>
        }
        {type === CAMPAIGN_REQUEST_TYPES.bot_web_chat &&
          <Fragment>
            {currentStep === 0 && <BotType campaign={currentCampaign}
                                           setCampaign={setCurrentCampaign}
                                           campaignType={CAMPAIGN_TYPES.web.value}
                                           isCreate={isCreate}
                                           isCampaignSaving={isCampaignSaving}
                                           type={type}
                                           currentStep={currentStep}
                                           onUpdateBotSchema={updateBotSchema}
                                           onSave={saveCampaign}
                                           setCurrentStep={setCurrentStep} />}

            {currentStep === 1 && <BotBuilder campaign={currentCampaign}
                                              setCampaign={setCurrentCampaign}
                                              campaignType={CAMPAIGN_TYPES.web.value}
                                              isCreate={isCreate}
                                              isCampaignSaving={isCampaignSaving}
                                              type={type}
                                              onClearHistory={onClearHistory}
                                              currentStep={currentStep}
                                              onSave={saveCampaign}
                                              setCurrentStep={setCurrentStep} />}

            {currentStep === 2 && <DisplaySettings campaign={currentCampaign}
                                                   setCampaign={setCurrentCampaign}
                                                   isCreate={isCreate}
                                                   isCampaignSaving={isCampaignSaving}
                                                   type={type}
                                                   currentStep={currentStep}
                                                   setCurrentStep={setCurrentStep}
                                                   onSave={saveCampaign} />}
          </Fragment>
        }
        {type === CAMPAIGN_TYPES.landbot.value &&
          <Fragment>
            {currentStep === 0 && <BotType campaign={currentCampaign}
                                           setCampaign={setCurrentCampaign}
                                           campaignType={CAMPAIGN_TYPES.landbot.value}
                                           isCreate={isCreate}
                                           type={type}
                                           currentStep={currentStep}
                                           onSave={saveCampaign}
                                           setCurrentStep={(step) => {
                                             setCurrentStep(step);
                                             if (isCreate) {
                                               saveCampaign(false, isCreate, true);
                                             }
                                           }}
                                           onUpdateBotSchema={updateBotSchema}/>}

            {currentStep === 1 && <BotBuilder campaign={currentCampaign}
                                              setCampaign={setCurrentCampaign}
                                              campaignType={CAMPAIGN_TYPES.landbot.value}
                                              isCreate={isCreate}
                                              type={type}
                                              onClearHistory={onClearHistory}
                                              currentStep={currentStep}
                                              onSave={saveCampaign}
                                              setCurrentStep={(step) => {
                                                setCurrentStep(step);
                                                if (isCreate) {
                                                  saveCampaign(false, false, true);
                                                }
                                              }}/>}

            {currentStep === 2 && <DesignSettings campaign={currentCampaign}
                                                  setCampaign={setCurrentCampaign}
                                                  isCreate={isCreate}
                                                  type={type}
                                                  currentStep={currentStep}
                                                  onSave={saveCampaign}
                                                  setCurrentStep={(step) => {
                                                    setCurrentStep(step);
                                                    if (isCreate) {
                                                      saveCampaign(false, false, true);
                                                    }
                                                  }}/>}

            {currentStep === 3 && <LandbotDisplaySettings campaign={currentCampaign}
                                                          setCampaign={setCurrentCampaign}
                                                          isCampaignSaving={isCampaignSaving}
                                                          isCreate={isCreate}
                                                          type={type}
                                                          currentStep={currentStep}
                                                          setCurrentStep={setCurrentStep}
                                                          onSave={saveCampaign}/>}
          </Fragment>
        }
        {type === CAMPAIGN_TYPES.facebookMessenger.value && (
          <Fragment>
            {currentStep === 0 && <BotType campaign={currentCampaign}
                                           setCampaign={setCurrentCampaign}
                                           campaignType={CAMPAIGN_TYPES.facebookMessenger.value}
                                           isCreate={isCreate}
                                           type={type}
                                           currentStep={currentStep}
                                           onUpdateBotSchema={updateBotSchema}
                                           onSave={saveCampaign}
                                           setCurrentStep={setCurrentStep} />}

            {currentStep === 1 && <BotBuilder campaign={currentCampaign}
                                              setCampaign={setCurrentCampaign}
                                              campaignType={CAMPAIGN_TYPES.facebookMessenger.value}
                                              isCreate={isCreate}
                                              type={type}
                                              onClearHistory={onClearHistory}
                                              currentStep={currentStep}
                                              onSave={saveCampaign}
                                              setCurrentStep={setCurrentStep} />}
          </Fragment>
        )}
      </IntlWrapper>
    )
  );
}