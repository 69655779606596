import React, {Fragment, useState, useEffect} from 'react';
import update from 'immutability-helper';

import SendMessageSettings from './SendMessageSettings';
import ChoiceButtonSettings from './ChoiceButtonSettings';
import ChoiceCardSettings from './ChoiceCardSettings';
import CloseChatSettings from './CloseChatSettings';
import NotifyManagerSettings from './NotifyManagerSettings';
import GoToLiveChatSettings from './GoToLiveChatSettings';
import SendEmailSettings from './SendEmailSettings';
import AskQuestionSettings from './AskQuestionSettings';
import {t} from 'utils';
import {BOT_NODE_TYPES, HISTORY_EVENT_TYPES} from 'constants.js';


export default function SettingsWrapper(props) {
  const {currentNode, onChangeNodes, onErrorFactSet, onCloseSettings, onChangeHistory} = props;
  const type = currentNode ? currentNode.type : '';
  const [localNode, setLocalNode] = useState(currentNode);

  useEffect(() => {
    setLocalNode(currentNode);
  }, [currentNode]);


  const handleNodeChange = (data) => {
    onChangeNodes({
      [localNode.id]: data
    }, true);
    setLocalNode(update(localNode, data));
  };

  const handleCancel = () => {
    onCloseSettings();
    onChangeNodes({
      [localNode.id]: {$set: currentNode}
    });
  };

  const handleSave = () => {
    onChangeHistory({
      type: HISTORY_EVENT_TYPES.edit,
      node_id: currentNode.id,
      body: currentNode
    }, {
      type: HISTORY_EVENT_TYPES.edit,
      node_id: localNode.id,
      body: {$set: localNode}
    });
    onCloseSettings();
  };

  const _t = (value,  values = {}, children = null) => {
    return t(`web.create.botBuilder.${type}.${value}`, values, children);
  };

  return (
    <div className={`sidebar-tab-cont settings-wrap ${!!localNode ? 'node-sidebar-open' : 'node-sidebar-close'}`}>
      <div className="dn-settings-header-w">
        <div className="dn-settings-header">{localNode ? t(`web.create.botBuilder.${localNode.type}`) : ''}</div>
        <div className="close-btn" onClick={handleCancel}>
          <div className="close-line-1 rotate"></div>
          <div className="close-line-1"></div>
        </div>
      </div>
      <div className="divider"></div>
      {localNode && (
        <div className="node-settings">
          {type === BOT_NODE_TYPES.sendMessage && (
            <SendMessageSettings t={_t} currentNode={localNode} onChangeNode={handleNodeChange}
                                 onErrorFactSet={onErrorFactSet} onSave={handleSave} onCancel={handleCancel}/>)}
          {type === BOT_NODE_TYPES.choiceButton && (
            <ChoiceButtonSettings t={_t} currentNode={localNode} onChangeNode={handleNodeChange}
                                  onErrorFactSet={onErrorFactSet} onSave={handleSave} onCancel={handleCancel}/>)}
          {type === BOT_NODE_TYPES.choiceCard && (
            <ChoiceCardSettings t={_t} currentNode={localNode} onChangeNode={handleNodeChange}
                                onErrorFactSet={onErrorFactSet} onSave={handleSave} onCancel={handleCancel}/>)}
          {type === BOT_NODE_TYPES.askQuestion && (
            <AskQuestionSettings t={_t} currentNode={localNode} onChangeNode={handleNodeChange}
                                 onErrorFactSet={onErrorFactSet} onSave={handleSave} onCancel={handleCancel}/>)}
          {type === BOT_NODE_TYPES.sendEmail && (
            <SendEmailSettings t={_t} currentNode={localNode} onChangeNode={handleNodeChange}
                               onErrorFactSet={onErrorFactSet} onSave={handleSave} onCancel={handleCancel}/>)}
          {type === BOT_NODE_TYPES.goToLiveChat && (
            <GoToLiveChatSettings t={_t} currentNode={localNode} onChangeNode={handleNodeChange}
                                  onErrorFactSet={onErrorFactSet} onSave={handleSave} onCancel={handleCancel}/>)}
          {type === BOT_NODE_TYPES.notifyManager && (
            <NotifyManagerSettings t={_t} currentNode={localNode} onChangeNode={handleNodeChange}
                                   onErrorFactSet={onErrorFactSet} onSave={handleSave} onCancel={handleCancel}/>)}
          {type === BOT_NODE_TYPES.closeChat && (
            <CloseChatSettings t={_t} currentNode={localNode} onChangeNode={handleNodeChange}
                               onErrorFactSet={onErrorFactSet} onSave={handleSave} onCancel={handleCancel}/>)}
        </div>
      )}
    </div>
  );
};