import React from 'react';
import ErrorBlock from 'components/widgets/ErrorBlock';
import TimeRow from "./TimeRow";


export default function Schedule(props) {
  const {schedule, timeFormat, errors = {},
    handleChangeSchedule, handleAddSchedule, handleDeleteSchedule, toggleDayEnabled} = props;

  const onAddSchedule = (day) => {
    handleAddSchedule({[day]: {intervals: {$push: [{'from': '08:00', 'until': '18:00'}]}}});
  };

  const onChangeSchedule = (day, key, value, i) => {
    if (!value) {
      value = '00:00';
    }
    handleChangeSchedule({[day]: {intervals: {[i]: {[key]: {$set: value}}}}});
  };

  const onDeleteSchedule = (day, ind) => {
    handleDeleteSchedule({[day]: {intervals: {$splice: [[ind, 1]]}}});
  };

  return (
    <>
      {Object.keys(schedule).map((day, weekDayIdx) =>
        <div className="day-row" key={day}>
          <div className="error-info-client flex-column">
            <label className="w-checkbox checkbox-field-2 fix-w">
              <input type="checkbox" id={day} name="client-info" data-name="client info"
                     className="w-checkbox-input checkbox-3"
                     checked={schedule[day].enabled ? 'checked' : 'none'}
                     onChange={() => toggleDayEnabled({[day]: {$toggle: ['enabled']}})}
              />
              <div className={`check-div right-indent ${schedule[day].enabled ? 'checked' : ''}`}>
                <img src={require('styles/images/tick-sign.svg')} alt=""/>
              </div>
              <span htmlFor={day} className="w-form-label">
                                  {day[0].toUpperCase() + day.substr(1)}:
                              </span>
            </label>
            <ErrorBlock error={errors[day] || null} addClass="align-left-small"/>
          </div>

          {schedule[day].enabled && (<div className="range-hints-wrap">
            {schedule[day].intervals.map((interval, timeRangeIdx) =>
              <TimeRow key={timeRangeIdx} day={day} interval={interval} timeRangeIdx={timeRangeIdx} timeFormat={timeFormat}
                       onChangeSchedule={onChangeSchedule} onDeleteSchedule={onDeleteSchedule} onAddSchedule={onAddSchedule}/>
            )}
            {schedule[day].intervals.length === 0 && (
              <a href="#" className="btn-add-range no-abs-position"
                onClick={() => onAddSchedule(day)}>+</a>)}
          </div>)}
        </div>
      )}
    </>
  );
}