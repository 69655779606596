import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import LiveVisitors from './LiveVisitors';
import {main, liveVisitors} from 'translate';

export default compose(
  connect((state, ownProps) => {
      return {
        messages: [main, liveVisitors]
      };
    }
  ),

  lifecycle({
    componentDidMount() {
      document.title = 'Live Visitors | HeyCom'
    },
  }),

  withHandlers()
) (
  LiveVisitors
);