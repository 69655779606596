import React, {Fragment, useState, useEffect} from 'react';

import SettingsWrapper from './SettingsWrapper';
import {useBuildBot} from 'hooks';
import {t} from 'utils';
import {BOT_NODE_TYPES, CAMPAIGN_TYPES} from 'constants.js';


export default function BotSettings(props) {
  const {campaignType} = props;
  const {selectedBlockType, currentNode, setErrorFact, setSelectedBlockType,
    setCurrentNode, onAddNode, onChangeNodes, onChangeHistory} = useBuildBot();

  const blocks = [
    {
      type: BOT_NODE_TYPES.sendMessage,
      icon: <div className="component-input-div"><div className="component-input-line"></div></div>,
      content: {
        sections: [{
          text: 'The welcome message is the first message your users will see when they visit your chatbot.',
          type: 'text'
        }]
      }
    },
    {
      type: BOT_NODE_TYPES.choiceButton,
      icon: <div className="component-btn-div"><div></div></div>,
      content: {
        text: 'The welcome message is the first message your users will see when they visit your chatbot.',
        buttons: [
          {text: 'Button 1', next_block_id: null}
        ],
        transfer_to_operator: false,
        transfer_sections: [{
          text: 'The welcome message is the first message your users will see when they visit your chatbot.',
          type: 'text'
        }]
      }
    },
    {
      type: BOT_NODE_TYPES.choiceCard,
      icon: <Fragment><div className="component-card-div"></div><div className="component-card-div"></div></Fragment>,
      content: {
        cards: [{
          title: '',
          description: '',
          url: '',
          buttons: [{
            text: 'Button 1',
            next_block_id: null
          }],
          attachment: {
            file: '',
            type: 'file'
          }
        }],
        transfer_to_operator: false,
        transfer_sections: [{
          text: 'The welcome message is the first message your users will see when they visit your chatbot.',
          type: 'text'
        }]
      }
    },
    {
      type: BOT_NODE_TYPES.askQuestion,
      icon: <Fragment></Fragment>,
      content: {
        question: 'What would you like to ask?',
        validator: 'none',
        error_message: 'Oops... It doesn\'t look like a ...',
        number_of_repeats: 0,
        save_answer_as_property: false,
        contact_property: null
      }
    },
    {
      type: BOT_NODE_TYPES.sendEmail,
      icon: <Fragment></Fragment>,
      content: {
        subject: 'New message from',
        message: 'Type in a message that will be sent to a visitor...'
      }
    },
    {
      type: BOT_NODE_TYPES.goToLiveChat,
      icon: <Fragment></Fragment>,
      content: {
        assignation_type: 'simple',
        selected_operators: [],
        email_notification: false,
        operator_mails: []
      }
    },
    {
      type: BOT_NODE_TYPES.notifyManager,
      icon: <Fragment></Fragment>,
      content: {
        message: 'Type in a message you want to receive when the scenario is completed, e.g. \"The visitor has abandoned a cart.\"'
      }
    },
    {
      type: BOT_NODE_TYPES.closeChat,
      icon: <Fragment></Fragment>,
      content: {
        message: 'Type here your Close chat message, that user will see when a chat will be ended.',
        transfer_to_operator: false,
        transfer_sections: [{
          text: 'The welcome message is the first message your users will see when they visit your chatbot.',
          type: 'text'
        }]
      }
    },
  ];

  const [tooltipVisibility, changeTooltipVisibility] = useState(false);

  return (
    <div className="editor-sidebar-wrap">
      <div className="sidebar-tab-cont">
        <div className="components-wrap">
          <div className="component-div">
            {blocks.map((block, i) => {
              // if (block.type === BOT_NODE_TYPES.choiceCard && campaignType === CAMPAIGN_TYPES.facebookMessenger.value) {
              //   return null
              // }
              return (
                <div key={i} className={`component-block w-inline-block ${selectedBlockType === block.type ? 'w--current' : ''}`}
                     onClick={() => {
                       setSelectedBlockType(block.type);
                       onAddNode(block.type, block.content);
                     }}>
                  <div className={`component-icon${block.type === BOT_NODE_TYPES.choiceCard? ' add-space' : ''}`}>
                    {block.icon}
                  </div>
                  <div>{t(`web.create.botBuilder.${block.type}`)}</div>
                  <div className="toltip-icon display-none" onMouseOver={() => changeTooltipVisibility(true)}
                       onMouseOut={() => changeTooltipVisibility(false)}></div>
                </div>
              )
            })}
          </div>
          <div className={`components-info-w ${tooltipVisibility ? 'w--open' : ''}`}>
            <div className="info-text min-indent">{t('web.create.botBuilder.tooltip')}</div>
          </div>
        </div>
      </div>
      <SettingsWrapper currentNode={currentNode}
                       campaignType={campaignType}
                       onChangeHistory={onChangeHistory}
                       onErrorFactSet={setErrorFact}
                       onChangeNodes={onChangeNodes}
                       onCloseSettings={() => {
                         setSelectedBlockType(null)
                         setCurrentNode(null)
                       }}/>
    </div>
  );
}


