import React, {useState} from 'react';
import Dropzone from 'react-dropzone';
import Loader from 'components/widgets/Loader';


export default function FileLoader(props) {
  const UPLOADED_FILE_MAX_SIZE = 1024 * 1024 * 10;
  const {children, loadFile, setErrors} = props;
  const [isLoading, setLoading] = useState(false);
  return (
    <Dropzone
      multiple={false}
      minSize={1}// 1 b
      maxSize={UPLOADED_FILE_MAX_SIZE}  // 1 Mb
      onDrop={(acceptedFiles, rejectedFiles) => {
        setLoading(true);
        if (acceptedFiles.length) {
          const file = acceptedFiles[0];
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (theFile => {
            if ((theFile.type in {'application/x-msdownload': 1})) {
              return (e) => {
                setErrors('files', 'Invalid file format');
                setLoading(false);
              };
            } else {
              return (e) => {
                setErrors('files', null);
                loadFile(theFile);
                setLoading(false);
              };
            }
          })(file);
        }
        else if (rejectedFiles.length) {
          const file = rejectedFiles[0];
          if (file.size > (UPLOADED_FILE_MAX_SIZE)) {
            setErrors('files', 'File size exceeded');
          }
          setLoading(false);
        }
      }}
    >
      {isLoading ?
        ({getRootProps, getInputProps}) => {
          return (
            <div {...getRootProps()} className="new-mess-action w-inline-block">
              <Loader isSmall={true} additionalClass="micro-loader"/>
            </div>
          );
        }
        : children}
    </Dropzone>
  );
}