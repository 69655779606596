import React, {useState, forwardRef, useEffect, useRef} from 'react';
import update from 'immutability-helper';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import CustomSelect from 'components/widgets/CustomSelect';
import DateRange from 'components/widgets/DateRange';
import {t} from 'utils';
import Alert from "react-s-alert";
import ReactModal from "react-modal";
import SubmitButton from "components/widgets/SubmitButton";
import {useLeadsCampaignTypeFilter, useLeadsFilters, useContactProperty,
  useSegments, useStateWidgets} from "hooks";


const filterOptions = {
  equals: {label: 'filters.options.equals', value: 'equals', isValueVisibility: true},
  not_equals: {label: 'filters.options.distinct', value: 'not_equals', isValueVisibility: true},
  has_value: {label: 'filters.options.exists', value: 'has_value', isValueVisibility: false},
  contains: {label: 'filters.options.contains', value: 'contains', isValueVisibility: true},
  not_contains: {label: 'filters.options.notContains', value: 'not_contains', isValueVisibility: true},
  not_set: {label: 'filters.options.notExist', value: 'not_set', isValueVisibility: false}
};

const requestFilterOptionOperators = {
  equals: {operator: ''},
  not_equals: {operator: '__ne'},
  has_value: {operator: '__ne', value: 'null'},
  contains: {operator: '__contains'},
  not_contains: {operator: '__ncontains'},
  not_set: {operator: '', value: 'null'}
};


const filterList = {
  //campaign: {label: 'filters.list.campaign', value: 'campaign', isValueVisibility: false},
  notes: {label: 'Notes', name: 'notes'},
  tags: {label: 'Tag', name: 'tags'},
  chat_started_from: {label: 'Chat started from', name: 'chat_started_from'},
  browser_language: {label: 'Browser language', name: 'browser_language'},
};

const defaultSegmentOptions = [
  {default: true, label: 'segments.all', value: 'all'}
];


export default function Filters(props) {
  const {currentWidgetId} = useStateWidgets();
  const {segmentsById, getSegments, isCreating, addSegment, isUpdating, changeSegment} = useSegments();
  const {selectedCampaignType, setSelectedCampaignType} = useLeadsCampaignTypeFilter();
  const {selectedFilters, setSelectedFilters} = useLeadsFilters();
  const {contactPropertiesByName} = useContactProperty(filterList);

  const segmentOptions = defaultSegmentOptions.concat(Object.values(segmentsById));
  const [selectedSegment, setSelectedSegment] = useState(segmentOptions[0]);

  const [filters, setFilters] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [lastAppliedFilterOptions, setLastAppliedFilterOptions] = useState([]);
  const [isFiltersVisibility, setFilterVisibility] = useState(false);

  const [saveSegmentModalVisibility, setSaveSegmentModalVisibility] = useState(false);

  useEffect(() => {
    if (dateRange && Object.keys(dateRange).length) {
      const params = getRequestFilterParams();
      setSelectedFilters(update(selectedFilters, {
        $set: params
      }));
    }
  }, [selectedSegment, dateRange, lastAppliedFilterOptions]);

  useEffect(() => {
    setFilters([]);
    setSelectedSegment(segmentOptions[0]);
  }, [selectedCampaignType, currentWidgetId]);

  const isOptionsChanged = (filter) => {
    const isValueVisible = filter.option.isValueVisibility;
    const isNotEmpty = filter.optionValue || !isValueVisible;
    const isOptionChanged = filter.option.value !== filter.lastAppliedOptions.option.value;
    const isOptionValueChanged = isValueVisible && (filter.optionValue !== filter.lastAppliedOptions.optionValue);
    return (isNotEmpty && (isOptionChanged || isOptionValueChanged));
  };

  const getRequestFilterParams = () => {
    // range filter
    if (!dateRange) {
      return;
    }
    const paramFilters = filters.reduce((obj, cur) => {
      const requestOption = requestFilterOptionOperators[cur.option.value];
      obj[cur.name + requestOption.operator] = requestOption.value || cur.optionValue;
      return obj;
    }, {});

    return {...dateRange, ...paramFilters};
  };

  const setOptionsVisibility = (i, isVisibility) => {
    setFilters(update(filters, {
      [i]: {
        isOptionsVisibility: {$set: isVisibility}
      }
    }));
  };

  const onAddFilter = (filter) => {
    filter.option = Object.values(filterOptions)[0];
    filter.optionValue = null;
    filter.isOptionsVisibility = false;
    filter.lastAppliedOptions = {option: filter.option, optionValue: filter.optionValue};

    setFilters(update(filters, {
      $push: [filter]
    }));
  };

  const onChangeSegment = (segment) => {
    setSelectedSegment(segment);
    if (!(segment.default)) {
      setFilters(update(filters, {
        $apply: () => segment.filters.map((filter, i) => {
          const filterSegment = contactPropertiesByName[filter.field];
          const option = {option: filterOptions[filter.operator]};
          const optionValue = {optionValue: filter.value};
          const isOptionsVisibility = {isOptionsVisibility: false};
          const lastAppliedOptions = {lastAppliedOptions: {option: option.option, optionValue: optionValue.optionValue}};
          return Object.assign(filterSegment, option, optionValue, isOptionsVisibility, lastAppliedOptions);
        })
      }));
    } else {
      setFilters([]);
    }
  };

  const onSaveSegment = (segmentName) => {

    const body = {
      "name": segmentName,
      "date_filter": dateRange ? dateRange.type.value : 'all',
      "date_range_from": dateRange.created_at__gte ? dateRange.created_at__gte : null,
      "date_range_to": dateRange.created_at__lte ? dateRange.created_at__gte : null,
      "filters": filters.map(filter => {
        return {
          "field": filter.type ? `contact_properties.${filter.name}` : filter.name,
          "operator": filter.option.value,
          "value": filter.optionValue
        };
      })
    };
    if (segmentName === selectedSegment.name) {
      const id = selectedSegment.id;
      return changeSegment(id, body);
    } else {
      return addSegment(body);
    }
  };

  return (
    <div>
      <div className="page-content-header-div hor-flex">
        <div>
          <div className="page-header add-indent">{t('segments.title')}</div>
          <CustomSelect options={segmentOptions}
                        value={selectedSegment}
                        defaultValue={segmentOptions[0]}
                        selectClassName={`settings-select no-indent w-select`}
                        getOptionLabel={option => {
                          return option.default ? t(option.label) : option.name
                        }}
                        getOptionValue={option => {
                          return option.default ? option.value : option.id
                        }}
                        onChange={onChangeSegment}/>
        </div>
      </div>
      <div className="content-white-block hor-flex-2">
        <div className="table-filter-block">
          <DateRange onChangeRange={setDateRange} segment={selectedSegment}/>
          {filters.map((filter, i) =>
            <div key={i} className="filter-drop no-indent-2 w-dropdown"
                 onMouseOut={() => setOptionsVisibility(i, false)} onMouseOver={() => setOptionsVisibility(i, true)}>
              <div className="filter-drop-tog no-arrow w-dropdown-toggle">
                <img src={require('styles/images/filter.svg')} alt="" className="filter-icons"/>
                <div className="margins"
                     onClick={() => setOptionsVisibility(i, !filter.isOptionsVisibility)}>{filter.label}</div>
                <img src={require('styles/images/close-button.svg')} alt=""
                     className="filter-icons close"
                     onClick={() => {
                       setFilters(update(filters, {
                         $splice: [[i, 1]]
                       }));
                       setLastAppliedFilterOptions(update(lastAppliedFilterOptions, {
                           $splice: [[i, 1]]
                       }));
                     }}/>
              </div>
              <nav
                className={`dropdown-list add-padding w-dropdown-list ${filter.isOptionsVisibility ? 'w--open' : ''}`}>
                <div className="filter-settings">
                  <CustomSelect options={Object.values(filterOptions)}
                                defaultValue={filter.option}
                                selectClassName={`settings-select w-select`}
                                getOptionLabel={option => t(option.label)}
                                onChange={(option) => {
                                  setFilters(update(filters, {
                                    [i]: {
                                      option: {$set: option}
                                    }
                                  }));
                                }}/>
                  {
                    filter.option.isValueVisibility && (
                      <input type="text"
                         value={filter.optionValue || ''}
                         className="node-settings-input w-input" maxLength="256"
                         placeholder="Value here..." required="" onChange={(evt) => {
                            setFilters(update(filters, {
                              [i]: {
                                optionValue: {$set: evt.target.value}
                              }
                            }));
                         }}
                      />
                    )
                  }
                  <div className="blue-button full-w w-button"
                       style={isOptionsChanged(filter) ? {} : {pointerEvents: 'none', opacity: 0.4}}
                       onClick={() => {
                         setFilters(update(filters, {
                           [i]: {
                             lastAppliedOptions: {$set: {option: filter.option, optionValue: filter.optionValue}}
                           }
                         }));
                         setLastAppliedFilterOptions(update(lastAppliedFilterOptions, {
                           [i]: {
                             $set: {option: filter.option, optionValue: filter.optionValue}
                           }
                         }));
                       }}>Apply
                  </div>
                </div>
              </nav>
            </div>
          )}
          <div className="filter-drop left-indent-2 w-dropdown"
               onMouseOut={() => setFilterVisibility(false)} onMouseOver={() => setFilterVisibility(true)}>
            <div className="filter-drop-tog like-btn norm-paddings w-dropdown-toggle"
                 onClick={() => setFilterVisibility(!isFiltersVisibility)}>
              <div><span className="awesome-icon">+</span> Add filter</div>
            </div>
            <nav className={`dropdown-list w-dropdown-list ${isFiltersVisibility ? 'w--open' : ''}`}>
              {Object.values(contactPropertiesByName).map((filter, i) =>
                <div key={i} onClick={() => {
                  onAddFilter(filter);
                  setFilterVisibility(false);
                }} className="dropdown-link link-block w-inline-block">
                  <div className="choice-text">+ {filter.label}</div>
                </div>
              )}
            </nav>
          </div>
        </div>
        <div className="like-btn w-button" onClick={() => setSaveSegmentModalVisibility(true)}>{t('segments.save')}</div>
      </div>
      <SaveSegmentModal isOpen={saveSegmentModalVisibility} value={selectedSegment.name}
                        isLoading={isCreating || isUpdating} onSave={onSaveSegment}
                        onClose={() => setSaveSegmentModalVisibility(false)}/>
    </div>
  );
}


const SaveSegmentModal = (props) => {
  const {isOpen, isLoading, onClose, onSave, value} = props;
  const [error, setError] = useState(null);
  const [segmentName, setSegmentName] = useState('');

  useEffect(() => {
    setSegmentName(value || '');
  }, [value]);

  const handleSaveSegment = () => {
    if (!segmentName) {
      setError(t('error.field.empty'));
      return;
    }
    onSave(segmentName)
      .then(data => {
        if (data.error) {
          setError(data.error.message || 'Error');
        } else {
          setError(null);
          setTimeout(() => {
            handleModalClose();
            Alert.success('Successful!');
          }, 1000);
        }
      });
  };

  const handleModalClose = () => {
    onClose();
    setError(null);
  };
  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 outline-none"
      overlayClassName="popup-w w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleModalClose}
    >
      <div className="sign-header">{t('segments.modal.title')}</div>
      <div className="w-form">
        <label className="settings-item-label">{t('segments.modal.label')}</label>
        <input value={segmentName} onChange={e => setSegmentName(e.target.value)} type="text" className="node-settings-input w-input"
               maxLength="256" required=""/>
        <SubmitButton simpleButton={true} additionalClass={'right-float'} onClick={() => handleSaveSegment()}
                      isSending={isLoading} text={t('segments.modal.button')}/>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        {error && (
          <div className="w-form-fail display-block">
            <div>{error}</div>
          </div>
        )}
      </div>
      <div className="abs-position">
        <div className="delete-btn" onClick={handleModalClose}>
          <div className="close-line-1 rotate back-color"></div>
          <div className="close-line-1 back-color"></div>
        </div>
      </div>
    </ReactModal>
  );
};