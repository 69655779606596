import React, { Fragment, useState } from 'react';
import CustomSelect from 'components/widgets/CustomSelect';
import {useContactProperty} from 'hooks';
import {t, h} from 'utils';
import {randString} from 'utils';

export default function AddContactProperties(){
  const propertyTypes = [
    {value: 'text', label: 'text'},
    {value: 'url', label: 'url'},
    {value: 'email', label: 'email'},
    {value: 'number', label: 'number'},
    {value: 'phone_number', label: 'phoneNumber'},
  ];

  const [propertyType, setPropertyType] = useState(propertyTypes[0]);
  const [label, setLabel] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState(null);

  const {contactPropertiesByName, createProperty} = useContactProperty();
  const handleCreateProperty = () => {
    if (contactPropertiesByName[name]) {
      setError('Property already added');
    } else {
      createProperty({
        label, name, type: propertyType.value
      })
        .then(data => {
          if (data.error) {
            //console.log(data.error);
            setError(t(`server.error.${data.error.code}`));
          }
        });
    }

  };

  return (
    <div className="transfer-settings-w-2 overflow-unset">
      <div className="settings-item">
        <div className="settings-item-label add-margin">
          {t('newProperty.label.label')}
        </div>
        <input type="text" value={label} onChange={e => setLabel(e.target.value)}
               className="node-settings-input w-input" maxLength="256" required=""/></div>
      <div className="settings-item">
        <div className="settings-item-label add-margin">
          {t('newProperty.name.label')}
          <div className="error-info-client">
            {error && (
              <div className="error-message">{error}</div>
            )}
          </div>
        </div>
        <input type="text" value={name} onChange={e => {
            if (error) {
              setError(null);
            }
            setName(e.target.value)
          }} className="node-settings-input w-input" maxLength="256" required=""/></div>
      <div className="settings-item select-z-index">
        <div className="settings-item-label add-margin">
          {t('newProperty.type.label')}
        </div>
        <CustomSelect options={propertyTypes}
                      defaultValue={propertyType}
                      selectClassName={`settings-select w-select`}
                      getOptionLabel={option => t(`newProperty.type.${option.label}`)}
                      onChange={property => setPropertyType(property)}/>
      </div>
      <div className="button full-w w-button" onClick={handleCreateProperty}>
        {t('newProperty.create')}
      </div>
    </div>
  );
}
