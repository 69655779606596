import React, {Component, useEffect, useState} from 'react';
import update from "immutability-helper";
import ReactModal from "react-modal";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Alert from "react-s-alert";
import Loader from "components/widgets/Loader";
import TablePagination from "components/widgets/TablePagination";
import SubmitButton from "components/widgets/SubmitButton";
import ImageLoader from "components/widgets/ImageLoader";
import ModalWarning from 'components/widgets/ModalWarning';
import ErrorBlock from 'components/widgets/ErrorBlock';
import {useTablePagination, useMeetingLinks, useSendFiles, useWidgets,
  useOperators, useGetCurrentOperator} from 'hooks';
import {getOperatorUserName, t} from 'utils';


export default function Links(props) {
  const tableHeaders = ['meetingName', 'duration', 'manager', 'options'];
  const [modalVisibility, setModalVisibility] = useState(false);
  const {currentWidgetId} = useWidgets();
  const [currentOperator] = useGetCurrentOperator();
  const {operatorsById} = useOperators();
  const {meetingLinksById, meetingLinksCount, meetingSettings, isMeetingLinksFetching, isMeetingLinkAdding,
    getMeetingLinks, getMeetingLink, addMeetingLink, deleteMeetingLink, editMeetingLink, copyMeetingLink} = useMeetingLinks();
  const {itemsOnPage, pageCount, pageNumber, tableRowCount, rowCounts,
    setPageNumber, setTableRowCount} = useTablePagination(meetingLinksById, meetingLinksCount, getMeetingLinks);
  const [selectedLink, setSelectedLink] = useState({});
  const [isWarningShow, showWarning] = useState(false);

  useEffect(() => {
    getMeetingLinks({
      offset: 0,
      limit: tableRowCount
    });
  }, [currentWidgetId]);


  const togglePage = page => {
    setPageNumber(page.selected)
  };

  const copyLink = (text, result) => {
    if (result) {
      Alert.success('Link has been copied');
    } else {
      Alert.error('An error occurred while copying');
    }
  };

  const _t = (value, values = {}, children = null) => {
    return t(`meetings.links.${value}`, values, children);
  };

  if (isMeetingLinksFetching) {
    return <Loader isSmall={true} additionalOverlayClass="meetings-loader-overlay"/>
  }

  return (
    <div className='campaigns-preview-w'>
      <div className='content-white-block'>
        <div className='page-content-header-div hor-flex justify-left'>
          <div className='blue-button w-button' onClick={() => setModalVisibility(true)}>
            <span className='awesome-icon big-icon'></span>
            <span> </span>
            {_t('button.label')}
          </div>
          <div className='paragraph-w'>
            <div className='page-subheader no-margin'>{_t('table.title')}</div>
          </div>
        </div>
        <div className='content-white-block table-container-2'>
          <div className='table-w'>
            <div className='table-row table-header'>
              {tableHeaders.map((header,index) =>
                <div key={header} className='table-col1'>
                  <div className="details-header">{_t(`table.header.${header}`)}</div>
                </div>
              )}
            </div>
            <div className="table-content">
              {Object.values(meetingLinksById).length > 0 && itemsOnPage.map((link, i) =>
                <div key={link.id} className='table-row'>
                  <div className='table-col1'>
                    {link.isDefault ? (
                      <div className="badge badge-default">Default</div>
                    ) : (
                      <div className='cp-info'>{link.name}</div>
                    )}
                  </div>
                  <div className='table-col1'>
                    <div className='cp-info'>{`${link.duration} minutes`}</div>
                  </div>
                  <div className='table-col1'>
                    <div className='chat-avatar-2'>
                      {link.operatorId && operatorsById[link.operatorId] && operatorsById[link.operatorId].user && (
                        <>
                        {operatorsById[link.operatorId].user.avatar.url ? (
                          <img className="operator-avatar-meeting" src={operatorsById[link.operatorId].user.avatar.url}/>
                        ) : (
                          <div>{getOperatorUserName(operatorsById[link.operatorId]) ?
                            getOperatorUserName(operatorsById[link.operatorId])[0].toUpperCase() : 'O'}</div>
                        )}
                        </>
                      )}
                    </div>
                    <div className='cp-info'>
                      <strong>{getOperatorUserName(operatorsById[link.operatorId])}</strong></div>
                  </div>
                  <div className="table-col1">
                    <CopyToClipboard text={link.url}
                                     onCopy={copyLink}>
                      <div className="action-btn right-indent w-inline-block">
                        <div><span className="awesome-icon"></span> Copy link</div>
                      </div>
                    </CopyToClipboard>

                    {link.operatorId === currentOperator.id && (
                      <>
                        <div className="action-btn w-inline-block" onClick={() => {
                          setSelectedLink(link);
                          setModalVisibility(true);
                        }}>
                          <div><span className="awesome-icon"></span> Edit</div>
                        </div>
                        {!link.isDefault && (
                          <div className="action-btn delete-btn no-margin w-inline-block"
                               onClick={() => {
                                 setSelectedLink(link);
                                 showWarning(true);
                               }}>
                            <div><span className="awesome-icon"></span> Delete</div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="table-settings right-float">
            <TablePagination itemsLength={itemsOnPage.length} rowCounts={rowCounts} pageCount={pageCount}
                             tableRowCount={tableRowCount} onTogglePage={togglePage}
                             setTableRowCount={setTableRowCount} pageNumber={pageNumber}/>
          </div>
      </div>
      </div>
      <ModalWarning
        isOpen={isWarningShow}
        text={_t('modal.confirm.text')}
        onConfirm={() => {
          deleteMeetingLink(selectedLink.id);
          showWarning(false);
          setSelectedLink({});
        }}
        onClose={() => {
          showWarning(false);
          setSelectedLink({});
        }}
      />
      <MeetingLinkModal
        selectedLink={selectedLink}
        operatorsById={operatorsById}
        currentOperatorId={currentOperator.id}
        meetingSettings={meetingSettings}
        isEditMode={selectedLink.id}
        isOpen={modalVisibility}
        onClose={() => {
          if (selectedLink.id) {
            setSelectedLink({});
          }
          setModalVisibility(false);
        }}
        onAddMeetingLink={addMeetingLink}
        onEditMeetingLink={editMeetingLink}
        onGetMeetingLink={getMeetingLink}
        isCreating={false}
        _t={_t}
      />
    </div>
  );
};

const MeetingLinkModal = (props) => {
  const durations = [
    {title: '15 minutes', value: 15},
    {title: '30 minutes', value: 30},
    {title: '45 minutes', value: 45},
    {title: '60 minutes', value: 60},
  ];

  const {selectedLink, meetingSettings, isEditMode, operatorsById, currentOperatorId, isOpen,
    onClose, onAddMeetingLink, onEditMeetingLink, onGetMeetingLink,
    isCreating, _t} = props;

  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [customUrlPostfix, setCustomUrlPostfix] = useState(''); //TODO add validation
  const [meetingUrlWithoutPostfix, setMeetingUrlWithoutPostfix] = useState('');
  const [title, setTitle] = useState('');
  const [avatar, setAvatar] = useState({url: '', filename: '', mimetype: ''});
  const [duration, setDuration] = useState(durations[0].value);
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const [isCreated, setIsCreated] = useState(false);
  const sendFiles = useSendFiles();
  const [errors, setErrors] = useState({link: null, saving: null, name: null});

  useEffect(() => {
    if (selectedLink.id) {
      setName(selectedLink.name || '');
      setLink(selectedLink.url || '');
      setCustomUrlPostfix(selectedLink.customUrlPostfix || '');
      if (!meetingUrlWithoutPostfix) {
        setMeetingUrlWithoutPostfix(selectedLink.meetingUrlWithoutPostfix);
      }
      setTitle(selectedLink.title || '');
      setAvatar(selectedLink.avatar || {url: '', filename: '', mimetype: ''});
      setDuration(selectedLink.duration); //TODO: for editing
      setLocation(selectedLink.location || '');
      setDescription(selectedLink.description || '');
    }
  }, [selectedLink]);

  useEffect(() => {
    if (!meetingUrlWithoutPostfix) {
      setMeetingUrlWithoutPostfix(meetingSettings.default_meeting_link.meeting_url_without_postfix);
    }
  }, [meetingSettings]);

  const validateFields = () => {
    let newErrors = {};
    const checkUrl = /^[a-zA-Z0-9_-]+$/;

    if (!checkUrl.test(customUrlPostfix)) {
      newErrors = {...newErrors, link: 'Prefix not valid'};
    }

    if (!name) {
      newErrors = {...newErrors, name: 'Field must not be empty '};
    }

    if (newErrors.link || newErrors.name) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (validateFields()) {
      if (avatar && avatar.preview) {
        _getAvatarUrl(avatar)
          .then(fileData => {
            if (isEditMode) {
              handleMeetingLinkEdit(fileData);
            } else {
              handleMeetingLinkAdd(fileData);
            }
          })
      } else {
        if (isEditMode) {
          handleMeetingLinkEdit(avatar);
        } else {
          handleMeetingLinkAdd(avatar);
        }
      }
    }
  };

  const handleMeetingLinkAdd = (file) => {
    onAddMeetingLink({
      name: name,
      duration: duration,
      custom_url_postfix: customUrlPostfix,
      avatar: file,
      title: title,
      location: location,
      description: description,
    })
      .then(data => {
        successMessage(data);
      });
  };

  const handleMeetingLinkEdit = (file) => {
    onEditMeetingLink({
      name: name,
      duration: duration,
      custom_url_postfix: customUrlPostfix,
      avatar: file,
      title: title,
      location: location,
      description: description,
    }, selectedLink.id)
      .then(data => {
        successMessage(data);
      });
  };

  const handleModalClose = () => {
    onClose();
    setName('');
    setLink('');
    setTitle('');
    setCustomUrlPostfix('');
    //setMeetingUrlWithoutPostfix('');
    setAvatar({url: '', filename: '', mimetype: ''});
    setDuration(durations[0].value);
    setLocation('');
    setDescription('');
    setIsCreated(false);
    setErrors({link: null, saving: null});
  };

  const successMessage = (data) => {
    if (data.code || data.errors) {
      if (data.code === 1022) {
        setErrors(update(errors, {link: {$set: data.message}}));
      } else {
        setErrors(update(errors, {saving: {$set: data.message || 'Not successful'}}));
        Alert.error(data.message || 'Not successful');
      }
    } else {
      setIsCreated(true);
      setErrors({link: null, saving: null});
      setTimeout(() => {
        handleModalClose();
        Alert.success('Successful!');
      }, 1000);
    }
  };

  async function _getAvatarUrl(file) {
    let obj = {};
    const response = handleSendFiles(file);
    await response
      .then(data => {
        obj = data.files[0];
      });
    return obj;
  }
  async function handleSendFiles(file) {
    const sendingPromise = sendFiles([file]);
    return await sendingPromise;
  }


  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 no-max-w"
      overlayClassName="popup-w-2 w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={onClose}
    >
      <div className="sign-header">{isEditMode ? _t('modal.edit.header') : _t('modal.add.header')}</div>
      <div className="w-form">
        <div>
          <div className='row'>
            <div className='label-w'>
              <div className='label-div'>
                <div className="error-info-client">
                  <div className="settings-item-label add-margin">
                    {_t('modal.name')}
                  </div>
                  <ErrorBlock error={errors.name} addClass="align-right margin-left"/>
                </div>
              </div>
            </div>

            {_t('modal.name.placeholder', {},
              msg => <input type="text" className="node-settings-input w-input" maxLength="256"
                            value={name}
                            onChange={e => {
                              if (errors.name) {
                                setErrors({...errors, name: null});
                              }
                              setName(e.target.value)
                            }}
                            placeholder={msg}/>)}
          </div>

          <div className='row'>
            <div className='label-w'>
              <div className='label-div'>
                <div className="error-info-client">
                  <div className="settings-item-label add-margin"> {_t('modal.link')}</div>
                  <ErrorBlock error={errors.link} addClass="align-right margin-left"/>
                </div>
              </div>
            </div>

            <div className='meeting-url-block'>
              <div className='meeting-url'>
                {meetingUrlWithoutPostfix}
              </div>
              {_t('modal.link.placeholder', {},
                msg => <input type="text" className="node-settings-input no-round w-input" maxLength="256"
                              value={customUrlPostfix}
                              onChange={e => {
                                if (errors.link) {
                                  setErrors({...errors, link: null});
                                }
                                setCustomUrlPostfix(e.target.value)
                              }}
                              placeholder={msg}/>)}
            </div>

          </div>

          <div className='group-row more-margin'>
            <div className='col-3'>
              <div className="settings-item-label add-margin">
                {_t('modal.avatar')}
              </div>
              <div className='avatar-add-wrap big-wrap'>
                <ImageLoader value={avatar && (avatar.url || avatar.preview) ? avatar : {url: require('styles/images/forum-user.svg')}}
                             classNames={`avatar-add-div open ${avatar && (avatar.url || avatar.preview) ? 'full-background' : ''}`}
                             defaultImgUrl={require('styles/images/forum-user.svg')}
                             imgClasses={'new-mess-action w-inline-block'}
                             onChange={file => {setAvatar(file.file)}}>
                </ImageLoader>

                <div className="avatar-control-elements">
                  <div className="control-icon" onClick={() => {
                    if (selectedLink.id) {
                      onGetMeetingLink(selectedLink)
                    }
                  }}></div>
                  <div className="control-icon delete-icon" onClick={() => setAvatar({url: '', filename: '', mimetype: ''})}></div>
                </div>
              </div>

            </div>
            <div className='col-9'>
              <div className="settings-item-label add-margin">
                {_t('modal.title')}
              </div>

              {_t('modal.title.placeholder', {},
                msg => <input type="text" className="node-settings-input w-input" maxLength="256"
                              value={title}
                              onChange={e => setTitle(e.target.value)}
                              placeholder={`${msg} ${selectedLink && selectedLink.operatorId ?
                                getOperatorUserName(operatorsById[selectedLink.operatorId]) :
                                (currentOperatorId ? getOperatorUserName(operatorsById[currentOperatorId]) : '')}`}/>)}
            </div>
          </div>

          <div className='row'>
            <div className='label-w'>
              <div className='label-div'>
                <div className="settings-item-label add-margin">
                  {_t('modal.duration')}
                </div>
              </div>
            </div>
            <div className='choose-type-w no-border w-clearfix'>
              {durations.map((item, i) =>
                <div key={i} className={`choose-item ${item.value === duration ? 'checked' : ''}`}
                     onClick={() => setDuration(item.value)}>
                  {item.title}
                </div>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='label-w'>
              <div className='label-div'>
                <div className="settings-item-label add-margin">
                  {_t('modal.location')}
                </div>
              </div>
            </div>

            <input type="text" className="node-settings-input w-input" maxLength="256"
                          value={location}
                          onChange={e => setLocation(e.target.value)}/>
          </div>

          <div className='row'>
            <div className='label-w'>
              <div className='label-div'>
                <div className="settings-item-label add-margin">
                  {_t('modal.description')}
                </div>
              </div>
            </div>

            <textarea className="node-settings-input textarea w-input" maxLength="256"
                      value={description} onChange={e => setDescription(e.target.value)}/>
          </div>

          <div className='settings-btn-w add-space-2 align-center'>
            <div className='link-2' onClick={handleModalClose}>{_t('modal.cancel.button')}</div>
            <SubmitButton simpleButton={true} additionalClass={'button left-indent w-button'}
                          onClick={handleSave} isSending={isCreating} text={_t('modal.save.button')}/>
          </div>
        </div>
      </div>
      <div className="abs-position" onClick={handleModalClose}>
        <div className="delete-btn">
          <div className="close-line-1 rotate"/>
          <div className="close-line-1"/>
        </div>
      </div>
    </ReactModal>
  );
};