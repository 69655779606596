export const handlerWrapper = () => {
  const cache = [];

  return (fn, args = []) => {

    // debugger;

    const argsLength = args.length;

    let item = cache.find(item => {
      if (fn !== item.initFn || argsLength !== item.args.length) {
        return false;
      }
      for (let i = 0, l = argsLength; i < l; ++i) {
        if (args[i] !== item.args[i]) {
          return false;
        }
      }
      return true;
    });

    if (!item) {
      item = {
        initFn: fn,
        args,
        handler: () => fn(...args),
      };
      cache.push(item);
    }

    return item.handler;
  };
};

/**
 * Sets delay for dispatched thunked action. Delay is applied to given
 * action types which are dispatched by the main thunked action.
 */
export const withDelay = (finalActionTypes = [], delay = 1) => {
  return dispatchedFunc => {
    return (...args) => {
      const thunk = dispatchedFunc(...args);

      return (dispatch, getState) => {
        let timerStopped = false;
        let actionsToDispatch = [];

        const enhancedDispatch = action => {
          if (finalActionTypes.indexOf(action.type) >= 0 && !timerStopped) {
            actionsToDispatch.push(action);
          } else {
            return dispatch(action);
          }
        };

        window.setTimeout(() => {
          timerStopped = true;
          actionsToDispatch.forEach(action => dispatch(action));
        }, delay * 1000);

        return thunk(enhancedDispatch, getState);
      }
    };
  };
};
