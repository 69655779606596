import React, { Fragment, useState, useEffect, useRef } from 'react';
import ReactModal from "react-modal";
import Alert from "react-s-alert";
import update from 'immutability-helper';
import moment from 'moment-timezone';

import CustomSelect from "components/widgets/CustomSelect";
import SubmitButton from 'components/widgets/SubmitButton';
import Switch from 'components/widgets/Switch';
import Schedule from "components/widgets/Schedule";
import {TIME_FORMATS} from "constants.js";
import {t} from 'utils';

export default function Notifications(props) {
  const {operator, setOperator, currentUser, updateCurrentUser, onSaveOperatorNotificationSettings} = props;

  const [operatorNotifications, setOperatorNotifications] = useState(operator.notifications);
  const [errors, setErrors] = useState({schedule: {}});

  useEffect(() => {
    setOperatorNotifications(operator.notifications);
  }, [operator]);

  // useEffect(() =>
  //     setOperator(update(operator, {notifications: {$set: operatorNotifications}})),
  //     [operatorNotifications]
  // );

  const handleSettingsSave = () => {
    const errors = {schedule: {}};
    if (operatorNotifications.online_mode.enabled) {
      Object.keys(operatorNotifications.online_mode.schedule).forEach(day => {
        const intervals = operatorNotifications.online_mode.schedule[day].intervals;
        intervals.forEach((interval, i) => {
          if (interval.from >= interval.until) {
            errors.schedule[day] = 'Incorrect Intervals';
            return;
          }
          if (i > 0) {
            if (intervals[i].from < intervals[i - 1].until) {
              errors.schedule[day] = 'Incorrect Intervals';
              return;
            }
          }
        })
      });
    }

    if (Object.keys(errors.schedule).length) {
      setErrors(errors);
      return;
    }
    onSaveOperatorNotificationSettings({...operator, notifications: operatorNotifications});
  };

  const changeOperatorNotification = (settings) => {
    if (settings.online_mode && settings.online_mode.schedule) {
      setErrors({...errors, schedule: {}});
    }
    setOperatorNotifications(update(operatorNotifications, settings));
  };

  const _t = (value, values = {}, children = null) => {
    return t(`notifications.${value}`, values, children);
  };

  return (
    <div className="pane-content-w">
      <ChatNotifications chatNotificationSettings={operatorNotifications.chat} onSave={changeOperatorNotification} _t={_t}/>
      
      <EmailNotifications emailNotificationSettings={operatorNotifications.email} onSave={changeOperatorNotification} _t={_t}/>

      <WorkingHours workingHoursSettings={operatorNotifications.online_mode}
                    errors={errors} onSave={changeOperatorNotification}
                    timeZone={currentUser.timezone} updateCurrentUser={updateCurrentUser} _t={_t}/>

      <div className="settings-btn-w">
        <div className="button w-button" onClick={handleSettingsSave}>Save</div>
      </div>
    </div>
  );
}

const ChatNotifications = (props) => {
  const {chatNotificationSettings, onSave, _t} = props;
  const sounds = {
    'dong': {value: 'dong', label: 'Dong'},
    'bong': {value: 'bong', label: 'Bong'},
    'coin-echo': {value: 'coin-echo', label: 'Coin Echo'},
    'digital-life': {value: 'digital-life', label: 'Digital Life'},
    'disco-clap': {value: 'disco-clap', label: 'Disco Clap'},
    'infinite-life': {value: 'infinite-life', label: 'Infinite Life'},
    'jingle': {value: 'jingle', label: 'Jingle'},
    'menu-click': {value: 'menu-click', label: 'Menu-click'},
    'notification': {value: 'notification', label: 'Notification'},
    'plang': {value: 'plang', label: 'Plang'},
    'call': {value: 'call', label: 'Call (repeats continuously)'},
  };
  return (
    <div className="content-white-block">
      <div>
        <div className="group-title">{_t(`chat.header`)}</div>
      </div>
      <div className="group-content-w">
        <div className="group-w">
          <div className="group-w-header">
            <div className="group-title">{_t(`chat.requestSound.enable.label`)}</div>
            <Switch isOn={chatNotificationSettings.new_chat_request_sound.enabled}
                    onClick={() => onSave({chat: {new_chat_request_sound: {$toggle: ['enabled']}}})}/>
          </div>
          {chatNotificationSettings.new_chat_request_sound.enabled && (
            <div className="show-group-content hide">
              <div className="show-group-div">
                <div className="group-row">
                  <div className="col-6">
                    <CustomSelect options={Object.values(sounds)}
                                  defaultValue={Object.values(sounds)[0]}
                                  selectClassName={`settings-select w-select`}
                                  getOptionLabel={option => option.label}
                                  onChange={sound =>
                                      onSave({chat: {new_chat_request_sound: {sound: {$set: sound.value}}}})}
                                  value={sounds[chatNotificationSettings.new_chat_request_sound.sound]}
                    />
                  </div>
                  <div className="col-6 right-padding"/>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="group-w">
          <div className="group-w-header">
            <div className="group-title">{_t(`chat.messageSound.enable.label`)}</div>
            <Switch isOn={chatNotificationSettings.new_chat_message_sound.enabled}
                    onClick={() => onSave({chat: {new_chat_message_sound: {$toggle: ['enabled']}}})}/>
          </div>
          {chatNotificationSettings.new_chat_message_sound.enabled && (
            <div className="show-group-content hide">
              <div className="show-group-div">
                <div className="group-row">
                  <div className="col-6">
                    <CustomSelect options={Object.values(sounds)}
                                  defaultValue={Object.values(sounds)[0]}
                                  selectClassName={`settings-select w-select`}
                                  getOptionLabel={option => option.label}
                                  onChange={sound =>
                                      onSave({chat: {new_chat_message_sound: {sound: {$set: sound.value}}}})}
                                  value={sounds[chatNotificationSettings.new_chat_message_sound.sound]}
                    />
                  </div>
                  <div className="col-6 right-padding"/>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  )
};

const EmailNotifications = (props) => {
  const {emailNotificationSettings, onSave, _t} = props;

  const handleAddRecipient = () => {
    onSave({email: {recipients: {$push: ['']}}});
  };

  const handleChangeRecipientEmail = (value, i) => {
    onSave({email: {recipients: {[i]: {$set: value}}}});
  };

  const handleDeleteRecipient = ind => {
    onSave({email: {recipients: {$splice: [[ind, 1]]}}});
  };

  return (
    <div className="content-white-block">
      <div>
        <div className="group-title">{_t(`email.header`)}</div>
        <div className="page-subheader">{_t(`email.subheader`)}</div>
      </div>
      <div className="group-content-w">
        <div className="group-w">
          <div className="group-w-header">
            <div className="group-title">{_t(`email.enable.label`)}</div>
            <Switch isOn={emailNotificationSettings.enabled} onClick={() => onSave({email: {$toggle: ['enabled']}})}/>
          </div>
          {emailNotificationSettings.enabled && (<div className="show-group-content">
            <div className="show-group-div">
              <div className="group-row">

                <div className="col-6">
                  <div className="settings-item-label add-margin">{_t(`sendTo.label`)}</div>
                  {emailNotificationSettings.recipients.map((recipient, i) =>
                      <div key={i}>
                        <div className={`${i !== 0 ? 'input-div-2' : ''}`}>
                          <input type="text" value={recipient} className="node-settings-input w-input"
                                 onChange={(e) =>
                                     handleChangeRecipientEmail(e.target.value, i)}
                                 maxLength="256" />
                          {i !== 0 && (
                              <div className="delete-btn" onClick={() => handleDeleteRecipient(i)}>
                                <div className="close-line-1 rotate"/>
                                <div className="close-line-1"/>
                              </div>
                          )}
                        </div>
                      </div>
                  )}
                  <div className="link-2" onClick={handleAddRecipient}>{_t(`sendTo.button`)}</div>
                </div>
              </div>
            </div>
          </div>)}
        </div>
      </div>
    </div>
  )
};

const WorkingHours = (props) => {
  const {workingHoursSettings, errors, onSave, timeZone, updateCurrentUser, _t} = props;
  const [timeFormat, setTimeFormat] = useState(workingHoursSettings.timeFormat ?
    TIME_FORMATS[workingHoursSettings.timeFormat].value : TIME_FORMATS["24h"].value);
  const [timeZonesModalVisibility, setTimeZonesModalVisibility] = useState(false);
  const workingHoursRef = useRef(null);

  useEffect(() => {
    if (errors.schedule && Object.keys(errors.schedule).length) {
      workingHoursRef.current.scrollIntoView();
    }
  }, [errors]);


  return (
    <div className="content-white-block">
      <div>
        <div className="group-title">{_t(`onlineMode.header`)}</div>
        <div className="page-subheader">{_t(`onlineMode.subheader`)}</div>
      </div>
      <div className="group-content-w" ref={workingHoursRef}>
        <div className="group-w">
          <div className="group-w-header">
              <div className="group-title">{_t(`onlineMode.enable.label`)}
                <a href="#" className="link-2 small-link"
                   onClick={() => setTimeZonesModalVisibility(!timeZonesModalVisibility)}> ({timeZone.name})</a>
              </div>
              <Switch isOn={workingHoursSettings.enabled} onClick={() => onSave({online_mode: {$toggle: ['enabled']}})}/>
          </div>

          {workingHoursSettings.enabled && (
            <div className="show-group-content">
              <div className="show-group-div">
                <div className="group-row">
                  <div className="col-6 right-padding">
                    <div className="working-days-block">
                      <div className='time-zone-settings'>
                        <CustomSelect options={Object.values(TIME_FORMATS)}
                                      defaultValue={Object.values(TIME_FORMATS)[0]}
                                      selectClassName={`settings-select w-select time-format no-left-margin`}
                                      getOptionLabel={option => option.label}
                                      onChange={format =>
                                      {onSave({online_mode: {timeFormat: {$set: format.value}}}); setTimeFormat(format.value)}}
                                      value={TIME_FORMATS[timeFormat]}/>
                      </div>
                      <Schedule schedule={workingHoursSettings.schedule}
                                errors={errors.schedule}
                                timeFormat={TIME_FORMATS[timeFormat].value}
                                handleChangeSchedule={data => onSave({online_mode: {schedule: data}})}
                                handleAddSchedule={data => onSave({online_mode: {schedule: data}})}
                                handleDeleteSchedule={data => onSave({online_mode: {schedule: data}})}
                                toggleDayEnabled={data => onSave({online_mode: {schedule: data}})}/>
                    </div>
                  </div>
                  <div className="col-6"/>
                </div>
              </div>
            </div>
          )}

          <TimeZoneSelectModal
            isOpen={timeZonesModalVisibility}
            currentTimeZone={timeZone}
            onUpdate={updateCurrentUser}
            onClose={() => setTimeZonesModalVisibility(false)}
          />
        </div>
      </div>
    </div>
  )
};

const TimeZoneSelectModal = (props) => {
  const {isOpen, currentTimeZone,  onUpdate, onClose} = props;
  const [timeZone, setTimeZone] = useState(currentTimeZone);
  const [error, setError] = useState(null);
  const [isCreated, setIsCreated] = useState(false);

  const timeZones = moment.tz.names();
  const timeZonesWithOffset = timeZones.reduce((obj, cur) => {
    obj[cur] = {
      value: cur,
      offset: moment.tz(cur)._offset,
      label: `${cur} (GMT${moment.tz(cur).format('Z')})`
    };
    return obj;
  }, {});

  const handleUpdate = () => {
    onUpdate({timezone: timeZone}).then(data => {
      if (data && data.code) {
        setError(data.message || 'Error');
      } else {
        setIsCreated(true);
        setError(null);
        setTimeout(() => {
          handleModalClose();
          Alert.success('Successful!');
        }, 1000);
      }
    })
  };

  const handleModalClose = () => {
    onClose();
    setError(null);
    setIsCreated(false);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 outline-none overflow-unset"
      overlayClassName="popup-w-2 w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={onClose}
    >
      <div className="sign-header">TimeZone</div>
      <div className="w-form">
        <div id="email-form-4" name="email-form-4" data-name="Email Form 4">
          <div className="filter-drop w-dropdown">
            <CustomSelect options={Object.values(timeZonesWithOffset)}
                          defaultValue={timeZonesWithOffset[timeZone.name]}
                          selectClassName={`settings-select w-select`}
                          getOptionLabel={option => option.label}
                          onChange={tz => setTimeZone(update(timeZone, {$set: {name: tz.value, offset: tz.offset}}))}
                          value={timeZonesWithOffset[timeZone.name]}
            />
          </div>

          <div className="settings-btn-w">
            <SubmitButton simpleButton={true} onClick={handleUpdate}
                          isSending={false} text={'OK'}/>
            <SubmitButton simpleButton={true} additionalClass={'left-indent gray-button'} onClick={handleModalClose}
                          isSending={false} text={'Cancel'}/>
          </div>
        </div>
      </div>

      <div className={`w-form-done ${isCreated ? 'display-block' : ''}`}>
        <div>Thank you! Your submission has been received!</div>
      </div>
      {error && (
        <div className="w-form-fail display-block">
          <div>{error}</div>
        </div>
      )}

      <div className="abs-position" onClick={handleModalClose}>
        <div className="delete-btn">
          <div className="close-line-1 rotate"/>
          <div className="close-line-1"/>
        </div>
      </div>
    </ReactModal>
  );
};