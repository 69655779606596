//QuickReplies
import React, {useEffect, useState} from 'react';
import ReactModal from 'react-modal';
import update from "immutability-helper";

import SubmitButton from "components/widgets/SubmitButton";
import {t} from 'utils';
import _ from "lodash";


export default function QuickReplies(props) {
  const {widget, onSave} = props;

  const [quickReplies, setQuickReplies] = useState(widget.settings.quick_replies);
  const [curWidgetId, setCurWidgetId] = useState(widget.id);
  const [currentQuickReply, setCurrentQuickReply] = useState({'index': null, 'message': ''});
  const [modalSettings, setModalSettings] = useState({'type': 'add', 'isVisible': false});

  useEffect(() => {
    setQuickReplies(widget.settings.quick_replies);
    setCurWidgetId(widget.id)
  }, [widget])

  useEffect(() => {
    if (widget && widget.settings && widget.settings.quick_replies && widget.id === curWidgetId &&
      !_.isEqual(widget.settings.quick_replies, quickReplies)) {
      onSave({...widget, settings: {...widget.settings, quick_replies: quickReplies}});
    }
  }, [widget, quickReplies, curWidgetId]);

  const _t = (value, values = {}, children = null) => {
    return t(`quickReplies.${value}`, values, children);
  };

  const openModal = (type) => {
    setModalSettings(update(modalSettings, {$set: {
      'type': type,
      'isVisible': true
    }}));
  };

  const closeModal = () => {
    setModalSettings(update(modalSettings, {$toggle: ['isVisible']}));
    setCurrentQuickReply(update(currentQuickReply, {$set: {'index': null, 'message': ''}}))
  };


  const handleAddQuickReply = quickReply => {
    setQuickReplies(update(quickReplies, {$push: [quickReply.message]}));
  };

  const handleDeleteQuickReply = index => {
    setQuickReplies(update(quickReplies, {$splice: [[index, 1]]}));
  };

  const handleChangeQuickReply = (quickReply) => {
    setQuickReplies(update(quickReplies, {[quickReply.index]: {$set: quickReply.message}}));
  };

  return (
    <>
      <div className="pane-content-w">
        <div className="content-white-block">
          <div>
            <div className="group-title">{_t('header')}</div>
            <div className="page-subheader">{_t('subheader')}</div>
          </div>
          <div className="group-content-w">
            <div className="settings-btn-w add-space-2">
              <div className="blue-button top-indent w-button" onClick={() => openModal('add')}>
                <span className="awesome-icon big-icon"></span><span> </span>{_t('button.add')}</div></div>
              {quickReplies.map((reply, i) =>
                <div key={i} className="group-w">
                  <div className="action-w grow" key={i}>
                    <div className="details-header right-indent grow">{reply}</div>
                    <div className="action-btn edit-btn w-inline-block" onClick={() => {
                      setCurrentQuickReply(update(currentQuickReply, {$set: {'message': reply, 'index': i}}));
                      openModal('edit');
                    }}>
                      <div><span className="awesome-icon"></span> {_t('reply.edit')}</div>
                    </div>
                    <div className="action-btn delete-btn w-inline-block" onClick={() => handleDeleteQuickReply(i)}>
                      <div><span className="awesome-icon">&nbsp;</span>
                        {_t('reply.delete')}
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <QuickRepliesModal
        isOpen={modalSettings.isVisible}
        onClose={closeModal}
        onClick={modalSettings.type === 'add' ? handleAddQuickReply : handleChangeQuickReply}
        headerText={modalSettings.type === 'add' ? _t('modal.add.header') : _t('modal.edit.header')}
        labelText={modalSettings.type === 'add' ? _t('modal.add.message.label') : _t('modal.edit.message.label')}
        buttonText={modalSettings.type === 'add' ? _t('button.add') : _t('button.edit')}
        currentQuickReply={currentQuickReply}
        _t={_t}
      />
    </>
  );
}


const QuickRepliesModal = (props) => {
  const {isOpen, onClose, onClick, headerText, labelText, buttonText, currentQuickReply, _t} = props;
  const [message, setMessage] = useState('');

  const [quickReply, setQuickReply] = useState(null);

  useEffect(() => {
    setQuickReply(currentQuickReply);
  }, [currentQuickReply]);

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 outline-none"
      overlayClassName="popup-w-2 w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={onClose}
    >
      <div className="sign-header">{headerText}</div>
      <div className="w-form">
        <form id="email-form-4" name="email-form-4" data-name="Email Form 4">
          <div>
            <label htmlFor="Add-reply" className="settings-item-label">{labelText}</label>
            {_t('modal.add.message.placeholder', {},
              msg => <input value={quickReply.message}
                            onChange={e => setQuickReply(update(quickReply, {message: {$set: e.target.value}}))}
                            type="text"
                            className="node-settings-input-2 w-input"
                            maxLength="256" placeholder={msg} id="Add-reply" required=""/>)}

          </div>
          <SubmitButton simpleButton={true} additionalClass={'button-3 right-float'}
                        onClick={() => {onClick(quickReply); onClose();}}
                        isSending={false} text={buttonText} type={"button"}/>
        </form>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
      <div className="abs-position" onClick={onClose}>
        <div className="delete-btn">
          <div className="close-line-1-2 rotate"></div>
          <div className="close-line-1-2"></div>
        </div>
      </div>
    </ReactModal>
  )
}