import update from 'immutability-helper';
import _ from 'lodash';
import * as actions from 'actions';
import {SENDER_TYPES, PAGINATION_OFFSET_TYPE,  BOT_CAMPAIGN_TYPES, BOT_NODE_TYPES} from 'constants.js';
import {SET_INITIAL_CHATS_STATE} from "actions";

const SAVED_CHAT_COUNT = 5;

const initialState = {
  chatsById: null,
  chatsError: null,
  savedChatsWithMessage: [],
  _isChatsFetching: false,
  _hasMoreMessage: false,

  searchResults: null,
  orderedSearchResults: null,
  orderedChatIds: [],

  selectedChat: null,
  selectedChatId: null,
  chatError: null,
  _isChatFetching: false,
  _isChatUpdating: false,

  selectedClientId: null,

  /**
   * Отключен ли у пользователя звук в виджете.
   */
  turnOffNotification: localStorage.getItem('off_lk_notification') === 'true' ? true : false,
  /**
   * Нужно ли проигрывать звук, потому что пришло новое сообщение.
   */
  isNewMessageAudioPlay: false,
  isNewChatAudioPlay: false,

  //filters
  channelType: null,
  selectedOperator: null,
  chatStatus: null,
  chatOrder: {value: 'new'}
};

export default function chats(state=initialState, action) {
  switch(action.type) {
    case actions.FETCH_CHATS__R: {
      return update(state, {
        isChatsFetching: {$set: true}
      });
    }
    case actions.FETCH_CHATS__S: {
      try {
        const chatsById = _.cloneDeep(action.payload).chats.reduce((obj, cur) => {
          if (!obj[cur.chat_id]) {
            obj[cur.chat_id] = _.cloneDeep(cur.chat);
            delete cur.chat;
            obj[cur.chat_id].lastMessage = {...obj[cur.chat_id], ...parseLastMessage(cur)};
          }
          return obj;
        }, {});
        const orderedChatIds = _.cloneDeep(state.orderedChatIds);

        if (action.payload.offset === 0) {
          const newChatIds = _.cloneDeep(action.payload).chats.map(chat => chat.chat_id);
          return update(state, {
            chatsById: {$set: chatsById},
            orderedChatIds: {$set: newChatIds},
            isChatsFetching: {$set: false}
          });
        }
        const newChatIds = _.cloneDeep(action.payload).chats.reduce((arr, chat) => {
          if (!state.chatsById || !(chat.chat_id in state.chatsById)) {
            arr.push(chat.chat_id);
          }
          return arr;
        }, []);
        //console.log(newChatIds, orderedChatIds, _.cloneDeep(action.payload))
        const concatChatIds = action.payload.paginationDirection === PAGINATION_OFFSET_TYPE.next ?
          [...newChatIds, ...orderedChatIds] : [...orderedChatIds, ...newChatIds];
        //const concatChatIds = orderedChatIds.concat(newChatIds);

        if (action.payload.reset) {
          return update(state, {
            chatsById: {$set: chatsById},
            orderedChatIds: {$set: newChatIds},
            isChatsFetching: {$set: false}
          });
        }

        return update(state, {
          chatsById: state.chatsById ? {$merge: chatsById} : {$set: chatsById},
          orderedChatIds: {$set: concatChatIds},
          isChatsFetching: {$set: false}
        });
      } catch(e) {
        console.log(e)
      }
      break;
    }
    case actions.FETCH_CHATS__F: {
      return update(state, {
        chatsError: {$set: action.payload},
        isChatsFetching: {$set: false}
      });
    }

    case actions.FETCH_CHAT__R: {
      return update(state, {
        _isChatFetching: {$set: true}
      });
    }
    case actions.FETCH_CHAT__S: {
      if (action.payload.id) {
        if (state.chatsById[action.payload.id]) {
          return update(state, {
            chatsById: {[action.payload.id]: {$merge: action.payload}},
            selectedChatId: {$set: action.payload.id},
            selectedChat: state.selectedChat ? {$merge: action.payload} : {$set: action.payload},
            _isChatFetching: {$set: false},
            _hasMoreMessage: {$set: false}
          });
        } else {
          return update(state, {
            selectedChat: state.selectedChat ? {$merge: action.payload} : {$set: action.payload},
            selectedChatId: {$set: action.payload.id},
            _isChatFetching: {$set: false},
            _hasMoreMessage: {$set: false}
          });
        }
      } else {
        return state;
      }
    }
    case actions.FETCH_CHAT__F: {
      return update(state, {
        chatError: {$set: action.payload},
        _isChatFetching: {$set: false}
      });
    }

    case actions.CREATE_CHAT__R: {
      return update(state, {
        _isChatFetching: {$set: true}
      });
    }
    case actions.CREATE_CHAT__S: {
      let chat = action.payload;
      if (chat.client_id === state.selectedClientId) {
        if (!state.chatsById['default']) {
          return state;
        }
        const chatsById = _.cloneDeep(state.chatsById);
        const defaultChatClient = _.cloneDeep(chatsById['default'].client);
        delete chatsById['default'];
        chatsById[action.payload.id] = chat;
        chatsById[action.payload.id].client = defaultChatClient;
        return update(state, {
          chatsById: {$set: chatsById},
          selectedChatId: {$set: action.payload.id},
          selectedChat: {$set: chatsById[action.payload.id]},
          selectedClientId: {$set: null},
          _isChatFetching: {$set: false}
        });
      } else {
        return update(state, {
          chatsById: {[action.payload.id]: {$merge: chat}},
          selectedChat: !state.selectedChat ? {$set: chat} : {$merge: chat},
          selectedChatId: {$set: action.payload.id},
          _isChatFetching: {$set: false}
        });
      }

    }
    case actions.CREATE_CHAT__F: {
      return update(state, {
        chatError: {$set: action.payload},
        _isChatFetching: {$set: false}
      });
    }

    case actions.UPDATE_CHAT__R: {
      return update(state, {
        _isChatUpdating: {$set: true}
      });
    }
    case actions.UPDATE_CHAT__S: {
      let chat = action.payload;
      return update(state, {
        chatsById: {[action.payload.id]: {$merge: chat}},
        _isChatUpdating: {$set: false}
      });
    }
    case actions.UPDATE_CHAT__F: {
      return update(state, {
        chatError: {$set: action.payload},
        _isChatUpdating: {$set: false}
      });
    }

    case actions.START_TYPING: {
      const data = action.payload;
      if (!state.chatsById || !state.chatsById[data.chat_id]) {
        return state;
      }

      return update(state, {
        chatsById: {[data.chat_id]: {typing: {$set: {
          is_typing: true,
          sender_id: data.sender_id,
          text: data.text}}
        }}
      });
    }
    case actions.END_TYPING: {
      const data = action.payload;
      if (!state.chatsById || !state.chatsById[data.chat_id]) {
        return state;
      }

      return update(state, {
        chatsById: {[data.chat_id]: {typing: {is_typing: {$set: false}}}}
      });
    }

    case actions.FETCH_CHAT_MESSAGES__R: {
      return update(state, {
        _isChatFetching: {$set: true}
      });
    }
    case actions.FETCH_CHAT_MESSAGES__S: {
      try {
        let localChat = state.chatsById[action.payload.chatId] ?
          _.cloneDeep(state.chatsById[action.payload.chatId]) : _.cloneDeep(state.selectedChat);
        const data = getChatMessages(localChat, action, state);

        return update(state, data);

      } catch (e) {
        console.log(e);
      }
      break;
    }
    case actions.FETCH_CHAT_MESSAGES__F: {
      return update(state, {
        chatError: {$set: action.payload},
        _isChatFetching: {$set: false}
      });
    }

    case actions.GET_VISITOR_INFO__R: {
      return update(state, {
        _isChatFetching: {$set: true}
      });
    }
    case actions.GET_VISITOR_INFO__S: {
      try {
        const {clientId, data} = action.payload;
        const chatsById = _.cloneDeep(state.chatsById || {});
        if (data.active_chat_id) {
          return update(state, {
            chatsById: {$set: chatsById},
            selectedChatId: {$set: data.active_chat_id},
            _isChatFetching: {$set: false}
          });
        }
        chatsById['default'] = {
          id: 'default',
          campaign_type: 'live_web_chat',
          client: data
        };
        return update(state, {
          selectedClientId: {$set: clientId},
          selectedChatId: {$set: 'default'},
          selectedChat: {$set: {
            id: 'default',
            campaign_type: 'live_web_chat',
            client: data
          }},
          chatsById: {$set: chatsById},
          _isChatFetching: {$set: false}
        });
      } catch(e) {
        console.log(e);
      }
      break;
    }
    case actions.GET_VISITOR_INFO__F: {
      return update(state, {
        chatError: {$set: action.payload},
        _isChatFetching: {$set: false}
      });
    }

    case actions.UPDATE_VISITOR_INFO__R: {
      return update(state, {
        _isChatFetching: {$set: true}
      });
    }
    case actions.UPDATE_VISITOR_INFO__S: {
      const {chatId, data} = action.payload;
      const updatingStateFields = updateClientInfo(state, data);
      updatingStateFields['_isChatFetching'] = {$set: false};
      return update(state, updatingStateFields);
    }
    case actions.UPDATE_VISITOR_INFO__F: {
      return update(state, {
        chatError: {$set: action.payload},
        _isChatFetching: {$set: false}
      });
    }

    case actions.READ_MESSAGE__R: {
      return update(state, {
        _isChatFetching: {$set: true}
      });
    }
    case actions.READ_MESSAGE__S: {
      if (!state.chatsById) {
        return state;
      }
      const chat = _.cloneDeep(action.payload);
      let obj = {
        selectedChat: {$merge: chat},
        _isChatFetching: {$set: false}
      };
      if (action.payload.id in state.chatsById) {
        obj['chatsById'] = {[action.payload.id]: {$merge: chat}}
      }
      return update(state, obj);
    }
    case actions.READ_MESSAGE__F: {
      return update(state, {
        chatError: {$set: action.payload},
        _isChatFetching: {$set: false}
      });
    }

    case actions.SEND_MESSAGE__R: {
      return update(state, {
        _isChatFetching: {$set: true}
      });
    }
    case actions.SEND_MESSAGE__S: {
      if (!(action.payload.chatId in state.chatsById)) {
        throw new Error('No chat');
      }
      let message = action.payload.message;
      const orderedChatIds = changeMessageOrder(state, message);

      let updateData = {
        orderedChatIds: {$set: orderedChatIds},
        _isChatFetching: {$set: false}
      };

      updateData = updateChatMessages(updateData, message, action, state);

      return update(state, updateData);
    }
    case actions.SEND_MESSAGE__F: {
      return update(state, {
        chatError: {$set: action.payload},
        _isChatFetching: {$set: false}
      });
    }

    case actions.GETTING_NEW_CHAT: {
      //console.log('add chat', action.payload)
      if (!state.chatsById) {
        return state;
      }
      let chat = _.cloneDeep(action.payload.chat);
      let currentOperator = _.cloneDeep(action.payload.currentOperator);
      if (chat.client_id === state.selectedClientId) {
        if (!state.chatsById['default']) {
          return state;
        }
        const chatsById = _.cloneDeep(state.chatsById);
        const defaultChatClient = _.cloneDeep(chatsById['default'].client);
        delete chatsById['default'];
        chatsById[action.payload.id] = chat;
        chatsById[action.payload.id].client = defaultChatClient;
        return update(state, {
          chatsById: {$set: chatsById},
          selectedChatId: {$set: action.payload.id},
          selectedChat: {$set: {...chat, client: defaultChatClient}},
          selectedClientId: {$set: null},
          isNewChatAudioPlay: (currentOperator.is_online && chat.active_operator_ids &&
          (chat.active_operator_ids.includes(currentOperator.id) || chat.active_operator_ids.length === 0)) ?
            {$set: true} : {$set: false},
          _isChatFetching: {$set: false}
        });
      } else {
        chat.id = action.payload.id;
        let orderedChatIds = _.cloneDeep(state.orderedChatIds);
        if (state.chatOrder.value === 'new') {
          orderedChatIds.unshift(chat.id );
        } else {
          orderedChatIds.push(chat.id);
        }
        return update(state, {
          chatsById: {[action.payload.id]: {$set: chat}},
          orderedChatIds: {$set: orderedChatIds},
          isNewChatAudioPlay: (currentOperator.is_online && chat.active_operator_ids &&
          (chat.active_operator_ids.includes(currentOperator.id) || chat.active_operator_ids.length === 0)) ?
            {$set: true} : {$set: false}
        });
      }

    }
    case actions.UPDATING_CHAT: {
      try {
        if (!state.chatsById) {
          return state;
        }
        const chat = _.cloneDeep(action.payload);
        //console.log(chat)
        if (state.selectedChat && state.selectedChat.id === action.payload.id ) {
          return update(state, {
            chatsById: state.chatsById[action.payload.id] ?
              {[action.payload.id]: {$merge: chat}} : {$merge: {[action.payload.id]: chat}},
            selectedChat: {$merge: chat},
          });
        } else {
          return update(state, {
            chatsById: state.chatsById[action.payload.id] ?
              {[action.payload.id]: {$merge: chat}} : {$merge: {[action.payload.id]: chat}}
          });
        }
      } catch(e) {
        console.log(e);
      }
      break;
    }
    case actions.DELETING_CHAT: {
      if (!state.chatsById) {
        return state;
      }
      let orderedChatIds = _.cloneDeep(state.orderedChatIds);
      const oldChatIndex = orderedChatIds.indexOf(action.payload.id);
      orderedChatIds.splice(oldChatIndex, 1);
      const savedChatsWithMessage = _.cloneDeep(state.savedChatsWithMessage);
      if (savedChatsWithMessage.indexOf(action.payload.id) !== - 1) {
        savedChatsWithMessage.splice(savedChatsWithMessage.indexOf(action.payload.id), 1);
      }
      const selectedChatId = orderedChatIds && orderedChatIds.length ? (oldChatIndex !== orderedChatIds.length ?
        orderedChatIds[oldChatIndex] : orderedChatIds[oldChatIndex - 1]) : null;
      let updateData = {
        chatsById: {$unset: [action.payload.id]},
        orderedChatIds: {$set: orderedChatIds},
        selectedChatId: {$set: selectedChatId},
        savedChatsWithMessage: {$set: savedChatsWithMessage}
      };
      if (state.selectedChat && state.selectedChat.id === action.payload.id) {
        updateData['selectedChat'] = {
          $set: null
        }
      }
      return update(state, updateData);
    }

    case actions.GETTING_NEW_MESSAGE: {
      if (!(action.payload.message.chat_id in state.chatsById)) {
        throw new Error('No chat');
      }
      const message = action.payload.message;

      const currentOperator = action.payload.currentOperator;
      const orderedChatIds = changeMessageOrder(state, message);

      let updateData = {
        orderedChatIds: {$set: orderedChatIds},
        isNewMessageAudioPlay: currentOperator.is_online && message.sender_id !== currentOperator.id && (message.chat.active_operator_ids &&
        (message.chat.active_operator_ids.includes(currentOperator.id) || message.chat.active_operator_ids.length === 0)) ?
           {$set: true} : {$set: false},
      };

      updateData = updateChatMessages(updateData, message, action, state);

      return update(state, updateData);
    }

    case actions.UPDATING_MESSAGE: {
      if (!state.chatsById || !state.chatsById[action.payload.chat_id] || !state.chatsById[action.payload.chat_id].messageDescriptor) {
        return state;
      } else {
        if (!(action.payload.id in state.chatsById[action.payload.chat_id].messageDescriptor)) {
          return state;
        }
      }

      const chat = state.chatsById[action.payload.chat_id];

      return update(state, {
        chatsById: {
          [action.payload.chat_id]:
            {
              lastMessage: {$set: {...action.payload, ...parseLastMessage(action.payload)}},
              messages: {[state.chatsById[action.payload.chat_id].messageDescriptor[action.payload.id]]: {$set: action.payload}}
            }
        },
        //TODO: by selectedChat
      });
    }

    case actions.DELETING_MESSAGE: {
      if (!state.chatsById || !state.chatsById[action.payload.chat_id] || !state.chatsById[action.payload.chat_id].messageDescriptor) {
        return state;
      }
      const chat = state.chatsById[action.payload.chat_id];
      const messages = chat.messages;
      const prevMessage = messages.length > 1 ? messages[messages.length - 2] : null;
      const deletingInd = chat.messageDescriptor[action.payload.id];
      if (deletingInd === undefined) {
        return state;
      }
      return update(state, {
        chatsById: {
          [action.payload.chat_id]:
            {
              lastMessage: {$set: {...prevMessage, ...parseLastMessage(prevMessage)}},
              messages: {$splice: [[deletingInd, 1]]},
              messageDescriptor: {$apply: (messageDescriptor) => {
                return Object.keys(messageDescriptor).reduce((obj, curId) => {
                  if (messageDescriptor[curId] > deletingInd) {
                    obj[curId] = messageDescriptor[curId] - 1;
                  }
                  if (messageDescriptor[curId] < deletingInd) {
                    obj[curId] = messageDescriptor[curId];
                  }
                  return obj;
                }, {});
              }}
            }
        },
        //TODO: by selectedChat
      });
    }

    case actions.UPDATING_CLIENT: {
      const updatingStateFields = updateClientInfo(state, action.payload);
      const client = state.leadsById ? state.leadsById[action.payload.id] : undefined;
      if (client) {
        updatingStateFields['leadsById'] =  {[action.payload.id]: {$merge: action.payload}};
      }
      return update(state, updatingStateFields);
    }

    case actions.RESET_SEARCH: {
      return update(state, {
        searchResults: {$set: null},
        orderedSearchResults: {$set: null},
      });
    }

    case actions.SEARCH_MESSAGES__R: {
      return update(state, {
        isChatsFetching: {$set: true}
      });
    }
    case actions.SEARCH_MESSAGES__S: {
      const searchResults = action.payload.results.reduce((obj, cur) => {
        if (!obj[cur.id]) {
          obj[cur.id] = _.cloneDeep(cur);
        }
        return obj;
      }, {});
      if (action.payload.pagination === 0) {
        return update(state, {
          searchResults: {$set: searchResults},
          orderedSearchResults: {$set: action.payload.results.map(messages => messages.id)},
          isChatsFetching: {$set: false}
        });
      } else {
        const orderedSearchResults = _.cloneDeep(state.orderedSearchResults);
        const newSearchResults = action.payload.results.map(m => m.id);
        const concatChatIds = orderedSearchResults.concat(newSearchResults);

        return update(state, {
          searchResults: {$merge: searchResults},
          orderedSearchResults: {$set: concatChatIds},
          isChatsFetching: {$set: false}
        });
      }

    }
    case actions.SEARCH_MESSAGES__F: {
      return update(state, {
        chatsError: {$set: action.payload},
        isChatsFetching: {$set: false}
      });
    }

    case actions.SET_CHANNEL_TYPE_FILTER: {
      return update(state, {
        channelType: {$set: action.payload},
      });
    }
    case actions.SET_OPERATOR_FILTER: {
      return update(state, {
        selectedOperator: {$set: action.payload},
      });
    }
    case actions.SET_STATUS_FILTER: {
      return update(state, {
        chatStatus: {$set: action.payload},
      });
    }
    case actions.SET_CHAT_SORT: {
      return update(state, {
        chatOrder: {$set: action.payload},
      });
    }

    case actions.SELECT_CHAT: {
      if (!state.chatsById) {
        return state;
      }
      let updateData = {
        selectedChatId: {$set: action.payload},
      };
      if (action.payload !== 'default') {
        updateData['selectedClientId'] = {$set: null};
      }
      if (action.payload && state.chatsById[action.payload] && state.chatsById[action.payload].messages) {
        updateData['selectedChat'] = {$set: state.chatsById[action.payload]};
      } else {
        if (!action.payload) {
          updateData['selectedChat'] = {$set: null};
        }
      }
      return update(state, updateData);
    }

    case actions.TOGGLE_NOTIFICATION: {
      localStorage.setItem('off_lk_notification', !state.turnOffNotification);
      return update(state, {$toggle: ['turnOffNotification']});
    }

    case actions.OFF_NEW_MESSAGE_AUDIO_PLAY: {
      return update(state, {
        isNewMessageAudioPlay: {$set: false}
      });
    }

    case actions.OFF_NEW_CHAT_AUDIO_PLAY: {
      return update(state, {
        isNewChatAudioPlay: {$set: false}
      });
    }

    case actions.SET_INITIAL_CHATS_STATE: {
      return update(state, {$set: initialState});
    }

    default:
      return state;
  }
}

const parseLastMessage = (message, isFull = false) => {
  let text = '';
  if (message) {
    if (message.sender_type === SENDER_TYPES.bot) {
      text = message.bot_message_text || message.text;
      try {
        const parseText = JSON.parse(text);
        if (isFull) {
          text = parseText;
        } else {
          if (parseText && parseText.type) {
            if ([BOT_NODE_TYPES.askQuestion, BOT_NODE_TYPES.notifyManager, BOT_NODE_TYPES.closeChat].includes(parseText.type)) {
              text = parseText.content.message || parseText.content.question || parseText.content.text ||
                'Bot sent a message';
            } else {
              text = 'Bot sent a message';
            }
          } else {
            text = 'Bot sent a message';
          }
        }
      } catch(e) {
        //need something?
      }
    } else {
      text = message.text;
    }
  }
  let messageFiles = null;
  if (message && message.files) {
    messageFiles = {img: [], video: [], other: []};
    message.files.forEach(file => {
      if (file.mimetype.includes('image')) {
        messageFiles.img.push(file);
      } else {
        if (file.mimetype.includes('video')) {
          messageFiles.video.push(file);
        } else {
          messageFiles.other.push(file);
        }
      }
    });
    if (!text && !isFull) {
      text = 'Files'
    }
  }
  return {text, files: messageFiles};
};

const getChatMessages = (chat, action, state) => {
  //console.log(action.payload, chat)
  if (chat['messages']) {
    const messages = _.cloneDeep(action.payload.messages).reduce((arr, message) => {
      if (!(message.id in chat['messageDescriptor'])) {
        const fullMessage = {...message, ...parseLastMessage(message, true)};
        arr.push(fullMessage);
      }
      return arr;
    }, []);
    if (action.payload.paginationDirection === PAGINATION_OFFSET_TYPE.reset &&
      (action.payload.messages.length === messages.length)) {
      chat['messages'] = action.payload.messages;
    } else {
      if (action.payload.paginationDirection === PAGINATION_OFFSET_TYPE.middle) {
        //если длины массивов равны, значит нет пересечений с уже имеющимися сообщениями
        chat['messages'] = action.payload.messages;
      } else {
        chat['messages'] = action.payload.paginationDirection === PAGINATION_OFFSET_TYPE.prev ?
          [...messages, ...chat['messages']] : [...chat['messages'], ...messages];
      }
    }
  } else {
    chat['messages'] = _.cloneDeep(action.payload.messages).reduce((arr, message) => {
      const fullMessage = {...message, ...parseLastMessage(message, true)};
      //console.log(fullMessage)
      arr.push(fullMessage);
      return arr;
    }, []);
  }
  chat['messageDescriptor'] = chat['messages'].reduce((obj, cur, ind) => {
    obj[cur.id] = ind;
    return obj;
  }, {});

  chat['messageCount'] = action.payload.messageCount;

  let savedChatsWithMessage = _.cloneDeep(state.savedChatsWithMessage);
  let deleteChatId = null;
  if (savedChatsWithMessage.indexOf(action.payload.chatId) === - 1) {
    if (savedChatsWithMessage.length === SAVED_CHAT_COUNT) {
      deleteChatId = savedChatsWithMessage.splice(SAVED_CHAT_COUNT - 1, 1);
    }
    savedChatsWithMessage.unshift(action.payload.chatId);
  }

  let updateData = {
    selectedChat: !state.selectedChat ? {$set: chat} : {$merge: chat},
    _isChatFetching: {$set: false},
    savedChatsWithMessage: {$set: savedChatsWithMessage}
  };

  if (deleteChatId && (deleteChatId[0] in state.chatsById)) {
    updateData['chatsById'] = {
      [deleteChatId[0]]: {
        messages: {$set: null},
        messageDescriptor: {$set: null}
      }
    };
  }
  if (action.payload.chatId in state.chatsById) {
    updateData['chatsById'] = {
      ...updateData['chatsById'],
      [action.payload.chatId]: !state.chatsById[action.payload.chatId] ? {$set: chat} : {$merge: chat}
    };
  }
  return updateData;
};

const changeMessageOrder = (state, message) => {
  let orderedChatIds = _.cloneDeep(state.orderedChatIds);
  const oldChatIndex = orderedChatIds.indexOf(message.chat_id);
  if (oldChatIndex !== -1) {
    orderedChatIds.splice(oldChatIndex, 1);
  }

  if (state.chatOrder.value === 'new') {
    orderedChatIds.unshift(message.chat_id);
  } else {
    orderedChatIds.push(message.chat_id);
  }

  return orderedChatIds;
};

const updateChatMessages = (updateData, message, action, state) => {
  const isOnline = message.sender_type === SENDER_TYPES.client ?
    {client: {$merge: {is_online: true}}} : {};

  if (!state.chatsById || !state.chatsById[message.chat_id]) {
    if (state.selectedChat && state.selectedChat.id !== message.chat_id) {
      return updateData;
    }
  } else {
    if (!state.chatsById[message.chat_id].messages && message.chat_id !== state.selectedChatId) {

      updateData['chatsById'] = {
        [message.chat_id]: {
          lastMessage: {$set: {...message, ...parseLastMessage(message)}},
          client: state.chatsById[message.chat_id].client ? {$merge: {is_online: true}} : {$set: {is_online: true}}
        }
      }
    } else {
      if (!(state.chatsById[message.chat_id].messageDescriptor && (message.id in state.chatsById[message.chat_id].messageDescriptor))) {
        const fullMessage = {...message, ...parseLastMessage(message, true)};
        updateData['chatsById'] = {
          [message.chat_id]: {
            lastMessage: {$set: {...message, ...parseLastMessage(message)}},
            messages: state.chatsById[message.chat_id].messages ? {$push: [fullMessage]} : {$set: [fullMessage]},
            messagesCount: {$set: (state.chatsById[message.chat_id] && state.chatsById[message.chat_id].messagesCount) ?
              state.chatsById[message.chat_id].messagesCount + 1 : 1},
            messageDescriptor: state.chatsById[message.chat_id].messageDescriptor ? {
              [message.id]: {
                $set: state.chatsById[message.chat_id].messages ?
                  state.chatsById[message.chat_id].messages.length : 0
              }
            } : {
              $set: {
                [message.id]: 0
              }
            },
            client: state.chatsById[message.chat_id].client ? {$merge: {is_online: true}} : {$set: {is_online: true}}
          },
        }
      }
    }
  }
  if (state.selectedChat && state.selectedChat.id === message.chat_id && !state.searchResults) {
    if (!(state.selectedChat.messageDescriptor && (message.id in state.selectedChat.messageDescriptor))) {
      const fullMessage = {...message, ...parseLastMessage(message, true)};
      updateData['selectedChat'] = {
        lastMessage: {$set: {...message, ...parseLastMessage(message)}},
        messages: state.selectedChat.messages ? {$push: [fullMessage]} : {$set: [fullMessage]},
        messagesCount: {$set: (state.selectedChat && state.selectedChat.messagesCount) ? state.selectedChat.messagesCount + 1 : 1},
        messageDescriptor: state.selectedChat.messageDescriptor ? {
          [message.id]: {
            $set: state.selectedChat.messages ?
              state.selectedChat.messages.length : 0
          }
        } : {
          $set: {
            [message.id]: 0
          }
        },
        client: state.selectedChat.client ? {$merge: {is_online: true}} : {$set: {is_online: true}}
      }
    }
  }
  return updateData;
};

const updateClientInfo = (state, data) => {
  const updatingStateFields = {};
  let chat = state.chatsById ? Object.values(state.chatsById).find(chat => chat.client_id === data.id) : undefined;

  if (chat) {
    updatingStateFields['chatsById'] = {
      [chat.id]: {
        client: state.chatsById[chat.id].client ? {$merge: data} : {$set: data},
      }
    };
  }
  chat = state.selectedChat && state.selectedChat.client_id === data.id ? state.selectedChat : undefined;
  if (chat) {
    updatingStateFields['selectedChat'] = {
      client: state.selectedChat.client ? {$merge: data} : {$set: data}
    };
  }

  return updatingStateFields;
};