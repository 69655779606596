import React, {Fragment, useState} from 'react';

import MediaWrapper from 'components/widgets/MediaWrapper';
import TextareaWithAddons from 'components/widgets/TextareaWithAddons';
import {t} from 'utils';


export default function BlockBuilderSections(props) {
  const {sections, textareaPlaceholderField, isHideButtons, onAdd, onChange, onDelete} = props;
  const handleChange = (data, i) => {
    onChange(data, i);
  };
  const handleAdd = (section) => {
    onAdd(section);
  };
  const handleDelete = (i) => {
    onDelete(i);
  };
  return (
    <>
      {sections && sections.map((section, i) => {
        let type = section.type;
        let loaderType = type;
        if (type === 'link') {
          if (section.link && (section.link.includes('youtube.com') || section.link.includes('youtu.be'))) {
            type = 'youtube'
          }
          if (section.link && section.link.includes('giphy.com')) {
            type = 'giphy'
          }
          loaderType = section.link && (section.link.includes('youtube') || section.link.includes('youtu.be')) ?
            'youtube' : (section.link && section.link.includes('giphy') ? 'giphy' : type);
        }
        if (section.type === 'text') {
          return (
            <TextareaWithAddons placeholderField={textareaPlaceholderField}
                                value={section.text}
                                isNotDeleting={false}
                                onChange={value => handleChange({text: value}, i)}
                                onDelete={() => handleDelete(i)}
            />
          );
        } else {
          return (
            <div className="settings-item">
              <MediaWrapper loaderType={loaderType}
                            value={section.file || section.link}
                            onChangeType={type => {
                              if (!section.link) {
                                handleChange({type}, i)
                              } else {
                                type = type === 'youtube' || type === 'giphy' ? 'link' : type;
                                handleChange({type}, i)
                              }
                            }}
                            onChangeValue={data => {
                              if (data.link && (data.link.includes('youtube') || data.link.includes('youtu.be') || data.link.includes('giphy'))) {
                                handleChange({...data, type: 'link'}, i)
                              } else {
                                if (data.link) {
                                  const type = loaderType === 'youtube' || loaderType === 'giphy' ? 'link' : loaderType;
                                  handleChange({...data, type}, i)
                                } else {
                                  handleChange(data, i)
                                }
                              }

                            }}
                            onDelete={() => handleDelete(i)}
              />
            </div>
          )
        }
      })}
      {!isHideButtons && (
        <div className="btns-w">
          <div className="step-btn add-field w-inline-block"
               onClick={() => handleAdd({text: '', type: 'text'})}>
            <div> + Add text</div>
          </div>
          <div className="step-btn add-field w-inline-block"
               onClick={() => handleAdd({type: 'file'})}>
            <div> + Add media</div>
          </div>
        </div>
      )}
      {!isHideButtons && (
        <div className="info-text">{t('web.create.botBuilder.transferMessage.notice')}</div>
      )}
    </>
  );
}
//TransferToOperatorMessage