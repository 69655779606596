import React, {Fragment, useState, useEffect} from 'react';
import update from 'immutability-helper';

import Header from 'components/Header';
import BotWorkspace from './BotWorkspace';
import BotSettings from './BotSettings';
import StepChain from '../StepChain';
import {t} from 'utils';
import {CAMPAIGN_REQUEST_TYPES} from "constants.js";
import SubmitButton from "components/widgets/SubmitButton";
import {useCampaigns, useBuildBot, useHistoryEvents} from "hooks";


export default function BotBuilder(props) {
  const {currentStep, type, isCreate, campaign, campaignType,
    onClearHistory, setCurrentStep, setCampaign, onSave} = props;

  const {nodes, saveBotSchema} = useBuildBot();


  const getHeaderTitle = () => {
    if (campaignType === CAMPAIGN_REQUEST_TYPES.facebook_messenger) {
      return (
        <div className="display-center">
          <img src={require('styles/images/messenger-logo.svg')} alt="" className="header-channel-icon"/>
          <div>{t(`${campaignType}.create.botBuilder.header${campaign && campaign.id ? '.edit' : ''}`)}</div>
        </div>
      );
    }
    return t(`${campaignType}.create.botBuilder.header${campaign && campaign.id ? '.edit' : ''}`);

  }

  const handleCurrentStepToggle = (step) => {
    if (!isCreate) {
      setCampaign(update(campaign, {
        bot_builder: {$set: nodes}
      }));
      onClearHistory(true);
    }
    setCurrentStep(step);
  }

  return (
    <Fragment>
      <Header title={getHeaderTitle()}>
        <StepChain type={type} setCurrentStep={handleCurrentStepToggle} campaign={campaign}
                   currentStep={currentStep} additionalClass="no-bottom-indent"/>
      </Header>
      <div className="editor-form-block w-form">
        <form id="email-form" name="email-form" data-name="Email Form" className="editor-form">
          <div className="editor-cont-wrap">
            <BotSettings campaignType={campaignType}/>
            <BotWorkspace goToDisplaySettings={(nodes) => {
                            setCampaign(update(campaign, {
                              bot_builder: {$set: nodes}
                            }));
                            if (campaignType === CAMPAIGN_REQUEST_TYPES.facebook_messenger) {
                              onSave(!!campaign.enabled, isCreate);
                              return;
                            }
                            if (isCreate) {
                              setCurrentStep(currentStep + 1);
                            } else {
                              onSave(campaign.enabled, isCreate);
                            }
                          }}
                          backOnPrevioslyStep={(nodes) => {
                            onClearHistory();
                            setCurrentStep(currentStep - 1);
                          }}
                          isCreate={isCreate}
                          campaignType={campaignType} />
          </div>
          <div className="upload-img-popup">
            <div className="popup-container">
              <div className="popup-header-div">
                <div className="popup-header">{t('web.create.botBuilder.editor.imageLibrary')}</div>
                <a href="#" className="close-link no-move" data-ix="close-upload-img-popup"></a></div>
              <div className="popup-content-wrap">
                <div className="content-column no-margin"></div>
              </div>
            </div>
          </div>
        </form>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </Fragment>
  );
}