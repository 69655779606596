import React, {Fragment, useState, useEffect} from 'react';
import ReactModal from "react-modal";
import MediaWrapper from 'components/widgets/MediaWrapper';
import SubmitButton from 'components/widgets/SubmitButton';
import {useSendFiles} from 'hooks';

export default function ImageLoaderModal(props) {
  const {isOpen, onClose, onSaveFile} = props;
  const [file, setFile] = useState(null);
  const sendFiles = useSendFiles();
  const handleSendFiles = () => {
    sendFiles([file])
      .then(fileInfo => {
        if (fileInfo.files) {
          onSaveFile(fileInfo.files[0].url);
          setFile(null);
          onClose();
        }
      })
  };
  const closeModal = () => {
    setFile(null);
    onClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 outline-none"
      overlayClassName="popup-w-2 w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={closeModal}
    >
      <div className="sign-header">Load image</div>
      <div className="w-form">
        <div id="email-form-4" name="email-form-4" data-name="Email Form 4">
          <div className="settings-item">
            <MediaWrapper loaderType={'file'}
                          value={file}
                          onChangeValue={data => {
                            setFile(data.file)
                          }}
            />
          </div>
          <div className="settings-btn-w">
            <SubmitButton simpleButton={true} onClick={handleSendFiles}
                          isSending={false} text={'OK'}/>
            <SubmitButton simpleButton={true} additionalClass={'left-indent gray-button'} onClick={closeModal}
                          isSending={false} text={'Cancel'}/>
          </div>
        </div>
      </div>
      <div className="abs-position" onClick={onClose}>
        <div className="delete-btn">
          <div className="close-line-1 rotate"></div>
          <div className="close-line-1"></div>
        </div>
      </div>
    </ReactModal>
  );
};