import React, { Component } from 'react';
import _ from 'lodash';
import {Formik, Form, Field} from 'formik';
import {Link, Redirect} from 'react-router-dom';
import moment from 'moment';
import {Elements, injectStripe} from 'react-stripe-elements';

import SubmitButton from 'components/widgets/SubmitButton';
import IntlWrapper from 'components/widgets/IntlWrapper';
import Header from '../../Header';
import PaymentCardData from "../NewSubscription/PaymentCardData";
import {t, isCardNumberValid, isCvcValid} from 'utils';
import {TARIFF_PERIODS} from 'constants.js';
import {main, billing} from 'translate';


export default class NewSubscription extends Component {
  constructor(props) {
    super(props);
    this.defaultDate = moment().add(2, 'years').month(0);

    this.messages = [main, billing];
    this.stripeObjectContainer = {};

    this.handlePlanBuy = this.handlePlanBuy.bind(this);
  }

  handlePlanBuy(tariff, paymentPeriod) {
    this.props.onPlanBuy(tariff, this.getStripeObject())
  }

  getStripeObject() {
    return this.stripeObjectContainer.stripe;
  }

  _t = (value, values = {}, children = null) => {
    return t(`billings.${value}`, values, children);
  };

  render() {
    const {isPlanBuying, isSuccessfulPlanBuying, isFailed, payPaymentErrorMessage,
      userCard, currentUser, isUserCardLoading} = this.props;

    const {selectedPlan} = this.props;

    // const requestErrorMessage = requestErrors.message;

    if ((!selectedPlan) || isSuccessfulPlanBuying) {
      return <Redirect to='/billing'/>;
    }

    return (
      <IntlWrapper messages={this.messages}>
        <Header title={this._t('newSubscription.header')}/>
        <div className="page-content normal-content">
          <div className='w-form'>
            <Formik
              initialValues={{
                number: userCard ? (userCard.number || '') : '',
                expirationYear: userCard.expiration_year || this.defaultDate.format('YYYY'),
                expirationMonth: userCard.expiration_month || this.defaultDate.format('MM'),
                cvc: ''
              }}
              onSubmit={(values, actions) => {
                this.handlePlanBuy(selectedPlan);
              }}
              render={props => {

                return (
                  <Form>
                    <div className='no-wide-block'>
                      <div className='content-white-block grow-w'>
                        <div className='page-content-header-div hor-flex justify-left'>
                          <div className='group-title'>{this._t('newSubscription.signUp.title')}</div>
                        </div>
                        <div className='content-white-block table-container-2'>
                          <div className='table-w'>
                            <div className='table-row table-header in-view'>
                              <div className='table-col1'>
                                <div className='details-header'>
                                  {this._t('newSubscription.table.header.plan')}
                                </div>
                              </div>
                              <div className='table-col1'>
                                <div className='details-header'>
                                  {this._t('newSubscription.table.header.price')}
                                </div>
                              </div>
                            </div>
                            <div className='table-content'>
                              <div className='table-row in-view'>
                                <div className='table-col1'>
                                  <div className='cp-info'>{selectedPlan.name}</div>
                                </div>
                                <div className='table-col1'>
                                  <div className='cp-info'>${selectedPlan.price}/month</div>
                                </div>
                              </div>
                              <div className='table-row in-view'>
                                <div className='table-col1'>
                                  <div className='cp-info'><strong>{this._t('newSubscription.table.todayPayment')}:</strong></div>
                                </div>
                                <div className='table-col1'>
                                  <div className='cp-info'>
                                    <strong>
                                    {selectedPlan.period === TARIFF_PERIODS.year ?
                                      Math.round(selectedPlan.price * 12 * 100)/100 : Math.round(selectedPlan.price * 100)/100}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='content-white-block grow-w'>
                        <div>
                          <div className='group-title'>{this._t('newSubscription.credentials.title')}</div>
                        </div>
                        <Elements>
                          <PaymentCardData stripeObjectContainer={this.stripeObjectContainer} _t={this._t}/>
                        </Elements>
                        {payPaymentErrorMessage && (
                          <div className="w-form-fail" style={{display: 'block'}}>
                            <div>{payPaymentErrorMessage}</div>
                          </div>
                        )}
                      </div>
                      <div className='settings-btn-w align-center'>
                        <SubmitButton simpleButton={true} additionalClass={'right-float'}
                                      isSending={isPlanBuying} text={this._t('newSubscription.startPlan.button')}/>
                      </div>
                    </div>
                  </Form>
                );
              }}
            />
          </div>
        </div>
      </IntlWrapper>
    );
  }
}