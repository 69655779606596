import Cookies from 'js-cookie';

export const getAccessToken = () => {
  return Cookies.get('_chatbotter_op');
};

export const setAccessToken = token => {
  return Cookies.set('_chatbotter_op', token, { expires: 365 * 10 });
};

export const setAccessSessionToken = token => {
  return Cookies.set('_chatbotter_op', token, { expires: '' });
};

export const removeAccessToken = () => {
  Cookies.remove('_chatbotter_op');
};

export const accessTokenExists = () => {
  return !!getAccessToken().trim();
};
