import React, {Fragment, useState} from 'react';
import {Link} from 'react-router-dom';

import IntlWrapper from 'components/widgets/IntlWrapper';
import Header from 'components/Header';
import {t} from 'utils';
import {main, campaigns} from 'translate';

export default function ChooseWebChatCampaign(props) {
  const messages = [main, campaigns];
  const {match} = props;
  return (
    <IntlWrapper messages={messages}>
      <Header title={t('web.create.choose.title')}/>
      <div className="page-content normal-content">
        <div className="content-block">
          <Link to={`${match.url}/live-chat`} className="big-link-block w-inline-block">
            <img src={require('styles/images/create-web-chat-live.svg')} alt="" className="link-block-icon"/>
            <div className="link-block-div">
              <div className="page-header">{t('web.create.choose.liveChat')}</div>
              <div className="page-subheader display-none">{t('web.create.choose.liveChat.notice')}</div>
            </div>
          </Link>
          <Link to={`${match.url}/bot`} className="big-link-block w-inline-block">
            <img src={require('styles/images/create-web-chat-bot.svg')} alt="" className="link-block-icon"/>
            <div className="link-block-div">
              <div className="page-header">{t('web.create.choose.bot')}</div>
              <div className="page-subheader display-none">{t('web.create.choose.bot.notice')}</div>
            </div>
          </Link>
        </div>
      </div>
    </IntlWrapper>
  );
}