import React from 'react';
import { init, exec } from 'pell';
import {EditorState, RichUtils, KeyBindingUtil} from 'draft-js';
import 'draft-js/dist/Draft.css';

export const Link = props => {
  const { contentState, entityKey } = props;
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      className="link"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      aria-label={url}
    >
      {props.children}
    </a>
  );
};

export const Media = props => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const data = entity.getData();
  const type = entity.getType();

  if (type === "image") {
    return  !!data.src ?  <img src={data.src} /> : null;
  }

  if (type === "link") {
    return !!data.href ?  <a href={data.url}>{data.href}{data.url}</a> : null;
  }

  return null;
};



export const mediaBlockRenderer = block => {
  if (block.getType() === "atomic") {
    return {
      component: Media,
      editable: true
    };
  }
  return null;
};


export const linkStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

export const addLinkPlugin = {
  keyBindingFn(event, { getEditorState }) {
    const editorState = getEditorState();
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      return;
    }
    if (KeyBindingUtil.hasCommandModifier(event) && event.which === 75) {
      return "add-link";
    }
  },

  handleKeyCommand(command, editorState, { getEditorState, setEditorState }) {
    if (command !== "add-link") {
      return "not-handled";
    }
    let link = window.prompt("Paste the link -");
    const selection = editorState.getSelection();
    if (!link) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null));
      return "handled";
    }
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    setEditorState(RichUtils.toggleLink(newEditorState, selection, entityKey));
    return "handled";
  },

  decorators: [
    {
      strategy: linkStrategy,
      component: Link
    }
  ]
};


