import Cookies from 'js-cookie';
import settings from 'settings';

export const fetchProcessingPaymentsExist = () => {
  return !!Cookies.get(`${settings.SERVICE_BRAND_NAME}_kd`);
};

export const persistProcessingPaymentsExist = () => {
  // Set cookie for 7 minutes
  const date = new Date();
  date.setTime(date.getTime() + (7 * 60 * 1000));
  Cookies.set(`${settings.SERVICE_BRAND_NAME}_kd`, 'true', {expires: date});
};

export const removeProcessingPaymentsExist = () => {
  Cookies.remove(`${settings.SERVICE_BRAND_NAME}_kd`);
};
