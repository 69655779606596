import 'styles/normalize.css';
import 'styles/main.css';
import 'styles/chatbots.css';
import 'styles/custom.css';
import 'styles/sign.css';

// mandatory
import 'react-s-alert/dist/s-alert-default.css';

// optional - you can choose the effect you want
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import 'react-s-alert/dist/s-alert-css-effects/flip.css';
import 'react-s-alert/dist/s-alert-css-effects/genie.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'components/Root';

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);
