import React, {Fragment, useState, useCallback} from 'react';
import {t} from 'utils';
import {CAMPAIGN_REQUEST_TYPES, CAMPAIGN_TYPES} from 'constants.js';


export default function StepChain(props) {
  const {additionalClass, currentStep, campaign, setCurrentStep} = props;
  const backToPrevStep = useCallback((i) => {
    //console.log(campaign.id)
    if (currentStep > i || campaign.id) {
      setCurrentStep(i);
    }
  });
  const {type} = props;

  let steps = [];

  switch (type) {
    case CAMPAIGN_REQUEST_TYPES.bot_web_chat: steps = [campaign && campaign.id ? 'web.steps.bot.botName' : 'web.steps.bot.botType',
      'web.steps.bot.botBuilder', 'web.steps.bot.displaySettings']; break;
    case CAMPAIGN_REQUEST_TYPES.live_web_chat: steps = ['web.steps.liveChat.welcomeMessage', 'web.steps.liveChat.displaySettings']; break;
    case CAMPAIGN_TYPES.landbot.value: steps = ['landBot.steps.botType', 'landBot.steps.botBuilder', 'landBot.steps.design', 'landBot.steps.displaySettings']; break;
    case CAMPAIGN_TYPES.facebookMessenger.value: steps = ['facebook_messenger.steps.botType', 'facebook_messenger.steps.botBuilder']; break;
    default: steps = [];
  }

  return (
    <Fragment>
      <div className={`step-btns-w ${additionalClass}`}>
        {steps.map((step, i) => {
          return (
            <Fragment key={i}>
              <div onClick={() => backToPrevStep(i)} className={`step-btn w-inline-block${currentStep === i ? ' w--current' : ''}`}>
                <div className="step-number">{i + 1}</div>
                <div>{t(`${step}`)}</div>
              </div>
              {(i + 1 !== steps.length) && (
                <div className="arrow-block">
                  <div className="arrow-line">
                    <div className="top-arr-line"/>
                    <div className="top-arr-line bottom-line"/>
                  </div>
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
}