import React, { Fragment, useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import update from 'immutability-helper';
import ModalWarning from 'components/widgets/ModalWarning';
import ErrorBlock from 'components/widgets/ErrorBlock';
import CustomSelect from 'components/widgets/CustomSelect';
import {useFacebookConnected} from 'hooks'
import {t, isEmailValid} from 'utils';
import Alert from 'react-s-alert';
import FacebookLogin from 'react-facebook-login';
import SubmitButton from "../../../../widgets/SubmitButton";
import _ from "lodash";


const CHANNEL_TYPES = {
  whatsapp: 'whatsapp',
  messenger: 'messenger',
  telegram: 'telegram',
  viber: 'viber',
  instagram: 'instagram',
  email: 'email',
  phone: 'phone'
};

export default function Channels(props) {
  const {widget, setWidget, onSave} = props;

  const types = ['whatsapp', 'messenger', 'telegram', 'viber', 'instagram', 'email', 'phone'];
  const facebookSteps = ['connect', 'choosePage', 'disconnect'];

  const [channelSettings, setChannelSettings] = useState(widget.settings.channels);
  const [curWidgetId, setCurWidgetId] = useState(widget.id);
  const [isModalConnectOpen, setModalConnectOpen] = useState(false);
  const [isWarningShow, setIsWarningShow] = useState(false);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    setChannelSettings(widget.settings.channels);
    setCurWidgetId(widget.id)
  }, [widget])

  useEffect(() => {
    if (widget && widget.settings && widget.settings.channels && widget.id === curWidgetId &&
      !_.isEqual(widget.settings.channels, channelSettings)) {
      onSave({...widget, settings: {...widget.settings, channels: channelSettings}});
    }
  }, [widget, channelSettings, curWidgetId]);

  const _t = (value, values = {}, children = null) => {
    return t(`channels.${value}`, values, children);
  };

  const handleConnect = (value) => {

    setChannelSettings(update(channelSettings, {[selectedType]: {$set: value}}));
    setSelectedType(null);
  };

  const handleDisconnect = () => {
    setChannelSettings(update(channelSettings, {[selectedType]: {$set: null}}));
    setSelectedType(null);
    setIsWarningShow(false);
  };

  return (
    <>
      <div className="pane-content-w channels-settings">

        <div className="pane-content-w">
          <FacebookButtons _t={_t} settings={channelSettings.facebook}
                           setFacebookSettings={(data) => setChannelSettings(update(channelSettings, data))}/>

          {/*<div className="content-white-block">*/}
          {/*  <div>*/}
          {/*    <div className="group-title">{_t('facebook.account.title')}</div>*/}
          {/*    <div className="page-subheader">{_t('facebook.account.note')}</div>*/}
          {/*  </div>*/}
          {/*  <div className="group-content-w add-padding">*/}
          {/*    <a href="#" className="blue-button facebook-btn w-button">{_t('facebook.account.button')}</a>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="content-white-block">*/}
            {/*<div>*/}
              {/*<div className="group-title">{_t('messenger.title')}</div>*/}
              {/*<div className="page-subheader">{_t('messenger.note')}</div>*/}
            {/*</div>*/}
            {/*<div className="group-content-w add-padding">*/}
              {/*<a href="#" className="blue-button right-margin whatsapp-btn w-button">{_t('messenger.button.whatsApp')}</a>*/}
              {/*<a href="#" className="blue-button right-margin viber-btn w-button">{_t('messenger.button.viber')}</a>*/}
              {/*<a href="#" className="blue-button telegram-btn w-button">{_t('messenger.button.telegram')}</a>*/}
            {/*</div>*/}
          {/*</div>*/}
          {/*<div className="content-white-block">*/}
            {/*<div>*/}
              {/*<div className="group-title">{_t('callback.title')}</div>*/}
              {/*<div className="page-subheader">{_t('callback.note')}</div>*/}
            {/*</div>*/}
            {/*<div className="group-content-w add-padding">*/}
              {/*<a href="#" className="blue-button w-button">{_t('callback.button')}</a>*/}
            {/*</div>*/}
          {/*</div>*/}
          {/*<div className="content-white-block">*/}
            {/*<div>*/}
              {/*<div className="group-title">{_t('teamEmail.title')}</div>*/}
              {/*<div className="page-subheader">{_t('teamEmail.note')}</div>*/}
            {/*</div>*/}
            {/*<div className="group-content-w add-padding">*/}
              {/*<a href="#" className="blue-button w-button">{_t('teamEmail.button')}</a></div>*/}
          {/*</div>*/}
          <div className="content-white-block">
            <div>
              <div className="group-title">Connect another messenger apps</div>
              <div className="page-subheader">To integrate Heycom with Messenger, make a connection with a Heycom app on your Facebook fanpage first.</div>
            </div>
            <div className="group-content-w add-padding">
              {types.map((type, i) => !channelSettings[type] && (
                <div key={`connect-${type}`} className={`blue-button right-margin ${type}-btn w-button first-item`}
                     onClick={() => {
                       setSelectedType(type);
                       setModalConnectOpen(true);
                     }}>
                  {_t(`${type}.button.connect`)}
                </div>
              ))}
            </div>
          </div>
          <div className="content-white-block">
            <div>
              <div className="group-title">Disconnect another messenger apps</div>
              <div className="page-subheader">To integrate Heycom with Messenger, make a connection with a Heycom app on your Facebook fanpage first.</div>
            </div>
            <div className="group-content-w add-padding">
              {types.map((type, i) => channelSettings[type] && (
                <div key={`disconnect-${type}`} className={`blue-button right-margin ${type}-btn w-button first-item`}
                     onClick={() => {
                       setSelectedType(type);
                       setIsWarningShow(true);
                     }}>
                  {_t(`${type}.button.disconnect`)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <AddChannelModal
        type={selectedType}
        channelValue={channelSettings[selectedType]}
        isOpen={isModalConnectOpen}
        onConnect={handleConnect}
        onClose={() => {
          setSelectedType(null);
          setModalConnectOpen(false);
        }}
      />
      <ModalWarning
        isOpen={isWarningShow}
        text={_t('modal.warning.header')}
        buttonConfirmText={_t('modal.warning.disconnect')}
        onConfirm={handleDisconnect}
        onClose={() => {
          setSelectedType(null);
          setIsWarningShow(false);
        }}
      />
    </>
  );
}

const FacebookButtons = (props) => {
  const {_t, settings, setFacebookSettings} = props;
  const {facebookPages, selectedFacebookPage, facebookConnectingError, isLoading,
    getPages, facebookConnected, facebookLogout, facebookPageChoose, facebookPageDisconnect} = useFacebookConnected();
  const facebookSteps = ['connect', 'choosePage', 'disconnect'];
  const [facebookStep, setFacebookStep] = useState(facebookSteps[settings.fb_selected_page_id ? 2 : 0]);

  const [selectedPage, setSelectedPages] = useState(null);


  const handleFacebookConnect = (e) => {
    e.preventDefault();
    window.FB.login(function(response) {
      facebookConnected({access_token: response.authResponse.accessToken, user_id: response.authResponse.userID});
      setFacebookStep(facebookSteps[1]);
    },  {scope: 'public_profile,email,pages_show_list,pages_messaging,pages_manage_metadata'});
  };

  const handleFacebookPageConnect = (e) => {
    e.preventDefault();
    facebookPageChoose(selectedPage.id)
    .then(res=>{
      if (res.error){
        //console.log('BAD facebook page connected');
        //console.log(res.error.message );
        setFacebookStep(facebookSteps[0]);
        Alert.error(res.error.message);
      }
      else{
        //console.log('Good facebook page connected');
        //console.log(res);
        setFacebookSettings({facebook: {$set: {...settings, fb_selected_page_id: selectedPage.id}}});
        setFacebookStep(facebookSteps[2]);
      }
      
    })
    .catch(err=>{
      //console.log('BAD facebook page connected');
      //console.log(err);
      setFacebookStep(facebookSteps[0]);
    })
    
  };

  const handleFacebookCancelConnect = (e) => {
    e.preventDefault();
    facebookPageDisconnect(settings.fb_selected_page_id)
      .then(() => {
        facebookLogout();
        setFacebookStep(facebookSteps[0]);
      });
  };

  if (!window.FB) {
    return null;
  }

  return (
    <>
      {facebookStep === facebookSteps[0] && (
        <div className="content-white-block">
          <div>
            <div className="group-title">{_t('facebook.title')}</div>
            <div className="page-subheader">{_t('facebook.note')}</div>
          </div>
          <div className="group-content-w add-padding">
            <SubmitButton simpleButton={true} type={null}
                          additionalClass={'blue-button facebook-btn w-button'}
                          onLoadEnd={() => setFacebookStep(facebookSteps[1])}
                          onClick={handleFacebookConnect}
                          isSending={isLoading} text={_t('facebook.button')}/>
            {/*<div className="blue-button facebook-btn w-button" onClick={handleFacebookPageConnect}>*/}
            {/*  {_t('facebook.button')}</div>*/}
          </div>
        </div>
      )}
      {facebookStep === facebookSteps[1] && (
        <div className="content-white-block">
          <div>
            <div className="group-title">{_t('fanpage.title')}</div>
            <div className="page-subheader">{_t('fanpage.note')}</div>
          </div>
          <div className="table-content">
            {facebookPages.map((page) => {
              return (
                <div className="table-row">
                  <label className="visitor-info-w w-radio">
                    <input type="radio" id={page.id} value={selectedPage && page.id === selectedPage.id} required=""
                           onChange={() => setSelectedPages(page)}
                           className="w-form-formradioinput w-radio-input"/>
                    <div className="step-2-img">
                      <img src={page.url || `https://graph.facebook.com/${page.id}/picture`} alt=""/>
                    </div>
                    <span htmlFor={page.id} className="w-form-label">{page.name}</span>
                  </label>
                </div>
              )
            })}
          </div>
          <div className="group-content-w add-padding">
            <SubmitButton simpleButton={true}
                          additionalClass={'blue-button facebook-btn right-margin w-button'}
                          onLoadEnd={() => setFacebookStep(facebookSteps[2])}
                          onClick={handleFacebookPageConnect}
                          isSending={isLoading} text={_t('fanpage.button.save')}/>
            <SubmitButton simpleButton={true}
                          additionalClass={'blue-button transparent w-button'}
                          onLoadEnd={() => setFacebookStep(facebookSteps[0])}
                          onClick={handleFacebookCancelConnect}
                          isSending={isLoading} text={_t('fanpage.button.cancel')}/>
            {/*<div className="blue-button facebook-btn right-margin w-button" onClick={facebookPageConnect}>*/}
            {/*  {_t('fanpage.button.save')}</div>*/}
            {/*<div className="blue-button transparent w-button" onClick={handleFacebookCancelConnect}>*/}
            {/*  {_t('fanpage.button.cancel')}</div>*/}
          </div>
        </div>
      )}
      {selectedFacebookPage && facebookStep === facebookSteps[2] && (
        <div className="content-white-block">
          <div>
            <div className="group-title">{_t('fanpage.messenger.title')}</div>
            <div className="page-subheader">{_t('fanpage.messenger.note')}</div>
          </div>
          <div className="group-content-w add-padding">
            <div className="fanpage-info-w">
              <div className="fanpage-connected">
                <div className="step-2-img no-left-indent"><img
                  src={selectedFacebookPage.url || `https://graph.facebook.com/${selectedFacebookPage.id}/picture`}
                  alt=""/></div>
                <div>{selectedFacebookPage.name}</div>
              </div>
              <div>
                <div className="cp-info bottom-indent">Account ID: fb{selectedFacebookPage.id}</div>
                <div className="cp-info">Chat/Bot Link:&nbsp;
                  <a href={`https://m.me/${selectedFacebookPage.id}`}
                     className="link-3">https://m.me/{selectedFacebookPage.id}</a>
                </div>
              </div>
            </div>
            <SubmitButton simpleButton={true}
                          additionalClass={'blue-button facebook-btn w-button'}
                          onLoadEnd={() => setFacebookStep(facebookSteps[0])}
                          onClick={handleFacebookCancelConnect}
                          isSending={isLoading} text={_t('fanpage.messenger.button.cancel')}/>
            {/*<div className="blue-button facebook-btn w-button" onClick={handleFacebookCancelConnect}>*/}
            {/*  {_t('fanpage.messenger.button.cancel')}</div>*/}
          </div>
        </div>
      )}
    </>
  )
}

const AddChannelModal = (props) => {
  const {logo, type, channelValue, isOpen, onClose, onConnect} = props;

  const [value, setValue] = useState('');
  const [errors, setErrors] = useState({validation: null});

  const _t = (value, values = {}, children = null) => {
    return t(`channels.${value}`, values, children);
  };

  useEffect(() => {
    setValue(channelValue || '');
  }, [channelValue]);

  if (!type) {
    return null;
  }

  const handleChannelAdd = () => {
    let newErrors = {};
    switch(type) {
      case CHANNEL_TYPES.whatsapp: {}
      case CHANNEL_TYPES.phone: {}
      case CHANNEL_TYPES.viber: {
        if (!(/^\+[0-9]{10,15}$/i.test(value.trim()))) {
          newErrors = {...newErrors, validation: 'Not valid'}
        }
        break;
      }
      case CHANNEL_TYPES.email: {
        if (!isEmailValid(value.trim())) {
          newErrors = {...newErrors, validation: 'Not valid'}
        }
        break;
      }
      case CHANNEL_TYPES.instagram: {
        if (!(/^@[a-zA-Z0-9_.]{1,30}$/i.test(value.trim()))) {
          newErrors = {...newErrors, validation: 'Not valid'}
        }
        break;
      }
      case CHANNEL_TYPES.messenger: {
        if (!(/^(https:\/\/)?(www.)?messenger.com\/t\/[a-zA-Z0-9_.]{1,50}$/i.test(value.trim()))) {
          newErrors = {...newErrors, validation: 'Not valid'}
        }
        break;
      }
      case CHANNEL_TYPES.telegram: {
        if (!(/^(https:\/\/)?(www.)?t.me\/[a-zA-Z0-9_]{5,32}/i.test(value.trim()))) {
          newErrors = {...newErrors, validation: 'Not valid'}
        }
        break;
      }
    }
    if (newErrors.validation) {
      setErrors(newErrors);
    } else {
      onConnect(value.trim());
      close();
    }
  };

  const close = () => {
    setValue('');
    setErrors({validation: null});
    onClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 outline-none max-w-3"
      overlayClassName="popup-w w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={onClose}
    >
      <div className="popup-header-block">
        <img src={require(`styles/images/${type}-logo.svg`)} alt="" className="channel-icon"/>
        <div className="sign-header no-margin">{_t(`${type}.modal.header`)}</div>
      </div>
      <p className="popup-subheader">{_t(`${type}.modal.subheader`)}</p>
      <div className="w-form">
        <div>
          <div>
            <div className="error-info-client">
              <div className="settings-item-label">{_t(`${type}.modal.label`)}</div>
              <ErrorBlock error={errors.validation} addClass="align-right"/>
            </div>
            {_t(`${type}.modal.placeholder`, {},
              (msg) => <input type="text" className="node-settings-input-2 w-input"
                              value={value} onChange={e => {
                                setValue(e.target.value)
                                if (errors.validation) {setErrors({...errors, validation: null})}
                              }}
                              maxlength="256" placeholder={msg} required=""/>)}

          </div>
          <input type="button" value="Add channel" onClick={handleChannelAdd} className="button right-float w-button"/>
        </div>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
      <div className="abs-position">
        <div className="delete-btn" onClick={close}>
          <div className="close-line-1 rotate" style={{'backgroundColor': 'rgb(221, 221, 221)'}}></div>
          <div className="close-line-1" style={{'backgroundColor': 'rgb(221, 221, 221)'}}></div>
        </div>
      </div>
    </ReactModal>
  )
};