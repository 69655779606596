
export const addUrlSchemeIfNeeded = (url, scheme = null) => {
  if (url.indexOf('http://') < 0 && url.indexOf('https://') < 0) {
    if (scheme) scheme = scheme + '://';
    return (scheme || 'http://') + url;
  }
  return url;
};

export const removeUrlScheme = url => {
  let trimmedCharsNumber = -1;
  if (url.indexOf('http://') === 0) {
    trimmedCharsNumber = 7;
  } else if (url.indexOf('https://') === 0) {
    trimmedCharsNumber = 8;
  }

  if (trimmedCharsNumber >= 0) {
    return url.substring(trimmedCharsNumber);
  } else {
    return url;
  }
};
