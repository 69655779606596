import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import SignIn from './SignIn';

export default compose(
  connect((state, ownProps) => {
      return {
        messages: []
      };
    }
  ),

  lifecycle({
    componentDidMount() {
      document.title = 'Sign In | HeyCom'

      console.log('SignIn componentDidMount')
    },
  }),

  withHandlers()
) (
  SignIn
);