import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import Analytics from './Analytics';
import {main, analytics} from 'translate';

export default compose(
  connect((state, ownProps) => {
      return {
        messages: [main, analytics]
      };
    }
  ),

  lifecycle({
    componentDidMount() {
      document.title = 'Analytics | HeyCom'

      console.log('Analytics componentDidMount')
    },
  }),

  withHandlers()
) (
  Analytics
);