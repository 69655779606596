import React from 'react';
import {t} from 'utils';
import update from 'immutability-helper';
import DropdownBlock from "components/widgets/DropdownBlock";
import CustomSelect from "components/widgets/CustomSelect";
import Switch from "components/widgets/Switch";

export default function OtherSettings(props) {
  const {campaign, setCampaign} = props;
  const settings = campaign.display_settings.other;

  const _t = (value, values = {}, children = null) => {
    return t(`landbot.create.displaySettings.other.${value}`, values, children);
  };

  const settingBlocks = [
    {value: 'Second visit', label: _t('secondVisit.header'),
      content: <SecondVisit campaign={campaign} setCampaign={setCampaign} _t={_t}/>}
  ];

  const speedTypes = ['custom', 'constant'];

  return (
    <div className="w-tab-pane w--tab-active">
      <div className="pane-content-w">
        <div className="div-block-5">
          <div className="content-white-block-2 grow-w">
            {settingBlocks.map((block,i) =>
              <DropdownBlock title={block.label}
                             content={block.content}
                             additionalClass={i === 0 ? 'no-margin-top' : ''}/>
            )}
            <div className="content-white-block-2">
              <div className="group-w-header">
                <div className="group-title">{_t(`typingEmulation.header`)}</div>
                <Switch isOn={settings.typing_emulation.enabled}
                        onClick={() => setCampaign(update(campaign,
                          {display_settings: {other: {typing_emulation: {$toggle: ['enabled']}}}}))}/>
              </div>
              {settings.typing_emulation.enabled && (
                <div className="show-group-content">
                  <div className="show-group-div">
                    <div className="group-row">
                      <div className="col-6">
                        <div className="settings-item-label add-margin">{_t('typingEmulation.speed.label')}:</div>
                        <div className="choose-typing-emulation w-clearfix">
                          {speedTypes.map((type, i) => (
                            <>
                              <div className={`choose-item ${type === settings.typing_emulation.speed_type ? 'checked' : ''}`}
                                   onClick={() => setCampaign(update(campaign,
                                     {display_settings: {other: {typing_emulation: {speed_type: {$set: type}}}}}))}>
                                <div>{_t(`typingEmulation.speed.type.${type}`)}</div>
                              </div>
                              {i !== speedTypes.length - 1 && (
                                <div className="line display-none"/>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                      <div className="col-6 right-padding"/>
                    </div>
                    <div className="show-group-content-2-2">
                      <div className="show-group-div">
                        <div className="group-row">
                          <div className="col-6">
                            <div className="settings-item-label add-margin">{_t('typingEmulation.speed.type.readingSpeed.label')}:</div>
                            <input type="text" value={settings.typing_emulation.average_human_reading_speed}
                                   onChange={e => setCampaign(update(campaign,
                                     {display_settings: {other: {typing_emulation: {average_human_reading_speed: {$set: e.target.value}}}}}))}
                                   className="node-settings-input-2 w-input" maxLength="256"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="show-group-content-2-2">
                      <div className="show-group-div">
                        <div className="group-row">
                          <div className="col-6">
                            <div className="settings-item-label add-margin">{_t('typingEmulation.speed.type.delay.label')}:</div>
                            <input type="text" value={settings.typing_emulation.maximum_delay}
                                   onChange={e => setCampaign(update(campaign,
                                     {display_settings: {other: {typing_emulation: {maximum_delay: {$set: e.target.value}}}}}))}
                                   className="node-settings-input-2 w-input" maxLength="256"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SecondVisit = (props) => {
  const {campaign, setCampaign, _t} = props;
  const settings = campaign.display_settings.other;

  const secondVisitTypes = {
    'start_or_continue': {label: 'Provide buttons to start again or continue', value: 'start_or_continue'},
    'new': {label: 'Always start a new conversation', value: 'new'},
    'continue': {label: 'Always continue a conversation', value: 'continue'}
  };

  return (
    <>
      <div className="show-group-content-2-2">
        <div className="group-row">
          <div className="col-6">
            <CustomSelect options={Object.values(secondVisitTypes)}
                          defaultValue={Object.values(secondVisitTypes)[0]}
                          selectClassName={`settings-select w-select`}
                          getOptionLabel={option => option.label}
                          onChange={type => setCampaign(update(campaign,
                            {display_settings: {other: {second_visit: {type: {$set: type.value}}}}}))}
                          value={secondVisitTypes[settings.second_visit.type]}/>
          </div>
        </div>
      </div>
    </>
  );
};