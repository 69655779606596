import React, { Fragment, useState, useRef, useEffect } from 'react';
import {ChromePicker, SketchPicker} from 'react-color';
import {isHexColorValid, closeNodeByOnBlur, addRefFocus} from 'utils';

export default function ColorPicker(props){
  const { color, onChangeSettings} = props;
  const pickerRef = useRef();

  const [isColorPickerOpen, openColorPicker] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (pickerRef) {
      addRefFocus(pickerRef.current);
    }
  }, [pickerRef]);

  const _isColorValid = (color) => {
    const characters = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (color.length > 7) {
      return false;
    }
    if (color[0] !== '#') {
      return false;
    }
    for (let i = 1; i < color.length; i++) {
      const letter = color[i].toLowerCase();
      if (!(characters.indexOf(letter) !== -1)) {
        return false;
      }
    }
    return true;
  };

  const handleColorChange = (value) => {
    let hexColor = value;
    if (hexColor[0] !== '#' && hexColor.length > 0) {
      hexColor = '#' + hexColor;
    }
    if (_isColorValid(hexColor) || !hexColor.length) {
      onChangeSettings(hexColor);
      setError(null);
    }
  };

  const handlePickerClose = (event) => {
    if (closeNodeByOnBlur(event)) {
      openColorPicker(false)
    }
  };

  const handleColorCheck = () => {
    if (!isHexColorValid(color)) {
      setError('Invalid hex color');
    }
  };

  return (
    <div className="color-themes-w">
      <input value={color || '#0889dd'} onChange={e => handleColorChange(e.target.value)}
             type="text" className="node-settings-input color-input w-input" maxLength="256"
             placeholder="#0889dd" id="custom-color-theme"/>
      <div className="color-item solid-color" style={{background: `${color}`}}
           onClick={() => openColorPicker(!isColorPickerOpen)}></div>
      {isColorPickerOpen && (
        <div ref={pickerRef} className="color-picker-container no-outline" tabIndex={-1}
             onBlur={e => handlePickerClose(e)}>
          <ChromePicker
            disableAlpha={true}
            color={color || '#0889dd'}
            onChange={color => handleColorChange(color.hex)}
          />
        </div>
      )}
    </div>
  );
}
