import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import {TIME_FORMATS, TIME_PERIODS} from "constants.js";
import CustomSelect from "components/widgets/CustomSelect";

export default function TimePicker(props) {
  const {strTime, defaultTime, day, type, timeFormat,
    additionalTimeBlockClass = '', additionalPickerClass = '', additionalFormatClass = '',
    onChange} = props;
  const [editZone, setEditZone] = useState(null);
  const [period, setPeriod] = useState(TIME_PERIODS.PM.value);

  useEffect(() => {
    const elem = document.getElementById("time-picker-" + day + "-" + type);
    elem.addEventListener("keydown", (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleCheckKeyEvent(e)
    }, false);
  }, []);

  const timeConstant = {
    'hours': {
      'max': timeFormat === TIME_FORMATS["24h"].value ? 23 : 12,
      'min': timeFormat === TIME_FORMATS["24h"].value ? 0 : 1,
    },
    'minutes': {
      'max': 59,
      'min': 0,
    }
  };
  const classPlaceholder = (!strTime) ? "placeholder" : "";

  const handleCheckKeyEvent = e => {
    if((e.keyCode === 38) || (e.keyCode === 40)){
      const time = getHoursAndMinutes(strTime, defaultTime);
      const action = e.keyCode === 38 ? "add" : "subtract";
      handleChangeTime(action, time);
    }
  };

  const handleChangeEditZone = value => {
    setEditZone(value);
  };

  const handleChangeTime = (action, time) => {
    if (editZone === null) {
      setEditZone('hours');
    }

    if (action !== 'change_period') {
      time = changeElementTime(time, (editZone || 'hours'), action,
        timeConstant[editZone || 'hours'].max, timeConstant[editZone || 'hours'].min);
    }

    if (timeFormat === TIME_FORMATS["AM/PM"].value) {
      const strTime = moment(`${time['hours']}:${time['minutes']} ${period}`, 'HH:mm A').format('HH:mm');
      onChange(strTime);
    } else {
      onChange(`${time['hours']}:${time['minutes']}`);
    }
  };

  const getHoursAndMinutes = (time, default_time) => {
    if (timeFormat === TIME_FORMATS["AM/PM"].value) {
      time = moment(time, 'hh:mm').format('hh:mm');
    }

    const arTime = time ? time.split(':') : default_time.replace('-', ':').split(':');
    return {
      hours: arTime[0],
      minutes: arTime[1]
    };
  };

  const changeElementTime = (time, editZone, action, max, min) => {
    if (action === 'add') {
      time[editZone] = +time[editZone] + 1;
    } else {
      time[editZone] = +time[editZone] - 1;
    }
    if(time[editZone] < min) {
      time[editZone] = max;
    }
    if (time[editZone] > max) {
      time[editZone] = min;
    }

    if((time[editZone] <= 9) && (time[editZone] >= 0)) {
      time[editZone] = `0${time[editZone]}`;
    } else {
      time[editZone] = `${time[editZone]}`;
    }

    return time;
  };

  const [time, setTime] = useState(getHoursAndMinutes(strTime, defaultTime));

  useEffect(() => {
    setTime(getHoursAndMinutes(strTime, defaultTime));
  }, [strTime, timeFormat]);

  useEffect(() => {
    setPeriod(TIME_PERIODS[moment(strTime, 'hh:mm').format('A')].value);
  }, [timeFormat]);

  useEffect(() => {
    handleChangeTime('change_period', time);
  }, [period]);

  return (
    <>
      <div id={"time-picker-" + day + "-" + type} tabIndex="0"
           className={`time-picker min-input add-margin ${classPlaceholder} ${additionalPickerClass}`}>
        <div className={`time-block ${additionalTimeBlockClass}`}>
          <div className={(editZone === 'hours') ? 'active' : ''}
               onClick={() => {handleChangeEditZone('hours')}}>{time.hours}</div>
          {":"}
          <div className={(editZone === 'minutes') ? 'active' : ''}
               onClick={() => {handleChangeEditZone('minutes')}}>{time.minutes}</div>
        </div>
        <div className="arrows-block">
          <div className="arrow-up" onClick={() => {handleChangeTime('add', time)}}/>
          <div className="arrow-down" onClick={() => {handleChangeTime('subtract', time)}}/>
        </div>
      </div>

      {timeFormat === TIME_FORMATS["AM/PM"].value &&
        <CustomSelect options={Object.values(TIME_PERIODS)}
                      defaultValue={Object.values(TIME_PERIODS)[0]}
                      controlPadding="0 5px"
                      containerMinHeight="45px"
                      selectClassName={`settings-select w-select time-format add-margin schedule-select-time-format ${additionalFormatClass}`}
                      getOptionLabel={option => option.label}
                      onChange={period => setPeriod(period.value)}
                      value={TIME_PERIODS[period]}/>
      }
    </>
  );
}