import React, {Fragment, useState, useEffect} from 'react';

import Switch from 'components/widgets/Switch';
import TextareaWithAddons from 'components/widgets/TextareaWithAddons';
import AddContactProperties from 'components/widgets/AddContactProperties';
import CustomSelect from 'components/widgets/CustomSelect';
import ErrorBlock from 'components/widgets/ErrorBlock';
import ActionButtons from './ActionButtons';
import {useContactProperty} from 'hooks';
import {t as translateCommon, h} from 'utils';


export default function AskQuestionSettings(props) {
  const {currentNode, t, onChangeNode, onSave, onErrorFactSet, onCancel} = props;
  const {contactPropertiesByName} = useContactProperty();
  const [isAnswerSaving, toggleAnswerSaving] = useState(false);
  const [isNewPropertyAdding, toggleNewPropertyAdding] = useState(false);
  const [propertiesByLabel, setProperties] = useState({});
  const [errors, setErrors] = useState({question: null, errorMessage: null, validationNumber: null});


  const validationOptions = {
    none: {value: 'none', label: 'none'},
    name: {value: 'name', label: 'name'},
    email: {value: 'email', label: 'email'},
    number: {value: 'number', label: 'number'},
    phone_number: {value: 'phone_number', label: 'phoneNumber'},
    website: {value: 'website', label: 'websiteAddress'}
  };


  const handleNodeChange = (data, field) => {
    onChangeNode(currentNode.content[field] ?
      {content: {[field]: {$set: data}}} : {content: {$merge: {[field]: data}}}, (data !== 0 && !data));
  };

  const save = () => {
    let gettingErrors = {};
    if (currentNode.content.save_answer_as_property && !currentNode.content.contact_property) {
      if (contactPropertiesByName) {
        handleNodeChange(Object.values(contactPropertiesByName)[0].name, 'contact_property');
      } else {
        gettingErrors = {...gettingErrors, contactProperty: translateCommon('error.property.empty')};
      }
    }
    if (!currentNode.content.question) {
      gettingErrors = {...gettingErrors, question: translateCommon('error.field.empty')};
    }
    if (currentNode.content.validator && currentNode.content.validator !== 'none' && !currentNode.content.error_message) {
      gettingErrors = {...gettingErrors, errorMessage: translateCommon('error.field.empty')};
    }
    if (currentNode.content.validator && currentNode.content.validator !== 'none' && !currentNode.content.number_of_repeats) {
      gettingErrors = {...gettingErrors, validationNumber: translateCommon('error.field.empty')};
    }
    if (currentNode.content.validator && currentNode.content.validator !== 'none' && currentNode.content.number_of_repeats < 0) {
      gettingErrors = {...gettingErrors, validationNumber: translateCommon('error.number.lessZero')};
    }
    if (Object.values(gettingErrors).length === 0) {
      onErrorFactSet(false);
      onSave();
    } else {
      onErrorFactSet(true);
      setErrors(gettingErrors);
    }
  };

  return (
    <Fragment>
      <div className="node-settings">
        <div className="settings-item">
          <div className="error-info-client">
            <div className="settings-item-label add-margin">{t('question.label')}</div>
            <ErrorBlock error={errors.question} addClass="align-right"/>
          </div>
          <TextareaWithAddons placeholderField={`web.create.botBuilder.${currentNode.type}.question.placeholder`}
                              isNotDeleting={true}
                              value={currentNode.content.question ||  ''}
                              onChange={value => {
                                if (errors && errors.question) {
                                  setErrors({...errors, question: null});
                                }
                                handleNodeChange(value, 'question')
                              }}/>
          <div className="info-text min-indent">{t('question.notice')}</div>
        </div>
        <div className="settings-item select-z-index">
          <div className="settings-item-label add-margin">{t('validation.label')}</div>
          <CustomSelect options={Object.values(validationOptions)}
                        defaultValue={validationOptions[currentNode.content.validator || 'none']}
                        selectClassName={`settings-select w-select`}
                        getOptionLabel={option => t(`validation.${option.label}`)}
                        onChange={validation => handleNodeChange(validation.value, 'validator')}/>
        </div>
        {currentNode.content.validator && currentNode.content.validator !== 'none' && (
          <div className="settings-item">
            <div className="error-info-client">
              <div className="settings-item-label add-margin">{t('error.label')}</div>
              <ErrorBlock error={errors.errorMessage} addClass="align-right"/>
            </div>
            {t(`error.${currentNode.content.validator || 'none'}.placeholder`, {},
              msg => <input type="text" className="node-settings-input w-input"
                            value={currentNode.content.error_message || ''}
                            onChange={e => {
                              if (errors && errors.errorMessage) {
                                setErrors({...errors, errorMessage: null});
                              }
                              handleNodeChange(e.target.value, 'error_message')
                            }}
                            maxLength="256" placeholder={msg} required=""/>
            )}
          </div>
        )}
        <div className="settings-item">
          <div className="error-info-client">
            <div className="settings-item-label add-margin">{t('repeats.label')}</div>
            <ErrorBlock error={errors.validationNumber} addClass="align-right"/>
          </div>
          <input type="number" className="node-settings-input w-input"
                 value={currentNode.content.number_of_repeats || 0}
                 onChange={e => {
                   if (errors && errors.validationNumber) {
                     setErrors({...errors, validationNumber: null});
                   }
                   handleNodeChange(e.target.value, 'number_of_repeats')
                 }}
                 maxLength="256" placeholder="1" required=""/>
        </div>
      </div>
      <div className="divider"></div>
      <div className="transfer-header">
        <div className="settings-item-label">{t('contactProperty.label')}</div>
        <Switch isOn={currentNode.content.save_answer_as_property}
                onClick={() => {
                  if (errors && errors.contactProperty) {
                    setErrors({...errors, contactProperty: null});
                  }
                  handleNodeChange(!currentNode.content.save_answer_as_property, 'save_answer_as_property')
                }}/>
      </div>
      <div className="error-info-client">
        <ErrorBlock error={errors.contactProperty}/>
      </div>
      {currentNode.content.save_answer_as_property && (
        <Fragment>
          <div className="transfer-settings-w">
            <div className="settings-item select-z-index">
              {contactPropertiesByName ? (
                <CustomSelect options={Object.values(contactPropertiesByName).map(o => ({value: o.name, label: o.label}))}
                              menuMaxHeight={'250px'}
                              defaultValue={{
                                value: Object.values(contactPropertiesByName)[0].name,
                                label: Object.values(contactPropertiesByName)[0].label
                              }}
                              value={contactPropertiesByName[currentNode.content.contact_property] ? {
                                value: contactPropertiesByName[currentNode.content.contact_property].name,
                                label: contactPropertiesByName[currentNode.content.contact_property].label
                              } : {}}
                              selectClassName={`settings-select w-select`}
                              getOptionLabel={option => option.label}
                              onChange={property => handleNodeChange(property.value, 'contact_property')}/>
              ) : (
                <div className="no-chats">No contact property</div>
              )}

            </div>
            <div className="btns-w">
              <div className="step-btn add-field-full w-inline-block" onClick={() => {
                if (errors && errors.contactProperty) {
                  setErrors({...errors, contactProperty: null});
                }
                toggleNewPropertyAdding(true)
              }}>
                <div>{translateCommon('newProperty.add')}</div>
              </div>
            </div>
          </div>
          {isNewPropertyAdding && (
            <AddContactProperties/>
          )}
        </Fragment>
      )}
      <ActionButtons onSave={save} onCancel={onCancel}/>
    </Fragment>
  );
}