import React, {useState, useEffect, useRef} from 'react';
import { init, exec } from 'pell';
import {EditorState, RichUtils, AtomicBlockUtils, Modifier} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import Editor from 'draft-js-plugins-editor';
import 'draft-js/dist/Draft.css';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import {useContactProperty} from 'hooks';
import ImageLoaderModal from 'components/widgets/ImageLoaderModal';
import {t, mediaBlockRenderer, Link, addLinkPlugin} from 'utils';


export default function EmailBodyWYSIWYGEditor(props) {
  const {defaultValue, value, onChangeValue} = props;
  const [isShowImageLoader, toggleShowImageLoader] = useState(false);
  let options = {
    //defaultBlockTag: null,
  };

  const [editorState, setEditorState] = useState(value ?
    EditorState.createWithContent(stateFromHTML(value)) : EditorState.createEmpty());
  useEffect(() => {
    if (defaultValue) {
      setEditorState(defaultValue ?
        EditorState.createWithContent(stateFromHTML(defaultValue)) : EditorState.createEmpty())
    }
  }, [defaultValue]);

  const [templateVisible, toggleTemplateVisible] = useState(false);
  const [isEmojiShow, showEmojiPicker] = useState(false);
  const textRef = useRef(null);
  const pickerRef = useRef(null);
  const showPickerRef = useRef(null);
  // Invitee Name
  // Invitee Email
  // Invitee Title
  // Invitee Phone
  // Start Time
  // End Time
  // Date
  // Timezone (short)
  // Timezone (long)
  // Meeting Location
  // Meeting Description
  // Meeting Name
  // Meeting Page
  //
  // Contact Name
  // Contact Email
  // Contact Title
  // Contact Phone
  //
  // Company Name
  // Domain
  // Company Description
  // Company Location
  const contactPropertiesByName = {
    invitee_name: {name: 'invitee_name', label: 'Invitee First Name', property: 'meeting.client.first_name'},
    invitee_last_name: {name: 'invitee_last_name', label: 'Invitee Last Name', property: 'meeting.client.last_name'},
    invitee_email: {name: 'invitee_email', label: 'Invitee Email', property: 'meeting.client.email'},
    invitee_title: {name: 'invitee_title', label: 'Invitee Title', property: 'meeting_link.title'},
    //invitee_phone: {name: 'invitee_phone', label: 'Invitee Phone'},
    //start_time: {name: 'start_time', label: 'Start Time', property: 'meeting_link.datetime'},
    //end_time: {name: 'end_time', label: 'End Time', property: 'meeting.client.name'},
    date: {name: 'date', label: 'Date', property: 'meeting.datetime'},
    //timezone_short: {name: 'timezone_short', label: 'Timezone (short)'},
    //timezone_long: {name: 'timezone_long', label: 'Timezone (long)'},
    meeting_location: {name: 'meeting_location', label: 'Meeting Location', property: 'meeting_link.location'},
    meeting_description: {name: 'meeting_description', label: 'Meeting Description', property: 'meeting_link.description'},
    meeting_name: {name: 'meeting_name', label: 'Meeting Name', property: 'meeting_link.name'},
    meeting_page: {name: 'meeting_page', label: 'Meeting Page', property: 'meeting_link.meeting_url'},
    contact_name: {name: 'contact_name', label: 'Contact Name', property: 'operator.name'},
    contact_email: {name: 'contact_email', label: 'Contact Email', property: 'user.email'},
    //contact_title: {name: 'contact_title', label: 'Contact Title', property: 'operator.name'},
    //contact_phone: {name: 'contact_phone', label: 'Contact Phone', property: 'operator.name'},
    //company_name: {name: 'company_name', label: 'Company Name'},
    //company_description: {name: 'company_description', label: 'Company Description'},
    //company_location: {name: 'company_location', label: 'Company Location'},
    //domain: {name: 'domain', label: 'Domain'},
  };

  const editor = useRef(null);

  const focusEditor = () => {
    editor.current.focus();
  };

  useEffect(() => {
    onChangeValue(stateToHTML(editorState.getCurrentContent(), options));
  }, [editorState]);

  const onChange = state => {
    setEditorState(state);
  };

  const createNewEditor = (type, data) => {
    const state = editorState;
    const contentState = state.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      type,
      "IMMUTABLE",
      data
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      state,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    const valueText = data.text || " ";
    setEditorState(
      AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        valueText
      ));
  };

  const onAddImage = e => {
    e.preventDefault();
    toggleShowImageLoader(true);
    //const urlValue = window.prompt("Paste Image Link");
    //createNewEditor("image", { src: urlValue })
  };

  const onSaveImage = url => {
    createNewEditor("image", { src: url });
  };

  const onAddLink = () => {
    //const editorState = editorState;
    const selection = editorState.getSelection();
    const link = window.prompt("Paste the link -");
    if (!link) {
      onChange(RichUtils.toggleLink(editorState, selection, null));
      return "handled";
    }
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
    return "handled";
  };

  const onBoldClick = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const onItalicClick = (e) => {
    onChange(
      RichUtils.toggleInlineStyle(editorState, "ITALIC")
    );
  };

  const addEmoji = e => {
    const emoji = e.native;
    onChange(EditorState.createWithContent(Modifier.insertText(editorState.getCurrentContent(), editorState.getSelection(), emoji)));
    showEmojiPicker(false);
  };

  const addTemplate = property => {
    const template = property.property;
    onChange(EditorState.createWithContent(Modifier.insertText(editorState.getCurrentContent(), editorState.getSelection(), `{{${template}}}`)));
    toggleTemplateVisible(false);
  };

  //TODO: add  draft-js-resizeable-plugin
  return (
    <>
      <div className="edit-tools-w">
        <div className="vert-line"></div>
        <div onClick={onBoldClick} className="control-btn more-size-2" data-ix="emoji-animate">
          <div></div>
        </div>
        <div className="vert-line"></div>
        <div onClick={onItalicClick} className="control-btn more-size-2" data-ix="emoji-animate">
          <div></div>
        </div>
        <div className="vert-line"></div>
        <div onClick={onAddLink} className="control-btn more-size-2" data-ix="emoji-animate">
          <div></div>
        </div>
        <div className="vert-line"></div>
        <div onClick={onAddImage} className="control-btn more-size-2" data-ix="emoji-animate">
          <div></div>
        </div>
        <div className="vert-line"></div>
        <div className="control-btn more-size-2" data-ix="emoji-animate">
          <Picker ref={pickerRef} onSelect={addEmoji} showPreview={false} showSkinTones={false} set="twitter"
                  autoFocus={true}
                  style={{ position: 'absolute', width: '200px', bottom: '-180px', right: '30px', zIndex: 170,
                    display: isEmojiShow ? 'block' : 'none'}}
                  include={['search','recent','people', 'objects', 'symbols']}/>
          <div id="show-emoji-mart"  className="emoji-icon" ref={showPickerRef} onClick={() => showEmojiPicker(!isEmojiShow)}></div>
        </div>
        <div className="vert-line"></div>
        {contactPropertiesByName && Object.values(contactPropertiesByName).length && (
          <div data-delay="0" className="control-btn no-indent w-dropdown" style={{zIndex: 10}}>
            <div className="toggle-control-btn black-icon w-dropdown-toggle" onClick={() => toggleTemplateVisible(!templateVisible)}>
              <div></div>
              <div className="drop-icon hide-icon w-icon-dropdown-toggle"></div>
            </div>
            {templateVisible && (
              <nav className="dropdown-list move-list new-position w-dropdown-list email-template-custom-property w--open">
                {Object.values(contactPropertiesByName).map((property, i) => {
                  return (
                    <div key={i} className="dropdown-link w-dropdown-link" onClick={() => addTemplate(property)}>
                      <span className="awesome-icon fix-w"></span>  {property.label}</div>
                  );
                })}
              </nav>
            )}
          </div>
        )}
      </div>
      <Editor
        blockRendererFn={mediaBlockRenderer}
        ref={editor}
        editorState={editorState}
        onChange={state => onChange(state)}
        plugins={[addLinkPlugin]}
      />
      <ImageLoaderModal isOpen={isShowImageLoader} onClose={() => toggleShowImageLoader(false)}
                   onSaveFile={url => onSaveImage(url)}/>
    </>
  );
}

