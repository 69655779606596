import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';


export default function ShareButtons(props) {
  const {url} = props;

  return (
    <>
      <EmailShareButton url={url} subject="Heycom Landbot" body="Heycom Landbot" >
        <div className="social-link right-margin w-inline-block">
          <img src={require('styles/images/email.svg')} alt="Email"/>
        </div>
      </EmailShareButton>
      <TwitterShareButton url={url} title="Heycom Landbot">
        <div className="social-link right-margin w-inline-block">
          <img src={require('styles/images/twitter.svg')} alt="Twitter"/>
        </div>
      </TwitterShareButton>
      <FacebookShareButton url={url} quote="Heycom Landbot">
        <div className="social-link right-margin w-inline-block">
          <img src={require('styles/images/facebook.svg')} alt="Facebook"/>
        </div>
      </FacebookShareButton>
      <LinkedinShareButton url={url} title="Heycom Landbot">
        <div className="social-link right-margin w-inline-block">
          <img src={require('styles/images/linkedIn.svg')} alt="Linkedin"/>
        </div>
      </LinkedinShareButton>
      <WhatsappShareButton url={url} title="Heycom Landbot">
        <div className="social-link right-margin w-inline-block">
          <img src={require('styles/images/whatsApp.svg')} alt="Whatsapp"/>
        </div>
      </WhatsappShareButton>

    </>
  );
};