import React, {Fragment, useState, useEffect, useRef} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import StepChain from '../../StepChain';
import ShareSettings from "components/views/CampaignSettings/Landbot/DisplaySettings/ShareSettings";
import SeoSettings from "components/views/CampaignSettings/Landbot/DisplaySettings/SeoSettings";
import OtherSettings from "components/views/CampaignSettings/Landbot/DisplaySettings/OtherSettings";
import Header from 'components/Header';
import {t} from 'utils';
import {useCampaigns, useCurrentUserPlan} from "hooks";
import SubmitButton from "components/widgets/SubmitButton";
import {CAMPAIGN_REQUEST_TYPES} from "../../../../../constants";


export default function DisplaySettings(props) {
  const {isCreate, isCampaignSaving, type, currentStep, setCurrentStep, campaign, setCampaign, onSave} = props;
  const {isCreating, isUpdating} = useCampaigns();
  const {userCurrentPlan} = useCurrentUserPlan();

  const messageConstant = 'landbot.create.displaySettings';
  const tabs = [
    {value: 'Share', label: t(`${messageConstant}.share.tab`)},
    {value: 'SEO & tracking', label: t(`${messageConstant}.seo.tab`)},
    {value: 'Other settings', label: t(`${messageConstant}.other.tab`)},
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  const isClickSubmitButton = useRef([false, false]);

  return (
    <Fragment>
      <Header title={t(`${messageConstant}.header${campaign && campaign.id ? '.edit' : ''}`)}>
        <StepChain type={type} currentStep={currentStep} campaign={campaign}
                   setCurrentStep={setCurrentStep} additionalClass="no-bottom-indent"/>
      </Header>

      <div className="page-content normal-content">
        <div className="w-form">
          <div id="email-form-2" name="email-form-2" data-name="Email Form 2">
            <div className="page-content-header-div">
              <div className="page-header">{t(`${messageConstant}.title`)}</div>
              <div className="page-subheader">{t(`${messageConstant}.subtitle`)}</div>
            </div>
            <div className="tabs w-tabs">
              <div className="left-float-menu w-tab-menu top-0">
                {tabs.map((tab, i) =>
                  <div key={i} onClick={() => setCurrentTab(tab.value)}
                       className={`left-tab-link-2 w-inline-block w-tab-link ${tab.value === currentTab ? 'w--current' : ''}`}>
                    <div><strong>{tab.label}</strong></div>
                  </div>
                )}
              </div>
              <div className="tabs-content w-tab-content">
                {currentTab === 'Share' && (
                  <ReactCSSTransitionGroup
                    transitionName="tabs-on"
                    transitionAppear={true}
                    transitionAppearTimeout={300}
                  >
                    <ShareSettings campaign={campaign} setCampaign={setCampaign}/>
                  </ReactCSSTransitionGroup>
                )}
                {currentTab === 'SEO & tracking' && (
                  <ReactCSSTransitionGroup
                    transitionName="tabs-on"
                    transitionAppear={true}
                    transitionAppearTimeout={300}
                  >
                    <SeoSettings campaign={campaign} setCampaign={setCampaign}/>
                  </ReactCSSTransitionGroup>
                )}
                {currentTab === 'Other settings' && (
                  <ReactCSSTransitionGroup
                    transitionName="tabs-on"
                    transitionAppear={true}
                    transitionAppearTimeout={300}
                  >
                    <OtherSettings campaign={campaign} setCampaign={setCampaign}/>
                  </ReactCSSTransitionGroup>
                )}
              </div>
            </div>
            <div className="settings-btn-w">
              {isCreate ? (
                <>
                  <SubmitButton simpleButton={true} additionalClass={'blue-button w-button'}
                                spinnerClass={'blue-button'}
                                disabled={userCurrentPlan.landing_bot_chats_limit &&
                                userCurrentPlan.landing_bot_chats_count >= userCurrentPlan.landing_bot_chats_limit}
                                onClick={() => {isClickSubmitButton.current[0] = true; onSave(true, false);}}
                                isSending={(isCreating || isUpdating || isCampaignSaving) && isClickSubmitButton.current[0]}
                                onLoadEnd={() => {window.location = '/campaigns';}}
                                text={t(`${messageConstant}.activateNow`)}/>
                  <SubmitButton simpleButton={true} additionalClass={'left-indent w-button'}
                                onClick={() => {isClickSubmitButton.current[1] = true; onSave(false, false);}}
                                isSending={(isCreating || isUpdating || isCampaignSaving) && isClickSubmitButton.current[1]}
                                onLoadEnd={() => {window.location = '/campaigns';}}
                                text={t(`${messageConstant}.activateLater`)}/>
                </>
              ) : (
                <SubmitButton simpleButton={true} additionalClass={'left-indent w-button'}
                              onClick={() => onSave(campaign.enabled, false)}
                              isSending={(isCreating || isUpdating || isCampaignSaving)}
                              onLoadEnd={() => {window.location = '/campaigns';}}
                              text={t(`button.save`)}/>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}