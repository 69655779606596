import React, { Fragment, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import update from 'immutability-helper';
import { Link } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';
import ContentEditable from 'react-contenteditable'
import VisibilitySensor from 'react-visibility-sensor';
import { Element } from 'react-scroll';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import ReactModal from 'react-modal';

import {
  useSendMessage, useReadMessage, useScrollPagination, useSendFiles, useCurrentUserPlan,
  useDebounce, useCurrentUserState, useUpdateChat, useCreateChat, useGetCurrentOperator,
  useSelectSettingsTab, useStateWidgets, useAskChatRating, useSendTranscript, useCampaigns, useWidgets
} from 'hooks';
import FileLoader from 'components/widgets/FileLoader';
import SubmitButton from 'components/widgets/SubmitButton';
import Loader from 'components/widgets/Loader';
import ModalWarning from 'components/widgets/ModalWarning';
import CustomSelect from 'components/widgets/CustomSelect';
import VisitorInfo from './VisitorInfo';
import {t, getOperatorName, addRefFocus, isEmailValid} from 'utils';
import {SendMessage, ChoiceButtonMessage, ChoiceCardMessage} from './botCampaignElements';
import {CAMPAIGN_REQUEST_TYPES, CAMPAIGN_TYPES, SENDER_TYPES, BOT_NODE_TYPES, CHANNELS_OPTIONS} from 'constants.js';


export default function Chat(props) {
  const {operatorsById, chat, chatId, selectedSearchResult, searchMode,
    getChatMessages, onResetSelectSearchResult, typing, operatorStartTyping, operatorEndTyping} = props;

  //const [chat, setChat] = useState({});
  const {currentUser} = useCurrentUserState();
  const {isWidgetFetching} = useWidgets();

  const updateChat = useUpdateChat();
  const askChatRating = useAskChatRating();
  const sendTranscript = useSendTranscript();
  const [isActionsToggle, toggleActions] = useState(false);
  const [isWarningShow, showWarning] = useState(false);
  const [isReassignShow, showReassign] = useState(false);
  const [isEnterEmailShow, showEnterEmail] = useState(false);
  const [currentOperator] = useGetCurrentOperator();


  useEffect(() => {
    //setChat(chat);
  }, [chat]);

  const handleBlockVisitor = () => {
    if (chat.status === 'blocked') {
      updateChat(chat.id, {status: 'pending'});
    } else {
      updateChat(chat.id, {status: 'blocked'});
    }
    showWarning(false);
  };

  const handleMakePending = () => {
    updateChat(chat.id, {status: 'pending'});
  };

  const handleCloseChat = () => {
    updateChat(chat.id, {status: 'closed'});
  };

  const handleAskChatRating = () => {
    askChatRating(chat.id);
  };

  const handleReassignOperator = (newOperatorId) => {
    const operatorIds = cloneDeep(chat.active_operator_ids);
    operatorIds.push(newOperatorId);
    _updateOperators(operatorIds);
  };

  const handleLeaveChat = () => {
    _updateOperators(chat.active_operator_ids);
    //updateChat(chat.id, {status: 'closed'});
  };

  const handleSendTranscript = (email) => {
    sendTranscript(email, chat.id);
    showEnterEmail(false);
  };

  const handleStartTyping = () => {
    operatorStartTyping({
      chat_id: chatId,
      sender_id: currentOperator.id,
      sender_type: SENDER_TYPES.operator,
      recipient_ids: chat.client_id
    });
  };

  const handleEndTyping = () => {
    operatorEndTyping({
      chat_id: chatId,
      sender_id: currentOperator.id,
      sender_type: SENDER_TYPES.operator,
      recipient_ids: chat.client_id
    });
  };

  const _updateOperators = (activeOperatorIds) => {
    const indOperator = activeOperatorIds.indexOf(currentOperator.id);
    if (indOperator !== -1) {
      const result = cloneDeep(activeOperatorIds);
      result.splice(indOperator, 1);
      updateChat(chat.id, {active_operator_ids: result});
    }
  };

  const actions = [
    { value: 'reassignOperator', label: 'chat.actions.reassignOperator', action: () => showReassign(true) },
    { value: 'sendTranscript', label: 'chat.actions.sendTranscript', action: () => showEnterEmail(true) },
    { value: 'blockVisitor', label: `chat.actions.${chat.status === 'blocked' ? 'unblock' : 'block'}`, action: () => showWarning(true)},
    { value: 'askChatRating', label: 'chat.actions.askChatRating', action: handleAskChatRating},
    { value: 'leaveChat', label: 'chat.actions.leaveChat', action: handleLeaveChat},
    { value: 'leaveAndClose', label: 'chat.actions.leaveAndClose', action: () => {handleLeaveChat(); handleCloseChat();}},
    { value: 'makePending', label: 'chat.actions.makePending', action: handleMakePending},
    { value: 'close', label: 'chat.actions.close', action: handleCloseChat}
  ];

  if (!chat || (chat && !chat.id)) {
    return (<Loader isSmall={true}/>);
  }

  const {messages, client, campaign_type} = chat;
  return (
    <Fragment>
      <div className="chat-wrap custom">
        <div className="chat-header-w" id="chat-header">
          <div className="chat-header-div-1 flex-column">
            <div className="position-relative flex-row">
              {chat.client && chat.client.is_online && (
                <div className="online-indicator chat-header"></div>
              )}
              <div className="chat-user-name">{chat.client ? (chat.client.name || 'Site Visitor') : 'Site Visitor'}</div>
              <div className="location-div">
                <div className="user-location">Chat via {t(Object.values(CHANNELS_OPTIONS).find(channel => channel.includedTypes.includes(campaign_type)).label)}
                &nbsp;
                {chat.campaign && chat.campaign.name ? (
                  <>(<span className="chat-campaign-name">{`campaign ${chat.campaign.name}`}</span>)</>
                ) : ''}
                </div>
              </div>
            </div>
            <div className="user-location no-indent"><span className="pin-icon"></span> {
              client.city && client.country ?
                (client.city + ', ' + client.country)
                : (client.city || client.country || 'Location not found')
            }</div>
          </div>
          <div className="filter-drop no-indent w-dropdown"
               onMouseOver={() => toggleActions(true)} onMouseOut={() => toggleActions(false)}>
            <div className="filter-drop-tog awesome-font w-dropdown-toggle">
              <div></div>
            </div>
            <nav className={`dropdown-list right-float-list w-dropdown-list ${isActionsToggle ? 'w--open' : ''}`}>
              {currentOperator && actions.map((option, i) => {
                if (!chat.active_operator_ids) {
                  return (
                    <div key={i} className="dropdown-link w-dropdown-link disabled-text">
                      {t(option.label)}</div>
                  );
                }
                if (chat.active_operator_ids && chat.active_operator_ids.indexOf(currentOperator.id) === -1 &&
                  (option.value === 'reassignOperator' || option.value === 'leaveChat' || option.value === 'leaveAndClose')) {
                  return (
                    <div key={i} className="dropdown-link w-dropdown-link disabled-text">
                      {t(option.label)}</div>
                  );
                }
                return (
                  <div key={i} onClick={() => {
                    option.action();
                    toggleActions(false);
                  }} className="dropdown-link w-dropdown-link">
                    {t(option.label)}</div>
                );
              })}
            </nav>
          </div>
        </div>
        {!messages ? (
          <>
          {chat.id !== 'default' ? (<Loader isSmall={true}/>) : (<div className="no-messages-chat">No messages</div>)}
          </>
        ) : (
          <ChatMessages operatorsById={operatorsById} chat={chat} chatId={chatId}
                        selectedSearchResult={selectedSearchResult} searchMode={searchMode}
                        getChatMessages={getChatMessages} onResetSelectSearchResult={onResetSelectSearchResult}/>
        )}
        <ChatNewMessage chat={chat} startTyping={handleStartTyping} endTyping={handleEndTyping} typing={typing}/>
      </div>
      <VisitorInfo chat={chat}/>
      <ModalWarning
        isOpen={isWarningShow}
        text={chat.status === 'blocked' ? t('chat.blockModal.text.unblocked') : t('chat.blockModal.text.blocked')}
        onConfirm={handleBlockVisitor}
        onClose={() => showWarning(false)}
      />
      {chat.active_operator_ids && (
        <ModalOperatorReassign
          operatorsById={operatorsById}
          activeOperatorIds={chat.active_operator_ids}
          currentUser={currentUser}
          isOpen={isReassignShow}
          onUpdate={handleReassignOperator}
          onClose={() => showReassign(false)}
        />
      )}
      <ModalTypeEmail
        isOpen={isEnterEmailShow}
        onSave={handleSendTranscript}
        onClose={() => showEnterEmail(false)}
      />
    </Fragment>
  );
};

const ChatMessages = (props) => {
  const {operatorsById, chat, chatId, selectedSearchResult, searchMode,
    getChatMessages, onResetSelectSearchResult} = props;

  const [localChat, setChat] = useState({});
  const {currentUser} = useCurrentUserState();
  const scrollRef = useRef(null);

  const searchRef = useRef(null);

  const readMessage = useReadMessage();


  const [lastReadMessage, setLastReadMessage] = useState(null);
  const debouncedLastReadMessage = useDebounce(lastReadMessage, 250);

  const {hasMorePrevMessage, hasMoreNextMessage, localSearchResult, isLoadingMoreMessage,
    handleMessagesLoad} = useScrollPagination({selectedSearchResult, selectedChatId: chatId,
    chat: localChat, selectedChat: chat, searchMode,
    getChatMessages, onResetSelectSearchResult, setChat});

  const [containmentElement, setContainmentElement] = useState(null);

  useEffect(() => {
    setContainmentElement(document.getElementById('chat-window'));
  }, [chat]);

  useEffect(() => {
    if (chat) {
      setChat(chat);
      setLastReadMessage(chat.unread_messages_by_user);
    }
  }, [chat]);

  useEffect(() => {
    if ((debouncedLastReadMessage) && (debouncedLastReadMessage > chat.unread_messages_by_user)) {
      readMessage(debouncedLastReadMessage, chat.id);
    }
  }, [debouncedLastReadMessage]);

  const handleScroll = (e) => {
    if (chat.id && chat.messages ) {
      if (scrollRef && scrollRef.current) {
        const {
          firstChild,
          lastChild,
          scrollTop,
          offsetTop,
          offsetHeight
        } = scrollRef.current;

        if (firstChild && lastChild) {
          const topEdge = firstChild.offsetTop;
          const bottomEdge = lastChild.offsetTop + lastChild.offsetHeight;
          const scrolledUp = scrollTop + offsetTop;
          const scrolledDown = scrolledUp + offsetHeight;

          if (bottomEdge - scrolledDown <= 300 && localSearchResult && hasMoreNextMessage && !isLoadingMoreMessage) {
            handleMessagesLoad(1)
          }

          if (scrolledUp - topEdge <= 350 && hasMorePrevMessage && !isLoadingMoreMessage) {
            handleMessagesLoad(-1)
          }
        }
      }
    }
  };

  const _getNameDateAndAvatar = (message) => {
    const date = moment(message.created_at).format('MMM D, YYYY');
    let name = '';
    let avatar = null;
    let dateBlock = null;

    if (date !== savedDate) {
      savedDate = date;
      dateBlock = (
        <div className="day-divider">
          <div className="divider"></div>
          <div className="divider-text">{savedDate}</div>
          <div className="divider"></div>
        </div>
      );
    }

    if (message.sender_type === SENDER_TYPES.client) {
      name = message.sender ?
        (message.sender.name || (chat.client.name ? chat.client.name : message.sender_id))
        : (chat.client.name ? chat.client.name : message.sender_id);
    }
    if (message.sender_type === SENDER_TYPES.operator) {
      const user = operatorsById[message.sender_id] ? operatorsById[message.sender_id].user : null
      name = user?.name ? user?.name : operatorsById[message.sender_id]?.name ? operatorsById[message.sender_id]?.name : 'Operator';
      avatar = user ? user.avatar : null;
    }
    if (message.sender_type === SENDER_TYPES.bot) {
      name = message.sender ?
        (message.sender.name || 'Bot') : 'Bot';
    }

    return {name, avatar, dateBlock};
  };
  const {messages, unread_messages_by_chat, campaign_type} = chat;
  let savedDate = null;

  let isUnreadMessage = false;
  if (!lastReadMessage) {
    return null;
  }

  return (
    <div ref={scrollRef} id="chat-window" className="chat-window"
         onScroll={e => handleScroll(e)} >
      {hasMorePrevMessage && isLoadingMoreMessage && (
        <div className="show-more-message">
          <Loader isSmall={true} additionalClass="micro-loader"/>
        </div>
      )}
      {messages && messages.map((message, i) => {
        const currentUserIsSender = operatorsById[message.sender_id] && (operatorsById[message.sender_id].user_id === currentUser.id);
        //const headerHeight = document.getElementById('top-navbar') ? document.getElementById('top-navbar').clientHeight : 0;
        //const chatHeaderHeight = document.getElementById('chat-header') ? document.getElementById('chat-header').clientHeight : 0;
        //const chatNewMessageWrapperHeight = document.getElementById('chat-bottom-new-message-wrapper') ?
        //  document.getElementById('chat-bottom-new-message-wrapper').clientHeight : 0;

        const {name, avatar, dateBlock} = _getNameDateAndAvatar(message);
        const prevMessage = messages.length ? messages[i - 1] : null;

        // if (!isUnreadMessage) {
        //   isUnreadMessage = (prevMessage ? (message.created_at > lastReadMessage && lastReadMessage >= prevMessage.created_at)
        //       : (message.created_at > lastReadMessage)) && !currentUserIsSender;
        // }

        return (
          <Element key={i} id={`scroll-to-${message.id}`} name={`scroll-to-${message.id}`}>
            {dateBlock}
            {(prevMessage ? (message.created_at > lastReadMessage && lastReadMessage >= prevMessage.created_at)
              : (message.created_at > lastReadMessage)) && !currentUserIsSender &&  (
              <div className="day-divider unread-message-divider" id="scroll-to-unread-messages-divider">
              </div>
            )}
            <VisibilitySensor
              active={(message.created_at > lastReadMessage) && (chat.unread_messages_by_user_count > 0)}
              partialVisibility="bottom"
              onChange={(isVisible) => {
                if (isVisible && (message.created_at > lastReadMessage)) {
                  setLastReadMessage(message.created_at);
                }
              }}
              key={i}
              containment={containmentElement}
            >
            <div
              className={`chat-message-item position-relative ${isUnreadMessage ? 'unread-message-to-user' : '' }
              ${message.id === localSearchResult ? 'search-select-message' : ''}`}
              // onMouseEnter={() => {
              //   if ((message.created_at > lastReadMessage) && (chat.unread_messages_by_user_count > 0)) {
              //     setLastReadMessage(message.created_at);
              //   }
              // }}
              ref={message.id === localSearchResult ? searchRef : null}>
              <div className={`chat-avatar-2 ${message.sender_type === SENDER_TYPES.client ? '' : 'closed-chat'}`}>
                {avatar && avatar.url ? (
                  <div className="chat-avatar-icon"><img className="chat-avatar-icon-img" src={avatar.url}/></div>
                ) : (
                  <div className="chat-avatar-icon"></div>
                )}
              </div>
              <div className="chat-message-content" id={`message-${message.id}`}>
                <div className="identity-row">
                  <div className="user-identity">{name}</div>
                  <div className="chat-date time-text">{moment(message.created_at).format('HH:mm')}</div>
                </div>
                {(message.sender_type === SENDER_TYPES.bot) ? (
                  <BotMessage chat={chat} message={message}/>
                ) : (
                  <p className="chat-message-p chat-message-formatted">{message.text}</p>
                )}
                <Files message={message}/>
              </div>
              {(unread_messages_by_chat < message.created_at) && currentUserIsSender && (
                <div className="unread-message-indicator"></div>
              )}
            </div>
            </VisibilitySensor>
          </Element>
        )}
      )}
      {hasMoreNextMessage && isLoadingMoreMessage && (
        <div className="show-more-message">
          <Loader isSmall={true} additionalClass="micro-loader"/>
        </div>
      )}
    </div>
  );
};

const Files = (props) => {
  const {message} = props;
  if (!message.files) {
    return null;
  }
  const {files} = message;
  return (
    <span className="files-list no-bottom">
      {files.img && files.img.map(file => {
        return (
          <img src={file.url} className="file-name message-file-img" onClick={e => {
            e.preventDefault();
            window.open(e.target.src, '_blank');
          }}/>
        )
      })}
      {files.video && files.video.map(file => {
        return (
          <video src={file.url} className="file-name message-file-img video-in-message" controls target="_blank"/>
        )
      })}
      {files.other && files.other.map(file => {
        return (
          <a href={file.url} className="file-name" target="_blank">{file.filename}</a>
        )
      })}
    </span>
  )
};

const ChatNewMessage = (props) => {
  const {chat, startTyping, endTyping, typing} = props;
  const {campaigns, triggerCampaign} = useCampaigns();
  const {userCurrentPlan} = useCurrentUserPlan();
  const sendMessage = useSendMessage();
  const createChat = useCreateChat();
  const [isEmojiShow, showEmojiPicker] = useState(false);
  const [isQuickRepliesShow, showQuickReplies] = useState(false);
  const inputRef = useRef();
  const {setSelectedTab} = useSelectSettingsTab();
  const {currentWidget} = useStateWidgets();
  const [quickReplies, setQuickReplies] = useState(currentWidget.settings.quick_replies || []);

  const [files, setFiles] = useState([]);
  const sendFiles = useSendFiles();
  const [newMessage, setNewMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [isSending, setSending] = useState(false);
  const [botCampaigns, setBotCampaigns] = useState(null);
  const [showBotCampaigns, setShowBotCampaign] = useState(false);

  const pickerRef = useRef(null);
  const showPickerRef = useRef(null);
  const textRef = useRef(null);

  const endTypingTimeout = 10000;
  const endTypingTimeoutId = useRef(null);

  useEffect(() => {
    if (!botCampaigns) {
      getBotCampaigns();
    }
  }, [campaigns]);

  useEffect(() => {
    getBotCampaigns();
  }, [chat.campaign_type]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    }
  }, []);

  useEffect(() => {
    if (textRef && chat.id) {
      addRefFocus(textRef.current);
    }
  }, [textRef, chat.id]);

  const getBotCampaigns = () => {
    if (campaigns) {
      if (CAMPAIGN_TYPES.web.includedTypes.includes(chat.campaign_type)) {
        const filteredCampaigns = Object.values(campaigns).filter(campaign =>
          campaign.type === CAMPAIGN_REQUEST_TYPES.bot_web_chat && campaign.display_settings.triggers.is_start_chatbot_active
        );
        setBotCampaigns(filteredCampaigns);
      } else {
        setBotCampaigns(null);
      }
    }
  };

  const handleClick = (event) => {
    try {
      if (pickerRef) {
        let node = pickerRef.current;
        if (node && node.base && !node.base.contains(event.target)) {
          let button = showPickerRef.current;
          if (!button.contains(event.target) && event.target.id !== 'show-emoji-mart') {
            showEmojiPicker(false);
          }
        }
      }
    } catch(error) {
      console.log(error);
      return null
    }
  };

  const _sendMessage = (chatId, fileInfo = null) => {
    const messagesObj = fileInfo ? {text: newMessage.trim(), files: fileInfo} : {text: newMessage.trim()};
    setSending(true);
    sendMessage(messagesObj, chatId)
      .then(data => {
        if (data && !data.error) {
          setNewMessage('');
        }
        setSending(false);
    });
    endTyping();
    clearTimeout(endTypingTimeoutId.current);
  };

  const _checkingSendFiles = (data) => {

    if (files.length !== 0) {
      sendFiles(files)
        .then(fileInfo => {
          if (fileInfo.files) {
            _sendMessage(data.id, fileInfo.files);
            setFiles([]);
          } else {
            setSending(false);
          }
        })
    } else {
      if (newMessage.trim() !== '') {
        _sendMessage(data.id);
      }
    }

  };

  const handleSendMessage = () => {
    if (chat.id === 'default') {
      createChat(chat.client.id)
        .then(data => {
          if (data && !data.error && data.id) {
            _checkingSendFiles(data);
          }
        });
    } else {
      _checkingSendFiles(chat)
    }

  };

  const handleSetFiles = (file) => {
    textRef.current.focus();
    if (files.length < 10) {
      setFiles(update(files, {$push: [file]}));
    }
  };

  const handleRemoveFiles = (i) => {
    setFiles(update(files, {$unset: [i]}));
    textRef.current.focus();
  };

  const handleSetErrors = (field, error) => {
    setErrors({
      ...errors,
      [field]: error
    });
  };

  const handleChangeNewMessage = value => {
    if (!newMessage && value === '\/') {
      setShowBotCampaign(true);
    } else {
      if (showBotCampaigns) {
        setShowBotCampaign(false);
      }
    }
    setNewMessage(value);
    startTyping();
    clearTimeout(endTypingTimeoutId.current);
    endTypingTimeoutId.current = setTimeout(() => endTyping(), endTypingTimeout);
  };

  const handleTriggerCampaign = (campaignId) => {
    triggerCampaign({
      campaignId: campaignId,
      clientId: chat.client_id
    });
    setNewMessage('');
    setShowBotCampaign(false);
  };

  const addEmoji = e => {
    const emoji = e.native;
    handleChangeNewMessage(newMessage.splice(textRef.current.selectionStart, emoji, 0));
    textRef.current.focus();
  };

  return (
    <div className="new-message-wrapper" id="chat-bottom-new-message-wrapper">
      <div className="new-message-form-block w-form">
        <form id="email-form-2" name="email-form-2" data-name="Email Form 2" className="new-message-form">
          <div className="text-area-header">
            {(typing && typing.is_typing) && (
              <div className="typing-block">
                <div className="typing-text">{chat.client.name} typing{userCurrentPlan.live_typing ? ':' : ''}</div>
                <div className="typing-text visitor-typing">
                  {userCurrentPlan.live_typing ? `${typing.text}` : ''}...</div>
              </div>
            )}
            <div className="new-message-actions">
              <Picker ref={pickerRef} onSelect={addEmoji} showPreview={false} showSkinTones={false} set="twitter"
                      autoFocus={true}
                      style={{ position: 'absolute', width: '300px', bottom: '35px', right: 0,
                        display: isEmojiShow ? 'block' : 'none'}}
                      include={['search','recent','people', 'objects', 'symbols']}/>
              <div className="new-mess-action w-inline-block" ref={showPickerRef}
                   id="show-emoji-mart" onClick={() => showEmojiPicker(!isEmojiShow)}>
                <div></div>
              </div>
              <FileLoader
                loadFile={handleSetFiles}
                setErrors={handleSetErrors}
                children={({getRootProps, getInputProps}) => {
                  return (
                    <div {...getRootProps()} className="new-mess-action w-inline-block">
                      <input {...getInputProps()} />
                      <div></div>
                    </div>
                  );
                }} />

              <div onClick={() => showQuickReplies(!isQuickRepliesShow)} className="new-mess-action w-inline-block">
                <div></div>
                {isQuickRepliesShow && (
                  <div className={`new-mess-action-3 quick-replies-container ${quickReplies.length ? 'questions' : ''}`}>
                    <div className="popup-container-block2 no-margin-top">
                      {quickReplies.length ? (
                        quickReplies.map((reply, i) =>
                          <div className={`group-w ${i === 0 ? 'no-indent': ''}`}>
                            <div className="action-w grow" key={i}>
                              <div className="details-header right-indent grow word-break-all">{reply}</div>
                              <div className="blue-button-copy" onClick={() => {
                                handleChangeNewMessage(newMessage + reply);
                                textRef.current.focus();
                              }}>
                                <div>Paste</div></div>
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="group-w-header-copy">
                          <div className="ava-title">You didn’t created any Quick replies yet.&nbsp;<br/>
                            <Link to="/settings" onClick={() => setSelectedTab('quickReplies')}
                                  className="link-2">Create first here!</Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {t('chat.newMessage.placeholder', {},
            msg => <textarea ref={textRef} value={newMessage} onChange={e => handleChangeNewMessage(e.target.value)}
                             autoFocus onKeyPress={e => {
                             if (e.charCode === 13) {
                               if (e.type === "keypress"  && !e.shiftKey) {
                                 if (!isSending) {
                                   handleSendMessage();
                                 }
                               } else {
                                 handleChangeNewMessage(newMessage.splice(textRef.current.selectionStart, '\n', 0));
                               }
                               e.preventDefault();
                             }
                           }}
                           id="send-textarea" placeholder={msg} maxLength="5000"
                           className="send-text-area w-input custom-textarea"/>)}
          <div className="files-list">
            {errors.files ? (
              <div className="file-name" style="color:red">{errors.files}</div>
            ) : (
              files.map((file, i) => {
                return (
                  <div className="file-row">
                    <div className="file-name">{file.name}</div>
                    <div className="delete-btn" onClick={() => handleRemoveFiles(i)}>
                      <div className="close-line-1 rotate"></div><div class="close-line-1"></div></div>
                  </div>
                );
              })
            )}

          </div>
          {showBotCampaigns && botCampaigns && (
            <div className="bot-campaign-list">
              {botCampaigns.length && botCampaigns.map(campaign => {
                return (
                  <div className="bot-campaign-one"
                       onClick={() => handleTriggerCampaign(campaign.id)}>{campaign.name}</div>
                );
              })}
            </div>
          )}

        </form>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
  );
};

const ModalOperatorReassign = (props) => {
  const {operatorsById, currentUser, isOpen, activeOperatorIds, onUpdate, onClose} = props;
  const [isOperatorsOpen, setOperatorsOpen] = useState(false);
  const [operator, setOperator] = useState(null);

  const handleReassign = () => {
    onUpdate(operator);
    onClose();
  };

  const handleClose = () => {
    setOperator(null);
    onClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 outline-none overflow-unset"
      overlayClassName="popup-w-2 w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <div className="sign-header">Reassign</div>
      <div className="w-form">
        <div id="email-form-4" name="email-form-4" data-name="Email Form 4">
          <div className="filter-drop w-dropdown" onClick={() => setOperatorsOpen(!isOperatorsOpen)}>
            <div className="filter-drop-tog w-dropdown-toggle">
              <div>{operator ? getOperatorName(operatorsById[operator]) : t('Select operator')}</div>
              <div className="drop-icon w-icon-dropdown-toggle"></div>
            </div>
            <nav className={`dropdown-list right-float-list w-dropdown-list ${isOperatorsOpen ? 'w--open' : ''}`}>
              {Object.values(operatorsById).map((operator, i) => {
                if (activeOperatorIds.indexOf(operator.id) !== -1) {
                  return null;
                }
                const name = getOperatorName(operator, i);
                return ( operator.user_id !== currentUser.id &&
                  <div key={i} onClick={() => setOperator(operator.id)}
                       className="dropdown-link w-dropdown-link reassign operator-name">{name}</div>
                )}
              )}
            </nav>
          </div>
          <div className="settings-btn-w">
            <SubmitButton simpleButton={true} onClick={handleReassign}
                          isSending={false} text={'OK'}/>
            <SubmitButton simpleButton={true} additionalClass={'left-indent gray-button'} onClick={handleClose}
                          isSending={false} text={'Cancel'}/>
          </div>
        </div>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
      <div className="abs-position" onClick={onClose}>
        <div className="delete-btn">
          <div className="close-line-1 rotate"></div>
          <div className="close-line-1"></div>
        </div>
      </div>
    </ReactModal>
  );
};

const ModalTypeEmail = (props) => {
  const {isOpen, onSave, onClose} = props;
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const handleSend = () => {
    if (isEmailValid(email)) {
      setError(null);
      onSave(email);
    } else {
      setError('Email not valid');
    }
  };

  const handleClose = () => {
    setEmail('');
    onClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 outline-none overflow-unset"
      overlayClassName="popup-w-2 w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <div className="sign-header">Enter email</div>
      <div className="w-form">
        <div id="email-form-4" name="email-form-4" data-name="Email Form 4">
          <div className="visitor-details-item">
            <div className="error-info-client">
              {error && (
                <div className="error-message">{error}</div>
              )}
            </div>
            {t('visitor.detail.email.placeholder', {},
              msg => <input value={email}
                            onChange={e => {
                              if (error) {
                                setError(null);
                              }
                              setEmail(e.target.value);
                            }}
                            type="email" className="node-settings-input w-input"
                            maxLength="256" placeholder={msg}/>)}
          </div>
          <div className="settings-btn-w">
            <SubmitButton simpleButton={true} onClick={handleSend}
                          isSending={false} text={'Send'}/>
            <SubmitButton simpleButton={true} additionalClass={'left-indent gray-button'} onClick={handleClose}
                          isSending={false} text={'Cancel'}/>
          </div>
        </div>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
      <div className="abs-position" onClick={onClose}>
        <div className="delete-btn">
          <div className="close-line-1 rotate"></div>
          <div className="close-line-1"></div>
        </div>
      </div>
    </ReactModal>
  );
};

const BotMessage = (props) => {
  const {message, chat} = props;
  let messageText = message.text;

  return (
    <>
      {(messageText.type === BOT_NODE_TYPES.sendMessage || messageText.type === BOT_NODE_TYPES.transferToOperator) && (
        <p key={message.id} className="chat-message-p chat-message-formatted flex-column">
          <SendMessage key={message.id} message={messageText} sections={messageText.content.sections || messageText.content.transfer_sections}/></p>
      )}
      {messageText.type === BOT_NODE_TYPES.choiceButton && (<ChoiceButtonMessage message={messageText}/>)}
      {messageText.type === BOT_NODE_TYPES.choiceCard && (<ChoiceCardMessage message={messageText}/>)}
      {(messageText.type === BOT_NODE_TYPES.askQuestion) || (messageText.type === BOT_NODE_TYPES.notifyManager)
        || (messageText.type === BOT_NODE_TYPES.closeChat) ? (
        <>
          {(messageText.type === BOT_NODE_TYPES.askQuestion) && (
            <p className="chat-message-p chat-message-formatted">{messageText.content.question}</p>
          )}
          {(messageText.type === BOT_NODE_TYPES.closeChat || messageText.type === BOT_NODE_TYPES.notifyManager) && (
            <p className="chat-message-p chat-message-formatted">{messageText.content.message}</p>
          )}
        </>
      ) : (
        <></>
      )}
      {messageText && !messageText.type && (
        <p className="chat-message-p chat-message-formatted flex-column">{messageText}</p>
      )}
    </>
  );
};