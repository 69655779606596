import React, { Fragment, forwardRef, useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import CustomSelect from 'components/widgets/CustomSelect';
import {t} from 'utils';


export default function DateRange(props){
  const {segment, fixRangeType, additionalClass = '', onChangeRange} = props;
  const dateRanges = {
    all: {label: 'filters.dateRange.all', value: 'all'},
    today: {label: 'filters.dateRange.today', value: 'today'},
    last_week: {label: 'filters.dateRange.lastWeek', value: 'last_week'},
    current_month: {label: 'filters.dateRange.currentMonth', value: 'current_month'},
    current_year: {label: 'filters.dateRange.currentYear', value: 'current_year'},
    range: {label: 'filters.dateRange.range', value: 'range'}
  };

  const [selectedDateRange, setDateRange] = useState(fixRangeType ? dateRanges['range'] : Object.values(dateRanges)[0]);
  const [customStartDate, setCustomStartDate] = useState(fixRangeType ? moment().subtract(7,'d').toDate() : moment().startOf('day').toDate());
  const [customEndDate, setCustomEndDate] = useState(moment().toDate());


  useEffect(() => {
    if (segment && !(segment.default)) {
      setDateRange(dateRanges[segment.date_filter]);
      if (segment.date_range_from && segment.date_range_to) {
        setCustomStartDate(moment(segment.date_range_from).toDate());
        setCustomEndDate(moment(segment.date_range_to).toDate());
      } else {
        setCustomStartDate(moment().startOf('day').toDate());
        setCustomEndDate(moment().toDate());
      }
    } else {
      setDateRange(fixRangeType ? dateRanges['range'] : Object.values(dateRanges)[0]);
    }
  }, [segment]);

  useEffect(() => {
    const range = getRequestFilterParams();
    onChangeRange(range);
  }, [selectedDateRange, customStartDate, customEndDate]);

  const getRequestFilterParams = () => {
    // range filter
    let range = {type: selectedDateRange.value};
    if (selectedDateRange.value === 'range') {
      range = {
        ...range,
        created_at__gte: moment(customStartDate).toISOString(),
        year__gte: moment(customStartDate).year(),
        month__gte: moment(customStartDate).month(),
        created_at__lte: moment(customEndDate).toISOString(),
        year__lte: moment(customEndDate).year(),
        month__lte: moment(customEndDate).month(),
      };
    } else if (selectedDateRange.value !== 'all') {
      range = {
        ...range,
        created_at__gte: fromToIsoDateRangesFuncs[selectedDateRange.value]().from,
        created_at__lte: fromToIsoDateRangesFuncs[selectedDateRange.value]().to
      };
    }

    return range;
  };

  const fromToIsoDateRangesFuncs = {
    today: () => {return {from: moment().startOf('day').toISOString(), to: moment().toISOString()}},
    last_week: () => {return {from: moment().subtract(7,'d').toISOString(), to: moment().toISOString()}},
    current_month: () => {return {from: moment().startOf('month').toISOString(), to: moment().toISOString()}},
    current_year: () => {return {from: moment().startOf('year').toISOString(), to: moment().toISOString()}},
  };

  return (
    <>
      {!fixRangeType && (
        <CustomSelect options={Object.values(dateRanges)}
                      defaultValue={selectedDateRange}
                      value={selectedDateRange}
                      selectClassName={`settings-select no-indent max-w w-select`}
                      getOptionLabel={option => t(option.label)}
                      getOptionValue={option => option.value}
                      onChange={range => setDateRange(range)}/>
      )}

      {selectedDateRange.value === 'range' && (
        <div className={`daterange-div ${additionalClass}`}>
          <Calendar
            onChangeDate={(date) => {setCustomStartDate(date);}}
            maxDate={customEndDate}
            selectedDate={customStartDate}
          />
          <div className="arrow-block">
            <div className="arrow-line">
              <div className="top-arr-line"></div>
              <div className="top-arr-line bottom-line"></div>
            </div>
          </div>
          <Calendar
            onChangeDate={(date) => {setCustomEndDate(date);}}
            minDate={customStartDate}
            selectedDate={customEndDate}
          />
        </div>
      )}
    </>
  );
}

const Calendar = (props) => {
  const [currentDate, setCurrentDate] = useState(moment().toDate());

  const getYear = (date) => {
    return +moment(date).format('YYYY');
  };
  const getMonthNumber = (date) => {
    return +moment(date).format('M');
  };
  const range = (start, end, step) => {
    if(start === end) return [start];
    return [start, ...range(start + step, end, step)];
  };
  const years = range(1990, getYear(), 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const onChangeDate = (date) => {
    setCurrentDate(date);
    if (props.onChangeDate) {
      props.onChangeDate(date);
    }
  };

  const ExampleCustomInput = forwardRef((props, ref) => (
    <div ref={ref} className="filter-drop-tog no-arrow w-dropdown-toggle" onClick={props.onClick}>
      <div>{props.value}</div>
    </div>
  ));
  return (
    <div className="filter-drop no-indent w-dropdown">
      <DatePicker
        dateFormat="MMM dd, yyyy"
        renderCustomHeader={({
                               date,
                               changeYear,
                               changeMonth,
                               decreaseMonth,
                               increaseMonth,
                               prevMonthButtonDisabled,
                               nextMonthButtonDisabled
                             }) => (
          <div className="custom-header">
            <div className="arrow arrow-dec" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}></div>
            <div className="date-part">
              <div>{months[getMonthNumber(date) - 1]}</div>
              <div className="buttons-calendar-arrows">
                <div className="date-change-button button-up" onClick={() => {
                  changeMonth(getMonthNumber(date));
                }}></div>
                <div className="date-change-button button-down" onClick={() => {
                  changeMonth(getMonthNumber(date) - 2);
                }}></div>
              </div>
            </div>

            <div className="date-part">
              <div>{getYear(date)}</div>
              <div className="buttons-calendar-arrows">
                <div className="date-change-button button-up" onClick={() => {
                  changeYear(getYear(date) + 1);
                }}></div>
                <div className="date-change-button button-down" onClick={() => {
                  changeYear(getYear(date) - 1);
                }}></div>
              </div>
            </div>

            <div className="arrow arrow-inc" onClick={increaseMonth} disabled={nextMonthButtonDisabled}></div>
          </div>
        )}
        minDate={props.minDate}
        maxDate={props.maxDate}
        selected={props.selectedDate || currentDate}
        onChange={onChangeDate}
        customInput={<ExampleCustomInput/>}
      />
    </div>
  );
};
