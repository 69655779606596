import update from 'immutability-helper';
import l_cloneDeep from 'lodash.clonedeep';
import * as actions from 'actions';
import {CAMPAIGN_TYPES, CAMPAIGN_REQUEST_TYPES, HISTORY_EVENT_TYPES} from 'constants.js'


const initialState = {
  campaigns: {},
  currentCampaign: null,
  campaignsError: null,
  _isCampaignsFetching: false,
  _isCreating: false,
  _isUpdating: false,
  _isRemoving: false,

  history: [{prev: null, next: null}],
  pointer: 0,

  botSchema: {},
  selectedBlockType: null,
  selectedNode: null,
  coordinates: [],

  savingBotSchema: {
    "ac44ca1e-5a27-4999-a34b-d40737d1ac05": {
      id: "ac44ca1e-5a27-4999-a34b-d40737d1ac05",
      type: "send_message",
      position: {
        x: 20,
        y: 30,
      },
      next_block_id: "dd000ddd-5a27-4999-a34b-d40737d1ac05",
      content: {
        sections: [
          {text: "11111111111111111111111111111111111111111", type: "text"},
          {text: "https://stg.chatbotter.nppsatek.com/docs/api/#/campaigns/createCampaign", type: "link"}
        ]
      }
    },
    "ac44ca1e-5a27-4999-a555-d40737d1ac05": {
      id: "ac44ca1e-5a27-4999-a555-d40737d1ac05",
      type: "send_email",
      position: {
        x: 220,
        y: 50,
      },
      next_block_id: "dd000ddd-5a27-4999-a34b-d40737d1ac05",
      content: {
        subject: 'CHROMEOGRAPHY',
        message: 'In praise of the chrome logos and lettering affixed to vintage automobiles and electric appliances.',
      }
    },
    "ac44ca1e-5a27-4999-a555-d40737d1a777": {
      id: "ac44ca1e-5a27-4999-a555-d40737d1a777",
      type: "go_to_livechat",
      position: {
        x: 220,
        y: 50,
      },
      next_block_id: null,
      content: {
        assignation_type: 'simple', //str (“simple”, “default”)
        selected_operators: ['uc44ca1e-5a27-4999-a555-d40737d1a777', 'oc44ca1e-5a27-4999-a555-d40737d1a777'],
        email_notification: false,
        operator_mails: ['test@gmail.com', 'test111@gmail.com']
      }
    },
    "ac44ca1e-5a27-6699-a555-d40737d1a777": {
      id: "ac44ca1e-5a27-6699-a555-d40737d1a777",
      type: "notify_manager",
      position: {
        x: 220,
        y: 50,
      },
      next_block_id: null,
      content: {
        message: 'Type in a message you want to receive when the scenario is completed, e.g. "The visitor has abandoned a cart."',
      }
    },
    "dd000ddd-5a27-4999-a34b-d40737d1ac05": {
      id: "dd000ddd-5a27-4999-a34b-d40737d1ac05",
      type: "ask_question",
      position: {
        x: 122,
        y: 280,
      },
      next_block_id: null,
      content: {
        question: 'What would you like to ask?',
        validator: 'none', //(“none”, “email”, “name”, “number”, “phone_number”, “website”)
        error_message: 'str',
        number_of_repeats: 3,
        save_answer_as_property: false,
        contact_property: ''
      }
    },
    "dd000ddd-5a27-4999-a34b-d40737d1a555": {
      id: "dd000ddd-5a27-4999-a34b-d40737d1a555",
      type: "choice_button",
      position: {
        x: 72,
        y: 80,
      },
      next_block_id: null,
      content: {
        text: 'The welcome message is the first message your users will see when they visit your chatbot.',
        buttons: [{
          text: 'button 1',
          next_block_id: null
        }],
        transfer_to_operator: false,
        transfer_sections: [{
          type: 'text',
          text: ''
        }]
      }
    },
    "dd000ddd-5a27-4000-a34b-d40737d1a555": {
      id: "dd000ddd-5a27-4000-a34b-d40737d1a555",
      type: "choice_card",
      position: {
        x: 172,
        y: 180,
      },
      next_block_id: null,
      content: {
        cards: [{
          title: 'The welcome message is the first message your users will see when they visit your chatbot.',
          description: '',
          url: '',
          buttons: [{
            text: 'button 1',
            next_block_id: null
          }],
          transfer_to_operator: false,
          attachment: {
            type: 'file',
            text: ''
          }
        }],
        transfer_to_operator: false,
        transfer_sections: [{
          type: 'text',
          text: ''
        }]
      }
    },
  },

  //campaign type filter
  selectedCampaignType: Object.values(CAMPAIGN_TYPES)[0]
};

export default function campaigns(state=initialState, action) {
  switch(action.type) {
    case actions.FETCH_CAMPAIGNS__R: {
      return update(state, {
        _isCampaignsFetching: {$set: true}
      });
    }
    case actions.FETCH_CAMPAIGNS__S: {
      return update(state, {
        campaigns: {$set: action.payload.reduce((obj, cur) => {
          obj[cur.id] = cur;
          return obj;
        }, {})},
        _isCampaignsFetching: {$set: false}
      });
    }
    case actions.FETCH_CAMPAIGNS__F: {
      return update(state, {
        campaignsError: {$set: action.payload},
        _isCampaignsFetching: {$set: false}
      });
    }

    case actions.FETCH_CAMPAIGN__R: {
      return update(state, {
        _isCampaignsFetching: {$set: true}
      });
    }
    case actions.FETCH_CAMPAIGN__S: {
      return update(state, {
        campaigns: {[action.payload.id]: {$set: action.payload}},
        currentCampaign: {$set: action.payload},
        botSchema: {$set: action.payload.bot_builder},
        _isCampaignsFetching: {$set: false}
      });
    }
    case actions.FETCH_CAMPAIGN__F: {
      return update(state, {
        campaignsError: {$set: action.payload},
        _isCampaignsFetching: {$set: false}
      });
    }

    case actions.CREATE_CAMPAIGN__R: {
      return update(state, {
        _isCreating: {$set: true}
      });
    }
    case actions.CREATE_CAMPAIGN__S: {
      let campaign = action.payload;
      return update(state, {
        campaigns: {[action.payload.id]: {$set: campaign}},
        currentCampaign: {$set: campaign},
        _isCreating: {$set: false}
      });
    }
    case actions.CREATE_CAMPAIGN__F: {
      return update(state, {
        campaignsError: {$set: action.payload},
        _isCreating: {$set: false}
      });
    }

    case actions.UPDATE_CAMPAIGN__R: {
      return update(state, {
        _isUpdating: {$set: true}
      });
    }
    case actions.UPDATE_CAMPAIGN__S: {
      let campaign = action.payload;
      return update(state, {
        campaigns: {[action.payload.id]: {$set: campaign}},
        _isUpdating: {$set: false}
      });
    }
    case actions.UPDATE_CAMPAIGN__F: {
      return update(state, {
        campaignsError: {$set: action.payload},
        _isUpdating: {$set: false}
      });
    }

    case actions.REMOVE_CAMPAIGN__R: {
      return update(state, {
        _isRemoving: {$set: true}
      });
    }
    case actions.REMOVE_CAMPAIGN__S: {
      let campaignId = action.payload;
      return update(state, {
        campaigns: {$unset: [campaignId]},
        _isRemoving: {$set: false}
      });
    }
    case actions.REMOVE_CAMPAIGN__F: {
      return update(state, {
        campaignsError: {$set: action.payload},
        _isRemoving: {$set: false}
      });
    }

    case actions.ENABLE_CAMPAIGN__R: {
      return update(state, {
        _isUpdating: {$set: true}
      });
    }
    case actions.ENABLE_CAMPAIGN__S: {
      let campaignId = action.payload;
      return update(state, {
        campaigns: {[campaignId]: {$merge: {'enabled': true}}},
        _isUpdating: {$set: false}
      });
    }
    case actions.ENABLE_CAMPAIGN__F: {
      return update(state, {
        campaignsError: {$set: action.payload},
        _isUpdating: {$set: false}
      });
    }

    case actions.DISABLE_CAMPAIGN__R: {
      return update(state, {
        _isUpdating: {$set: true}
      });
    }
    case actions.DISABLE_CAMPAIGN__S: {
      let campaignId = action.payload;
      return update(state, {
        campaigns: {[campaignId]: {$merge: {'enabled': false}}},
        _isUpdating: {$set: false}
      });
    }
    case actions.DISABLE_CAMPAIGN__F: {
      return update(state, {
        campaignsError: {$set: action.payload},
        _isUpdating: {$set: false}
      });
    }

    case actions.ARCHIVE_CAMPAIGN__R: {
      return update(state, {
        _isUpdating: {$set: true}
      });
    }
    case actions.ARCHIVE_CAMPAIGN__S: {
      let campaignId = action.payload;
      return update(state, {
        campaigns: {[campaignId]: {$merge: {'archived': true}}},
        _isUpdating: {$set: false}
      });
    }
    case actions.ARCHIVE_CAMPAIGN__F: {
      return update(state, {
        campaignsError: {$set: action.payload},
        _isUpdating: {$set: false}
      });
    }

    case actions.UNARCHIVE_CAMPAIGN__R: {
      return update(state, {
        _isUpdating: {$set: true}
      });
    }
    case actions.UNARCHIVE_CAMPAIGN__S: {
      let campaignId = action.payload;
      return update(state, {
        campaigns: {[campaignId]: {$merge: {'archived': false}}},
        _isUpdating: {$set: false}
      });
    }
    case actions.UNARCHIVE_CAMPAIGN__F: {
      return update(state, {
        campaignsError: {$set: action.payload},
        _isUpdating: {$set: false}
      });
    }

    case actions.CLONE_CAMPAIGN__R: {
      return update(state, {
        _isCreating: {$set: true}
      });
    }
    case actions.CLONE_CAMPAIGN__S: {
      let campaign = action.payload;
      return update(state, {
        campaigns: {[action.payload.id]: {$set: campaign}},
        _isCreating: {$set: false}
      });
    }
    case actions.CLONE_CAMPAIGN__F: {
      return update(state, {
        campaignsError: {$set: action.payload},
        _isCreating: {$set: false}
      });
    }

    case actions.SELECT_CAMPAIGN: {
      if (action.payload.type === CAMPAIGN_REQUEST_TYPES.bot_web_chat ||
        action.payload.type === CAMPAIGN_REQUEST_TYPES.landbot ||
        action.payload.type === CAMPAIGN_REQUEST_TYPES.facebook_messenger) {
        return update(state, {
          currentCampaign: {$set: action.payload},
          botSchema: {$set: action.payload.bot_builder}
        });
      } else {
        return update(state, {
          currentCampaign: {$set: action.payload},
        });
      }
    }

    // campaign type filter
    case actions.SET_CAMPAIGNS_CAMPAIGN_TYPE_FILTER: {
      return update(state, {
        selectedCampaignType: {$set: action.payload},
      });
    }

    case actions.CHANGE_BOT_SCHEMA: {
      if (action.payload) {
        return update(state, {
          botSchema: {$set: action.payload}
        });
      } else {
        return update(state, {
          botSchema: {$set: state.currentCampaign.bot_builder}
        });
      }

    }

    case actions.ADD_HISTORY_EVENT: {
      const {prevEvent, nextEvent} = action.payload;

      let history = l_cloneDeep(state.history);
      history[state.pointer].next = nextEvent;
      if (state.pointer < history.length -1) {
        history = history.slice(0, state.pointer);
      }
      history.push({prev: prevEvent, next: null});
      return update(state, {
        history: {$set: history},
        pointer: {$set: history.length - 1}
      })
    }

    case actions.UNDO_HISTORY_EVENT: {
      if (state.pointer > 0) {
        return update(state, {
          pointer: {$set: state.pointer - 1}
        });
      }
      return state;
    }

    case actions.REDO_HISTORY_EVENT: {
      if (state.pointer < state.history.length - 1) {
        return update(state, {
          pointer: {$set: state.pointer + 1}
        });
      }
      return state;
    }

    case actions.SELECT_BLOCK_TYPE: {
      return update(state, {
        selectedBlockType: {$set: action.payload}
      })
    }

    case actions.SELECT_BOT_NODE: {
      return update(state, {
        selectedNode: {$set: action.payload}
      })
    }

    case actions.UPDATE_BOT_NODE: {
      return update(state, {
        selectedNode: action.payload
      })
    }

    case actions.CLEAR_CURRENT_CAMPAING: {
      return update(state, {
        currentCampaign: {$set: null}
      })
    }

    case actions.CLEAR_BOT_HISTORY: {
      if (action.payload.particial) {
        return update(state, {
          // history: {$set: [{prev: null, next: null}]},
          // pointer: {$set: 0},
          currentCampaign: state.selectedNode ? {
            bot_builder: {[state.selectedNode.id]: {$set: state.selectedNode}}
          } : {$set: state.currentCampaign},
          botSchema: state.selectedNode ? {
            [state.selectedNode.id]: {$set: state.selectedNode}
          } : {$set: state.botSchema},
          selectedBlockType: {$set: null},
          selectedNode: {$set: null},
        })
      }
      return update(state, {
        history: {$set: [{prev: null, next: null}]},
        pointer: {$set: 0},

        botSchema: {$set: {}},
        selectedBlockType: {$set: null},
        selectedNode: {$set: null},
      })
    }

    case actions.CHANGE_COORDINATES: {
      return update(state, {
        coordinates: {$set: action.payload}
      })
    }


    default:
      return state;
  }
}