import React from 'react';
import update from 'immutability-helper';

import Switch from 'components/widgets/Switch';
import CustomSelect from 'components/widgets/CustomSelect';
import {t} from 'utils';
import {CountryDropdown} from 'react-country-region-selector';


export default function ConditionSettings(props) {
  const {webChatType, campaign, setCampaign} = props;
  const messageConstant = 'web.create.displaySettings.conditions';
  return (
    <div className="w-tab-pane w--tab-active">
      <div className="pane-content-w">
        <div className="content-white-block">
          <div>
            <div className="group-title">{t(`${messageConstant}.title`)}</div>
            <div className="page-subheader">{t(`${messageConstant}.${webChatType}.subtitle`)}</div>
          </div>
          <div className="group-content-w">
            <ReturningOrNewVisitors messageConstant={messageConstant} campaign={campaign} setCampaign={setCampaign}/>
            <DisplayOnFollowingDevice messageConstant={messageConstant} campaign={campaign} setCampaign={setCampaign}/>
            <UTMBasedDisplay messageConstant={messageConstant} campaign={campaign} setCampaign={setCampaign}/>
            <BasedOnSpecificCookies messageConstant={messageConstant} campaign={campaign} setCampaign={setCampaign}/>
            <FromReferrals messageConstant={messageConstant} campaign={campaign} setCampaign={setCampaign}/>
            <Geolocation messageConstant={messageConstant} campaign={campaign} setCampaign={setCampaign}/>
          </div>
        </div>
      </div>
    </div>
  );
}

const ReturningOrNewVisitors = (props) => {
  const {messageConstant, campaign, setCampaign} = props;

  const conditionsSettings = campaign.display_settings.conditions;
  const visitorFilters = {
    all: {value: 'all', label: `${messageConstant}.visitors.all`},
    new: {value: 'new', label: `${messageConstant}.visitors.new`},
    old: {value: 'old', label: `${messageConstant}.visitors.old`}
  };
  return (
    <div className="group-w">
      <div className="group-w-header">
        <div className="group-title">{t(`${messageConstant}.visitors.label`)}</div>
        <Switch isOn={conditionsSettings.is_returning_new_visitor_active}
                onClick={() => {
                  setCampaign(update(campaign, {
                    display_settings: {
                      conditions: {
                        $toggle: ['is_returning_new_visitor_active']
                      }
                    }
                  }));
                }}/>
      </div>
      {conditionsSettings.is_returning_new_visitor_active && (
        <div className="show-group-content">
          <div className="show-group-div">
            <div className="group-row">
              <div className="col-6 select-block">
                <CustomSelect options={Object.values(visitorFilters)}
                              defaultValue={conditionsSettings.returning_new_visitor_filter ?
                                visitorFilters[conditionsSettings.returning_new_visitor_filter] :
                                visitorFilters.all}
                              selectClassName={`settings-select w-select`}
                              getOptionLabel={option => t(option.label)}
                              onChange={filter => {
                                setCampaign(update(campaign, {
                                  display_settings: {
                                    conditions: {
                                      returning_new_visitor_filter: {$set: filter.value}
                                    }
                                  }
                                }));
                              }}
                              value={visitorFilters[conditionsSettings.popup_time_between_appearances_unit]}/>
              </div>
              <div className="col-6 right-padding"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const DisplayOnFollowingDevice = (props) => {
  const {messageConstant, campaign, setCampaign} = props;

  const conditionsSettings = campaign.display_settings.conditions;
  const deviceFilters = {
    all: {value: 'all', label: `${messageConstant}.devices.all`},
    desktop: {value: 'desktop', label: `${messageConstant}.devices.desktop`},
    mobile: {value: 'mobile', label: `${messageConstant}.devices.mobile`}
  };
  return (
    <div className="group-w">
      <div className="group-w-header">
        <div className="group-title">{t(`${messageConstant}.devices.label`)}</div>
        <Switch isOn={conditionsSettings.is_display_on_devices_active}
                onClick={() => {
                  setCampaign(update(campaign, {
                    display_settings: {
                      conditions: {
                        $toggle: ['is_display_on_devices_active']
                      }
                    }
                  }));
                }}/>
      </div>
      {conditionsSettings.is_display_on_devices_active && (
        <div className="show-group-content">
          <div className="show-group-div">
            <div className="group-row">
              <div className="col-6 select-block">
                <CustomSelect options={Object.values(deviceFilters)}
                              defaultValue={conditionsSettings.display_on_devices_filter ?
                                deviceFilters[conditionsSettings.display_on_devices_filter] :
                                deviceFilters.all}
                              selectClassName={`settings-select w-select`}
                              getOptionLabel={option => t(option.label)}
                              onChange={filter => {
                                setCampaign(update(campaign, {
                                  display_settings: {
                                    conditions: {
                                      display_on_devices_filter: {$set: filter.value}
                                    }
                                  }
                                }));
                              }}
                              value={deviceFilters[conditionsSettings.display_on_devices_filter]}/>
              </div>
              <div className="col-6 right-padding"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const UTMBasedDisplay = (props) => {
  const {messageConstant, campaign, setCampaign} = props;

  const conditionsSettings = campaign.display_settings.conditions;
  const matchs = {
    contains: {value: 'contains', label: `${messageConstant}.utm.contains`},
    match: {value: 'match', label: `${messageConstant}.utm.match`}
  };
  return (
    <div className="group-w">
      <div className="group-w-header">
        <div className="group-title">{t(`${messageConstant}.utm.label`)}</div>
        <Switch isOn={conditionsSettings.is_utm_based_display_active}
                onClick={() => {
                  setCampaign(update(campaign, {
                    display_settings: {
                      conditions: {
                        $toggle: ['is_utm_based_display_active']
                      }
                    }
                  }));
                }}/>
      </div>
      {conditionsSettings.is_utm_based_display_active && (
        <div className="show-group-content">
          <div className="show-group-div">
            <div className="group-row">
              <div className="col-4">
                <div className="settings-item-label add-margin">&amp;utm_source=</div>
                <CustomSelect options={Object.values(matchs)}
                              defaultValue={conditionsSettings.utm_based_display_source_match ?
                                matchs[conditionsSettings.utm_based_display_source_match] :
                                matchs.contains}
                              selectClassName={`settings-select w-select`}
                              getOptionLabel={option => t(option.label)}
                              onChange={match => {
                                setCampaign(update(campaign, {
                                  display_settings: {
                                    conditions: {
                                      utm_based_display_source_match: {$set: match.value}
                                    }
                                  }
                                }));
                              }}
                              value={matchs[conditionsSettings.utm_based_display_source_match]}/>
                {t(`${messageConstant}.utm.source.placeholder`, {},
                  msg => <input type="text" maxLength="256" placeholder={msg}
                                className="node-settings-input w-input"
                                value={conditionsSettings.utm_based_display_source_value}
                                onChange={(e) => {
                                  setCampaign(update(campaign, {
                                    display_settings: {
                                      conditions: {
                                        utm_based_display_source_value: {$set: e.target.value}
                                      }
                                    }
                                  }));
                                }
                                }/>)}
              </div>
              <div className="col-4">
                <div className="settings-item-label add-margin">&amp;utm_medium=</div>
                <CustomSelect options={Object.values(matchs)}
                              defaultValue={conditionsSettings.utm_based_display_medium_match ?
                                matchs[conditionsSettings.utm_based_display_medium_match] :
                                matchs.contains}
                              selectClassName={`settings-select w-select`}
                              getOptionLabel={option => t(option.label)}
                              onChange={match => {
                                setCampaign(update(campaign, {
                                  display_settings: {
                                    conditions: {
                                      utm_based_display_medium_match: {$set: match.value}
                                    }
                                  }
                                }));
                              }}
                              value={matchs[conditionsSettings.utm_based_display_medium_match]}/>
                {t(`${messageConstant}.utm.medium.placeholder`, {},
                  msg => <input type="text" maxLength="256" placeholder={msg}
                                className="node-settings-input w-input"
                                value={conditionsSettings.utm_based_display_medium_value}
                                onChange={(e) => {
                                  setCampaign(update(campaign, {
                                    display_settings: {
                                      conditions: {
                                        utm_based_display_medium_value: {$set: e.target.value}
                                      }
                                    }
                                  }));
                                }
                                }/>)}
              </div>
              <div className="col-4">
                <div className="settings-item-label add-margin">&amp;utm_campaign=</div>
                <CustomSelect options={Object.values(matchs)}
                              defaultValue={conditionsSettings.utm_based_display_campaign_match ?
                                matchs[conditionsSettings.utm_based_display_campaign_match] :
                                matchs.contains}
                              selectClassName={`settings-select w-select`}
                              getOptionLabel={option => t(option.label)}
                              onChange={match => {
                                setCampaign(update(campaign, {
                                  display_settings: {
                                    conditions: {
                                      utm_based_display_campaign_match: {$set: match.value}
                                    }
                                  }
                                }));
                              }}
                              value={matchs[conditionsSettings.utm_based_display_campaign_match]}/>
                {t(`${messageConstant}.utm.campaign.placeholder`, {},
                  msg => <input type="text" maxLength="256" placeholder={msg}
                                className="node-settings-input w-input"
                                value={conditionsSettings.utm_based_display_campaign_value}
                                onChange={(e) => {
                                  setCampaign(update(campaign, {
                                    display_settings: {
                                      conditions: {
                                        utm_based_display_campaign_value: {$set: e.target.value}
                                      }
                                    }
                                  }));
                                }
                                }/>)}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const BasedOnSpecificCookies = (props) => {
  const {messageConstant, campaign, setCampaign} = props;

  const conditionsSettings = campaign.display_settings.conditions;
  const cookieOptions = {
    is: {value: 'is', label: `${messageConstant}.cookies.is`},
    isnot: {value: 'isnot', label: `${messageConstant}.cookies.isnot`},
    includes: {value: 'includes', label: `${messageConstant}.cookies.includes`},
    notinclude: {value: 'notinclude', label: `${messageConstant}.cookies.notinclude`}
  };

  const handleDeleteCookie = ind => {
    setCampaign(update(campaign, {
      display_settings: {
        conditions: {
          based_on_cookie_cookies: {$splice: [[ind, 1]]}
        }
      }
    }));
  };
  const handleAddCookie = () => {
    setCampaign(update(campaign, {
      display_settings: {
        conditions: {
          based_on_cookie_cookies: {$push: [{option: 'is', name: ''}]}
        }
      }
    }));
  };
  const handleChangeCookieOption = (value, i) => {
    setCampaign(update(campaign, {
      display_settings: {
        conditions: {
          based_on_cookie_cookies: {[i]: {option: {$set: value}}}
        }
      }
    }));
  };
  const handleChangeCookieName = (value, i) => {
    setCampaign(update(campaign, {
      display_settings: {
        conditions: {
          based_on_cookie_cookies: {[i]: {name: {$set: value}}}
        }
      }
    }));
  };
  return (
    <div className="group-w">
      <div className="group-w-header">
        <div className="group-title">{t(`${messageConstant}.cookies.label`)}</div>
        <Switch isOn={conditionsSettings.is_based_on_cookie_active}
                onClick={() => {
                  setCampaign(update(campaign, {
                    display_settings: {
                      conditions: {
                        $toggle: ['is_based_on_cookie_active']
                      }
                    }
                  }));
                }}/>
      </div>
      {conditionsSettings.is_based_on_cookie_active && (
        <div className="show-group-content">
          <div className="show-group-div">
            {conditionsSettings.based_on_cookie_cookies.map((cookie, i) =>
              <div key={i} className="group-row">
                <div className="col-6 select-block">
                  <CustomSelect options={Object.values(cookieOptions)}
                                defaultValue={cookie.option ? cookieOptions[cookie.option] : cookieOptions.is}
                                selectClassName={`settings-select w-select`}
                                getOptionLabel={option => t(option.label)}
                                onChange={c => handleChangeCookieOption(c.value, i)}
                                value={cookie.value}/>
                </div>
                <div className={`col-6 ${i === 0 ? 'right-padding' : 'hor-flex'}`}>
                  <input type="text" value={cookie.name} className="node-settings-input w-input"
                         onChange={(e) => handleChangeCookieName(e.target.value, i)} maxLength="256" />
                  {i !== 0 && (
                    <div className="delete-btn" onClick={() => handleDeleteCookie(i)}>
                      <div className="close-line-1 rotate"></div>
                      <div className="close-line-1"></div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="blue-button min-h w-button" onClick={handleAddCookie}>
            <span className="awesome-icon big-icon"></span>  {t(`${messageConstant}.cookies.add`)}
            </div>
        </div>
      )}
    </div>
  );
};

const FromReferrals = (props) => {
  const {messageConstant, campaign, setCampaign} = props;

  const conditionsSettings = campaign.display_settings.conditions;
  const referrerOptions = {
    is: {value: 'is', label: `${messageConstant}.referrer.is`},
    isnot: {value: 'isnot', label: `${messageConstant}.referrer.isnot`},
    includes: {value: 'includes', label: `${messageConstant}.referrer.includes`},
    notinclude: {value: 'notinclude', label: `${messageConstant}.referrer.notinclude`}
  };

  const handleDeleteReferrer = ind => {
    setCampaign(update(campaign, {
      display_settings: {
        conditions: {
          from_referrals_referrers: {$splice: [[ind, 1]]}
        }
      }
    }));
  };
  const handleAddReferrer = () => {
    setCampaign(update(campaign, {
      display_settings: {
        conditions: {
          from_referrals_referrers: {$push: [{option: 'is', name: ''}]}
        }
      }
    }));
  };
  const handleChangeReferrerOption = (value, i) => {
    setCampaign(update(campaign, {
      display_settings: {
        conditions: {
          from_referrals_referrers: {[i]: {option: {$set: value}}}
        }
      }
    }));
  };
  const handleChangeReferrerName = (value, i) => {
    setCampaign(update(campaign, {
      display_settings: {
        conditions: {
          from_referrals_referrers: {[i]: {name: {$set: value}}}
        }
      }
    }));
  };
  return (
    <div className="group-w">
      <div className="group-w-header">
        <div className="group-title">{t(`${messageConstant}.referrer.label`)}</div>
        <Switch isOn={conditionsSettings.is_from_referrals_active}
                onClick={() => {
                  setCampaign(update(campaign, {
                    display_settings: {
                      conditions: {
                        $toggle: ['is_from_referrals_active']
                      }
                    }
                  }));
                }}/>
      </div>
      {conditionsSettings.is_from_referrals_active && (
        <div className="show-group-content">
          <div className="show-group-div">
            {conditionsSettings.from_referrals_referrers.map((referrer, i) =>
              <div key={i} className="group-row">
                <div className="col-6 select-block">
                  <CustomSelect options={Object.values(referrerOptions)}
                                defaultValue={referrer.option ? referrerOptions[referrer.option] : referrerOptions.is}
                                selectClassName={`settings-select w-select`}
                                getOptionLabel={option => t(option.label)}
                                onChange={r => handleChangeReferrerOption(r.value, i)}
                                value={referrer.value}/>
                </div>
                <div className={`col-6 ${i === 0 ? 'right-padding' : 'hor-flex'}`}>
                  <input type="text" value={referrer.name} className="node-settings-input w-input"
                         onChange={(e) => handleChangeReferrerName(e.target.value, i)} maxLength="256" />
                  {i !== 0 && (
                    <div className="delete-btn" onClick={() => handleDeleteReferrer(i)}>
                      <div className="close-line-1 rotate"></div>
                      <div className="close-line-1"></div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="blue-button min-h w-button" onClick={handleAddReferrer}>
            <span className="awesome-icon big-icon"></span>  {t(`${messageConstant}.referrer.add`)}
          </div>
        </div>
      )}
    </div>
  );
};

const Geolocation = (props) => {
  const {messageConstant, campaign, setCampaign} = props;

  const conditionsSettings = campaign.display_settings.conditions;
  const countryOptions = {
    is: {value: 'is', label: `${messageConstant}.country.is`},
    isnot: {value: 'isnot', label: `${messageConstant}.country.isnot`},
  };

  const handleDeleteCountry= ind => {
    setCampaign(update(campaign, {
      display_settings: {
        conditions: {
          geolocation_countries: {$splice: [[ind, 1]]}
        }
      }
    }));
  };
  const handleAddCountry = () => {
    setCampaign(update(campaign, {
      display_settings: {
        conditions: {
          geolocation_countries: {$push: [{option: 'is', name: ''}]}
        }
      }
    }));
  };
  const handleChangeCountryOption = (value, i) => {
    setCampaign(update(campaign, {
      display_settings: {
        conditions: {
          geolocation_countries: {[i]: {option: {$set: value}}}
        }
      }
    }));
  };
  const handleChangeCountryName = (value, i) => {
    setCampaign(update(campaign, {
      display_settings: {
        conditions: {
          geolocation_countries: {[i]: {name: {$set: value}}}
        }
      }
    }));
  };
  return (
    <div className="group-w">
      <div className="group-w-header">
        <div className="group-title">{t(`${messageConstant}.country.label`)}</div>
        <Switch isOn={conditionsSettings.is_geolocation_active}
                onClick={() => {
                  setCampaign(update(campaign, {
                    display_settings: {
                      conditions: {
                        $toggle: ['is_geolocation_active']
                      }
                    }
                  }));
                }}/>
      </div>
      {conditionsSettings.is_geolocation_active && (
        <div className="show-group-content">
          <div className="show-group-div">
            {conditionsSettings.geolocation_countries.map((country, i) =>
              <div key={i}  className="group-row">
                <div className="col-6 select-block">
                  <CustomSelect options={Object.values(countryOptions)}
                                defaultValue={country.option ? countryOptions[country.option] : countryOptions.is}
                                selectClassName={`settings-select w-select`}
                                getOptionLabel={option => t(option.label)}
                                onChange={r => handleChangeCountryOption(r.value, i)}
                                value={country.value}/>
                </div>
                <div className={`col-6 ${i === 0 ? 'right-padding' : 'hor-flex'}`}>
                  <CountryDropdown
                    value={country.name}
                    showDefaultOption={true}
                    labelType="full"
                    valueType="full"
                    classes="settings-select w-select"
                    onChange={c => handleChangeCountryName(c, i)} />
                  {i !== 0 && (
                    <div className="delete-btn" onClick={() => handleDeleteCountry(i)}>
                      <div className="close-line-1 rotate"></div>
                      <div className="close-line-1"></div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="blue-button min-h w-button" onClick={handleAddCountry}>
            <span className="awesome-icon big-icon"></span>  {t(`${messageConstant}.country.add`)}</div>
        </div>
      )}

    </div>
  )
};