import {fetchSpecificPayments} from 'actions';

export default class RecurrentPaymentFetcher {
  constructor(dispatchFunction) {
    this._fetchPaymentTimoutId = null;
    this._dispatch = dispatchFunction;
  }

  runFetching(paymentIds) {
    // debugger;
    if (this._fetchPaymentTimoutId == null) {
      const callback = () => {
        this._dispatch(fetchSpecificPayments(paymentIds));
        this._fetchPaymentTimoutId = window.setTimeout(callback, 5000);
      };
      this._fetchPaymentTimoutId = window.setTimeout(callback, 5000);
    }
  }

  stopFetching() {
    // debugger;
    if (this._fetchPaymentTimoutId != null) {
      window.clearTimeout(this._fetchPaymentTimoutId);
    }
  }
};
