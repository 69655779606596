import React, {Component, useEffect, useState} from 'react';
import SubmitButton from 'components/widgets/SubmitButton';
import {meetings} from 'translate';
import {useGoogleAuth} from 'hooks';
import {redirectTo} from 'utils';
import {t} from 'utils';


export default function ConnectCalendar() {
  const messages = [meetings];

  const {getGoogleAuthUrl, isGoogleAuthUrlFetching} = useGoogleAuth();

  const _t = (value, values = {}, children = null) => {
    return t(`connect.${value}`, values, children);
  };

  const handleConnectToCalendar = () => {
    getGoogleAuthUrl(`${window.location.origin.toString()}/meetings-settings`).then(data => {
      if (data.url) {
        redirectTo(data.url);
      }
    });
  };

  return (
    <div className='content-white-block grow-w'>
      <div>
        <div className='group-title'>{_t('title')}</div>
        <div className='page-subheader'>{_t('subtitle')}</div>
      </div>
      <div className='group-content-w add-padding'>
        <div className='btns-wrap'>
          <SubmitButton simpleButton={true} additionalClass='blue-button add-indent2 w-button'
                        onClick={handleConnectToCalendar} isSending={isGoogleAuthUrlFetching}
                        text={_t('google.calendar.button')} type='button'/>


          <div className='btn-block'>
            <a href='#' className='blue-button no-active w-button'>{_t('office.calendar.button')}</a>
            <div className='badge badge-btn'>{_t('soon')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}