import React, { Fragment, useState, useEffect } from 'react';
import ReactModal from 'react-modal';

import {useTablePagination, useCurrentUserState, useWidgets, useCurrentUserPlan} from 'hooks';
import {isEmailValid} from 'utils/validators';
import SubmitButton from 'components/widgets/SubmitButton';
import ModalWarning from 'components/widgets/ModalWarning';
import {t} from 'utils';
import Alert from "react-s-alert";


export default function Managers(props) {
  const {currentOperator, operatorsById, addOperator, deleteOperator, updateOperator, isCreating} = props;

  const tableHeaders = ['name', 'email', 'role', 'options'];
  const [addModalVisibility, setAddModalVisibility] = useState(false);
  const {currentUser} = useCurrentUserState();
  const {userCurrentPlan} = useCurrentUserPlan();
  const {currentWidgetId} = useWidgets();
  const [isManagerOptionsOpen, setManagerOptionsOpen] = useState(null);
  const [isWarningShow, setIsWarningShow] = useState(false);
  const [selectedManagerId, setSelectedManagerId] = useState(null);
  const [activeOperators, setActiveOperators] = useState([]);

  useEffect(() => {
    const activeOperators = [];
    for (const operatorId in operatorsById) {
      if (operatorsById[operatorId].is_activate) {
        activeOperators.push(operatorId)
      }
    }
    setActiveOperators(activeOperators);
  }, [operatorsById]);

  const {itemsOnPage, pageCount, tableRowCount, rowCounts,
    setPageNumber, setTableRowCount} = useTablePagination(operatorsById);


  const togglePage = (page) => {
    setPageNumber(page.selected)
  };

  const isCanEditManager = (manager) => {
    return currentOperator.is_owner && manager.user_id !== currentUser.id && !manager.is_owner;
  };

  const getManagerRole = (manager) => {
    let isYou = (manager.user_id === currentUser.id) ? 'You, ' : '';
    let role = manager.is_owner ? 'Project owner' : 'Manager';

    return isYou + role;
  };

  const _t = (value, values = {}, children = null) => {
    return t(`managers.${value}`, values, children);
  };

  const handleDelete = (managerId) => {
    deleteOperator(managerId).then(() => {
      setIsWarningShow(false);
      Alert.success('Successful!');
    });
  };

  const handleToggleActivate = (managerId, isActivate) => {
    if (isActivate && userCurrentPlan.managers_limit) {
      if (activeOperators.length < userCurrentPlan.managers_limit) {
        updateOperator(managerId, {'is_activate': isActivate}, true).then(() => {
          Alert.success('Successful!');
        });
      } else {
        Alert.error('To much active manager!');
      }
    } else {
      updateOperator(managerId, {'is_activate': isActivate}, true).then(() => {
        Alert.success('Successful!');
      });
    }
  };

  return (
    <>
      <div className="pane-content-w">
        <div className="content-white-block">
          <div className="settings-btn-w add-space-2">
            {!userCurrentPlan.managers_limit || activeOperators.length < userCurrentPlan.managers_limit ? (
              <div className="blue-button w-button" onClick={() => setAddModalVisibility(true)}>
                <span className="awesome-icon big-icon"></span><span> </span>{_t('button')}
              </div>
            ) : (
              <div className="blue-button w-button disable-button">
                <span className="awesome-icon big-icon"></span><span> </span>{_t('button')}
              </div>
            )}

          </div>
          <div className="campaigns-preview-w">
            <div className="content-white-block table-container">
              <div className="table-w">
                <div className="table-row table-header">
                  {tableHeaders.map((header, i) =>
                    <div key={i} className="table-col1">
                      <div className="details-header">{header}</div>
                    </div>
                  )}
                </div>
                <div className="table-content">
                  {itemsOnPage.map((manager) =>
                    <div key={manager.id} className={`table-row ${manager.is_activate ? '' : 'inactive'}`}>
                      <div className="table-col1">
                        <div className="visitor-info-w">
                          <div className="chat-avatar-2-2">
                            {(manager && manager.user && manager.user.avatar && manager.user.avatar.url) ? (
                              <img className="chat-avatar-icon-img" src={currentOperator.user.avatar.url}/>
                            ) : (
                              <div>S</div>
                            )}
                            {manager.is_online ? (
                              <div className="operator-online-ind ava-green"></div>
                            ) : (
                              <div className="operator-online-ind ava-orange"></div>
                            )}
                          </div>
                          <span className="settings-item-label">{manager.user.name ? manager.user.name : manager.name}</span>
                        </div>
                      </div>
                      <div className="table-col1">
                        <div className="cp-info">{manager.user.email}</div>
                      </div>
                      <div className="table-col1">
                        <div className="cp-info">{getManagerRole(manager)}</div>
                      </div>
                      <div className="table-col1">
                        {isCanEditManager(manager) ? (
                            <div className="table-col1">
                              <div className="filter-drop left-align w-dropdown"
                                   onMouseOver={() => setManagerOptionsOpen(manager.id)}
                                   onMouseOut={() => setManagerOptionsOpen(null)}>
                                <div className="filter-drop-tog w-dropdown-toggle">
                                  <img src={require('styles/images/settings-blue.svg')} alt="" className="dt-icon"/>
                                  <div className="drop-icon w-icon-dropdown-toggle"/>
                                </div>
                                <nav className={`dropdown-list w-dropdown-list ${isManagerOptionsOpen === manager.id ? 'w--open' : ''}`}>
                                  <div className="dropdown-link w-dropdown-link"
                                       onClick={() => {
                                         handleToggleActivate(manager.id, !manager.is_activate)
                                       }}>
                                    {manager.is_activate ? _t('options.deactivate') : _t('options.activate')}
                                  </div>
                                  <div className="dropdown-link red-link w-dropdown-link"
                                       onClick={() => {
                                         setIsWarningShow(true);
                                         setSelectedManagerId(manager.id)
                                       }}>
                                    {_t('options.delete')}
                                  </div>
                                </nav>
                              </div>
                            </div>
                        ) : (
                            <div className="cp-info">-</div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddManagerModal
        isOpen={addModalVisibility}
        onClose={() => setAddModalVisibility(false)}
        onAddOperator={addOperator}
        isCreating={isCreating}
        widgetId={currentWidgetId}
        _t={_t}
      />

      <ModalWarning
        isOpen={isWarningShow}
        text={'Are you sure you want to delete this operator?'}
        onConfirm={() => handleDelete(selectedManagerId)}
        onClose={() => setIsWarningShow(false)}
      />
    </>
  );
}


const AddManagerModal = (props) => {
  const {isOpen, onClose, onAddOperator, isCreating, widgetId, _t} = props;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [isCreated, setIsCreated] = useState(false);

  const handleOperatorAdd = () => {
    if (!isEmailValid(email)) {
      setError(t('error.email.invalid'));
      return;
    }

    onAddOperator({
      widget_id: widgetId,
      name: name,
      email: email
    }).then(data => {
      if (data.code) {
        setError(data.message || 'Error');
      } else {
        setIsCreated(true);
        setError(null);
        setTimeout(() => {
          handleModalClose();
          Alert.success('Successful!');
        }, 1000);
      }
    })
  };

  const handleModalClose = () => {
    onClose();
    setName('');
    setEmail('');
    setError(null);
    setIsCreated(false);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 outline-none"
      overlayClassName="popup-w-2 w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={onClose}
    >
      <div className="sign-header">{_t('modal.header')}</div>
      <div className="w-form">
        <form id="email-form-4" name="email-form-4" data-name="Email Form 4">
          <div>
            <label htmlFor="Manager-name" className="settings-item-label">{_t('modal.name.label')}</label>
            <input value={name} onChange={(e) => setName(e.target.value)} type="text"
                   className="node-settings-input w-input" maxLength="256" required=""/>
          </div>
          <div>
            <label htmlFor="Manager-email" className="settings-item-label">{_t('modal.email.label')}</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email"
                   className="node-settings-input w-input" maxLength="256" required=""/>
          </div>

          <SubmitButton simpleButton={true} additionalClass={'right-float'}
                        onClick={() => handleOperatorAdd()}
                        isSending={isCreating} text={_t('button')} type={"button"}/>
        </form>
        <div className={`w-form-done ${isCreated ? 'display-block' : ''}`}>
          <div>Thank you! Your submission has been received!</div>
        </div>
        {error && (
            <div className="w-form-fail display-block">
              <div>{error}</div>
            </div>
        )}
      </div>
      <div className="abs-position" onClick={onClose}>
        <div className="delete-btn">
          <div className="close-line-1 rotate"/>
          <div className="close-line-1"/>
        </div>
      </div>
    </ReactModal>
  );
};