import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import Leads from './Leads';
import {main, leads} from 'translate';

export default compose(
  connect((state, ownProps) => {
      return {
        messages: [main, leads]
      };
    }
  ),

  lifecycle({
    componentDidMount() {
      document.title = 'Leads | HeyCom'

      console.log('Leads componentDidMount')
    },
  }),

  withHandlers()
) (
  Leads
);