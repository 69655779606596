import React, {useState} from 'react';
import CampaignSettings from "../CampaignSettings";
import {CAMPAIGN_TYPES} from 'constants.js';
import {BACKGROUND_TYPES} from "../../../constants";

export default function CreateLandBotCampaign(props) {
  const newCampaign = {
    name: '',
    display_settings: {
      share: {
        share_it_on: ''
      },
      seo: {
        meta_title: '',
        site_description: '',
        meta_image: null,
        meta_favicon: null,
        google_analytics: {
          code: '',
          send_type: 'Send no events to GA'
        },
        facebook_pixel_id: '',
        google_ads: {
          conversion_id: '',
          tag_manager_container_id: ''
        }
      },
      other: {
        second_visit: {
          type: 'start_or_continue'
        },
        typing_emulation: {
          enabled: false,
          speed_type: 'custom',
          average_human_reading_speed: 300,
          maximum_delay: 1.5
        }
      }
    },
    design: {
      main_settings: {
        logo: null,
        logo_url: '',
        title: '',
        title_color: '#fff',
        sub_title: '',
        sub_title_color: '#fff',
        enable_branding: true
      },
      design_settings: {
        background: {
          type: BACKGROUND_TYPES.color,
          color: ['#8664e9', '#4517ca'],
          image: null,
          image_url: '',
          video: null
        },
        message_colors: {
          bot_background: '#228ed4',
          bot_text: '#fff',
          user_background: '#fff',
          user_text: '#333333',
        },
        button_and_input_colors: {
          button_background: '#6a3fe7',
          button_text: '#fff',
          input: '#fff',
          input_text: '#333333',
          input_border: '#37a7e5',
        }
      },
      font_settings: {
        size: 18,
        family: 'Circular'
      }
    },
    bot_type: {},
    bot_builder: {}
  };

  return (
    <CampaignSettings isCreate={true} campaignType={CAMPAIGN_TYPES.landbot.value}
                      newCampaign={newCampaign}/>
  );

}