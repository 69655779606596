import validator from 'validator';
import {removeUrlScheme} from './url';

export const isEmpty = str => {
  return str === undefined || str === null || (typeof str === 'string' && !str.trim());
};

export const isUrlValid = url => {
  // validator.js considers urls with "localhost" domain invalid.
  // So replace it with "localhost.com"
  if (/^localhost([:/]|$)/i.test(removeUrlScheme(url))) {
    url = url.replace(/localhost/i, 'localhost.com')
  }

  return validator.isURL(url, {
    protocols: ['http', 'https'],
    require_protocol: true,
    require_host: true,
    allow_underscores: true,
    disallow_auth: true
  });
};

export const isPhoneNumberValid = phone => {
  if (typeof phone !== 'string') {
    return false;
  }

  const characters = ['(', ')', '-', '+', ' '];
  let checkPairBrace = 0;

  for (let i = 0; i < phone.length; i++) {
    const letter = phone[i];
    if (!((characters.indexOf(letter) !== -1) || (Number.isInteger(+letter)))) {
      return false;
    } else {
      if ((checkPairBrace === 0) && (letter === ')')) {
        return false;
      }
      if (letter === '(') checkPairBrace += 1;
      if (letter === ')') checkPairBrace -= 1;
    }
  }

  return checkPairBrace === 0;
};

export const isEmailValid = email => {
  return validator.isEmail(email)
};

export const isPasswordValid = password => {
  return (password.length > 8 && !isEmpty(password))
};

export const isPasswordNotSimple = password => {
  const checkIdenticalLetters = /\b(\w)\1*\b/;
  const checkIdenticalNumbers = /\b(\d)\1*\b/;
  return !(checkIdenticalLetters.test(password) || checkIdenticalNumbers.test(password))
};

export const isPasswordNotOnlyNumbers = password => {
  const checkOnlyNumbers = /\b(\d)+\b/;

  return !checkOnlyNumbers.test(password)
};

export const isCardNumberValid = cardNumber => {
  return validator.isCreditCard(cardNumber);
};

export const isCvcValid = cvc => {
  const matchOnlyNumber = /^\d+$/g;
  return matchOnlyNumber.test(cvc) && (3 <= cvc.length && cvc.length <= 4);
};

export const isHexColorValid = color => {
  return validator.isHexColor(color)
};
