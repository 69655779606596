import React, { Fragment, Component, useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {useCurrentUserPlan} from "hooks";
import {t} from 'utils';


export default function CampaignsHeader(props) {
  const {userCurrentPlan} = useCurrentUserPlan();

  const [isNewCampaignsToggle, toggleNewCampaigns] = useState(false);
  const [newCampaignTypes, setCampaignTypes] = useState([
    { link: '/campaigns/create/web-chat', label: 'header.newCampaigns.webChat', enabled: true},
    { link: '/campaigns/create/landbot', label: 'header.newCampaigns.landbot', enabled: true},
    { link: '/campaigns/create/facebook-messenger', label: 'header.newCampaigns.messengerChat', enabled: true}
  ]);


  return (
    <div className="filers-w">
      <div className="filter-drop-w">
        <div className="filter-drop w-dropdown" onMouseOver={() => toggleNewCampaigns(true)} onMouseOut={() => toggleNewCampaigns(false)}>
          <div className="filter-drop-tog w-dropdown-toggle">
            <div><span className="awesome-icon">+</span> {t('header.newCampaigns.title')} </div>
            <div className="drop-icon w-icon-dropdown-toggle"/>
          </div>
          <nav className={`dropdown-list w-dropdown-list ${isNewCampaignsToggle ? 'w--open': ''}`}>
            {newCampaignTypes.map((type, i) =>
              type.enabled ? (
                <Link to={type.link} key={i} className="dropdown-link w-dropdown-link">
                  <span className="awesome-icon">+</span> {t(type.label)}</Link>
              ) : (
                <div className="dropdown-link w-dropdown-link disabled-text">
                  <span className="awesome-icon">+</span> {t(type.label)}</div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}