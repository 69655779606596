// import {availableLanguages, pageWithQueryLang} from 'constants.js';
// import queryString from 'query-string';
// import esLocale from 'moment/locale/es';
// import enLocale from 'moment/locale/en-gb';
// import moment from 'moment';


export default class LanguageFactory {
  static _defaultLanguage = 'en';
  // static _language = null;
  // static _momentLocale = {
  //   'en': enLocale,
  //   'es': esLocale
  // };

  // static setLocale(language) {
  //   const newLanguage = availableLanguages.indexOf(language) !== -1 ? language : LanguageFactory._defaultLanguage;
  //   const locale = LanguageFactory._momentLocale[newLanguage];
  //   LanguageFactory._language = newLanguage;
  //   moment.updateLocale(newLanguage, locale);
  //   localStorage.setItem('userLanguage', newLanguage);
  // }

  static getLocale() {
    // const queryArgs = queryString.parse(window.location.search || '');
    // if (queryArgs.lang && (pageWithQueryLang.indexOf(window.location.pathname) !== -1)) {
    //   return availableLanguages.indexOf(queryArgs.lang) !== -1 ? queryArgs.lang : LanguageFactory._defaultLanguage;
    // }
    return localStorage.getItem('userLanguage') || LanguageFactory._defaultLanguage;
  }
}