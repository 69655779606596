import React, { Component, useEffect, useState } from 'react';
import Header from 'components/Header';
import IntlWrapper from 'components/widgets/IntlWrapper';
import Loader from 'components/widgets/Loader';
import {main, billing} from 'translate';
import {usePlans, useCurrentUserPlan} from 'hooks';
import {t} from 'utils';
import {Link} from 'react-router-dom';
import {TARIFF_PERIODS} from 'constants.js';


export default function TariffPlans() {
  const messages = [main, billing];

  const {planFeatures, getPlans, setPlan} = usePlans();
  const {userCurrentPlan} = useCurrentUserPlan();
  const [period, setPeriod] = useState(TARIFF_PERIODS.year); // | year

  useEffect(() => {
    getPlans();
  }, []);

  const _t = (value, values = {}, children = null) => {
    return t(`plans.${value}`, values, children);
  };

  const togglePeriod = () => {
    if (period === TARIFF_PERIODS.month) {
      setPeriod(TARIFF_PERIODS.year);
    } else {
      setPeriod(TARIFF_PERIODS.month);
    }
  };

  if (!userCurrentPlan.id) {
    return (<Loader/>);
  }

  return (
    <IntlWrapper messages={messages}>
      <Header title={_t('title', {},
        msg => <>
                {msg} <span className="title-span">(Your current plan: </span>
                <span className="tariff-name-span">{userCurrentPlan.subscription.plan_name}</span>
                <span className="title-span">)</span>
              </>)}></Header>
      <div className="page-content normal-content overflow-visible">
        <div className="w-form">
          <form>
            <div className="content-white-block no-wide">
              <div className="page-header center">Select a plan that’s right for your business(es)</div>
              <div className="tariff-period-choose" onClick={togglePeriod}>
                <div>Monthly</div>
                <div>Yearly (-30%)</div>
                <div className={`tariff-chose-button-2 w-inline-block ${period === TARIFF_PERIODS.month ?
                  'toggle-period-month-animate' : 'toggle-period-year-animate'}`}>
                  <div className={`monthly-text ${period === TARIFF_PERIODS.month ?
                    'display-flex' : 'display-none'}`}>Monthly</div>
                  <div className={`annually-text ${period === TARIFF_PERIODS.month ?
                    'display-none' : 'display-flex'}`}>Yearly (-30%)</div>
                </div>
              </div>
              <div className="">
                {/*<div id="w-node-03a659f87b85-3b9be9ed" className="grid-block">*/}
                {/*<div className="plan-label checkmark">✓</div>*/}
                {/*</div>*/}

                {Object.keys(planFeatures[period]).map((key, i) => {
                  const features = planFeatures[period][key];

                  return (
                    <div className={`features-row ${key === 'plan' ? 'price-bg-block' : ''}`}>
                      {features.map((value, j) => {
                        const flexLabel = 1/(features[1].length + 1);
                        const flexBlockRow = features[1].length/(features[1].length + 1);
                        if (key === 'plan') {
                          if (!value) {
                            return <div style={{flex: flexLabel}} className="features-empty grid-block top-block"></div>;
                          }
                          return (
                            <div style={{flex: flexBlockRow}} className="features-top-block">
                              {value.map((v) => {
                                return (
                                  <div className="flex-1 grid-block top-block">
                                    <div className="tariff-name">{v.name}</div>
                                    <div className="price-w">
                                      <div className="price-div">
                                        <div>$</div>
                                        <div className="price-block">
                                          <div className="yearly-price">{v.price}</div>
                                        </div>
                                      </div>
                                      <div className="price-label">/month</div>
                                    </div>
                                    {(v.name === 'Free' || v.price === 0) ? (
                                      <div className="transparent-button min-indent w-button">
                                      </div>
                                    ) : (
                                      <Link to="/new-subscription" className="blue-button min-indent w-button"
                                            onClick={() => setPlan(v)}>
                                        Get it now
                                      </Link>
                                    )}
                                  </div>
                                )
                              })}
                            </div>
                          )
                        }

                        return (
                          j === 0 ? (
                            <div style={{flex: flexLabel}} className="features-label grid-label">
                              <div className="tariff-plan-label">{value}</div>
                              <div className="toggle-w">
                                <div className="tooltip-div tooltip-block">
                                  <div className="info-text tooltip-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. </div>
                                  <div className="tooltip-arrow"></div>
                                </div>
                                <div className="awes-icon tooltip-icon"></div>
                              </div>
                            </div>
                          ) : (
                            <div style={{flex: flexBlockRow}} className="features-values-block">
                              {value.map((v) => {
                                return (
                                  <div className="flex-1 grid-block">
                                    {['live_visitors', 'live_typing', 'meetings', 'removable_branding'].includes(key) ? (
                                      v ? <div className="plan-label checkmark">✓</div> : <div className="plan-label">-</div>
                                    ) : (
                                      <div className="plan-label">{v === null || v === undefined ? 'Unlimited' : v}</div>
                                    )}
                                  </div>
                                )
                              })}
                            </div>
                          )
                        );
                      })}
                    </div>
                  )
                })}

              </div>
              {/*<div className="w-layout-grid grid">*/}
                {/*<div className="price-bg-block grid-th-row"></div>*/}

                {/*/!*<div id="w-node-03a659f87b85-3b9be9ed" className="grid-block">*!/*/}
                {/*/!*<div className="plan-label checkmark">✓</div>*!/*/}
                {/*/!*</div>*!/*/}

                {/*{planFeatures[period].map((plan, i) => {*/}
                  {/*const features = Object.keys(plan);*/}
                  {/*return (*/}
                    {/*features.map((key, j) => {*/}
                      {/*const style = {*/}
                        {/*'-ms-grid-column': i + 1,*/}
                        {/*'grid-column-start': i + 1,*/}
                        {/*'-ms-grid-column-span': i + 1,*/}
                        {/*'grid-column-end': i + 2,*/}
                        {/*'-ms-grid-row': j + 1,*/}
                        {/*'grid-row-start': j + 1,*/}
                        {/*'-ms-grid-row-span': j + 1,*/}
                        {/*'grid-row-end': j + 2,*/}
                      {/*};*/}
                      {/*if (key === 'plan') {*/}
                        {/*if (!plan[key]) {*/}
                          {/*return null;*/}
                        {/*}*/}
                        {/*return (*/}
                          {/*<div style={style} className="grid-block top-block">*/}
                            {/*<div className="tariff-name">{plan[key].name}</div>*/}
                            {/*<div className="price-w">*/}
                              {/*<div className="price-div">*/}
                                {/*<div>$</div>*/}
                                {/*<div className="price-block">*/}
                                  {/*<div className="monthly-price">{plan[key].price}</div>*/}
                                {/*</div>*/}
                              {/*</div>*/}
                              {/*<div className="price-label">/month</div>*/}
                            {/*</div>*/}
                            {/*<Link to="/new-subscription" className="blue-button min-indent w-button"*/}
                                  {/*onClick={() => setPlan(plan[key].name, 1)}>*/}
                              {/*Get it now*/}
                            {/*</Link>*/}
                          {/*</div>*/}
                        {/*);*/}
                      {/*}*/}

                      {/*return (*/}
                        {/*i === 0 ? (*/}
                          {/*<div className="grid-label" style={style}>*/}
                            {/*<div className="tariff-plan-label">{plan[key]}</div>*/}
                            {/*<div className="toggle-w">*/}
                              {/*<div className="tooltip-div tooltip-block">*/}
                                {/*<div className="info-text tooltip-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. </div>*/}
                                {/*<div className="tooltip-arrow"></div>*/}
                              {/*</div>*/}
                              {/*<div className="awes-icon tooltip-icon"></div>*/}
                            {/*</div>*/}
                          {/*</div>*/}
                        {/*) : (*/}
                          {/*<div className="grid-block" style={style}>*/}
                            {/*{['live_visitors', 'live_typing', 'meetings', 'removable_branding'].includes(key) ? (*/}
                              {/*plan[key] ? <div className="plan-label checkmark">✓</div> : <div className="plan-label">-</div>*/}
                            {/*) : (*/}
                              {/*<div className="plan-label">{plan[key]}</div>*/}
                            {/*)}*/}
                          {/*</div>*/}
                        {/*)*/}
                      {/*);*/}
                    {/*})*/}
                  {/*)*/}
                {/*})}*/}

              {/*</div>*/}
              <div className="top-plan-w hide">
                <div className="div-block-15">
                  <div className="page-header">Callback software</div>
                  <div className="page-subheader-2">Get instant call leads with our Callback widget</div>
                  <div className="div-block-14">
                    <div className="tariff-plan-label">Integrated in:</div>
                    <div className="top-plan-features">
                      <div className="top-plan-div">
                        <div className="plan-label checkmark">✓</div>
                        <div className="tariff-plan-label add-indent">Live chats</div>
                      </div>
                      <div className="top-plan-div">
                        <div className="plan-label checkmark">✓</div>
                        <div className="tariff-plan-label add-indent">Widget bots</div>
                      </div>
                      <div className="top-plan-div min-indent">
                        <div className="plan-label checkmark">✓</div>
                        <div className="tariff-plan-label add-indent">Landing bots</div>
                      </div>
                      <div className="top-plan-div">
                        <div className="plan-label checkmark">&amp;</div>
                        <div className="tariff-plan-label add-indent">Original widget</div>
                      </div>
                    </div>
                  </div>
                </div><a href="#" className="button min-indent w-button">See calls pricing</a></div>
              <div className="plan-label center"><strong>Have a questions? </strong>
                Write us at 
                <a href="mailto:support@heycom.io" className="text-link">support@heycom.io</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </IntlWrapper>
  );
}