import update from 'immutability-helper';
import * as actions from 'actions';

const initialState = {
  operatorsById: {},
  operatorsError: null,
  _isOperatorsFetching: false,
  _isCreating: false,
  _isRemoving: false,

  operator: {},
  operatorError: null,
  _isOperatorFetching: false,

  currentOperator: null
};

export default function operators(state=initialState, action) {
  switch(action.type) {
    case actions.FETCH_OPERATORS__R: {
      return update(state, {
        _isOperatorsFetching: {$set: true}
      });
    }
    case actions.FETCH_OPERATORS__S: {
      return update(state, {
        operatorsById: {$set: action.payload.reduce((obj, cur) => {
          obj[cur.id] = cur;
          return obj;
        }, {})},
        operatorsError: {$set: null},
        _isOperatorsFetching: {$set: false}
      });
    }
    case actions.FETCH_OPERATORS__F: {
      return update(state, {
        operatorsError: {$set: action.payload},
        _isOperatorsFetching: {$set: false}
      });
    }

    case actions.FETCH_OPERATOR__R: {
      return update(state, {
        _isOperatorFetching: {$set: true}
      });
    }
    case actions.FETCH_OPERATOR__S: {
      return update(state, {
        operator: {$set: action.payload},
        operatorError: {$set: null},
        _isOperatorFetching: {$set: false}
      });
    }
    case actions.FETCH_OPERATOR__F: {
      return update(state, {
        operatorError: {$set: action.payload},
        _isOperatorFetching: {$set: false}
      });
    }

    case actions.UPDATE_OPERATOR__R: {
      return update(state, {
        _isOperatorFetching: {$set: true}
      });
    }
    case actions.UPDATE_OPERATOR__S: {
      return update(state, {
        operatorsById: {[action.payload.id]: {$merge: action.payload}},
        operatorError: {$set: null},
        _isOperatorFetching: {$set: false}
      });
    }
    case actions.UPDATE_OPERATOR__F: {
      return update(state, {
        operatorError: {$set: action.payload},
        _isOperatorFetching: {$set: false}
      });
    }

    case actions.ADD_OPERATOR__R: {
      return update(state, {
        _isCreating: {$set: true}
      });
    }

    case actions.ADD_OPERATOR__S: {
      return update(state, {
        operatorsById: {[action.payload.id]: {$set: action.payload}},
        operatorError: {$set: null},
        _isCreating: {$set: false}
      });
    }

    case actions.ADD_OPERATOR__F: {
      return update(state, {
        operatorError: {$set: action.payload},
        _isCreating: {$set: false}
      });
    }

    case actions.REMOVE_OPERATOR__R: {
      return update(state, {
        _isRemoving: {$set: true}
      });
    }

    case actions.REMOVE_OPERATOR__S: {
      let operatorId = action.payload;

      return update(state, {
        operatorsById: {$unset: [operatorId]},
        _isRemoving: {$set: false}
      });
    }

    case actions.REMOVE_OPERATOR__F: {
      return update(state, {
        operatorError: {$set: action.payload},
        _isRemoving: {$set: false}
      });
    }

    case actions.SET_CURRENT_OPERATOR: {
      return update(state, {
        currentOperator: {$set: action.payload},
      });
    }

    case actions.UPDATING_OPERATOR: {
      if (!(action.payload.id in state.operatorsById)) {
        return state;
      }
      if (action.payload.id === state.currentOperator.id) {
        return update(state, {
          currentOperator: {$merge: action.payload},
          operatorsById: {
            [action.payload.id]: {$merge: action.payload}
          },
        });
      } else {
        return update(state, {
          operatorsById: {
            [action.payload.id]: {$merge: action.payload}
          },
        });
      }

    }


    default:
      return state;
  }
}