import React, {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch, shallowEqual } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';

import {useSendFiles} from 'hooks';
import * as actions from 'actions';
import {CAMPAIGN_REQUEST_TYPES, HISTORY_EVENT_TYPES} from 'constants.js';


export const useBuildBot = () => {
  const startCoordinate = 30;
  const stepCoordinate = 20;
  const botSchemaState = useSelector(state => state.campaigns.botSchema, shallowEqual);
  const selectedBlockType = useSelector(state => state.campaigns.selectedBlockType, shallowEqual);
  const currentNode = useSelector(state => state.campaigns.selectedNode, shallowEqual);
  const coordinates = useSelector(state => state.campaigns.coordinates, shallowEqual);
  const dispatch = useDispatch();
  const [nodes, setSelectedNodes] = useState(botSchemaState);
  const [isChange, toggleSchemaChange] = useState(false);
  const [errorFact, setErrorFact] = useState(false);
  //const [coordinates, changeCoordinates] = useState([]);

  //console.log(botSchemaState, nodes);

  const updateBotSchema = () => {dispatch(actions.changeBotSchema(nodes))};
  const setSelectedBlockType = (type) => {dispatch(actions.selectBlockType(type))};
  const setCurrentNode = (node) => {
    if (node) {
      if (currentNode && currentNode.id) {
        onChangeNodes({
          [currentNode.id]: {$set: currentNode}
        }, true);
      }
    }
    if (!errorFact || !node) {
      dispatch(actions.selectBotNode(node));
    }
  };
  const updateCurrentNode = (node) => dispatch(actions.updateSelectedBotNode(node));
  const changeHistory = (prevEvent, nextEvent) => dispatch(actions.addHistoryEvent(prevEvent, nextEvent));

  const onChangeNodes = (data, isNotHistoryChange = false) => {
    toggleSchemaChange(true);
    const nodeId = Object.keys(data)[0];
    if (data[nodeId]['position']) {
      let ind = null;
      coordinates.forEach(([key, value], i) => {
        if (key === nodeId) {
          ind = i
        }
      });
      if (ind !== null) {
        coordinates.splice(ind, 1);
        dispatch(actions.changeCoordinates(coordinates))
      }
    }
    if (!isNotHistoryChange) {
      changeHistory({
        type: HISTORY_EVENT_TYPES.edit,
        node_id: nodeId,
        body: nodes[nodeId]
      }, {
        type: HISTORY_EVENT_TYPES.edit,
        node_id: nodeId,
        body: data[nodeId]
      });
    } else {
      if (currentNode && nodeId === currentNode.id) {
        //updateCurrentNode(data[currentNode.id]);
      }
    }
    setSelectedNodes(update(nodes, data));
  };

  const onAddNode = (component, content) => {
    //console.log('add', component)
    const id = uuidv4();
    let lastCoordinates = coordinates.length ? null : startCoordinate;
    let prevCoordinates = startCoordinate;
    coordinates.forEach(([key, value], i) => {
      if (value - prevCoordinates > stepCoordinate) {
        prevCoordinates = value - stepCoordinate;
        lastCoordinates = lastCoordinates || prevCoordinates;
      } else {
        if (value - stepCoordinate === startCoordinate && i === 0) {
          lastCoordinates = lastCoordinates || startCoordinate;
        }
        prevCoordinates = value;
      }
    });
    lastCoordinates = lastCoordinates || prevCoordinates + stepCoordinate;
    const node = {
      [id]: {
        id: id,
        type: component,
        position: {
          x: lastCoordinates,
          y: lastCoordinates,
        },
        next_block_id: null,
        content: content
      }
    };
    coordinates.push([id, lastCoordinates]);
    dispatch(actions.changeCoordinates(coordinates.sort((c1,c2) => {
      //console.log(c1,c2)
      if (c1[1] < c2[1]) return -1;
      if (c1[1] > c2[1]) return 1;
      return 0;
    })));
    toggleSchemaChange(true);
    setSelectedNodes(update(nodes, {
      $merge: node
    }));
    changeHistory({
      type: HISTORY_EVENT_TYPES.add,
      node_id: id,
      body: null
    }, {
      type: HISTORY_EVENT_TYPES.add,
      node_id: id,
      body: node
    });
  };

  const onDeleteNode = id => {
    toggleSchemaChange(true);
    let ind = null;
    coordinates.forEach(([key, value], i) => {
      if (key === id) {
        ind = i
      }
    });
    if (ind !== null) {
      coordinates.splice(ind, 1);
      dispatch(actions.changeCoordinates(coordinates))
    }
    changeHistory({
      type: HISTORY_EVENT_TYPES.delete,
      node_id: id,
      body: nodes[id]
    }, {
      type: HISTORY_EVENT_TYPES.delete,
      node_id: id,
      body: null
    });
    if (currentNode && id === currentNode.id) {
      setCurrentNode(null)
      setSelectedBlockType(null)
    }
    setSelectedNodes(update(nodes, {
      $unset: [id]
    }));
  };

  const onCopyNode = node => {
    const newId = uuidv4();
    const newNode = {
      ...node,
      position: {
        x: node.position.x + stepCoordinate,
        y: node.position.y + stepCoordinate
      }
    };
    toggleSchemaChange(true);
    changeHistory({
      type: HISTORY_EVENT_TYPES.add,
      node_id: newId,
      body: null
    }, {
      type: HISTORY_EVENT_TYPES.add,
      node_id: newId,
      body: newNode
    });
    setSelectedNodes(update(nodes, {
      $merge: {
        [newId]: {
          ...newNode,
          id: newId
        }
      }
    }));
  };

  useEffect(() => {
    //console.log('update botSchemaState', nodes)
    setSelectedNodes(botSchemaState);
    toggleSchemaChange(false);
  }, [botSchemaState]);
  //
  useEffect(() => {
    //console.log('update nodes', nodes)
    if (isChange) {
      updateBotSchema();
    }
  }, [nodes]);

  return {nodes: botSchemaState, selectedBlockType, currentNode,
    setSelectedBlockType, setCurrentNode, setErrorFact,
    onChangeNodes, onAddNode, onDeleteNode, onCopyNode, onChangeHistory: changeHistory}
};


export const useCampaigns = () => {
  const campaigns = useSelector(state => state.campaigns.campaigns, shallowEqual);
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const isCreating = useSelector(state => state.campaigns._isCreating, shallowEqual);
  const isUpdating = useSelector(state => state.campaigns._isUpdating, shallowEqual);
  const selectedCampaignType = useSelector(state => state.campaigns.selectedCampaignType, shallowEqual);
  const isFetching = useSelector(state => state.campaigns._isCampaignsFetching, shallowEqual);
  const dispatch = useDispatch();

  const getCampaigns = useCallback((params) => {
    const type = params && params.type ? params.type : selectedCampaignType.includedTypes.join(',');
    dispatch(actions.fetchCampaigns({
      ...params,
      widget_id: currentWidgetId,
      type: type
    }))
  }, [dispatch, currentWidgetId, selectedCampaignType]);

  const getCampaign = useCallback((campaignId) => {
    dispatch(actions.fetchCampaign(campaignId))
  }, [dispatch]);

  const updateCampaign = useCallback((id, params) => dispatch(actions.updateCampaign(id, params)), [dispatch]);
  const deleteCampaign = useCallback((id) => dispatch(actions.removeCampaign(id)), [dispatch]);
  const enableCampaign = useCallback((id) => dispatch(actions.enableCampaign(id)), [dispatch]);
  const disableCampaign = useCallback((id) => dispatch(actions.disableCampaign(id)), [dispatch]);
  const archiveCampaign = useCallback((id) => dispatch(actions.archiveCampaign(id)), [dispatch]);
  const unarchiveCampaign = useCallback((id) => dispatch(actions.unarchiveCampaign(id)), [dispatch]);
  const cloneCampaign = useCallback((id, name) => dispatch(actions.cloneCampaign(id, name)), [dispatch]);
  const triggerCampaign = useCallback(data => dispatch(actions.triggerCampaign(data)), [dispatch]);

  return {campaigns, getCampaigns, isFetching, isCreating, updateCampaign, triggerCampaign,
    isUpdating, deleteCampaign, enableCampaign, disableCampaign, archiveCampaign, unarchiveCampaign, cloneCampaign};
};

export const useCampaign = (props) => {
  const {campaignType, newCampaign, isCreate} = props;

  const campaignIdParam = props.match ? props.match.params.campaignId : null;

  const sendFiles = useSendFiles();
  const {campaigns, currentCampaign, botSchema} = useSelector(state => state.campaigns, shallowEqual);
  const [localCampaign, setLocalCampaign] = useState(currentCampaign);
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const dispatch = useDispatch();

  const [type, setType] = useState(null);
  const [isSaveTime, toggleSaveTime] = useState(false);
  const [isFileExists, toggleFileExists] = useState(false);
  const isCampaignSaving = false;
  //const [isCampaignSaving, setCampaignSaving] = useState(false);

  useEffect(() => {
    if (campaigns[campaignIdParam] && !isCreate) {
      const selectedCampaign = campaigns[campaignIdParam];
      const campType = Object.keys(CAMPAIGN_REQUEST_TYPES).find(key => CAMPAIGN_REQUEST_TYPES[key] === selectedCampaign.type);
      //setCurrentCampaign(selectedCampaign);
      setType(campType);
    }
  }, [campaigns]);

  useEffect(() => {
    setLocalCampaign(update(localCampaign, {
      $set: Object.values(botSchema).length !== 0 ? {...currentCampaign, bot_builder: botSchema} : currentCampaign
    }));
  }, [currentCampaign, botSchema]);

  useEffect(() => {
    if (isSaveTime) {

      toggleSaveTime(false);
    }
  }, [isSaveTime]);

  const updateBotSchema = (nodes) => {dispatch(actions.changeBotSchema(nodes))};
  const setCurrentCampaign = (campaign) => dispatch(actions.selectCampaign(campaign));

  const getCampaign = useCallback((campaignId) => {
    return dispatch(actions.fetchCampaign(campaignId))
  }, [dispatch]);

  const addCampaign = useCallback((params) => dispatch(actions.createCampaign({
    ...params,
    widget_id: currentWidgetId
  })), [dispatch, currentWidgetId]);

  const updateCampaign = useCallback((id, params) => dispatch(actions.updateCampaign(id, params)), [dispatch]);
  const setCampaignCreating = useCallback(() => dispatch(actions.createCampaignRq()), [dispatch]);
  const setCampaignUpdating = useCallback(() => dispatch(actions.updateCampaignRq()), [dispatch]);
  const clearCurrentCampaign = useCallback(() => dispatch(actions.clearCurrentCampaign()), [dispatch]);


  const handleSetCampaign = () => {
    if (isCreate) {
      setCurrentCampaign(newCampaign);
      setType(campaignType);
    } else if (campaignIdParam) {
      getCampaign(campaignIdParam);
    }
  };

  const saveCampaign = (isActivateNow, isCreate, isDraft = false) => {
    //console.log('saveCampaign', localCampaign);
    const sendingCampaign = cloneDeep(localCampaign);
    if (isCreate) {
      setCampaignCreating();
    } else {
      setCampaignUpdating();
    }

    if (type === CAMPAIGN_REQUEST_TYPES.bot_web_chat || type === CAMPAIGN_REQUEST_TYPES.landbot || type === CAMPAIGN_REQUEST_TYPES.facebook_messenger) {
      if (sendingCampaign.bot_builder) {
        const nodes = Object.values(sendingCampaign.bot_builder);
        _getFiles(nodes, sendingCampaign)
          .then(data => {
            _saveCampaign(data, isActivateNow, isCreate, isDraft);
          })
      }
    } else {
      _saveCampaign(localCampaign, isActivateNow, isCreate, isDraft);
    }
  };

  const _saveCampaign = (sendingCampaign, isActivateNow, isCreate, isDraft) => {
    if (isCreate) {
      addCampaign({
        ...sendingCampaign,
        type: CAMPAIGN_REQUEST_TYPES[type],
        is_draft: isDraft,
        enabled: isActivateNow
      });
    } else {
      updateCampaign(currentCampaign.id, {
        ...sendingCampaign,
        is_draft: isDraft,
        enabled: isActivateNow
      });
    }
  };

  async function _getFiles(nodes, sendingCampaign) {
    for (const node of nodes) {
      //const node = sendingCampaign.bot_builder[id];
      const id = node.id;
      const isFinalNode = false;//i === nodeIds.length - 1;
      if (node.content && node.content.sections) {
        await handleFilesSend(node.content.sections, isFinalNode)
          .then(data => {
            sendingCampaign.bot_builder[id].content.sections = data;
          });
      }
      if (node.content && node.content.transfer_sections) {
        await handleFilesSend(node.content.transfer_sections)
          .then(data => {
            sendingCampaign.bot_builder[id].content.transfer_sections = data;
          });
      }
      if (node.content && node.content.cards) {
        for (let cardInd in node.content.cards) {
          if (node.content.cards[cardInd].attachment) {
            await handleFilesSend([node.content.cards[cardInd].attachment])
              .then(data => {
                sendingCampaign.bot_builder[id].content.cards[cardInd].attachment = data[0];
              });
          }
        }
      }
    }

    if (type === CAMPAIGN_REQUEST_TYPES.landbot) {
      await handleFilesSend([{type: 'file', file: sendingCampaign.design.main_settings.logo}]).then(data => {
        sendingCampaign.design.main_settings.logo = data[0] &&  data[0].file ? data[0].file : null;
      });
      await handleFilesSend([{type: 'file', file: sendingCampaign.design.main_settings.avatar}]).then(data => {
        sendingCampaign.design.main_settings.avatar = data[0] &&  data[0].file ? data[0].file : null;
      });
      await handleFilesSend([{type: 'file', file: sendingCampaign.design.design_settings.background.image}]).then(data => {
        sendingCampaign.design.design_settings.background.image = data[0] &&  data[0].file ? data[0].file : null;
      });
      await handleFilesSend([{type: 'file', file: sendingCampaign.design.design_settings.background.video}]).then(data => {
        sendingCampaign.design.design_settings.background.video = data[0] &&  data[0].file ? data[0].file : null;
      });
      await handleFilesSend([{type: 'file', file: sendingCampaign.display_settings.seo.meta_favicon}]).then(data => {
        sendingCampaign.display_settings.seo.meta_favicon = data[0] &&  data[0].file ? data[0].file : null;
      });
      await handleFilesSend([{type: 'file', file: sendingCampaign.display_settings.seo.meta_image}]).then(data => {
        sendingCampaign.display_settings.seo.meta_image = data[0] &&  data[0].file ? data[0].file : null;
      });
    }

    return sendingCampaign;
  }

  async function handleFilesSend(sections) {
    let key = 0;
    for (let section of sections) {
      //const section = sections[key];
      if (section.type === 'file' && section.file && !section.file.url) {
        const response = _sending(section);
        await response
          .then(data => {
            sections[key].file = data.files[0];
          })
      }
      key += 1;
    }
    return sections;
  };

  async function _sending(section) {
    const sendingPromise = sendFiles([section.file]);
    return await sendingPromise;
  }

  return {campaigns, isCampaignSaving, getCampaign, handleSetCampaign, updateBotSchema, saveCampaign,
    type, currentCampaign, clearCurrentCampaign, setCurrentCampaign};
};

export const useCampaignsCampaignTypeFilter = () => {
  const selectedCampaignType = useSelector(state => state.campaigns.selectedCampaignType, shallowEqual);

  const dispatch = useDispatch();
  const setSelectedCampaignType = useCallback(value => {dispatch(actions.setCampaignsCampaignTypeFilter(value))}, [dispatch]);

  return {selectedCampaignType, setSelectedCampaignType}
};

export const useHistoryEvents = () => {
  const {onChangeNodes} = useBuildBot();
  const {history, pointer} = useSelector(state => state.campaigns, shallowEqual);
  const dispatch = useDispatch();
  const onClearHistory = (particial = false) => dispatch(actions.clearBotHistory(particial));

  const onUndoHistoryEvent = () => {
    //return;
    const event = history[pointer].prev;
    if (event) {
      switch (event.type) {
        case HISTORY_EVENT_TYPES.add: {
          onChangeNodes({$unset: [event.node_id]}, true);
          break;
        }
        case HISTORY_EVENT_TYPES.delete: {
          onChangeNodes({$merge: {[event.node_id]: event.body[event.node_id] ? event.body[event.node_id] : event.body}}, true);
          break;
        }
        case HISTORY_EVENT_TYPES.edit: {
          onChangeNodes({[event.node_id]: {$set: event.body[event.node_id] ? event.body[event.node_id] : event.body}}, true);
          break;
        }
        default:
          //console.log('Oh, really?');
          break;
      }
    }

    //onChangeNodes(history[pointer]);
    dispatch(actions.undoHistoryEvent());
  };

  const onRedoHistoryEvent = () => {
    //return;
    const event = history[pointer].next;
    if (event) {
      switch (event.type) {
        case HISTORY_EVENT_TYPES.add: {
          onChangeNodes({$merge: {[event.node_id]: event.body[event.node_id] ? event.body[event.node_id] : event.body}}, true);
          break;
        }
        case HISTORY_EVENT_TYPES.delete: {
          onChangeNodes({$unset: [event.node_id]}, true);
          break;
        }
        case HISTORY_EVENT_TYPES.edit: {
          onChangeNodes({[event.node_id]: event.body[event.node_id] ? event.body[event.node_id] : event.body}, true);
          break;
        }
        default:
          //console.log('Oh, really?');
          break;
      }
    }
    dispatch(actions.redoHistoryEvent());
  };
  return {historyItems: history.length, history, pointer, onUndoHistoryEvent, onRedoHistoryEvent, onClearHistory};
};


