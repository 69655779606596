import React, { Fragment, useEffect, useState, useCallback } from 'react';
import moment from 'moment-timezone';
import update from "immutability-helper";

import {useCurrentUserState} from 'hooks';
import Switch from 'components/widgets/Switch';
import {t} from 'utils';


const timeZones = moment.tz.names();
const timeZonesWithOffset = timeZones.reduce((obj, cur) => {
  obj[cur] = {
    value: cur,
    offset: moment.tz(cur)._offset,
    label: `${cur} (GMT${moment.tz(cur).format('Z')})`
  };
  return obj;
}, {});


export default function SiteSettings(props) {
  const {widget, widgetsById, setWidget, currentOperator, onSave, onDelete} = props;

  const [projectSettings, setProjectSettings] = useState(widget.settings.project);
  const [siteUrl, setSiteUrl] = useState(widget.site_url);
  const {currentUser} = useCurrentUserState();

  const isCanDeleteWidget = currentUser.id === currentOperator.user_id && currentOperator.is_owner
    && Object.values(widgetsById).length > 1;

  useEffect(() => {
    setProjectSettings(widget.settings.project);
    setSiteUrl(widget.site_url);
  }, [widget])

  useEffect(() =>
    setWidget(update(widget, {settings: {project: {$set: projectSettings}}})
  ), [projectSettings]);

  useEffect(() =>
    setWidget(update(widget, {site_url: {$set: siteUrl}})
  ), [siteUrl]);

  const frequencyOptions = {
    every_day: {value: 'every_day', label: 'everyDay'},
    every_3_days: {value: 'every_3_days', label: 'everyThreeDays'},
    weekly: {value: 'weekly', label: 'weekly'},
    monthly: {value: 'monthly', label: 'monthly'},
  };

  useEffect(() => {
    // set defaults values
    const defaultEmailRecipients = projectSettings.reports.recipients.length ? projectSettings.reports.recipients : [''];
    setProjectSettings(update(projectSettings, {
        reports: {
          recipients: {$set: defaultEmailRecipients}
        }
    }));
  }, [widget]);

  const handleDeleteRecipient = ind => {
    setProjectSettings(update(projectSettings, {
        reports: {
          recipients: {$splice: [[ind, 1]]}
        }
    }));
  };
  const handleAddRecipient = () => {
    setProjectSettings(update(projectSettings, {
        reports: {
          recipients: {$push: ['']}
        }
    }));
  };
  const handleChangeRecipientEmail = (value, i) => {
    setProjectSettings(update(projectSettings, {
        reports: {
          recipients: {[i]: {$set: value}}
        }
    }));
  };

  const handleSave = useCallback(() => {
    onSave({...widget, site_url: siteUrl, settings: {...widget.settings, project: projectSettings}})
  }, [widget, siteUrl, projectSettings])

  const _t = (value) => {
    return t(`siteSettings.${value}`);
  };

  return (
    <>
      <div className="pane-content-w">
        <div className="content-white-block">
          <div className="group-row no-margin">
            <div className="col-6">
              <div className="settings-item-label add-margin">{_t('siteUrl.label')}</div>
              <input type="text" value={siteUrl}
                            onChange={e => setSiteUrl(e.target.value)}
                            className="node-settings-input w-input" maxLength="256"/>
            </div>
            {/*<div className="col-6">*/}
              {/*<div className="settings-item-label add-margin">{_t('timezone.label')}</div>*/}
              {/*<CustomSelect options={Object.values(timeZonesWithOffset)}*/}
                            {/*defaultValue={timeZonesWithOffset[projectSettings.timezone.name]}*/}
                            {/*selectClassName={`settings-select w-select`}*/}
                            {/*getOptionLabel={option => option.label}*/}
                            {/*onChange={tz => {*/}
                              {/*setProjectSettings(update(projectSettings, {*/}
                                  {/*timezone: {*/}
                                    {/*name: {$set: tz.value},*/}
                                    {/*offset: {$set: tz.offset}*/}
                                  {/*}*/}
                              {/*}));*/}
                            {/*}}*/}
                            {/*value={timeZonesWithOffset[projectSettings.timezone.name]}*/}
              {/*/>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="content-white-block">
          <div className="group-w no-indent">
            <div className="group-w-header">
              <div className="group-title">{_t('reports')}</div>
              <Switch isOn={projectSettings.reports.enabled}
                      onClick={() => {
                        setProjectSettings(update(projectSettings, {
                          reports: {
                            $toggle: ['enabled']
                          }
                        }));
                      }}/>
            </div>
            {projectSettings.reports.enabled && (
              <div className="group-block">
                <div className="show-group-content">
                  <div className="show-group-div">
                    <div className="group-row">
                      <div className="col-6">
                        <div className="settings-item-label add-margin">{_t(`frequency.label`)}</div>
                        <div className="form-row">
                          {Object.values(frequencyOptions).map((option, i) =>
                            <label key={i} className="radio-button-field w-radio">
                              <input type="radio"
                                     className="w-form-formradioinput w-radio-input"
                                     value={option.value}
                                     checked={projectSettings.reports.frequency === option.value}
                                     onChange={(e) => {
                                       setProjectSettings(update(projectSettings, {
                                         reports: {
                                           frequency: {$set: e.target.value}
                                         }
                                       }));
                                     }
                                     }/>
                              <span className="radio-button-label w-form-label">{_t(`frequency.${option.label}`)}</span>
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="settings-item-label add-margin">{_t(`sendTo.label`)}</div>
                        {projectSettings.reports.recipients.map((recipient, i) =>
                          <div key={i}>
                            <div className={`${i !== 0 ? 'input-div-2' : ''}`}>
                              <input type="text" value={recipient} className="node-settings-input w-input"
                                     onChange={(e) => handleChangeRecipientEmail(e.target.value, i)} maxLength="256" />
                              {i !== 0 && (
                                <div className="delete-btn" onClick={() => handleDeleteRecipient(i)}>
                                  <div className="close-line-1 rotate"></div>
                                  <div className="close-line-1"></div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="link-2" onClick={handleAddRecipient}>{_t(`sendTo.button`)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`settings-btn-w ${isCanDeleteWidget ? 'add-space' : ''}`}>
          {isCanDeleteWidget && (
              <div className="step-btn red-btn w-button" onClick={() => onDelete(widget.id)}>{_t(`delete.button`)}</div>
          )}
          <div className="button left-indent w-button" onClick={handleSave}>{_t(`save.button`)}</div>
        </div>
      </div>
    </>
  );
}