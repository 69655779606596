import update from 'immutability-helper';
import * as actions from 'actions';
import {CAMPAIGN_TYPES} from 'constants.js'


const initialState = {
  liveVisitorsById: {},
  liveVisitorsCount: 0,
  liveVisitorsError: null,
  _isLiveVisitorsFetching: false,

  //campaign type filter
  selectedCampaignType: Object.values(CAMPAIGN_TYPES)[0],
};

export default function liveVisitors(state=initialState, action) {
  switch(action.type) {
    // live visitors
    case actions.FETCH_LIVE_VISITORS__R: {
      return update(state, {
        _isLiveVisitorsFetching: {$set: true}
      });
    }
    case actions.FETCH_LIVE_VISITORS__S: {
      return update(state, {
        liveVisitorsById: {$set: action.payload.payload.reduce((obj, cur) => {
            obj[cur.id] = cur;
            return obj;
          }, {})},
        liveVisitorsCount: {$set: action.payload.count !== undefined ? action.payload.count : state.liveVisitorsCount},
        liveVisitorsError: {$set: null},
        _isLiveVisitorsFetching: {$set: false}
      });
    }
    case actions.FETCH_LIVE_VISITORS__F: {
      return update(state, {
        liveVisitorsError: {$set: action.payload},
        _isLiveVisitorsFetching: {$set: false}
      });
    }

    // campaign type filter
    case actions.SET_LIVE_VISITORS_CAMPAIGN_TYPE_FILTER: {
      return update(state, {
        selectedCampaignType: {$set: action.payload},
      });
    }

    default:
      return state;
  }
}