import React, { useCallback, useState, useEffect } from 'react';
import {useSelector, useDispatch, shallowEqual } from 'react-redux';

import * as actions from 'actions';
import {setInitialChatsState} from "actions";


export const useWidgets = () => {
  const widgetsById = useSelector(state => state.widgets.widgetsById, shallowEqual);
  const currentWidget = useSelector(state => state.widgets.currentWidget, shallowEqual);
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const isInstallCurrentWidget = useSelector(state => state.widgets.isInstallCurrentWidget);
  const isCheckingInstall = useSelector(state => state.widgets._isCheckingInstall, shallowEqual);
  const isFetching = useSelector(state => state.widgets._isWidgetsFetching, shallowEqual);
  const isWidgetFetching = useSelector(state => state.widgets._isWidgetFetching, shallowEqual);
  const isUpdating = useSelector(state => state.widgets._isUpdating, shallowEqual);
  const isCreating = useSelector(state => state.widgets._isCreating, shallowEqual);

  const dispatch = useDispatch();
  const getWidgets = useCallback((filter = {}) => {dispatch(actions.fetchWidgets(filter))}, [dispatch]);
  const addWidget = useCallback((params) => dispatch(actions.createWidget(params)), [dispatch]);
  const updateWidget = useCallback((id, params) => dispatch(actions.updateWidget(id, params)), [dispatch]);
  const deleteWidget = useCallback((id) => dispatch(actions.removeWidget(id)), [dispatch]);
  const getWidget = useCallback((id) => dispatch(actions.fetchWidget(id)), [dispatch]);
  const setCurrentWidgetId = useCallback(value => {
    dispatch(actions.setCurrentWidget(value));
    dispatch(actions.setInitialChatsState());
  }, [dispatch, widgetsById]);
  const checkInstallWidget = useCallback(id => dispatch(actions.checkInstallWidget(id)), [dispatch]);

  return {widgetsById, currentWidget, currentWidgetId, setCurrentWidgetId, isFetching, isWidgetFetching,
    isCreating, isUpdating, isCheckingInstall, getWidgets, getWidget, addWidget, updateWidget, deleteWidget,
    checkInstallWidget, isInstallCurrentWidget};
};

export const useSelectSettingsTab = () => {
  const selectedTab = useSelector(state => state.widgets.selectedSettingsTab, shallowEqual);
  const dispatch = useDispatch();
  const setSelectedTab = useCallback(value => {dispatch(actions.selectSettingTab(value))}, [dispatch]);
  return {selectedTab, setSelectedTab};
};

export const useStateWidgets = () => {
  const widgetsById = useSelector(state => state.widgets.widgetsById, shallowEqual);
  const isWidgetFetching = useSelector(state => state.widgets._isWidgetFetching, shallowEqual);
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const currentWidget = useSelector(state => state.widgets.currentWidget, shallowEqual);
  return {widgetsById, currentWidget, currentWidgetId, isWidgetFetching};
};

export const useContactProperty = (additionalProperties = {}) => {
  const dispatch = useDispatch();
  const {currentWidget, currentWidgetId} = useSelector(state => state.widgets, shallowEqual);
  const [contactPropertiesByName, setContactProperties] = useState({
    name: {name: 'name', label: 'Name'},
    email: {name: 'email', label: 'Email'},
    phone: {name: 'phone', label: 'Phone'},
    city: {name: 'city', label: 'City'},
    country: {name: 'country', label: 'Country'},
    ...additionalProperties
  });

  useEffect(() => {
    if (currentWidget.contact_properties && currentWidget.contact_properties.length) {
      const customProperties = currentWidget.contact_properties.reduce((obj, cur) => {
        obj[cur.name] = cur;
        return obj;
      }, {});
      setContactProperties({
        ...contactPropertiesByName,
        ...customProperties
      })
    }
  }, [currentWidget]);

  const createProperty = useCallback((property) => {
    return dispatch(actions.createContactProperty(property, currentWidgetId))
  }, [dispatch]);

  return {contactPropertiesByName, createProperty}
};

export const useVideos = () => {
  const [videos, setVideos] = useState(null);
  const dispatch = useDispatch();
  const getVideo =  useCallback(() => {
    dispatch(actions.getVideoFiles())
      .then(videoFiles => {
        setVideos(videoFiles.files)
      })},
    [dispatch]);

  return {videos, getVideo};
}


export const useFacebookConnected = () => {
  const dispatch = useDispatch();

  const currentWidget = useSelector(state => state.widgets.currentWidget, shallowEqual);
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);

  const facebookPages = useSelector(state => state.widgets.facebookPages, shallowEqual);
  const selectedFacebookPage = useSelector(state => state.widgets.selectedFacebookPage, shallowEqual);
  const facebookConnectingError = useSelector(state => state.widgets.facebookConnectingError, shallowEqual);
  const isFacebookConnecting = useSelector(state => state.widgets._isFacebookConnecting, shallowEqual);
  const isFacebookPagesFetching = useSelector(state => state.widgets._isFacebookPagesFetching, shallowEqual);
  const isFacebookPageConnecting = useSelector(state => state.widgets._isFacebookPageConnecting, shallowEqual);
  const isFacebookLogout = useSelector(state => state.widgets._isFacebookLogout, shallowEqual);

  const [isLoading, setIsLoading] = useState(isFacebookConnecting || isFacebookPagesFetching
    || isFacebookPageConnecting || isFacebookLogout);

  useEffect(() => {
    const value = isFacebookConnecting || isFacebookPagesFetching
      || isFacebookPageConnecting || isFacebookLogout;
    if (isLoading === value) {
      setIsLoading(value);
    }
  }, [isFacebookConnecting, isFacebookPagesFetching, isFacebookPageConnecting, isFacebookLogout])


  const getPages = useCallback(() => dispatch(actions.fetchFacebookPages(currentWidgetId)), [currentWidgetId]);
  const facebookConnected = useCallback((data) => dispatch(actions.facebookLogin(data, currentWidgetId)), [currentWidgetId]);
  const facebookLogout = useCallback(() => dispatch(actions.facebookLogout(currentWidgetId)), [currentWidgetId]);
  const facebookPageChoose = useCallback((pageId) => dispatch(actions.facebookPageChoose(pageId, currentWidgetId)), [currentWidgetId]);
  const facebookPageDisconnect = useCallback((pageId) => dispatch(actions.facebookPageDisconnect(pageId, currentWidgetId)), [currentWidgetId]);

  return {facebookPages, facebookConnectingError, selectedFacebookPage, isLoading,
    getPages, facebookConnected, facebookLogout, facebookPageChoose, facebookPageDisconnect};
}