import React from 'react';
import TimePicker from "components/widgets/TimePicker";

export default function TimeRow(props) {
  const {day, interval, timeRangeIdx, timeFormat, onChangeSchedule, onDeleteSchedule, onAddSchedule} = props;

  return (
    <div className="range-hint-item" key={timeRangeIdx}>
      <div>From:</div>
      <TimePicker strTime={interval.from.replace('-', ':')} defaultTime={"00:00"} day={day}
                  timeFormat={timeFormat}  type="start" additionalFormatClass="add-big-margin"
                  additionalPickerClass="input-control-2 w-input align-center" additionalTimeBlockClass="no-left-margin"
                  onChange={time => onChangeSchedule(day, 'from', time, timeRangeIdx)}/>
      <div>Until:</div>
      <TimePicker strTime={interval.until.replace('-', ':')} defaultTime={"00:00"} day={day}
                  timeFormat={timeFormat} type="finish"
                  additionalPickerClass="input-control-2 w-input align-center" additionalTimeBlockClass="no-left-margin"
                  onChange={time => onChangeSchedule(day, 'until', time, timeRangeIdx)}/>

      {timeRangeIdx !== 0 && (
        <div className="delete-btn" onClick={() => onDeleteSchedule(day, timeRangeIdx)}>
          <div className="close-line-1 rotate"/>
          <div className="close-line-1"/>
        </div>
      )}

      {timeRangeIdx === 0 && (<a href="#" className="btn-add-range no-abs-position"
                                 onClick={() => onAddSchedule(day)}>+</a>)}
    </div>
  );
};