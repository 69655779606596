import React, {Fragment, useState} from 'react';

import BlockBuilderSections from './BlockBuilderSections';
import CustomizableButton from 'components/widgets/CustomizableButton';
import TextareaWithAddons from 'components/widgets/TextareaWithAddons';
import Switch from 'components/widgets/Switch';
import ActionButtons from './ActionButtons';
import {t} from 'utils';


export default function ChoiceButtonSettings(props) {
  const {currentNode, onChangeNode, onSave, onErrorFactSet, onCancel} = props;
  const [errors, setErrors] = useState({cards: null, transferToOperator: null});
  const handleNodeChange = (data, field) => {
    onChangeNode(currentNode.content[field] ?
      {content: {[field]: {$set: data}}} : {content: {$merge: {[field]: data}}})
  };
  const handleButtonChange = (data, i) => {
    onChangeNode({content: {buttons: {[i]: {$merge: data}}}});
  };
  const handleButtonAdd = (section) => {
    onChangeNode(currentNode.content.buttons ?
      {content: {buttons: {$push: [section]}}} : {content: {$set: {buttons: [section]}}});
  };
  const handleButtonDelete = (i) => {
    onChangeNode({content: {buttons: {$splice: [[i, 1]]}}});
  };
  const save = () => {
    let gettingErrors = {};
    if (currentNode.content.transfer_to_operator && (!currentNode.content.transfer_sections ||
      (currentNode.content.transfer_sections &&currentNode.content.transfer_sections.length === 0))) {
      gettingErrors = {...gettingErrors, transferToOperator: 'You must add the field'};
    }
    if (Object.values(gettingErrors).length === 0) {
      onErrorFactSet(false);
      onSave();
    } else {
      onErrorFactSet(true);
      setErrors(gettingErrors);
    }
  };

  return (
    <Fragment>
      <div className="node-settings">
        <TextareaWithAddons placeholderField="web.create.botBuilder.first.message.placeholder"
                            isNotDeleting={true}
                            value={currentNode.content.text ||  ''}
                            onChange={value => handleNodeChange(value, 'text')}/>
        {currentNode.content.buttons && currentNode.content.buttons.map((button, i) =>
          <div className="settings-item">
            <CustomizableButton name={button.text}
                                onChangeName={value => handleButtonChange({text: value}, i)}
                                onDelete={() => handleButtonDelete(i)}/>
          </div>
        )}
        <div className="btns-w">
          <div className="step-btn add-field-full w-inline-block" onClick={() => handleButtonAdd({
            text: `Button ${currentNode.content.buttons ? currentNode.content.buttons.length + 1 : 1}`,
            next_block_id: null
          })}>
            <div>+ Add button</div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="transfer-header">
        <div className="settings-item-label">{t('web.create.botBuilder.transferMessage.title')}</div>
        <Switch isOn={currentNode.content.transfer_to_operator}
                onClick={() => handleNodeChange(!currentNode.content.transfer_to_operator, 'transfer_to_operator')}/>
      </div>
      <div className="error-info-client">
        {errors && errors.transferToOperator && (
          <div className="error-message">{errors.transferToOperator}</div>
        )}
      </div>
      {currentNode.content.transfer_to_operator && (
        <div className="transfer-settings-w">
          <BlockBuilderSections sections={currentNode.content.transfer_sections || []}
                                textareaPlaceholderField="web.create.botBuilder.transferMessage.placeholder"
                                onAdd={section => {
                                  if (errors && errors.transferToOperator) {
                                    setErrors({...errors, transferToOperator: null});
                                  }
                                  onChangeNode(currentNode.content.transfer_sections ?
                                    {content: {transfer_sections: {$push: [section]}}} : {content: {$merge: {transfer_sections: [section]}}})
                                }}
                                onDelete={(i) => onChangeNode({content: {transfer_sections: {$splice: [[i, 1]]}}},
                                  currentNode.content.transfer_sections.length === 1)}
                                onChange={(data, i) => onChangeNode({content: {transfer_sections: {[i]: {$merge: data}}}})}
          />
        </div>
      )}
      <ActionButtons onSave={save} onCancel={onCancel}/>
    </Fragment>
  );
}