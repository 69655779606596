import React, {Fragment, useState, useEffect} from 'react';
import update from 'immutability-helper';
import _ from 'lodash';

import StepChain from '../StepChain';
import Header from 'components/Header';
import Workspace from 'components/widgets/Workspace';
import Checkbox from 'components/widgets/Checkbox';
import CustomSelect from 'components/widgets/CustomSelect';
import ErrorBlock from 'components/widgets/ErrorBlock';
import Switch from 'components/widgets/Switch';
import {useCampaigns, useOperators, useStateWidgets} from 'hooks';
import {t, getOperatorName} from 'utils';
import settings from 'settings';
import SubmitButton from "../../../widgets/SubmitButton";


export default function WelcomeMessage(props) {
  const queryArgPrefix = settings.SERVICE_BRAND_NAME.toLowerCase();
  const {currentStep, isCreate, type, setCurrentStep, campaign, setCampaign, onSave} = props;

  const askEmailOptions = {
    immediately: {label: 'web.create.welcomeMessage.customize.askEmail.immediately', value: 'immediately'},
    no_responds: {label: 'web.create.welcomeMessage.customize.askEmail.noResponds', value: 'no_responds'},
    do_not: {label: 'web.create.welcomeMessage.customize.askEmail.doNot', value: 'do_not'}
  };

  const {operatorsById} = useOperators();
  const {currentWidget} = useStateWidgets();
  const {isCreating, isUpdating} = useCampaigns();

  const [localOperators, setLocalOperators] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedUsers, setSelectedUsers] = useState(campaign.welcome_message.active_operator_ids || null);

  const [isUserSelect, toggleUserSelect] = useState(false);
  const [errors, setErrors] = useState({emptyTeam: null, campaignName: null});

  useEffect(() => {
    if (!selectedUsers) {
      setSelectedUsers(campaign.welcome_message.active_operator_ids || []);
    }
  }, [campaign.welcome_message]);

  useEffect(() => {
    if (operatorsById) {
      const operators = Object.values(operatorsById).reduce((arr, cur) => {
        arr.push(cur);
        return arr;
      }, []);
      setLocalOperators(update(localOperators, {$set: operators}));
    }
  }, [operatorsById]);

  useEffect(() => {
    let searchResult = Object.values(operatorsById).filter(operator =>
      operator.name && operator.name.includes(searchInput.trim())
      || operator.user && operator.user.name && operator.user.name.includes(searchInput.trim()));

    setLocalOperators(update(localOperators, {$set: searchResult}));
  }, [searchInput]);

  const selectedUserToggle = (id) => {
    if (errors.emptyTeam) {
      setErrors({...errors, emptyTeam: null});
    }
    if (selectedUsers.indexOf(id) !== -1) {
      setSelectedUsers(update(selectedUsers, {
        $splice: [[selectedUsers.indexOf(id), 1]]
      }));
    } else {
      setSelectedUsers(update(selectedUsers, {
        $push: [id]
      }));
    }
  };

  const changeCampaign = (field, value) => {
    setCampaign(update(campaign, {
      welcome_message: campaign.welcome_message[field] ? {[field]: {$set: value}} : {$merge: {[field]: value}}
    }));
  };

  const changeHeadCampaign = (field, value) => {
    if (campaign.welcome_message.heading_text) {
      changeCampaign('heading_text', {...campaign.welcome_message.heading_text, [field]: value});
    } else {
      changeCampaign('heading_text', {[field]: value});
    }
  };

  const handleCampaignSave = () => {
    let gettingErrors = {};
    if (selectedUsers.length === 0) {
      gettingErrors = {...gettingErrors, emptyTeam: t('web.create.welcomeMessage.customize.team.error')};
    }
    if (!campaign.name) {
      gettingErrors = {...gettingErrors, campaignName: 'Field empty'};
    }

    if (!(_.isEmpty(gettingErrors))) {
      setErrors(gettingErrors);
      return;
    }
    setCampaign(update(campaign, {
      welcome_message: campaign.welcome_message.active_operator_ids ?
        {active_operator_ids: {$set: selectedUsers}} : {$merge: {active_operator_ids: selectedUsers}}
    }));
    if (isCreate) {
      setCurrentStep(currentStep + 1);
    } else {
      onSave(campaign.enabled, false);
    }

  };

  const handleCurrentStepToggle = (step) => {
    if (campaign.name) {
      setCurrentStep(step);
    } else {
      setErrors({...errors, campaignName: 'Field empty'});
    }
  };

  return (
    <Fragment>
      <Header title={t(`web.create.welcomeMessage.header${campaign && campaign.id ? '.edit' : ''}`)}/>
      <div className="page-content normal-content">
        <div className="w-form">
          <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
            <StepChain type={type} currentStep={currentStep} campaign={campaign} setCurrentStep={handleCurrentStepToggle}/>
            <div className="content-white-block">
              <div>
                <div className="error-info-client">
                  <label htmlFor="name">{t('web.create.welcomeMessage.name.label')}</label>
                  <ErrorBlock error={errors.campaignName} addClass="align-right margin-bottom"/>
                </div>
                {t('web.create.welcomeMessage.name.label', {},
                  msg => <input type="text" value={campaign.name}
                                onChange={e => {
                                  if (errors && errors.campaignName) {
                                    setErrors({...errors, campaignName: null});
                                  }
                                  setCampaign(update(campaign, {
                                    name: {$set: e.target.value}
                                  }))
                                }}
                                className="node-settings-input input w-input" maxLength="256"
                                placeholder={msg} id="name"/>)}
              </div>
            </div>
            <div className="page-content-header-div hor-flex">
              <div>
                <div className="page-header">{t('web.create.welcomeMessage.customize.title')}</div>
                <div className="page-subheader">{t('web.create.welcomeMessage.customize.notice')}</div>
              </div>
              {isCreate ? (
                <div onClick={handleCampaignSave} className="button left-indent w-button">
                  {t('web.create.welcomeMessage.customize.button.goSettings')}
                </div>
              ) : (
                <SubmitButton simpleButton={true} additionalClass={'left-indent w-button'}
                              onClick={handleCampaignSave}
                              isSending={isCreating || isUpdating}
                              onLoadEnd={() => {window.location = '/campaigns';}}
                              text={t(`button.save`)}/>
              )}

            </div>
            <div className="group-row">
              <div className="content-white-block grow-w">
                <div className="show-group-content no-indent">
                  <div className="show-group-div">
                    <div className="group-row">
                      <div className="col-6">
                        <div className="label-w">
                          <div className="label-div">
                            <div className="settings-item-label add-margin empty">
                              {t('web.create.welcomeMessage.customize.team.label')}
                            </div>
                            <div className="label-div">
                              <div className="settings-item-label add-margin">Choose manager(s):</div>
                              <div className="toggle-w">
                                <div className="tooltip-div">
                                  <div className="info-text no-indent">Add a custom image or logo to give your chat a personalized touch</div>
                                  <div className="tooltip-arrow"></div>
                                </div>
                                <div className="awes-icon tooltip-icon"></div>
                              </div>
                            </div>
                          </div>
                          <ErrorBlock error={errors.emptyTeam}/>
                        </div>
                        <div data-delay="0" className="dropdown-like-select w-dropdown" tabIndex={-1}
                             onBlur={(e) => {
                               if (!e.relatedTarget || (e.relatedTarget &&
                                 (e.relatedTarget.type !== "checkbox" && e.relatedTarget.type !== "text"))) {
                                 toggleUserSelect(false)
                               }
                             }}>
                          <div className="dropdown-toggle w-dropdown-toggle multi-dropdown-toggle"
                               style={{minHeight: '45px'}} onClick={() => {toggleUserSelect(!isUserSelect)}}>
                            <div className="icon w-icon-dropdown-toggle"></div>
                            {!selectedUsers || (selectedUsers && selectedUsers.length === 0) ? (
                              <>Select manager...</>
                            ) : (
                              <>
                                {selectedUsers.map((userId, i) => {
                                  if (!operatorsById[userId]) {
                                    return;
                                  }
                                  const operator = operatorsById[userId];
                                  const name = getOperatorName(operator, i);
                                  return (
                                    <div key={userId} id={`chatbotter-card-operator-${userId}`} className="user-tag-w additional-margin">
                                      <div className="tag-icons"></div>
                                      <div className="tag-text">{name}</div>
                                      <div className="tag-icons" onClick={() => selectedUserToggle(userId)}></div>
                                    </div>)
                                })}
                              </>
                            )}
                          </div>
                          <nav className={`dropdown-list add-padding w-dropdown-list ${isUserSelect ? 'w--open' : ''}`}>
                            <input id="chatbotter-search-operator-field" type="text" className="search-field w-input" maxLength="256"
                                   onChange={e => {
                                     setSearchInput(e.target.value);
                                   }}
                                   placeholder="Search here..." />
                            <div className="search-block">
                              <label className="settings-item-label">User</label>
                              <div className="search-items height-250-px">
                                {localOperators.map((operator, i) => {
                                  const name = getOperatorName(operator, i);
                                  return (<Checkbox name={'client-info'}
                                                    label={name}
                                                    checked={selectedUsers.indexOf(operator.id) !== -1}
                                                    onChange={() => {
                                                      selectedUserToggle(operator.id);
                                                    }} />)
                                })}
                              </div>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div className="group-row">
                      <div className="col-6">
                        <div className="settings-item-label add-margin">
                          {t('web.create.welcomeMessage.head.label')}
                        </div>
                        {t('web.create.welcomeMessage.head.title.placeholder', {},
                          msg => <input type="text" className="node-settings-input w-input" maxLength="256"
                                        value={campaign.welcome_message.heading_text ?
                                          campaign.welcome_message.heading_text.title : ''}
                                        onChange={e => changeHeadCampaign('title', e.target.value)}
                                        placeholder={msg}/>)}
                        {t('web.create.welcomeMessage.head.subtitle.placeholder', {},
                          msg => <input type="text" className="node-settings-input w-input" maxLength="256"
                                        value={campaign.welcome_message.heading_text ?
                                          campaign.welcome_message.heading_text.subtitle : ''}
                                        onChange={e => changeHeadCampaign('subtitle', e.target.value)}
                                        placeholder={msg}/>)}
                      </div>
                      <div className="col-6"></div>
                    </div>
                  </div>
                </div>
                <div className="show-group-content">
                  <div className="show-group-div">
                    <div className="group-row">
                      <div className="col-6">
                        <div className="label-w">
                          <div className="label-div">
                            <div className="settings-item-label add-margin">
                              {t('web.create.welcomeMessage.customize.message.label')}
                            </div>
                            <div className="toggle-w">
                              <div className="tooltip-div tooltip-animation">
                                <div className="info-text no-indent">
                                  {t('web.create.welcomeMessage.customize.message.tooltip')}
                                </div>
                                <div className="tooltip-arrow"></div>
                              </div>
                              <div className="awes-icon tooltip-icon"></div>
                            </div>
                          </div>
                          <div className="error-message info-message">313</div>
                        </div>
                        {t('web.create.welcomeMessage.customize.message.placeholder', {},
                          msg => <textarea placeholder={msg} maxLength="5000"
                                           value={campaign.welcome_message.text || 'Got any questions? I\'m happy to help.'}
                                           onChange={e => changeCampaign('text', e.target.value)}
                                           className="node-settings-input textarea w-input"></textarea>)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="show-group-content">
                  <div className="group-w-header">
                    <div>
                      <div className="group-title">
                        {t('web.create.welcomeMessage.customize.autoAssign.label')}
                      </div>
                      <div className="page-subheader">
                        {t('web.create.welcomeMessage.customize.autoAssign.notice')}</div>
                    </div>
                    <Switch isOn={campaign.welcome_message.auto_assign_conversations}
                            onClick={() =>
                              changeCampaign('auto_assign_conversations', !campaign.welcome_message.auto_assign_conversations)}/>
                  </div>
                </div>
                <div className="show-group-content">
                  <div className="show-group-div">
                    <div className="group-row">
                      <div className="col-6">
                        <div className="label-w">
                          <div className="label-div">
                            <div className="settings-item-label add-margin">Ask visitors for their email address</div>
                          </div>
                        </div>
                        <CustomSelect options={Object.values(askEmailOptions)}
                                      defaultValue={campaign.welcome_message.ask_email_option ?
                                        askEmailOptions[campaign.welcome_message.ask_email_option] : askEmailOptions['immediately']}
                                      selectClassName={`settings-select w-select`}
                                      getOptionLabel={option => t(option.label)}
                                      onChange={type => changeCampaign('ask_email_option', type.value)}/>
                      </div>
                    </div>
                    <div className="group-row">
                      <div className="col-6">
                        <div className="label-w">
                          <div className="label-div">
                            <div className="settings-item-label add-margin">
                              {t('web.create.welcomeMessage.customize.emailCapture.label')}
                            </div>
                          </div>
                          <div className="error-message info-message">201</div>
                        </div>
                        {t('web.create.welcomeMessage.customize.emailCapture.placeholder', {},
                          msg => <textarea placeholder={msg} maxLength="5000"
                                           value={campaign.welcome_message.ask_email_message}
                                           onChange={e => changeCampaign('ask_email_message', e.target.value)}
                                           className="node-settings-input textarea w-input"></textarea>)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {currentWidget && currentWidget.settings && (
                <Workspace
                  widgetSettings={{...currentWidget.settings,
                    edited_campaign: {...campaign,
                      edited_operator: operatorsById,
                      welcome_message: {...campaign.welcome_message, active_operator_ids: selectedUsers || []}
                  }}}
                  url={`/widget.html?${queryArgPrefix}_mode=`}
                  widgetType='widget'
                  modes={[
                    {value: 'RMFqc1Hqq1', label: 'welcome_message'},
                  ]}
                />
              )}
            </div>
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}