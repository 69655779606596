import React, {useCallback} from 'react';
import {useSelector, useDispatch, shallowEqual } from 'react-redux';
import {fetchLiveVisitors, setLiveVisitorsCampaignTypeFilter} from 'actions';


export const useLiveVisitors = () => {
  const liveVisitorsById = useSelector(state => state.liveVisitors.liveVisitorsById, shallowEqual);
  const liveVisitorsCount = useSelector(state => state.liveVisitors.liveVisitorsCount, shallowEqual);
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const selectedCampaignType = useSelector(state => state.liveVisitors.selectedCampaignType, shallowEqual);
  const isFetching = useSelector(state => state.liveVisitors._isLiveVisitorsFetching, shallowEqual);

  const dispatch = useDispatch();
  const getLiveVisitors = useCallback((params) => {
    dispatch(fetchLiveVisitors({
      is_online: true,
      send_count: true,
      widget_id: currentWidgetId,
      campaign_type: selectedCampaignType.includedTypes.join(','),
      sort: '-created_at',
      ...params
    }))
  }, [dispatch, currentWidgetId, selectedCampaignType]);

  return {liveVisitorsById, liveVisitorsCount, getLiveVisitors, isFetching};
};


export const useLiveVisitorsCampaignTypeFilter = () => {
  const selectedCampaignType = useSelector(state => state.liveVisitors.selectedCampaignType, shallowEqual);

  const dispatch = useDispatch();
  const setSelectedCampaignType = useCallback(value => {dispatch(setLiveVisitorsCampaignTypeFilter(value))}, [dispatch]);

  return {selectedCampaignType, setSelectedCampaignType}
};