import React, { useEffect } from 'react';
import {useDropzone} from 'react-dropzone';

export default function ButtonImageLoader(props) {
  const {text, onChange, img, type, preview = null} = props;
  const loadType = type || 'image';
  const {getRootProps, getInputProps} = useDropzone({
    accept: `${loadType}/*`,
    onDrop: acceptedFiles => {
      onChange({file: acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)        
      }))[0]});
    }
  });

  return (
    <>
      {preview && ( <div className="icon-image" style={{backgroundImage: `url('${preview}')`}}></div>)}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
       {/*<img className="col-1 icon-image" src={preview}></img>*/}
        <a href="#" className="blue-button facebook-btn right-margin w-button">
          {img && img}
          {text}
        </a>
      </div>
    </>
  )
};