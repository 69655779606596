import React from 'react';
import {BOT_SECTION_TYPES} from 'constants.js';

const mime = require('mime-types');

export const CompoundMessage = (props) => {
  const {section } = props;
  switch (section.type) {
    case BOT_SECTION_TYPES.file: {
      if (!(section.file && section.file.url)) {
        return null;
      }
      return (section.file && section.file.url &&
        <img className="iframe-in-message margin-attachment-bottom" src={section.file.url} onClick={e => {
          e.preventDefault();
          window.open(e.target.src, '_blank');
        }}/>
      );
    }
    case BOT_SECTION_TYPES.link: {
      if (section.link.includes('youtube') || section.link.includes('youtu.be')) {
        let link = section.link;

        if (section.link.includes('https://youtu.be/')) {
          link = `https://www.youtube.com/embed/${section.link.slice('https://youtu.be/'.length)}`;
        }
        if (section.link.includes('https://www.youtube.com/watch?v=')) {
          link = `https://www.youtube.com/embed/${section.link.slice('https://www.youtube.com/watch?v='.length)}`;
        }
        return (
          <iframe className="iframe-in-message video-in-message margin-attachment-bottom" src={link}
                  frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
        );
      }
      if (section.link.includes('giphy')) {
        let link = section.link;

        if (section.link.includes('https://media.giphy.com/media/')) {
          link = `https://giphy.com/embed/${section.link.slice('https://media.giphy.com/media/'.length, section.lastIndexOf('/'))}`;
        }
        if (section.link.includes('https://giphy.com/gifs/')) {
          link = `https://giphy.com/embed/${section.link.slice('https://giphy.com/gifs/'.length)}`;
        }
        return (
          <iframe className="iframe-in-message video-in-message margin-attachment-bottom" width="480" height="420"
                  src={link} frameBorder="0" allowFullScreen></iframe>
        );
      }

      if (mime.lookup(section.link) && mime.lookup(section.link).includes('image')) {
        return (
          <img className="iframe-in-message margin-attachment-bottom" src={section.link} onClick={e => {
            e.preventDefault();
            window.open(e.target.src, '_blank');
          }}/>
        );
      }

      if (mime.lookup(section.link) && mime.lookup(section.link).includes('video') &&
        (mime.lookup(section.link).includes('mp4') || mime.lookup(section.link).includes('ogg') || mime.lookup(section.link).includes('webm'))) {
        return (
          <video className="iframe-in-message margin-attachment-bottom video-in-message" src={section.link} controls/>
        );
      }

      return (
        <a href={section.link} target="_blank">{section.link}</a>
      );
    }
    case BOT_SECTION_TYPES.text: {
      return (
        <span>{section.text}</span>
      );
    }
    default: {
      if (section.link && mime.lookup(section.link) && mime.lookup(section.link).includes('image')) {
        return (
          <img className="iframe-in-message margin-attachment-bottom" src={section.link} onClick={e => {
            e.preventDefault();
            window.open(e.target.src, '_blank');
          }}/>
        );
      }

      return null;
    }
  }
};
