import React, { Component, useEffect, useState } from 'react';
import update from 'immutability-helper';
import _ from 'lodash';
import moment from 'moment';
import Header from 'components/Header';
import Chart from 'components/widgets/Chart';
import CustomSelect from 'components/widgets/CustomSelect';
import IntlWrapper from 'components/widgets/IntlWrapper';
import DateRange from 'components/widgets/DateRange';
import {useAnalytics, useCampaigns, useOperators} from 'hooks';
import {main, analytics} from 'translate';
import {t, translateAssign, getOperatorName, getGranularity} from 'utils';
import LanguageFactory from 'api/LanguageFactory';
import {ANALYTICS_TYPES, CHANNELS_OPTIONS,
  WIDGET_CHART_LINES, FACEBOOK_CHART_LINES} from 'constants.js';


export default function Analytics(props) {
  const messages = [main, analytics];
  const [campaignType, setCampaignType] = useState(Object.values(CHANNELS_OPTIONS)[0]);
  const [analyticsType, setAnalyticsType] = useState(Object.values(ANALYTICS_TYPES)[0]);

  return (
    <IntlWrapper messages={messages}>
      <Header title={t('title')}>
        <AnalyticsHeader campaignType={campaignType} setCampaignType={setCampaignType}/>
      </Header>
      <div className="page-content normal-content">
        <div className="w-form">
          <form id="email-form-5" name="email-form-5" data-name="Email Form 5">
            {CHANNELS_OPTIONS.facebookMessenger.includedTypes.includes(campaignType.value) ? (
              <FacebookAnalytics campaignType={campaignType} analyticsType={campaignType.value}/>
            ) : (
              <div data-duration-in="300" data-duration-out="100" className="a-tabs w-tabs">
                <div className="a-tabs-menu w-tab-menu">
                  {Object.values(ANALYTICS_TYPES).map(tab =>
                    <a key={tab.value} onClick={() => {
                      if (analyticsType.value !== tab.value) {
                        setAnalyticsType(tab);
                      }
                    }}
                       className={`a-tab-link w-inline-block w-tab-link tab-margin ${analyticsType.value === tab.value ? 'w--current' : ''}`}>
                      <div>{t(tab.label)}</div>
                    </a>
                  )}
                </div>
                <div className="a-tabs-content w-tab-content">
                  <div className="w-tab-pane w--tab-active">
                    <div className="campaigns-preview-w">
                      {analyticsType.value === Object.values(ANALYTICS_TYPES)[0].value && (
                        <ChatAnalytics campaignType={campaignType} analyticsType={analyticsType.value}/>
                      )}
                      {analyticsType.value === Object.values(ANALYTICS_TYPES)[1].value && (
                        <OperatorAnalytics campaignType={campaignType} analyticsType={analyticsType.value}/>
                      )}
                      {analyticsType.value === Object.values(ANALYTICS_TYPES)[2].value && (
                        <CampaignAnalytics campaignType={campaignType} analyticsType={analyticsType.value}/>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </IntlWrapper>
  );
}

const AnalyticsHeader = (props) => {
  const {campaignType, setCampaignType} = props;
  const [isChannelTypesOpen, setChannelTypesOpen] = useState(false);

  return (
    <div className="filers-w">

      <div className="filter-drop-w right-align">
        <div className="filter-drop w-dropdown" onMouseOver={() => setChannelTypesOpen(true)}
             onMouseOut={() => setChannelTypesOpen(false)}>
          <div className="filter-drop-tog w-dropdown-toggle">
            <div>{campaignType && campaignType.includedTypes.length ? t(campaignType.label) : t('header.channel.title')}</div>
            <div className="drop-icon w-icon-dropdown-toggle"></div>
          </div>
          <nav className={`dropdown-list right-float-list w-dropdown-list ${isChannelTypesOpen ? 'w--open' : ''}`}>
            {Object.values(CHANNELS_OPTIONS).map((option, i) =>
              <div key={i} onClick={() => {
                setCampaignType(option);
                setChannelTypesOpen(false);
              }} className="dropdown-link w-dropdown-link">
                {t(option.label)}</div>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

const ChatAnalytics = (props) => {
  const {campaignType, analyticsType} = props;
  const translate = translateAssign(main, analytics)[LanguageFactory.getLocale()];
  const chartSettings = {
    lines: {
      [WIDGET_CHART_LINES.totalChats]: {name: translate['analytics.chats.statistics.chats'],
        color: '#26b94c', key: WIDGET_CHART_LINES.totalChats},
      [WIDGET_CHART_LINES.totalMessages]: {name:  translate['analytics.chats.statistics.messages'],
        color: '#006ef1', key: WIDGET_CHART_LINES.totalMessages},
      [WIDGET_CHART_LINES.visitorMessages]: {name:  translate['analytics.chats.statistics.visitor.messages'],
        color: '#f19e17', key: WIDGET_CHART_LINES.visitorMessages},
      [WIDGET_CHART_LINES.operatorMessages]: {name:  translate['analytics.chats.statistics.operator.messages'],
        color: '#6a3fe7', key: WIDGET_CHART_LINES.operatorMessages},
    }
  };
  const {chatsChartData, getAnalytics} = useAnalytics();
  const [dateRange, setDateRange] = useState({});

  useEffect(() => {
    if (Object.keys(dateRange).length) {
      const granularityInfo = getGranularity({
        start_datetime: dateRange.created_at__gte,
        end_datetime: dateRange.created_at__lte,
      });
      getAnalytics({
          start_datetime: dateRange.created_at__gte,
          end_datetime: dateRange.created_at__lte,
          granularity: granularityInfo.granularity,
          campaign_type: campaignType.includedTypes.length ? campaignType.includedTypes.join(',') : 'all'
        },
        analyticsType, granularityInfo.tickFormat);
    }
  }, [campaignType, dateRange]);


  return (
    <>
      <div className="page-content-header-div hor-flex">
        <div className="div-block-2">
          <div className="page-header right-indent">{t('analytics.chats.title')}</div>
        </div>
      </div>
      <div className="content-white-block hor-flex-2">
        <div className="table-filter-block">
          <DateRange onChangeRange={setDateRange}/>
        </div>
      </div>
      {chatsChartData ? (
        <Chart data={chatsChartData} chartSettings={chartSettings}/>
      ): (
        <div className="no-data">No data</div>
      )}
      <div className="campaign-preview-item">
        <div className="cp-info-block">
          <div className="cp-info-item">
            <div className="cp-info-header">
              <span className="cp-info-header-icon"></span>{t('analytics.chats.statistics.chats')}</div>
            {chatsChartData && chatsChartData.totalCounts ? (
              <div className="cp-info-count green">{chatsChartData.totalCounts[WIDGET_CHART_LINES.totalChats]}</div>
            ) : (
              <div className="cp-info-count green">0</div>
            )}
          </div>
          <div className="cp-info-item border-left">
            <div className="cp-info-header">
              <span className="cp-info-header-icon"></span>{t('analytics.chats.statistics.messages')}</div>
            {chatsChartData && chatsChartData.totalCounts ? (
              <div className="cp-info-count blue">{chatsChartData.totalCounts[WIDGET_CHART_LINES.totalMessages]}</div>
            ) : (
              <div className="cp-info-count blue">0</div>
            )}
          </div>
          <div className="cp-info-item border-left">
            <div className="cp-info-header">
              <span className="cp-info-header-icon"></span>{t('analytics.chats.statistics.visitor.messages')}</div>
            {chatsChartData && chatsChartData.totalCounts ? (
              <div className="cp-info-count orange">{chatsChartData.totalCounts[WIDGET_CHART_LINES.visitorMessages]}</div>
            ) : (
              <div className="cp-info-count orange">0</div>
            )}
          </div>
          <div className="cp-info-item border-left">
            <div className="cp-info-header">
              <span className="cp-info-header-icon"></span>{t('analytics.chats.statistics.operator.messages')}</div>
            {chatsChartData && chatsChartData.totalCounts ? (
              <div className="cp-info-count violet">{chatsChartData.totalCounts[WIDGET_CHART_LINES.operatorMessages]}</div>
            ) : (
              <div className="cp-info-count violet">0</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const OperatorAnalytics = (props) => {
  const {campaignType, analyticsType} = props;
  const sortTypes = {
    messages: {label: 'analytics.operators.sort.messages', value: 'messages'},
    reaction_time: {label: 'analytics.operators.sort.reaction', value: 'reaction_time'},
  };

  const {operatorData, getAnalytics} = useAnalytics();
  const {operatorsById} = useOperators();
  const [operators, setOperators] = useState(null);
  const [totals, setTotal] = useState({});
  const [dateRange, setDateRange] = useState({});
  const [sort, setSort] = useState(Object.values(sortTypes)[0]);

  useEffect(() => {
    if (operatorData.operators){
      setOperators(operatorData.operators);
      setTotal(operatorData.totals);
    }
  }, [operatorData]);
  useEffect(() => {
    if (Object.keys(dateRange).length) {
      const granularityInfo = getGranularity({
        start_datetime: dateRange.created_at__gte,
        end_datetime: dateRange.created_at__lte,
      });
      getAnalytics({
          start_datetime: dateRange.created_at__gte,
          end_datetime: dateRange.created_at__lte,
          sorted_by: sort.value,
          granularity: granularityInfo.granularity,
          campaign_type: campaignType.includedTypes.length ? campaignType.includedTypes.join(',') : 'all'
        },
        analyticsType, granularityInfo.tickFormat);
    }
  }, [campaignType, dateRange, sort]);

  return (
    <>
      <div className="page-content-header-div hor-flex">
        <div className="div-block-2">
          <div className="page-header right-indent">{t('analytics.operators.title')}</div>
        </div>
      </div>
      <div className="content-white-block hor-flex-2">
        <div className="table-filter-block">
          <CustomSelect options={Object.values(sortTypes)}
                        defaultValue={sort}
                        value={sort}
                        selectClassName={`settings-select no-indent max-w w-select`}
                        getOptionLabel={option => t(option.label)}
                        getOptionValue={option => option.value}
                        onChange={range => setSort(range)}/>
          <div className="filter-drop no-indent-2 display-flex">
            <DateRange onChangeRange={setDateRange}/>
          </div>
        </div>
      </div>
      {operators && Object.values(operators).length ? (
        <>
          {Object.values(operators).map(operator => {
          const operatorUser = operatorsById[operator.id].user || {};
          const avatarUrl = operatorUser && operatorUser.avatar && operatorUser.avatar.url ? operatorUser.avatar.url
            : "https://uploads-ssl.webflow.com/5dce49288763a1a0ced603c6/5dce49288763a1ca4bd60419_bot-img.png";
          return (
            <div key={operator.id} className="campaign-preview-item">
              <div className="cp-info-w">
                <div className="cp-avatar-div">
                  <img src={avatarUrl}
                       alt="" className="cp-avatar"/>
                </div>
                <div className="cp-name-div">
                  <div className="cp-name-text">{getOperatorName(operatorsById[operator.id])}</div>
                </div>
              </div>
              <div className="cp-info-block">
                <div className="cp-info-item">
                  <div className="cp-info-header">{t('analytics.operators.statistics.chats')}</div>
                  <div className="cp-info-count">
                    {operator.chats} / {totals.chats ? (100 * (operator.chats / totals.chats)).toFixed() : 0}%</div>
                </div>
                <div className="cp-info-item border-left">
                  <div className="cp-info-header">{t('analytics.operators.statistics.messages')}</div>
                  <div className="cp-info-count">
                    {operator.messages} / {totals.messages ? (100 * (operator.messages / totals.messages)).toFixed() : 0}%
                  </div>
                </div>
                <div className="cp-info-item border-left">
                  <div className="cp-info-header">{t('analytics.operators.statistics.reactionTime')}</div>
                  <div className="cp-info-count">
                    {operator.average_reaction_time ? Math.round(operator.average_reaction_time * 100) / 100 : 0} sec</div>
                </div>
              </div>
            </div>
          );
          })}
        </>
      ) : (
        <div className="no-data">No data</div>
      )}
    </>
  );
};

const CampaignAnalytics = (props) => {
  const {campaignType, analyticsType} = props;
  const translate = translateAssign(main, analytics)[LanguageFactory.getLocale()];
  const chartSettings = {
    lines: {
      [WIDGET_CHART_LINES.campaignMessages]: {name: translate['analytics.campaigns.statistics.messages'],
        color: '#333', key: WIDGET_CHART_LINES.campaignMessages},
    }
  };

  const {campaigns, getCampaigns} = useCampaigns();
  const {campaignsChartData, getAnalytics} = useAnalytics();
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [dateRange, setDateRange] = useState({});

  useEffect(() => {
    getCampaigns({type: campaignType.includedTypes.length ? campaignType.includedTypes.join(',') : null});
  }, [campaignType]);

  useEffect(() => {
    if (Object.keys(campaigns).length && !_.isEqual(selectedCampaign, Object.values(campaigns)[0])) {
      setSelectedCampaign(Object.values(campaigns)[0]);
    }
  }, [campaigns]);

  useEffect(() => {
    if (selectedCampaign && Object.keys(dateRange).length) {
      const granularityInfo = getGranularity({
        start_datetime: dateRange.created_at__gte,
        end_datetime: dateRange.created_at__lte,
      });

      getAnalytics({
          start_datetime: dateRange.created_at__gte,
          end_datetime: dateRange.created_at__lte,
          campaign_id: selectedCampaign.id,
          granularity: granularityInfo.granularity,
          campaign_type: campaignType.includedTypes.length ? campaignType.includedTypes.join(',') : 'all'
        },
        analyticsType, granularityInfo.tickFormat);
    }
  }, [campaignType, dateRange, selectedCampaign]);

  return (
    <>
      <div className="page-content-header-div hor-flex">
        <div className="div-block-2">
          <div className="page-header right-indent">{t('analytics.campaigns.title')}</div>
        </div>
      </div>
      <div className="content-white-block hor-flex-2">
        <div className="table-filter-block">
          <CustomSelect options={Object.values(campaigns)}
                        defaultValue={selectedCampaign}
                        value={selectedCampaign}
                        selectClassName={`settings-select no-indent max-w w-select`}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        onChange={campaign => setSelectedCampaign(campaign)}/>
          <div className="filter-drop no-indent-2 display-flex">
            <DateRange onChangeRange={setDateRange}/>
          </div>
        </div>
      </div>
      {campaignsChartData && selectedCampaign ? (
        <Chart data={campaignsChartData} chartSettings={chartSettings}/>
      ) : (
        <div className="no-data">No data</div>
      )}
      <div className="campaign-preview-item">
        <div className="cp-info-block">
          <div className="cp-info-item">
            <div className="cp-info-header">
              <span className="cp-info-header-icon"></span>{t('analytics.campaigns.statistics.messages')}</div>
            {selectedCampaign && campaignsChartData && campaignsChartData.totalCounts ? (
              <div className="cp-info-count">{campaignsChartData.totalCounts[WIDGET_CHART_LINES.campaignMessages]}</div>
            ) : (
              <div className="cp-info-count">0</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const FacebookAnalytics = (props) => {
  const {campaignType, analyticsType} = props;

  const translate = translateAssign(main, analytics)[LanguageFactory.getLocale()];
  const chartSettings = {
    lines: {
      [FACEBOOK_CHART_LINES.chats]: {name: translate['analytics.facebook.statistics.chats'],
        color: '#26b94c', key: FACEBOOK_CHART_LINES.chats},
      [FACEBOOK_CHART_LINES.leads]: {name:  translate['analytics.facebook.statistics.leads'],
        color: '#006ef1', key: FACEBOOK_CHART_LINES.leads},
      [FACEBOOK_CHART_LINES.finished]: {name:  translate['analytics.facebook.statistics.finished'],
        color: '#f19e17', key: FACEBOOK_CHART_LINES.finished},
      [FACEBOOK_CHART_LINES.subscribes]: {name:  translate['analytics.facebook.statistics.subscribes'],
        color: '#6a3fe7', key: FACEBOOK_CHART_LINES.subscribes},
      [FACEBOOK_CHART_LINES.unsubscribes]: {name:  translate['analytics.facebook.statistics.unsubscribes'],
        color: '#6a3fe7', key: FACEBOOK_CHART_LINES.unsubscribes},
      [FACEBOOK_CHART_LINES.activeSubscribes]: {name:  translate['analytics.facebook.statistics.activeSubscribes'],
        color: '#6a3fe7', key: FACEBOOK_CHART_LINES.activeSubscribes},
    }
  };
  const {campaigns, getCampaigns} = useCampaigns();
  const {chatsChartData, getAnalytics} = useAnalytics();
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [dateRange, setDateRange] = useState({});

  useEffect(() => {
    getCampaigns({type: campaignType.includedTypes.length ? campaignType.includedTypes.join(',') : null});
  }, [campaignType]);


  useEffect(() => {
    if (Object.keys(campaigns).length && !_.isEqual(selectedCampaign, Object.values(campaigns)[0])) {
      setSelectedCampaign(Object.values(campaigns)[0]);
    }
  }, [campaigns]);

  useEffect(() => {
    if (Object.keys(dateRange).length) {
      const granularityInfo = getGranularity({
        start_datetime: dateRange.created_at__gte,
        end_datetime: dateRange.created_at__lte,
      });
      getAnalytics({
          start_datetime: dateRange.created_at__gte,
          end_datetime: dateRange.created_at__lte,
          granularity: granularityInfo.granularity,
          campaign_type: campaignType.includedTypes.length ? campaignType.includedTypes.join(',') : 'all'
        },
        analyticsType, granularityInfo.tickFormat);
    }
  }, [campaignType, dateRange]);

  return (
    <div className="campaigns-preview-w">
      <div className="page-content-header-div hor-flex justify-left">
        <div className="div-block-2">
          <div className="page-header right-indent">Messenger Bot overview</div>
        </div>
      </div>
      <div className="content-white-block hor-flex-2">
        <div className="table-filter-block no-indent">
          <CustomSelect options={Object.values(campaigns)}
                        defaultValue={selectedCampaign}
                        value={selectedCampaign}
                        selectClassName={`settings-select no-indent max-w w-select`}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        onChange={campaign => setSelectedCampaign(campaign)}/>
          <div className="filter-drop no-indent-2 display-flex">
            <DateRange onChangeRange={setDateRange}/>
          </div>
        </div>
      </div>
      {chatsChartData ? (
        <Chart data={chatsChartData} chartSettings={chartSettings}/>
      ): (
        <div className="no-data">No data</div>
      )}
      <div className="campaign-preview-item">
        <div className="cp-info-block">
          <div className="cp-info-item">
            <div className="cp-info-header">
              <span className="cp-info-header-icon"></span>{t('analytics.facebook.statistics.chats')}</div>
            <div className="cp-info-count green">10000</div>
          </div>
          <div className="cp-info-item border-left">
            <div className="cp-info-header">
              <span className="cp-info-header-icon"></span>{t('analytics.facebook.statistics.leads')}</div>
            <div className="cp-info-count blue">100000</div>
          </div>
          <div className="cp-info-item border-left">
            <div className="cp-info-header">
              <span className="cp-info-header-icon"></span>{t('analytics.facebook.statistics.finished')}</div>
            <div className="cp-info-count orange">50000</div>
          </div>
        </div>
      </div>
      {/*<div className="campaign-preview-item">*/}
      {/*  <div className="cp-info-block">*/}
      {/*    <div className="cp-info-item">*/}
      {/*      <div className="cp-info-header">*/}
      {/*        <span className="cp-info-header-icon"></span>{t('analytics.facebook.statistics.subscribes')}</div>*/}
      {/*      <div className="cp-info-count green">10000</div>*/}
      {/*    </div>*/}
      {/*    <div className="cp-info-item border-left">*/}
      {/*      <div className="cp-info-header">*/}
      {/*        <span className="cp-info-header-icon"></span>{t('analytics.facebook.statistics.unsubscribes')}</div>*/}
      {/*      <div className="cp-info-count blue">100000</div>*/}
      {/*    </div>*/}
      {/*    <div className="cp-info-item border-left">*/}
      {/*      <div className="cp-info-header">*/}
      {/*        <span className="cp-info-header-icon"></span>*/}
      {/*        <strong>{t('analytics.facebook.statistics.activeSubscribes')}</strong>*/}
      {/*      </div>*/}
      {/*      <div className="cp-info-count orange">50000</div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
};