import React, {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from 'moment';
import * as actions from 'actions';


export const useMeetings = () => {
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const meetingsToday = useSelector(state => state.meetings.meetingsToday, shallowEqual);
  const meetingSettings = useSelector(state => state.meetings.meetingSettings, shallowEqual);
  const isUpdateBookedMeetings = useSelector(state => state.meetings.isUpdateBookedMeetings, shallowEqual);
  const dispatch = useDispatch();

  const getMeetingsToday = useCallback((currentOperatorId) => {
    dispatch(actions.fetchMeetingsToday({
      widget_id: currentWidgetId,
      operator_id: currentOperatorId,
      start_meeting_datetime: moment().startOf('day').toISOString(),
      end_meeting_datetime: moment().endOf('day').toISOString()
    }));
  }, [dispatch, currentWidgetId]);

  const getMeetingSettings = useCallback(() => {
    dispatch(actions.fetchMeetingSettings(currentWidgetId));
  }, [dispatch, currentWidgetId]);

  const getMeetingLinks = useCallback((params = {}) => {
    dispatch(actions.fetchMeetingLinks({...params, widget_id: currentWidgetId}));
  }, [dispatch, currentWidgetId]);

  return {meetingsToday, meetingSettings, isUpdateBookedMeetings,
    getMeetingsToday, getMeetingLinks, getMeetingSettings};
};

export const useMeetingsToday = () => {
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const meetingsToday = useSelector(state => state.meetings.meetingsToday, shallowEqual);
  const dispatch = useDispatch();

  const copyMeetingToday = useCallback(() => {
    dispatch(actions.copyMeetingToday());
  }, [dispatch, currentWidgetId]);

  return {meetingsToday, copyMeetingToday};
};


export const useMeetingReports = () => {
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);

  const meetingLinksById = useSelector(state => state.meetings.meetingLinksById, shallowEqual);
  const meetingsChartData = useSelector(state => state.meetings.chartData, shallowEqual);
  const bookedMeetings = useSelector(state => state.meetings.bookedMeetings, shallowEqual);
  const bookedMeetingsCount = useSelector(state => state.meetings.bookedMeetingsCount, shallowEqual);
  const managersByBookedMeeting = useSelector(state => state.meetings.managersByBookedMeeting, shallowEqual);
  const managersByBookedMeetingCount = useSelector(state => state.meetings.managersByBookedMeetingCount, shallowEqual);

  const isBookedMeetingsFetching = useSelector(state => state.meetings._isBookedMeetingsFetching, shallowEqual);
  const isChartDataFetching = useSelector(state => state.meetings._isChartDataFetching, shallowEqual);
  const isManagersFetching = useSelector(state => state.meetings._isManagersFetching, shallowEqual);
  const isUpdateBookedMeetings = useSelector(state => state.meetings.isUpdateBookedMeetings, shallowEqual);

  const dispatch = useDispatch();

  const getMeetingsChartData = useCallback((params, tickFormat) => {
    dispatch(actions.fetchMeetingsChartData({...params, widget_id: currentWidgetId}, tickFormat));
  }, [dispatch, currentWidgetId]);

  const getBookedMeetings = useCallback((params = {}) => {
    dispatch(actions.fetchBookedMeetings({
      widget_id: currentWidgetId,
      ...params
    }));
  }, [dispatch, currentWidgetId]);

  const getManagerBookedMeetings = useCallback((params) => {
    dispatch(actions.fetchTopManagerBookedMeetings({
      widget_id: currentWidgetId,
      ...params
    }));
  }, [dispatch, currentWidgetId]);

  const exportBookedMeetings = useCallback((params = {}) => dispatch(actions.exportBookedMeetings({
    widget_id: currentWidgetId,
    ...params
  })), [dispatch, currentWidgetId]);

  return {meetingLinksById, meetingsChartData, bookedMeetings, managersByBookedMeeting,
    bookedMeetingsCount, managersByBookedMeetingCount, isUpdateBookedMeetings,
    isBookedMeetingsFetching, isChartDataFetching, isManagersFetching,
    getMeetingsChartData, getBookedMeetings, getManagerBookedMeetings, exportBookedMeetings};

};

export const useMeetingLinks = () => {
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const meetingLinksById = useSelector(state => state.meetings.meetingLinksById, shallowEqual);
  const meetingLinksCount = useSelector(state => state.meetings.meetingLinksCount, shallowEqual);
  const meetingSettings = useSelector(state => state.meetings.meetingSettings, shallowEqual);

  const isMeetingLinksFetching = useSelector(state => state.meetings._isMeetingLinksFetching, shallowEqual);
  const isMeetingLinkAdding = useSelector(state => state.meetings._isMeetingLinkAdding, shallowEqual);
  const isUpdateBookedMeetings = useSelector(state => state.meetings.isUpdateBookedMeetings, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isUpdateBookedMeetings) {
      dispatch(actions.updateBookedMeetingList(false));
    }
  }, [isUpdateBookedMeetings]);

  const addMeetingLink = useCallback((link) => {
    return dispatch(actions.addMeetingLink({...link, widget_id: currentWidgetId}));
  }, [dispatch, currentWidgetId]);

  const deleteMeetingLink = useCallback((linkId) => {
    dispatch(actions.deleteMeetingLink(linkId))
      .then(() => {
        dispatch(actions.updateBookedMeetingList(true));
      });
  }, [dispatch, currentWidgetId]);

  const editMeetingLink = useCallback((link, linkId) => {
    return dispatch(actions.editMeetingLink(link, linkId));
  }, [dispatch, currentWidgetId]);

  const copyMeetingLink = useCallback((link) => {
    dispatch(actions.copyMeetingLink(link));
  }, [dispatch, currentWidgetId]);

  const getMeetingLinks = useCallback((params = {}) => {
    return dispatch(actions.fetchMeetingLinks({...params, widget_id: currentWidgetId}));
  }, [dispatch, currentWidgetId]);

  //fetchMeetingLink
  const getMeetingLink = useCallback((linkId) => {
    dispatch(actions.fetchMeetingLink(linkId));
  }, [dispatch, currentWidgetId]);


  return {meetingLinksById, meetingLinksCount, meetingSettings, isMeetingLinksFetching, isMeetingLinkAdding,
    getMeetingLinks, getMeetingLink, addMeetingLink, deleteMeetingLink, editMeetingLink, copyMeetingLink};
};

export const useMeetingSettings = () => {
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const meetingSettings = useSelector(state => state.meetings.meetingSettings, shallowEqual);
  const isMeetingSettingsFetching = useSelector(state => state.meetings._isMeetingSettingsFetching, shallowEqual);
  const isUpdatingMeetingSettings = useSelector(state => state.meetings._isUpdatingMeetingSettings, shallowEqual);

  const dispatch = useDispatch();

  const updateMeetingSettings = useCallback(settings => dispatch(actions.updateMeetingSettings(settings, currentWidgetId)),
    [dispatch, currentWidgetId]);

  return {meetingSettings, isMeetingSettingsFetching, updateMeetingSettings, isUpdatingMeetingSettings};
};

export const useSendTestEmail = () => {
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const isMeetingSettingsSendingTest = useSelector(state => state.meetings._isMeetingSettingsSendingTest, shallowEqual);
  const dispatch = useDispatch();

  const sendTestEmail = useCallback((type, template) => {
    dispatch(actions.sendTestEmail(type, template, currentWidgetId));
  }, [dispatch, currentWidgetId]);

  return {isMeetingSettingsSendingTest, sendTestEmail};
};

export const useUpdateData = (callback) => {
  const isUpdateBookedMeetings = useSelector(state => state.meetings.isUpdateBookedMeetings, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUpdateBookedMeetings) {
      callback();
      dispatch(actions.updateBookedMeetingList(false));
    }
  }, [isUpdateBookedMeetings]);
};