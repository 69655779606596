import React, {useState, useRef, Fragment} from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import {useContactProperty} from 'hooks';
import {t, h} from 'utils';

export default function TextareaWithAddons(props) {
  const {value, propertiesHeight = '300px', placeholderField, isSmallTextarea, isNotDeleting, onChange, onDelete} = props;

  const [templateVisible, toggleTemplateVisible] = useState(false);
  const [isEmojiShow, showEmojiPicker] = useState(false);
  const textRef = useRef(null);
  const pickerRef = useRef(null);
  const showPickerRef = useRef(null);
  const {contactPropertiesByName} = useContactProperty();
  const addEmoji = e => {
    const emoji = e.native;
    onChange(value.splice(textRef.current.selectionStart, emoji, 0));
  };
  const addTemplate = property => {
    const template = property.type ? `contact_properties.${property.name}` : property.name;
    onChange(value.splice(textRef.current.selectionStart, `{${template}}`, 0));
  };

  return (
    <div className="settings-item add-z-index" id="textarea-addons">
      {t(placeholderField, {},
        msg => <textarea ref={textRef} placeholder={msg} maxLength="5000"
                         value={value} onChange={e => onChange(e.target.value)}
                         className={`textarea${isSmallTextarea ? ' small-message' : ''} w-input`}></textarea>)}
      {!isNotDeleting && (
        <div className="diagram-node-control settings-control node-control-animation">
          <div className="control-btn more-size w-inline-block" onClick={onDelete}>
            <div></div>
          </div>
        </div>
      )}
      <div className="input-control">
        {contactPropertiesByName && Object.values(contactPropertiesByName).length && (
          <div data-delay="0" className="control-btn w-dropdown" style={{zIndex: 11}}>
            <div className="toggle-control-btn w-dropdown-toggle" onClick={() => toggleTemplateVisible(!templateVisible)}>
              <div></div>
              <div className="drop-icon hide-icon w-icon-dropdown-toggle"></div>
            </div>
            {templateVisible && (
              <nav style={{height: propertiesHeight, overflow: 'auto'}} className="dropdown-list move-list w-dropdown-list w--open">
                {Object.values(contactPropertiesByName).map((property, i) => {
                  return (
                    <div key={i} className="dropdown-link w-dropdown-link" onClick={() => addTemplate(property)}>
                      <span className="awesome-icon fix-w"></span>  {property.name}</div>
                  );
                })}
              </nav>
            )}
          </div>
        )}
        <div className="control-btn more-size" data-ix="emoji-animate">
          <Picker ref={pickerRef} onSelect={addEmoji} showPreview={false} showSkinTones={false} set="twitter"
                  autoFocus={true}
                  style={{ position: 'absolute', width: '200px', bottom: '-180px', right: '30px', zIndex: 170,
                    display: isEmojiShow ? 'block' : 'none'}}
                  include={['search','recent','people', 'objects', 'symbols']}/>
          <div id="show-emoji-mart" ref={showPickerRef} onClick={() => showEmojiPicker(!isEmojiShow)}></div>
        </div>
      </div>
    </div>
  );
};