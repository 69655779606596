import React, {useState, useCallback, useEffect} from 'react';
import {useSelector, useDispatch, shallowEqual } from 'react-redux';
import {fetchOperators, fetchOperator, updateOperator,
  setCurrentOperator as actionSetCurrentOperator
} from 'actions';
import * as actions from 'actions';


export const useOperators = () => {
  const operatorsById = useSelector(state => state.operators.operatorsById, shallowEqual);
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const dispatch = useDispatch();
  const getOperators = useCallback((filter = {widgetId: currentWidgetId}) =>
    dispatch(fetchOperators(filter)), [dispatch, currentWidgetId]);
  const addOperator = useCallback(data => dispatch(actions.addOperator(data)), [dispatch]);
  const deleteOperator = useCallback(id => dispatch(actions.removeOperator(id) ), [dispatch]);
  const updateOperator = useCallback((id, data, toggleActivate = false) =>
    dispatch(actions.updateOperator(id, data, toggleActivate)), [dispatch]);

  const isCreating = useSelector(state => state.operators._isCreating, shallowEqual);
  const isOperatorsFetching = useSelector(state => state.operators._isOperatorsFetching, shallowEqual);

  return {operatorsById, getOperators, addOperator, deleteOperator, updateOperator, isCreating, isOperatorsFetching};
};

export const useOperator = (id) => {
  const operator = useSelector(state => state.operators.operator, shallowEqual);
  const dispatch = useDispatch();
  const getOperator = useCallback((id) => {dispatch(fetchOperator(id))}, [dispatch]);
  useEffect(() => {
    getOperator(id);
  }, []);
  return [operator, getOperator];
};

export const useGetCurrentOperator = () => {
  const currentUser = useSelector(state => state.users.currentUser, shallowEqual);
  const operatorsById = useSelector(state => state.operators.operatorsById, shallowEqual);
  const currentOperator = useSelector(state => state.operators.currentOperator, shallowEqual);
  const dispatch = useDispatch();
  const updateCurrentOperator = useCallback((id, data) => dispatch(updateOperator(id, data)), [dispatch]);
  const setCurrentOperator = useCallback((operator) => dispatch(actionSetCurrentOperator(operator)), [dispatch]);
  useEffect(() => {
    Object.values(operatorsById).map((operator, i) => {
      if (operator && operator.user && operator.user.id === currentUser.id) {
        setCurrentOperator(operator)
      }
    })
  }, [operatorsById, currentUser]);

  return [currentOperator, updateCurrentOperator];
};