import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import configureStore from 'store/configureStore';
import AppContainer from 'components/App';
import SignIn from 'components/SignIn';
import SignUp from 'components/SignUp';
import ResetPassword from 'components/ResetPassword';
import PasswordRecovery from 'components/PasswordRecovery';
import ErrorBoundary from 'components/ErrorBoundary';
import NotFound from 'components/views/NotFound';
import IntlWrapper from 'components/widgets/IntlWrapper';
import {addStringSplice} from 'utils';
import {auth} from 'translate';

const store = configureStore();


export default class Root extends Component {
  render() {
    const locale = 'en';
    addStringSplice();

    return (
      <ErrorBoundary>
        <Provider store={store}>
          <IntlWrapper locale={locale} messages={[auth]}>
            <Router>
              <Switch>
                <Route path="/signin" component={SignIn} />
                <Route path="/signup" component={SignUp} />
                <Route path="/password-recovery/reset" component={ResetPassword} />
                <Route path="/password-recovery/change" component={PasswordRecovery} />
                <Route path="/" component={AppContainer} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          </IntlWrapper>
        </Provider>
      </ErrorBoundary>
    );
  }
}