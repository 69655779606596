import React, {Component, useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import moment from 'moment';

import Header from 'components/Header';
import Loader from 'components/widgets/Loader';
import IntlWrapper from 'components/widgets/IntlWrapper';
import PaymentHistoryTable from './PaymentHistoryTable';
import PaymentCardModal from './PaymentCardModal';
import {usePlans, useUserCard} from 'hooks';
import {t} from 'utils';


export default class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addModalVisibility: false
    }
  }
  resetPaymentConfirmationUrl() {
    if (typeof window.history.replaceState === 'function') {
      // window.history.replaceState({}, window.document.title, window.location.pathname);
      window.document.location.replace(window.location.pathname);
    } else {
      window.document.location.replace(window.location.pathname);
    }
  }

  setAddModalVisibility() {
    this.setState({
      addModalVisibility: !this.state.addModalVisibility
    })
  }

  render() {
    const {userCurrentPlan, messages, isUserCurrentPlanFetching, userPaymentHistory, needsConfirmPayment,
      isPaymentMaking, isPaymentBeingConfirmed, isFailed, isUserCardSetting,
      isPaymentConfirmationSuccessful, payPaymentError, confirmPaymentErrorMessage,
      cardErrorMessage, onSort, onPaymentHistoryRefresh, onPay, onCardModalClose, onPaymentCardSet} = this.props;

    if (needsConfirmPayment) {
      if (isPaymentConfirmationSuccessful) {
        this.resetPaymentConfirmationUrl();
      } else if (confirmPaymentErrorMessage) {
        window.alert(confirmPaymentErrorMessage);
        this.resetPaymentConfirmationUrl();
      }
    }

    const {addModalVisibility} = this.state;

    const handleCardModalSave = stripeObject => {
      return onPaymentCardSet(stripeObject);
    };

    const handleCloseModal = () => {
      this.setAddModalVisibility(false);
      onCardModalClose();
    };

    const _t = (value, values = {}, children = null) => {
      return t(`billings.${value}`, values, children);
    };

    if (isUserCurrentPlanFetching || !userCurrentPlan.id) {
      return <Loader/>;
    }

    return (
      <IntlWrapper messages={messages}>
        <Header title={t('title')}/>
        <div className="page-content normal-content">
          <div className='w-form'>
            <form id='email-form-2'>
              <div className='content-white-block grow-w'>
                <div className='show-group-content no-indent'>
                  <div className='show-group-col'>
                    <div className='col-9'>
                      <div className='group-title'>{_t('currentPlan.title')}</div>
                      <div className='show-group-content'>
                        <div className='row'>
                          <div className='plan-info-block-2'>
                            <p className='account-paragraph no-indent'>
                              {_t('yourPlan.label')}: <strong>{userCurrentPlan.subscription.plan_name}</strong>
                            </p>
                            <NavLink to="/upgrade" className="step-btn w-inline-block">{_t('button.seePricing')}</NavLink>
                          </div>
                        </div>
                        <div className='row'>
                          <p className='account-paragraph'>
                            {_t('nextPayment.label')}: <strong>
                              {userCurrentPlan.subscription.next_payment_at ?
                                moment(userCurrentPlan.subscription.next_payment_at).format("DD/MM/YYYY") : ''}
                          </strong>
                          </p>
                        </div>
                        <div className='row'>
                          <div className='plan-info-block'>
                            <p className='account-paragraph no-indent'>{_t('used.label')}:</p>
                            <div className='plan-info-div'>
                              <div className='visitors-info current-plan-info'>
                                <div>{userCurrentPlan.managers_count}/
                                  {userCurrentPlan.managers_limit ? userCurrentPlan.managers_limit : 'Unlimited'}</div>
                                <div>{_t('chat.managers.label')}</div>
                              </div>
                              <div className='visitors-info current-plan-info'>
                                <div>{userCurrentPlan.widget_bot_chats_count}/
                                  {userCurrentPlan.widget_bot_chats_limit ? userCurrentPlan.widget_bot_chats_limit : 'Unlimited'}</div>
                                <div>{_t('widget.bot.chats.label')}</div>
                              </div>
                              <div className='visitors-info current-plan-info'>
                                <div>{userCurrentPlan.landing_bot_chats_count}/
                                  {userCurrentPlan.landing_bot_chats_limit ? userCurrentPlan.landing_bot_chats_limit : 'Unlimited'}</div>
                                <div>{_t('landing.bot.chats.label')}</div>
                              </div>
                              <div className='visitors-info current-plan-info'>
                                <div>{userCurrentPlan.facebook_messenger_bot_chats_count}/
                                  {userCurrentPlan.facebook_messenger_bot_chats_limit ? userCurrentPlan.facebook_messenger_bot_chats_limit : 'Unlimited'}</div>
                                <div>{_t('fb.bot.chats.label')}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-3'>
                      <div className='group-title'>{_t('billing.info.title')}</div>
                      <div className='show-group-content'>
                        <div className='row align' >
                          <div onClick={() => this.setAddModalVisibility()}className='blue-button add-indent w-button'>
                            <span className='awesome-icon big-icon'> </span>
                            {_t('modal.button')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='page-content-header-div'>
                <div>
                  <div className='page-header'>{_t('payment.history.label')}</div>
                </div>
              </div>
              <PaymentHistoryTable onPay={onPay} onSort={onSort}/>
            </form>
          </div>
        </div>
        {addModalVisibility && (
          <PaymentCardModal
            isOpen={addModalVisibility}
            isLoading={isUserCardSetting}
            errorMessage={cardErrorMessage}
            onClose={handleCloseModal}
            onSave={onPaymentCardSet}
            _t={_t}/>
        )}
      </IntlWrapper>
    );
  }
};