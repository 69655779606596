import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import App from './App';
import {main} from 'translate';

export default compose(
  connect((state, ownProps) => {
      return {
        messages: [main]
      };
    }
  ),

  lifecycle({
    componentDidMount() {
      //console.log('AppContainer componentDidMount')
    },
  }),

  withHandlers()
) (
  App
);