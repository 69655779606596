import React, {Component, useEffect, useRef, useState} from 'react';
import _ from 'lodash';
import DateRange from "components/widgets/DateRange";
import Loader from "components/widgets/Loader";
import BookedMeetingTables from "./BookedMeetingsTable";
import {useOperators, useMeetingReports, useWidgets, useUpdateData, useGetCurrentOperator} from "hooks";
import LanguageFactory from "api/LanguageFactory";
import {main, meetings} from 'translate';
import {MEETINGS_CHART_LINES} from "constants.js";
import Chart from "components/widgets/Chart";
import {t, translateAssign, getOperatorName, getGranularity} from 'utils';


export default function Reports(props) {
  const {operatorsById} = useOperators();
  const [currentOperator] = useGetCurrentOperator();
  const {currentWidgetId} = useWidgets();
  const [dateRange, setDateRange] = useState({});
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedMeetingLink, setSelectedMeetingLink] = useState(null);
  const [isOperatorsOpen, setOperatorsOpen] = useState(false);
  const [isMeetingLinksOpen, setMeetingLinksOpen] = useState(false);

  const {meetingLinksById, meetingsChartData, isUpdateBookedMeetings,
    getMeetingsChartData} = useMeetingReports();

  const isFirstRun = useRef([true, true]);

  const handleChartGet = () => {
    const granularityInfo = getGranularity({
      start_datetime: dateRange.created_at__gte,
      end_datetime: dateRange.created_at__lte,
    });
    getMeetingsChartData({
      operator_id: _.get(selectedOperator, 'id'),
      meeting_link_id: _.get(selectedMeetingLink, 'id'),
      start_datetime: dateRange.created_at__gte,
      end_datetime: dateRange.created_at__lte,
      granularity: granularityInfo.granularity,
    }, granularityInfo.tickFormat);
  };

  useUpdateData(handleChartGet);

  useEffect(() => {
    if (isFirstRun.current[0]) {
      isFirstRun.current[0] = false;
      //return;
    } else {
      if (!_.isEmpty(dateRange)) {
        handleChartGet();
      }
    }
  }, [dateRange, selectedOperator, selectedMeetingLink, currentWidgetId]);

  const _t = (value, values = {}, children = null) => {
    return t(`meetings.reports.${value}`, values, children);
  };

  return (
    <div className='campaigns-preview-w'>
      <div className="content-white-block hor-flex-2">
        <div className="table-filter-block no-indent">
          <DateRange onChangeRange={setDateRange} fixRangeType={true} additionalClass="right-margin"/>
          <div className='filers-w'>
            <div className="filter-drop w-dropdown" onMouseOver={() => setOperatorsOpen(true)}
                 onMouseOut={() => setOperatorsOpen(false)}>
              <div className="filter-drop-tog no-arrow w-dropdown-toggle">
                <div>{selectedOperator ?
                  getOperatorName(selectedOperator)
                  : _t('choose.manager')}</div>
                <div className="drop-icon w-icon-dropdown-toggle"/>
              </div>
              <nav className={`height-250-px dropdown-list right-float-list w-dropdown-list ${isOperatorsOpen ? 'w--open' : ''}`}>
                <div onClick={() => setSelectedOperator(null)} className="dropdown-link w-dropdown-link operator-name">
                  {_t('choose.all')}
                </div>
                {Object.values(operatorsById).map((operator, i) => {
                  const name = getOperatorName(operator, i);
                  return (
                    <div key={i} onClick={() => {
                      setSelectedOperator(operator);
                      setOperatorsOpen(false);
                    }} className="dropdown-link w-dropdown-link operator-name">{name}</div>
                  )}
                )}
              </nav>
            </div>

            <div className="filter-drop w-dropdown" onMouseOver={() => setMeetingLinksOpen(true)}
                 onMouseOut={() => setMeetingLinksOpen(false)}>
              <div className="filter-drop-tog no-arrow w-dropdown-toggle">
                <div>{selectedMeetingLink ?
                  selectedMeetingLink.name
                  : _t('choose.meeting.links')}</div>
                <div className="drop-icon w-icon-dropdown-toggle"/>
              </div>
              <nav className={`height-250-px dropdown-list right-float-list w-dropdown-list ${isMeetingLinksOpen ? 'w--open' : ''}`}>
                <div onClick={() => setSelectedMeetingLink(null)} className="dropdown-link w-dropdown-link operator-name">
                  {_t('choose.all')}
                </div>
                {Object.values(meetingLinksById).map((link, i) => {
                  return (
                    <div key={i} onClick={() => {
                      setSelectedMeetingLink(link);
                      setMeetingLinksOpen(false);
                    }} className="dropdown-link w-dropdown-link operator-name">{link.name}</div>
                  )}
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <MeetingChart meetingsChartData={meetingsChartData}/>
      <BookedMeetingTables dateRange={dateRange}
                           selectedOperator={selectedOperator}
                           selectedMeetingLink={selectedMeetingLink}
      />

    </div>
  );
};


const MeetingChart = (props) => {
  const translate = translateAssign(main, meetings)[LanguageFactory.getLocale()];
  const {meetingsChartData} = props;
  const chartSettings = {
    lines: {
      [MEETINGS_CHART_LINES.views]: {name:  translate['meetings.reports.chart.views'],
        color: '#26b94c', key: MEETINGS_CHART_LINES.views},
      [MEETINGS_CHART_LINES.meetingsBooked]: {name:  translate['meetings.reports.chart.meetingsBooked'],
        color: '#597892', key: MEETINGS_CHART_LINES.meetingsBooked},
    }
  };

  const _t = (value, values = {}, children = null) => {
    return t(`meetings.reports.${value}`, values, children);
  };

  return (
    <>
      {(meetingsChartData && (meetingsChartData.totalCounts.views > 0 || meetingsChartData.totalCounts.meetings_booked > 0)) ? (
        <Chart data={meetingsChartData} chartSettings={chartSettings}/>
      ): (
        <div className="no-data">No data</div>
      )}

      <div className="campaign-preview-item">
        <div className="cp-info-block">
          <div className="cp-info-item">
            <div className="cp-info-header">
              <span className="cp-info-header-icon"></span>{_t('chart.views')}</div>
            {meetingsChartData && meetingsChartData.totalCounts ? (
              <div className="cp-info-count">{meetingsChartData.totalCounts[MEETINGS_CHART_LINES.views]}</div>
            ) : (
              <div className="cp-info-count">0</div>
            )}
          </div>
          <div className="cp-info-item border-left">
            <div className="cp-info-header">
              <span className="cp-info-header-icon"></span>{_t('chart.meetingsBooked')}</div>
            {meetingsChartData && meetingsChartData.totalCounts ? (
              <div className="cp-info-count">{meetingsChartData.totalCounts[MEETINGS_CHART_LINES.meetingsBooked]}</div>
            ) : (
              <div className="cp-info-count">0</div>
            )}
          </div>
          <div className="cp-info-item border-left">
            <div className="cp-info-header"><span className="cp-info-header-icon"></span>Conversion rate</div>
            <div className="cp-info-count">
              {meetingsChartData &&  meetingsChartData.totalCounts && meetingsChartData.totalCounts[MEETINGS_CHART_LINES.views]
                ? (100 * (meetingsChartData.totalCounts[MEETINGS_CHART_LINES.meetingsBooked]
              / meetingsChartData.totalCounts[MEETINGS_CHART_LINES.views])).toFixed() : 0}%
            </div>
          </div>
        </div>
      </div>
    </>
  )
};