import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import Settings from './Settings';
import {main, settings} from 'translate';

export default compose(
  connect((state, ownProps) => {
      return {
        messages: [main, settings]
      };
    }
  ),

  lifecycle({
    componentDidMount() {
      document.title = 'Settings | HeyCom'
    },
  }),

  withHandlers()
) (
  Settings
);