import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';

import {useChatState, useChatSort, useGetChats, useStateWidgets, useGetCurrentOperator} from 'hooks';
import {t} from 'utils';
import {CHAT_MESSAGES_LIMIT, PAGINATION_OFFSET_TYPE} from 'constants.js';


export default function ChatList(props) {
  const chatOrders = [
    {value: 'new'},
    {value: 'old'}
  ];

  const {chatsById, selectedChat, searchResults,searchMode, clientId,
    onSelectSearchResult, onSelectChat, onChangeFilters} = props;
  const {currentWidgetId} = useStateWidgets();
  const scrollRef = useRef(null);
  const [isChatOrderOpen, setChatOrderOpen] = useState(false);
  const [isLoadingMoreMessage, setLoadingMoreMessage] = useState(false); //
  const {chatOrder, setChatOrder} = useChatSort();
  const {orderedChatIds, orderedSearchResults} = useChatState();
  const [currentOperator] = useGetCurrentOperator();

  //console.log(chatsById, orderedChatIds, selectedChat)

  useEffect(() => {
    if (chatOrder) {
      if (selectedChat && selectedChat.id !== null) {
        onChangeFilters({
          sort: chatOrder.value === 'new' ? '-created_at' : 'created_at',
          offset: 0 //selectedChat ? selectedChat.id : 0
        });
      }
    }
  }, [chatOrder]);

  // if (selectedChat && orderedChatIds && orderedChatIds.length && orderedChatIds.indexOf(selectedChat.id) === -1 && !clientId) {
  //   return (
  //     <Redirect to={`/chats/${orderedChatIds[0]}`}/>
  //   );
  // }

  const handleChatsLoad = (offsetDirection = -1) => {
    //setLoadingMoreMessage(true);
    const offsetBySearch = orderedSearchResults && orderedSearchResults.length ?
      orderedSearchResults.length - 1 : 0;
    const offsetByChats = orderedChatIds && orderedChatIds.length ?
      orderedChatIds.length - 1 : 0;
    if (offsetBySearch || offsetByChats) {
      onChangeFilters({
        limit: '1,30',
        //paginationDirection: offsetDirection > 0 ? PAGINATION_OFFSET_TYPE.next : PAGINATION_OFFSET_TYPE.prev,
        offset:  searchMode ? offsetBySearch : offsetByChats
      });
    }
  };

  const handleScroll = () => {
    const {
      firstChild,
      lastChild,
      scrollTop,
      offsetTop,
      offsetHeight
    } = scrollRef.current;

    const topEdge = firstChild.offsetTop;
    const bottomEdge = lastChild.offsetTop + lastChild.offsetHeight;
    const scrolledUp = scrollTop + offsetTop;
    const scrolledDown = scrolledUp + offsetHeight;

    if (bottomEdge - scrolledDown <= 100 ) {
      handleChatsLoad(1);
    }
  };

  return (
    <div className="left-sidebar-w custom">
      <div className="sort-w">
        <div className="filter-drop no-indent w-dropdown" onMouseOver={() => setChatOrderOpen(true)} onMouseOut={() => setChatOrderOpen(false)}>
          <div className="filter-drop-tog w-dropdown-toggle">
            <div>{t(`chats.chatOrder.${chatOrder ? chatOrder.value : chatOrders[0].value}`)}</div>
            <div className="drop-icon w-icon-dropdown-toggle"></div>
          </div>
          <nav className={`dropdown-list right-float-list w-dropdown-list ${isChatOrderOpen ? 'w--open' : ''}`}>
            {chatOrders.map((option, i) =>
              <div key={i} onClick={() => setChatOrder(option)} className="dropdown-link w-dropdown-link">
                {t(`chats.chatOrder.${option.value}`)}</div>
            )}
          </nav>
        </div>
      </div>
      <div ref={scrollRef}
           className="chats-list-w hidden-scrollbar" id="chat-list-block"
           onScroll={e => handleScroll(e)}
      >
        {orderedSearchResults ? (
          !orderedSearchResults.length ? (
            <div className="no-chats">
              <img src={require(`styles/images/empty-chat-icon.svg`)} alt=""/>
              <div>No chats</div>
            </div>
          ) : (
            orderedSearchResults && orderedSearchResults.map(id => {
              const searchResult = searchResults[id];
              return (
                <Link to={`/chats/${searchResult.chat_id}`} key={searchResult.id} onClick={() => {
                  onSelectChat(searchResult.chat_id);
                  onSelectSearchResult(id);
                }} className={`chat-item ${(selectedChat && selectedChat.id === searchResult.chat_id) ? 'search-results' : ''} w-inline-block`}>
                  <div className="chat-avatar">
                    {/*<div className="chat-avatar-icon chat-avatar-animation"></div>*/}
                    <div className="chat-item-check chat-avatar-animation"></div>
                  </div>
                  <div className="chat-item-info">
                    <div className="identity-row">
                      <div className="user-identity">{searchResult.chat.client ? (searchResult.chat.client.name || searchResult.chat.client_id) : searchResult.chat.client_id}</div>
                      <div className="chat-date">{moment(searchResult.created_at).format('MMM D')}</div>
                    </div>
                    <div className="user-location">{searchResult.chat.location}</div>
                    <div className="chat-last-message-preview">{searchResult.text}</div>
                  </div>
                </Link>
              );
            })
          )
        ) : (
          !orderedChatIds.length ? (
            <div className="no-chats">
              <img src={require(`styles/images/empty-chat-icon.svg`)} alt=""/>
              <div>No chats</div>
            </div>
          ) : (
            orderedChatIds && orderedChatIds.map((chatId, i) => {
              const chat = chatsById[chatId];
              const text = chat.lastMessage ? chat.lastMessage.text : '';
              return (
                <Link to={`/chats/${chat.id}`} key={chat.id} onClick={() => onSelectChat(chat.id)}
                      className={`chat-item ${(selectedChat && selectedChat.id === chat.id) ? 'new-chat' : ''} w-inline-block position-relative`}>
                  <div className={`chat-avatar ${chat.status && chat.status + '-chat'}`}>
                    <div className="chat-avatar-icon chat-avatar-animation position-relative">
                      {chat.client && chat.client.is_online && (
                        <div className="online-indicator"></div>
                      )}
                    </div>
                    <div className="chat-item-check chat-avatar-animation"></div>

                  </div>
                  <div className="chat-item-info">
                    <div className="identity-row">
                      <div className="user-identity">{chat.client ? (chat.client.name || 'Site Visitor') : 'Site Visitor'}</div>
                      <div className="chat-date">{moment(chat.lastMessage ? chat.lastMessage.created_at : chat.date).format('MMM D')}</div>
                    </div>
                    <div className="user-location">{chat.location}</div>
                    <div className="chat-last-message-preview ellipsis">{text}</div>
                  </div>
                  {chat.unread_messages_by_user_count > 0 && currentOperator && (
                    <div className={`message-count ${
                      !chat.active_operator_ids ||
                      (chat.active_operator_ids && !chat.active_operator_ids.includes(currentOperator.id) &&
                      chat.active_operator_ids.length !== 0)
                      || !currentOperator.is_online ? 'disable-notification' : ''}`}>
                      {chat.unread_messages_by_user_count}</div>
                  )}
                </Link>
              );
            })
          )
        )}
      </div>
    </div>
  )
}