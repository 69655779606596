import {isEmailValid, isEmpty, isPasswordNotOnlyNumbers, isPasswordNotSimple, isPasswordValid} from './validators';
import {main as commonTranslate} from 'translate';


export const getErrorText = (errorText, data, lang = 'en') => {
  if (!errorText) {
    const text = Object.values(data)[0];
    if (text) {
      return text;
    } else {
      return commonTranslate[lang]['message.failure'];
    }
  }
  return errorText;
};

export const getPasswordError = (password, lang = 'en') => {
  if (!isPasswordValid(password)) {
    return commonTranslate[lang]['error.password.length'];
  }
  if (!isPasswordNotSimple(password)) {
    return commonTranslate[lang]['error.password.simple'];
  }
  if (!isPasswordNotOnlyNumbers(password)) {
    return commonTranslate[lang]['error.password.consistNumber'];
  }
  return null;
};

export const getFormError = (email, password) => {
  if (!isEmailValid(email) || isEmpty(email)) {
    return 'Invalid email';
  }
  const passwordError = getPasswordError(password);
  if (passwordError) {
    return passwordError;
  }
  return null;
};


export const getTranslateErrorMessage = (error, lang) => {
  return error.code ?
    Object.assign({}, error, {message: commonTranslate[lang][`server.error.${error.code}`] || error.message}) : error;
};

export class StripeError extends Error {
  constructor(errorData) {
    super(errorData.message);

    Object.keys(errorData).forEach(prop => {
      this[prop] = errorData[prop];
    });

    this.name = 'StripeError';
    // this.message = `[${errorData.type}] ${errorData.message}`;
    this.message = errorData.message;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, StripeError);
    } else {
      this.stack = (new Error()).stack;
    }
  }
}

export const createUserErrorMessage = (error, lang = 'en') => {
  if (!error) {
    return null;
  }

  // debugger;

  let message = commonTranslate[lang]['error.user.stripe'];

  if (error instanceof StripeError) {
    if (error.type === 'card_error' || error.type === 'validation_error') {
      message = error.message;
    } else if (error.code === 'payment_intent_authentication_failure') {
      message = commonTranslate[lang]['error.user.stripe.authentication'];
    }
  } else {
    if (!/stripe/i.test(error.message)) {
      if (error.data && error.data.message) {
        message = error.data.message;
      } else {
        message = error.message
      }

    }
  }

  return message;
};