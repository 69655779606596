import React, { Fragment, useState, useEffect } from 'react';

import CustomSelect from 'components/widgets/CustomSelect';
import {t} from 'utils';
import {useWidgets} from 'hooks';

export default function InstallCode(props) {
  const {widget, setWidget} = props;

  const {checkInstallWidget, isCheckingInstall, isInstallCurrentWidget} = useWidgets();

  useEffect(() => {
    checkInstallWidget(widget.id);
  }, []);

  const refTypeOptions = {
    is: {label: 'ref.options.is', value: 'is', isValueVisibility: true},
    isNot: {label: 'ref.options.isNot', value: 'isNot', isValueVisibility: true},
    includes: {label: 'ref.options.includes', value: 'includes', isValueVisibility: true},
    notInclude: {label: 'ref.options.notInclude', value: 'notInclude', isValueVisibility: true},
  };

  const [referrerType, setReferrerType] = useState('is');
  const [devEmail, setDevEmail] = useState('');

  const _t = (value, values = {}, children = null) => {
    return t(`installCode.${value}`, values, children);
  };

  return (
    <>
      <div className="pane-content-w">
        <div className="content-white-block">
          <div>
            <div className="group-title">{_t('header')} {widget.site_url}
              {isCheckingInstall ?
                <span className="check-code cheking-code"/>
                 :
                <span className={`check-code ${isInstallCurrentWidget ? 'code-installed' : 'code-not-installed'}`}>
                  {isInstallCurrentWidget ? _t('installedFact') : _t('notInstalledFact')}
                </span>
              }
            </div>
          </div>
          <div className="group-content-w">
            <div className="group-w">
              <div className="group-w-header">
                <div className="group-title">{_t('code.label')}</div>
              </div>
              <div className="show-group-content">
                <div className="show-group-div">
                  <div className="group-row">
                    <textarea value={widget.embed_code} placeholder="Example Text" maxLength="5000"
                              className="custom-textarea settings-select w-input cursor-text" readOnly={true}>
                    </textarea>
                  </div>
                </div>
              </div>
              <div className="show-group-content">
                <div className="group-w-header">
                  <div className="group-title">{_t('sendCode.label')}</div>
                </div>
              </div>
              <div className="show-group-content">
                <div className="show-group-div">
                  <div className="group-row">
                    <div className="col-6">
                      <input type="email" value={devEmail} onChange={e => setDevEmail(e.target.value)}
                             className="node-settings-input w-input"
                             maxLength="256" placeholder="name@email.com"/>
                    </div>
                    <div className="col-6 right-padding">
                      <div className="blue-button w-button">{_t('sendCode.button')}</div></div>
                  </div>
                  <div className="page-subheader">{_t('sendCode.tooltip')} <a href="#" className="link-3">support@heycom.io</a></div>
                </div>
              </div>
            </div>
            <div className="group-w hide">
              <div className="group-w-header">
                <div className="group-title">{_t('ref.label')}</div>
                <div className="col-6">
                  <CustomSelect options={Object.values(refTypeOptions)}
                                defaultValue={refTypeOptions.is}
                                selectClassName={`settings-select w-select`}
                                getOptionLabel={option => _t(option.label)}
                                onChange={option => setReferrerType(option.value)}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}