import l_cloneDeep from 'lodash.clonedeep';
import l_isEmpty from 'lodash.isempty';
import createSocketIoClient from './socket.io-client-factory';
import settings from 'settings';
import ServerEvents from './ServerEvents';
import {debugLog} from 'utils';

const sio = require('socket.io-client');

export default class ServerEventChannel {
  static _SERVER_SIDE_EVENTS_TO_CLIENT_SIDE_EVENTS = {
    'add_message': {
      clientSideName: ServerEvents.ADD_MESSAGE,
      useMock: true,
      mockTimeOut: 11,  // secs
    },
    'update_message': {
      clientSideName: ServerEvents.UPDATE_MESSAGE,
      useMock: true,
      mockTimeOut: 16,  // secs
    },
    'delete_message': {
      clientSideName: ServerEvents.DELETE_MESSAGE,
      useMock: true,
      mockTimeOut: 16,  // secs
    },
    'start_typing': {
      clientSideName: ServerEvents.START_TYPING,
      useMock: true,
      mockTimeOut: 11,  // secs
    },
    'end_typing': {
      clientSideName: ServerEvents.END_TYPING,
      useMock: true,
      mockTimeOut: 11,  // secs
    },

    'add_chat': {
      clientSideName: ServerEvents.ADD_CHAT,
      useMock: true,
      mockTimeOut: 16,  // secs
    },
    'update_chat': {
      clientSideName: ServerEvents.UPDATE_CHAT,
      useMock: true,
      mockTimeOut: 16,  // secs
    },
    'delete_chat': {
      clientSideName: ServerEvents.DELETE_CHAT,
      useMock: true,
      mockTimeOut: 16,  // secs
    },

    'client_connected': {
      clientSideName: ServerEvents.CLIENT_CONNECTED,
      useMock: true,
      mockTimeOut: 16,  // secs
    },
    'client_disconnected': {
      clientSideName: ServerEvents.CLIENT_DISCONNECTED,
      useMock: true,
      mockTimeOut: 16,  // secs
    },
    'update_client': {
      clientSideName: ServerEvents.UPDATE_CLIENT,
      useMock: true,
      mockTimeOut: 16,  // secs
    },

    'update_operator': {
      clientSideName: ServerEvents.UPDATE_OPERATOR,
      useMock: true,
      mockTimeOut: 16,  // secs
    },

    'update_active_campaign': {
      clientSideName: ServerEvents.UPDATE_ACTIVE_CAMPAIGN,
      useMock: true,
      mockTimeOut: 16,  // secs
    },

    'ask_chat_rating': {
      clientSideName: ServerEvents.ASK_CHAT_RATING,
      useMock: true,
      mockTimeOut: 16,  // secs
    },

    'add_payment': {
      clientSideName: ServerEvents.ADD_PAYMENT,
      useMock: true,
      mockTimeOut: 16,  // secs
    },

    'update_payment': {
      clientSideName: ServerEvents.UPDATE_PAYMENT,
      useMock: true,
      mockTimeOut: 16,  // secs
    },

    'subscribe_to_plan': {
      clientSideName: ServerEvents.SUBSCRIBE_TO_PLAN,
      useMock: true,
      mockTimeOut: 16,  // secs
    },
  };

  static _channels = [];
  static _eventHandlers = {};
  static _socket = null;
  static _id = 100;
  static _token = null;

  constructor(id) {
    this._id = id;
  }

  static get(token, clientId = null, campaignId = null, widgetId = null, previewMode = null) {
    const channel = new ServerEventChannel(ServerEventChannel._generateId(), token, clientId, campaignId, widgetId);
    ServerEventChannel._channels.push(channel);
    return ServerEventChannel._createSocket(token, clientId, campaignId, widgetId, previewMode)
      .then(() => {
        return channel;
      });
  }

  destroy() {
    ServerEventChannel._destroyChannel(this);
  }

  getId() {
    return this._id;
  }

  getSessionId() {
    return ServerEventChannel._getSessionId();
  }

  on(eventName, handler) {
    ServerEventChannel._on(eventName, handler, this._id);
  }

  emit(eventName, data) {
    ServerEventChannel._emit(eventName, JSON.stringify(data));
  }

  off(eventName, handler) {
    ServerEventChannel._off(eventName, handler, this._id);
  }

  static _generateId() {
    return ++ServerEventChannel._id;
  }

  static _validateEventName(eventName) {
    if (Object.values(ServerEvents).findIndex(name => name === eventName) < 0) {
      throw new TypeError(`Unknown server event: "${eventName}"`);
    }
  }

  static _dispatchEvent(serverSideEventName, eventData) {
    debugLog('СОБЫТИЕ!  --> ', serverSideEventName);
    const event = ServerEventChannel._SERVER_SIDE_EVENTS_TO_CLIENT_SIDE_EVENTS[serverSideEventName];
    if (!event) {
      return;
    }

    Object.values(ServerEventChannel._eventHandlers[event.clientSideName] || {})
      .forEach(handlers => handlers.forEach(handler => handler(eventData)));
  }

  static _on(eventName, handler, channelId) {

    ServerEventChannel._validateEventName(eventName);

    const eventHandlers = ServerEventChannel._eventHandlers;

    if (!eventHandlers[eventName]) {
      eventHandlers[eventName] = {};
    }

    if (!eventHandlers[eventName][channelId]) {
      eventHandlers[eventName][channelId] = [];
    }

    if (eventHandlers[eventName][channelId].findIndex(h => h === handler) < 0) {
      eventHandlers[eventName][channelId].push(handler);
    }
  }

  static _emit(eventName, data) {
    ServerEventChannel._validateEventName(eventName);

    ServerEventChannel._socket.emit(eventName, data);
  }

  static _off(eventName, handler, channelId) {
    const handlersByChannelId = ServerEventChannel._eventHandlers[eventName] || {};

    if (!(channelId in handlersByChannelId)) {
      return;
    }

    const idx = handlersByChannelId[channelId].findIndex(h => h === handler);

    if (idx >= 0) {
      handlersByChannelId[channelId].splice(idx, 1);
      if (!handlersByChannelId[channelId].length) {
        delete handlersByChannelId[channelId];
      }
    }

    if (l_isEmpty(ServerEventChannel._eventHandlers[eventName])) {
      delete ServerEventChannel._eventHandlers[eventName];
    }
  }

  static _destroyChannel(channel) {
    const channels = ServerEventChannel._channels;
    const idx = channels.findIndex(anotherChannel => channel === anotherChannel);
    if (idx >= 0) {
      channels.splice(idx);
    }

    const channelId = channel.getId();

    Object.values(ServerEventChannel._eventHandlers).forEach(handlersByChannelId => {
      if (channelId in handlersByChannelId) {
        delete handlersByChannelId[channelId];
      }
    });

    if (!channels.length) {
      ServerEventChannel._destroySocket();
    }
  }

  static _createSocket(token, clientId, campaignId, widgetId, previewMode) {
    return new Promise((resolve, reject) => {
      if (ServerEventChannel._socket != null) {
        resolve();
      }

      const query = {
        access_token: token,
        preview_mode: previewMode,
        client_id: clientId || undefined,
        campaign_id: campaignId || undefined,
        widget_id: widgetId || undefined
      };

      Object.keys(query).forEach(key => query[key] === undefined ? delete query[key] : {});

      const sioArgs = {
        transports: ["polling","websocket"],
        query: query
      };

      if (sio.isMock) {
        sioArgs.mockEvents = Object.keys(ServerEventChannel._SERVER_SIDE_EVENTS_TO_CLIENT_SIDE_EVENTS).map(key => {
          const event = l_cloneDeep(ServerEventChannel._SERVER_SIDE_EVENTS_TO_CLIENT_SIDE_EVENTS[key]);
          event.serverSideName = key;
          return event;
        });
      }

      ServerEventChannel._socket = sio.connect(settings.CHAT_SOCKETIO_ORIGIN, sioArgs);

      debugLog('SOCKET CREATED');

      Object.keys(ServerEventChannel._SERVER_SIDE_EVENTS_TO_CLIENT_SIDE_EVENTS).forEach(eventName => {
        ServerEventChannel._socket.on(eventName, (data) => ServerEventChannel._dispatchEvent(eventName, data));
      });

      ServerEventChannel._socket.on('connect', () => {
        debugLog('CONNECT', ServerEventChannel._socket.id);
        resolve();
      });
    });
  }

  static _destroySocket() {
    if (ServerEventChannel._socket) {
      ServerEventChannel._socket.close();
      ServerEventChannel._socket = null;
    }
  }

  static _getSessionId() {
    return ServerEventChannel._socket ? ServerEventChannel._socket.id : null;
  }
}
