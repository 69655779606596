import client from 'api/client';
import {getAccessToken} from 'utils';
import {CHAT_MESSAGES_LIMIT} from 'constants.js';


export const FETCH_CHATS__R = 'FETCH_CHATS__R';
export const fetchChatsRq = () => {
  return {
    type: FETCH_CHATS__R
  };
};
export const FETCH_CHATS__S = 'FETCH_CHATS__S';
export function fetchChatsSc(chats, offset, reset) {
  return {
    payload: {chats, offset, reset},
    type: FETCH_CHATS__S
  };
}
export const FETCH_CHATS__F = 'FETCH_CHATS__F';
export function fetchChatsFl(error) {
  return {
    payload: error,
    type: FETCH_CHATS__F
  };
}
export const FETCH_CHATS = 'FETCH_CHATS';
export const fetchChats = (params, paginationDirection) => (dispatch, getState) => {
  dispatch(fetchChatsRq());
  return client.get('/messages', {
    params: {
      ...params,
      only_last_message: true,
      expand_chat: true
    }
  })
    .then(data => {
      dispatch(fetchChatsSc(data, params.offset, params.reset));
      return data;
    })
    .catch(error => {
      console.log(error);
      dispatch(fetchChatsFl(error))
    });
};

export const FETCH_CHAT__R = 'FETCH_CHAT__R';
export const fetchChatRq = () => {
  return {
    type: FETCH_CHAT__R
  };
};
export const FETCH_CHAT__S = 'FETCH_CHAT__S';
export function fetchChatSc(chat) {
  return {
    payload: chat,
    type: FETCH_CHAT__S
  };
}
export const FETCH_CHAT__F = 'FETCH_CHAT__F';
export function fetchChatFl(error) {
  return {
    payload: error,
    type: FETCH_CHAT__F
  };
}
export const FETCH_CHAT = 'FETCH_CHAT';
export const fetchChat = params => (dispatch, getState) => {
  dispatch(fetchChatRq());
  return client.get('/chats/' + params.chat_id, {
    // params: {
    //   ...params,
    //   only_last_message: true,
    //   expand_chat: true,
    //   //send_count: true
    // }
  })
    .then(data => {
      if (data[0]) {
        dispatch(fetchChatSc(data[0].chat));
        dispatch(fetchChatMessages(params));
      } else {
        dispatch(fetchChatSc(data));
        dispatch(fetchChatMessages(params));
      }
    })
    .catch(error => {
      console.log(error)
      dispatch(fetchChatFl(error))
    });
};

export const FETCH_CHAT_MESSAGES__R = 'FETCH_CHAT_MESSAGES__R';
export const fetchChatMessagesRq = () => {
  return {
    type: FETCH_CHAT_MESSAGES__R
  };
};
export const FETCH_CHAT_MESSAGES__S = 'FETCH_CHAT_MESSAGES__S';
export function fetchChatMessagesSc(chat) {
  return {
    payload: chat,
    type: FETCH_CHAT_MESSAGES__S
  };
}
export const FETCH_CHAT_MESSAGES__F = 'FETCH_CHAT_MESSAGES__F';
export function fetchChatMessagesFl(error) {
  return {
    payload: error,
    type: FETCH_CHAT_MESSAGES__F
  };
}
export const FETCH_CHAT_MESSAGES = 'FETCH_CHAT_MESSAGES';
export const fetchChatMessages = (params, paginationDirection) => (dispatch, getState) => {
  dispatch(fetchChatMessagesRq());
  return client.get(`/messages`, {
    params: {
      ...params,
      limit: params.limit || CHAT_MESSAGES_LIMIT,
      send_count: true,
      offset: params.offset || -1 * CHAT_MESSAGES_LIMIT,
    }
  })
    .then(data => {
      dispatch(fetchChatMessagesSc({
        chatId: params.chat_id,
        messages: data.payload,
        messageCount: data.count,
        paginationDirection,
      }));
    })
    .catch(error => {
      console.log(error);
      dispatch(fetchChatMessagesFl(error))
    });
};


export const CREATE_CHAT__R = 'CREATE_CHAT__R';
export const createChatRq = () => {
  return {
    type: CREATE_CHAT__R
  };
};
export const CREATE_CHAT__S = 'CREATE_CHAT__S';
export function createChatSc(chat) {
  return {
    payload: chat,
    type: CREATE_CHAT__S
  };
}
export const CREATE_CHAT__F = 'CREATE_CHAT__F';
export function createChatFl(error) {
  return {
    payload: error,
    type: CREATE_CHAT__F
  };
}
export const CREATE_CHAT = 'CREATE_CHAT';
export const createChat = newChatData => (dispatch, getState) => {
  dispatch(createChatRq());

  return client.post('/chats/', newChatData)
    .then(data => {
      dispatch(createChatSc(data));
      return data;
    })
    .catch(error => {
      dispatch(createChatFl(error))
      return {error}
    });
};

export const UPDATE_CHAT__R = 'UPDATE_CHAT__R';
export const updateChatRq = () => {
  return {
    type: UPDATE_CHAT__R
  };
};
export const UPDATE_CHAT__S = 'UPDATE_CHAT__S';
export function updateChatSc(chat) {
  return {
    payload: chat,
    type: UPDATE_CHAT__S
  };
}
export const UPDATE_CHAT__F = 'UPDATE_CHAT__F';
export function updateChatFl(error) {
  return {
    payload: error,
    type: UPDATE_CHAT__F
  };
}
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const updateChat = (chatId, data) => (dispatch, getState) => {
  dispatch(updateChatRq());
  return client.patch(`/chats/${chatId}`, data)
    .then(data => {
      dispatch(updateChatSc(data));
    })
    .catch(error => {
      dispatch(updateChatFl(error))
    });
};

export const GET_VISITOR_INFO__R = 'GET_VISITOR_INFO__R';
export const getVisitorInfoRq = () => {
  return {
    type: GET_VISITOR_INFO__R
  };
};
export const GET_VISITOR_INFO__S = 'GET_VISITOR_INFO__S';
export function getVisitorInfoSc(clientId, data) {
  return {
    payload: {clientId, data},
    type: GET_VISITOR_INFO__S
  };
}
export const GET_VISITOR_INFO__F = 'GET_VISITOR_INFO__F';
export function getVisitorInfoFl(error) {
  return {
    payload: error,
    type: GET_VISITOR_INFO__F
  };
}
export const GET_VISITOR_INFO = 'GET_VISITOR_INFO';
export const getVisitorInfo = (chatId, clientId) => (dispatch, getState) => {
  dispatch(getVisitorInfoRq());
  return client.get(`/clients/${clientId}?send_active_chat=${true}`)
    .then(data => {
      dispatch(getVisitorInfoSc(clientId, data));
    })
    .catch(error => {
      dispatch(getVisitorInfoFl(error));
    });
};

export const UPDATE_VISITOR_INFO__R = 'UPDATE_VISITOR_INFO__R';
export const updateVisitorInfoRq = () => {
  return {
    type: UPDATE_VISITOR_INFO__R
  };
};
export const UPDATE_VISITOR_INFO__S = 'UPDATE_VISITOR_INFO__S';
export function updateVisitorInfoSc(chatId, data) {
  return {
    payload: {chatId, data},
    type: UPDATE_VISITOR_INFO__S
  };
}
export const UPDATE_VISITOR_INFO__F = 'UPDATE_VISITOR_INFO__F';
export function updateVisitorInfoFl(error) {
  return {
    payload: error,
    type: UPDATE_VISITOR_INFO__F
  };
}
export const UPDATE_VISITOR_INFO = 'UPDATE_VISITOR_INFO';
export const updateVisitorInfo = (chatId, clientId, data) => (dispatch, getState) => {
  dispatch(updateVisitorInfoRq());
  return client.patch(`/clients/${clientId}`, data)
    .then(data => {
      dispatch(updateVisitorInfoSc(chatId, data));
    })
    .catch(error => {
      dispatch(updateVisitorInfoFl(error));
    });
};

export const READ_MESSAGE__R = 'READ_MESSAGE__R';
export const readMessageRq = () => {
  return {
    type: READ_MESSAGE__R
  };
};
export const READ_MESSAGE__S = 'READ_MESSAGE__S';
export function readMessageSc(data) {
  return {
    payload: data,
    type: READ_MESSAGE__S,
  };
}
export const READ_MESSAGE__F = 'READ_MESSAGE__F';
export function readMessageFl(error) {
  return {
    payload: error,
    type: READ_MESSAGE__F
  };
}
export const READ_MESSAGE = 'READ_MESSAGE';
export const readMessage = (last_read_message, chatId) => (dispatch, getState) => {
  dispatch(readMessageRq());
  return client.post(`/chats/${chatId}/read`, {
    last_read_message_at: last_read_message,
  })
    .then(data => {
      dispatch(readMessageSc(data));
    })
    .catch(error => {
      dispatch(readMessageFl(error))
    });
};


export const SEND_MESSAGE__R = 'SEND_MESSAGE__R';
export const sendMessageRq = () => {
  return {
    type: SEND_MESSAGE__R
  };
};
export const SEND_MESSAGE__S = 'SEND_MESSAGE__S';
export function sendMessageSc(message, chatId) {
  return {
    payload: {
      message, chatId
    },
    type: SEND_MESSAGE__S,
  };
}
export const SEND_MESSAGE__F = 'SEND_MESSAGE__F';
export function sendMessageFl(error) {
  console.log(error)
  return {
    payload: error,
    type: SEND_MESSAGE__F
  };
}
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const sendMessage = (message, chatId) => (dispatch, getState) => {
  dispatch(sendMessageRq());
  const state = getState();
  const widgetId = state.widgets.currentWidgetId;
  if (chatId in state.chats.chatsById) {
    return client.post(`/chats/${chatId}/messages`, message)
      .then(data => {
        dispatch(sendMessageSc(data, chatId));
        return true;
      })
      .catch(error => {
        dispatch(sendMessageFl(error));
        return {error}
      });
  } else {
    return dispatch(fetchChats({
      widget_id: widgetId,
      chat_id: chatId
    }, 'next'))
      .then(() => {
        if (state.chats.selectedChatId === chatId) {
          return dispatch(fetchChat({
            widget_id: widgetId,
            chat_id: chatId
          }))
            .then(() => {
              return client.post(`/chats/${chatId}/messages`, message)
                .then(data => {
                  dispatch(sendMessageSc(data, chatId));
                  return true;
                })
                .catch(error => {
                  dispatch(sendMessageFl(error));
                  return {error}
                });
            });
        } else {
          return client.post(`/chats/${chatId}/messages`, message)
            .then(data => {
              dispatch(sendMessageSc(data, chatId));
              return true;
            })
            .catch(error => {
              dispatch(sendMessageFl(error));
              return {error}
            });
        }
      });
  }
};

export const SEND_FILES = 'SEND_FILES';
export const sendFiles = (files) => (dispatch, getState) => {
  let formData = new FormData();
  for (const k in files) {
    formData.append(k, files[k]);
  }
  return client.post(`/filemanager/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${getAccessToken()}`,
      }
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return error;
    });
};


export const GETTING_NEW_CHAT = 'GETTING_NEW_CHAT';
export const gettingNewChat = (data) => (dispatch, getState) => {
  const state = getState();
  return {
    payload: {chat: data, currentOperator: state.operators.currentOperator},
    type: GETTING_NEW_CHAT,
  }
}

export const UPDATING_CHAT = 'UPDATING_CHAT';
export function updatingChat(data) {
  return {
    payload: data,
    type: UPDATING_CHAT,
  }
}

export const DELETING_CHAT = 'DELETING_CHAT';
export function deletingChat(data) {
  return {
    payload: data,
    type: DELETING_CHAT,
  }
}

export const START_TYPING = 'START_TYPING';
export function startTyping(data) {
  return {
    payload: data,
    type: START_TYPING
  }
}

export const END_TYPING = 'END_TYPING';
export function endTyping(data) {
  return {
    payload: data,
    type: END_TYPING
  }
}


export const GETTING_NEW_MESSAGE = 'GETTING_NEW_MESSAGE';
export const gettingNewMessage = (data) => (dispatch, getState) => {
  const state = getState();
  const currentWidgetId = state.widgets.currentWidgetId;
  const selectedChatId = state.chats.selectedChatId;
  const chatsById = state.chats.chatsById;
  if (chatsById && data.chat_id in chatsById) {
    return dispatch({
      payload: {message: data, currentOperator: state.operators.currentOperator},
      type: GETTING_NEW_MESSAGE,
    });
  } else {
    return dispatch(fetchChats({
      widget_id: currentWidgetId,
      chat_id: data.chat_id
    }, 'next'))
      .then(() => {
        if (selectedChatId === data.chat_id) {
          return dispatch(fetchChat({
            widget_id: currentWidgetId,
            chat_id: data.chat_id
          }))
            .then(() => {
              return dispatch({
                payload: {message: data, currentOperator: state.operators.currentOperator},
                type: GETTING_NEW_MESSAGE,
              });
            });
        } else {
          return dispatch({
            payload: {message: data, currentOperator: state.operators.currentOperator},
            type: GETTING_NEW_MESSAGE,
          });
        }
      });
  }

}

export const UPDATING_MESSAGE = 'UPDATING_MESSAGE';
export function updatingMessage(data) {
  return {
    payload: data,
    type: UPDATING_MESSAGE,
  }
}

export const DELETING_MESSAGE = 'DELETING_MESSAGE';
export function deletingMessage(data) {
  return {
    payload: data,
    type: DELETING_MESSAGE,
  }
}

export const UPDATING_CLIENT = 'UPDATING_CLIENT';
export function updatingClient(data) {
  return {
    payload: data,
    type: UPDATING_CLIENT,
  }
}

export const RESET_SEARCH = 'RESET_SEARCH';
export const resetSearch = () => {
  return {
    type: RESET_SEARCH
  };
};
export const SEARCH_MESSAGES__R = 'SEARCH_MESSAGES__R';
export const searchMessagesRq = () => {
  return {
    type: SEARCH_MESSAGES__R
  };
};
export const SEARCH_MESSAGES__S = 'SEARCH_MESSAGES__S';
export function searchMessagesSc(results, pagination) {
  return {
    payload: {results, pagination},
    type: SEARCH_MESSAGES__S
  };
}
export const SEARCH_MESSAGES__F = 'SEARCH_MESSAGES__F';
export function searchMessagesFl(error) {
  return {
    payload: error,
    type: SEARCH_MESSAGES__F
  };
}
export const SEARCH_MESSAGES = 'SEARCH_MESSAGES';
export const searchMessages = params => (dispatch, getState) => {
  dispatch(searchMessagesRq());
  return client.get('/messages', {
    params: {
      ...params,
      expand_chat: true
    }
  })
    .then(data => {
      dispatch(searchMessagesSc(data, params.offset));
      return data;
    })
    .catch(error => {
      dispatch(searchMessagesFl(error))
    });
};



//------ Filters -------//

export const SET_CHANNEL_TYPE_FILTER = 'SET_CHANNEL_TYPE_FILTER';
export function setChannelTypeFilter(value) {
  return {
    payload: value,
    type: SET_CHANNEL_TYPE_FILTER,
  }
}

export const SET_OPERATOR_FILTER = 'SET_OPERATOR_FILTER';
export function setOperatorFilter(value) {
  return {
    payload: value,
    type: SET_OPERATOR_FILTER,
  }
}

export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export function setStatusFilter(value) {
  return {
    payload: value,
    type: SET_STATUS_FILTER,
  }
}

export const SET_CHAT_SORT = 'SET_CHAT_SORT';
export function setChatSort(value) {
  return {
    payload: value,
    type: SET_CHAT_SORT,
  }
}

export const SELECT_CHAT = 'SELECT_CHAT';
export function selectChat(id) {
  return {
    payload: id,
    type: SELECT_CHAT,
  }
}

export const SET_INITIAL_CHATS_STATE = 'SET_INITIAL_CHATS_STATE';
export function setInitialChatsState() {
  return {
    type: SET_INITIAL_CHATS_STATE,
  }
}

export const ASK_CHAT_RATING__R = 'ASK_CHAT_RATING__R';
export const askChatRatingRq = () => {
  return {
    type: ASK_CHAT_RATING__R
  };
};
export const ASK_CHAT_RATING__S = 'ASK_CHAT_RATING__S';
export function askChatRatingSc(data) {
  return {
    payload: data,
    type: ASK_CHAT_RATING__S,
  };
}
export const ASK_CHAT_RATING__F = 'ASK_CHAT_RATING__F';
export function askChatRatingFl(error) {
  return {
    payload: error,
    type: ASK_CHAT_RATING__F
  };
}
export const ASK_CHAT_RATING = 'ASK_CHAT_RATING';
export const askChatRating = (chatId) => (dispatch, getState) => {
  dispatch(askChatRatingRq());
  return client.post(`/chats/${chatId}/ask_rating`)
    .then(data => {
      dispatch(askChatRatingSc(data));
    })
    .catch(error => {
      dispatch(askChatRatingFl(error))
    });
};

export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';
export function toggleNotification(value) {
  return {
    payload: value,
    type: TOGGLE_NOTIFICATION,
  }
}

export const OFF_NEW_MESSAGE_AUDIO_PLAY = 'OFF_NEW_MESSAGE_AUDIO_PLAY';
export function offNewMessageAudioPlay(value) {
  return {
    payload: value,
    type: OFF_NEW_MESSAGE_AUDIO_PLAY,
  }
}

export const OFF_NEW_CHAT_AUDIO_PLAY = 'OFF_NEW_CHAT_AUDIO_PLAY';
export function offNewChatAudioPlay(value) {
  return {
    payload: value,
    type: OFF_NEW_CHAT_AUDIO_PLAY,
  }
}

export const SEND_EMAIL__R = 'SEND_EMAIL__R';
export const sendEmailRq = () => {
  return {
    type: SEND_EMAIL__R
  };
};
export const SEND_EMAIL__S = 'SEND_EMAIL__S';
export function sendEmailSc(operators) {
  return {
    payload: operators,
    type: SEND_EMAIL__S
  };
}
export const SEND_EMAIL__F = 'SEND_EMAIL__F';
export function sendEmailFl(error) {
  return {
    payload: error,
    type: SEND_EMAIL__F
  };
}
export const SEND_EMAIL = 'SEND_EMAIL';
export const sendEmail = (data) => (dispatch, getState) => {
  dispatch(sendEmailRq());
  return client.post(`/mail/send`, data)
    .then(data => {
      dispatch(sendEmailSc(data));
    })
    .catch(error => {
      dispatch(sendEmailFl(error))
    });
};
