import React, {useEffect, useRef, useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Alert from "react-s-alert";
import {useTablePagination} from 'hooks';
import {t} from 'utils';


export default function MeetingsTable(props) {
  const {meetingsToday} = props;

  const tableHeaders = ['name', 'duration', 'meetingTime', 'meetingLink'];
  const {itemsOnPage} = useTablePagination(meetingsToday);

  const copyLink = (text, result) => {
    if (result) {
      Alert.success('Link has been copied');
    } else {
      Alert.error('An error occurred while copying');
    }
  };

  const _t = (value, values = {}, children = null) => {
    return t(`meetings.${value}`, values, children);
  };

  return (
    <div className='content-white-block grow-w'>
      <div className='page-content-header-div hor-flex justify-left'>
        <div className='group-title'>
          {_t('table.title.have')} {meetingsToday.length} {_t('table.title.meetings')}
        </div>
      </div>
      <div className='content-white-block table-container-2'>
        <div className="table-w">
          <div className="table-row table-header">
            {tableHeaders.map((header,index) =>
              <div key={header} className={`table-col1 ${index === 0 ? 'time-col' : ''}`}>
                <div className="details-header">{_t(`table.header.${header}`)}</div>
              </div>
            )}
          </div>
          <div className="table-content">
            {meetingsToday.length > 0 && itemsOnPage.map((meeting, i) =>
              <div key={meeting.id} className='table-row'>
                <div className='table-col1 time-col'>
                  <div className='cp-info'>
                    <strong>{meeting.name}</strong> / {meeting.email}
                  </div>
                </div>
                <div className='table-col1'>
                  <div className='cp-info'>
                    {meeting.duration} minutes
                  </div>
                </div>
                <div className='table-col1'>
                  <div className='cp-info'>
                    {meeting.meetingTime}
                  </div>
                </div>
                <div className='table-col1'>
                  <CopyToClipboard text={meeting.meetingLink}
                                   onCopy={copyLink}>
                    <div className="action-btn right-indent w-inline-block">
                      <div><span className="awesome-icon"></span> {_t('table.copy.button')}</div>
                    </div>
                  </CopyToClipboard>

                </div>
              </div>
            )}
          </div>
        </div>
        <div className='table-settings right-float'>
          <a href='https://calendar.google.com/calendar/r?tab=wc' target="_blank" className='link-2'>{_t('go.to.googleCalendar')}</a>
        </div>
      </div>
    </div>
  );
}