import _ from 'lodash';
import update from 'immutability-helper';
import * as actions from 'actions';
import {TARIFF_PERIODS} from 'constants.js';


const initialState = {
  planTextFields: {
    plan: null,
    managers_limit: 'Chat managers',
    widget_bot_chats: 'Widget bot chats',
    landing_bot_chats: 'Landing bot chats',
    fb_messenger_bot_chats: 'FB messenger bot chats',
    websites: 'Websites',
    live_chats: 'Live chats',
    welcome_campaigns: 'Welcome campaigns',
    widget_bot_campaigns: 'Widget bot campaigns',
    landing_bot_campaigns: 'Landing bot campaigns',
    live_visitors: 'Live visitors',
    live_typing: 'Live typing',
    meetings: 'Meetings',
    callback_software: 'Callback software',
    removable_branding: 'Removable heycom branding',
    support_manager: 'Support manager'
  },
  planFeatures: {
    [TARIFF_PERIODS.month]: [],
    [TARIFF_PERIODS.year]: []
  },

  paymentHistoryById: {},
  paymentHistory: [],
  userCurrentPlan: {},
  selectedPlan: null,
  selectedPeriod: null,

  userCard: {},

  errors: null,
  confirmPaymentError: null,
  userCardError: null,
  paymentError: null,

  paymentHistoryCount: 2,
  plansError: null,
  paymentHistoryError: null,
  userCurrentPlanError: null,
  _isPlansFetching: false,
  _isPaymentHistoryFetching: false,

  _isUserCardFetching: false,
  _isUserCardSetting: false,

  _isUserCurrentPlanFetching: false,

  _isPaymentBeingConfirmed: false,
  _isPaymentConfirmationSuccessful: false,

  _isPlanCheckingout: false,
  _isPlanBuying: false,
  _isSuccessfulPlanBuying: false,

  _isPaymentCheckingout: false,
  _isPaymentMaking: false,
  _isPaymentDeleting: false

};

export default function billing(state=initialState, action) {
  switch(action.type) {
    case actions.FETCH_PLANS__R: {
      return update(state, {
        _isPlansFetching: {$set: true}
      });
    }
    case actions.FETCH_PLANS__S: {
      const planFeatures = getPeriodicityPlans(state, action.payload.sort((a, b) => {
        if (a.amount < b.amount) return -1;
        if (a.amount > b.amount) return 1;
        return 0;
      }));
      return update(state, {
        planFeatures: {$set: planFeatures},
        plansError: {$set: null},
        _isPlansFetching: {$set: false}
      });
    }
    case actions.FETCH_PLANS__F: {
      return update(state, {
        plansError: {$set: action.payload},
        _isPlansFetching: {$set: false}
      });
    }
    case actions.SET_SELECTED_PLAN: {
      return update(state, {
        selectedPlan: {$set: action.payload},
        _isSuccessfulPlanBuying: {$set: false}
      });
    }

    case actions.FETCH_USER_PAYMENT_HISTORY__R: {
      return update(state, {
        _isPaymentHistoryFetching: {$set: true}
      });
    }
    case actions.FETCH_USER_PAYMENT_HISTORY__S: {
      return update(state, {
        paymentHistory: {$set: action.payload.payload},
        paymentHistoryById: {$set: action.payload.payload.reduce((obj, cur) => {
          obj[cur.id] = cur;
          return obj;
        }, {})},
        paymentHistoryCount: {$set: action.payload.count},
        paymentHistoryError: {$set: null},
        _isPaymentHistoryFetching: {$set: false}
      });
    }
    case actions.FETCH_USER_PAYMENT_HISTORY__F: {
      return update(state, {
        paymentHistoryError: {$set: action.payload},
        _isPaymentHistoryFetching: {$set: false}
      });
    }

    case actions.ADD_PAYMENT: {
      const paymentHistory = _.cloneDeep(state.paymentHistory);
      const ind = paymentHistory.findIndex((item) => item.id === action.payload.id);
      if (ind === -1) {
        return update(state, {
          paymentHistory: {$unshift: [action.payload]},
          paymentHistoryCount: {$set: state.paymentHistoryCount + 1},
          userCurrentPlanError: {$set: null},
        });
      } else {
        paymentHistory[ind] = action.payload;
        return update(state, {
          paymentHistory: {$set: paymentHistory},
          userCurrentPlanError: {$set: null},
        });
      }
    }

    case actions.UPDATE_PAYMENT: {
      const paymentHistory = _.cloneDeep(state.paymentHistory);
      const ind = paymentHistory.findIndex((item) => item.id === action.payload.id);
      if (ind === -1) {
        return update(state, {
          paymentHistory: {$unshift: [action.payload]},
          paymentHistoryCount: {$set: state.paymentHistoryCount + 1},
          userCurrentPlanError: {$set: null},
        });
      } else {
        paymentHistory[ind] = action.payload;
        return update(state, {
          paymentHistory: {$set: paymentHistory},
          userCurrentPlanError: {$set: null},
        });
      }
    }

    case actions.FETCH_USER_CURRENT_PLAN__R: {
      return update(state, {
        _isUserCurrentPlanFetching: {$set: true}
      });
    }
    case actions.FETCH_USER_CURRENT_PLAN__S: {
      return update(state, {
        userCurrentPlan: {$set: action.payload},
        userCurrentPlanError: {$set: null},
        _isUserCurrentPlanFetching: {$set: false}
      });
    }
    case actions.FETCH_USER_CURRENT_PLAN__F: {
      return update(state, {
        userCurrentPlanError: {$set: action.payload},
        _isUserCurrentPlanFetching: {$set: false}
      });
    }

    case actions.SUBSCRIBE_TO_PLAN: {
      return update(state, {
        userCurrentPlan: {$set: action.payload},
        userCurrentPlanError: {$set: null},
      });
    }

    /* --- Fetch Card --- */

    case actions.FETCH_USER_CARD__R: {
      return update(state, {
        errors: {$set: null},
        _isFailed: {$set: false},
        _isFetching: {$set: true},
        _isSuccessfulPlanBuying: {$set: false},
        _isUserCardFetching: {$set: true}
      });
    }

    case actions.FETCH_USER_CARD__S: {
      return update(state, {
        userCard: {$set: action.payload.card},
        _isFetching: {$set: false},
        _isUserCardFetching: {$set: false},
      });
    }

    case actions.FETCH_USER_CARD__F: {
      return update(state, {
        errors: {$set: action.payload},
        _isFailed: {$set: true},
        _isFetching: {$set: false},
        _isUserCardFetching: {$set: false}
      });
    }


    /* --- Set card --- */

    case actions.SET_USER_CARD__R: {
      return update(state, {
        userCardError: {$set: null},
        _isUserCardSetting: {$set: true},
      });
    }

    case actions.SET_USER_CARD__S: {
      return update(state, {
        userCard: {$merge: action.payload.card},
        _isUserCardSetting: {$set: false}
      });
    }

    case actions.SET_USER_CARD__F: {
      return update(state, {
        userCardError: {$set: action.payload},
        _isUserCardSetting: {$set: false}
      });
    }


    /* --- Delete payment --- */

    case actions.DELETE_PAYMENT__R: {
      return update(state, {
        paymentError: {$set: null},
        _isPaymentDeleting: {$set: true},
      });
    }

    case actions.DELETE_PAYMENT__S: {
      const paymentId = action.payload;

      return update(state, {
        paymentHistoryById: {$unset: [paymentId]},
        _isPaymentDeleting: {$set: false}
      });
    }

    case actions.DELETE_PAYMENT__F: {
      return update(state, {
        paymentError: {$set: action.payload},
        _isPaymentDeleting: {$set: false}
      });
    }


    /* --- Clear card state --- */

    case actions.CLEAR_USER_CARD_ERROR: {
      return update(state, {
        userCardError: {$set: null},
      });
    }

    case actions.CLEAR_BILLING_ERRORS: {
      return update(state, {
        userCardError: {$set: null},
        errors: {$set: null},
      });
    }

    /* --- Buy plan --- */

    case actions.BUY_PLAN__R: {
      return update(state, {
        errors: {$set: null},
        _isFailed: {$set: false},
        _isPlanCheckingout: {$set: true},
        _isPlanBuying: {$set: true},
        _isSuccessfulPlanBuying: {$set: false}
      });
    }

    case actions.CHECKOUT_PLAN__S: {
      return update(state, {
        _isPlanCheckingout: {$set: false},
      });
    }

    case actions.BUY_PLAN__S: {
      return update(state, {
        _isPlanCheckingout: {$set: false},
        _isPlanBuying: {$set: false},
        _isSuccessfulPlanBuying: {$set: true},
      });
    }

    case actions.BUY_PLAN__F: {
      return update(state, {
        errors: {$set: action.payload},
        _isFailed: {$set: true},
        _isPlanCheckingout: {$set: false},
        _isPlanBuying: {$set: false}
      });
    }


    /* --- Pay for current plan --- */

    case actions.PAY_PAYMENT__R: {
      return update(state, {
        errors: {$set: null},
        _isFailed: {$set: false},
        _isPaymentCheckingout: {$set: true},
        _isPaymentMaking: {$set: true},
      });
    }

    case actions.CHECKOUT_PAYMENT__S: {
      return update(state, {
        _isPaymentCheckingout: {$set: false},
      });
    }

    case actions.PAY_PAYMENT__S: {
      const {payment} = action.payload;
      state = updateStateWithPayments(state, payment);
      return update(state, {
        _isPaymentCheckingout: {$set: false},
        _isPaymentMaking: {$set: false},
      });
    }

    case actions.PAY_PAYMENT__F: {
      return update(state, {
        errors: {$set: action.payload},
        _isFailed: {$set: true},
        _isPaymentCheckingout: {$set: false},
        _isPaymentMaking: {$set: false},
      });
    }

    /* --- Fetch payment --- */

    case actions.FETCH_PAYMENT__R: {
      return state;
    }

    case actions.FETCH_PAYMENT__S: {
      const {payment} = action.payload;
      return updateStateWithPayments(state, payment);
    }

    case actions.FETCH_PAYMENT__F: {
      return state;
    }


    /* --- Fetch specific payments --- */

    case actions.FETCH_SPECIFIC_PAYMENTS__R: {
      return state;
    }

    case actions.FETCH_SPECIFIC_PAYMENTS__S: {
      const {payments} = action.payload;
      return updateStateWithPayments(state, payments);
    }

    case actions.FETCH_SPECIFIC_PAYMENTS__F: {
      return state;
    }


    /* --- Fetch last payment transaction --- */

    case actions.CONFIRM_LAST_PAYMENT__R: {
      return update(state, {
        confirmPaymentError: {$set: null},
        _isPaymentBeingConfirmed: {$set: true},
        _isPaymentConfirmationSuccessful: {$set: false},
      });
    }

    case actions.CONFIRM_LAST_PAYMENT__S: {
      const {payment} = action.payload;
      state = updateStateWithPayments(state, payment);
      return update(state, {
        confirmPaymentError: {$set: null},
        _isPaymentBeingConfirmed: {$set: false},
        _isPaymentConfirmationSuccessful: {$set: true},
      });
    }

    case actions.CONFIRM_LAST_PAYMENT__F: {
      return update(state, {
        confirmPaymentError: {$set: action.payload},
        _isPaymentBeingConfirmed: {$set: false},
        _isPaymentConfirmationSuccessful: {$set: false},
      });
    }

    default:
      return state;
  }
}

const updateStateWithPayments = (state, payments) => {
  if (!payments) {
    return state;
  }

  if (!_.isArray(payments)) {
    payments = [payments];
  }

  payments.forEach(payment => {
    const paymentIdx = state.paymentHistoryById[payment.id];
    if (paymentIdx >= 0) {
      state = update(state, {
        paymentHistoryById: {[paymentIdx]: {$set: payment}},
      });
    }
  });

  return state;
};


const getPeriodicityPlans = (state, plans) => {
  let monthlyPlans = {
    plan: [state.planTextFields.plan, []],
    managers_limit: [state.planTextFields.managers_limit, []],
    widget_bot_chats: [state.planTextFields.widget_bot_chats, []],
    landing_bot_chats: [state.planTextFields.landing_bot_chats, []],
    fb_messenger_bot_chats: [state.planTextFields.fb_messenger_bot_chats, []],
    websites: [state.planTextFields.websites, []],
    live_chats: [state.planTextFields.live_chats, []],
    welcome_campaigns: [state.planTextFields.welcome_campaigns, []],
    widget_bot_campaigns: [state.planTextFields.widget_bot_campaigns, []],
    landing_bot_campaigns: [state.planTextFields.landing_bot_campaigns, []],
    live_visitors: [state.planTextFields.live_visitors, []],
    live_typing: [state.planTextFields.live_typing, []],
    meetings: [state.planTextFields.meetings, []],
    callback_software: [state.planTextFields.callback_software, []],
    removable_branding: [state.planTextFields.removable_branding, []],
    support_manager: [state.planTextFields.support_manager, []]
  };
  let yearlyPlans = {
    plan: [state.planTextFields.plan, []],
    managers_limit: [state.planTextFields.managers_limit, []],
    widget_bot_chats: [state.planTextFields.widget_bot_chats, []],
    landing_bot_chats: [state.planTextFields.landing_bot_chats, []],
    fb_messenger_bot_chats: [state.planTextFields.fb_messenger_bot_chats, []],
    websites: [state.planTextFields.websites, []],
    live_chats: [state.planTextFields.live_chats, []],
    welcome_campaigns: [state.planTextFields.welcome_campaigns, []],
    widget_bot_campaigns: [state.planTextFields.widget_bot_campaigns, []],
    landing_bot_campaigns: [state.planTextFields.landing_bot_campaigns, []],
    live_visitors: [state.planTextFields.live_visitors, []],
    live_typing: [state.planTextFields.live_typing, []],
    meetings: [state.planTextFields.meetings, []],
    callback_software: [state.planTextFields.callback_software, []],
    removable_branding: [state.planTextFields.removable_branding, []],
    support_manager: [state.planTextFields.support_manager, []]
  };
  plans.forEach(plan => {
    let tempPlans = {};
    if (plan.interval === TARIFF_PERIODS.month && plan.amount !== 0) {
      monthlyPlans = formTempPlansArray(monthlyPlans, plan);
    }
    if (plan.interval === TARIFF_PERIODS.year && plan.amount !== 0) {
      yearlyPlans = formTempPlansArray(yearlyPlans, plan);
    }

    if (plan.amount === 0) {
      monthlyPlans = formTempPlansArray(monthlyPlans, plan);
      yearlyPlans = formTempPlansArray(yearlyPlans, plan);
    }
  });

  return {
    [TARIFF_PERIODS.month]: monthlyPlans,
    [TARIFF_PERIODS.year]: yearlyPlans
  }
};

const formTempPlansArray = (tempPlans, plan) => {
  tempPlans.plan[1].push({
    name: plan.name,
    price: plan.interval === TARIFF_PERIODS.year ? Math.round(plan.amount / 12) / 100 : plan.amount / 100,
    id: plan.id,
    period: plan.interval
  });
  tempPlans.managers_limit[1].push(plan.managers_limit);
  tempPlans.widget_bot_chats[1].push(plan.widget_bot_chats_limit);
  tempPlans.landing_bot_chats[1].push(plan.landing_bot_chats_limit);
  tempPlans.fb_messenger_bot_chats[1].push(plan.facebook_messenger_bot_chats_limit);
  tempPlans.websites[1].push(plan.websites);
  tempPlans.live_chats[1].push(plan.live_chats);
  tempPlans.welcome_campaigns[1].push(plan.welcome_campaigns);
  tempPlans.widget_bot_campaigns[1].push(plan.widget_bot_campaigns);
  tempPlans.landing_bot_campaigns[1].push(plan.landing_bot_campaigns);
  tempPlans.live_visitors[1].push(plan.live_visitors);
  tempPlans.live_typing[1].push(plan.live_typing);
  tempPlans.meetings[1].push(plan.meetings);
  tempPlans.callback_software[1].push(plan.callback_software);
  tempPlans.removable_branding[1].push(plan.removable_branding);
  tempPlans.support_manager[1].push(plan.support_manager);

  return tempPlans;
};
