import React, { Component } from 'react';
import moment from 'moment';
import {CartesianGrid, Legend, Line, LineChart, Area, AreaChart, Tooltip, XAxis, YAxis, ResponsiveContainer} from 'recharts';
//import FormattedMessage from 'components/widgets/FormattedMessage';


export default function Chart(props) {
  const {data, chartSettings, dataField} = props;

  return (
    <div className="campaign-preview-item">
      <div className="cp-info-block chat-container">
        <ResponsiveContainer width="100%" height="96%">
          <AreaChart data={data['chartData']} margin={{top: 5, right: 30, left: 0, bottom: 5}}>
            <Legend verticalAlign="top" height={36} wrapperStyle={{"left": "0px"}}
                    iconType="square" iconSize={13} align="left"/>
            <XAxis dataKey={data['tickFormat'] === 'hours' ? 'time' : 'date'} type={'category'}
                   tick={{width: 100, fontSize: 12, paddingBottom: 25}}
                   allowDataOverflow={true}/>
            <YAxis interval={1}/>
            <CartesianGrid strokeDasharray="0 0"/>
            <Tooltip/>
            {
              Object.values(chartSettings['lines']).map(line =>
                <Area key={line.key} name={line.name} type="linear"
                      dataKey={line.key} stroke={line.color} fill={line.color} isAnimationActive={false}/>
              )
            }
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

{/*<CustomTooltip dataField={dataField}*/}
               {/*labelFormat={data['tickFormat'] === 'hours' ? 'HH:mm' : 'YYYY-MM-DD'}/>*/}
// function CustomTooltip(props) {
//   const { active, labelFormat, dataField } = props;
//   const { label } = props;
//   const payload = props.payload || [];
//   if (!active) {
//     return null;
//   }
//   return (
//     <div className="recharts-custom-tooltip">
//       <div className="label">{`${moment.unix(label).format(labelFormat)}`}</div>
//       <div className="items">
//         {payload.map((item, i) =>
//           <div key={i} className="item">
//             <div className="square" style={{"backgroundColor" : item.color}}></div>
//             {(dataField === 'status') ? (
//               <>{item.value}&nbsp;<FormattedMessage id="chart.tooltip"/></>
//             ) : (
//               <>{`${item.value} ${item.name.toLowerCase()}`}</>
//             )}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }