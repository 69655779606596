import React, {Fragment, useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import update from 'immutability-helper';

import ImageLoader from 'components/widgets/ImageLoader';
import {useSendFiles} from 'hooks';
import {t} from 'utils';


export default function MediaWrapper(props) {
  const {loaderType, value, onChangeType, onChangeValue, onDelete} = props;
  const [menuVisibility, setMenuVisibility] = useState(false);

  const handleTypeChange = (type) => {
    setMenuVisibility(false);
    onChangeType(type)
  };

  return (
    <div className={`dn-media-w ${loaderType === 'file' ? 'image-type' : ''}`}>
      {onChangeType && (
        <div className="diagram-node-control settings-control node-control-animation z-index-10">
          <div data-delay="0" className="control-btn w-dropdown">
            <div className="toggle-control-btn w-dropdown-toggle">
              <div onClick={() => setMenuVisibility(!menuVisibility)}></div>
              <div className="drop-icon hide-icon w-icon-dropdown-toggle" onClick={onDelete}></div>
            </div>
            {onChangeType && (
              <nav className={`dropdown-list move-list w-dropdown-list ${menuVisibility ? 'w--open' : ''}`}>
                <div className="dropdown-link w-dropdown-link" onClick={() => handleTypeChange('file')}>{t('media.image.button')}</div>
                <div className="dropdown-link w-dropdown-link" onClick={() => handleTypeChange('link')}>{t('media.url.button')}</div>
                <div className="dropdown-link display-none w-dropdown-link" onClick={() => handleTypeChange('giphy')}>{t('media.giphy.button')}</div>
                <div className="dropdown-link w-dropdown-link" onClick={() => handleTypeChange('youtube')}>{t('media.youtube.button')}</div>
              </nav>
            )}
          </div>
          <div className="control-btn more-size w-inline-block" onClick={onDelete}>
            <div></div>
          </div>
        </div>
      )}
      {loaderType === 'file' && <ImageLoader value={value} defaultImgUrl={require('styles/images/upload.svg')}
                                             imgClasses={'image-loader-icon'}
                                             onChange={onChangeValue}/>}
      {loaderType === 'link' && <URLLoader value={value} onChange={onChangeValue}/>}
      {loaderType === 'giphy' && <GiphyLoader value={value} onChange={onChangeValue}/>}
      {loaderType === 'youtube' && <YouTubeLoader value={value} onChange={onChangeValue}/>}

    </div>
  );
};

const URLLoader = (props) => {
  const {value, onChange} = props;
  return (
    <Fragment>
      <img src={require('styles/images/link.svg')} alt="" className="image-loader-icon" />
      <div>{t('media.url.title')}</div>
      <div className="upload-file-div">
        <input type="text" className="text-field-2 upload-input round w-input" maxLength="256"
               value={value} onChange={e => onChange({link: e.target.value})}
               data-name="Field 5" placeholder="https://..." required=""/></div>
    </Fragment>
  );
};

const GiphyLoader = (props) => {
  const {value, onChange} = props;
  return (
    <Fragment>
      <img src={require('styles/images/5842a962a6515b1e0ad75b04.png')} alt="" className="image-loader-icon wide-logo"/>
      <div className="upload-file-div">
        {t('media.giphy.placeholder', {},
          msg => <input type="text" className="text-field-2 upload-input round w-input"
                        value={value} onChange={e => onChange({link: e.target.value})}
                        maxLength="256" placeholder={msg} id="field-5" required=""/>)}
        </div>
    </Fragment>
  );
};

const YouTubeLoader = (props) => {
  const {value, onChange} = props;
  return (
    <Fragment>
      <img src={require('styles/images/youtube-logo.svg')} alt="" className="image-loader-icon wide-logo"/>
      <div>{t('media.youtube.title')}</div>
      <div className="upload-file-div">
        <input type="text" className="text-field-2 upload-input round w-input"
               value={value} onChange={e => onChange({link: e.target.value})}
               maxLength="256" placeholder="https://..." required=""/></div>
    </Fragment>
  );
};