import React, {Fragment, useState, useEffect, useRef} from 'react';
import Draggable from 'react-draggable';
import Alert from 'react-s-alert';
import update from 'immutability-helper';
import {BOT_NODE_TYPES, CAMPAIGN_TYPES} from 'constants.js';
import {ConnectionArrowSize} from 'constants/botEditor';
import NodeWrapper from './NodeWrapper';
import {useCampaigns, useBuildBot, useHistoryEvents} from 'hooks';
import {t, getConnectionCoordinates, ConnectionTipDirection} from 'utils';
import SubmitButton from "../../../../widgets/SubmitButton";


const EDITOR_WIDTH = 20000; //he square
const SCALE_STEP = 0.05;

export default function EditorWorkspace(props) {
  const {isCreate, campaignType, goToDisplaySettings, backOnPrevioslyStep} = props;
  const {nodes, currentNode, setCurrentNode, onChangeNodes, onDeleteNode, onCopyNode} = useBuildBot();
  const {historyItems, pointer, onUndoHistoryEvent, onRedoHistoryEvent} = useHistoryEvents();
  const {isCreating, isUpdating} = useCampaigns();

  const [scale, setScale] = useState(1);
  const [startDragPoint, setStartDragPoint] = useState({x: null, y: null});
  const [startDragging, setStartDragging] = useState(false);
  const [translate, setTranslate] = useState({x: 0, y: 0});
  const [scaleOffset, setScaleOffset] = useState({x: 0, y: 0});

  const [workspaceTranslate, setWorkspaceTranslate] = useState({
    transform: `translate(${translate.x}px, ${translate.y}px) translateZ(0) scale(${scale})`
  });
  const [workspaceScale, setWorkspaceScale] = useState({
    transform: `translateZ(0) scale(1)`,
    transformOrigin: '0% 0%'
  });

  const editorContainer = useRef();
  const scrollableContainer = useRef();

  useEffect(() => {
    if (editorContainer && editorContainer.current) {
      editorContainer.current.addEventListener('wheel', zoom, {passive: false});
    }
    return () => {
      if (editorContainer && editorContainer.current) {
        editorContainer.current.removeEventListener('wheel', zoom);
      }
    }
  }, [editorContainer, scale, translate.x, translate.y]);

  const zoom = (e) => {
    if (e.ctrlKey && nodes && Object.keys(nodes).length && (SCALE_STEP < scale)) {
      const nextScale = scale - (e.deltaY / 1000);
      e.preventDefault();
      const rect = editorContainer.current.getBoundingClientRect();

      changeScale(nextScale, e, e.pageX - rect.left, e.pageY - rect.top);
    }
  };

  const changeScale = (nextScale, e = null, offsetX = 0, offsetY = 0) => {
    //брать размеры view из editorContainer
    // если прибавляем к транслейту, то нужно отнимать для центрирования
    // если убавляем - то нужно прибавлять

    //const coef = 1// nextScale < 1 ? -1 : 1;
    //console.log(offsetX, offsetY)
    const additionalCoef = EDITOR_WIDTH * (nextScale - 1) / 2;
    const prevAdditionalCoef = EDITOR_WIDTH * (scale - 1) / 2;
    //console.log(additionalCoef, prevAdditionalCoef);
    if (!e) {
      //console.log(additionalCoef, prevAdditionalCoef)
      setScale(nextScale);
      const pointX = additionalCoef + (translate.x - prevAdditionalCoef);
      const pointY = additionalCoef + (translate.y - prevAdditionalCoef);
      setTranslate({x: pointX, y: pointY});
      setWorkspaceTranslate({
        transform: `translate(${pointX}px, ${pointY}px) translateZ(0) scale(${nextScale})`
      });
      return;
    }
    const x = offsetX - offsetX * nextScale; //(editorContainer.current.clientWidth / 2) - offsetX;
    const y = offsetY - offsetY * nextScale; //(editorContainer.current.clientHeight / 2) - offsetY;
    const pointX = additionalCoef + x + (translate.x - prevAdditionalCoef - scaleOffset.x);
    const pointY = additionalCoef + y + (translate.y - prevAdditionalCoef - scaleOffset.y);
    //console.log(offsetX, offsetY, pointX, pointY, x, y, translate);
    // trnasform-origin  - решить задачу через него
    setScale(nextScale);
    setScaleOffset({x: x, y: y});
    setTranslate({x: pointX, y: pointY});
    setWorkspaceTranslate({
      transform: `translate(${pointX}px, ${pointY}px) translateZ(0) scale(${nextScale})`
    });
  };

  const handleCoordinatesChange = (e, type) => {
    if (type === 'up' && startDragging) {
      setStartDragging(false);
      return;
    }
    if (!Object.keys(nodes).length) {
      return;
    }
    let node = e.target;
    while (node && (node.className.includes && !node.className.includes('diagram-node-wrap')) && (node.id !== 'editor-workspace-wrap')) {
      node = node.parentNode;
    }
    if (node && (node.id === 'editor-workspace-wrap')) {
      if (type === 'down') {
        setStartDragPoint({x: e.clientX, y: e.clientY});
        setStartDragging(true);
      } else {
        setStartDragging(false);
        //handleEditorMove({x: e.clientX, y: e.clientY});
      }
    }
  };

  const handleEditorMove = (endDragPoint) => {
    //console.log(translate, startDragPoint, endDragPoint)
    const x = translate.x - (startDragPoint.x - endDragPoint.x);
    const y = translate.y - (startDragPoint.y - endDragPoint.y);
    const currentWidth = EDITOR_WIDTH * scale;
    const translationByX = x <= (EDITOR_WIDTH * (scale - 1) / 2) ? (x < currentWidth ? x : currentWidth) : translate.x;
    const translationByY = y <= (EDITOR_WIDTH * (scale - 1) / 2) ? (y < currentWidth ? y : currentWidth) : translate.y;
    setWorkspaceTranslate({
      transform: `translate(${translationByX}px, ${translationByY}px) translateZ(0) scale(${scale})`
    });
    setStartDragPoint({x: endDragPoint.x, y: endDragPoint.y});
    setTranslate({x: translationByX, y: translationByY});
  };

  const _checkTransition = () => {
    const transitions = {};
    for (const id in nodes) {
      const node = nodes[id];
      if (nodes[id].next_block_id) {
        transitions[nodes[id].next_block_id] = transitions[nodes[id].next_block_id] || 1;
      }
      const content = nodes[id].content;
      if (content.buttons) {
        for (const button of content.buttons) {
          if (button.next_block_id) {
            transitions[button.next_block_id] = transitions[button.next_block_id] || 1;
          }

        }
      }
      if (content.cards) {
        for (const card of content.cards) {
          if (card.buttons) {
            for (const button of card.buttons) {
              if (button.next_block_id) {
                transitions[button.next_block_id] = transitions[button.next_block_id] || 1;
              }
            }
          }
        }
      }
    }
    //console.log(transitions)
    let check = Object.values(nodes).length === 0;
    for (const id in nodes) {
      //console.log(id);
      if (!transitions[id]) {
        check = true;
      }
    }
    return check;
  };

  const handleSaveChanges = () => {
    if (_checkTransition()) {
      goToDisplaySettings(nodes);
    } else {
      Alert.error('Loop transitions!');
    }
  }

  return (
      <div ref={editorContainer} id="editor-workspace-wrap" className="editor-workspace-wrap editor-wrap-custom"
            //onWheel={(e) => zoom(e)}
           onMouseMove={(e) => {
             if (startDragging) {
               handleEditorMove({x: e.clientX, y: e.clientY});
             }
           }}
           onMouseLeave={e => handleCoordinatesChange(e, 'up')}
           onMouseDown={e => handleCoordinatesChange(e, 'down')}
           onMouseUp={e => handleCoordinatesChange(e, 'up')}>
        {nodes && (!Object.keys(nodes).length ? (
          <div className="scrollable-editor-workspace" style={{height: '100%', width: '100%'}}>
            <div className="editor-workspace display-block">
              <div className="first-message-w">
                <div className="big-icon"></div>
                <div>{t('web.create.botBuilder.editor.title')}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="scrollable-editor-workspace" ref={scrollableContainer} style={{...workspaceTranslate}}>
            <Components nodes={nodes} workspaceTranslate={workspaceTranslate} workspaceScale={workspaceScale}
                        currentNode={currentNode} setCurrentNode={setCurrentNode}
                        onCopyNode={onCopyNode} onChangeNodes={onChangeNodes} onDeleteNode={onDeleteNode}
            />
          </div>
        ))}
        <Zoom componentCount={nodes ? Object.keys(nodes).length : 0} scale={scale} onChangeScale={(value) => changeScale(value)}/>
        <div className="editor-control-w">
          {!pointer ? (
            <div className={`vert-link control-disabled`}>
              <span className="awesome-icon"></span><br/>{t('web.create.botBuilder.editor.undo')}</div>
          ) : (
            <div className={`vert-link`} onClick={onUndoHistoryEvent}>
              <span className="awesome-icon"></span><br/>{t('web.create.botBuilder.editor.undo')}</div>
          )}
          {pointer === historyItems - 1 ? (
            <div className={`vert-link right-indent control-disabled`}>
              <span className="awesome-icon"></span><br/>{t('web.create.botBuilder.editor.redo')}</div>
          ) : (
            <div className={`vert-link right-indent`} onClick={onRedoHistoryEvent}>
              <span className="awesome-icon"></span><br/>{t('web.create.botBuilder.editor.redo')}</div>
          )}
          <div className="blue-button w-button" onClick={backOnPrevioslyStep}>
            {t('web.create.botBuilder.editor.back')}</div>

          {campaignType === CAMPAIGN_TYPES.facebookMessenger.value ? (
            <SubmitButton simpleButton={true} additionalClass={'left-indent w-button'}
                          onClick={handleSaveChanges}
                          isSending={(isCreating || isUpdating)}
                          onLoadEnd={() => {
                            window.location = '/campaigns';
                          }}
                          text={t(`${campaignType}.create.botBuilder.editor.goTo`)}/>
          ) : (
            <>
              {!isCreate ? (
                <SubmitButton simpleButton={true} additionalClass={'left-indent w-button'}
                              onClick={handleSaveChanges}
                              isSending={(isCreating || isUpdating)}
                              onLoadEnd={() => {window.location = '/campaigns'}}
                              text={t(`button.save`)}/>
              ) : (
                <div onClick={handleSaveChanges} className="button left-indent w-button">
                  {t(`${campaignType}.create.botBuilder.editor.goTo`)}
                </div>
              )}
            </>

          )}

        </div>
      </div>
  );
}


const Components = (props) => {
  const {nodes: nodesById, workspaceTranslate, currentNode, workspaceScale,
    setCurrentNode, onChangeNodes, onDeleteNode, onCopyNode} = props;

  const nodes = Object.values(nodesById);

  const lastRef = useRef(null);
  const [isComponentChanged, setIsComponentChanged] = useState(false);
  const [draggableNode, setDraggableNode] = useState(null);
  const [newTransition, setNewTransition] = useState({id: null, cardInd: null, buttonInd: null});

  useEffect(() => {
    setIsComponentChanged(true);
  }, [lastRef]);

  const onDrag = (e, position, id, node) => {
    //console.log('Не удалять! Ловится редкий баг 2', draggableNode, position)
    setDraggableNode({...draggableNode, position})
    // onChangeNodes({
    //   [id]: {position: {$set: {x: position.x, y: position.y}}}
    // }, true);
  };

  const handleTransitionStartAdd = (e, {id, cardInd, buttonInd}) => {
    if (e.nativeEvent.which === 3) {
      return;
    }
    setNewTransition(update(newTransition, {id: {$set: id},  cardInd: {$set: cardInd}, buttonInd: {$set: buttonInd}}));
  };

  const handleTransitionEndAdd = (e, id, isDelete = false, deleteFields = null) => {
    if (e.nativeEvent.which === 3 && !isDelete) {
      return;
    }
    const transition = isDelete ? deleteFields : newTransition;
    if (transition.id === id) {
      return;
    }
    if (transition.buttonInd !== null) {
      if (transition.cardInd !== null) {
        handleTransitionCardButtonEndAdd(e, id, transition);
      } else {
        handleTransitionButtonEndAdd(e, id, transition);
      }
    } else {
      if (transition.id) {
        onChangeNodes({
          [transition.id]: {next_block_id: {$set: id}}
        });
      }
    }
    setNewTransition({id: null, cardInd: null, buttonInd: null});
  };

  const handleTransitionButtonEndAdd = (e, id, transition) => {
    onChangeNodes({
      [transition.id]: {content: {buttons: {[transition.buttonInd]: {next_block_id: {$set: id}}}}}
    });
  };

  const handleTransitionCardButtonEndAdd = (e, id, transition) => {
    onChangeNodes({
      [transition.id]: {content: {cards: {[transition.cardInd]: {buttons: {[transition.buttonInd]: {next_block_id: {$set: id}}}}} }}
    });
  };

  const handleDeleteTransition = (event, {id, cardInd, buttonInd}) => {
    event.preventDefault();
    const result = window.confirm('Delete transition');
    if (result) {
      handleTransitionEndAdd(event, null, true, {id, cardInd, buttonInd})
    }
  };

  const handleDeleteNode = (node) => {
    onDeleteNode(node.id);
  };

  return (
    <>
      <div className="editor-workspace display-block user-select-none smooth-editor-draggable" style={workspaceScale}>
        {nodes.map((node, i) => {
          const {position} = draggableNode && draggableNode.id === node.id ? draggableNode : node;
          return (
            <Draggable handle=".digram-node-header" key={node.id}
                       position={position} defaultPosition={position} bounds="parent"
                       onStart={(e) => setDraggableNode(node)}
                       onDrag={(e, position) => onDrag(e, position, node.id)}
                       onStop={(e, position) => {
                         setDraggableNode(null)
                         onChangeNodes({
                           [node.id]: {position: {$set: {x: position.x, y: position.y}}}
                         });
                       }}>
              <div className="block-node" style={{position: 'relative', zIndex: position.x * position.y + position.y}}
                   ref={nodes.length === i + 1 ? lastRef : null}>
                <NodeWrapper node={node} setCurrentNode={setCurrentNode}
                             selectCurrentNode={currentNode}
                             onStartAddTransition={handleTransitionStartAdd}
                             onEndAddTransition={handleTransitionEndAdd}
                             //onDeleteTransition={handleDeleteTransition}
                             onChangeNodes={onChangeNodes}
                             onCopyNode={() => onCopyNode(node)}
                             onDeleteNode={() => handleDeleteNode(node)}/>
              </div>
            </Draggable>
          );
        })}
      </div>
      {isComponentChanged && (
        <svg className="svg-layout" xmlns="http://www.w3.org/2000/svg" style={{...workspaceScale, pointerEvents: 'none'}}>
          {nodes.map((node, i) => {
            const outElement = document.getElementById(`element-${node.id}`);
            const {position: outElementPosition}
              = draggableNode && draggableNode.id === node.id ? draggableNode : node;

            switch(node.type) {
              case BOT_NODE_TYPES.choiceButton: {
                const btnContainerElement = document.getElementById(`buttons-container-${node.id}`);
                if (!btnContainerElement) {
                  return null;
                }
                return node.content && node.content.buttons && node.content.buttons.map((button, buttonIdx) => {
                    if (!button.next_block_id) {
                      return null;
                    }
                    const outInnerElement = document.getElementById(`element-${node.id}-button-${buttonIdx}`);
                    if (!outInnerElement) {
                      return null;
                    }
                    const outInnerElementPosition = {
                      x: node.position.x,
                      // y: (buttonIdx * 48 + 22) + node.position.y + (btnContainerElement.offsetTop - 18)
                      y: node.position.y + outInnerElement.offsetTop,
                    };

                    // const transitionId = node.id + button.next_block_id + '_' + buttonIdx;
                    // const entryPointElement = document.getElementById(`drag-${node.id}-button-${buttonIdx}`);
                    // debugger;
                    // return getPath(button, nodesById, outElement, [], entryPointElement, position);
                    return <ArrowPath node={button} nodesById={nodesById}
                                      draggableNode={draggableNode}
                                      outMainElement={outElement}
                                      outMainElementPosition={outElementPosition}
                                      outInnerElement={outInnerElement}
                                      outInnerElementPosition={outInnerElementPosition}
                                      onDeleteTransition={(e) => handleDeleteTransition(e, {id: node.id, cardInd: null, buttonInd: buttonIdx})}/>
                  })
              }
              case BOT_NODE_TYPES.choiceCard: {
                return node.content && node.content.cards && node.content.cards.map((card, cardInd) => {
                    const btnContainerElement =
                      document.getElementById(`buttons-container-${node.id}-card-${cardInd}`);
                    if (!btnContainerElement) {
                      return null;
                    }
                    return card.buttons && card.buttons.map((button, buttonIdx) => {
                        if (!button.next_block_id) {
                          return null;
                        }
                        const outInnerElement =
                          document.getElementById(`element-${node.id}-card-${cardInd}-button-${buttonIdx}`);

                        if (!outInnerElement) {
                          return null;
                        }
                        const outInnerElementPosition = {
                          // x: 160 + node.position.x,
                          x: node.position.x,
                          // y: (buttonIdx * 48 + 23) + node.position.y + (btnContainerElement.offsetTop - 13)
                          y: node.position.y + outInnerElement.offsetTop,
                        };
                        // const transitionId = node.id + button.next_block_id + '_' + cardInd + '_' + buttonInd;
                        // const entryPointElement = document.getElementById(`drag-${node.id}-card-${cardInd}-button-${buttonInd}`);
                        // return getPath(button, nodesById, outElement, [], entryPointElement, position);
                        return <ArrowPath node={button} nodesById={nodesById}
                                          draggableNode={draggableNode}
                                          outMainElement={outElement}
                                          outMainElementPosition={outElementPosition}
                                          outInnerElement={outInnerElement}
                                          outInnerElementPosition={outInnerElementPosition}
                                          onDeleteTransition={(e) => handleDeleteTransition(e, {id: node.id, cardInd: cardInd, buttonInd: buttonIdx})}/>
                      });
                  })
              }
              default: {
                const {position} = draggableNode && draggableNode.id === node.id ? draggableNode : node;
                // const outElement = document.getElementById(`element-${node.id}`);
                // const entryPointElement = document.getElementById(`drag-${node.id}`);
                // const transitionId = node.id + node.next_block_id;
                // return getPath(node, nodesById, outElement, [], entryPointElement, position);
                // return getPath(node, nodesById, outElement, position, null, null);

                return <ArrowPath node={node} nodesById={nodesById}
                                  draggableNode={draggableNode}
                                  outMainElement={outElement} outMainElementPosition={position}
                                  onDeleteTransition={(e) => handleDeleteTransition(e, {id: node.id, cardInd: null, buttonInd: null})}/>
              }
            }
          })}
        </svg>
      )}
    </>
  )
};

const ArrowPath = (props) => {
  const {node, nodesById, draggableNode, outMainElement, outMainElementPosition,
    outInnerElement = null, outInnerElementPosition = null, onDeleteTransition} = props;
  const pathRef = useRef();
  const [deleteIconCoords, setCoordinates] = useState({x: -1000, y: -1000});

  useEffect(() => {
    if (pathRef && pathRef.current) {
      pathRef.current.addEventListener('mouseleave', hideDeleteIcon);
    }
    return () => {
      if (pathRef && pathRef.current) {
        pathRef.current.removeEventListener('mouseleave', hideDeleteIcon);
      }
    }
  }, []);

  const showDeleteIcon = (e) => {
    setCoordinates({x: e.offsetX - 50 - 15, y: e.offsetY - 70 - 15})
  };

  const hideDeleteIcon = () => {
    setCoordinates({x: -1000, y: -1000})
  };

  const transitionId = node.next_block_id;
  const inElement = document.getElementById(`element-${transitionId}`);
  // if (!entryPointElement || !inElement || !nodesById[transitionId]) {


  if (!outMainElement) {
    return null;
  }

  if (!inElement || !nodesById[transitionId]) {
    return null;
  }

  const {curX, curY, nextX, nextY, refPoints, arrowDirection} = getConnectionCoordinates({
    // component: {x: position.x, y: position.y},
    // componentMainElement: outMainElement,
    // componentInnerElement: outInnerElement,
    mainElement: outMainElement,
    mainElementPosition: {x: outMainElementPosition.x, y: outMainElementPosition.y},
    innerElement: outInnerElement,
    innerElementPosition: outInnerElementPosition && {x: outInnerElementPosition.x, y: outInnerElementPosition.y},
    attachPointType: 'right',
  }, {
    mainElement: inElement,
    mainElementPosition: {
      x: draggableNode && draggableNode.id === transitionId && draggableNode.position ? draggableNode.position.x : nodesById[transitionId].position.x,
      y: draggableNode && draggableNode.id === transitionId && draggableNode.position ? draggableNode.position.y : nodesById[transitionId].position.y
    },
    attachPointType: 'left',
  });

  return [
      <path
        d={`
        M${curX} ${curY}
        C ${refPoints[0].x} ${refPoints[0].y}, ${refPoints[1].x} ${refPoints[1].y}, ${nextX} ${nextY}
      `}
        stroke="#00c875"
        fill="transparent"
        strokeWidth="3"
        style={{stroke: '#00c875', pointerEvents: 'auto'}}
      />,

      <foreignObject x={deleteIconCoords.x} y={deleteIconCoords.y} width={'26px'} height={'26px'}
                     style={{pointerEvents: 'auto'}}>
        <div className="delete-connector" style={{pointerEvents: 'auto'}}
             onMouseMove={e => showDeleteIcon(e.nativeEvent)}>
          <div></div>
        </div>
      </foreignObject>,
      <path ref={pathRef} onMouseMove={e => showDeleteIcon(e.nativeEvent)}
            onClick={(e) => {e.stopPropagation(); e.preventDefault(); onDeleteTransition(e)}}
            d={`
              M${curX} ${curY}
              C ${refPoints[0].x} ${refPoints[0].y}, ${refPoints[1].x} ${refPoints[1].y}, ${nextX} ${nextY}
            `}
            stroke="transparent"
            fill="transparent"
            strokeWidth="5"
            style={{pointerEvents: 'auto'}}
      />,
      <ConnectionArrow
        bottomX={nextX}
        bottomY={nextY}
        direction={ConnectionTipDirection.reverse(arrowDirection)}
      />
  ];
};

const ConnectionArrow = props => {
  const arrowLength = ConnectionArrowSize.Length;
  const arrowWidth = ConnectionArrowSize.Width;
  const bottomDashLength = ConnectionArrowSize.BottomDashLength;

  const {bottomX, bottomY, direction} = props;
  let tipX = null;
  let tipY = null;
  let point1 = null;
  let point2 = null;
  let bottomDashStartX = null;
  let bottomDashStartY = null;
  let bottomDashEndX = null;
  let bottomDashEndY = null;

  // Up - ▲
  // Down - ▼
  // Left - ◄
  // Right - ►

  switch (direction) {
    case ConnectionTipDirection.Up: {
      tipX = bottomX;
      tipY = bottomY - arrowLength;
      bottomDashStartX = bottomX;
      bottomDashStartY = bottomY + 1;
      bottomDashEndX = bottomX;
      bottomDashEndY = bottomDashStartY - bottomDashLength;
      point1 = {
        // x: tipX + width / 2,
        x: bottomX + arrowWidth / 2,
        // y: tipY + length,
        y: bottomY,
      };
      point2 = {
        // x: tipX - width / 2,
        x: bottomX - arrowWidth / 2,
        // y: tipY + length,
        y: bottomY,
      };
      break;
    }

    case ConnectionTipDirection.Down: {
      tipX = bottomX;
      tipY = bottomY + arrowLength;
      bottomDashStartX = bottomX;
      bottomDashStartY = bottomY - 1;
      bottomDashEndX = bottomX;
      bottomDashEndY = bottomDashStartY + bottomDashLength;
      point1 = {
        // x: tipX - width / 2,
        x: bottomX - arrowWidth / 2,
        // y: tipY - length,
        y: bottomY,
      };
      point2 = {
        // x: tipX + width / 2,
        x: bottomX + arrowWidth / 2,
        // y: tipY - length,
        y: bottomY,
      };
      break;
    }

    case ConnectionTipDirection.Left: {
      tipX = bottomX - arrowLength;
      tipY = bottomY;
      bottomDashStartX = bottomX + 1;
      bottomDashStartY = bottomY;
      bottomDashEndX = bottomDashStartX - bottomDashLength;
      bottomDashEndY = bottomY;
      point1 = {
        // x: tipX + length,
        x: bottomX,
        // y: tipY - width / 2,
        y: bottomY - arrowWidth / 2,
      };
      point2 = {
        // x: tipX + length,
        x: bottomX,
        // y: tipY + width / 2,
        y: bottomY + arrowWidth / 2,
      };
      break;
    }

    case ConnectionTipDirection.Right: {
      tipX = bottomX + arrowLength;
      tipY = bottomY;
      bottomDashStartX = bottomX - 1;
      bottomDashStartY = bottomY;
      bottomDashEndX = bottomDashStartX + bottomDashLength;
      bottomDashEndY = bottomY;
      point1 = {
        // x: tipX - length,
        x: bottomX,
        // y: tipY + width / 2,
        y: bottomY + arrowWidth / 2,
      };
      point2 = {
        // x: tipX - length,
        x: bottomX,
        // y: tipY - width / 2,
        y: bottomY - arrowWidth / 2,
      };
      break;
    }

    default: {
    }
  }

  return (
    <g>
      <path
        fill="#00c875"
        d={`
          M ${tipX} ${tipY}
          L ${point1.x} ${point1.y}
          A ${Math.abs((point2.y - point1.y))} ${Math.abs((point2.y - point1.y))} 0 0 0 ${point2.x} ${point2.y}
          Z
        `}
        transform=""
        style={{'fill': '#00c875'}}>
      </path>
      <path
        d={`
          M ${bottomDashStartX} ${bottomDashStartY}
          L ${bottomDashEndX} ${bottomDashEndY}
        `}
        strokeWidth="3"
        style={{'stroke': 'rgb(125, 188, 61)'}}>
      </path>
    </g>
  );
};

const Zoom = (props) => {
  const scaleStep = 0.1;
  const {componentCount, scale, onChangeScale} = props;
  const onZoom = (direction, e = null) => {
    if (componentCount && (0.06 <= scale)){
      const nextScale = (direction === 'up' ? scale + scaleStep : scale - scaleStep) || 0.06;
      onChangeScale(nextScale);
    }
  };
  const [zoomPercent, setZoomPercent] = useState('');
  useEffect(() => {
    setZoomPercent(Math.round(scale * 100));
  }, [scale]);
  return (
    <div className="editor-zoom-w">
      <input type="text" value={zoomPercent} className="control-input w-input" maxLength="256" aria-valuemax={299}
             readOnly={true}
             // onBlur={(e) => {
             //   if (!isNaN(e.target.value) && (e.target.value >= 5)) {
             //     onChangeScale(zoomPercent / 100);
             //   } else {
             //     setZoomPercent(Math.round(scale * 100));
             //   }
             // }}
             // onKeyPress={e => {
             //   if (e.charCode === 13) {
             //     onChangeScale(zoomPercent / 100);
             //     e.preventDefault();
             //   }
             // }}
             // onChange={e => {
             //   if (/^[0-9]+$/.test(e.target.value.trim())) {
             //     setZoomPercent(e.target.value.trim())
             //   }
             // }}
             placeholder="100%" required=""/>
      <a href="#" className="zoom-btn w-inline-block" onClick={() => onZoom('up')}>
        <div></div>
      </a>
      <a href="#" className="zoom-btn no-indent w-inline-block" onClick={() => onZoom('down')}>
        <div></div>
      </a>
    </div>

  )
};
