import React, {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch, shallowEqual } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';

import {useSendFiles} from 'hooks';
import * as actions from 'actions';
import {CAMPAIGN_REQUEST_TYPES, HISTORY_EVENT_TYPES} from 'constants.js';


export const useAnalytics = () => {
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const analyticsData = useSelector(state => state.analytics.analytics, shallowEqual);
  const {chatsChartData, campaignsChartData, operatorData} = useSelector(state => state.analytics, shallowEqual);
  const dispatch = useDispatch();

  const getAnalytics = useCallback((params, analyticsType, tickFormat) => {
    dispatch(actions.fetchAnalytics({...params, widget_id: currentWidgetId}, analyticsType, tickFormat))
  }, [dispatch]);
  return {operatorData, chatsChartData, campaignsChartData, getAnalytics};
};

export const useAnalyticsTypeFilter = () => {
  const selectedAnalyticsType = useSelector(state => state.analytics.selectedAnalyticsType, shallowEqual);
  const dispatch = useDispatch();

  const setSelectedAnalyticsType = useCallback(value => {dispatch(actions.setAnalyticsTypeFilter(value))}, [dispatch]);

  return {selectedAnalyticsType, setSelectedAnalyticsType};
};