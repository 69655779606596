import client from 'api/client';


// live visitors

export const FETCH_LIVE_VISITORS__R = 'FETCH_LIVE_VISITORS__R';
export const fetchLiveVisitorsRq = () => {
  return {
    type: FETCH_LIVE_VISITORS__R
  };
};
export const FETCH_LIVE_VISITORS__S = 'FETCH_LIVE_VISITORS__S';

export function fetchLiveVisitorsSc(liveVisitors) {
  return {
    payload: liveVisitors,
    type: FETCH_LIVE_VISITORS__S
  };
}

export const FETCH_LIVE_VISITORS__F = 'FETCH_LIVE_VISITORS__F';

export function fetchLiveVisitorsFl(error) {
  return {
    payload: error,
    type: FETCH_LIVE_VISITORS__F
  };
}

export const FETCH_LEADS = 'FETCH_LEADS';
export const fetchLiveVisitors = (params) => (dispatch, getState) => {
  dispatch(fetchLiveVisitorsRq());
  return client.get(`/clients`, {
    params: {
      ...params
    }
  })
    .then(data => {
      dispatch(fetchLiveVisitorsSc(data));
    })
    .catch(error => {
      dispatch(fetchLiveVisitorsFl(error))
    });
};



// campaign type filter

export const SET_LIVE_VISITORS_CAMPAIGN_TYPE_FILTER = 'SET_LIVE_VISITORS_CAMPAIGN_TYPE_FILTER';
export function setLiveVisitorsCampaignTypeFilter(value) {
  return {
    payload: value,
    type: SET_LIVE_VISITORS_CAMPAIGN_TYPE_FILTER,
  }
}
