import React, { PureComponent } from 'react';
import {IntlProvider } from 'react-intl';
//import en from 'react-intl/locale-data/en';

import LanguageFactory from 'api/LanguageFactory';
import {main} from 'translate';
//addLocaleData([...en]);

export default class IntlWrapper extends PureComponent {
  static _translateAssign(messages) {
    const translate = {};
    Object.keys(messages[0]).forEach((language) => {
      translate[language] = Object.assign({}, messages[0][language], messages[1][language]);
    });
    return translate;
  };

  render() {
    const locale = LanguageFactory.getLocale();
    const {messages} = this.props;
    const commonMessages = messages.push(main);
    const translate = IntlWrapper._translateAssign(messages);

    return (
        <IntlProvider locale={locale} key={locale} messages={translate[locale]}>
          <React.Fragment>{this.props.children}</React.Fragment>
        </IntlProvider>
      );
    }
  }
