export const CAMPAIGN_REQUEST_TYPES = {
  live_web_chat: 'live_web_chat',
  bot_web_chat: 'bot_web_chat',
  landbot: 'landbot',
  facebook_messenger: 'facebook_messenger'
};

export const SENDER_TYPES = {
  operator: 'operator',
  client: 'client',
  bot: 'bot'
};

export const PAGINATION_OFFSET_TYPE = {
  next: 'next',
  prev: 'prev',
  middle: 'middle',
  reset: 'reset'
};

export const CAMPAIGN_TYPES = {
  web: {label: 'tabs.webChats', value: 'web', includedTypes: ['bot_web_chat', 'live_web_chat']},
  landbot: {label: 'tabs.landbot', value: 'landbot', includedTypes: ['landbot']},
  facebookMessenger: {label: 'tabs.messengerChats', value: 'facebook_messenger', includedTypes: ['facebook_messenger']}
};

export const BOT_CAMPAIGN_TYPES = ['bot_web_chat', 'landbot'];

export const CHANNELS_OPTIONS = {
  all: { value: 'all', label: 'header.channels.all', includedTypes: [] },
  siteWidget: { value: 'site_widget', label: 'header.channels.siteWidget', includedTypes: ['bot_web_chat', 'live_web_chat'] },
  landbots:{ value: 'landbots', label: 'header.channels.landbots', includedTypes: ['landbot'] },
  facebookMessenger:{ value: 'facebook_messenger', label: 'header.channels.messenger', includedTypes: ['facebook_messenger'] }
};

export const ERRORS = {
  invalidEmailOrPassword: 1005,
  emailExist: 1015,
  siteExist: 1010,
};

export const CHAT_MESSAGES_LIMIT = 40;

export const BOT_NODE_TYPES = {
  sendMessage: 'send_message',
  choiceButton: 'choice_buttons',
  choiceCard: 'choice_cards',
  askQuestion: 'ask_question',
  sendEmail: 'send_email',
  goToLiveChat: 'go_to_livechat',
  notifyManager: 'notify_manager',
  closeChat: 'close_chat',
  transferToOperator: 'transfer_to_operator'
};

export const BOT_SECTION_TYPES = {
  text: 'text',
  link: 'link',
  file: 'file'
};

export const HISTORY_EVENT_TYPES = {
  add: 'ADD',
  delete: 'DELETE',
  edit: 'EDIT'
};

export const BACKGROUND_TYPES = {
  color: 'color',
  gradient: 'gradient',
  image: 'image',
  video: 'video'
};

export const WIDGET_CHART_LINES = {
  totalChats: 'chats',
  totalMessages: 'total_messages',
  visitorMessages: 'visitor_messages',
  operatorMessages: 'operator_messages',
  campaignMessages: 'campaign_messages'
};

export const FACEBOOK_CHART_LINES = {
  chats: 'chats',
  leads: 'leads',
  finished: 'finished',
  subscribes: 'subscribes',
  unsubscribes: 'unsubscribes',
  activeSubscribes: 'total_active_subscribes'
};


export const MEETINGS_CHART_LINES = {
  clicks: 'clicks',
  hovers: 'hovers',
  views: 'views',
  meetingsBooked: 'meetings_booked',
  conversionRate: 'conversion_rate',
};

export const ANALYTICS_TYPES = {
  chats: {label: 'analytics.tabs.chats', value: 'chats'},
  operators: {label: 'analytics.tabs.operators', value: 'operators'},
  campaigns: {label: 'analytics.tabs.campaigns', value: 'campaigns'}
};

export const PaymentStatus = {
  Success: 'success',
  NotPaid: 'not_paid',
  Prepared: 'prepared',
  Cancelled: 'canceled',
  Processing: 'processing',
};

export const PayablePaymentStatuses = [
  PaymentStatus.Prepared,
  PaymentStatus.NotPaid,
];

export const TARIFF_PERIODS = {
  month: 'month',
  year: 'year'
};

export const MEETING_SETTING_TABS = {
  reports: 'reports',
  links: 'links',
  settings: 'settings'
};

export const MEETING_EMAIL_TYPE = {
  confirm: 'confirm',
  remindDay: 'remind_day',
  remindHalfHour: 'remind_half_hour'
};

export const TIME_FORMATS = {
  'AM/PM': {value: 'AM/PM', label: 'AM/PM'},
  '24h': {value: '24h', label: '24 h'},
};

export const TIME_PERIODS = {
  'AM': {value: 'AM', label: 'AM'},
  'PM': {value: 'PM', label: 'PM'}
};

