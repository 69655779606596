import React, { Component, useEffect, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Alert from "react-s-alert";
import Header from 'components/Header';
import IntlWrapper from 'components/widgets/IntlWrapper';
import Loader from "components/widgets/Loader";
import ConnectCalendar from './ConnectCalendar';
import MeetingsTable from './MeetingsTable';
import Reports from './MeetingSettings/Reports';
import Links from './MeetingSettings/Links';
import Settings from './MeetingSettings/Settings';
import {MEETING_SETTING_TABS} from 'constants.js';
import {useMeetings, useCurrentUser, useGetCurrentOperator, useUpdateData, useWidgets} from 'hooks';
import {meetings} from 'translate';
import {t} from 'utils';


export default function Meetings() {
  const messages = [meetings];
  const {currentUser} = useCurrentUser();
  const [currentOperator, updateCurrentOperator] = useGetCurrentOperator();

  if (!currentUser || !currentOperator || !currentOperator.id) {
    return <Loader/>;
  }

  return (
    <IntlWrapper messages={messages}>
      <Header title={t('title')}/>
      <div className='page-content normal-content' id="meeting-page">
        <div className='w-form'>
          {(currentUser.google_account && currentUser.google_account.credentials) ? (
            <MeetingsPage currentOperator={currentOperator}/>
          ) : (
            <ConnectCalendar/>
          )}
        </div>
      </div>
    </IntlWrapper>
  );
}


const MeetingsPage = (props) => {
  const {currentOperator} = props;
  const [activeTab, setActiveTab] = useState(MEETING_SETTING_TABS.reports);
  const {currentWidgetId} = useWidgets();
  const {meetingsToday, meetingSettings, getMeetingsToday, getMeetingSettings} = useMeetings();

  useUpdateData(() => {
    if (currentOperator) {
      getMeetingsToday(currentOperator.id);
    }
  });

  useEffect(() => {
    if (currentOperator) {
      getMeetingsToday(currentOperator.id);
    }
    //getMeetingLinks();
    getMeetingSettings();
  }, [currentWidgetId, currentOperator]);

  const _t = (value, values = {}, children = null) => {
    return t(`meetings.${value}`, values, children);
  };

  if (!meetingSettings.default_meeting_link) {
    return <Loader isSmall={false}/>;
  }

  return (
    <>
      {meetingsToday && meetingsToday.length ? (
        <MeetingsTable meetingsToday={meetingsToday}/>
      ) : (
        <NoMeetings meetingSettings={meetingSettings}/>
      )}

      <div className='a-tabs top-indent w-tabs' data-duration-in='300' data-duration-out='100'>
        <div className='a-tabs-menu w-tab-menu' role='tablist'>
          <div className={`a-tab-link w-inline-block w-tab-link ${activeTab === MEETING_SETTING_TABS.reports ? 'w--current' : ''}`}
               onClick={() => setActiveTab(MEETING_SETTING_TABS.reports)}>
            {_t('tab.reports')}
          </div>
          <div className={`a-tab-link w-inline-block w-tab-link ${activeTab === MEETING_SETTING_TABS.links ? 'w--current' : ''}`}
               onClick={() => setActiveTab(MEETING_SETTING_TABS.links)}>
            {_t('tab.links')}
          </div>
          <div className={`a-tab-link w-inline-block w-tab-link ${activeTab === MEETING_SETTING_TABS.settings ? 'w--current' : ''}`}
               onClick={() => setActiveTab(MEETING_SETTING_TABS.settings)}>
            {_t('tab.settings')}
          </div>
        </div>

        <div className='a-tabs-content w-tab-content'>
          <div className={`w-tab-pane ${activeTab === MEETING_SETTING_TABS.reports ? 'w--tab-active' : ''}`}>
            <Reports/>
          </div>

          <div className={`w-tab-pane ${activeTab === MEETING_SETTING_TABS.links ? 'w--tab-active' : ''}`}>
            <Links/>
          </div>

          <div className={`w-tab-pane ${activeTab === MEETING_SETTING_TABS.settings ? 'w--tab-active' : ''}`}>
            <Settings/>
          </div>
        </div>
      </div>
    </>
  );
};

const NoMeetings = (props) => {
  const {meetingSettings} = props;
  const _t = (value, values = {}, children = null) => {
    return t(`meetings.empty.${value}`, values, children);
  };

  const copyLink = (text, result) => {
    if (result) {
      Alert.success('Link has been copied');
    } else {
      Alert.error('An error occurred while copying');
    }
  };

  return (
    <div className='content-white-block grow-w'>
      <div>
        <div className='group-title'>{_t('title')}</div>
      </div>
      <div className='group-content-w add-padding'>
        <div className='group-row horizontal-row'>
          <div className='col-6 full-w'>
            <div className='page-subheader add-margin-2'>{_t('subtitle')}</div>
          </div>
          <div className='col-6 full-w'>
            <div className="settings-item-label add-margin">{_t('link.label')}:</div>
            <input type="text" value={meetingSettings.default_meeting_link.meeting_url} readOnly={true}
                   className="node-settings-input w-input cursor-text" maxLength="256"/>
            <div className='btns-block'>
              <CopyToClipboard text={meetingSettings.default_meeting_link.meeting_url}
                               onCopy={copyLink}>
                <div className='step-btn solid-bg w-inline-block'><div>{_t('copy.button')}</div></div>
              </CopyToClipboard>
              <a target="_blank" href={meetingSettings.default_meeting_link.meeting_url}
                 className='step-btn add-indent w-inline-block'><div>{_t('check.button')}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};