import React, {useEffect, useState} from 'react';
import { CSSTransition } from 'react-transition-group';


export default function AuthPageContainer({ children }) {
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(true);
  }, []);

  return (
    <div className="main-sign-wrapper register-page" >
      { children }
      <div className="shapes-w">
        <CSSTransition
          in={pageLoading}
          timeout={300}
          classNames="shape-1-animation"
          unmountOnExit
        >
          <img src={require('styles/images/Asset-2.svg')}
               alt="" className="shape-1 transform-style-end"/>
        </CSSTransition>
        <CSSTransition
          in={pageLoading}
          timeout={300}
          classNames="shape-2-animation"
          unmountOnExit
        >
          <img src={require('styles/images/Asset-4.svg')}
               alt="" className="shape-2 transform-style-end"/>
        </CSSTransition>
        <CSSTransition
          in={pageLoading}
          timeout={300}
          classNames="shape-3-animation"
          unmountOnExit
        >
          <img src={require('styles/images/Asset-5.svg')}
               alt="" className="shape-3 transform-style-end" />
        </CSSTransition>
        <CSSTransition
          in={pageLoading}
          timeout={300}
          classNames="shape-4-animation"
          unmountOnExit
        >
          <img src={require('styles/images/Asset-3.svg')}
               alt="" className="shape-4 transform-style-end"/>
        </CSSTransition>
      </div>
    </div>
  );
}