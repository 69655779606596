import React, {Fragment, useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import update from 'immutability-helper';

import {useSendFiles} from 'hooks';
import {t} from 'utils';

export default function ImageLoader(props) {
  const {value, defaultImgUrl, imgClasses, classNames = 'dn-media-w img-on-bg',  onChange} = props;
  const defaultImg = defaultImgUrl;
  //const [files, setFiles] = useState([]);

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      onChange({file: acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))[0]});
    }
  });

  return (
    (value && (value.url || value.preview)) ? (
      <div style={{backgroundImage: `url('${value.url || value.preview}')`, width: '100%'}}
           className={classNames} {...getRootProps()}><input {...getInputProps()} /></div>
    ) : (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <img src={defaultImg} alt="" className={imgClasses} />
        <div className="display-none">{t('media.image.title')}</div>
      </div>
    )
  )
};