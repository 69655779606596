import React, {Fragment, useState} from 'react';
import update from 'immutability-helper';
import {isInt} from 'validator';

import Switch from 'components/widgets/Switch';
import CustomSelect from 'components/widgets/CustomSelect';
import CampaignWarningBlock from 'components/widgets/CampaignWarningBlock';
import {t} from 'utils';
import {CAMPAIGN_REQUEST_TYPES} from 'constants.js';


export default function TriggerSettings(props) {
  const {webChatType, errors, campaign, setErrors, setCampaign} = props;

  const messageConstant = 'web.create.displaySettings.triggers';
  return (
    <div className="w-tab-pane w--tab-active">
      <div className="pane-content-w">
        <ByClicking messageConstant={messageConstant} webChatType={webChatType} campaign={campaign} setCampaign={setCampaign}/>
        <ActionVisitor
          messageConstant={messageConstant}
          webChatType={webChatType}
          campaign={campaign}
          setCampaign={setCampaign}
          errors={errors}
          setErrors={setErrors}
        />
        <TriggerActivates
          messageConstant={messageConstant}
          webChatType={webChatType}
          campaign={campaign}
          setCampaign={setCampaign}
          errors={errors}
          setErrors={setErrors}
        />
        {webChatType === CAMPAIGN_REQUEST_TYPES.bot_web_chat && (
          <ManagerStartBot
            messageConstant={messageConstant}
            campaign={campaign}
            setCampaign={setCampaign}
            errors={errors}
            setErrors={setErrors}
          />
        )}
      </div>
    </div>
  );
}

const ByClicking = (props) => {
  const {messageConstant, webChatType, campaign, setCampaign} = props;

  const triggersSettings = campaign.display_settings.triggers;
  return (
    <div className="content-white-block">
      <div>
        <div className="group-title">{t(`${messageConstant}.clicking.${webChatType}.title`)}</div>
      </div>
      <div className="group-content-w">
        <div className="group-w">
          <div className="group-w-header">
            <div className="group-title">{t(`${messageConstant}.clicking.${webChatType}.label`)}</div>
            <Switch isOn={triggersSettings.is_trigger_by_click_active}
                    onClick={() => {
                      setCampaign(update(campaign, {
                        display_settings: {
                          triggers: {
                            $toggle: ['is_trigger_by_click_active']
                          }
                        }
                      }));
                    }}/>
          </div>
        </div>
      </div>
    </div>
  );
};

const ActionVisitor = (props) => {
  const {messageConstant, webChatType, errors, campaign, setErrors, setCampaign} = props;

  const triggersSettings = campaign.display_settings.triggers;
  return (
    <div className="content-white-block">
      <div>
        <div className="group-title">{t(`${messageConstant}.action.title`)}</div>
        <div className="page-subheader">{t(`${messageConstant}.action.${webChatType}.subtitle`)}</div>
      </div>
      <div className="group-content-w">
        <div className="group-w">
          <div className="group-w-header">
            <div className="group-title">
              {t(`${messageConstant}.action.exitIntent.label`)} <span className="group-title-span">
              {t(`${messageConstant}.action.exitIntent.desktop`)}</span>
            </div>
            <Switch isOn={triggersSettings.is_exit_intent_active}
                    onClick={() => {
                      setCampaign(update(campaign, {
                        display_settings: {
                          triggers: {
                            $toggle: ['is_exit_intent_active']
                          }
                        }
                      }));
                    }}/>
          </div>
        </div>
        <div className="group-w" id="timedTriggerValue">
          <div className="group-w-header">
            <div className="group-title">{t(`${messageConstant}.action.timed.label`)}</div>
            <Switch isOn={triggersSettings.is_timed_trigger_active}
                    onClick={() => {
                      if (triggersSettings.is_timed_trigger_active && errors.timedTriggerValue) {
                        setErrors({...errors, timedTriggerValue: null});
                      }
                      setCampaign(update(campaign, {
                        display_settings: {
                          triggers: {
                            $toggle: ['is_timed_trigger_active']
                          }
                        }
                      }));
                    }}/>
          </div>
          {triggersSettings.is_timed_trigger_active && (
            <div className="show-group-content">
              <div className="show-group-div">
                <div className="group-row flex-column">
                  <CampaignWarningBlock error={errors.timedTriggerValue}/>
                  <div className="col-12">
                    <div className="settings-item-label add-margin">
                      {t(`${messageConstant}.action.timed.formShow`)}
                    </div>
                    <input type="number" maxLength="256" placeholder="1" min={0}
                           className="node-settings-input number-input w-input"
                           value={triggersSettings.timed_trigger_seconds_value}
                           onChange={(e) => {
                             if (!isInt('' + e.target.value, {min: 0})) {
                               setErrors({...errors, timedTriggerValue: t('error.number.intLessZero')});
                             } else {
                               if (errors.timedTriggerValue) {
                                 setErrors({...errors, timedTriggerValue: null});
                               }
                             }
                             setCampaign(update(campaign, {
                               display_settings: {
                                 triggers: {
                                   timed_trigger_seconds_value: {$set: e.target.value}
                                 }
                               }
                             }));
                           }
                           }/>
                    <div className="settings-item-label add-margin">
                      {t(`${messageConstant}.action.timed.seconds`)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="group-w" id="scrollTriggerValue">
          <div className="group-w-header">
            <div className="group-title">{t(`${messageConstant}.action.scroll.label`)}</div>
            <Switch isOn={triggersSettings.is_after_scrolling_active}
                    onClick={() => {
                      if (triggersSettings.is_after_scrolling_active && errors.scrollTriggerValue) {
                        setErrors({...errors, scrollTriggerValue: null});
                      }
                      setCampaign(update(campaign, {
                        display_settings: {
                          triggers: {
                            $toggle: ['is_after_scrolling_active']
                          }
                        }
                      }));
                    }}/>
          </div>
          {triggersSettings.is_after_scrolling_active && (
            <div className="show-group-content">
              <div className="show-group-div">
                <div className="group-row flex-column">
                  <CampaignWarningBlock error={errors.scrollTriggerValue}/>
                  <div className="col-12">
                    <div className="settings-item-label add-margin">
                      {t(`${messageConstant}.action.scroll.formShow`)}
                    </div>
                    <input type="number" maxLength="256" placeholder="1" min={1} max={100}
                           className="node-settings-input number-input w-input"
                           value={triggersSettings.after_scrolling_percents_value}
                           onChange={(e) => {
                             if (!isInt('' + e.target.value, {min: 1, max: 100})) {
                               setErrors({...errors, scrollTriggerValue: t('error.number.intFromRange')});
                             } else {
                               if (errors.scrollTriggerValue) {
                                 setErrors({...errors, scrollTriggerValue: null});
                               }
                             }
                             setCampaign(update(campaign, {
                               display_settings: {
                                 triggers: {
                                   after_scrolling_percents_value: {$set: e.target.value}
                                 }
                               }
                             }));
                           }
                           }/>
                    <div className="settings-item-label add-margin">
                      {t(`${messageConstant}.action.scroll.percent`)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="group-w" id="inactivityTimedTriggerValue">
          <div className="group-w-header">
            <div className="group-title">
              {t(`${messageConstant}.action.inactivity.label`)}
            </div>
            <Switch isOn={triggersSettings.is_time_of_inactivity_active}
                    onClick={() => {
                      if (triggersSettings.is_time_of_inactivity_active && errors.inactivityTimedTriggerValue) {
                        setErrors({...errors, inactivityTimedTriggerValue: null});
                      }
                      setCampaign(update(campaign, {
                        display_settings: {
                          triggers: {
                            $toggle: ['is_time_of_inactivity_active']
                          }
                        }
                      }));
                    }}/>
          </div>
          {triggersSettings.is_time_of_inactivity_active && (
            <div className="show-group-content">
              <div className="show-group-div">
                <div className="group-row flex-column">
                  <CampaignWarningBlock error={errors.inactivityTimedTriggerValue}/>
                  <div className="col-12">
                    <div className="settings-item-label add-margin">
                      {t(`${messageConstant}.action.inactivity.formShow`)}
                    </div>
                    <input type="number" maxLength="256" placeholder="1" min={0}
                           className="node-settings-input number-input w-input"
                           value={triggersSettings.time_of_inactivity_seconds_value}
                           onChange={(e) => {
                             if (!isInt('' + e.target.value, {min: 0})) {
                               setErrors({...errors, inactivityTimedTriggerValue: t('error.number.intLessZero')});
                             } else {
                               if (errors.inactivityTimedTriggerValue) {
                                 setErrors({...errors, inactivityTimedTriggerValue: null});
                               }
                             }
                             setCampaign(update(campaign, {
                               display_settings: {
                                 triggers: {
                                   time_of_inactivity_seconds_value: {$set: e.target.value}
                                 }
                               }
                             }));
                           }
                           }/>
                    <div className="settings-item-label add-margin">
                      {t(`${messageConstant}.action.inactivity.seconds`)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const TriggerActivates = (props) => {
  const {messageConstant, errors, webChatType, campaign, setCampaign, setErrors} = props;

  const triggersSettings = campaign.display_settings.triggers;
  const timeUnits = {
    second: {value: 'second', label: 'options.second'},
    minute: {value: 'minute', label: 'options.minute'},
    hour: {value: 'hour', label: 'options.hour'},
    day: {value: 'day', label: 'options.day'},
  };
  return (
    <div className="content-white-block">
      <div>
        <div className="group-title">{t(`${messageConstant}.activate.${webChatType}.title`)}</div>
      </div>
      <div className="group-content-w" id="periodicity">
        <div className="group-w">
          <div className="group-w-header">
            <div className="group-title">{t(`${messageConstant}.activate.freq.label`)}</div>
            <Switch isOn={triggersSettings.is_periodicity_active}
                    onClick={() => {
                      if (triggersSettings.is_periodicity_active && errors.periodicity) {
                        setErrors({...errors, periodicity: null});
                      }
                      setCampaign(update(campaign, {
                        display_settings: {
                          triggers: {
                            $toggle: ['is_periodicity_active']
                          }
                        }
                      }));
                    }}/>
          </div>
          {triggersSettings.is_periodicity_active && (
            <div className="show-group-content">
              <div className="show-group-div">
                <div className="group-row flex-column">
                  <CampaignWarningBlock error={errors.periodicity}/>
                  <div className="col-12">
                    <div className="settings-item-label add-margin">
                      {t(`${messageConstant}.activate.freq.maximum`)}
                    </div>
                    <input type="number" maxLength="256" placeholder="2" min={0}
                           className="node-settings-input number-input w-input"
                           value={triggersSettings.popup_will_appear_maximum_number_value}
                           onChange={(e) => {
                             if (!isInt('' + e.target.value, {min: 0}) ||
                               !isInt('' + triggersSettings.popup_time_between_appearances_value, {min: 0})) {
                               setErrors({...errors, periodicity: t('error.number.intLessZero')});
                             } else {
                               if (errors.periodicity) {
                                 setErrors({...errors, periodicity: null});
                               }
                             }
                             setCampaign(update(campaign, {
                               display_settings: {
                                 triggers: {
                                   popup_will_appear_maximum_number_value: {$set: e.target.value}
                                 }
                               }
                             }));
                           }
                           }/>
                    <div className="settings-item-label add-margin">
                      {t(`${messageConstant}.activate.freq.times`)}
                    </div>
                    <input type="number" maxLength="256" placeholder="1" min={0}
                           className="node-settings-input number-input w-input"
                           value={triggersSettings.popup_time_between_appearances_value}
                           onChange={(e) => {
                             if (!isInt('' + e.target.value, {min: 0})  ||
                               !isInt(triggersSettings.popup_will_appear_maximum_number_value, {min: 0})) {
                               setErrors({...errors, periodicity: t('error.number.intLessZero')});
                             } else {
                               if (errors.periodicity) {
                                 setErrors({...errors, periodicity: null});
                               }
                             }
                             setCampaign(update(campaign, {
                               display_settings: {
                                 triggers: {
                                   popup_time_between_appearances_value: {$set: e.target.value}
                                 }
                               }
                             }));
                           }
                           }/>
                    <CustomSelect options={Object.values(timeUnits)}
                                  noControlPadding={true}
                                  defaultValue={triggersSettings.popup_time_between_appearances_unit ?
                                    timeUnits[triggersSettings.popup_time_between_appearances_unit] :
                                      timeUnits.second}
                                  selectClassName={`settings-select narrow-select w-select`}
                                  getOptionLabel={option => t(option.label)}
                                  onChange={unit => {
                                    setCampaign(update(campaign, {
                                      display_settings: {
                                        triggers: {
                                          popup_time_between_appearances_unit: {$set: unit.value}
                                        }
                                      }
                                    }));
                                  }}
                                  value={timeUnits[triggersSettings.popup_time_between_appearances_unit]}/>
                    <div className="settings-item-label add-margin">
                      {t(`${messageConstant}.activate.freq.appearances`)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ManagerStartBot = (props) => {
  const {messageConstant, errors, campaign, setCampaign, setErrors} = props;

  const triggersSettings = campaign.display_settings.triggers;
  const timeUnits = {
    second: {value: 'second', label: 'options.second'},
    minute: {value: 'minute', label: 'options.minute'},
  };
  return (
    <div className="content-white-block">
      <div>
        <div className="group-title">{t(`${messageConstant}.manager.title`)}</div>
      </div>
      <div className="group-content-w">
        <div className="group-w" id="withoutRespondTriggerValue">
          <div className="group-w-header">
            <div className="group-title">{t(`${messageConstant}.manager.respond.label`)}</div>
            <Switch isOn={triggersSettings.is_not_respond_conversation_active}
                    onClick={() => {
                      if (triggersSettings.is_not_respond_conversation_active && errors.withoutRespondTriggerValue) {
                        setErrors({...errors, withoutRespondTriggerValue: null});
                      }
                      setCampaign(update(campaign, {
                        display_settings: {
                          triggers: {
                            $toggle: ['is_not_respond_conversation_active']
                          }
                        }
                      }));
                    }}/>
          </div>
          {triggersSettings.is_not_respond_conversation_active && (
            <div className="show-group-content">
              <div className="show-group-div">
                <div className="group-row">
                  <div className="col-6">
                    <CampaignWarningBlock error={errors.withoutRespondTriggerValue}/>
                    {/*<div className="alert-w warning">*/}
                    {/*  <div className="alert-div">*/}
                    {/*    <div className="awes-icon"></div>*/}
                    {/*    <div>{t(`${messageConstant}.manager.respond.alert`)}</div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="settings-item-label add-margin">
                      {t(`${messageConstant}.manager.respond.time`)}
                    </div>
                    <div className="inputs-row">
                      <input type="number" maxLength="256" placeholder="5" min={0}
                             className="node-settings-input number-input w-input"
                             value={triggersSettings.without_responding_time_value}
                             onChange={(e) => {
                               if (!isInt('' + e.target.value, {min: 0})) {
                                 setErrors({...errors, withoutRespondTriggerValue: t('error.number.intLessZero')});
                               } else {
                                 if (errors.withoutRespondTriggerValue) {
                                   setErrors({...errors, withoutRespondTriggerValue: null});
                                 }
                               }
                               setCampaign(update(campaign, {
                                 display_settings: {
                                   triggers: {
                                     without_responding_time_value: {$set: e.target.value}
                                   }
                                 }
                               }));
                             }
                             }/>
                      <CustomSelect options={Object.values(timeUnits)}
                                    noControlPadding={true}
                                    defaultValue={triggersSettings.without_responding_time_unit ?
                                      timeUnits[triggersSettings.without_responding_time_unit] :
                                      timeUnits.second}
                                    selectClassName={`settings-select narrow-select w-select`}
                                    getOptionLabel={option => t(option.label)}
                                    onChange={unit => {
                                      setCampaign(update(campaign, {
                                        display_settings: {
                                          triggers: {
                                            without_responding_time_unit: {$set: unit.value}
                                          }
                                        }
                                      }));
                                    }}
                                    value={timeUnits[triggersSettings.without_responding_time_unit]}/>
                    </div>
                  </div>
                  <div className="col-6 right-padding"></div>
                </div>
              </div>
            </div>
          )}
        </div >
        <div className="group-w">
          <div className="group-w-header">
            <div className="group-title">{t(`${messageConstant}.manager.starts.label`)}</div>
            <Switch isOn={triggersSettings.is_start_chatbot_active}
                    onClick={() => {
                      setCampaign(update(campaign, {
                        display_settings: {
                          triggers: {
                            $toggle: ['is_start_chatbot_active']
                          }
                        }
                      }));
                    }}/>
          </div>
          {triggersSettings.is_start_chatbot_active && (
            <div className="show-group-content">
              <div className="show-group-div">
                <div className="group-row">
                  <div className="col-6">
                    <div className="settings-item-label add-margin">
                      {t(`${messageConstant}.manager.starts.notice`)}</div>
                  </div>
                  <div className="col-6 right-padding"></div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="group-w" id="notTakeTriggerValue">
          <div className="group-w-header">
            <div className="group-title">{t(`${messageConstant}.manager.take.label`)}</div>
            <Switch isOn={triggersSettings.is_not_take_conversation_active}
                    onClick={() => {
                      if (triggersSettings.is_not_take_conversation_active && errors.notTakeTriggerValue) {
                        setErrors({...errors, notTakeTriggerValue: null});
                      }
                      setCampaign(update(campaign, {
                        display_settings: {
                          triggers: {
                            $toggle: ['is_not_take_conversation_active']
                          }
                        }
                      }));
                    }}/>
          </div>
          {triggersSettings.is_not_take_conversation_active && (
            <div className="show-group-content">
              <div className="show-group-div">
                <div className="group-row">
                  <div className="col-6">
                    <CampaignWarningBlock error={errors.notTakeTriggerValue}/>
                    <div className="settings-item-label add-margin">{t(`${messageConstant}.manager.take.time`)}</div>
                    <div className="inputs-row">
                      <input type="number" maxLength="256" placeholder="5" min={0}
                             className="node-settings-input number-input w-input"
                             value={triggersSettings.without_taking_time_value}
                             onChange={(e) => {
                               if (!isInt('' + e.target.value, {min: 0})) {
                                 setErrors({...errors, notTakeTriggerValue: t('error.number.intLessZero')});
                               } else {
                                 if (errors.notTakeTriggerValue) {
                                   setErrors({...errors, notTakeTriggerValue: null});
                                 }
                               }
                               setCampaign(update(campaign, {
                                 display_settings: {
                                   triggers: {
                                     without_taking_time_value: {$set: e.target.value}
                                   }
                                 }
                               }));
                             }
                             }/>
                      <CustomSelect options={Object.values(timeUnits)}
                                    noControlPadding={true}
                                    defaultValue={triggersSettings.without_taking_time_unit ?
                                      timeUnits[triggersSettings.without_taking_time_unit] :
                                      timeUnits.second}
                                    selectClassName={`settings-select narrow-select w-select`}
                                    getOptionLabel={option => t(option.label)}
                                    onChange={unit => {
                                      setCampaign(update(campaign, {
                                        display_settings: {
                                          triggers: {
                                            without_taking_time_unit: {$set: unit.value}
                                          }
                                        }
                                      }));
                                    }}
                                    value={timeUnits[triggersSettings.without_taking_time_unit]}/>
                    </div>
                  </div>
                  <div className="col-6 right-padding"></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};