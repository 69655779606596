import React, { useState } from 'react';

import IntlWrapper from 'components/widgets/IntlWrapper';
import {main} from 'translate';


export default function NotFound(props) {

  return (
    <IntlWrapper messages={[main]}>
      <div className="page-content normal-content">
        <div className="title-404">404</div>
        <div className="subtitle-404">Not Found</div>
      </div>
    </IntlWrapper>
  );
}