import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import TariffPlans from './TariffPlans';
import {main, billing} from 'translate';

export default compose(
  connect((state, ownProps) => {
      return {
        messages: [main, billing]
      };
    }
  ),

  lifecycle({
    componentDidMount() {
      document.title = 'Pricing | HeyCom'
      
      console.log('TariffPlans componentDidMount')
    },
  }),

  withHandlers()
) (
  TariffPlans
);