import React, {useEffect} from 'react';
import IntlWrapper from 'components/widgets/IntlWrapper';

import {assistants} from 'translate';
import Header from "../../Header";
import {t} from "../../../utils";
import client from 'api/client'
import {useStateWidgets} from "../../../hooks";
import {useState} from "react";
import {Link} from "react-router-dom";
import Switch from "../../widgets/Switch";
import moment from "moment";
import update from "immutability-helper";


export default function Assistants(props) {
  const messages = [assistants];
  const {currentWidgetId} = useStateWidgets();
  console.log('CLIENT: ', currentWidgetId)

  const [assistantsItems, setAssistantsItems] = useState(null);
  const [activeAssistant, setActiveAssistant] = useState(null)

  const getAllAssistants = () => {
    client.get(`/widgets/${currentWidgetId}/assistants`, {}).then(data => {
      setAssistantsItems(data)
    })
  }

  useEffect(() => {
    // console.log('Use effect')
    getAllAssistants()
  }, [])


  const removeAll = () => {
    for (let assistant of assistantsItems) {
      if (assistant.name === "New assistant") {
        client.delete(`/widgets/${currentWidgetId}/assistants/${assistant.id}`).then(data => {
          client.get(`/widgets/${currentWidgetId}/assistants`, {}).then(data => {
            setAssistantsItems(data)
          })
        })
      }

    }

  }

  const createAssistant = () => {
    client.post(`/widgets/${currentWidgetId}/assistants`, {
      campaign_ids: [],
      name: "New assistant"
    }).then(({id}) => {
      location.href = `assistants/${id}`
    })
  }

  const handleCloneCampaignClick = () => {

  }


  const handleDeleteCampaignClick = (id) => {
    setActiveAssistant(null)
    client.delete(`/widgets/${currentWidgetId}/assistants/${id}`, {
      campaign_ids: [],
      name: "New assistant"
    }).finally(() => {
      getAllAssistants()
    })


  }

  const assistantsList = () => {
    return (
      <div>
        {/*<button onClick={removeAll}>Remove all</button>*/}

        <div>{assistantsItems.map((assistant, index) => {
          return (
            <div key={assistant.id} className="campaign-preview-item">
              <div style={{display: 'flex', justifyContent: "flex-start"}}>
                <div className="cp-avatar-div">
                  <img src={require(`styles/images/bot-img.png`)} alt="" className="cp-avatar"/>
                </div>
                <div className="cp-name-div" title={assistant.name} >
                  <Link to={`assistants/${assistant.id}`} className="cp-name-link" style={{marginBottom: '0'}}>{assistant.name}</Link>
                </div>

                <div className="action-btn edit-btn w-inline-block" onClick={() => {
                  location.href = `assistants/${assistant.id}`
                }}>
                  <div><span className="awesome-icon"></span> {t('assistants.action.edit')}</div>
                </div>

                <div className="action-btn edit-btn w-inline-block" onClick={() => {
                  handleCloneCampaignClick(assistant)
                }}>
                  <div>{t('assistants.action.clone')}</div>
                </div>
                <div className="action-btn delete-btn w-inline-block" onClick={() => handleDeleteCampaignClick(assistant.id)}>
                  <div><span className="awesome-icon">&nbsp;</span>
                    {t('assistants.action.delete')}
                  </div>
                </div>




              </div>



              <div style={{display: 'flex', gap: '50px'}}>
                <div className="cp-info-item">
                  <div className="cp-info-header" style={{whiteSpace: 'nowrap'}}> Connected campaigns</div>
                  <div className="cp-info-date">{assistant.campaign_ids.length}</div>
                </div>
                <div className="cp-info-item">
                  <div className="cp-info-header"><span className="cp-info-header-icon"></span> Last Updated</div>
                  <div className="cp-info-date">{moment(assistant.updated_at).format('MMM D, YYYY')}</div>
                </div>
              </div>
            </div>
          )
        })}
        </div>
        <button className="button blue-button" onClick={() => createAssistant()}>New AI assistant</button>
      </div>
    )
  }

  return (
    <IntlWrapper messages={messages}>
      <Header title={t('title')}/>
      <div className="page-content normal-content">
        {assistantsItems ? assistantsList() : 'загрузка'}

      </div>
    </IntlWrapper>
  );
}
