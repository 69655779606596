import React, { PureComponent } from 'react';
import moment from 'moment';
import Spinner from 'react-spinkit';


export default class SubmitButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };

    this.timestampOld = null;
    this.defaultDuration = 1;

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {isLoading} = this.state;

    if (this.props.isSending && !prevProps.isSending) {
      this.timestampOld = moment().format('x');
      this.setState({
        isLoading: true
      });

      if (!isLoading) {
        this.triggerLoadStart();
      }
    }

    if (!this.props.isSending && prevProps.isSending) {
      const timestampNew = moment().format('x');
      const duration = (this.props.duration !== null && this.props.duration !== undefined) ?
        this.props.duration * 1000 : this.defaultDuration * 1000;
      if ((timestampNew - this.timestampOld) > duration) {
        this.setState({
          isLoading: false
        });
        if (isLoading) {
          this.triggerLoadEnd();
        }
      } else {
        setTimeout(() => {
          const {isLoading} = this.state;
          this.setState({
            isLoading: false
          });
          if (isLoading) {
            this.triggerLoadEnd();
          }
        }, (duration - (timestampNew - this.timestampOld)));
      }
    }
  }

  handleClick(e) {
    if (this.props.onClick) {
      this.props.onClick(e)
    }
  }

  triggerLoadStart() {
    if (this.props.onLoadStart) {
      this.props.onLoadStart();
    }
  }

  triggerLoadEnd() {
    if (this.props.onLoadEnd) {
      this.props.onLoadEnd();
    }
  }

  render() {
    const {isLoading} = this.state;
    const {additionalClass, text, simpleButton, spinnerClassName, spinnerClass,
      disabled, type = 'submit', fullSimpleClass = 'button'} = this.props;
    return (
      <>
        {isLoading ? (
          <button disabled={true} type={type}
                  className={`${simpleButton ? fullSimpleClass : 'submit-button'}  btn-send-loader ${additionalClass ? additionalClass : ''} w-button`}>
            {text}
            <div className={`button-loader-wrap ${spinnerClass ? spinnerClass : ''}`}>
              <div className={"spinner-loader-wrap"}>
                <Spinner name="ball-clip-rotate" className={spinnerClassName || 'default-spinner-color'} />
              </div>
            </div>
          </button>
        ) : (
          <button disabled={disabled} type={type} onClick={e => this.handleClick(e)}
                  className={`${simpleButton ? fullSimpleClass : 'submit-button'} ${additionalClass ? additionalClass : ''}
                  ${disabled ? 'disable-button' : ''} w-button`}>
            {text}
          </button>
        )}
      </>
    );
  }
}
