import React, {useCallback, useEffect, useRef} from 'react';
import {useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  fetchLeads,
  fetchSegments,
  createSegment,
  updateSegment,
  setLeadsCampaignTypeFilter,
  setLeadsFilters,
  deleteLeads as actionDeleteLeads,
  exportLeads as actionExportLeads
} from 'actions';


export const useLeads = () => {
  const leadsById = useSelector(state => state.leads.leadsById, shallowEqual);
  const leadsCount = useSelector(state => state.leads.leadsCount, shallowEqual);
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const selectedCampaignType = useSelector(state => state.leads.selectedCampaignType, shallowEqual);
  const selectedFilters = useSelector(state => state.leads.selectedFilters, shallowEqual);
  const isFetching = useSelector(state => state.leads._isLeadsFetching, shallowEqual);

  const dispatch = useDispatch();
  const getLeads = useCallback((params) => {
    dispatch(fetchLeads({
      is_lead: true,
      send_count: true,
      widget_id: currentWidgetId,
      campaign_type: selectedCampaignType.includedTypes.join(','),
      sort: '-updated_by_operator_at',
      ...selectedFilters,
      ...params
    }))
  }, [dispatch, currentWidgetId, selectedCampaignType, selectedFilters]);



  const exportLeads = useCallback(() => dispatch(actionExportLeads({
    is_lead: true,
    widget_id: currentWidgetId,
    campaign_type: selectedCampaignType.includedTypes.join(','),
    sort: '-created_at',
    export: 'csv',
    ...selectedFilters,
  })), [dispatch, currentWidgetId, selectedCampaignType, selectedFilters]);

  return {leadsById, leadsCount, getLeads, exportLeads, isFetching};
};


export const useSegments = () => {
  const segmentsById = useSelector(state => state.leads.segmentsById, shallowEqual);
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const selectedCampaignType = useSelector(state => state.leads.selectedCampaignType, shallowEqual);
  const isCreating = useSelector(state => state.widgets._isCreating, shallowEqual);
  const isUpdating = useSelector(state => state.widgets._isUpdating, shallowEqual);

  const dispatch = useDispatch();
  const getSegments = useCallback((params) => {
    dispatch(fetchSegments({
      ...params,
      widget_id: currentWidgetId,
      sort: 'created_at',
      campaign_type: selectedCampaignType.includedTypes.join(',')
    }))
  }, [dispatch, currentWidgetId, selectedCampaignType]);
  const addSegment = useCallback((params) => dispatch(createSegment({
    ...params,
    widget_id: currentWidgetId,
    campaign_type: selectedCampaignType.includedTypes[0]
  })), [dispatch, currentWidgetId, selectedCampaignType]);
  const changeSegment = useCallback((id, params) => dispatch(updateSegment(id, params)), [dispatch]);

  useEffect(() => {
    getSegments();
  }, [selectedCampaignType, currentWidgetId]);

  return {segmentsById, getSegments, isCreating, addSegment, isUpdating, changeSegment};
};


export const useLeadsCampaignTypeFilter = () => {
  const selectedCampaignType = useSelector(state => state.leads.selectedCampaignType, shallowEqual);

  const dispatch = useDispatch();
  const setSelectedCampaignType = useCallback(value => {dispatch(setLeadsCampaignTypeFilter(value))}, [dispatch]);

  return {selectedCampaignType, setSelectedCampaignType}
};


export const useLeadsFilters = () => {
  const selectedCampaignType = useSelector(state => state.leads.selectedCampaignType, shallowEqual);
  const selectedFilters = useSelector(state => state.leads.selectedFilters, shallowEqual);

  const dispatch = useDispatch();
  const setSelectedFilters = useCallback(value => {dispatch(setLeadsFilters(value))}, [dispatch]);

  return {selectedFilters, setSelectedFilters}
};

export const useDeleteLeads = () => {
  const currentWidgetId = useSelector(state => state.widgets.currentWidgetId, shallowEqual);
  const dispatch = useDispatch();
  return useCallback(leadIds => dispatch(actionDeleteLeads(currentWidgetId, leadIds)), [dispatch]);
};