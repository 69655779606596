import React, { Fragment, useState, useEffect, useRef } from 'react';

import CustomSelect from 'components/widgets/CustomSelect';
import {t} from 'utils';


export default function Workspace(props) {
  const {widgetSettings, modes, url, widgetType, setWidget, onSave} = props;
  const [editorMode, setEditorMode] = useState(modes[0]);

  const iframeRef = useRef();
  useEffect(() => {
    _sendDataToIframe();
  }, [widgetSettings]);
  const _sendDataToIframe = () => {
    iframeRef.current.contentWindow.postMessage(JSON.stringify(widgetSettings), '*');
  };
  const _getUrl = (url) => {
    return window.location.hostname.includes('localhost') ? `http://localhost:5000${url}` : url;
  };
  const handleIframeLoad = () => {
    _sendDataToIframe();
  };

  return (
    <>
      {widgetType === 'widget' ? (
        <div className="content-white-block new-col-4">
          {modes.length > 1 && (
            <div className="div-block-6">
              <div className="settings-item-label add-margin-2">Preview mode:</div>
              <CustomSelect options={modes}
                            defaultValue={editorMode}
                            selectClassName={`settings-select no-indent w-select`}
                            getOptionLabel={option => option.label}
                            onChange={mode => setEditorMode(mode)}/>
            </div>
          )}
          <div className="chat-preview-w web-chat big-width">
            <iframe ref={iframeRef} frameBorder="0"
                    src={`${_getUrl(url)}${editorMode.value}`}
                    onLoad={() => handleIframeLoad()}>
            </iframe>
          </div>
        </div>
      ) : (
        <div className="chat-preview-w landbot-preview">
          <iframe ref={iframeRef} frameBorder="0" height='100%'
                  src={`${_getUrl(url)}${editorMode.value}`}
                  onLoad={() => handleIframeLoad()}>
          </iframe>
        </div>
      )}
    </>
  );
}