import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import Assistants from './Assistants';

export default compose(
  connect((state, ownProps) => {
      return {
      };
    }
  ),

  lifecycle({
    componentDidMount() {
      document.title = 'AI assistants | HeyCom'
    },
  }),

  withHandlers()
) (
  Assistants
);