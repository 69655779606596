import axios from 'axios';
import LanguageFactory from 'api/LanguageFactory';
import {getAccessToken, removeAccessToken, getTranslateErrorMessage} from 'utils';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${getAccessToken()}`,
    'Accept-Language': LanguageFactory.getLocale(),
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

client.interceptors.response.use(
  resp => {
    if (resp.data && resp.data.status === 'error') {
      return Promise.reject(resp.data);
    } else {
      if (resp.headers['content-disposition']) {
        let [, fileName] = resp.headers['content-disposition'].split('filename=');
        fileName = fileName.substring(1, fileName.length - 1);

        return {
          fileName: fileName,
          mimeType: resp.headers['content-type'].split(';')[0],
          data: resp.data
        };

      } else {
        return resp.data;
      }
    }
  },
  error => {
    if (error.response && error.response.status === 401) {
      removeAccessToken();
      window.redirectToSignin(true);
    }
    if (error.response && (500 <= error.response.status && error.response.status < 600)) {
      //Alert.error('Oops.. Some problem with the server');
    }
    return Promise.reject(getTranslateErrorMessage(error.response.data, LanguageFactory.getLocale()));
  });

export default client;