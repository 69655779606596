// @flow weak

import { combineReducers }  from 'redux';
import analytics from './analytics';
import meetings from './meetings';
import billing from './billing';
import chats from './chats';
import campaigns from './campaigns';
import widgets from './widgets';
import operators from './operators';
import leads from './leads';
import liveVisitors from './liveVisitors';
import users from './users';

export default combineReducers({
  analytics,
  billing,
  chats,
  campaigns,
  operators,
  leads,
  liveVisitors,
  users,
  widgets,
  meetings
});
