import React, {Fragment, useState} from 'react';


import BlockBuilderSections from './BlockBuilderSections';
import CustomizableButton from 'components/widgets/CustomizableButton';
import MediaWrapper from 'components/widgets/MediaWrapper';
import TextareaWithAddons from 'components/widgets/TextareaWithAddons';
import Switch from 'components/widgets/Switch';
import ActionButtons from './ActionButtons';
import {t} from 'utils';


export default function ChoiceCardSettings(props) {
  const {currentNode, onChangeNode, onSave, onErrorFactSet, onCancel} = props;
  const [errors, setErrors] = useState({cards: null, transferToOperator: null});
  const [cardNumber, slideCard] = useState(0);

  const newCard = {
    title: '',
    description: '',
    url: '',
    buttons: [{
      text: 'Button 1',
      next_block_id: null
    }],
    transfer_to_operator: false,
    attachment: {
      type: 'file',
      text: ''
    }
  };
  const handleNodeCardChange = (data, field, cardInd) => {
    onChangeNode(currentNode.content.cards[cardInd][field] ?
      {content: {cards: {[cardInd]: {[field]: {$set: data}}}}} :
      {content: {cards: {[cardInd]: {$merge: {[field]: data}}}}})
  };

  const handleButtonChange = (data, buttonInd, cardInd) => {
    onChangeNode({content: {cards: {[cardInd]: {buttons: {[buttonInd]: {$merge: data}}}}}});
  };
  const handleButtonAdd = (button, cardInd) => {
    onChangeNode(currentNode.content.cards[cardInd] ?
      {content: {cards: {[cardInd]: {buttons: {$push: [button]}}}}} :
      {content: {cards: {[cardInd]: {$merge: {buttons: [button]}}}}});
  };
  const handleButtonDelete = (buttonInd, cardInd) => {
    onChangeNode({content: {cards: {[cardInd]: {buttons: {$splice: [[buttonInd, 1]]}}}}});
  };

  const handleCardAdd = () => {
    if (currentNode.content.cards) {
      onChangeNode({content: {cards: {$push: [newCard]}}});
    } else {
      onChangeNode({content: {$set: {cards: [newCard]}}});
    }
  };

  const handleCardDelete = (i) => {
    onChangeNode({content: {cards: {$splice: [[i, 1]]}}},
      currentNode.content.cards.length === 1);
  };

  const handleNodeChange = (data, field) => {
    onChangeNode(currentNode.content[field] ?
      {content: {[field]: {$set: data}}} : {content: {$merge: {[field]: data}}})
  };

  const save = () => {
    let gettingErrors = {};
    if (!currentNode.content.cards || (currentNode.content.cards && currentNode.content.cards.length == 0)) {
      gettingErrors = {...gettingErrors, cards: 'You must add the card'};
    }
    if (currentNode.content.transfer_to_operator && (!currentNode.content.transfer_sections ||
      (currentNode.content.transfer_sections &&currentNode.content.transfer_sections.length === 0))) {
      gettingErrors = {...gettingErrors, transferToOperator: 'You must add the field'};
    }
    if (Object.values(gettingErrors).length === 0) {
      onErrorFactSet(false);
      onSave();
    } else {
      onErrorFactSet(true);
      setErrors(gettingErrors);
    }
  };

  return (
    <Fragment>
      <div data-animation="slide" data-hide-arrows="1" data-duration="500" data-infinite="1"
           className="node-settinsg-slider-w w-slider in-diagram sidebar-edit">
        <div className="error-info-client">
          {errors && errors.cards && (
            <div className="error-message">{errors.cards}</div>
          )}
        </div>
        <div className="cards-node-masc w-slider-mask">
          {currentNode.content.cards && currentNode.content.cards.map((card, i) => {
            if (!card) {
              return null;
            }
            if (i !== cardNumber) {
              return null;
            }
            let type = card.attachment && card.attachment.type;
            if (type !== 'file' && card.attachment.link) {
              if (card.attachment.link.includes('youtube.com') || card.attachment.link.includes('youtube.be')) {
                type = 'youtube'
              }
              if (card.attachment.link.includes('giphy.com')) {
                type = 'giphy'
              }
            }
            return (
              <div className="card-slide w-slide">
                <div className="node-settings sidebar-edit">
                  <div className="settings-item">
                    <MediaWrapper loaderType={card.attachment && card.attachment.link &&
                    (card.attachment.link.includes('youtube') || card.attachment.link.includes('youtu.be')) ?
                      'youtube' : (card.attachment && card.attachment.link && card.attachment.link.includes('giphy') ? 'giphy' : type)}
                                  value={card.attachment.file || card.attachment.link}
                                  onChangeType={type => {
                                    if (!card.attachment.link) {
                                      handleNodeCardChange({...card.attachment, type}, 'attachment', i)
                                    } else {
                                      type = type === 'youtube' || type === 'youtu.be' || type === 'giphy' ? 'link' : type;
                                      handleNodeCardChange({...card.attachment, type}, 'attachment', i)
                                    }

                                  }}
                                  onChangeValue={data => {
                                    if (data.link && (data.link.includes('youtube') || data.link.includes('youtu.be') || data.link.includes('giphy'))) {
                                      handleNodeCardChange({...card.attachment, ...data, type: 'link'}, 'attachment', i)
                                    } else {
                                      handleNodeCardChange({...card.attachment, ...data}, 'attachment', i)
                                    }

                                  }}
                                  onDelete={() => {}}
                    />
                  </div>
                  <div className="settings-item">
                    <input type="text" className="node-settings-input w-input"
                           value={card.title ||  ''}
                           onChange={e => handleNodeCardChange(e.target.value, 'title', i)}
                           maxLength="256" placeholder="Title" required=""/>
                  </div>
                  <TextareaWithAddons isSmallTextarea={true}
                                      propertiesHeight={'150px'}
                                      isNotDeleting={true}
                                      value={card.description ||  ''}
                                      onChange={value => handleNodeCardChange(value, 'description', i)}
                                      placeholderField="web.create.botBuilder.choice_cards.message.placeholder"/>
                  <div className="settings-item">
                    <input type="text" className="node-settings-input w-input"
                           value={card.url ||  ''}
                           onChange={e => handleNodeCardChange(e.target.value, 'url', i)}
                           maxLength="256" placeholder="URL"  required=""/>
                  </div>
                  {card.buttons && card.buttons.map((button, buttonInd) =>
                    <div className="settings-item">
                      <CustomizableButton name={button.text}
                                          onChangeName={value => handleButtonChange({text: value}, buttonInd, i)}
                                          onDelete={() => handleButtonDelete(buttonInd, i)}/>
                    </div>
                  )}
                  <div className="btns-w">
                    <div className="step-btn add-field-full w-inline-block" onClick={() => handleButtonAdd({
                      text: `Button ${card.buttons ? card.buttons.length + 1 : 1}`,
                      next_block_id: null
                    }, i)}>
                      <div>+ Add button</div>
                    </div>
                  </div>
                </div>
                <div className="card-slide-control">
                  <div className="control-btn more-size w-inline-block" onClick={() => handleCardDelete(i)}>
                    <div></div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        {cardNumber > 0 && (
          <div className="slider-arrow w-slider-arrow-left" onClick={() => slideCard(cardNumber - 1)}>
            <div className="w-icon-slider-left"></div>
          </div>
        )}
        {currentNode.content.cards && cardNumber < (currentNode.content.cards.length - 1) ? (
          <div className="slider-arrow right-arrow w-slider-arrow-right" onClick={() => slideCard(cardNumber + 1)}>
            <div className="w-icon-slider-right"></div>
          </div>
        ) : (
          <div className="add-card-btn w-inline-block"
               onClick={() => {
                 if (errors && errors.cards) {
                   setErrors({...errors, cards: null});
                 }
                 handleCardAdd();
               }}>
            <div className="plus-icon-w">
              <div className="plus-line"></div>
              <div className="plus-line rotate"></div>
            </div>
          </div>
        )}

        <div className="cards-node-slide-nav w-slider-nav w-slider-nav-invert w-shadow w-round">
          {currentNode.content.cards && currentNode.content.cards.map((card, i) => {
            return (
              <div class={`w-slider-dot ${cardNumber === i ? 'w-active' : ''}`}
                   onClick={() => slideCard(i)}
                   key={i} data-wf-ignore=""></div>
            );
          })}
        </div>
      </div>
      <div className="divider"></div>
      <div className="transfer-header">
        <div className="settings-item-label">{t('web.create.botBuilder.transferMessage.title')}</div>
        <Switch isOn={currentNode.content.transfer_to_operator}
                onClick={() => handleNodeChange(!currentNode.content.transfer_to_operator, 'transfer_to_operator')}/>
      </div>
      <div className="error-info-client">
        {errors && errors.transferToOperator && (
          <div className="error-message">{errors.transferToOperator}</div>
        )}
      </div>
      {currentNode.content.transfer_to_operator && (
        <div className="transfer-settings-w">
          <BlockBuilderSections sections={currentNode.content.transfer_sections || []}
                                textareaPlaceholderField="web.create.botBuilder.transferMessage.placeholder"
                                onAdd={section => {
                                  if (errors && errors.transferToOperator) {
                                    setErrors({...errors, transferToOperator: null});
                                  }
                                  onChangeNode(currentNode.content.transfer_sections ?
                                    {content: {transfer_sections: {$push: [section]}}} : {content: {$merge: {transfer_sections: [section]}}})
                                }}
                                onDelete={(i) => onChangeNode({content: {transfer_sections: {$splice: [[i, 1]]}}},
                                  currentNode.content.transfer_sections.length === 1)}
                                onChange={(data, i) => onChangeNode({content: {transfer_sections: {[i]: {$merge: data}}}})}
          />
        </div>
      )}
      <ActionButtons onSave={save} onCancel={onCancel}/>
    </Fragment>
  );
}