import React, {Fragment, useState} from 'react';
import Header from 'components/Header';
import ErrorBlock from 'components/widgets/ErrorBlock';
import StepChain from '../StepChain';
import {t} from 'utils';
import update from 'immutability-helper';
import SubmitButton from "components/widgets/SubmitButton";
import {useCampaigns} from "hooks";


export default function BotType(props) {
  const {currentStep, isCampaignSaving, type, setCurrentStep, campaign, onSave, onUpdateBotSchema,
    setCampaign, campaignType} = props;
  const {isCreating, isUpdating} = useCampaigns();
  const [error, setError] = useState('');

  const handleCurrentStepToggle = (step) => {
    if (campaign.name) {
      if (step === 1 && campaign.id) {
        onUpdateBotSchema();
      }
      setCurrentStep(step);
    } else {
      setError('Field empty');
    }
  };

  const handleEdit = () => {
    onSave(campaign.enabled, false);
  }

  return (
    <Fragment>
      <Header title={t(`${campaignType}.create.botType.header${campaign && campaign.id ? '.edit' : ''}`)}/>
      <div className="page-content normal-content">
        <div className="flex-justify-between">
          <StepChain type={type} currentStep={currentStep} campaign={campaign} setCurrentStep={handleCurrentStepToggle}/>
          {campaign && campaign.id && (
            <SubmitButton simpleButton={true} additionalClass={'add-big-indent w-button'}
                          onClick={handleEdit}
                          isSending={isCreating || isUpdating || isCampaignSaving}
                          onLoadEnd={() => {window.location = '/campaigns';}}
                          text={t(`button.save`)}/>
          )}
        </div>


        <div className="content-white-block">
          <div>
            <div className="form-block w-form">
              <form id="email-form" name="email-form" data-name="Email Form">
                <div className="error-info-client">
                  <label htmlFor="name">{t(`${campaignType}.create.botType.name.label`)}</label>
                  <ErrorBlock error={error} addClass="align-right margin-bottom"/>
                </div>
                {t(`${campaignType}.create.botType.name.placeholder`, {},
                  msg => <input type="text"  value={campaign.name}
                                onChange={e => setCampaign(update(campaign, {
                                  name: {$set: e.target.value}
                                }))}
                                className="node-settings-input input w-input" maxLength="256" name="name"
                                placeholder={msg} id="name"/>)}

              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
        {!campaign.id && (
          <div className="content-white-block">
            <div className="content-wrap">
              <div onClick={() => {
                onUpdateBotSchema();
                handleCurrentStepToggle(1);
              }} className="button add-indent w-button">
                <span className="awesome-icon big-icon"></span> {t(`${campaignType}.create.botType.bot.buildBot`)}
              </div>
              <div className="block-heading">{t(`${campaignType}.create.botType.bot.chooseTemplate`)}</div>
              <div className="wrapper display-flex">
                <a href="#" className="template-preview template-soon w-inline-block">Soon</a>
                <a href="#" className="template-preview template-soon w-inline-block">Soon</a>
                <a href="#" className="template-preview template-soon w-inline-block">Soon</a>
                <a href="#" className="template-preview template-soon w-inline-block">Soon</a>
              </div>
            </div>
          </div>
        )}

      </div>
    </Fragment>
  );
}