import update from 'immutability-helper';
import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import * as actions from 'actions';
import {PaymentStatus, PayablePaymentStatuses} from 'constants.js';
import {translateAssign} from 'utils';
import {createUserErrorMessage, StripeError} from 'utils/errors';
import {fetchProcessingPaymentsExist, persistProcessingPaymentsExist} from 'utils/payments';
import Billing from './Billing';
import RecurrentPaymentFetcher from './RecurrentPaymentFetcher';
import {main, billing} from 'translate';


const extractPaymentTransactionIdFromQueryArgs = () => {
  const queryArgs = queryString.parse(window.location.search || '');
  return queryArgs.pa && queryArgs.st && queryArgs.dep ? queryArgs.dep : null;
};

const needsConfirmPayment = () => {
  return !!extractPaymentTransactionIdFromQueryArgs();
};

const createPayPaymentErrorMessage = (error, lang = 'en') => {
  // FIXME: Better create custom error for this case
  if (
    error
    && !(error instanceof StripeError)
    && error.message
    && error.message.indexOf('paid') > 0
    && error.paymentStatus
  ) {
    const message = {
      [PaymentStatus.Paid]: main[lang]['error.payment.notPaid'],
      [PaymentStatus.Cancelled]: main[lang]['error.payment.cancelled'],
    }[error.paymentStatus];

    if (message) {
      return {
        message,
        action: 'refresh_payment_history',
      }
    }
  }

  return createUserErrorMessage(error);
};

const createConfirmPaymentErrorMessage = (confirmPaymentError, lang = 'en') => {

  // debugger;

  if (!confirmPaymentError) {
    return null;
  }


  let message = main[lang]['error.confirm.payment'];
  if (!(confirmPaymentError instanceof StripeError)) {
    if (confirmPaymentError.code === 106) {
      message = main[lang]['error.confirm.payment.expired'];
    } else if (confirmPaymentError.code === 116) {
      message = main[lang]['error.confirm.payment.missing'];
    }
  }
  return message;
};

let recurrentPaymentFetcher = null;


export default compose(
  withRouter,

  connect((state, ownProps) => {
    // debugger;
    const billingState = state.billing;
    let payments = Object.values(state.billing.paymentHistoryById);
    const processingPaymentsExist = fetchProcessingPaymentsExist();
    const paymentsToFetchRecurrently = [];

    if (processingPaymentsExist && payments) {
      // debugger;
      payments = payments.map(payment => {
        if (PayablePaymentStatuses.indexOf(payment.status) >= 0) {
          // debugger;
          paymentsToFetchRecurrently.push(payment.id);
          return update(payment, {status: {$set: PaymentStatus.Processing}});
        } else {
          return payment;
        }
      });
    }

    if (recurrentPaymentFetcher) {
      if (paymentsToFetchRecurrently.length) {
        recurrentPaymentFetcher.runFetching(paymentsToFetchRecurrently);
      } else {
        recurrentPaymentFetcher.stopFetching();
      }
    }

    return {
      messages: [main, billing],
      currentWidgetId: state.widgets.currentWidgetId,
      currentUserId: state.users.currentUser.id,
      userCurrentPlan: billingState.userCurrentPlan,
      userPaymentHistory: payments,
      needsConfirmPayment: needsConfirmPayment(),
      isFailed: billingState._isFailed,
      payPaymentError: createPayPaymentErrorMessage(billingState.errors, 'en'),
      cardErrorMessage: createUserErrorMessage(billingState.userCardError, 'en'),
      confirmPaymentErrorMessage: createConfirmPaymentErrorMessage(billingState.confirmPaymentError, 'en'),
      isPaymentConfirmationSuccessful: billingState._isPaymentConfirmationSuccessful,
      isPaymentCheckingout: billingState._isPaymentCheckingout,
      isPaymentMaking: billingState._isPaymentMaking,
      isPaymentBeingConfirmed: billingState._isPaymentBeingConfirmed,
      isUserCardSetting: billingState._isUserCardSetting,
      isUserCurrentPlanFetching: billingState._isUserCurrentPlanFetching,
    };
  }),

  lifecycle({
    componentDidMount() {
      document.title = 'Billing | HeyCom'

      const {dispatch} = this.props;
      recurrentPaymentFetcher = new RecurrentPaymentFetcher(dispatch);
      Promise.all([
        dispatch(actions.fetchUserCurrentPlan({widget_id: this.props.currentWidgetId})),
        //dispatch(actions.fetchUserPaymentHistory())
      ])
        .then(() => {
          // if (needsConfirmPayment()) {
          //   dispatch(confirmLastPayment(extractPaymentTransactionIdFromQueryArgs()))
          //     .then(data => {
          //       if (!data.error) {
          //         dispatch(fetchUserPlan());
          //       }
          //     });
          // }
        });
    },

    componentWillUnmount() {
      recurrentPaymentFetcher.stopFetching();
      recurrentPaymentFetcher = null;
    },
  }),

  withHandlers({
    onPaymentCardSet: props => stripeObject => {
      return props.dispatch(actions.setUserCard(stripeObject));
    },

    onPay: props => (paymentId, params) => {
      return props.dispatch(actions.payPayment(paymentId, params))
        .then(() => {
          // persistProcessingPaymentsExist();
          props.dispatch(actions.fetchUserCurrentPlan({widget_id: props.currentWidgetId}));
          props.dispatch(actions.fetchUserPaymentHistory(params));
        })
    },

    // onSort: props => params => {
    //   return props.dispatch(actions.fetchUserPaymentHistory(params));
    // },

    onCardModalClose: props => () => {
      return props.dispatch(actions.clearUserCardError());
    },

    onPaymentHistoryRefresh: props => params => {
      return props.dispatch(actions.fetchUserPaymentHistory(params));
    }
  })
) (
  Billing
);
