import update from 'immutability-helper';
import cloneDeep from 'lodash.clonedeep';
import * as actions from 'actions';
import {CAMPAIGN_TYPES} from 'constants.js'

const initialState = {
  // leads
  leadsById: {},
  leadsCount: 0,
  leadsError: null,
  _isLeadsFetching: false,
  _isLeadsDeleting: false,
  _isLeadsExporting: false,

  // segments
  segmentsById: {},
  segmentsError: null,
  _isSegmentsFetching: false,
  _isCreating: false,
  _isUpdating: false,

  //campaign type filter
  selectedCampaignType: Object.values(CAMPAIGN_TYPES)[0],

  // filters
  selectedFilters: {}
};

export default function leads(state=initialState, action) {
  switch(action.type) {
    // leads
    case actions.FETCH_LEADS__R: {
      return update(state, {
        _isLeadsFetching: {$set: true}
      });
    }
    case actions.FETCH_LEADS__S: {
      return update(state, {
        leadsById: {$set: action.payload.payload.reduce((obj, cur) => {
          obj[cur.id] = cur;
          return obj;
        }, {})},
        leadsCount: {$set: action.payload.count !== undefined ? action.payload.count : state.leadsCount},
        leadsError: {$set: null},
        _isLeadsFetching: {$set: false}
      });
    }
    case actions.FETCH_LEADS__F: {
      return update(state, {
        leadsError: {$set: action.payload},
        _isLeadsFetching: {$set: false}
      });
    }

    case actions.DELETE_LEADS__R: {
      return update(state, {
        _isLeadsDeleting: {$set: true}
      });
    }
    case actions.DELETE_LEADS__S: {
      const leadsById = cloneDeep(state.leadsById);
      for (const leadId in state.leadsById) {
        if (action.payload.indexOf(leadId) !== -1) {
          delete leadsById[leadId];
        }
      }
      return update(state, {
        leadsById: {$set: leadsById},
        leadsCount: {$set: state.leadsCount - action.payload.length},
        leadsError: {$set: null},
        _isLeadsDeleting: {$set: false}
      });
    }
    case actions.DELETE_LEADS__F: {
      return update(state, {
        leadsError: {$set: action.payload},
        _isLeadsDeleting: {$set: false}
      });
    }

    case actions.EXPORT_LEADS__R: {
      return update(state, {
        _isLeadsExporting: {$set: true}
      });
    }
    case actions.EXPORT_LEADS__S: {
      return update(state, {
        leadsError: {$set: null},
        _isLeadsExporting: {$set: false}
      });
    }
    case actions.EXPORT_LEADS__F: {
      return update(state, {
        leadsError: {$set: action.payload},
        _isLeadsExporting: {$set: false}
      });
    }

    // segments
    case actions.FETCH_SEGMENTS__R: {
      return update(state, {
        _isSegmentsFetching: {$set: true}
      });
    }
    case actions.FETCH_SEGMENTS__S: {
      return update(state, {
        segmentsById: {$set: action.payload.payload.reduce((obj, cur) => {
            obj[cur.id] = cur;
            return obj;
          }, {})},
        segmentsError: {$set: null},
        _isSegmentsFetching: {$set: false}
      });
    }
    case actions.FETCH_SEGMENTS__F: {
      return update(state, {
        segmentsError: {$set: action.payload},
        _isSegmentsFetching: {$set: false}
      });
    }
    case actions.CREATE_SEGMENT__R: {
      return update(state, {
        _isCreating: {$set: true}
      });
    }
    case actions.CREATE_SEGMENT__S: {
      let segment = action.payload;
      return update(state, {
        segmentsById: {[action.payload.id]: {$set: segment}},
        _isCreating: {$set: false}
      });
    }
    case actions.CREATE_SEGMENT__F: {
      return update(state, {
        segmentsError: {$set: action.payload},
        _isCreating: {$set: false}
      });
    }
    case actions.UPDATE_SEGMENT__R: {
      return update(state, {
        _isUpdating: {$set: true}
      });
    }
    case actions.UPDATE_SEGMENT__S: {
      let segment = action.payload;
      return update(state, {
        segmentsById: {[action.payload.id]: {$merge: segment}},
        _isUpdating: {$set: false}
      });
    }
    case actions.UPDATE_SEGMENT__F: {
      return update(state, {
        segmentsError: {$set: action.payload},
        _isUpdating: {$set: false}
      });
    }

    // campaign type filter
    case actions.SET_LEADS_CAMPAIGN_TYPE_FILTER: {
      return update(state, {
        selectedCampaignType: {$set: action.payload},
      });
    }

    // filters
    case actions.SET_LEADS_FILTERS: {
      return update(state, {
        selectedFilters: {$set: action.payload},
      });
    }

    default:
      return state;
  }
}
