import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import Chats from './Chats';
import {main, chats} from 'translate';

export default compose(
  connect((state, ownProps) => {
      return {
        messages: [main, chats]
      };
    }
  ),

  lifecycle({
    componentDidMount() {
      document.title = 'Chats | HeyCom'

      console.log('Chats componentDidMount')
    },
  }),

  withHandlers()
) (
  Chats
);