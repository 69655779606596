import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose, withHandlers, lifecycle} from 'recompose';
import {fetchUserCard, setSelectedPlan, buyPlan, clearBillingErrors} from 'actions';
import NewSubscription from './NewSubscription';
import {createUserErrorMessage} from 'utils';


export default compose(
  withRouter,

  connect((state, ownProps) => {

    return {
      currentUser: state.users.currentUser,
      selectedPlan: state.billing.selectedPlan,
      selectedPeriod: state.billing.selectedPeriod,
      clientToken: state.billing.clientToken,
      userCard: state.billing.userCard,
      // requestErrors: state.billing.errors,
      payPaymentErrorMessage: createUserErrorMessage(state.billing.errors),
      isFailed: state.billing._isFailed,
      isPlanCheckingout: state.billing._isPlanCheckingout,
      isPlanBuying: state.billing._isPlanBuying,
      isSuccessfulPlanBuying: state.billing._isSuccessfulPlanBuying,
      isUserCardLoading: state.billing._isUserCardFetching,
    }
  }),

  lifecycle({
    componentDidMount(props) {
      //this.props.dispatch(fetchUserCard());
    },
    componentWillUnmount(props) {
      this.props.dispatch(setSelectedPlan(null, null));
      this.props.dispatch(clearBillingErrors());
    }
  }),

  withHandlers({
    onPlanBuy: props => (plan, stripe) => {
      return props.dispatch(buyPlan(plan, stripe))
        .then(() => {
          setSelectedPlan(null);
          // persistProcessingPaymentsExist();
        });
    }
  })
) (
  NewSubscription
);
