import React from 'react';
import {CompoundMessage} from './CompoundMessage';
import {BOT_SECTION_TYPES} from 'constants.js';


export const SendMessage = (props) => {
  const {message, sections, onSendMessage} = props;

  if (!sections) {
    return null;
  }
  return (
    sections.map((section, i) => {
      return (<CompoundMessage key={i} section={section}/>)
    })
  );
};
