import React, {Fragment, useState, useEffect, useRef} from 'react';
import Draggable from 'react-draggable';
import update from 'immutability-helper';
import _ from 'lodash';
import MediaWrapper from 'components/widgets/MediaWrapper';
import TextareaWithAddons from 'components/widgets/TextareaWithAddons';
import {t, getConnectionCoordinates, ConnectionTipDirection} from 'utils';
import {BOT_NODE_TYPES} from 'constants.js';


export default function NodeWrapper(props) {
  const {node, selectCurrentNode, setCurrentNode, onCopyNode, onDeleteNode,
    onStartAddTransition, onEndAddTransition} = props;
  const {type, id} = node;

  return (
    <div id={`element-${id}`} onMouseUp={e => {
      onEndAddTransition(e, id)
    }} className="diagram-node-wrap position-absolute">
      <div className="diagram-node-control node-control-animation">
        <div className="control-btn w-inline-block" onClick={onCopyNode}>
          <div></div></div>
        <div className="control-btn more-size w-inline-block" onClick={onDeleteNode}>
          <div></div></div>
      </div>
      <div className="digram-node-header">
        <div className="diagram-node-name-w">
          <div className="diagram-node-name">{t(`web.create.botBuilder.${type}`)}</div>
        </div>
      </div>
      <div className="diagram-node-content">
        {type === BOT_NODE_TYPES.sendMessage && (
          <SendMessageNode node={node}/>
        )}
        {type === BOT_NODE_TYPES.choiceButton && (
          <ChoiceButtonNode
            node={node}
            onStartAddTransition={(e, data) => onStartAddTransition(e, data)}/>
        )}
        {type === BOT_NODE_TYPES.choiceCard && (
          <ChoiceCardNode
            node={node}
            onStartAddTransition={(e, data) => onStartAddTransition(e, data)}/>
        )}
        {type === BOT_NODE_TYPES.askQuestion && (
          <AskQuestionNode node={node}/>
        )}
        {type === BOT_NODE_TYPES.sendEmail && (
          <SendEmailNode node={node}/>
        )}
        {type === BOT_NODE_TYPES.goToLiveChat && (
          <GoToLiveChatNode node={node}/>
        )}
        {type === BOT_NODE_TYPES.notifyManager && (
          <NotifyManagerNode node={node}/>
        )}
        {type === BOT_NODE_TYPES.closeChat && (
          <CloseChatNode node={node}/>
        )}

        <div className="hover-popup-w hover-popup-animation" onClick={() => {
          if (!selectCurrentNode || (selectCurrentNode && selectCurrentNode.id !== node.id)) {
            setCurrentNode(node);
          }
        }}>
          <div>Click to edit</div>
        </div>
      </div>

      {type !== BOT_NODE_TYPES.choiceButton && type !== BOT_NODE_TYPES.choiceCard && (
        <div id={`drag-${id}`}
             className="drag-point-w"
             onMouseDown={(e) => onStartAddTransition(e, {id: node.id, cardInd: null, buttonInd: null})}>
          <div className="dn-drag-point"></div>
        </div>
      )}

    </div>
  )
};

const SendMessageNode = (props) => {
  const {node} = props;
  const mime = require('mime-types');
  return (
    <>{
      node && node.content && node.content.sections && node.content.sections.map((section, sectionIdx) => {
      switch (section.type) {
        case 'text': {
          return (
            <div key={sectionIdx} className="dn-content-item word-break-all white-space-pre">
              <div className="dn-message-w">
                <div className="dn-message-text">{section.text}</div>
              </div>
            </div>
          );
        }
        case 'file': {
          return (section.file &&
            <div key={sectionIdx}
                 style={{backgroundImage: `url('${(section.file.url || section.file.preview)}')`}}
                 className="dn-content-item dn-media-w img-on-bg"></div>
          );
        }
        default: {
          let linkHtml = <a href={section.link} className="dn-message-text link">{section.link}</a>;
          if (section.link && (section.link.includes('youtube') || section.link.includes('youtu.be'))) {
            let link = section.link;

            if (section.link.includes('https://youtu.be/')) {
              link = `https://www.youtube.com/embed/${section.link.slice('https://youtu.be/'.length)}?controls=0`;
            }
            if (section.link.includes('https://www.youtube.com/watch?v=')) {
              link = `https://www.youtube.com/embed/${section.link.slice('https://www.youtube.com/watch?v='.length)}?controls=0`;
            }
            linkHtml = <iframe class="iframe-in-message margin-attachment-bottom" src={link} width="100%"
                               frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen={false}></iframe>
          }
          if (section.link && section.link.includes('giphy')) {
            let link = section.link;

            if (section.link.includes('https://media.giphy.com/media/')) {
              link = `https://giphy.com/embed/${section.link.slice('https://media.giphy.com/media/'.length, section.lastIndexOf('/'))}`;
            }
            if (section.link.includes('https://giphy.com/gifs/')) {
              link = `https://giphy.com/embed/${section.link.slice('https://giphy.com/gifs/'.length)}`;
            }

            linkHtml = <iframe width="100%" class="iframe-in-message margin-attachment-bottom" src={link} frameBorder="0"
                      allowFullScreen></iframe>
          }

          if (section.link && mime.lookup(section.link) && mime.lookup(section.link).includes('image')) {
            linkHtml = <img className="iframe-in-message margin-attachment-bottom" src={section.link}/>
          }

          if (section.link && mime.lookup(section.link) && mime.lookup(section.link).includes('video') &&
            (mime.lookup(section.link).includes('mp4') || mime.lookup(section.link).includes('ogg') || mime.lookup(section.link).includes('webm'))) {
            linkHtml = <video className="iframe-in-message margin-attachment-bottom video-in-node" src={section.link}/>
          }
          return (
            <div key={sectionIdx} className="dn-content-item word-break-all">
              <div className="dn-message-w">
                {linkHtml}
              </div>
            </div>
          );
        }
      }
    })}
    </>
  );
};

const ChoiceButtonNode = (props) => {
  const {node, onStartAddTransition} = props;
  return (
    <>
      <div className="dn-content-item" key={0}>
        <div className="dn-message-w">
          <div className="dn-message-text">{node.content.text}</div>
        </div>
      </div>
      <div className="dn-content-item" id={`buttons-container-${node.id}`} key={1}>
        <div className="dn-buttons-w">
          {node.content.buttons && node.content.buttons.map((button, i) => {
            return (
              <div className="dn-button" id={`element-${node.id}-button-${i}`}  key={i}>
                <div className="dn-button-text">{button.text}</div>
                <div className="drag-point-w for-btn" id={`drag-${node.id}-button-${i}`}
                     onMouseDown={e => onStartAddTransition(e, {id: node.id, cardInd: null, buttonInd: i})}>
                  <div className="dn-drag-point"></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const ChoiceCardNode = (props) => {
  const {node, onStartAddTransition} = props;
  const [cardNumber, slideCard] = useState(0);
  const mime = require('mime-types');
  return (
    <>
      <div data-animation="slide" data-hide-arrows="1" data-duration="500" data-infinite="1"
           className="node-settinsg-slider-w no-padding w-slider in-diagram">
        <div className="cards-node-masc w-slider-mask overflow-unset">
          {node.content.cards && node.content.cards.map((card, i) => {
            let linkHtml = ''

            if (cardNumber !== i) {
              return null;
            }

            if (card.attachment && card.attachment.type && card.attachment.type !== 'file') {
              linkHtml = <a href={card.attachment.link} className="dn-message-text link">{card.attachment.link}</a>;

              if (card.attachment.link && (card.attachment.link.includes('youtube') || card.attachment.link.includes('youtu.be'))) {
                let link = card.attachment.link;

                if (card.attachment.link.includes('https://youtu.be/')) {
                  link = `https://www.youtube.com/embed/${card.attachment.link.slice('https://youtu.be/'.length)}?controls=0`;
                }
                if (card.attachment.link.includes('https://www.youtube.com/watch?v=')) {
                  link = `https://www.youtube.com/embed/${card.attachment.link.slice('https://www.youtube.com/watch?v='.length)}?controls=0`;
                }
                linkHtml = <iframe class="iframe-in-message margin-attachment-bottom" src={link} width="100%"
                          frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen={false}></iframe>
              }

              if (card.attachment.link && mime.lookup(card.attachment.link) && mime.lookup(card.attachment.link).includes('image')) {
                linkHtml = <img className="iframe-in-message margin-attachment-bottom" src={card.attachment.link}/>
              }

              if (card.attachment.link && mime.lookup(card.attachment.link) && mime.lookup(card.attachment.link).includes('video') &&
                (mime.lookup(card.attachment.link).includes('mp4') || mime.lookup(card.attachment.link).includes('ogg') || mime.lookup(card.attachment.link).includes('webm'))) {
                linkHtml = <video className="iframe-in-message margin-attachment-bottom video-in-node" src={card.attachment.link}/>
              }
            }

            return (
              <div className="card-slide w-slide" key={i}>
                <div className="node-settings">
                  {card.attachment && card.attachment.type && (
                    <>
                      {card.attachment.type === 'file' && card.attachment.file ? (
                        <div className="settings-item add-margin">
                          <div style={{backgroundImage: `url('${(card.attachment.file.url || card.attachment.file.preview)}')`}}
                               className="dn-media-w img-on-bg"></div>
                        </div>
                      ) : (
                        <div className="dn-content-item word-break-all">
                          <div className="dn-message-w">
                            {linkHtml}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {card.title && (
                    <div className="dn-content-item">
                      <div className="dn-message-w">
                        <div className="dn-title">{card.title}</div>
                      </div>
                    </div>
                  )}
                  {card.description && (
                    <div className="dn-content-item">
                      <div className="dn-message-w">
                        <div className="dn-message-text">{card.description}</div>
                      </div>
                    </div>
                  )}
                  {card.url && (
                    <div className="dn-content-item">
                      <div className="dn-message-w">
                        <a href="#" className="dn-message-text link">{card.url}</a></div>
                    </div>
                  )}
                  <div className="settings-item" id={`buttons-container-${node.id}-card-${i}`}>
                    {card.buttons && card.buttons.map((button, buttonInd) => {
                      return (
                        <div className="dn-button" id={`element-${node.id}-card-${i}-button-${buttonInd}`}
                             key={buttonInd}>
                          <div className="dn-button-text">{button.text}</div>
                          <div className="drag-point-w for-btn" id={`drag-${node.id}-card-${i}-button-${buttonInd}`}
                               onMouseDown={e => onStartAddTransition(e, {id: node.id, cardInd: i, buttonInd})}>
                            <div className="dn-drag-point"></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {cardNumber > 0 && (
          <div className="slider-arrow in-diagram w-slider-arrow-left" onClick={() => slideCard(cardNumber - 1)}>
            <div className="w-icon-slider-left"></div>
          </div>
        )}
        {node.content.cards && cardNumber < (node.content.cards.length - 1) && (
          <div className="slider-arrow in-diagram right-arrow w-slider-arrow-right" onClick={() => slideCard(cardNumber + 1)}>
            <div className="w-icon-slider-right"></div>
          </div>
        )}
        <div className="cards-node-slide-nav hide-slide-nav w-slider-nav w-slider-nav-invert w-shadow w-round"></div>
      </div>
    </>
  );
};

const AskQuestionNode = (props) => {
  const {node} = props;
  return (
    <Fragment>
      <div className="dn-content-item">
        <div className="dn-message-w">
          <div className="dn-message-text">{node.content.question}</div>
        </div>
      </div>
    </Fragment>
  );
};

const SendEmailNode = (props) => {
  const {node} = props;
  return (
    <Fragment>
      <div className="dn-content-item">
        <div className="dn-message-w">
          <div className="dn-title">{node.content.subject || ''}</div>
        </div>
      </div>
      <div className="dn-content-item">
        <div className="dn-message-w">
          <div className="dn-message-text"><div dangerouslySetInnerHTML={{__html: node.content.message || ''}}></div></div>
        </div>
      </div>
    </Fragment>
  );
};

const GoToLiveChatNode = (props) => {
  const {node} = props;
  return (
    <Fragment>
      <div className="dn-content-item">
        <div>
          <div className="dn-message-text">Talk to your users</div>
        </div>
      </div>
    </Fragment>
  );
};

const NotifyManagerNode = (props) => {
  const {node} = props;
  return (
    <Fragment>
      <div className="dn-content-item">
        <div className="dn-message-w">
          <div className="dn-message-text">{node.content.message}</div>
        </div>
      </div>
    </Fragment>
  );
};

const CloseChatNode = (props) => {
  const {node} = props;
  return (
    <Fragment>
      <div className="dn-content-item">
        <div>
          <div className="dn-message-text">
            {node.content && node.content.message ? node.content.message : 'Set chat status to closed'}
          </div>
        </div>
      </div>
    </Fragment>
  );
};