import update from 'immutability-helper';
import l_cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import * as actions from 'actions';
import {MEETINGS_CHART_LINES, MEETING_EMAIL_TYPE} from 'constants.js'


const initialState = {
  chartData: null,
  chartDataError: null,

  meetingsToday: [],

  bookedMeetings: [],
  bookedMeetingsCount: null,
  managersByBookedMeeting: [],
  managersByBookedMeetingCount: null,

  meetingLinksById: {},
  meetingLinksCount: null,

  meetingSettings: {},

  chartError: null,
  meetingTodayError: null,
  meetingSettingsError: null,
  bookedMeetingsError: null,
  meetingLinksByIdError: null,

  _isMeetingsTodayFetching: false,
  _isBookedMeetingsExporting: false,
  _isChartDataFetching: false,
  _isBookedMeetingsFetching: false,
  _isManagersFetching: false,
  _isMeetingLinksFetching: false,
  _isMeetingLinkFetching: false,
  _isMeetingLinkAdding: false,
  _isMeetingLinkDeleting: false,
  _isMeetingLinkEditing: false,
  _isMeetingLinkCoping: false,
  _isMeetingSettingsFetching: false,
  _isMeetingSettingsSendingTest: {
    [MEETING_EMAIL_TYPE.confirm]: false,
    [MEETING_EMAIL_TYPE.remindDay]: false,
    [MEETING_EMAIL_TYPE.remindHalfHour]: false,
  },
  _isUpdatingMeetingSettings: false,

  isUpdateBookedMeetings: false,
};

export default function meetings(state=initialState, action) {
  switch(action.type) {
    case actions.UPDATE_BOOKED_MEETING_LIST: {
      return update(state, {
        isUpdateBookedMeetings: {$set: action.payload}
      })
    }

    case actions.FETCH_MEETINGS_TODAY__R: {
      return update(state, {
        _isMeetingsTodayFetching: {$set: true}
      });
    }
    case actions.FETCH_MEETINGS_TODAY__S: {
      return update(state, {
        meetingsToday: {$set: action.payload.payload.map(meeting => transformMeetingToday(meeting))},
        meetingTodayError: {$set: null},
        _isMeetingsTodayFetching: {$set: false}
      });
    }
    case actions.FETCH_MEETINGS_TODAY__F: {
      return update(state, {
        meetingTodayError: {$set: action.payload},
        _isMeetingsTodayFetching: {$set: false}
      });
    }

    case actions.COPY_MEETING_TODAY__R: {
      return update(state, {
        _isMeetingsTodayFetching: {$set: true}
      });
    }
    case actions.COPY_MEETING_TODAY__S: {
      return update(state, {
        meetingsToday: {$push: [transformMeetingToday(action.payload)]},
        meetingTodayError: {$set: null},
        _isMeetingsTodayFetching: {$set: false}
      });
    }
    case actions.COPY_MEETING_TODAY__F: {
      return update(state, {
        meetingTodayError: {$set: action.payload},
        _isMeetingsTodayFetching: {$set: false}
      });
    }


    case actions.FETCH_MEETING_SETTINGS__R: {
      return update(state, {
        _isMeetingSettingsFetching: {$set: true}
      });
    }
    case actions.FETCH_MEETING_SETTINGS__S: {
      return update(state, {
        meetingSettings: {$set: transformMeetingSettings(action.payload)},
        meetingSettingsError: {$set: null},
        _isMeetingSettingsFetching: {$set: false}
      });
    }
    case actions.FETCH_MEETING_SETTINGS__F: {
      return update(state, {
        meetingSettingsError: {$set: action.payload},
        _isMeetingSettingsFetching: {$set: false}
      });
    }

    case actions.UPDATE_MEETING_SETTINGS__R: {
      return update(state, {
        _isUpdatingMeetingSettings: {$set: true}
      });
    }
    case actions.UPDATE_MEETING_SETTINGS__S: {
      return update(state, {
        meetingSettings: {$set: transformMeetingSettings(action.payload)},
        _isUpdatingMeetingSettings: {$set: false}
      });
    }
    case actions.UPDATE_MEETING_SETTINGS__F: {
      return update(state, {
        meetingSettingsError: {$set: action.payload},
        _isUpdatingMeetingSettings: {$set: false}
      });
    }

    case actions.SEND_TEST_EMAIL__R: {
      //console.log(action.payload, action.payload.type, state._isMeetingSettingsSendingTest);
      return update(state, {
        sendingTestError: {$set: null},
        _isMeetingSettingsSendingTest: {$set: {
          ...state._isMeetingSettingsSendingTest,
          [action.payload.type]: true,
        }}
      });
    }
    case actions.SEND_TEST_EMAIL__S: {
      return update(state, {
        sendingTestError: {$set: null},
        _isMeetingSettingsSendingTest: {$set: {
          ...state._isMeetingSettingsSendingTest,
          [action.payload.type]: false,
        }}
      });
    }
    case actions.SEND_TEST_EMAIL__F: {
      return update(state, {
        sendingTestError: {$set: action.payload.error},
        _isMeetingSettingsSendingTest: {$set: {
          ...state._isMeetingSettingsSendingTest,
          [action.meta.type]: false,
        }}
      });
    }


    case actions.FETCH_MEETING_CHART_DATA__R: {
      return update(state, {
        _isChartDataFetching: {$set: true}
      });
    }
    case actions.FETCH_MEETING_CHART_DATA__S: {
      const data = getFormattedChartData(action.payload.data);

      return update(state, {
        chartData:  {$set: {
          chartData: data.chartData,
          totalCounts: data.totalCounts,
          tickFormat: action.payload.tickFormat
        }},
        _isChartDataFetching: {$set: false}
      });
    }
    case actions.FETCH_MEETING_CHART_DATA__F: {
      return update(state, {
        chartError: {$set: action.payload},
        _isChartDataFetching: {$set: false}
      });
    }

    case actions.FETCH_BOOKED_MEETINGS__R: {
      return update(state, {
        _isBookedMeetingsFetching: {$set: true}
      });
    }
    case actions.FETCH_BOOKED_MEETINGS__S: {
      return update(state, {
        bookedMeetings: {$set: action.payload.payload.map(meeting => transformBookedMeting(meeting))},
        bookedMeetingsCount: {$set: action.payload.count},
        bookedMeetingsError: {$set: null},
        _isBookedMeetingsFetching: {$set: false}
      });
    }
    case actions.FETCH_BOOKED_MEETINGS__F: {
      return update(state, {
        bookedMeetingsError: {$set: action.payload},
        _isBookedMeetingsFetching: {$set: false}
      });
    }

    case actions.EXPORT_BOOKED_MEETINGS__R: {
      return update(state, {
        _isBookedMeetingsExporting: {$set: true}
      });
    }
    case actions.EXPORT_BOOKED_MEETINGS__S: {
      return update(state, {
        bookedMeetingsError: {$set: null},
        _isBookedMeetingsExporting: {$set: false}
      });
    }
    case actions.EXPORT_BOOKED_MEETINGS__F: {
      return update(state, {
        bookedMeetingsError: {$set: action.payload},
        _isBookedMeetingsExporting: {$set: false}
      });
    }

    case actions.FETCH_TOP_MANAGER_BOOKED_MEETINGS__R: {
      return update(state, {
        _isManagersFetching: {$set: true}
      });
    }
    case actions.FETCH_TOP_MANAGER_BOOKED_MEETINGS__S: {
      return update(state, {
        managersByBookedMeeting: {$set: action.payload.map(manager => transformManagerBookedMeting(manager))},
        managersByBookedMeetingCount: {$set: action.payload.count || 0},
        bookedMeetingsError: {$set: null},
        _isManagersFetching: {$set: false}
      });
    }
    case actions.FETCH_TOP_MANAGER_BOOKED_MEETINGS__F: {
      return update(state, {
        bookedMeetingsError: {$set: action.payload},
        _isManagersFetching: {$set: false}
      });
    }

    case actions.FETCH_MEETING_LINKS__R: {
      return update(state, {
        _isMeetingLinksFetching: {$set: true}
      });
    }
    case actions.FETCH_MEETING_LINKS__S: {
      return update(state, {
        meetingLinksById: {$set: action.payload.payload.reduce((obj, cur) => {
          obj[cur.id] = transformMeetingLink(cur);
          return obj;
        }, {})},
        meetingLinksCount: {$set: action.payload.count},
        meetingLinksByIdError: {$set: null},
        _isMeetingLinksFetching: {$set: false}
      });
    }
    case actions.FETCH_MEETING_LINKS__F: {
      return update(state, {
        meetingLinksByIdError: {$set: action.payload},
        _isMeetingLinksFetching: {$set: false}
      });
    }

    case actions.FETCH_MEETING_LINK__R: {
      return update(state, {
        _isMeetingLinkFetching: {$set: true}
      });
    }
    case actions.FETCH_MEETING_LINK__S: {
      return update(state, {
        meetingLinksById: {$set: {
          ...state.meetingLinksById,
          [action.payload.id]: transformMeetingLink(action.payload)
        }},
        meetingLinksByIdError: {$set: null},
        _isMeetingLinkFetching: {$set: false}
      });
    }
    case actions.FETCH_MEETING_LINK__F: {
      return update(state, {
        meetingLinksByIdError: {$set: action.payload},
        _isMeetingLinkFetching: {$set: false}
      });
    }

    case actions.ADD_MEETING_LINK__R: {
      return update(state, {
        _isMeetingLinkAdding: {$set: true}
      });
    }
    case actions.ADD_MEETING_LINK__S: {
      return update(state, {
        meetingLinksById: {$set: {
          ...state.meetingLinksById,
          [action.payload.id]: transformMeetingLink(action.payload)
        }},
        meetingLinksCount: {$set: state.meetingLinksCount + 1},
        meetingLinksByIdError: {$set: null},
        _isMeetingLinkAdding: {$set: false}
      });
    }
    case actions.ADD_MEETING_LINK__F: {
      return update(state, {
        meetingLinksByIdError: {$set: action.payload},
        _isMeetingLinkAdding: {$set: false}
      });
    }

    case actions.DELETE_MEETING_LINK__R: {
      return update(state, {
        _isMeetingLinkDeleting: {$set: true}
      });
    }
    case actions.DELETE_MEETING_LINK__S: {
      const meetingLinksById = l_cloneDeep(state.meetingLinksById);
      delete meetingLinksById[action.payload];
      return update(state, {
        meetingLinksById: {$set: meetingLinksById},
        meetingLinksCount: {$set: state.meetingLinksCount - 1},
        meetingLinksByIdError: {$set: null},
        _isMeetingLinkDeleting: {$set: false}
      });
    }
    case actions.DELETE_MEETING_LINK__F: {
      return update(state, {
        meetingLinksByIdError: {$set: action.payload},
        _isMeetingLinkDeleting: {$set: false}
      });
    }

    case actions.EDIT_MEETING_LINK__R: {
      return update(state, {
        _isMeetingLinkEditing: {$set: true}
      });
    }
    case actions.EDIT_MEETING_LINK__S: {
      return update(state, {
        meetingLinksById: {$set: {
          ...state.meetingLinksById,
          [action.payload.id]: {
            ...state.meetingLinksById[action.payload.id],
            ...transformMeetingLink(action.payload)
          }
        }},
        meetingLinksByIdError: {$set: null},
        _isMeetingLinkEditing: {$set: false}
      });
    }
    case actions.EDIT_MEETING_LINK__F: {
      return update(state, {
        meetingLinksByIdError: {$set: action.payload},
        _isMeetingLinkEditing: {$set: false}
      });
    }

    case actions.COPY_MEETING_LINK__R: {
      return update(state, {
        _isMeetingLinkCoping: {$set: true}
      });
    }
    case actions.COPY_MEETING_LINK__S: {
      return update(state, {
        meetingLinksById: {$set: {
          ...state.meetingLinksById,
          [action.payload.id]: transformMeetingLink(action.payload)
        }},
        meetingLinksCount: {$set: state.meetingLinksCount + 1},
        meetingLinksByIdError: {$set: null},
        _isMeetingLinkCoping: {$set: false}
      });
    }
    case actions.COPY_MEETING_LINK__F: {
      return update(state, {
        meetingLinksByIdError: {$set: action.payload},
        _isMeetingLinkCoping: {$set: false}
      });
    }

    default:
      return state;
  }
}

const transformMeetingToday = (meeting) => {
  return {
    id: meeting.id,
    name: meeting.client.first_name,
    email: meeting.client.email,
    duration: meeting.meeting_link.duration,
    meetingTime:  meeting.datetime ?
      moment(meeting.datetime).format("HH:mm") : '',
    meetingLink: meeting.meeting_link.meeting_url,
  }
};

const transformMeetingLink = (link) => {
  return {
    id: link.id,
    name: link.name,
    duration: link.duration,
    isDefault: link.is_default,
    operatorId: link.operator_id,
    widgetId: link.widget_id,
    // operator: {
    //   name: link.operator.name
    // },
    avatar: link.avatar,
    title: link.title,
    location: link.location,
    description: link.description,

    emailUrlPrefix: link.email_url_prefix,
    customUrlPostfix: link.custom_url_postfix,
    meetingUrlWithoutPostfix: link.meeting_url_without_postfix,
    url: link.meeting_url
  }
};

const transformBookedMeting = (meeting) => {
  return {
    id: meeting.id,
    name: meeting.client.first_name,
    email: meeting.client.email,
    operatorId: meeting.meeting_link.operator_id,
    bookingDate: meeting.created_at ?
      moment(meeting.created_at).format("dddd, MMMM D, YYYY h:mm A") : '',
    meetingDate: meeting.datetime ?
      moment(meeting.datetime).format("dddd, MMMM D, YYYY h:mm A") : '',
    status: meeting.status
  }
};

const transformMeetingSettings = (settings) => {
  return {
    operator_id: settings.operator_id,
    user_id: settings.user_id,
    user_email: settings.user_email,
    default_meeting_link: settings.default_meeting_link,
    availability: {
      available_schedule: {
        schedule: settings.schedule,
        timeFormat: settings.time_format,
        timeZone: settings.timezone
      },
      minimum_notice_time: {
        enabled: settings.minimum_notice_time.enabled,
        value: settings.minimum_notice_time.amount
      },
      buffer_time: {
        enabled: settings.buffer_time.enabled,
        value: settings.buffer_time.amount
      },
    },
    email_notifications: {
      test_email: settings.user_email,
      confirmation_email: {
        enabled: settings.confirmation_email.enabled,
        confirmation_template: {
          subject: settings.confirmation_email.subject,
          body: settings.confirmation_email.body
        }
      },
      reminder_email: {
        enabled: settings.reminder_email.enabled,
        remind_day_before_template: {
          subject: settings.reminder_email.day_notice.subject,
          body: settings.reminder_email.day_notice.body
        },
        remind_half_an_hour_before_template: {
          subject: settings.reminder_email.half_hour_notice.subject,
          body: settings.reminder_email.half_hour_notice.body
        }
      }
    }
  }
};

const transformManagerBookedMeting = (manager) => {
  return {
    id: manager.id,
    name: manager.name,
    count: manager.count
  }
};

const transformAnalyticsData = analyticsData => {
  return {
    total_views: analyticsData.views,
    total_bookings: analyticsData.bookings,
    views: analyticsData.views_points,
    meetings_booked: analyticsData.bookings_points
  }
};

const getFormattedChartData = (analyticsData) => {
  analyticsData = transformAnalyticsData(analyticsData);
  const chartData = [];
  const totalCounts = {
    [MEETINGS_CHART_LINES.views]: analyticsData.total_views,
    [MEETINGS_CHART_LINES.meetingsBooked]: analyticsData.total_bookings,
  };
  const totalViews = analyticsData[MEETINGS_CHART_LINES.views];
  const totalMeetingBooked = analyticsData[MEETINGS_CHART_LINES.meetingsBooked];

  totalViews.forEach((point, i) => {
    const p = {
      datetime: point.datetime,
      date: moment(point.datetime).format('YYYY-MM-DD'),
      time: moment(point.datetime).format('HH:mm'),
      [MEETINGS_CHART_LINES.views]: point.value,
      [MEETINGS_CHART_LINES.meetingsBooked]: totalMeetingBooked[i].value,
    };
    chartData.push(p)
  });

  return {chartData, totalCounts};
};
