import React, { PureComponent } from 'react';


export default class CampaignWarningBlock extends PureComponent {
  render() {
    const { error, addClass } = this.props;
    if (!error) {
      return null;
    }
    return (
        <div className="alert-w warning">
          <div className="alert-div">
            <div className="awes-icon"></div>
            <div>{error}</div>
          </div>
        </div>
    );
  }
}
