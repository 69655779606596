import client from 'api/client';
import * as _ from 'lodash';


export const FETCH_OPERATORS__R = 'FETCH_OPERATORS__R';
export const fetchOperatorsRq = () => {
  return {
    type: FETCH_OPERATORS__R
  };
};
export const FETCH_OPERATORS__S = 'FETCH_OPERATORS__S';
export function fetchOperatorsSc(operators) {
  return {
    payload: operators,
    type: FETCH_OPERATORS__S
  };
}
export const FETCH_OPERATORS__F = 'FETCH_OPERATORS__F';
export function fetchOperatorsFl(error) {
  return {
    payload: error,
    type: FETCH_OPERATORS__F
  };
}
export const FETCH_OPERATORS = 'FETCH_OPERATORS';
export const fetchOperators = ({widgetId}) => (dispatch, getState) => {
  dispatch(fetchOperatorsRq());
  return client.get(`/operators`, {
    params: {widget_id: widgetId}
  })
    .then(data => {
      dispatch(fetchOperatorsSc(data));
    })
    .catch(error => {
      dispatch(fetchOperatorsFl(error))
    });
};

export const FETCH_OPERATOR__R = 'FETCH_OPERATOR__R';
export const fetchOperatorRq = () => {
  return {
    type: FETCH_OPERATOR__R
  };
};
export const FETCH_OPERATOR__S = 'FETCH_OPERATOR__S';
export function fetchOperatorSc(operator) {
  return {
    payload: operator,
    type: FETCH_OPERATOR__S
  };
}
export const FETCH_OPERATOR__F = 'FETCH_OPERATOR__F';
export function fetchOperatorFl(error) {
  return {
    payload: error,
    type: FETCH_OPERATOR__F
  };
}
export const FETCH_OPERATOR = 'FETCH_OPERATOR';
export const fetchOperator = (operatorId) => (dispatch, getState) => {
  dispatch(fetchOperatorRq());
  return client.get(`/operators/${operatorId}`)
    .then(data => {
      dispatch(fetchOperatorSc(data));
    })
    .catch(error => {
      dispatch(fetchOperatorFl(error))
    });
};

export const CREATE_OPERATOR__R = 'CREATE_OPERATOR__R';
export const createOperatorRq = () => {
  return {
    type: CREATE_OPERATOR__R
  };
};
export const CREATE_OPERATOR__S = 'CREATE_OPERATOR__S';
export function createOperatorSc(operator) {
  return {
    payload: operator,
    type: CREATE_OPERATOR__S
  };
}
export const CREATE_OPERATOR__F = 'CREATE_OPERATOR__F';
export function createOperatorFl(error) {
  return {
    payload: error,
    type: CREATE_OPERATOR__F
  };
}
export const CREATE_OPERATOR = 'CREATE_OPERATOR';
export const createOperator = newOperatorData => (dispatch, getState) => {
  dispatch(createOperatorRq());

  return client.post('/operators', newOperatorData)
    .then(data => {
      dispatch(createOperatorSc(data));
    })
    .catch(error => {
      dispatch(createOperatorFl(error))
    });
};

export const UPDATE_OPERATOR__R = 'UPDATE_OPERATOR__R';
export const updateOperatorRq = () => {
  return {
    type: UPDATE_OPERATOR__R
  };
};
export const UPDATE_OPERATOR__S = 'UPDATE_OPERATOR__S';
export function updateOperatorSc(operator) {
  return {
    payload: operator,
    type: UPDATE_OPERATOR__S
  };
}
export const UPDATE_OPERATOR__F = 'UPDATE_OPERATOR__F';
export function updateOperatorFl(error) {
  return {
    payload: error,
    type: UPDATE_OPERATOR__F
  };
}
export const UPDATE_OPERATOR = 'UPDATE_OPERATOR';
export const updateOperator = (operatorId, data, toggleActivate = false) => (dispatch, getState) => {
  dispatch(updateOperatorRq());

  let newOperatorData = _.cloneDeep(data);
  if (!toggleActivate) {
    delete newOperatorData.is_activate;
  }
  return client.patch(`/operators/${operatorId}`, newOperatorData)
    .then(data => {
      dispatch(updateOperatorSc(data));
    })
    .catch(error => {
      dispatch(updateOperatorFl(error));
    });
};

export const ADD_OPERATOR__R = 'ADD_OPERATOR__R';
export const addOperatorRq = () => {
  return {
    type: ADD_OPERATOR__R
  };
};

export const ADD_OPERATOR__S = 'ADD_OPERATOR__S';
export function addOperatorSc(operator) {
  return {
    payload: operator,
    type: ADD_OPERATOR__S
  };
}
export const ADD_OPERATOR__F = 'ADD_OPERATOR__F';
export function addOperatorFl(error) {
  return {
    payload: error,
    type: ADD_OPERATOR__F
  };
}

export const ADD_OPERATOR = 'ADD_OPERATOR';
export const addOperator = (data) => (dispatch, getState) => {
  dispatch(addOperatorRq());

  return client.post('/operators', data).then(data => {
    dispatch(addOperatorSc(data));
    return data;
  }).catch(error => {
    console.log(error);
    dispatch(addOperatorFl(error));
    return error;
  });
};


export const REMOVE_OPERATOR__R = 'REMOVE_OPERATOR__R';
export const removeOperatorRq = () => {
  return {
    type: REMOVE_OPERATOR__R
  };
};

export const REMOVE_OPERATOR__S = 'REMOVE_OPERATOR__S';
export function removeOperatorSc(id) {
  return {
    payload: id,
    type: REMOVE_OPERATOR__S
  };
}

export const REMOVE_OPERATOR__F = 'REMOVE_OPERATOR__F';
export function removeOperatorFl(error) {
  return {
    payload: error,
    type: REMOVE_OPERATOR__F
  };
}

export const REMOVE_OPERATOR = 'REMOVE_OPERATOR';
export const removeOperator = operatorId => (dispatch, getState) => {
  dispatch(removeOperatorRq());
  return client.delete(`/operators/${operatorId}`)
      .then(data => {
        dispatch(removeOperatorSc(operatorId));
        return {data};
      })
      .catch(error => {
        dispatch(removeOperatorFl(error));
        return {error};
      });
};

export const SET_CURRENT_OPERATOR = 'SET_CURRENT_OPERATOR';
export function setCurrentOperator(error) {
  return {
    payload: error,
    type: SET_CURRENT_OPERATOR
  };
}

export const UPDATING_OPERATOR = 'UPDATING_OPERATOR';
export function updatingOperator(data) {
  return {
    payload: data,
    type: UPDATING_OPERATOR,
  }
}