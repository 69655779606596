import React, {useState} from 'react';
import update from 'immutability-helper';

import Switch from 'components/widgets/Switch';
import CustomSelect from 'components/widgets/CustomSelect';
import {t, h} from 'utils';


export default function ButtonDisplaySettings(props) {
  const {webChatType, campaign, setCampaign} = props;

  const buttonDisplaySettings = campaign.display_settings.button_display;
  const messageConstant = 'web.create.displaySettings.button';
  const urlOptions = {
    is: {value: 'is', label: `${messageConstant}.urls.is`},
    isnot: {value: 'isnot', label: `${messageConstant}.urls.isnot`},
    includes: {value: 'includes', label: `${messageConstant}.urls.includes`},
    notinclude: {value: 'notinclude', label: `${messageConstant}.urls.notinclude`}
  };

  const handleDeleteUrl = ind => {
    setCampaign(update(campaign, {
      display_settings: {
        button_display: {
          urls: {$splice: [[ind, 1]]}
        }
      }
    }));
  };
  const handleAddUrl = () => {
    setCampaign(update(campaign, {
      display_settings: {
        button_display: {
          urls: {$push: [{option: 'is', link: ''}]}
        }
      }
    }));
  };
  const handleChangeUrlLink = (value, i) => {
    setCampaign(update(campaign, {
      display_settings: {
        button_display: {
          urls: {[i]: {link: {$set: value}}}
        }
      }
    }));
  };
  const handleChangeUrlOption = (value, i) => {
    setCampaign(update(campaign, {
      display_settings: {
        button_display: {
          urls: {[i]: {option: {$set: value}}}
        }
      }
    }));
  };

  return (
    <div className="w-tab-pane w--tab-active">
      <div className="pane-content-w">
        <div className="content-white-block">
          <div>
            <div className="group-title">{t(`${messageConstant}.${webChatType}.title`)}</div>
            <div className="page-subheader">{t(`${messageConstant}.${webChatType}.notice`)}</div>
          </div>
          <div className="group-content-w">
            <div className="group-w">
              <div className="group-w-header">
                <div className="group-title">{t(`${messageConstant}.urls.title`)}</div>
                <Switch isOn={buttonDisplaySettings.is_display_on_specific_page}
                        onClick={() => {
                          setCampaign(update(campaign, {
                            display_settings: {
                              button_display: {
                                $toggle: ['is_display_on_specific_page']
                              }
                            }
                          }));
                        }}/>
              </div>
              {buttonDisplaySettings.is_display_on_specific_page && (
                <div className="group-block">
                  <div className="show-group-content">
                    <div className="show-group-div">
                      {buttonDisplaySettings.urls.map((url, i) => (
                        <div className="group-row">
                          <div className="col-6 select-block">
                            <CustomSelect options={Object.values(urlOptions)}
                                          defaultValue={url.option ? urlOptions[url.option] : urlOptions.is}
                                          selectClassName={`settings-select w-select`}
                                          getOptionLabel={option => t(option.label)}
                                          onChange={url => handleChangeUrlOption(url.value, i)}
                                          value={url.value}/>
                          </div>
                          <div className={`col-6 ${i === 0 ? 'right-padding' : 'hor-flex'}`}>
                            <input type="text" value={url.link} className="node-settings-input w-input"
                                   onChange={(e) => handleChangeUrlLink(e.target.value, i)} maxLength="256"/>
                            {i !== 0 && (
                              <div className="delete-btn" onClick={() => handleDeleteUrl(i)}>
                                <div className="close-line-1 rotate"></div>
                                <div className="close-line-1"></div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="blue-button min-h w-button" onClick={handleAddUrl}>
                      <span className="awesome-icon big-icon"></span>  {t(`${messageConstant}.urls.add`)}</div>
                  </div>
                  <div className="show-group-content">
                    <div className="text-group">
                      <label htmlFor="Landing-Page-URL" className="field-label-2">{h(`${messageConstant}.rules.title`)}</label>
                      <ol className="list">
                        <li>
                          <div className="column-text min-indent">{h(`${messageConstant}.rules.first`)}</div>
                        </li>
                        <li>
                          <div className="column-text min-indent">{h(`${messageConstant}.rules.twelve`)}</div>
                        </li>
                        <li>
                          <div className="column-text min-indent">{h(`${messageConstant}.rules.third`)}</div>
                        </li>
                        <li>
                          <div className="column-text min-indent">{h(`${messageConstant}.rules.fourth`)}</div>
                        </li>
                      </ol>
                      <div className="small-text">{h(`${messageConstant}.rules.footnote.one`)}</div>
                      <div className="small-text">{h(`${messageConstant}.rules.footnote.two`)}</div>
                      <label htmlFor="Landing-Page-URL" className="field-label-2 top-indent">{h(`${messageConstant}.rules.example`)}</label>
                      <div className="blue-span">chatbot.app</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}