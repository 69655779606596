import client from 'api/client';


// leads

export const FETCH_LEADS__R = 'FETCH_LEADS__R';
export const fetchLeadsRq = () => {
    return {
        type: FETCH_LEADS__R
    };
};
export const FETCH_LEADS__S = 'FETCH_LEADS__S';
export function fetchLeadsSc(leads) {
    return {
        payload: leads,
        type: FETCH_LEADS__S
    };
}

export const FETCH_LEADS__F = 'FETCH_LEADS__F';
export function fetchLeadsFl(error) {
    return {
        payload: error,
        type: FETCH_LEADS__F
    };
}

export const FETCH_LEADS = 'FETCH_LEADS';
export const fetchLeads = (params) => (dispatch, getState) => {
    dispatch(fetchLeadsRq());
    return client.get(`/clients`, {
      params: {
        ...params
      }
    })
      .then(data => {
        dispatch(fetchLeadsSc(data));
      })
      .catch(error => {
        dispatch(fetchLeadsFl(error))
      });
};


export const DELETE_LEADS__R = 'DELETE_LEADS__R';
export const deleteLeadsRq = () => {
  return {
    type: DELETE_LEADS__R
  };
};

export const DELETE_LEADS__S = 'DELETE_LEADS__S';
export function deleteLeadsSc(leadIds) {
  return {
    payload: leadIds,
    type: DELETE_LEADS__S
  };
}

export const DELETE_LEADS__F = 'DELETE_LEADS__F';
export function deleteLeadsFl(error) {
  return {
    payload: error,
    type: DELETE_LEADS__F
  };
}

export const DELETE_LEADS = 'DELETE_LEADS';
export const deleteLeads = (widgetId, leadIds) => (dispatch, getState) => {
  dispatch(deleteLeadsRq());
  return client.post(`/clients/delete?widget_id=${widgetId}`, {
    client_ids: leadIds
  })
    .then(data => {
      dispatch(deleteLeadsSc(leadIds));
      return {data};
    })
    .catch(error => {
      dispatch(deleteLeadsFl(error));
      return {error};
    });
};

export const EXPORT_LEADS__R = 'EXPORT_LEADS__R';
export const exportLeadsRq = () => {
  return {
    type: EXPORT_LEADS__R
  };
};

export const EXPORT_LEADS__S = 'EXPORT_LEADS__S';
export function exportLeadsSc() {
  return {
    type: EXPORT_LEADS__S
  };
}

export const EXPORT_LEADS__F = 'EXPORT_LEADS__F';
export function exportLeadsFl(error) {
  return {
    payload: error,
    type: EXPORT_LEADS__F
  };
}

export const EXPORT_LEADS = 'EXPORT_LEADS';
export const exportLeads = (params) => (dispatch, getState) => {
  dispatch(exportLeadsRq());

  return client.get(`/clients`, {params}).then(data => {
    dispatch(exportLeadsSc(data));
    return data;
  }).catch(error => {
    dispatch(exportLeadsFl(error));
  });
};


// segments

export const FETCH_SEGMENTS__R = 'FETCH_SEGMENTS__R';
export const fetchSegmentsRq = () => {
    return {
        type: FETCH_SEGMENTS__R
    };
};
export const FETCH_SEGMENTS__S = 'FETCH_SEGMENTS__S';

export function fetchSegmentsSc(segments) {
    return {
        payload: segments,
        type: FETCH_SEGMENTS__S
    };
}

export const FETCH_SEGMENTS__F = 'FETCH_SEGMENTS__F';

export function fetchSegmentsFl(error) {
    return {
        payload: error,
        type: FETCH_SEGMENTS__F
    };
}

export const FETCH_SEGMENTS = 'FETCH_SEGMENTS';
export const fetchSegments = (params) => (dispatch, getState) => {
    dispatch(fetchSegmentsRq());
    return client.get(`/clients/segments`, {
      params: {
        ...params,
        offset: 0,
        limit: Number.MAX_SAFE_INTEGER
      }
    })
      .then(data => {
        dispatch(fetchSegmentsSc(data));
      })
      .catch(error => {
        dispatch(fetchSegmentsFl(error))
      });
};


export const CREATE_SEGMENT__R = 'CREATE_SEGMENT__R';
export const createSegmentRq = () => {
    return {
        type: CREATE_SEGMENT__R
    };
};
export const CREATE_SEGMENT__S = 'CREATE_SEGMENT__S';
export function createSegmentSc(segment) {
    return {
        payload: segment,
        type: CREATE_SEGMENT__S
    };
}
export const CREATE_SEGMENT__F = 'CREATE_SEGMENT__F';
export function createSegmentFl(error) {
    return {
        payload: error,
        type: CREATE_SEGMENT__F
    };
}
export const CREATE_SEGMENT = 'CREATE_SEGMENT';
export const createSegment = newSegmentData => (dispatch, getState) => {
    dispatch(createSegmentRq());
    return client.post('/clients/segments', newSegmentData)
      .then(data => {
          dispatch(createSegmentSc(data));
          return {data};
      })
      .catch(error => {
          dispatch(createSegmentFl(error));
          return {error};
      });
};


export const UPDATE_SEGMENT__R = 'UPDATE_SEGMENT__R';
export const updateSegmentRq = () => {
    return {
        type: UPDATE_SEGMENT__R
    };
};
export const UPDATE_SEGMENT__S = 'UPDATE_SEGMENT__S';
export function updateSegmentSc(data) {
    return {
        payload: data,
        type: UPDATE_SEGMENT__S
    };
}
export const UPDATE_SEGMENT__F = 'UPDATE_SEGMENT__F';
export function updateSegmentFl(error) {
    return {
        payload: error,
        type: UPDATE_SEGMENT__F
    };
}
export const UPDATE_SEGMENT = 'UPDATE_SEGMENT';
export const updateSegment = (id, updatedSegmentData) => (dispatch, getState) => {
    dispatch(updateSegmentRq());
    return client.patch(`/clients/segments/${id}`, updatedSegmentData)
      .then(data => {
          dispatch(updateSegmentSc(data));
          return {data};
      })
      .catch(error => {
          dispatch(updateSegmentFl(error));
          return {error};
      });
};

// campaign type filter

export const SET_LEADS_CAMPAIGN_TYPE_FILTER = 'SET_LEADS_CAMPAIGN_TYPE_FILTER';
export function setLeadsCampaignTypeFilter(value) {
    return {
        payload: value,
        type: SET_LEADS_CAMPAIGN_TYPE_FILTER,
    }
}


// filters

export const SET_LEADS_FILTERS = 'SET_LEADS_FILTERS';
export function setLeadsFilters(value) {
    return {
        payload: value,
        type: SET_LEADS_FILTERS,
    }
}
