import React, {Fragment, useState} from 'react';

import TextareaWithAddons from 'components/widgets/TextareaWithAddons';
import ActionButtons from './ActionButtons';
import {t as translateCommon} from 'utils';

export default function NotifyManagerSettings(props) {
  const {currentNode, onChangeNode, t, onSave, onErrorFactSet, onCancel} = props;
  const [errors, setErrors] = useState({message: null});

  const handleNodeChange = (data, field) => {
    onChangeNode(currentNode.content[field] ?
      {content: {[field]: {$set: data}}} : {content: {$merge: {[field]: data}}}, (!data))
  };
  const save = () => {
    let gettingErrors = {};
    if (!currentNode.content.message) {
      gettingErrors = {...gettingErrors, message: translateCommon('error.field.empty')};
    }
    if (Object.values(gettingErrors).length === 0) {
      onErrorFactSet(false);
      onSave();
    } else {
      onErrorFactSet(true);
      setErrors(gettingErrors);
    }
  };

  return (
    <Fragment>
      <div className="node-settings">
        <div className="settings-item">
          <div className="error-info-client">
            <div className="settings-item-label add-margin">{t('message.title')}</div>
            {errors && errors.message && (
              <div className="error-message">{errors.message}</div>
            )}
          </div>
          <TextareaWithAddons
            isNotDeleting={true}
            value={currentNode.content.message ||  ''}
            onChange={value => {
              if (errors && errors.message) {
                setErrors({...errors, message: null});
              }
              handleNodeChange(value, 'message')
            }}
            placeholderField="web.create.botBuilder.notify_manager.message.placeholder"/>
          <div className="info-text min-indent">{t('message.notice')}</div>
        </div>
      </div>
      <ActionButtons onSave={save} onCancel={onCancel}/>
    </Fragment>
  );
}
//NotifyManagerSettings