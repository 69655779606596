import client from 'api/client';
// campaigns

export const FETCH_CAMPAIGNS__R = 'FETCH_CAMPAIGNS__R';
export const fetchCampaignsRq = () => {
  return {
    type: FETCH_CAMPAIGNS__R
  };
};
export const FETCH_CAMPAIGNS__S = 'FETCH_CAMPAIGNS__S';
export function fetchCampaignsSc(campaigns) {
  return {
    payload: campaigns,
    type: FETCH_CAMPAIGNS__S
  };
}
export const FETCH_CAMPAIGNS__F = 'FETCH_CAMPAIGNS__F';
export function fetchCampaignsFl(error) {
  return {
    payload: error,
    type: FETCH_CAMPAIGNS__F
  };
}
export const FETCH_CAMPAIGNS = 'FETCH_CAMPAIGNS';
export const fetchCampaigns = (params) => (dispatch, getState) => {
  dispatch(fetchCampaignsRq());
  return client.get('/campaigns', {
    params: {
      ...params
    }
  })
    .then(data => {
      dispatch(fetchCampaignsSc(data));
    })
    .catch(error => {
      dispatch(fetchCampaignsFl(error))
    });
};


export const FETCH_CAMPAIGN__R = 'FETCH_CAMPAIGN__R';
export const fetchCampaignRq = () => {
  return {
    type: FETCH_CAMPAIGN__R
  };
};
export const FETCH_CAMPAIGN__S = 'FETCH_CAMPAIGN__S';
export function fetchCampaignSc(campaign) {
  return {
    payload: campaign,
    type: FETCH_CAMPAIGN__S
  };
}
export const FETCH_CAMPAIGN__F = 'FETCH_CAMPAIGN__F';
export function fetchCampaignFl(error) {
  return {
    payload: error,
    type: FETCH_CAMPAIGN__F
  };
}
export const FETCH_CAMPAIGN = 'FETCH_CAMPAIGN';
export const fetchCampaign = (campaignId) => (dispatch, getState) => {
  dispatch(fetchCampaignRq());
  return client.get(`/campaigns/${campaignId}`)
    .then(data => {
      dispatch(fetchCampaignSc(data));
    })
    .catch(error => {
      dispatch(fetchCampaignFl(error))
    });
};


export const CREATE_CAMPAIGN__R = 'CREATE_CAMPAIGN__R';
export const createCampaignRq = () => {
  return {
    type: CREATE_CAMPAIGN__R
  };
};
export const CREATE_CAMPAIGN__S = 'CREATE_CAMPAIGN__S';
export function createCampaignSc(campaign) {
  return {
    payload: campaign,
    type: CREATE_CAMPAIGN__S
  };
}
export const CREATE_CAMPAIGN__F = 'CREATE_CAMPAIGN__F';
export function createCampaignFl(error) {
  return {
    payload: error,
    type: CREATE_CAMPAIGN__F
  };
}
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const createCampaign = newCampaignData => (dispatch, getState) => {
  dispatch(createCampaignRq());
  return client.post('/campaigns', newCampaignData)
    .then(data => {
      dispatch(createCampaignSc(data));
      return {data};
    })
    .catch(error => {
      dispatch(createCampaignFl(error));
      return {error};
    });
};


export const UPDATE_CAMPAIGN__R = 'UPDATE_CAMPAIGN__R';
export const updateCampaignRq = () => {
  return {
    type: UPDATE_CAMPAIGN__R
  };
};
export const UPDATE_CAMPAIGN__S = 'UPDATE_CAMPAIGN__S';
export function updateCampaignSc(data) {
  return {
    payload: data,
    type: UPDATE_CAMPAIGN__S
  };
}
export const UPDATE_CAMPAIGN__F = 'UPDATE_CAMPAIGN__F';
export function updateCampaignFl(error) {
  return {
    payload: error,
    type: UPDATE_CAMPAIGN__F
  };
}
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const updateCampaign = (campaignId, updatedCampaignData) => (dispatch, getState) => {
  dispatch(updateCampaignRq());
  return client.patch(`/campaigns/${campaignId}`, updatedCampaignData)
    .then(data => {
      dispatch(updateCampaignSc(data));
      return {data};
    })
    .catch(error => {
      dispatch(updateCampaignFl(error));
      return {error};
    });
};


export const REMOVE_CAMPAIGN__R = 'REMOVE_CAMPAIGN__R';
export const removeCampaignRq = () => {
  return {
    type: REMOVE_CAMPAIGN__R
  };
};
export const REMOVE_CAMPAIGN__S = 'REMOVE_CAMPAIGN__S';
export function removeCampaignSc(id) {
  return {
    payload: id,
    type: REMOVE_CAMPAIGN__S
  };
}
export const REMOVE_CAMPAIGN__F = 'REMOVE_CAMPAIGN__F';
export function removeCampaignFl(error) {
  return {
    payload: error,
    type: REMOVE_CAMPAIGN__F
  };
}
export const REMOVE_CAMPAIGN = 'REMOVE_CAMPAIGN';
export const removeCampaign = campaignId => (dispatch, getState) => {
  dispatch(removeCampaignRq());
  return client.delete(`/campaigns/${campaignId}`)
    .then(data => {
      dispatch(removeCampaignSc(campaignId));
      return {data};
    })
    .catch(error => {
      dispatch(removeCampaignFl(error));
      return {error};
    });
};


export const ENABLE_CAMPAIGN__R = 'ENABLE_CAMPAIGN__R';
export const enableCampaignRq = () => {
  return {
    type: ENABLE_CAMPAIGN__R
  };
};
export const ENABLE_CAMPAIGN__S = 'ENABLE_CAMPAIGN__S';
export function enableCampaignSc(id) {
  return {
    payload: id,
    type: ENABLE_CAMPAIGN__S
  };
}
export const ENABLE_CAMPAIGN__F = 'ENABLE_CAMPAIGN__F';
export function enableCampaignFl(error) {
  return {
    payload: error,
    type: ENABLE_CAMPAIGN__F
  };
}
export const ENABLE_CAMPAIGN = 'ENABLE_CAMPAIGN';
export const enableCampaign = campaignId => (dispatch, getState) => {
  dispatch(enableCampaignRq());
  return client.get(`/campaigns/${campaignId}/enable`)
    .then(data => {
      dispatch(enableCampaignSc(campaignId));
      return {data};
    })
    .catch(error => {
      dispatch(enableCampaignFl(error));
      return {error};
    });
};


export const DISABLE_CAMPAIGN__R = 'DISABLE_CAMPAIGN__R';
export const disableCampaignRq = () => {
  return {
    type: DISABLE_CAMPAIGN__R
  };
};
export const DISABLE_CAMPAIGN__S = 'DISABLE_CAMPAIGN__S';
export function disableCampaignSc(id) {
  return {
    payload: id,
    type: DISABLE_CAMPAIGN__S
  };
}
export const DISABLE_CAMPAIGN__F = 'DISABLE_CAMPAIGN__F';
export function disableCampaignFl(error) {
  return {
    payload: error,
    type: DISABLE_CAMPAIGN__F
  };
}
export const DISABLE_CAMPAIGN = 'DISABLE_CAMPAIGN';
export const disableCampaign = campaignId => (dispatch, getState) => {
  dispatch(disableCampaignRq());
  return client.get(`/campaigns/${campaignId}/disable`)
    .then(data => {
      dispatch(disableCampaignSc(campaignId));
      return {data};
    })
    .catch(error => {
      dispatch(disableCampaignFl(error));
      return {error};
    });
};


export const ARCHIVE_CAMPAIGN__R = 'ARCHIVE_CAMPAIGN__R';
export const archiveCampaignRq = () => {
  return {
    type: ARCHIVE_CAMPAIGN__R
  };
};
export const ARCHIVE_CAMPAIGN__S = 'ARCHIVE_CAMPAIGN__S';
export function archiveCampaignSc(id) {
  return {
    payload: id,
    type: ARCHIVE_CAMPAIGN__S
  };
}
export const ARCHIVE_CAMPAIGN__F = 'ARCHIVE_CAMPAIGN__F';
export function archiveCampaignFl(error) {
  return {
    payload: error,
    type: ARCHIVE_CAMPAIGN__F
  };
}
export const ARCHIVE_CAMPAIGN = 'ARCHIVE_CAMPAIGN';
export const archiveCampaign = campaignId => (dispatch, getState) => {
  dispatch(archiveCampaignRq());
  return client.get(`/campaigns/${campaignId}/archive`)
    .then(data => {
      dispatch(archiveCampaignSc(campaignId));
      return {data};
    })
    .catch(error => {
      dispatch(archiveCampaignFl(error));
      return {error};
    });
};


export const UNARCHIVE_CAMPAIGN__R = 'UNARCHIVE_CAMPAIGN__R';
export const unarchiveCampaignRq = () => {
  return {
    type: UNARCHIVE_CAMPAIGN__R
  };
};
export const UNARCHIVE_CAMPAIGN__S = 'UNARCHIVE_CAMPAIGN__S';
export function unarchiveCampaignSc(id) {
  return {
    payload: id,
    type: UNARCHIVE_CAMPAIGN__S
  };
}
export const UNARCHIVE_CAMPAIGN__F = 'UNARCHIVE_CAMPAIGN__F';
export function unarchiveCampaignFl(error) {
  return {
    payload: error,
    type: UNARCHIVE_CAMPAIGN__F
  };
}
export const UNARCHIVE_CAMPAIGN = 'UNARCHIVE_CAMPAIGN';
export const unarchiveCampaign = campaignId => (dispatch, getState) => {
  dispatch(unarchiveCampaignRq());
  return client.get(`/campaigns/${campaignId}/unarchive`)
    .then(data => {
      dispatch(unarchiveCampaignSc(campaignId));
      return {data};
    })
    .catch(error => {
      dispatch(unarchiveCampaignFl(error));
      return {error};
    });
};



export const CLONE_CAMPAIGN__R = 'CLONE_CAMPAIGN__R';
export const cloneCampaignRq = () => {
  return {
    type: CLONE_CAMPAIGN__R
  };
};
export const CLONE_CAMPAIGN__S = 'CLONE_CAMPAIGN__S';
export function cloneCampaignSc(campaign) {
  return {
    payload: campaign,
    type: CLONE_CAMPAIGN__S
  };
}
export const CLONE_CAMPAIGN__F = 'CLONE_CAMPAIGN__F';
export function cloneCampaignFl(error) {
  return {
    payload: error,
    type: CLONE_CAMPAIGN__F
  };
}
export const CLONE_CAMPAIGN = 'CLONE_CAMPAIGN';
export const cloneCampaign = (campaignId, campaignName) => (dispatch, getState) => {
  dispatch(cloneCampaignRq());
  return client.post(`/campaigns/${campaignId}/clone`, {
    name: campaignName
  })
    .then(data => {
      dispatch(cloneCampaignSc(data));
      return {data};
    })
    .catch(error => {
      dispatch(cloneCampaignFl(error));
      return {error};
    });
};

export const TRIGGER_CAMPAIGN__R = 'TRIGGER_CAMPAIGN__R';
export const triggerCampaignRq = () => {
  return {
    type: TRIGGER_CAMPAIGN__R
  };
};
export const TRIGGER_CAMPAIGN__S = 'TRIGGER_CAMPAIGN__S';
export function triggerCampaignSc(campaign) {
  return {
    payload: campaign,
    type: TRIGGER_CAMPAIGN__S
  };
}
export const TRIGGER_CAMPAIGN__F = 'TRIGGER_CAMPAIGN__F';
export function triggerCampaignFl(error) {
  return {
    payload: error,
    type: TRIGGER_CAMPAIGN__F
  };
}
export const TRIGGER_CAMPAIGN = 'TRIGGER_CAMPAIGN';
export const triggerCampaign = data => (dispatch, getState) => {
  dispatch(triggerCampaignRq());
  return client.post(`/campaigns/${data.campaignId}/triggered`, {
    client_id: data.clientId
  })
    .then(data => {
      dispatch(triggerCampaignSc(data));
      return {data};
    })
    .catch(error => {
      dispatch(triggerCampaignFl(error));
      return {error};
    });
};


// campaign type filter

export const SET_CAMPAIGNS_CAMPAIGN_TYPE_FILTER = 'SET_CAMPAIGNS_CAMPAIGN_TYPE_FILTER';
export function setCampaignsCampaignTypeFilter(value) {
  return {
    payload: value,
    type: SET_CAMPAIGNS_CAMPAIGN_TYPE_FILTER,
  }
}

//CHANGE_BOT_SCHEMA

export const CHANGE_BOT_SCHEMA = 'CHANGE_BOT_SCHEMA';
export function changeBotSchema(value) {
  return {
    payload: value,
    type: CHANGE_BOT_SCHEMA,
  }
}

export const SELECT_BLOCK_TYPE = 'SELECT_BLOCK_TYPE';
export function selectBlockType(value) {
  return {
    payload: value,
    type: SELECT_BLOCK_TYPE,
  }
}

export const SELECT_BOT_NODE = 'SELECT_BOT_NODE';
export function selectBotNode(value) {
  return {
    payload: value,
    type: SELECT_BOT_NODE,
  }
}

export const UPDATE_BOT_NODE = 'UPDATE_BOT_NODE';
export function updateSelectedBotNode(value) {
  return {
    payload: value,
    type: UPDATE_BOT_NODE,
  }
}


export const SELECT_CAMPAIGN = 'SELECT_CAMPAIGN';
export function selectCampaign(value) {
  return {
    payload: value,
    type: SELECT_CAMPAIGN,
  }
}

export const ADD_HISTORY_EVENT = 'ADD_HISTORY_EVENT';
export function addHistoryEvent(prevEvent, nextEvent) {
  return {
    payload: {prevEvent, nextEvent},
    type: ADD_HISTORY_EVENT,
  }
}

export const UNDO_HISTORY_EVENT = 'UNDO_HISTORY_EVENT';
export function undoHistoryEvent(value) {
  return {
    payload: value,
    type: UNDO_HISTORY_EVENT,
  }
}

export const REDO_HISTORY_EVENT = 'REDO_HISTORY_EVENT';
export function redoHistoryEvent(value) {
  return {
    payload: value,
    type: REDO_HISTORY_EVENT,
  }
}

export const CLEAR_BOT_HISTORY = 'CLEAR_BOT_HISTORY';
export function clearBotHistory(particial = false) {
  return {
    payload: {particial},
    type: CLEAR_BOT_HISTORY,
  }
}

export const CLEAR_CURRENT_CAMPAING = 'CLEAR_CURRENT_CAMPAING';
export function clearCurrentCampaign() {
  return {
    type: CLEAR_CURRENT_CAMPAING,
  }
}

export const CHANGE_COORDINATES = 'CHANGE_COORDINATES';
export function changeCoordinates(value) {
  return {
    payload: value,
    type: CHANGE_COORDINATES,
  }
}