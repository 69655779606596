import React, {Component, useEffect, useState, useRef} from 'react';
import ReactModal from "react-modal";
import moment from "moment-timezone";
import { animateScroll as scroll } from 'react-scroll';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Alert from "react-s-alert";
import update from "immutability-helper";
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import Switch from "components/widgets/Switch";
import CustomSelect from "components/widgets/CustomSelect";
import SubmitButton from "components/widgets/SubmitButton";
import Loader from "components/widgets/Loader";
import Schedule from "components/widgets/Schedule";
import EmailBodyWYSIWYGEditor from './EmailBodyWYSIWYGEditor';
import ModalWarning from "components/widgets/ModalWarning";
import {useMeetingSettings, useMeetings, useSendTestEmail, useContactProperty, useCurrentUser,
  useGoogleAuth} from 'hooks';
import {MEETING_EMAIL_TYPE, TIME_FORMATS} from 'constants.js'
import {t, redirectTo} from 'utils';

export default function Settings(props) {

  const {currentUser} = useCurrentUser();
  const {signOut, isGoogleSignOutProcessing} = useGoogleAuth();
  const {meetingSettings, isMeetingSettingsFetching, updateMeetingSettings,
    isUpdatingMeetingSettings} = useMeetingSettings();
  const {getMeetingSettings} = useMeetings();

  const [isWarningShow, setIsWarningShow] = useState(false);
  const [settings, setSettings] = useState(meetingSettings);
  const [errors, setErrors] = useState({schedule: {}});

  useEffect(() => {
    setSettings(meetingSettings);
  }, [meetingSettings]);

  const onSave = (e) => {
    const errors = {schedule: {}};
    Object.keys(settings.availability.available_schedule.schedule).forEach(day => {
      const intervals = settings.availability.available_schedule.schedule[day].intervals;
      intervals.forEach((interval, i) => {
        if (interval.from >= interval.until) {
          errors.schedule[day] = 'Incorrect Intervals';
          return;
        }
        if (i > 0) {
          if (intervals[i].from < intervals[i - 1].until) {
            errors.schedule[day] = 'Incorrect Intervals';
            return;
          }
        }
      })
    });

    if (Object.keys(errors.schedule).length) {
      setErrors(errors)
      return;
    }

    updateMeetingSettings({
      timezone: settings.availability.available_schedule.timeZone,
      time_format: settings.availability.available_schedule.timeFormat,
      schedule: settings.availability.available_schedule.schedule,
      minimum_notice_time: {
        enabled: settings.availability.minimum_notice_time.enabled,
        amount: settings.availability.minimum_notice_time.value
      },
      buffer_time: {
        enabled: settings.availability.buffer_time.enabled,
        amount: settings.availability.buffer_time.value
      },
      confirmation_email: {
        enabled: settings.email_notifications.confirmation_email.enabled,
        subject: settings.email_notifications.confirmation_email.confirmation_template.subject,
        body: settings.email_notifications.confirmation_email.confirmation_template.body
      },
      reminder_email: {
        enabled: settings.email_notifications.reminder_email.enabled,
        day_notice: {
          subject: settings.email_notifications.reminder_email.remind_day_before_template.subject,
          body: settings.email_notifications.reminder_email.remind_day_before_template.body
        },
        half_hour_notice: {
          subject: settings.email_notifications.reminder_email.remind_half_an_hour_before_template.subject,
          body: settings.email_notifications.reminder_email.remind_half_an_hour_before_template.body
        }
      }
    })
      .then(() => {
        Alert.success('Successful!');
      });
  };

  const onResetSettings = () => {
    getMeetingSettings().then(() => {
      Alert.success('Successful!');
    });
  };

  const handleSettingsChange = (updatedSettings) => {
    if (updatedSettings.availability && updatedSettings.availability.available_schedule &&
          updatedSettings.availability.available_schedule.schedule) {
      setErrors({...errors, schedule: {}});
    }
    setSettings(update(settings, updatedSettings));
  };

  const handleSignOut = () => {
    signOut(currentUser.id).then(() => {
      Alert.success('Successful!');
      redirectTo('/meetings-settings');
      setIsWarningShow(false);
    });
  };

  const _t = (value, values = {}, children = null) => {
    return t(`meetings.settings.${value}`, values, children);
  };

  if (isMeetingSettingsFetching || !settings.user_id) {
    return(<Loader isSmall={true} additionalOverlayClass="meetings-loader-overlay"/>);
  }


  return (
    <div className='pane-content-w'>
      <div className='content-white-block grow-w'>
        <div>
          <div className='group-title'>{_t('availability.title')}</div>
          <div className='page-subheader'>{_t('availability.subtitle')}</div>
        </div>
        <DropdownBlock title={_t('availability.header')} errors={errors}
                       settings={settings} type='availability'
                       onChangeSettings={handleSettingsChange}
                       _t={_t}/>
      </div>

      <div className='content-white-block grow-w'>
        <div>
          <div className='group-title'>{_t('email.title')}</div>
          <div className='page-subheader'>{_t('email.subtitle')}</div>
        </div>
        <DropdownBlock title={_t('email.header')} settings={settings} type='emailNotifications'
                       onChangeSettings={handleSettingsChange} _t={_t}/>
      </div>

      <div className='content-white-block grow-w'>
        <div>
          <div className='group-title'>{_t('connected.title')}</div>
          <div className='page-subheader'>{_t('connected.subtitle')}</div>
        </div>
        <div className='show-group-content'>
          <div className='col-6 full-w'>
            <div className='settings-item-label add-margin'>{_t('connected.currently')}</div>
            <div className='input-row'>
              <input value={currentUser.google_account.email} type="text" readOnly
                     className="settings-select no-indent w-select cursor-text" maxLength="256"/>
              <div className='step-btn add-indent w-inline-block' onClick={() => setIsWarningShow(true)}>
                <div>{_t('connected.disconnect')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='settings-btn-w add-space-2 align-center'>
        <div className='link-2' onClick={onResetSettings}>{_t('button.cancel')}</div>
        <SubmitButton simpleButton={true} additionalClass={'button left-indent w-button'}
                      onClick={onSave} isSending={isUpdatingMeetingSettings} text={_t('button.save')}
                      type={'button'}/>
      </div>
      <ModalWarning
        isOpen={isWarningShow}
        text={'Caution: all meetings data, include meeting links and statistics will be destroyed'}
        onConfirm={() => handleSignOut()}
        onClose={() => setIsWarningShow(false)}
      />
    </div>
  );
};

const DropdownBlock = (props) => {
  const {title, settings, type, onChangeSettings, errors = {}, _t} = props;
  const [isDropdownOpen, openDropdown] = useState(false);
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView()
  }
  const dropdownRef = useRef(null)

  useEffect(() => {
    if (errors.schedule && Object.keys(errors.schedule).length && type === 'availability') {
      openDropdown(true);
      scrollToRef(dropdownRef)
    }
  }, [errors]);


  return (
    <div className="custom-dropdown-w" ref={dropdownRef}>
      <div className="custom-dropdown-toggle" onClick={() => openDropdown(!isDropdownOpen)}>
        <div className="group-w-header">
          <div className="group-title">{title}</div>
          <img src={require('styles/images/down-button.svg')} alt=""
               className={`down-arrow ${isDropdownOpen ? 'dropdown-arrow-up' : 'dropdown-arrow-down'}`}/>
        </div>
      </div>
      <div className={`custom-dropdown-list ${isDropdownOpen ? 'display-settings' : 'hide-settings'}`}>
        {type === 'availability' && <Availability settings={settings.availability}
                                                  errors={errors}
                                                  onChangeSettings={onChangeSettings}
                                                  _t={_t}/>}
        {type === 'emailNotifications' && <EmailNotifications settings={settings.email_notifications}
                                                              onChangeSettings={onChangeSettings}
                                                              _t={_t}/>}
      </div>
    </div>
  );
};

const Availability = (props) => {
  const {settings, errors, onChangeSettings, _t} = props;

  const noticeTimes = {
    15: {value: 15, label: '15minutes'},
    30: {value: 30, label: '30minutes'},
    60: {value: 60, label: '1hour'},
    120: {value: 120, label: '2hour'},
    180: {value: 180, label: '3hour'},
    240: {value: 240, label: '4hour'},
    300: {value: 300, label: '5hour'},
    1440: {value: 1440, label: '1day'},
    //0: {value: 0, label: 'noMinimumNoticeTime'}
  };

  const bufferTimes = {
    //0: {value: 0, label: 'noBufferTime'},
    5: {value: 5, label: '5minutes'},
    10: {value: 10, label: '10minutes'},
    15: {value: 15, label: '15minutes'},
    30: {value: 30, label: '30minutes'},
    60: {value: 60, label: '1hour'}
  };

  const timeZones = moment.tz.names();
  const timeZonesWithOffset = timeZones.reduce((obj, cur) => {
    obj[cur] = {
      value: cur,
      offset: moment.tz(cur)._offset,
      label: `${cur} (GMT${moment.tz(cur).format('Z')})`
    };
    return obj;
  }, {});

  return (
    <>
      <div className='show-group-content no-top-margin'>
        <div className='show-group-div'>
          <div className='group-row'>
            <div className='col-6'>
              <div className='working-days-block'>
                <div className='settings-item-label bottom-margin'>{_t('availability.label')}</div>
                <div className='time-zone-settings'>
                  <CustomSelect options={Object.values(timeZonesWithOffset)}
                                defaultValue={timeZonesWithOffset[settings.available_schedule.timeZone.name]}
                                selectClassName={`settings-select w-select`}
                                getOptionLabel={option => option.label}
                                onChange={tz =>
                                  onChangeSettings({availability: {available_schedule: {timeZone: {$set: {name: tz.value, offset: tz.offset}}}}})}
                                value={timeZonesWithOffset[settings.available_schedule.timeZone.name]}/>
                  <CustomSelect options={Object.values(TIME_FORMATS)}
                                defaultValue={Object.values(TIME_FORMATS)[0]}
                                selectClassName={`settings-select w-select time-format`}
                                getOptionLabel={option => option.label}
                                onChange={format =>
                                  onChangeSettings({availability: {available_schedule: {timeFormat: {$set: format.value}}}})}
                                value={TIME_FORMATS[settings.available_schedule.timeFormat]}/>
                </div>
                <Schedule schedule={settings.available_schedule.schedule}
                          timeFormat={settings.available_schedule.timeFormat}
                          errors={errors.schedule}
                          handleChangeSchedule={data =>
                            onChangeSettings({availability: {available_schedule: {schedule: data}}})}
                          handleAddSchedule={data =>
                            onChangeSettings({availability: {available_schedule: {schedule: data}}})}
                          handleDeleteSchedule={data =>
                            onChangeSettings({availability: {available_schedule: {schedule: data}}})}
                          toggleDayEnabled={data => onChangeSettings({availability: {available_schedule: {schedule: data}}})}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='group-content-w add-padding'>
        <div className='group-row'>
          <div className='col-6'>
            <div className='group-w-header add-margin'>
              <div className='settings-item-label'>{_t('availability.noticeTime.title')}</div>
              <Switch isOn={settings.minimum_notice_time.enabled}
                      onClick={() => onChangeSettings({availability: {minimum_notice_time: {$toggle: ['enabled']}}})}/>
            </div>
            <div className={`show-group-div ${settings.minimum_notice_time.enabled ? 'display-settings' : 'hide-settings'}`}>
              <div className='group-row'>
                <div className='col-12'>
                  <div className='settings-item-label add-margin'>{_t('availability.noticeTime.text')}</div>
                  <CustomSelect options={Object.values(noticeTimes)}
                                defaultValue={Object.values(noticeTimes)[0]}
                                selectClassName={`settings-select narrow-select w-select`}
                                getOptionLabel={option => _t(`time.period.${option.label}`)}
                                onChange={period =>
                                  onChangeSettings({availability: {minimum_notice_time: {value: {$set: period.value}}}})}
                                value={noticeTimes[settings.minimum_notice_time.value]}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='group-content-w add-padding'>
        <div className='group-row'>
          <div className='col-6'>
            <div className='group-w-header add-margin'>
              <div className='settings-item-label'>{_t('availability.bufferTime.title')}</div>
              <Switch isOn={settings.buffer_time.enabled}
                      onClick={() => onChangeSettings({availability: {buffer_time: {$toggle: ['enabled']}}})}/>
            </div>
            <div className={`show-group-div ${settings.buffer_time.enabled ? 'display-settings' : 'hide-settings'}`}>
              <div className='group-row'>
                <div className='col-12'>
                  <div className='settings-item-label add-margin'>{_t('availability.bufferTime.text')}</div>
                  <CustomSelect options={Object.values(bufferTimes)}
                                defaultValue={Object.values(bufferTimes)[0]}
                                selectClassName={`settings-select narrow-select w-select`}
                                getOptionLabel={option => _t(`time.period.${option.label}`)}
                                onChange={period =>
                                  onChangeSettings({availability: {buffer_time: {value: {$set: period.value}}}})}
                                value={noticeTimes[settings.buffer_time.value]}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EmailNotifications = (props) => {
  const {settings, onChangeSettings, _t} = props;
  const [modalVisibility, setModalVisibility] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const {isMeetingSettingsSendingTest, sendTestEmail} = useSendTestEmail();
  const [emailTemplateTitle, setEmailTemplateTitle] = useState(null);

  const showEditTemplateModal = (template, templateTitle) => {
    setModalVisibility(true);
    setSelectedTemplate(template);
    setEmailTemplateTitle(templateTitle);
  };

  const changeEditTemplate = (template) => {
    switch (emailTemplateTitle) {
      case MEETING_EMAIL_TYPE.confirm: {
        onChangeSettings({email_notifications: {confirmation_email: {confirmation_template: {$set: template}}}});
        break;
      }
      case MEETING_EMAIL_TYPE.remindDay: {
        onChangeSettings({email_notifications: {reminder_email: {remind_day_before_template: {$set: template}}}});
        break;
      }
      case MEETING_EMAIL_TYPE.remindHalfHour: {
        onChangeSettings({email_notifications: {reminder_email: {remind_half_an_hour_before_template: {$set: template}}}});
        break;
      }
      default: {
        return;
      }
    }
  };

  return (
    <div className="show-group-content no-top-margin2">
      <div className="group-content-w no-line">
        <div className="group-row">
          <div className="col-6">
            <div className="group-w-header add-margin">
              <div className="settings-item-label">{_t('email.confirm.title')}</div>
              <Switch isOn={settings.confirmation_email.enabled}
                      onClick={() => onChangeSettings({email_notifications: {confirmation_email: {$toggle: ['enabled']}}})}/>
            </div>
            <div className={`show-group-div ${settings.confirmation_email.enabled ? 'display-settings' : 'hide-settings'}`}>
              <div className="group-row vertical">
                <div className="page-subheader add-margin-2">
                  {_t('email.confirm.subtitle')}
                </div>
                <div className="btns-block left-justify">
                  <SubmitButton simpleButton={true} fullSimpleClass="step-btn solid-bg w-inline-block"
                                onClick={() => sendTestEmail(MEETING_EMAIL_TYPE.confirm, settings.confirmation_email.confirmation_template)}
                                isSending={isMeetingSettingsSendingTest[MEETING_EMAIL_TYPE.confirm]}
                                text={_t('email.button.test')}/>
                  <div className="step-btn add-indent w-inline-block"
                       onClick={() => showEditTemplateModal(settings.confirmation_email.confirmation_template, MEETING_EMAIL_TYPE.confirm)}>
                    <div>{_t('email.button.edit')}</div>
                  </div>
                </div>
                <div className="page-subheader add-margin">
                  {_t('email.confirm.test.note')} <a href="#" className="link-2">{settings.test_email}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="group-content-w add-padding">
        <div className="group-row">
          <div className="col-6">
            <div className="group-w-header add-margin">
              <div className="settings-item-label">{_t('email.remind.title')}</div>
              <Switch isOn={settings.reminder_email.enabled}
                      onClick={() => onChangeSettings({email_notifications: {reminder_email: {$toggle: ['enabled']}}})}/>
            </div>
            <div className={`show-group-div ${settings.reminder_email.enabled ? 'display-settings' : 'hide-settings'}`}>
              <div className="group-row vertical-2">
                <div className="settings-item-label add-margin">
                  {_t('email.remind.day.title')}</div>
                <div className="page-subheader add-margin-2">
                  {_t('email.remind.day.subtitle')}
                </div>
                <div className="btns-block left-justify">
                  <SubmitButton simpleButton={true} fullSimpleClass="step-btn solid-bg w-inline-block"
                                onClick={() => sendTestEmail(MEETING_EMAIL_TYPE.remindDay, settings.reminder_email.remind_day_before_template)}
                                isSending={isMeetingSettingsSendingTest[MEETING_EMAIL_TYPE.remindDay]}
                                text={_t('email.button.test')}/>
                  <div className="step-btn add-indent w-inline-block"
                       onClick={() =>
                         showEditTemplateModal(settings.reminder_email.remind_day_before_template, MEETING_EMAIL_TYPE.remindDay)}>
                    <div>{_t('email.button.edit')}</div>
                  </div>
                </div>
              </div>
              <div className="group-row vertical-2">
                <div className="settings-item-label add-margin">
                  {_t('email.remind.halfHour.title')}
                </div>
                <div className="page-subheader add-margin-2">
                  {_t('email.remind.halfHour.subtitle')}
                </div>
                <div className="btns-block left-justify">
                  <SubmitButton simpleButton={true} fullSimpleClass="step-btn solid-bg w-inline-block"
                                onClick={() => sendTestEmail(MEETING_EMAIL_TYPE.remindHalfHour, settings.reminder_email.remind_half_an_hour_before_template)}
                                isSending={isMeetingSettingsSendingTest[MEETING_EMAIL_TYPE.remindHalfHour]}
                                text={_t('email.button.test')}/>
                  <div className="step-btn add-indent w-inline-block"
                       onClick={() =>
                         showEditTemplateModal(settings.reminder_email.remind_half_an_hour_before_template,
                           MEETING_EMAIL_TYPE.remindHalfHour)}>
                    <div>{_t('email.button.edit')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmailTemplateModal isOpen={modalVisibility}
                          template={selectedTemplate}
                          emailTemplateTitle={emailTemplateTitle}
                          isSaving={false}
                          onSave={changeEditTemplate}
                          onClose={() => {
                            setModalVisibility(false);
                            setSelectedTemplate({});
                          }}
                          _t={_t}/>
    </div>
  );
};

const EmailTemplateModal = (props) => {
  const {template, emailTemplateTitle, isOpen, onClose, onSave, isSaving, _t} = props;

  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  useEffect(() => {
    setSubject(template.subject);
    if (template.body) {
      setBody(template.body);
    }
  }, [template]);

  const handleTemplateSave = () => {
    onSave({subject, body});
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Minimal Modal Example"
      ariaHideApp={false}
      className="popup-container-2 max-w-2"
      overlayClassName="popup-w-2 w--open"
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={false}
      onRequestClose={onClose}
    >
      <div className="sign-header">{_t(`email.modal.${emailTemplateTitle}.title`)}</div>
      <div className="w-form">
        <div>
          <div className="row add-margin">
            <div className="label-w">
              <div className="label-div">
                <div className="settings-item-label add-margin">{_t('email.modal.subjectLine.label')}</div>
              </div>
            </div>
            <EmailSubjectEditor value={subject} onChangeValue={(value) => setSubject(value)}/>
          </div>
          <div id="email-body-template" className="row add-margin">
            <div className="label-w">
              <div className="label-div">
                <div className="settings-item-label add-margin">{_t('email.modal.bodyText.label')}</div>
              </div>
            </div>
            <EmailBodyWYSIWYGEditor defaultValue={template.body} value={body} onChangeValue={(value) => setBody(value)}/>

          </div>
          <div className="settings-btn-w add-space-2 align-center">
            <div className="link-2" onClick={onClose}>{_t('email.modal.button.cancel')}</div>
            <SubmitButton simpleButton={true} additionalClass={'button left-indent w-button'}
                          onClick={handleTemplateSave} isSending={isSaving} text={_t('email.modal.button.save')}/>
          </div>
        </div>
      </div>
      <div className="abs-position" onClick={onClose}>
        <div className="delete-btn">
          <div className="close-line-1 rotate"/>
          <div className="close-line-1"/>
        </div>
      </div>
    </ReactModal>
  );
};

const EmailSubjectEditor = (props) => {
  const {value, onChangeValue} = props;
  const [templateVisible, toggleTemplateVisible] = useState(false);
  const [isEmojiShow, showEmojiPicker] = useState(false);
  const textRef = useRef(null);
  const pickerRef = useRef(null);
  const showPickerRef = useRef(null);
  const {contactPropertiesByName} = useContactProperty();

  const addEmoji = e => {
    const emoji = e.native;
    onChangeValue(value.splice(textRef.current.selectionStart, emoji, 0));
    showEmojiPicker(false);
  };

  const addTemplate = property => {
    const template = property.type ? `contact_properties.${property.name}` : property.name;
    onChangeValue(value.splice(textRef.current.selectionStart, `{{${template}}}`, 0));
    toggleTemplateVisible(false);
  };

  return (
    <>
      <div className="edit-tools-w">
        <div className="vert-line"></div>
        <div className="control-btn more-size-2" data-ix="emoji-animate">
          <Picker ref={pickerRef} onSelect={addEmoji} showPreview={false} showSkinTones={false} set="twitter"
                  autoFocus={true}
                  style={{ position: 'absolute', width: '200px', bottom: '-180px', right: '30px', zIndex: 170,
                    display: isEmojiShow ? 'block' : 'none'}}
                  include={['search','recent','people', 'objects', 'symbols']}/>
          <div id="show-emoji-mart"  className="emoji-icon" ref={showPickerRef} onClick={() => showEmojiPicker(!isEmojiShow)}></div>
        </div>
        <div className="vert-line"></div>
        {contactPropertiesByName && Object.values(contactPropertiesByName).length && (
          <div data-delay="0" className="control-btn no-indent w-dropdown" style={{zIndex: 10}}>
            <div className="toggle-control-btn black-icon w-dropdown-toggle" onClick={() => toggleTemplateVisible(!templateVisible)}>
              <div></div>
              <div className="drop-icon hide-icon w-icon-dropdown-toggle"></div>
            </div>
            {templateVisible && (
              <nav className="dropdown-list move-list new-position w-dropdown-list email-template-custom-property w--open">
                {Object.values(contactPropertiesByName).map((property, i) => {
                  return (
                    <div key={i} className="dropdown-link w-dropdown-link" onClick={() => addTemplate(property)}>
                      <span className="awesome-icon fix-w"></span>  {property.name}</div>
                  );
                })}
              </nav>
            )}
          </div>
        )}
      </div>
      <input value={value} ref={textRef} onChange={(e) => onChangeValue(e.target.value)} type="text" maxlength="256"
             className="node-settings-input no-top-rounds w-input"/>
    </>
  )
};