import React, { PureComponent } from 'react';


export default class ErrorBlock extends PureComponent {
  render() {
    const { error, addClass } = this.props;
    if (!error) {
      return null;
    }
    return (
      <div className={`error-message ${addClass}`}>{error}</div>
    );
  }
}
