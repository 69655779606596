import React, { Fragment, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import update from 'immutability-helper';

export default function TablePagination(props) {
  const {itemsLength, pageCount, rowCounts, tableRowCount, onTogglePage, setTableRowCount, pageNumber} = props;
  const [rowCountVisibility, setRowCountVisibility] = useState(false);

  return (
    <div className="pagination-w">
      {(itemsLength > 0) && (pageCount > 1) && (
        <ReactPaginate
          previousLabel={
            <div className="action-btn w-inline-block">&lt;</div>
          }
          nextLabel={
            <div className="action-btn w-inline-block">&gt;</div>
          }
          breakLabel={'...'}
          breakClassName={'page-link'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={1}
          onPageChange={onTogglePage}
          containerClassName={'ul-container pagination-div'}
          pageClassName={'page-link'}
          activeClassName={'active-page'}
          initialPage={pageNumber}
        />
      )}
      <div className="filter-drop pages-number w-dropdown"
           onMouseOver={() => setRowCountVisibility(true)} onMouseOut={() => setRowCountVisibility(false)}>
        <div className="filter-drop-tog gray w-dropdown-toggle">
          <div>{tableRowCount}</div>
          <div className="drop-icon w-icon-dropdown-toggle"></div>
        </div>
        <nav className={`dropdown-list w-dropdown-list ${rowCountVisibility ? 'w--open' : ''}`}>
          {rowCounts.map((count, i) =>
            <div key={i} onClick={() => {
              setTableRowCount(count);
              setRowCountVisibility(false);
            }} className="dropdown-link w-dropdown-link">{count}</div>
          )}
        </nav>
      </div>
    </div>
  )
}