import React, {useState} from 'react';
import {t} from 'utils';
import update from 'immutability-helper';
import DropdownBlock from "components/widgets/DropdownBlock";
import ShareButtons from "components/widgets/ShareButtons";

export default function ShareSettings(props) {
  const {campaign, setCampaign} = props;

  const _t = (value, values = {}, children = null) => {
    return t(`landbot.create.displaySettings.share.${value}`, values, children);
  };

  const settingBlocks = [
    {value: 'Bot Share', label: _t('botshare.header'),
      content: <BotShare campaign={campaign} setCampaign={setCampaign} _t={_t}/>},
    {value: 'Website', label: _t('website.header'),
      content: <WebSite  campaign={campaign} _t={_t}/>}
  ];

  return (
    <div className="w-tab-pane w--tab-active">
      <div className="pane-content-w">
          <div className="div-block-5">
            <div className="content-white-block-2 grow-w">
              {settingBlocks.map((block,i) =>
                <DropdownBlock title={block.label}
                               content={block.content}
                               additionalClass={i === 0 ? 'no-margin-top' : ''}/>
              )}
            </div>
          </div>
      </div>
    </div>
  );
};

const BotShare = (props) => {
  const {campaign, setCampaign, _t} = props;
  const settings = campaign.display_settings.share;

  return (
    <>
      <div className="show-group-content-2-2">
        <div className="show-group-div">
          <div className="group-row">
            <div className="col-6">
              <div className="settings-item-label add-margin">{_t('botshare.share.label')}:</div>
              {_t('botshare.share.placeholder', {},
                msg => <input type="text" value={campaign.url}
                              onChange={e => setCampaign(update(campaign,
                                {display_settings: {share: {share_it_on: {$set: e.target.value}}}}))}
                              className="node-settings-input w-input cursor-text" maxLength="256" placeholder={msg} readOnly={true}/>)}
            </div>
          </div>
          <div className="group-title">{_t('botshare.connect.header')}</div>
          <div className="page-subheader">{_t('botshare.connect.subheader')}.</div>
          <div className="group-content-w-2">
            <ShareButtons url={campaign.url}/>
          </div>
        </div>
      </div>
    </>
  );
};

const WebSite = (props) => {
  const {campaign, _t} = props;
  return (
    <>
      <div className="content-white-block-2">
        <div className="group-title">{_t('website.title')}</div>
        <div className="page-subheader">{_t('website.subtitle')}</div>
        <div className="group-content-w-2 add-padding">
          <a href={campaign.html_url} className="blue-button-2 facebook-btn w-button" download>{_t('website.download.label')}</a>
        </div>
      </div>
    </>
  );
};