import React, { Component } from 'react';
import {CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe} from 'react-stripe-elements';

class PaymentCardData extends Component {

  render() {
    const {_t} = this.props;
    this.props.stripeObjectContainer.stripe = this.props.stripe;

    const inputStyle = {
      lineHeight: 1.428571429,
      color: '#000',
      fontSize: '15px',
      fontWeight: 400,
    };

    // https://stripe.com/docs/stripe-js/reference#the-element-container
    // https://stripe.com/docs/stripe-js/reference#element-options

    return (
      <div className="fields-col-wrap no-indent vert-flex-2">
        <label className="settings-item-label">{_t('modal.card.information.label')}
          {_t('modal.card.number.placeholder', {},
            msg => <CardNumberElement
              classes={{
                base: 'node-settings-input w-input',
              }}
              placeholder={msg}
              style={{
                base: inputStyle,
              }}
            />)}
        </label>
        <div className='div-block-13'>
          <div className='form-group-column-2'>
            <label className="settings-item-label">{_t('modal.expirationDate.label')}
              <CardExpiryElement
                classes={{
                  base: 'settings-select w-select',
                }}
                style={{
                  base: inputStyle,
                }}
              />
            </label>
          </div>
          <div className='form-group-column-2 third-width'>
            <label className="settings-item-label">{_t('modal.cvc.placeholder')}
              <CardCVCElement
                classes={{
                  base: 'node-settings-input w-input',
                }}
                style={{
                  base: inputStyle,
                }}
              />
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default injectStripe(PaymentCardData);