import enMain from './en/main.json';
import enAnalytics from './en/analytics.json';
import enAssistants from './en/assistants.json';
import enAuth from './en/auth.json';
import enAvaibility from './en/avaibility.json';
import enBilling from './en/billing.json';
import enMeetings from './en/meetings.json';
import enCampaigns from './en/campaigns.json';
import enChats from './en/chats.json';
import enLeads from './en/leads.json';
import enLiveVisitors from './en/liveVisitors.json';
import enSettings from './en/settings.json';
import enUserAccount from './en/userAccount.json';

export const main = {
  'en': enMain
};

export const analytics = {
  'en': enAnalytics
};

export const assistants = {
  'en': enAssistants
};

export const meetings = {
  'en': enMeetings
};

export const auth = {
  'en': enAuth
};

export const avaibility = {
  'en': enAvaibility
};

export const billing = {
  'en': enBilling
};

export const campaigns = {
  'en': enCampaigns
};

export const chats = {
  'en': enChats
};

export const leads = {
  'en': enLeads
};

export const liveVisitors = {
  'en': enLiveVisitors
};

export const settings = {
  'en': enSettings
};

export const userAccount = {
  'en': enUserAccount
};