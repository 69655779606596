import React, { Component } from 'react';

export default function Loader(props) {
  const {isSmall, additionalOverlayClass, additionalClass} = props;
  return (
    <div className={`loader-overlay${isSmall ? ' small' : ''} ${additionalOverlayClass ? additionalOverlayClass : ''}`}>
      <div className={`loader ${additionalClass ? additionalClass : ''}`}></div>
    </div>
  );
}
