import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Header from 'components/Header';
import IntlWrapper from 'components/widgets/IntlWrapper';
import SiteSettings from "./SettingsTabs/SiteSettings";
import Managers from "./SettingsTabs/Managers";
import Channels from "./SettingsTabs/Channels";
import QuickReplies from "./SettingsTabs/QuickReplies";
import Notifications from "./SettingsTabs/Notifications";
import InstallCode from "./SettingsTabs/InstallCode";
import WidgetSettings from "./SettingsTabs/WidgetSettings";
import {t} from 'utils';
import {main, settings} from 'translate';
import {useWidgets, useSelectSettingsTab, useOperators, useGetCurrentOperator, useCurrentUserState} from 'hooks';
import Loader from 'components/widgets/Loader';
import Alert from "react-s-alert";


export default function Settings() {
  const tabs = ['siteSettings', 'managers', 'channels', 'notifications', 'quickReplies', 'installCode',
    'widgetSettings'];
  const {selectedTab, setSelectedTab} = useSelectSettingsTab();
  const {widgetsById, currentWidget, currentWidgetId, isFetching, updateWidget, deleteWidget} = useWidgets();
  const {operatorsById, addOperator, deleteOperator, updateOperator, isCreating, isOperatorsFetching} = useOperators();
  const [currentOperator, updateCurrentOperator] = useGetCurrentOperator();
  const {currentUser, updateCurrentUser} = useCurrentUserState();

  const [widget, setWidget] = useState(null);
  const [operator, setOperator] = useState(null);

  useEffect(() => {
    if (currentWidget) {
      setWidget(currentWidget);
    }

    if (currentOperator) {
      setOperator(currentOperator);
    }
  }, [currentWidget, currentOperator]);

  useEffect(() => {
    if (widget && widget.settings && widget.settings.quick_replies &&
      !_.isEqual(widget.settings.quick_replies, currentWidget.settings.quick_replies)) {
      onSave();
    }

  }, [widget]);

  const onSave = (value) => {
    updateWidget(currentWidgetId, value)
      .then(() => {
        Alert.success('Successful!');
      });
  };

  const onSaveOperatorNotificationSettings = (newOperatorData) => {
    updateCurrentOperator(newOperatorData.id, newOperatorData).then(() => {
      Alert.success('Successful!');
    })
  };

  const onDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this widget?')) {
      deleteWidget(id)
        .then(() => {
          Alert.success('Successful!');
        });
    }
  };

  if (isFetching || isOperatorsFetching) {
    return <Loader/>;
  }

  return (
    widget &&
    (
      <IntlWrapper messages={[main, settings]}>
        <Header title={t('title')}/>
        <div className="page-content normal-content">
          <div className="w-form">
            <form>
              <div data-duration-in="300" data-duration-out="100" className="tabs w-tabs">
                <div className="left-float-menu w-tab-menu top-0">
                  {tabs.map((tab) =>
                    <a data-w-tab="Tab 1" key={tab} onClick={() => setSelectedTab(tab)}
                       className={`left-tab-link w-inline-block w-tab-link ${tab === 'widgetSettings' ? 'widget-tab' : ''} ${tab === selectedTab ? 'w--current' : ''}`}>
                      <div>{t(`tabs.${tab}`)}</div>
                    </a>
                  )}
                </div>
                <div className="tabs-content w-tab-content overflow-unset">
                  {selectedTab === 'siteSettings' && <SiteSettings widget={widget} setWidget={setWidget}
                                                                   widgetsById={widgetsById}
                                                                   currentOperator={currentOperator}
                                                                   onSave={onSave} onDelete={onDelete}/>}
                  {selectedTab === 'managers' && <Managers currentOperator={currentOperator}
                                                           operatorsById={operatorsById} addOperator={addOperator}
                                                           deleteOperator={deleteOperator} updateOperator={updateOperator}
                                                           isCreating={isCreating}/>}
                  {selectedTab === 'channels' && <Channels widget={currentWidget} setWidget={setWidget} onSave={onSave}/>}
                  {selectedTab === 'notifications' && <Notifications operator={operator} setOperator={setOperator}
                                                                     currentUser={currentUser} updateCurrentUser={updateCurrentUser}
                                                                     onSaveOperatorNotificationSettings={onSaveOperatorNotificationSettings}/>}
                  {selectedTab === 'quickReplies' && <QuickReplies widget={currentWidget} setWidget={setWidget} onSave={onSave}/>}
                  {selectedTab === 'installCode' && <InstallCode widget={currentWidget}/>}
                  {selectedTab === 'widgetSettings' && <WidgetSettings widget={currentWidget}  setWidget={setWidget} onSave={onSave}/>}
                </div>
              </div>
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </IntlWrapper>
    )
  );
}