import {connect} from 'react-redux';
import {compose, lifecycle, withHandlers} from 'recompose';
import Campaigns from './Campaigns';
import {main, campaigns} from 'translate';

export default compose(
  connect((state, ownProps) => {
      return {
        messages: [main, campaigns]
      };
    }
  ),

  lifecycle({
    componentDidMount() {
      document.title = 'Campaigns | HeyCom'

      console.log('Campaigns componentDidMount')
    },
  }),

  withHandlers()
) (
  Campaigns
);