import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import update from 'immutability-helper';
import moment from 'moment';

import TablePagination from 'components/widgets/TablePagination';
import ModalWarning from 'components/widgets/ModalWarning';
import Loader from 'components/widgets/Loader';
import {useLeads, useLeadsCampaignTypeFilter, useLeadsFilters, useTablePagination,
  useStateWidgets, useDeleteLeads} from 'hooks';
import {t, downloadFile} from 'utils';
import {CAMPAIGN_TYPES} from 'constants.js'


export default function LeadTable(props) {
  const {currentWidgetId} = useStateWidgets();
  const {selectedFilters} = useLeadsFilters();
  const {selectedCampaignType} = useLeadsCampaignTypeFilter();

  const {leadsById, leadsCount, getLeads, exportLeads, isFetching} = useLeads();
  const deleteLeads = useDeleteLeads();

  const tableHeaders = selectedCampaignType.value !== CAMPAIGN_TYPES.facebookMessenger.value ?
    ['leadName', 'leadEmail', 'geolocation', 'lastUpdated', 'options'] :
    ['leadName', 'lastUpdated', 'options'] ;
  const [selectedLeads, setSelectedLeads] = useState([]);

  const {itemsOnPage, pageCount, pageNumber, tableRowCount, rowCounts,
    setPageNumber, setTableRowCount} = useTablePagination(leadsById, leadsCount, getLeads);

  const [isWarningShow, showWarning] = useState(false);
  const isFirstRun = useRef([true, true]);


  useEffect(() => {
    // skip a first call
    if (isFirstRun.current[0]) {
      isFirstRun.current[0] = false;
    } else {
      if (selectedFilters.type === 'all') {
        getLeads({
          offset: 0,
          limit: tableRowCount
        });
      }
    }
    // double request fix
  }, [selectedCampaignType, currentWidgetId]);

  useEffect(() => {
    // skip a first call
    if (isFirstRun.current[1]) {
      isFirstRun.current[1] = false;
      //return;
    } else {
      getLeads({
        send_count: true,
        offset: 0,
        limit: tableRowCount
      });
    }
  }, [selectedFilters]);

  const selectLead = (id = null) => {
    if (selectedLeads.length !== 10) {
      if (id) {
        setSelectedLeads(update(selectedLeads, {
          $push: [id]
        }));
      } else {
        setSelectedLeads(update(selectedLeads, {
          $apply: () => itemsOnPage.map((lead, i) => {
            return lead.id;
          })
        }));
      }
    }
  };

  const removeSelectedLead = (id = null) => {
    if (!id) {
      setSelectedLeads(update(selectedLeads, {
        $set: []
      }));
    } else {
      const ind = selectedLeads.indexOf(id);
      if (ind !== -1) {
        setSelectedLeads(update(selectedLeads, {
          $splice: [[ind, 1]]
        }));
      }
    }
  };

  const togglePage = (page) => {
    setPageNumber(page.selected)
  };

  const handleDeleteLeads = () => {
    deleteLeads(selectedLeads)
      .then(data => {
        if (data && !data.error) {
          removeSelectedLead();
          showWarning(false);
          getLeads({
            send_count: true,
            offset: 0,
            limit: tableRowCount
          });
        }
      });
  };

  const handleExportLeads = () => {
    exportLeads().then(data => {
      downloadFile(data.data, data.fileName, data.mimeType);
    });
  };

  if (isFetching) {
    return <Loader isSmall={true}/>;
  }
  return (
    <div className="content-white-block table-container">
      <div className="table-settings">
        <div className="action-w">
          <div className="details-header right-indent">{selectedLeads.length} {t('table.selected')}</div>
          <div className="action-btn w-inline-block" onClick={handleExportLeads}>
            <div><span className="awesome-icon"></span> {t('table.export')}</div>
          </div>
          {selectedLeads.length ? (
            <div className="action-btn delete-btn w-inline-block" onClick={() => showWarning(true)}>
              <div><span className="awesome-icon"></span> {t('table.delete')}</div>
            </div>
          ) : (
            <div className="action-btn delete-btn w-inline-block">
              <div><span className="awesome-icon"></span> {t('table.delete')}</div>
            </div>
          )}

        </div>
      </div>
      <div className="table-w table-padding">
        <div className="table-row table-header">
          <div className="table-col1 check-col">
            <div className="table-col1 check-col">
              {(!selectedLeads.length || (selectedLeads.length !== itemsOnPage.length)) ? (
                selectedLeads.length !== 10 ? (
                  <div className="check-div" onClick={() => selectLead()}></div>
                ) : (
                  <div className="check-div disabled-check"></div>
                )
              ) :(
                <div className="check-div checked" onClick={() => removeSelectedLead()}>
                  <img src={require('styles/images/tick-sign.svg')} alt=""/>
                </div>
              )}
            </div>
          </div>
          {tableHeaders.map(header =>
            <div key={header} className="table-col1">
              <div className="details-header">{t(`table.header.${header}`)}</div>
            </div>
          )}
        </div>
        <div className="table-content">
          {Object.values(leadsById).length > 0 && itemsOnPage.map((lead, i) =>
            <div key={lead.id} className="table-row">
              <div className="table-col1 check-col">
                {(selectedLeads.indexOf(lead.id) === -1) ? (
                  selectedLeads.length !== 10 ? (
                    <div className="check-div" onClick={() => selectLead(lead.id)}></div>
                  ) : (
                    <div className="check-div disabled-check"></div>
                  )
                ) :(
                  <div className="check-div checked" onClick={() => removeSelectedLead(lead.id)}>
                    <img src={require('styles/images/tick-sign.svg')} alt=""/>
                  </div>
                )}
              </div>
              <div className="table-col1">
                <div className="visitor-info-w">
                  <div className={`chat-avatar-2 ${(i + 1) % 3 === 0 ? 'blocked-chat' : ((i + 1) % 3 === 2 ? 'closed-chat' : '')}`}>
                    <div>S</div>
                  </div>
                  <Link to={Object.values(lead.current_campaign).length &&
                  lead.chat_id ? `/chats/${lead.chat_id}` : `/chats/?client_id=${lead.id}`}
                        className="settings-item-label user-link">{lead.name}</Link></div>
              </div>
              {selectedCampaignType.value !== CAMPAIGN_TYPES.facebookMessenger.value ? (
              <div className="table-col1">
                {selectedCampaignType.value === CAMPAIGN_TYPES.facebookMessenger.value ? (
                  <div className="cp-info">19.08.20</div>
                ) : (
                  <div className="cp-info">{lead.email}</div>
                )}
              </div>
              ) : ''}
              {selectedCampaignType.value !== CAMPAIGN_TYPES.facebookMessenger.value ? (
              <div className="table-col1">
                <div className="cp-info">{
                  lead.city && lead.country ?
                    (lead.city + ', ' + lead.country)
                    : (lead.city || lead.country)
                }</div>
              </div>
              ) : ''}
              <div className="table-col1">
                <div className="cp-info">{lead.updated_by_operator_at ?
                    moment(lead.updated_by_operator_at).format('MMM D, YYYY') :
                  moment(lead.created_at).format('MMM D, YYYY')}{
                  (lead.updated_by_operator) ? (' by ' + lead.updated_by_operator.name) : ''
                }</div>
              </div>
              <div className="table-col1">
                <Link to={Object.values(lead.current_campaign).length &&
                lead.chat_id ? `/chats/${lead.chat_id}` : `/chats/?client_id=${lead.id}`} className="step-btn w-inline-block">
                  <div className="awes-icon"><span className="awes-icon"></span></div>
                  <div>{t('table.viewDetails')}</div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalWarning
        isOpen={isWarningShow}
        text={`Are you sure you want to delete leads? (${selectedLeads.length})`}
        onConfirm={handleDeleteLeads}
        onClose={() => showWarning(false)}
      />
      <div className="table-settings right-float">
        <TablePagination itemsLength={itemsOnPage.length} rowCounts={rowCounts} pageCount={pageCount}
                         tableRowCount={tableRowCount} onTogglePage={togglePage}
                         setTableRowCount={setTableRowCount} pageNumber={pageNumber}/>
      </div>
    </div>
  );
}