import {Form, Formik} from 'formik';
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import {Elements} from 'react-stripe-elements';
import SubmitButton from 'components/widgets/SubmitButton';
import PaymentCardData from "./PaymentCardData";
import { t } from 'utils';
import {isCardNumberValid, isCvcValid} from "../../../utils";
import _ from "lodash";


export default class PaymentCardModal extends Component {
  constructor(props) {
    super(props);

    this.stripeObjectContainer = {};

    this.handleNewCardAdd = this.handleNewCardAdd.bind(this);
  }

  getStripeObject() {
    return this.stripeObjectContainer.stripe;
  }

  handleNewCardAdd() {
    this.props.onSave(this.getStripeObject())
      .then(() => {
        this.props.onClose();
      });
  }

  render() {
    const {isOpen, isLoading, errorMessage, onClose, _t} = this.props;

    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel={'Update billing info'}
        ariaHideApp={false}
        className="popup-container-2 outline-none overflow-unset"
        overlayClassName="popup-w-2 w--open"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
        onRequestClose={onClose}
      >
        <div className="sign-header">{_t('modal.header')}</div>
        <div className="w-form">
          <Formik
            initialValues={{
              number: '',
              expirationYear: '',
              expirationMonth: '',
              cvc: ''
            }}

            onSubmit={(values, actions) => {
              this.handleNewCardAdd();
            }}
            render={props => {
              return (
                <Form>
                  <Elements>
                    <PaymentCardData stripeObjectContainer={this.stripeObjectContainer} _t={_t}/>
                  </Elements>
                  {errorMessage && (
                    <div className="w-form-fail" style={{display: 'block'}}>
                      <div>{errorMessage}</div>
                    </div>
                  )}
                  <SubmitButton simpleButton={true} additionalClass={'right-float add-margin'}
                                isSending={isLoading} text={_t('modal.button')}/>
                </Form>
              );
            }}
          />
        </div>
        <div className="abs-position" onClick={onClose}>
          <div className="delete-btn">
            <div className="close-line-1 rotate"/>
            <div className="close-line-1"/>
          </div>
        </div>
      </ReactModal>
    );
  }
}
