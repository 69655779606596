import React, {Fragment, useState} from 'react';

import WYSIWYGEditor from 'components/widgets/WYSIWYGEditor';
import ActionButtons from './ActionButtons';


export default function SendEmailSettings(props) {
  const {currentNode, onChangeNode, t, onSave, onCancel} = props;

  const handleNodeChange = (data, field) => {
    onChangeNode(currentNode.content[field] ?
      {content: {[field]: {$set: data}}} : {content: {$merge: {[field]: data}}})
  };
  const save = () => {
    onSave();
  };

  return (
    <Fragment>
      <div className="node-settings">
        <div className="settings-item">
          <div className="settings-item-label add-margin">{t('subject.label')}</div>
          {t('subject.placeholder', {},
            msg => <input type="text" className="node-settings-input w-input"
                          value={currentNode.content.subject || ''} onChange={e => handleNodeChange(e.target.value, 'subject')}
                          maxLength="256" placeholder={msg} required=""/>)}
        </div>
        <div className="settings-item">
          <div className="settings-item-label add-margin">{t('message.label')}</div>
          <WYSIWYGEditor value={currentNode.content.message || ''}
                         onChangeValue={text => {
                           handleNodeChange(text, 'message')
                         }}
                         placeholderField="web.create.botBuilder.send_email.message.placeholder"/>
        </div>
      </div>
      <ActionButtons onSave={save} onCancel={onCancel}/>
    </Fragment>
  );
}

//SendEmailSettings