import React, {useState} from 'react';
import {CAMPAIGN_REQUEST_TYPES} from 'constants.js';
import CampaignSettings from '../CampaignSettings';


export default function CreateFacebookMessengerCampaign(props) {
  const newCampaign = {
    name: '',
    //welcome_message: {},
    display_settings: {
      button_display: {
        is_display_on_specific_page: false,
        urls: [
          {option: 'is', link: ''},
        ]
      },
      triggers: {
        is_trigger_by_click_active: false,

        is_exit_intent_active: false,
        is_timed_trigger_active: false,
        timed_trigger_seconds_value: 1,
        is_after_scrolling_active: false,
        after_scrolling_percents_value: 1,
        is_time_of_inactivity_active: false,
        time_of_inactivity_seconds_value: 1,

        is_periodicity_active: false,
        popup_will_appear_maximum_number_value: 2,
        popup_time_between_appearances_value: 1,
        popup_time_between_appearances_unit: 'second',

        is_not_respond_conversation_active: false,
        without_responding_time_value: 5,
        without_responding_time_unit: 'second',
        is_start_chatbot_active: false,
        is_not_take_conversation_active: false,
        without_taking_time_value: 5,
        without_taking_time_unit: 'second',
      },
      conditions: {
        is_returning_new_visitor_active: false,
        returning_new_visitor_filter: 'all',

        is_display_on_devices_active: false,
        display_on_devices_filter: 'all',

        is_utm_based_display_active: false,
        utm_based_display_source_match: 'contains',
        utm_based_display_source_value: '',
        utm_based_display_medium_match: 'contains',
        utm_based_display_medium_value: '',
        utm_based_display_campaign_match: 'contains',
        utm_based_display_campaign_value: '',

        is_based_on_cookie_active: false,
        based_on_cookie_cookies: [
          {option: 'is', name: ''},
        ],

        is_from_referrals_active: false,
        from_referrals_referrers: [
          {option: 'is', name: ''},
        ],

        is_geolocation_active: false,
        geolocation_countries: [
          {option: 'is', name: ''},
        ],
      },
    },
    //bot_type: {},
    //bot_builder: {}
  };

  const additionalForLiveChat = {
    welcome_message: {
      text: '',
      heading_text: {
        title: '',
        subtitle: ''
      },
      auto_assign_conversations: false,
      active_operator_ids: [],
      ask_email_option: 'immediately',
      ask_email_message: '',
    }
  };

  return (
    <CampaignSettings isCreate={true} campaignType={'facebook_messenger'}
                      newCampaign={{...newCampaign, bot_type: {}, bot_builder: {}}}/>
  );
}