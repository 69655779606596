import React, { useState } from 'react';

import {useLeadsCampaignTypeFilter} from 'hooks';
import Header from 'components/Header';
import IntlWrapper from 'components/widgets/IntlWrapper';
import Filters from './Filters';
import LeadTable from './LeadTable';
import {t} from 'utils';
import {main, leads} from 'translate';
import {CAMPAIGN_TYPES} from 'constants.js'


export default function Leads(props) {
  const {selectedCampaignType, setSelectedCampaignType} = useLeadsCampaignTypeFilter();

  return (
    <IntlWrapper messages={[main, leads]}>
      <Header title={t('title')}/>
      <div className="page-content normal-content">
        <div className="w-form">
          <form>
            <div data-duration-in="300" data-duration-out="100" className="a-tabs w-tabs">
              <div className="a-tabs-menu w-tab-menu">
                {Object.values(CAMPAIGN_TYPES).map(tab =>
                  <a key={tab.value} onClick={() => setSelectedCampaignType(tab)}
                     className={`a-tab-link w-inline-block w-tab-link tab-margin ${selectedCampaignType.value === tab.value ? 'w--current' : ''}`}>
                    <div>{t(tab.label)}</div>
                  </a>
                )}
              </div>
              <div className="a-tabs-content w-tab-content">
                <div className="w-tab-pane w--tab-active">
                  <div className="campaigns-preview-w">
                    <Filters/>
                    <LeadTable/>
                  </div>
                </div>
                <div data-w-tab="Tab 2" className="w-tab-pane"></div>
                <div data-w-tab="Tab 3" className="w-tab-pane"></div>
              </div>
            </div>
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </IntlWrapper>
  );
}