import React, { Component } from 'react';
import Select, { components } from 'react-select';

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props} >
      <div className="select-arrow-down"></div>
    </components.DropdownIndicator>
  );
};
const Menu = props => {
  return (
    <div className="dropdown-list w-dropdown-list nav-list">
      <components.Menu {...props}>
        {props.children}
      </components.Menu>
    </div>
  );
};

const SelectContainer = props => {
  return (
    <div className="filter-drop w-dropdown">
      {props.children}
    </div>
  )
};

const Control = props => {
  return (
    <div className="filter-drop-tog w-dropdown-toggle">
      {props.children}
    </div>
  )
};

const Option = props => {
  const {innerProps, ...otherProps} = props;
  const { onMouseOver, ...otherInnerProps} = innerProps;
  const newProps = {innerProps: {...otherInnerProps}, ...otherProps};
  return (
    <div {...newProps} className="dropdown-link w-dropdown-link" onMouseOver={onMouseOver}>
      {props.children}
    </div>
  )
};

const IndicatorsContainer = props => {
  return (
    <div style={{
      padding: 0,
      margin: '3px 0px 3px 0px'
    }}>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

export default class CustomSelect extends Component {
  static _getStyleSettings = ({noControlPadding, controlPadding, containerMinHeight, menuMaxHeight}) => ({
    container: (base, data) => {
      return {
        ...base,
        padding: 0,
        outline: 0,
        minWidth: containerMinHeight || '112px',
        border: data.isFocused ? '1px solid #3898EC' : '1px solid #ccc'
      }},
    control: () => ({
      display: 'flex',
      height: 'inherit',
      padding: noControlPadding ? 0 : controlPadding || '0 12px'
    }),
    menu: base => ({
      ...base,
      borderRadius: 0,
      backgroundColor: '#f2f6f9',
      border: '1px solid #839adc',
      marginTop: '1px',
      zIndex: '11',
      boxShadow: 'none',
      width: '100.3%'
    }),
    menuList: base => ({
      ...base,
      maxHeight: menuMaxHeight || '300px',
      padding: 0
    }),
    indicatorSeparator: base => ({
      display: 'none'
    }),
    dropdownIndicator: base => ({
      position: 'absolute',
      right: '6px',
      height: 'inherit'
    }),
    valueContainer: base => ({
      ...base,
      flexWrap: 'nowrap',
      padding: 0
    }),
    option: base => ({
      ...base,
      fontWeight: 'normal',
      padding: '1px 12px',
      ':active': {
        ...base[':active'],
        backgroundColor: '#4d90fe',
        color: 'hsl(0,0%,100%)'
      },
      ':focus': {
        ...base[':focus'],
        outline: 'none'
      }
    })
  });

  static _defaultCustomComponents = {
    DropdownIndicator, IndicatorSeparator: null,
  };

  render() {
    const {options, defaultValue, noControlPadding,
      controlPadding = null, containerMinHeight = null, selectClassName, customComponents, menuIsOpen, placeholder,
      getOptionValue, getOptionLabel, onChange, value} = this.props;
    return (
      <Select closeMenuOnSelect={true}
              placeholder={placeholder || ''}
              options={options}
              menuIsOpen={menuIsOpen}
              isSearchable={false}
              components={customComponents ? customComponents : CustomSelect._defaultCustomComponents}
              className={selectClassName}
              onChange={object => onChange(object)}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              defaultValue={defaultValue}
              value={value}
              styles={CustomSelect._getStyleSettings({noControlPadding, controlPadding, containerMinHeight})}
      />
    );
  }
}