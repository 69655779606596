import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch, shallowEqual } from 'react-redux';

import client from 'api/client';
import {scrollToBottom, scrollToElement, getErrorText} from 'utils';
import {CHAT_MESSAGES_LIMIT, PAGINATION_OFFSET_TYPE} from 'constants.js';


export const useAuthRequests = () => {
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = (url, data, headers, callback = null) => {
    setIsFetching(true);
    client.post(url, data, headers)
      .then(data => {
        setError(null);
        setIsFetching(false);
        setIsSuccess(true);
        if (callback) {
          callback(data);
        }
      })
      .catch(error => {
        setIsFetching(false);
        setIsSuccess(false);
        setError(getErrorText(error.message, error));
      });
  };

  return {error, isFetching, isSuccess, setError, onSubmit: handleSubmit};
};

export const useTablePagination = (itemsById, itemsCount, getItems) => {
  //console.log(itemsById, itemsCount, getItems)
  const rowCounts = [5, 25, 50, 75];
  const [tableRowCount, setTableRowCount] = useState(rowCounts[0]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [itemsOnPage, setItemsOnPage] = useState([]);

  const isFirstRun = useRef([true, true]);

  useEffect(() => {
    if (Array.isArray(itemsById)) {
      setItemsOnPage(itemsById);
    } else {
      setItemsOnPage(Object.values(itemsById));
    }
  }, [itemsById]);

  useEffect(() => {
    if (itemsCount > 0) {
      setPageCount(Math.ceil(itemsCount / tableRowCount));
    }
  }, [itemsCount]);

  useEffect(() => {
    // skip a first call
    if (isFirstRun.current[0]) {
      isFirstRun.current[0] = false;
    } else {
      if (itemsCount > 0) {
        setPageCount(Math.ceil(itemsCount / tableRowCount));
        // double request fix
        if (pageNumber * tableRowCount <= itemsCount) {
          getItems({
            offset: pageNumber * tableRowCount,
            limit: tableRowCount
          });
        }
      }
    }
  }, [tableRowCount]);

  useEffect(() => {
    // skip a first call
    if (isFirstRun.current[1]) {
      isFirstRun.current[1] = false;
      //return;
    } else {
      getItems({
        offset: pageNumber * tableRowCount,
        limit: tableRowCount
      });
    }
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber * tableRowCount > itemsCount) {
      setPageNumber(pageCount - 1);
    }
  }, [pageCount]);

  return {itemsOnPage, pageCount, pageNumber, tableRowCount, rowCounts,
    setPageNumber, setTableRowCount}
};

export const useScrollPagination = ({selectedSearchResult, chat, selectedChatId, selectedChat, searchMode,
getChatMessages, onResetSelectSearchResult, setChat}) => {
  const stateChatId = useSelector(state => state.chats.selectedChatId, shallowEqual);
  const [hasMorePrevMessage, setHasMorePrevMessage] = useState(false); //
  const [hasMoreNextMessage, setHasMoreNextMessage] = useState(false); //
  const [completedFirstLoad, setCompletedFirstLoad] = useState(undefined);
  const [isLoadingMoreMessage, setLoadingMoreMessage] = useState(false); //
  const [localSearchResult, setLocalSearchResult] = useState(null);
  const [firstMessage, setFirstMessage] = useState(null);
  const [lastMessage, setLastMessage] = useState(null);
  const [paginationDirection, setPaginationDirection] = useState(PAGINATION_OFFSET_TYPE.prev);

  const [scrollToSearchResult, setScrollToSearchResult] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    //console.log(localSearchResult, selectedSearchResult)
    if (selectedSearchResult && localSearchResult !== selectedSearchResult) {
      //цепочка если для предотвращения лишних запросов, из-за зависимости от айди чата
      if (selectedChatId !== selectedChat.id) {
      //   console.log('selectedChatId !== chat.id', selectedChatId, selectedChat.id);
      //   getChatMessages({
      //     chat_id: selectedChatId,
      //     offset: selectedSearchResult,
      //     limit: '20,20'
      //   }, PAGINATION_OFFSET_TYPE.middle)
      //     .then(() => onResetSelectSearchResult());
      //   setChat({
      //     ...chat,
      //     messages: []
      //   });
      } else {
        if (localSearchResult !== selectedSearchResult) {
          //console.log('localSearchResult === selectedSearchResult', localSearchResult, selectedSearchResult)
          getChatMessages({
            chat_id: stateChatId,
            offset: selectedSearchResult,
            limit: '20,20'
          }, PAGINATION_OFFSET_TYPE.middle)
            .then(() => onResetSelectSearchResult());
          setChat({
            ...chat,
            messages: []
          });
        }
      }

      setHasMoreNextMessage(true);
      setLocalSearchResult(selectedSearchResult)
    }
  }, [selectedSearchResult, localSearchResult]);

  useEffect(() => {
    if (!searchMode && localSearchResult) {
      setLocalSearchResult(null);
    }
  }, [searchMode]);

  useEffect(() => {
    if (chat && selectedChat.messages && chat.messages && selectedChat.messages.length) {
      if (!chat.messages.length) {
        setHasMoreNextMessage(true);
        setHasMorePrevMessage(true);
        setFirstMessage(null);
        setLastMessage(null);
      } else {
        if (selectedChat.messages[0].id !== chat.messages[0].id && !selectedSearchResult) {
          setHasMorePrevMessage(selectedChat.messages.length < selectedChat.messageCount);
          setHasMoreNextMessage(true);
          //setFirstMessage(chat.messages[0]);
        }

        if (selectedChat.messages[selectedChat.messages.length - 1].id !== chat.messages[chat.messages.length - 1].id && !selectedSearchResult) {
          setHasMoreNextMessage(selectedChat.messages.length < selectedChat.messageCount);
          setHasMorePrevMessage(true);
          //setLastMessage(chat.messages[chat.messages.length - 1]);
        }

        if ((selectedChat.messages[selectedChat.messages.length - 1].id === chat.messages[chat.messages.length - 1].id) &&
          (selectedChat.messages[0].id === chat.messages[0].id)) {
          setHasMorePrevMessage(paginationDirection === PAGINATION_OFFSET_TYPE.next && (selectedChat.messages.length < selectedChat.messageCount));
          setHasMoreNextMessage(paginationDirection === PAGINATION_OFFSET_TYPE.prev && (selectedChat.messages.length < selectedChat.messageCount));
        }
      }
    }
  }, [selectedChat.messages]);

  useEffect(() => {
    setCompletedFirstLoad(false);
    setHasMoreNextMessage(true);
    setHasMorePrevMessage(true);
    setFirstMessage(null);
    setLastMessage(null);
  }, [selectedChatId]);

  useEffect(() => {
    if (document.getElementById('chat-window')) {
      if (isLoadingMoreMessage) {
        setScrollTop(document.getElementById('chat-window').scrollTop);
        setScrollHeight(document.getElementById('chat-window').scrollHeight);
      } else {
        const newscrollHeight = document.getElementById('chat-window').scrollHeight;
        if (chat.id && chat.messages && chat.messages.length < chat.unread_messages_by_user) {
          document.getElementById('chat-window').scrollTo(0, paginationDirection === PAGINATION_OFFSET_TYPE.prev ? newscrollHeight - scrollHeight : scrollTop);
        }
      }
    }
  }, [isLoadingMoreMessage]);

  useEffect(() => {
    if (chat.id) {
      if ((localSearchResult && !lastMessage && !firstMessage) || (localSearchResult && scrollToSearchResult)) {
        scrollToElement(localSearchResult);
        // fix bug with search
        setScrollToSearchResult(false);
      }
      if (!isLoadingMoreMessage) {
        if (!localSearchResult) {
          if (chat.unread_messages_by_user_count) {
            scrollToElement('unread-messages-divider');
          } else {
            scrollToBottom();
          }
        }
      } else {
        setLoadingMoreMessage(false);
        if (!localSearchResult) {
          if (lastMessage || firstMessage) {
            if (firstMessage && chat.unread_messages_by_user_count) {
              if (chat.unread_messages_by_user < firstMessage.created_at) {
                scrollToElement('unread-messages-divider');
              } else {
                scrollToElement(paginationDirection === PAGINATION_OFFSET_TYPE.prev ? firstMessage.id : lastMessage.id);
              }
            } else {
              scrollToElement(paginationDirection === PAGINATION_OFFSET_TYPE.prev ? firstMessage.id : lastMessage.id);
            }
          } else {
            if (chat.unread_messages_by_user_count) {
              scrollToElement('unread-messages-divider');
            }
          }
        }
      }
      if (!completedFirstLoad && selectedChat.messages) {
        //console.log(selectedChat)
        //console.log('selectedChat.messages.length >= CHAT_MESSAGES_LIMIT', selectedChat.messages.length, CHAT_MESSAGES_LIMIT);
        setHasMorePrevMessage(selectedChat.messages.length < selectedChat.messageCount);
        setCompletedFirstLoad(true);
      }

      if (chat.messages) {
        setFirstMessage(chat.messages[0]);
        setLastMessage(chat.messages[chat.messages.length - 1]);
        // fix bug with search
        if (localSearchResult) {
          setScrollToSearchResult(true);
        }
      }
    }
  }, [chat.messages]);

  const handleMessagesLoad = (offsetDirection = -1) => {
    setLoadingMoreMessage(true);
    if (offsetDirection > 0) {
      getChatMessages({
          chat_id: selectedChatId,
          offset: lastMessage ? lastMessage.id : localSearchResult,
          limit: `1,${CHAT_MESSAGES_LIMIT}`
        }, PAGINATION_OFFSET_TYPE.next
      );
      setPaginationDirection(PAGINATION_OFFSET_TYPE.next);
    } else {
      getChatMessages({
          chat_id: selectedChatId,
          limit: localSearchResult ? `${CHAT_MESSAGES_LIMIT},1` : CHAT_MESSAGES_LIMIT,
          offset: localSearchResult ? ( firstMessage ? firstMessage.id : localSearchResult) : offsetDirection * chat.messages.length + offsetDirection * CHAT_MESSAGES_LIMIT,
        }, PAGINATION_OFFSET_TYPE.prev
      );
      setPaginationDirection(PAGINATION_OFFSET_TYPE.prev);
    }
  };

  return {hasMorePrevMessage, hasMoreNextMessage, isLoadingMoreMessage, localSearchResult, handleMessagesLoad}
};


export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value]
  );

  return debouncedValue;
};