import React, {useEffect, useRef, useState} from 'react';

import moment from "moment";
import SubmitButton from 'components/widgets/SubmitButton';
import TablePagination from 'components/widgets/TablePagination';
import {useTablePagination} from 'hooks';
import Loader from "components/widgets/Loader";
import {t} from 'utils';
import {usePaymentHistory, usePayments} from "hooks";
import {PaymentStatus} from "constants.js";
import {Link} from "react-router-dom";
import Alert from "react-s-alert";
import ModalWarning from "components/widgets/ModalWarning";


export default function PaymentHistoryTable(props) {

  const {onPay, onSort} = props;
  const {paymentHistoryById, paymentHistory, paymentHistoryCount, getPaymentHistory,
    isFetching, isPaymentCheckingout, isPaymentDeleting} = usePaymentHistory();
  const {deletePayment} = usePayments();
  const tableHeaders = [
    {label: 'id', value: 'stripe_invoice_id'},
    {label: 'plan', value: 'subscription.plan_name'},
    {label: 'months', value: 'subscription.months_count'},
    {label: 'amount', value: 'subscription.amount'},
    {label: 'status', value: 'status'},
    {label: 'paymentDate', value: 'paid_at'},
    {label: 'options', value: null},
  ];
  const {itemsOnPage, pageCount, pageNumber, tableRowCount, rowCounts,
    setPageNumber, setTableRowCount} = useTablePagination(paymentHistory, paymentHistoryCount, getPaymentHistory);
  const [isWarningShow, setIsWarningShow] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [ordering, setOrdering] = useState('-created_at');

  useEffect(() => {
      getPaymentHistory({
        offset: 0,
        limit: tableRowCount,
        sort: ordering
      });
  }, []);

  const handleDeletePayment = (paymentId) => {
    deletePayment(paymentId).then(data => {
      if (data && !data.error) {
        Alert.success('Successful!');
      }
      setIsWarningShow(false);
    });
  };

  const handlePayForPlan = (paymentId) => {
    onPay(paymentId, {sort: ordering, limit: tableRowCount, offset: tableRowCount * pageNumber});
  };

  const handleSort = (selectedOrdering) => {
    let newOrdering = '';
    if (ordering.includes(selectedOrdering)) {
      if (ordering.includes('-')) {
        newOrdering = selectedOrdering;
      } else {
        newOrdering = `-${selectedOrdering}`;
      }
    } else {
      newOrdering = selectedOrdering;
    }
    setOrdering(newOrdering);
    getPaymentHistory({
      offset: tableRowCount * pageNumber,
      limit: tableRowCount,
      sort: newOrdering
    });
  };

  const togglePage = page => {
    setPageNumber(page.selected)
  };


  if (isFetching) {
    return <Loader isSmall={true}/>;
  }


  return (
    <div className="content-white-block table-container">
      <div className="table-w table-padding">
        <div className="table-row table-header">
          {tableHeaders.map(header =>
            <div key={header} className="table-col1">
              <div className="details-header">{t(`billings.table.header.${header.label}`)}</div>
              {header.value && (
                <div className="sorting arrows-block" onClick={() => handleSort(header.value)}>
                  <div className={`arrow-up ${(ordering.includes(header.value) && (!(ordering.includes('-')))) ? 'active' : ''}`}></div>
                  <div className={`arrow-down ${(ordering.includes(header.value) && ordering.includes('-')) ? 'active' : ''}`}></div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="table-content">
          {paymentHistory.length > 0 && itemsOnPage.map((payment, i) =>
            <div key={payment.id} className='table-row'>
              <div className='table-col1'>
                <div className='cp-info'>{payment.stripe_invoice_id}</div>
              </div>
              <div className='table-col1'>
                <div className='cp-info'><strong>{payment.subscription.plan_name}</strong></div>
              </div>
              <div className='table-col1'>
                <div className='cp-info'>{payment.subscription.months_count}</div>
              </div>
              <div className='table-col1'>
                <div className='cp-info'>{payment.subscription.amount / 100}</div>
              </div>
              <div className='table-col1'>
                <div className={`badge ${payment.status === PaymentStatus.Prepared ? 'badge-warning'
                  : (payment.status === PaymentStatus.Cancelled ? 'badge-danger' : 'badge-success')}`}>
                  {payment.status}
                </div>
              </div>
              <div className='table-col1'>
                <div className='cp-info'>
                  {payment.paid_at ? moment(payment.paid_at).format("DD MMM YYYY, HH:mm:ss") :
                    t('billings.payment.not.paid')}
                </div>
              </div>
              <div className='table-col1'>
                {payment.status === PaymentStatus.Prepared ?
                  <>
                    <SubmitButton simpleButton={true} fullSimpleClass={'action-btn right-indent w-inline-block white-background'}
                                  onClick={() => handlePayForPlan(payment.id)}
                                  isSending={isPaymentCheckingout}
                                  spinnerClassName={'dark-spinner'}
                                  text={t(`billings.pay`, {}, (msg) =>
                                    <div>
                                      <span className='awesome-icon'></span> {msg}
                                    </div>)}/>
                    <SubmitButton simpleButton={true} fullSimpleClass={'action-btn  w-inline-block white-background'}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsWarningShow(true);
                                    setSelectedPaymentId(payment.id)
                                  }}
                                  isSending={isPaymentDeleting}
                                  spinnerClassName={'dark-spinner'}
                                  text={t(`billings.delete`, {}, (msg) =>
                                    <div>
                                      <span className='awesome-icon'></span> {msg}
                                    </div>)}/>
                  </>
                  :
                  <div className='cp-info'>no actions</div>
                }
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="table-settings right-float">
        <TablePagination itemsLength={itemsOnPage.length} rowCounts={rowCounts} pageCount={pageCount}
                         tableRowCount={tableRowCount} onTogglePage={togglePage}
                         setTableRowCount={setTableRowCount} pageNumber={pageNumber}/>
      </div>
      <ModalWarning
        isOpen={isWarningShow}
        text={'Are you sure you want to delete this payment?'}
        onConfirm={() => handleDeletePayment(selectedPaymentId)}
        onClose={() => setIsWarningShow(false)}
      />
    </div>
  );
}