import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import { CSSTransition } from 'react-transition-group';

import SubmitButton from 'components/widgets/SubmitButton';
import AuthPageContainer from 'components/widgets/AuthPageContainer';
import {useAuthRequests} from 'hooks';
import {
  t,
  redirectTo,
  setAccessToken,
  getFormError,
  withCurrentQueryArgs,
  isUrlValid
} from 'utils';
import settings from 'settings';


export default function SignUp(props) {
  const {error, isFetching, isSuccess, setError, onSubmit} = useAuthRequests();
  const [email, setEmail] = useState(queryString.parse(window.location.search).email || '');
  const [password, setPassword] = useState('');
  const [siteUrl, setSiteUrl] = useState('');
  const [isAgree, setIsAgree] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(true);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formError = getFormError(email, password);
    if (formError) {
      setError(formError);
      return;
    }
    if (!isUrlValid(`http://${siteUrl}`)) {
      setError(t('error.url'));
      return;
    }
    const data = { email, password, site_url: siteUrl };
    const headers = { headers: {} };
    onSubmit('/auth/signup', data, headers, (data) => {
      setTimeout(() => {
        setAccessToken(data.access_token);
        redirectTo(queryString.parse(window.location.search).next || '/');
      }, 3000);
    });
  };

  return (
    <AuthPageContainer>
      <CSSTransition
        in={pageLoading}
        timeout={300}
        classNames="register-nav-w-animation"
        unmountOnExit
      >
        <div className="register-nav-w">
          <div className="register-nav-container">
            <a href={`${settings.LANDING_URL}/`} className="nav-logo w-nav-brand">
              <img src={require('styles/images/logo-heycom.svg')} alt="" className="sign-logo-img"/>
            </a>
            <div className="register-nav-block">
              <div className="register-nav-text">{t('signUp.login.label')}</div>
              <Link to={withCurrentQueryArgs('/signin', 'next', 'lang')} className="sign-nav-link">
                {t('signUp.login.link')}
              </Link>
            </div>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={pageLoading}
        timeout={300}
        classNames="register-wrapper-animation"
        unmountOnExit
      >
        <div className="register-wrapper">
          <div className="register-col-1">
            <div className="sign-info-header">Get a new level of communications</div>
            <div className="sign-info-block">
              <div className="sign-info-div">
                <img src={require('styles/images/view-filled.svg')} loading="lazy" alt="" className="sign-info-icon"/>
                <div>Chat with your website visitors in real-time.</div>
              </div>
              <div className="sign-info-div">
                <img src={require('styles/images/astronaut-helmet-filled.svg')} loading="lazy" alt=""
                                                  className="sign-info-icon" />
                <div>The most intuitive no code chatbot builder.</div>
              </div>
              <div className="sign-info-div">
                <img src={require('styles/images/target-filled.svg')} loading="lazy" alt=""
                                                  className="sign-info-icon" />
                <div>Chatbot as landing page.</div>
              </div>
              <div className="sign-info-div">
                <img src={require('styles/images/calendar-filled.svg')} loading="lazy" alt=""
                                                  className="sign-info-icon"/>
                <div>Schedule meetings instantly.</div>
              </div>
              <div className="sign-info-div">
                <img src={require('styles/images/messenger-filled.svg')} loading="lazy" alt=""
                                                  className="sign-info-icon" />
                <div>Chatbot for Messenger without coding.</div>
              </div>
              <div className="sign-info-div">
                <img src={require('styles/images/comment-filled.svg')} loading="lazy" alt=""
                                                  className="sign-info-icon" />
                <div>Chat with your customers in the channels they prefer.</div>
              </div>
            </div>
          </div>
          <div className="register-col-2">
            <div className="sign-heading-div">
              <div className="sign-heading">Create your free Heycom account</div>
            </div>
            <div className="form-block-4 w-form">
              <form className="register-form" onSubmit={handleSubmit}>
                <div className="google-signup-w">
                  <a href="#" className="google-btn w-inline-block">
                    <img src={require('styles/images/google.svg')} alt="" className="google-icon"/>
                    <div>Sign up with Google</div>
                  </a>
                  <div className="or-wrap">
                    <div className="grey-line"></div>
                    <div>or</div>
                    <div className="grey-line"></div>
                  </div>
                </div>
                {t('email.placeholder', {},
                  msg => <input type="email" maxLength="256" placeholder={msg} required="" value={email}
                                className="text-field add-indent w-input"
                                onChange={e => setEmail(e.target.value)}/>)}
                {t('password.placeholder', {},
                  msg => <input type="password" className="text-field add-indent w-input" maxLength="256" placeholder={msg} required=""
                                value={password} onChange={e => setPassword(e.target.value)}/>)}
                {t('website.placeholder', {},
                  msg => <input type="text" className="text-field add-indent w-input" maxLength="256" placeholder={msg} required=""
                                value={siteUrl} onChange={e => setSiteUrl(e.target.value)}/>)}
                <div>
                  <div className="log-in-text-3">By signing up, you agree to the conditions of the
                    &nbsp;<a href={`${settings.LANDING_URL}/terms-of-use`} target="_blank" className="sign-link-3">User Agreement</a>
                  </div>
                </div>
                <SubmitButton simpleButton={true} additionalClass={'full-w'}
                              isSending={isFetching} text={t('signUp.button')}/>
              </form>
              {isSuccess && (
                <div className="w-form-done display-none">
                  <div>Thank you! Your submission has been received!</div>
                </div>
              )}
              {error && (
                <div className="w-form-fail error-message-2 display-block">
                  <div>{error}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </AuthPageContainer>
  );
}