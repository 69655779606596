import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import TablePagination from 'components/widgets/TablePagination';
import {
  useLiveVisitors,
  useLiveVisitorsCampaignTypeFilter,
  useStateWidgets,
  useTablePagination
} from 'hooks';
import {t} from 'utils';
import Loader from "components/widgets/Loader";


momentDurationFormatSetup(moment);

export default function LiveVisitorTable(props) {
  const {currentWidgetId} = useStateWidgets();
  const {selectedCampaignType} = useLiveVisitorsCampaignTypeFilter();
  const {liveVisitorsById, liveVisitorsCount, getLiveVisitors, isFetching} = useLiveVisitors();

  const tableHeaders = ['name', 'liveOnSite', 'currentPage', 'currentCampaign', 'options'];

  const {itemsOnPage, pageCount, pageNumber, tableRowCount, rowCounts,
    setPageNumber, setTableRowCount} = useTablePagination(liveVisitorsById, liveVisitorsCount, getLiveVisitors);


  useEffect(() => {
    getLiveVisitors({
      offset: 0,
      limit: tableRowCount
    });
  }, [selectedCampaignType, currentWidgetId]);


  const togglePage = (page) => {
    setPageNumber(page.selected)
  };

  if (isFetching) {
    return <Loader isSmall={true}/>
  }

  return (
    <div className="content-white-block table-container">
      <div className="table-w">
        <div className="table-row table-header">
          {tableHeaders.map(header =>
            <div key={header} className="table-col1">
              <div className="details-header">{t(`table.header.${header}`)}</div>
            </div>
          )}
        </div>
        <div className="table-content">
          {Object.values(liveVisitorsById).length > 0 && itemsOnPage.map((liveVisitor, i) =>
            <div key={liveVisitor.id} className="table-row">
              <div className="table-col1">
                <div className="visitor-info-w">
                  <div className={`chat-avatar-2 ${(i + 1) % 3 === 0 ? 'blocked-chat' : ((i + 1) % 3 === 2 ? 'closed-chat' : '')}`}>
                    <div>S</div>
                  </div>
                  <Link to={Object.values(liveVisitor.current_campaign).length &&
                  liveVisitor.chat_id ? `/chats/${liveVisitor.chat_id}` : `/chats/?client_id=${liveVisitor.id}`}
                        className="settings-item-label user-link">{liveVisitor.name}</Link></div>
              </div>
              <div className="table-col1">
                <div className="cp-info">
                  {moment.duration(moment().diff(moment(liveVisitor.online_from))).format('d [days], h [hrs], m [mins], s [secs]', {
                    largest: 2
                  })}
                </div>
              </div>
              <div className="table-col1">
                <div className="cp-info">{liveVisitor.current_page}</div>
              </div>
              <div className="table-col1">
                <div className="cp-info">{
                  Object.values(liveVisitor.current_campaign).length ?
                    `${liveVisitor.current_campaign.name} (${liveVisitor.current_campaign.type.replace(/_/g, ' ')})` :
                      null
                }</div>
              </div>
              <div className="table-col1">
                <Link to={Object.values(liveVisitor.current_campaign).length &&
                  liveVisitor.chat_id ? `/chats/${liveVisitor.chat_id}` : `/chats/?client_id=${liveVisitor.id}`}
                      className="step-btn w-inline-block">
                  <div className="awes-icon"><span className="awes-icon"></span></div>
                  <div>{t('table.row.startChat')}</div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="table-settings right-float">
        <TablePagination itemsLength={itemsOnPage.length} rowCounts={rowCounts} pageCount={pageCount}
                         tableRowCount={tableRowCount} onTogglePage={togglePage}
                         setTableRowCount={setTableRowCount} pageNumber={pageNumber}/>
      </div>
    </div>
  );
}